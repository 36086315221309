import { DataTableState } from '../stateTypes';
import { apply_client_side_filters } from './apply_client_side_filters';
import { apply_server_side_filters } from './apply_server_side_filters';
import { reset_data_filters } from './reset_date_filters';
import { reset_paginator } from './reset_paginator';
import { reset_quick_filters } from './reset_quick_filters';

export function filter_by_column_filters<RowData>(s: DataTableState<RowData>): void {
  reset_paginator(s);
  reset_data_filters(s);
  reset_quick_filters(s);
  apply_client_side_filters(s);
  apply_server_side_filters(s);
}
