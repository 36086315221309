import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCollapseSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 11L8 4L15 11"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgCollapseSmall.displayName = 'SvgCollapseSmall';
const CollapseSmall = withForgeIconProps(SvgCollapseSmall, {
  name: 'Collapse',
  usage: 'Use to collapse an Accordion or a dropdown',
  related: 'close',
  isLabelRequired: false,
  isNeutralInteractive: true,
  size: 'small',
});
export default CollapseSmall;
