import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgThumbsDownSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M14.5 10.1627H12.52L12.51 0.992706H14.5V10.1627ZM12.24 1.73271H6.08997C3.30997 1.38271 2.46997 4.53271 2.46997 4.53271C2.46997 4.53271 1.05997 9.38271 1.63997 9.38271H6.45997C5.43997 13.6927 5.93997 14.4927 7.40997 14.5827C8.87997 14.6727 9.06997 13.4627 9.74997 11.7227C10.43 9.98271 11.75 9.15271 11.75 9.15271L12.25 9.81271L12.23 1.73271H12.24Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgThumbsDownSmall.displayName = 'SvgThumbsDownSmall';
const ThumbsDownSmall = withForgeIconProps(SvgThumbsDownSmall, {
  name: 'ThumbsDown',
  usage: 'Use to show negative interactions or thumbs down',
  related: 'ThumbsUp',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ThumbsDownSmall;
