import Loader from '../../Loader';
import { TableRow } from './TableRow';
import { TableEmptyState } from './TableEmptyState';
import { classes } from '../utils/dataTableClasses';
import { getPageRowIds } from '../utils/getPageRowIds';
import { ColumnHeader } from '../columnHeader/ColumnHeader';
import { ColumnFooter } from '../columnFooter/ColumnFooter';
import { DataTableStore } from '../store/stateTypes';
import { hasControlPanelFromState } from '../utils/hasControlPanelFromState';
import { getEntityNameId } from '../utils/getEntityNameId';
type TableProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function TableElement<RowData>({ store }: TableProps<RowData>): JSX.Element {
  const tableId = store.useSelector((s) => s.tableId);
  const loader = store.useSelector((s) => (s.externalLoader.show ? s.externalLoader : s.loader));
  const hasFooter = store.useSelector((s) => s.columnIds.some((cid) => !!s.columns[cid].Footer));
  const hasTableTopBorder = store.useSelector((s) => {
    if (s.bulkEdit.layout === 'row') return true;
    if (hasControlPanelFromState(s)) return true;
    if (s.quickFilter.layout !== 'none') return false;
    if (s.dataFilter.show) return false;
    return true;
  });

  // columns
  const columns = store.useSelector((s) => s.columns);
  const columnIds = store.useSelector((s) => s.columnIds);
  const visibleColumnIds = columnIds.filter((cid) => !columns[cid].hide);

  // Table UI options
  const tableStyle = store.useSelector((s) => s.tableStyle);
  const tableLayout = store.useSelector((s) => s.tableLayout);
  const tableShowVerticalDividers = store.useSelector((s) => s.tableShowVerticalDividers);

  // Get Page Row Ids
  const tableType = store.useSelector((s) => s.tableType);
  const filteredAndSortedRowIds = store.useSelector((s) => s.filteredAndSortedRowIds);
  const pageSize = store.useSelector((s) => s.pagination.pageSize);
  const pageIndex = store.useSelector((s) => s.pagination.pageIndex);
  const rowIds = getPageRowIds({ tableType, filteredAndSortedRowIds, pageSize, pageIndex });
  const isFirstRowExpended = store.useSelector((s) => rowIds.length > 0 && s.rows[rowIds[0]].isExpanded);

  return (
    <Loader loading={loader.show} text={loader.text} fixedCenter={true}>
      <table
        aria-describedby={`${getEntityNameId({ tableId, panelType: 'table-title' })} ${getEntityNameId({ tableId, panelType: 'control' })}`}
        data-table-id={tableId}
        style={tableStyle}
        {...classes({
          element: 'table-element',
          extra: `fe_c_data_table--layout-${tableLayout}`,
          modifiers: {
            'vertical-dividers': tableShowVerticalDividers,
            'hide-top-border': !hasTableTopBorder,
          },
        })}
      >
        {/* Table Header */}
        <thead {...classes({ element: 'thead' })}>
          <tr
            {...classes({
              element: 'tr',
              modifiers: {
                // if the first cell row is expanded, display dark bottom border for the header row.
                'row-with-bottom-border': isFirstRowExpended,
              },
            })}
          >
            {visibleColumnIds.map((columnId) => {
              return (
                <th key={columnId} data-column={columnId} {...classes({ element: 'th' })}>
                  <ColumnHeader store={store} columnId={columnId} />
                </th>
              );
            })}
          </tr>
        </thead>

        {/* Table Body */}
        <tbody {...classes({ element: 'tbody' })}>
          {rowIds.map((rowId, index) => {
            return (
              <TableRow
                key={rowId}
                store={store}
                rowId={rowId}
                nextRowId={index === rowIds.length - 1 ? null : rowIds[index + 1]}
              />
            );
          })}
        </tbody>

        {/* Table Footer */}
        {hasFooter && (
          <tfoot {...classes({ element: 'tfoot' })}>
            <tr {...classes({ element: 'tr' })}>
              {visibleColumnIds.map((columnId) => {
                return (
                  <td key={columnId} data-column={columnId} {...classes({ element: 'td' })}>
                    <ColumnFooter store={store} columnId={columnId} />
                  </td>
                );
              })}
            </tr>
          </tfoot>
        )}
      </table>

      {/* Table Empty State */}
      <TableEmptyState store={store} />
    </Loader>
  );
}
