import { ReactElement } from 'react';
import { InlineAlert } from '@athena/forge';
import { FieldInformationType } from '../../../contexts';

import './information.scss';

type InformationProps = {
  field_information_body: string;
  field_information_type: FieldInformationType;
};

const Information = ({ field_information_body, field_information_type }: InformationProps): ReactElement => {
  return (
    <div className="information__container">
      <InlineAlert type={field_information_type}>{field_information_body}</InlineAlert>
    </div>
  );
};

export default Information;
