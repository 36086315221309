import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMoreVerticalLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.5 24C13.8807 24 15 22.8807 15 21.5C15 20.1193 13.8807 19 12.5 19C11.1193 19 10 20.1193 10 21.5C10 22.8807 11.1193 24 12.5 24Z"
      fill={props.color}
    />
    <path
      d="M12.5 14C13.8807 14 15 12.8807 15 11.5C15 10.1193 13.8807 9 12.5 9C11.1193 9 10 10.1193 10 11.5C10 12.8807 11.1193 14 12.5 14Z"
      fill={props.color}
    />
    <path
      d="M12.5 5C13.8807 5 15 3.88071 15 2.5C15 1.11929 13.8807 0 12.5 0C11.1193 0 10 1.11929 10 2.5C10 3.88071 11.1193 5 12.5 5Z"
      fill={props.color}
    />
  </svg>
);
SvgMoreVerticalLarge.displayName = 'SvgMoreVerticalLarge';
const MoreVerticalLarge = withForgeIconProps(SvgMoreVerticalLarge, {
  name: 'MoreVertical',
  usage: 'Use in small spaces to open a menu with additional options',
  related: 'kebab, meatball, navigation',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default MoreVerticalLarge;
