import React, { SVGProps, useMemo, useState } from 'react';
import { SemanticCategoryMap, SemanticColor, getSemanticColor } from '@athena/forge-shared';
import createBEMHelper from './bem-helper';
import { nanoid } from 'nanoid';

/** The available semantic color categories whitelisted for use on an icon */
export type IconSemanticColor =
  | `alert-${keyof SemanticCategoryMap['alert']}`
  | `brand-${keyof SemanticCategoryMap['brand']}`
  | `font-${keyof SemanticCategoryMap['font']}`
  | `interaction-${keyof SemanticCategoryMap['interaction']}`
  | 'default'
  | 'interactive'
  | 'disabled'
  | 'inverted';

/** The props available to every exported forge icon */
export interface IconProps extends SVGProps<SVGSVGElement> {
  /** Sets the size of the icon (a shortcut for setting height and width) */
  size?: string | number;
  /** Recolors the icon based on a semantic color category and name or SemanticColor string.
   *
   *  alert theme : 'alert-${keyof SemanticCategoryMap['alert']}'
   *  brand theme: 'brand-${keyof SemanticCategoryMap['brand']}'
   *  font theme: 'font-${keyof SemanticCategoryMap['font']}'
   *  interaction theme: 'interaction-${keyof SemanticCategoryMap['interaction']}'
   *  Additionally, the following preset color themes are available:
   *  'default':    For non-interactive icons on light backgrounds
   *  'interactive' For interactive icons on light backgrounds
   *  'disabled'    For disabled icons on light backgrounds
   *  'inverted'    For icons on dark backgrounds
   */
  semanticColor?: IconSemanticColor;
  /** Sets the inline content of the `<title>` element in the SVG (which is read when a screen
   * reader encounters the icon); defaults to the icon's name */
  title?: string;
}

export type IconSize = 'small' | 'large';

/** Represents a raw component created by svgr */
interface SVGRProps {
  title?: string;
  titleId?: string;
}
export interface SvgComponentType {
  (props: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element;
  displayName?: string;
  size?: string;
}

const colorInteractive = getSemanticColor('interaction-default');
const colorDisabled = getSemanticColor('interaction-disabled');
const colorDefault = getSemanticColor('font-default');
const colorInverted = getSemanticColor('font-invert');

/**
 * Determines the color for an icon based on the semanticColor prop.
 * If the semanticColor includes a specific category ('alert', 'brand', 'font', 'interaction'),
 * it retrieves the color using getSemanticColor.
 * Otherwise, it falls back to predefined colors based on the value of semanticColor.
 * If no matching conditions are found, it defaults to colorDefault.
 */
export function useColor({ semanticColor }: { semanticColor: IconSemanticColor }): string {
  if (semanticColor && semanticColor !== undefined && semanticColor.includes('-')) {
    const [category] = semanticColor.split('-');
    switch (category) {
      case 'alert':
      case 'brand':
      case 'font':
      case 'interaction': {
        const colorSemantic = getSemanticColor(semanticColor as SemanticColor);
        return colorSemantic || colorDefault;
      }
    }
  } else {
    switch (semanticColor) {
      case 'default':
        return colorDefault;
      case 'interactive':
        return colorInteractive;
      case 'disabled':
        return colorDisabled;
      case 'inverted':
        return colorInverted;
    }
  }
  return colorDefault;
}

const classes = createBEMHelper({ name: 'icon' });

/** Represents an icon in component form.
 *
 * The component itself has additional metadata applied directly to the function
 * object. This metadata can be used by other components to provide accessability
 * information or style certain icons differently than others.
 */
export type ForgeIconComponent = {
  (props: IconProps): JSX.Element;
  /** The name of the component as viewed in dev tools */
  displayName?: string;
  /** When used in a button, its color should be neutral */
  isNeutralInteractive?: boolean;
  /** Whether this is a large or a small icon */
  size?: IconSize;
  /** The default title for the icon */
  title?: string;
};

/** Metadata supplied by metadata.csv */
export interface ForgeIconMetadata {
  name: string;
  usage?: string;
  related?: string;
  size: IconSize;
  isLabelRequired?: boolean;
  isNeutralInteractive?: boolean;
}

/** Higher order function that applies a consistent interface to all generated
 * SVG components.
 */
export function withForgeIconProps(
  SvgComponent: SvgComponentType,
  { name, size: defaultSize, isNeutralInteractive }: ForgeIconMetadata
): ForgeIconComponent {
  // Ensure displayName is TitleCase.
  const displayName = name + defaultSize[0].toUpperCase() + defaultSize.slice(1);
  // Convert PascalCase to Title Case by inserting a space between words
  const defaultTitle = name.replace(/([a-z])([A-Z])/g, '$1 $2');
  const WithForgeIconProps: ForgeIconComponent = ({
    className,
    height: upstreamHeight,
    semanticColor = 'default',
    size,
    title = defaultTitle,
    width: upstreamWidth,
    ...rest
  }) => {
    const [titleId] = useState(nanoid());
    const color = useColor({ semanticColor });
    const heightAndWidth = useMemo(() => {
      const height = upstreamHeight || size;
      const width = upstreamWidth || size;
      /** Conditionally add the width and height props because an explicit
       * `undefined` will override the default width and height.
       */
      return {
        ...(width ? { width } : {}),
        ...(height ? { height } : {}),
      };
    }, [size, upstreamHeight, upstreamWidth]);
    /* fi_c_icon exists on SvgComponent by default, but we need to set it in Javascript
     * as well otherwise className will be overwritten by props from components
     * using an icon.
     */
    return (
      <SvgComponent
        {...classes({ modifiers: [semanticColor, defaultSize], extra: className ?? '' })}
        {...heightAndWidth}
        /** Explicitly setting role = `undefined` overrides explicit
         * role="img" in the SvgComponent implementation. It's not appropriate
         * to have an <svg role="img" /> without a title.
         */
        role={title ? 'img' : undefined}
        color={color}
        title={title}
        titleId={title ? titleId : undefined}
        {...rest}
      />
    );
  };
  WithForgeIconProps.title = defaultTitle;
  WithForgeIconProps.displayName = displayName;
  WithForgeIconProps.size = defaultSize;
  WithForgeIconProps.isNeutralInteractive = isNeutralInteractive;
  return WithForgeIconProps;
}
