import { RefObject, useEffect } from 'react';

type NodeOrRef = RefObject<Node> | Node | null;

const isRefObject = (nodeOrRef: NodeOrRef): nodeOrRef is RefObject<Node> => {
  return !!nodeOrRef && Object.prototype.hasOwnProperty.call(nodeOrRef, 'current');
};

/** Determines if a target DOM node is a child of ref */
const refContainsTarget = (nodeOrRef: NodeOrRef, target: Event['target']): boolean => {
  const node = isRefObject(nodeOrRef) ? nodeOrRef.current : nodeOrRef;
  return !!node?.contains(target as Node);
};

/** Accepts a ref and a callback
 *
 * When clicked outside of the ref, it calls the callback passed to it
 */
export default function useClickOutside(nodeOrRef: NodeOrRef, callback: () => void): void {
  useEffect(() => {
    const domNode = isRefObject(nodeOrRef) ? nodeOrRef.current : nodeOrRef;
    if (domNode) {
      const handleClickOutside: EventListener = (event) => {
        // Drills into an open shadow Root.
        const targetElement = event.composedPath()[0];

        if (targetElement instanceof Node && !refContainsTarget(domNode, targetElement)) {
          callback();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [callback, nodeOrRef]);
}
