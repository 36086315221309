import Button from '../../../Button';
import { DataTableStore } from '../../store/stateTypes';
import { getSelectedRowIds } from '../../utils/getSelectedRowIds';
import { hasRowsInEditStatusFromState } from '../../utils/hasRowsInEditStatusFromState';
import { DataTableBlockUI } from '../../utils/DataTableBlockUI';
import { classes } from '../../utils/dataTableClasses';

// Actions
import { apply_bulk_edit_async } from '../../store/asyncActions/apply_bulk_edit_async';

type BulkEditButtonLayoutProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function BulkEditButtonLayout<RowData>({ store }: BulkEditButtonLayoutProps<RowData>): JSX.Element {
  const dispatchAsyncAction = store.useDispatchAsyncAction();
  const tableType = store.useSelector((s) => s.tableType);
  const bulkEdit = store.useSelector((s) => s.bulkEdit);
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));

  // Get selected Row Ids
  const rows = store.useSelector((s) => s.rows);
  const filteredAndSortedRowIds = store.useSelector((s) => s.filteredAndSortedRowIds);
  const selectedRowIds = getSelectedRowIds({ filteredAndSortedRowIds, rows });

  // Don't show the bulk edit button if no options
  if (bulkEdit.layout !== 'button' || bulkEdit.options.length === 0) {
    return <></>;
  }

  return (
    <DataTableBlockUI block={hasRowsInEditStatus}>
      {/* The "fe_c_data_table__bulk_edit__button_layout" has no style definition.
       * Retained here to allow users to customize the CSS. */}
      <div {...classes({ element: 'bulk_edit__button_layout' })}>
        <Button
          text={bulkEdit.title?.(selectedRowIds.length) ?? `Button - Bulk Edit (${selectedRowIds.length})`}
          variant="secondary"
          disabled={selectedRowIds.length === 0}
          onClick={() => {
            dispatchAsyncAction(
              apply_bulk_edit_async({
                tableType,
                rows,
                bulkEdit,
                selectedRowIds,
                // Button layout always apply the first option
                selectedBulkEditOptionIndex: 0,
              })
            );
          }}
        />
      </div>
    </DataTableBlockUI>
  );
}
