import { Property } from 'csstype';
import dashify from 'dashify';
import { PageProps } from 'gatsby';
import { createContext, ReactElement, ReactNode } from 'react';
import { ChildNavMap, MenuItem, SVGIcons } from './components/page-context-provider/page-context-provider';
import { ProfileData } from './hooks/useProfile';
import { VersionResponse } from './hooks/useVersion';

export interface AllComponentMetadata {
  displayName: string;
  id: string;
  props: Array<{
    id: string;
    name: string;
    required: boolean;
    defaultValue: {
      value: unknown;
    };
    description: {
      text: string;
    };
    type: {
      name: string;
      raw: unknown;
      value: unknown;
    };
  }>;
  fields: {
    documentedBy: string;
  };
}

export type FieldInformationType = 'info' | 'success' | 'attention' | 'critical';

export interface ChildBlock {
  /** id of the child
   *
   * TODO: This is not set in algolia. Must be set elsewhere
   */
  id?: string;
  childrenBlock?: ChildBlock[];
  /** If true, uses `id` as the ID for the DOM element.
   * Otherwise, uses `field_title` to generate an ID for the DOM element.
   *
   * Not created from algolia because this is used to create autogenerated
   * sections
   */
  useIdForHyperlink?: boolean;
  /** Drupal type
   *
   * TODO: This is not set in algolia. Must be set elsewhere
   */
  drupal_type?: string;
  field_card_body?: string;
  field_card_link?: string;
  field_component_preview?: string;
  field_do_description?: string;
  field_do_image?: string;
  field_don_t_description?: string;
  field_don_t_image?: string;
  field_image?: string;
  field_information_body?: string;
  field_information_type?: FieldInformationType;
  field_markdown_body?: string;
  field_text_body?: string;
  field_title?: string;
  /** Children of the header coming from React code instead of drupal. Appended
   * to the end of children coming from drupal.
   *
   * Used to inject autogenerated sections into the guide.
   */
  reactChildren?: ReactNode;
}

/** Generates an Id used for hyperlinks to the ChildBlock */
export function childBlockLinkId({ id, field_title, useIdForHyperlink }: ChildBlock): string {
  return useIdForHyperlink && id ? id : dashify(field_title || '');
}
export interface ComponentPageNode {
  id: string;
  body: {
    summary: string;
    value: string;
  };
  field_non_component_summary: string;
  childrenBlock?: ChildBlock[];
  field_component_preview: string;
  field_summary: string;
  field_deprecated?: boolean;
  field_deprecated_reason?: string;
  field_contributed?: boolean;
  field_contributed_credits?: string;
  title: string;
  drupal_internal__nid: number;
  path: {
    alias: string;
  };
}

export interface NonComponentPageNode {
  id: string;
  body: {
    summary: string;
    value: string;
  };
  childrenBlock: ChildBlock[];
  field_component_preview: string;
  field_non_component_summary: { value: string };
  field_deprecated?: boolean;
  field_deprecated_reason?: string;
  title: string;
  drupal_internal__nid: number;
  path: {
    alias: string;
  };
}

export interface IconNode {
  node: {
    name: string;
    id: string;
    internal: { mediaType: string };
    svg: {
      originalContent: string;
    };
  };
}

export interface IconNodes {
  edges: IconNode[];
}

export interface IconMeta {
  Related?: string;
  Name: string;
  Usage?: string;
  id: string;
  Label_Required?: string;
  Neutral_Interactive?: string;
}

export interface IconsMeta {
  nodes: IconMeta[];
}

export interface AllMenuItems {
  childrenMenuItems: Array<{
    route: {
      parameters: {
        node: string;
      };
    };
  }>;
}

export interface AlgoliaRecord {
  component?: string;
  fullText?: string;
  isComponent?: boolean;
  objectID: string;
  path: string;
  summary: string;
  title: string;
  type: string;
  uniqueValue: string;
}

/** Gatsby page context
 *
 * TODO: Find out where this data gets set.
 */
export interface GatsbyPageContext {
  id?: number;
  bodyColor?: Property.BackgroundColor;
  headerColor?: Property.BackgroundColor;
  title?: string;
}

export interface ImageData {
  src: string;
  alt: string;
  width: number;
  height: number;
}

export interface MetadataElement {
  node: ComponentMetadata;
}

export interface ComponentMetadata {
  id: string;
  displayName: string;
  props: PropMetadata[];
}

export interface PropMetadata {
  id: string;
  name: string;
  required: boolean;
  defaultValue: {
    value: string;
  };
  description: {
    text: string;
  };
  type: PropType;
}

export type PropValue = PropType | PropType[] | Record<string, PropType> | ReactElement | string | null | undefined;

export interface PropType {
  name?: string;
  raw?: string;
  required?: boolean;
  value?: PropValue;
}

/** Gatsby page data
 *
 * TODO: Find out where this gets set
 */
export interface PageData {
  nodePage?: {
    title: string;
    body: {
      summary: string;
      value: string;
    };
  };
  parentTable?: {
    edges: MetadataElement[];
  };
  path?: string;
  childTables?: {
    edges: MetadataElement[];
  };
  newNodeInfo?: ComponentPageNode | null;
  iconsSVG?: IconNodes | null;
  iconsMeta?: IconsMeta | null;
}
export interface PageContextData {
  childParentMap?: ChildNavMap;
  pageContext?: GatsbyPageContext;
  menuItems?: MenuItem[];
  pageData?: PageData;
  profileData?: ProfileData;
  location?: PageProps['location'];
  svgIcons?: SVGIcons[];
}

interface ProfileContextData {
  profileData?: ProfileData;
  profilePhotoUrl?: string;
}

export const PageContext = createContext<PageContextData>({});

export const VersionContext = createContext<VersionResponse>({
  versions: [],
});

export const ProfileContext = createContext<ProfileContextData>({});
