import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGenAiLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.57149 11.987C2.68715 10.0366 4.78925 8.71997 7.2 8.71997H16.8C19.2108 8.71997 21.3129 10.0366 22.4285 11.987C23.3457 12.3245 24 13.2052 24 14.24V16.16C24 17.1948 23.3457 18.0754 22.4285 18.4129C21.3129 20.3634 19.2108 21.68 16.8 21.68H7.2C4.78924 21.68 2.68715 20.3634 1.57149 18.4129C0.654318 18.0754 0 17.1948 0 16.16V14.24C0 13.2052 0.654317 12.3245 1.57149 11.987ZM7.2 10.16C5.23818 10.16 3.53686 11.2807 2.7037 12.9202L2.54633 13.2299L2.20599 13.2994C1.76871 13.3888 1.44 13.7768 1.44 14.24V16.16C1.44 16.6231 1.76871 17.0111 2.20599 17.1005L2.54633 17.1701L2.7037 17.4797C3.53686 19.1192 5.23818 20.24 7.2 20.24H16.8C18.7618 20.24 20.4631 19.1192 21.2963 17.4797L21.4537 17.1701L21.794 17.1005C22.2313 17.0111 22.56 16.6231 22.56 16.16V14.24C22.56 13.7768 22.2313 13.3888 21.794 13.2994L21.4537 13.2299L21.2963 12.9202C20.4631 11.2807 18.7618 10.16 16.8 10.16H7.2Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2001 13.04C6.00716 13.04 5.0401 14.007 5.0401 15.2C5.0401 16.3929 6.00716 17.36 7.2001 17.36C8.39303 17.36 9.3601 16.3929 9.3601 15.2C9.3601 14.007 8.39303 13.04 7.2001 13.04ZM3.6001 15.2C3.6001 13.2118 5.21187 11.6 7.2001 11.6C9.18832 11.6 10.8001 13.2118 10.8001 15.2C10.8001 17.1882 9.18832 18.8 7.2001 18.8C5.21187 18.8 3.6001 17.1882 3.6001 15.2Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8 13.04C15.607 13.04 14.64 14.007 14.64 15.2C14.64 16.3929 15.607 17.36 16.8 17.36C17.9929 17.36 18.96 16.3929 18.96 15.2C18.96 14.007 17.9929 13.04 16.8 13.04ZM13.2 15.2C13.2 13.2118 14.8117 11.6 16.8 11.6C18.7882 11.6 20.4 13.2118 20.4 15.2C20.4 17.1882 18.7882 18.8 16.8 18.8C14.8117 18.8 13.2 17.1882 13.2 15.2Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62988 2H7.43998C9.40155 2 10.8044 2.84995 11.7003 3.98054C12.5762 5.08591 12.96 6.44631 12.96 7.52V9.44H11.52V8.13423C10.5332 7.96626 9.51753 7.7188 8.68099 7.0753C7.62736 6.26482 6.97699 4.93706 6.72496 2.8045L6.62988 2ZM11.4149 6.65017C11.2786 6.05882 11.0054 5.42225 10.5717 4.87485C10.0622 4.23191 9.3267 3.70356 8.29435 3.51461C8.56796 4.84856 9.0368 5.53225 9.55898 5.93393C10.0458 6.30839 10.6482 6.50034 11.4149 6.65017Z"
      fill={props.color}
    />
  </svg>
);
SvgGenAiLarge.displayName = 'SvgGenAiLarge';
const GenAiLarge = withForgeIconProps(SvgGenAiLarge, {
  name: 'GenAi',
  usage: 'Use to indicate AI generated content',
  related: 'alert, warning',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default GenAiLarge;
