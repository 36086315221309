import { DataTableColumn } from '../../utils/internalTypes';
import Checkbox from '../../../Checkbox';
import { getPageRowIdsFromState } from '../../utils/getPageRowIds';

const columnId = 'row-select';

export type CreateRowSelectColumn<RowData> = typeof createRowSelectColumn<RowData>;

export function createRowSelectColumn<RowData>(): DataTableColumn<RowData> {
  return {
    columnId,

    // ================================
    // Header
    // ================================
    Header: ({ useDispatch, useSelector }) => {
      const dispatch = useDispatch();
      const tableId = useSelector((s) => s.tableId);
      const rows = useSelector((s) => {
        return getPageRowIdsFromState(s)
          .filter((rid) => s.determineSelectableRow(s.rows[rid].data))
          .map((rid) => s.rows[rid]);
      });

      if (rows.length === 0) {
        return <></>;
      }

      const allSelected = rows.every((r) => r.isSelected);
      const allNotSelected = rows.every((r) => !r.isSelected);
      return (
        <Checkbox
          id={`${tableId}-${columnId}-header`}
          description=""
          checked={allSelected}
          indeterminate={!allSelected && !allNotSelected}
          onChange={(e) => {
            dispatch(function user_select_header_row(s) {
              rows.forEach((row) => {
                s.rows[row.rowId].isSelected = e.target.checked;
              });
            });
          }}
        />
      );
    },

    // ================================
    // Cell
    // ================================
    Cell: ({ useDispatch, useSelector, rowId }) => {
      const dispatch = useDispatch();
      const tableId = useSelector((s) => s.tableId);
      const isSelected = useSelector((s) => s.rows[rowId].isSelected);
      const isSelectable = useSelector((s) => s.determineSelectableRow(s.rows[rowId].data));

      return (
        /**
         * Prevent the `onClick` event from propagating to avoid triggering the event on the
         * row. Without `stopPropagation`, an issue may arise where the checkbox becomes
         * un-selectable if the user has set the `onRowClickLeft` callback to 'toggle-select-row'.
         */
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox
            id={`${tableId}-${columnId}-cell-${rowId}`}
            description=""
            checked={isSelected}
            disabled={!isSelectable}
            onChange={(e) => {
              dispatch(function user_select_row(s) {
                s.rows[rowId].isSelected = e.target.checked;
              });
            }}
          />
        </div>
      );
    },
  };
}
