import { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import parse from 'html-react-parser';
import Layout from '../components/layout';
import TertiaryNav from '../components/tertiary-nav-legacy';
import { pageOptions } from '../html-parsing-options';
import { GatsbyPageContext } from '../contexts';

interface PageData {
  nodePage: {
    title: string;
    id: string;
    body: {
      summary: string;
      value: string;
    };
  };
  menuItems: {
    childrenMenuItems: object[];
  };
}

export default function BasicPage({
  data,
  pageContext,
  uri,
  children,
}: PageProps<PageData, GatsbyPageContext>): ReactElement {
  const { title } = data.nodePage;

  const summary = data.nodePage.body ? data.nodePage.body.summary : '';
  const bodyHTML = data.nodePage.body ? data.nodePage.body.value : '';

  const regex = new RegExp(/<h2.*\/h2>/gi);
  const matches = data.nodePage.body?.value.match(regex);
  const tertiaryHeaders = matches?.map((match: string, index: number) => {
    const partsRegex = new RegExp(/<h2 id="(.+?)">(.+?)<\/h2>/gi);
    const parts = partsRegex.exec(match);
    return {
      title: parts ? parts[2] : '',
      link: parts ? parts[1] : '',
    };
  });

  return (
    <Layout pageContext={pageContext} title={title} legacy subtitle={summary}>
      {tertiaryHeaders && !uri.includes('/guidelines/') && <TertiaryNav items={tertiaryHeaders} />}

      {/* {summary !== '' && <p className="p--lead">{summary}</p>} */}

      {children}

      {bodyHTML !== '' && <div className="fg_markdown cms-content">{parse(bodyHTML, pageOptions())}</div>}
    </Layout>
  );
}

export const query = graphql`
  query ($title: String) {
    nodePage(title: { eq: $title }) {
      title
      body {
        summary
        value
      }
    }
    menuItems(title: { eq: $title }) {
      childrenMenuItems {
        title
      }
    }
  }
`;
