import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgArchiveSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2 5H1V1H15V5H14M2 5V15H11C12.6569 15 14 13.6569 14 12V5M2 5H14M6 8H10"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
SvgArchiveSmall.displayName = 'SvgArchiveSmall';
const ArchiveSmall = withForgeIconProps(SvgArchiveSmall, {
  name: 'Archive',
  usage: 'Use as a workflow icon that indicates an item is no longer needed',
  related: 'N/A',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default ArchiveSmall;
