import { ReactElement, ReactNode } from 'react';

interface RawDataPreviewProps {
  title: string;
  values: ReactNode;
  customData?: ReactNode;
}
const RawDataPreview = (props: RawDataPreviewProps): ReactElement => {
  const { title, values, customData } = props;
  return (
    <div
      style={{
        boxSizing: 'border-box',
        fontFamily: 'monospace',
        color: 'dimgray',
        border: '1px solid gainsboro',
        background: 'whitesmoke',
        padding: '1rem',
        borderRadius: '3px',
        minHeight: '62px',
        marginTop: '16px',
      }}
    >
      <strong>{title}:</strong>
      <br />
      <code>
        <pre>{values}</pre>
      </code>
      {customData}
    </div>
  );
};

export default RawDataPreview;
