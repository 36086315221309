import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDollarLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13 3.8147e-06H11V4H13V3.8147e-06Z" fill={props.color} />
    <path d="M13 20H11V24H13V20Z" fill={props.color} />
    <path
      d="M11.0815 20.99C10.183 20.9001 9.27454 20.6702 8.36606 20.3204C7.45757 19.9606 6.66889 19.4908 6 18.9112L7.52745 16.7724C8.21631 17.2821 8.90516 17.6918 9.61398 18.0017C10.3228 18.3115 11.0715 18.4714 11.8702 18.4714C12.8286 18.4714 13.5474 18.2615 14.0166 17.8518C14.4859 17.442 14.7255 16.8623 14.7255 16.1127C14.7255 15.5131 14.5258 15.0033 14.1364 14.6036C13.7471 14.2038 13.2479 13.834 12.6589 13.5042C12.0599 13.1744 11.421 12.8545 10.7321 12.5547C10.0433 12.2549 9.40433 11.8951 8.80532 11.4853C8.20632 11.0755 7.71714 10.5758 7.32779 10.0061C6.93844 9.42643 6.73877 8.71683 6.73877 7.85731C6.73877 6.57802 7.12812 5.50861 7.91681 4.65908C8.69551 3.80955 9.75374 3.21988 11.0915 3H12.9983C13.9368 3.10994 14.7454 3.3598 15.4243 3.74959C16.1032 4.13937 16.7121 4.60911 17.2612 5.13882L15.5241 6.97779C14.975 6.49806 14.4459 6.13826 13.9368 5.89839C13.4276 5.64853 13.2579 5.5286 12.5591 5.5286C11.7604 5.5286 11.1314 5.71849 10.6822 6.08829C10.2329 6.46808 10.0033 7.00778 10.0033 7.71738C10.0033 8.26708 10.203 8.71683 10.5923 9.07663C10.9817 9.43643 11.4809 9.76624 12.0799 10.0661C12.6789 10.3659 13.3178 10.6657 14.0067 10.9756C14.6955 11.2754 15.3344 11.6352 15.9334 12.055C16.5324 12.4747 17.0216 12.9945 17.411 13.6041C17.8003 14.2138 18 14.9833 18 15.8928C18 17.2121 17.5408 18.2515 16.822 19.201C16.0233 20.2504 14.426 21 13.0083 21H11.1015L11.0815 20.99Z"
      fill={props.color}
    />
  </svg>
);
SvgDollarLarge.displayName = 'SvgDollarLarge';
const DollarLarge = withForgeIconProps(SvgDollarLarge, {
  name: 'Dollar',
  usage: 'Use to indicate a dollar amount',
  related: 'money, cash, currency, payment',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default DollarLarge;
