import React, { HTMLAttributes, useEffect, useRef, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper('show-hide');

export interface ShowHideProps extends HTMLAttributes<HTMLDivElement> {
  /** Used to set if transition is used on component mount */
  appear?: boolean;
  /** Content to be shown and hidden */
  children: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** A minimum height in pixels for the element */
  minHeight?: number;
  /** Callback invoked when hide animation is complete */
  onHidden?: () => void;
  /** Callback invoked when show animation is complete */
  onShown?: () => void;
  /** Used to set the shown and hidden */
  show?: boolean;
}

function ShowHide({
  appear = true,
  children,
  className,
  minHeight = 0,
  onHidden,
  onShown,
  show = true,
  ...rest
}: ShowHideProps): React.ReactElement {
  const containerElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (minHeight > 0 && !show) {
      const elem = containerElement.current;
      if (elem) {
        elem.style.height = minHeight + 'px';
      }
    }
  }, [minHeight, show]);

  // the height of an element cannot be animated
  // unless explicity set. Transition lifecycle
  // callbacks are used to set inline styles for height.

  function handleEnter(): void {
    const elem = containerElement.current;
    if (elem) {
      elem.style.height = minHeight + 'px';
    }
  }

  function handleEntering(): void {
    const elem = containerElement.current;
    if (elem) {
      const height = elem.scrollHeight + 'px';
      elem.style.height = height;
    }
  }

  function handleEntered(): void {
    const elem = containerElement.current;
    if (elem) {
      elem.style.height = 'auto';
    }
    if (onShown) {
      onShown();
    }
  }

  function handleExit(): void {
    const elem = containerElement.current;
    if (elem) {
      const height = elem.scrollHeight + 'px';
      elem.style.height = height;
    }
  }

  function handleExiting(): void {
    const elem = containerElement.current;
    if (elem) {
      elem.style.height = minHeight + 'px';
    }
  }

  function handleExited(): void {
    if (onHidden) {
      onHidden();
    }
  }

  return (
    <CSSTransition
      appear={appear}
      in={show}
      timeout={250}
      onEnter={handleEnter}
      onEntering={handleEntering}
      onEntered={handleEntered}
      onExit={handleExit}
      onExiting={handleExiting}
      onExited={handleExited}
      unmountOnExit={minHeight === 0 ? true : false}
      classNames={minHeight === 0 ? 'fade-open' : 'open'}
      nodeRef={containerElement}
    >
      {() => (
        <div {...classes({ extra: className })} ref={containerElement} {...rest}>
          {children}
        </div>
      )}
    </CSSTransition>
  );
}

export const showHidePropTypes: WeakValidationMap<ShowHideProps & { '...rest': unknown }> = {
  children: PropTypes.node.isRequired,
  appear: PropTypes.bool,
  className: PropTypes.string,
  minHeight: PropTypes.number,
  onShown: PropTypes.func,
  onHidden: PropTypes.func,
  show: PropTypes.bool,
  /** Passthrough props to the component's root `<div>` element */
  '...rest': PropTypes.any,
};

ShowHide.propTypes = showHidePropTypes;

export default ShowHide;
