import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSettingsSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
      stroke={props.color}
      strokeWidth={4}
      strokeMiterlimit={10}
    />
    <path
      d="M7.5 15H8.5V13H7.5V15Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M2.69057 12.5934L3.39767 13.3005L4.81189 11.8863L4.10478 11.1792L2.69057 12.5934Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M1 7.5V8.5H3V7.5H1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M3.41035 2.69426L2.70325 3.40137L4.11746 4.81558L4.82457 4.10847L3.41035 2.69426Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M8.5 1H7.5V3H8.5V1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M13.302 3.40657L12.5948 2.69946L11.1806 4.11368L11.8877 4.82078L13.302 3.40657Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M15 8.5V7.5H13V8.5H15Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M12.5972 13.3057L13.3043 12.5986L11.89 11.1844L11.1829 11.8915L12.5972 13.3057Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgSettingsSmall.displayName = 'SvgSettingsSmall';
const SettingsSmall = withForgeIconProps(SvgSettingsSmall, {
  name: 'Settings',
  usage: 'Use to open settings',
  related: 'gear, preferences, setting',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default SettingsSmall;
