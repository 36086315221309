import FileUploadMockApplication from '@athena/forge/dist/FileUploadMockApplication';

import rawContents from "!!raw-loader!./FileUploadDragAndDrop.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { FileUploadMockApplication };

function FileUploadDragAndDrop():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="File Upload Drag And Drop" />;
}

export default FileUploadDragAndDrop;
