export interface Theme {
  rootClasses: string[];
}

export interface RootContext {
  theme: Theme;
  isKeyboardUser: boolean;
}

export const contextDefaultValues: RootContext = {
  theme: {
    rootClasses: [],
  },
  // `isKeyboardUser` is false so components outside of Root don't assume all users are keyboard users. When the Root
  // component initializes, it ignores this default and sets `isKeyboardUser` to true.
  isKeyboardUser: false,
};
