import { ReactElement } from 'react';
import { Link } from 'gatsby';
import { useContext } from 'react';
import { PageContext } from '../../contexts';
import AthenaDesignSystemNewLogo from './../../../static/svg/athena-design-system-logo.svg';
import AthenaDesignSystemLTSLogo from './../../../static/svg/athena-design-system-lts-logo.svg';
import { MenuItem } from '../page-context-provider/page-context-provider';
import createBEMHelper from '../../bem-helper';
import { forgeVersionType } from '../../versionCheckHelper';
import './main-nav-items.scss';

const classes = createBEMHelper({ name: 'main-nav-items' });

const MainNavItems = (): ReactElement => {
  const { pageContext, childParentMap, menuItems = [] } = useContext(PageContext);

  const isLts = forgeVersionType() === 'lts';

  const { home, landingPages } = menuItems.reduce<{ home?: MenuItem; landingPages: MenuItem[] }>(
    (categorizedLinks, menuItem) => {
      if (menuItem.title === 'Home') {
        categorizedLinks.home = menuItem;
      } else {
        categorizedLinks.landingPages.push(menuItem);
      }
      return categorizedLinks;
    },
    { landingPages: [] }
  );

  const AthenaDesignSystemLogo = isLts ? AthenaDesignSystemLTSLogo : AthenaDesignSystemNewLogo;

  return (
    <>
      {home && (
        <Link
          {...classes({ element: 'home', extra: 'fg_c_branding fe_u_margin--small' })}
          to={home.url.replace('/forge', '')}
        >
          <AthenaDesignSystemLogo height="100%" />
        </Link>
      )}
      <nav {...classes()}>
        <ul {...classes({ element: 'list' })}>
          {landingPages.map((menuItem, index) => {
            const parentPageId = pageContext?.id && childParentMap && childParentMap[pageContext.id];
            const activeLink =
              parentPageId === menuItem.route.parameters.node || (!parentPageId && menuItem.title.startsWith('Home'));
            const link = menuItem.url.replace('/forge', '');
            return (
              <li key={index} {...classes({ element: 'list-item', modifiers: { active: activeLink } })}>
                <Link
                  title={menuItem.title}
                  to={link}
                  {...classes({ element: 'link', modifiers: { active: activeLink, lts: isLts } })}
                >
                  {menuItem.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default MainNavItems;
