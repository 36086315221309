import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgListNumberedLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M23 5H7M23 19H7M23 12H7"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M0.322021 7.21803V6.25203H1.27402V3.95603H0.434022V3.21403C0.686022 3.1627 0.900688 3.10436 1.07802 3.03903C1.25535 2.9737 1.42802 2.88736 1.59602 2.78003H2.47802V6.25203H3.27602V7.21803H0.322021Z"
      fill={props.color}
    />
    <path
      d="M0.111908 14.262V13.576C0.373242 13.3333 0.615908 13.1047 0.839908 12.89C1.06857 12.6753 1.26691 12.4747 1.43491 12.288C1.60291 12.0967 1.73357 11.917 1.82691 11.749C1.92491 11.5763 1.97391 11.4153 1.97391 11.266C1.97391 11.0653 1.92257 10.9183 1.81991 10.825C1.72191 10.727 1.59124 10.678 1.42791 10.678C1.27857 10.678 1.14557 10.7223 1.02891 10.811C0.916908 10.895 0.807242 10.9907 0.699908 11.098L0.0559082 10.454C0.172575 10.3327 0.286908 10.2277 0.398908 10.139C0.510908 10.0457 0.627575 9.97099 0.748908 9.91499C0.874908 9.85432 1.00557 9.80999 1.14091 9.78199C1.28091 9.75399 1.43257 9.73999 1.59591 9.73999C1.81991 9.73999 2.02524 9.77499 2.21191 9.84499C2.39858 9.91499 2.55958 10.013 2.69491 10.139C2.83024 10.265 2.93524 10.419 3.00991 10.601C3.08458 10.7783 3.12191 10.9767 3.12191 11.196C3.12191 11.3733 3.08224 11.5553 3.00291 11.742C2.92824 11.924 2.83024 12.106 2.70891 12.288C2.58757 12.47 2.44991 12.6473 2.29591 12.82C2.14657 12.9927 1.99724 13.156 1.84791 13.31C1.95991 13.296 2.08591 13.2843 2.22591 13.275C2.37058 13.261 2.49191 13.254 2.58991 13.254H3.33191V14.262H0.111908Z"
      fill={props.color}
    />
    <path
      d="M1.582 21.306C1.218 21.306 0.905333 21.25 0.644 21.138C0.382667 21.026 0.168 20.872 0 20.676L0.546 19.92C0.681333 20.046 0.826 20.1486 0.98 20.228C1.13867 20.3026 1.29733 20.34 1.456 20.34C1.652 20.34 1.80833 20.305 1.925 20.235C2.04167 20.165 2.1 20.06 2.1 19.92C2.1 19.836 2.086 19.7613 2.058 19.696C2.03 19.6306 1.97633 19.5746 1.897 19.528C1.81767 19.4813 1.70567 19.4463 1.561 19.423C1.41633 19.3996 1.22733 19.388 0.994 19.388V18.548C1.18067 18.548 1.33467 18.5363 1.456 18.513C1.582 18.4896 1.68233 18.457 1.757 18.415C1.83167 18.3683 1.883 18.3146 1.911 18.254C1.94367 18.1933 1.96 18.1233 1.96 18.044C1.96 17.7733 1.806 17.638 1.498 17.638C1.34867 17.638 1.21567 17.6683 1.099 17.729C0.982333 17.7896 0.854 17.8806 0.714 18.002L0.112 17.274C0.331333 17.092 0.557667 16.952 0.791 16.854C1.02433 16.7513 1.27867 16.7 1.554 16.7C2.044 16.7 2.43367 16.8096 2.723 17.029C3.017 17.2436 3.164 17.5516 3.164 17.953C3.164 18.3823 2.926 18.702 2.45 18.912V18.94C2.702 19.0193 2.90733 19.1476 3.066 19.325C3.22467 19.4976 3.304 19.724 3.304 20.004C3.304 20.214 3.25733 20.4006 3.164 20.564C3.07067 20.7226 2.94467 20.858 2.786 20.97C2.632 21.082 2.45 21.166 2.24 21.222C2.03 21.278 1.81067 21.306 1.582 21.306Z"
      fill={props.color}
    />
  </svg>
);
SvgListNumberedLarge.displayName = 'SvgListNumberedLarge';
const ListNumberedLarge = withForgeIconProps(SvgListNumberedLarge, {
  name: 'ListNumbered',
  usage: 'Use in a Rich Text Editor to create a numbered list',
  related: 'list, bulleted list',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ListNumberedLarge;
