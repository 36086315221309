import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgProfileLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4.99998 22.9399H3.99998C3.99998 23.4901 4.44437 23.9369 4.99452 23.9399L4.99998 22.9399ZM4.99998 18.24H6.00006L5.99989 18.2272L4.99998 18.24ZM12 11L12.0209 10.0002C12.0069 9.99993 11.993 9.99993 11.9791 10.0002L12 11ZM19 18.24L18 18.2272V18.24H19ZM16 23L15.9945 24H16V23ZM5.99998 22.9399V18.24H3.99998V22.9399H5.99998ZM5.99989 18.2272C5.97912 16.6049 6.60029 15.04 7.72805 13.8736L6.29021 12.4834C4.79571 14.0291 3.97253 16.1029 4.00006 18.2528L5.99989 18.2272ZM7.72805 13.8736C8.8558 12.7071 10.3987 12.0337 12.0209 11.9998L11.9791 10.0002C9.82948 10.0451 7.78473 10.9376 6.29021 12.4834L7.72805 13.8736ZM11.9791 11.9998C13.6012 12.0337 15.1442 12.7071 16.2719 13.8736L17.7097 12.4834C16.2152 10.9376 14.1705 10.0451 12.0209 10.0002L11.9791 11.9998ZM16.2719 13.8736C17.3997 15.04 18.0208 16.6049 18.0001 18.2272L19.9999 18.2528C20.0274 16.1029 19.2042 14.0291 17.7097 12.4834L16.2719 13.8736ZM18 18.24V20H20V18.24H18ZM18 20C18 20.5305 17.7893 21.0392 17.4142 21.4142L18.8284 22.8284C19.5786 22.0783 20 21.0608 20 20H18ZM17.4142 21.4142C17.0392 21.7893 16.5304 22 16 22V24C17.0608 24 18.0783 23.5786 18.8284 22.8284L17.4142 21.4142ZM16.0054 22L5.00544 21.94L4.99452 23.9399L15.9945 24L16.0054 22ZM16 5.98999C16 8.19172 14.211 9.97998 12 9.97998V11.98C15.3118 11.98 18 9.30006 18 5.98999H16ZM12 9.97998C9.78897 9.97998 8 8.19172 8 5.98999H6C6 9.30006 8.68818 11.98 12 11.98V9.97998ZM8 5.98999C8 3.78826 9.78897 2 12 2V0C8.68818 0 6 2.67992 6 5.98999H8ZM12 2C14.211 2 16 3.78826 16 5.98999H18C18 2.67992 15.3118 0 12 0V2Z"
      fill={props.color}
    />
  </svg>
);
SvgProfileLarge.displayName = 'SvgProfileLarge';
const ProfileLarge = withForgeIconProps(SvgProfileLarge, {
  name: 'Profile',
  usage: 'Use to indicate a patient or user profile',
  related: 'avatar, person',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ProfileLarge;
