import { ReactElement } from 'react';

export interface IconComboProps {
  Large?: string;
  Small?: string;
}

const IconCombo = ({ Large, Small }: IconComboProps): ReactElement => {
  return (
    <div className="flex">
      {Large && <div className="large-icon" dangerouslySetInnerHTML={{ __html: Large }} />}
      {Small && <div className="small-icon" dangerouslySetInnerHTML={{ __html: Small }} />}
    </div>
  );
};

export default IconCombo;
