import { getDataTableDefaultPropValues } from '../DataTable';
import { DataTableState } from '../store/stateTypes';

const dataTableDefaultProps = getDataTableDefaultPropValues();

const dataTableDefaultState: DataTableState<unknown> = {
  tableId: '',
  tableType: 'client-side',

  // ==================================
  // 1. UI Options
  // ==================================
  rootClassName: '',
  rootStyle: {},
  tableStyle: {},
  tableLayout: 'medium',
  tableShowVerticalDividers: false,
  tableStickyHeaders: false,
  tableWrapHeaderStyle: 'wrap',
  grayDeletedRows: true,
  onRenderRowProps: undefined,

  // ==================================
  // 2. Table Title Panel: title, entity name, add row component, csv download button
  // ==================================
  tableTitle: '',

  entityName: {
    showInTableTitle: false,
    showInControlPanel: false,
    prefix: '',
    singular: 'entity',
    plural: 'entities',
  },

  lastDataUpdateTimeInTableTitle: {
    show: false,
    updateTime: new Date(),
  },

  showServerSideRefreshButton: false,

  CustomAddRowComponent: undefined,

  csvDownload: {
    showButton: false,
    buttonText: 'Download CSV',
    downloadFilePrefix: 'data-table',
    fetchCSV: undefined,
  },

  // ==================================
  // 3. Quick Filter Panel
  // ==================================
  quickFilter: {
    layout: 'none',
    showAllOptions: false,
    showAllOptionsButton: false,
    options: [],
    selectedOptionIds: [],
  },

  // ==================================
  // 4. Data Filter Panel
  // ==================================
  dataFilter: {
    show: false,
    isHideable: true,
    isExpanded: true,
    filterIds: [],
    filters: {}, // Map<filterId: string, filter: FilterObject>
  },

  // ==================================
  // 5. Control Panel: bulk edit, edit table button, delete rows checkbox, search box, paginator
  // ==================================
  bulkEdit: {
    layout: 'none',
    title: undefined,
    selectedOptionIndex: 0,
    options: [],
    confirmModal: undefined,
    completeModal: undefined,
    completeToast: undefined,
  },

  CustomBulkEditComponent: undefined,

  editTableButton: {
    show: false,
    showSaveAndCancelButtons: false,
    serverSideSaveMultipleRowsFn: undefined,
    onRowsSaved: undefined,
    saveAlerts: undefined,
    cancelAlerts: undefined,
  },

  deletedRowsCheckbox: {
    show: false,
    checked: true,
  },

  searchBox: {
    show: false,
    inputText: '',
  },

  columnConfig: {
    enable: false,
    isPopoverOpen: false,
    defaultOptions: [],
    configurableColumnIds: [],
  },

  pagination: {
    layout: 'none',
    pageIndex: 0,
    pageSize: -1, // show 'All Rows' if pageSize <= 0
    pageSizeOptions: [],
    paginatorLocation: 'top-right',
    serverSideTotalCount: 0,
  },

  // ==================================
  // 6. Columns
  // ==================================

  showColumnFilters: true,
  columnIds: [],
  /** Record<columnId: string, row: ColumnObject>
   *
   * Does not initialize using defaultColumns because:
   * 1. Mapping over an empty array does nothing
   * 2. Even if Object.fromEntries were used for documentation purposes,
   * Typescript would complain because the type of `column` wouldn't align.
   */
  columns: {},

  // ==================================
  // 7. Rows
  // ==================================
  filteredAndSortedRowIds: [], // The size can change after filtering.
  rows: {}, // Map<rowId: number, row: RowObject>
  determineSelectableRow: dataTableDefaultProps.determineSelectableRow, // () => true, The row is selectable by default.

  serverSideFetchRowDataFn: dataTableDefaultProps.serverSideFetchRowDataFn, // async () => ({ rowData: [], totalCount: 0 })
  serverSideFetchRowDataArgs: {
    includeDeletedRows: true,
    pageIndex: 0,
    pageSize: -1,
    dataFilterQueryParams: [],
    columnFilterQueryParams: [],
    quickFilterQueryParams: [],
    searchText: '',
    sortByColumnId: '',
    sortOrder: '',
  },
  forceFetchRowDataCounter: 0,

  // ==================================
  // 8. Expanded Table Row
  // ==================================
  ExpandedTableRow: undefined,
  determineExpandableRow: dataTableDefaultProps.determineExpandableRow, // `() => true` row is expandable by default given ExpandableTableRow is used

  // ==================================
  // 9. Click Rows
  // ==================================
  onRowClickLeft: undefined,

  contextMenu: {
    enable: false,
    rowId: -1,
    columnId: '',
  },

  // ==================================
  // 10. Empty State
  // ==================================
  CustomEmptyStateComponent: undefined,

  // ==================================
  // 11. Utils: loader, awaitable modal, toast
  // ==================================
  externalLoader: { show: false, text: '' },
  loader: { show: false, text: '' },
  awaitableModalCounter: 0,
  awaitableModals: [],
  toastStack: [],
};

export function getDataTableInitialState<RowData>({ tableId }: { tableId: string }): DataTableState<RowData> {
  return { ...dataTableDefaultState, tableId } as DataTableState<RowData>;
}
