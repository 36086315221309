import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgReverseCheckSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.34998 14.9999C10.0788 15.0131 10.8033 14.8828 11.4817 14.6161C12.1601 14.3494 12.7793 13.9513 13.304 13.4452C13.8286 12.9391 14.2485 12.3348 14.5396 11.6664C14.8306 10.998 14.987 10.2786 15 9.54969C14.9764 8.06779 14.3703 6.65519 13.313 5.61659C12.2557 4.57799 10.8321 3.99707 9.34998 3.99989H1M1 3.99989L3.99 0.999878M1 3.99989L4 6.99988M2 10.9999L4 12.9999L8 8.99988"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgReverseCheckSmall.displayName = 'SvgReverseCheckSmall';
const ReverseCheckSmall = withForgeIconProps(SvgReverseCheckSmall, {
  name: 'ReverseCheck',
  usage: "This icon's guidance is pending",
  related: 'N/A',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default ReverseCheckSmall;
