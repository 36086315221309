import Survey from './Survey.gen';
import Standalone from './Standalone.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <Survey />
      <Standalone />
    </>
  );
}
