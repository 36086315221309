import React, { ForwardedRef, useImperativeHandle } from 'react';
import { DataTableRef } from '../DataTableRef';
import { DataTableStore } from '../store/stateTypes';

type ImperativeHandlerProps<RowData> = {
  store: DataTableStore<RowData>;
  ref?: ForwardedRef<DataTableRef<RowData>>;
};

export const ImperativeHandler = React.forwardRef(function ImperativeHandler<RowData>(
  props: Omit<ImperativeHandlerProps<RowData>, 'ref'>,
  ref: ImperativeHandlerProps<RowData>['ref']
): JSX.Element {
  const { store } = props;
  const dispatch = store.useDispatch();
  const dispatchAsyncAction = store.useDispatchAsyncAction();
  const state = store.useSelector((s) => s);

  // Imperative Functions
  useImperativeHandle(
    ref,
    (): DataTableRef<RowData> => {
      return {
        getState: () => state,
        dispatch,
        dispatchAsyncAction,
      };
    },
    [state, dispatch, dispatchAsyncAction]
  );

  return <></>;
}) as <RowData>(props: ImperativeHandlerProps<RowData>) => JSX.Element;
