import { useState } from 'react';
import PropTypes from 'prop-types';
import { forwardRef, HTMLAttributes, WeakValidationMap, ReactElement, Ref } from 'react';
import { forgeClassHelper } from '../utils/classes';
import { getSemanticColor } from '@athena/forge-shared';
const classes = forgeClassHelper({ name: 'avatar' });

/** Returns a deterministic avatar background color based on the first letter of the initial passed in. */
function getBgColor(initials: string | undefined): string {
  const firstChar = initials?.charAt(0);
  switch (firstChar?.toLowerCase()) {
    case 'a':
    case 'g':
    case 'm':
    case 's':
    case 'y':
      return getSemanticColor('secondary-purple-100');
    case 'b':
    case 'h':
    case 'n':
    case 't':
    case 'z':
      return getSemanticColor('secondary-citron-100');
    case 'c':
    case 'i':
    case 'o':
    case 'u':
      return getSemanticColor('secondary-coral-100');
    case 'd':
    case 'j':
    case 'p':
    case 'v':
      return getSemanticColor('secondary-jungle-100');
    case 'e':
    case 'k':
    case 'q':
    case 'w':
      return getSemanticColor('secondary-pistachio-100');
    case 'f':
    case 'l':
    case 'r':
    case 'x':
      return getSemanticColor('secondary-orchid-100');
    default:
      return getSemanticColor('interaction-disabled');
  }
}

interface AvatarBaseProps {
  /** CSS class to apply to the root div */
  className?: string;

  /** Alt tag to apply to the image  */
  imageAlt?: string;

  /** Image to display. Accepts either an image path or an actual imported image loaded via a tool like webpack. */
  image?: string;

  /** Initials to display if the image isn't available. The avatar's background color is automatically assigned based on the initial's first letter. */
  initials: string;

  /** Ref to the root div */
  forwardedRef?: Ref<HTMLDivElement>;

  /** Avatar size */
  size?: 'small' | 'medium' | 'large';

  /** Avatar shape */
  shape?: 'circle' | 'square';
}

export type AvatarComponentProps = AvatarBaseProps & HTMLAttributes<HTMLDivElement>;

/** Indicate the author of a piece of data/content, select between users, or show who has access. */
const AvatarComponent = ({
  imageAlt,
  image,
  initials,
  size = 'medium',
  shape = 'circle',
  className,
  style,
  forwardedRef,
  onClick,
  ...divProps
}: AvatarComponentProps): ReactElement => {
  const [isImgFailure, setIsImgFailure] = useState(false);
  const renderInitials = isImgFailure || !image;

  return (
    <div
      ref={forwardedRef}
      {...classes({ extra: className, modifiers: { [size]: true, [shape]: true, clickable: !!onClick } })}
      style={{
        backgroundColor: getBgColor(initials),
        ...style,
      }}
      onClick={onClick}
      {...divProps}
    >
      {/** Fallback to initials */}
      {renderInitials ? initials : <img src={image} alt={imageAlt} onError={() => setIsImgFailure(true)} />}
    </div>
  );
};
/** displayName must match the name of the component in order for the props
 * table to be documented in the Forge Guide.
 */
AvatarComponent.displayName = 'Avatar';

/** Using AvatarBaseProps instead of AvatarProps because
 * WeakValidationMap<AvatarProps> can't deal with the complexity behind
 * RequireOnlyOne.
 *
 * This decreases the accuracy of propTypes compared to Typescript types, but
 * that's an acceptable compromise.
 */
const avatarPropTypes: WeakValidationMap<AvatarBaseProps> = {
  /** CSS class to apply to the root div */
  className: PropTypes.string,

  /** Alt tag to apply to the image  */
  imageAlt: PropTypes.string,

  /** Image to display. Accepts either an image path or an actual imported image
   * loaded via a tool like webpack. */
  image: PropTypes.string,

  /** Initials to display if the image isn't available. The avatar's background
   * color is automatically assigned based on the initial's first letter. */
  initials: PropTypes.string.isRequired,

  /** Avatar size */
  size: PropTypes.oneOf(['small', 'medium', 'large']),

  /** Avatar shape */
  shape: PropTypes.oneOf(['circle', 'square']),
};

AvatarComponent.propTypes = avatarPropTypes;

/** The final AvatarProps that the default export uses.
 *
 * The `forwardedRef` prop is an internal-only implementation detail, so we
 * do not want it to be a valid prop to be used by clients of Avatar.
 */
export type AvatarProps = Omit<AvatarComponentProps, 'forwardedRef'>;

const AvatarController = (props: AvatarProps, ref: Ref<HTMLDivElement>): ReactElement => {
  return <AvatarComponent {...props} forwardedRef={ref} />;
};

AvatarController.displayName = 'Avatar';

export default forwardRef<HTMLDivElement, AvatarProps>(AvatarController);
