import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHospitalSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0H0V1V5.17993V15V16H1H12C13.0608 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0608 16 12V1V0H15H1ZM2 5.17993V2H14V12C14 12.5305 13.7893 13.0392 13.4142 13.4142C13.0392 13.7893 12.5305 14 12 14H2V5.17993ZM5 4V12H6.4004V8.4526H9.5996V12H11V4H9.5996V7.21713H6.4004V4H5Z"
      fill={props.color}
    />
  </svg>
);
SvgHospitalSmall.displayName = 'SvgHospitalSmall';
const HospitalSmall = withForgeIconProps(SvgHospitalSmall, {
  name: 'Hospital',
  usage: 'Use to refer to a hospital',
  related: 'clinic',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default HospitalSmall;
