import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNewLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#23A1BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.051 5.34665C10.9966 5.14225 10.8115 5 10.6 5C10.3885 5 10.2034 5.14225 10.149 5.34665L9.00575 9.64261C8.88545 10.0946 8.54734 10.4567 8.10459 10.6077L5.31609 11.5583C5.12707 11.6227 5 11.8003 5 12C5 12.1997 5.12707 12.3773 5.31609 12.4417L8.10459 13.3923C8.54734 13.5433 8.88545 13.9054 9.00575 14.3574L10.149 18.6533C10.2034 18.8577 10.3885 19 10.6 19C10.8115 19 10.9966 18.8577 11.051 18.6533L12.1942 14.3574C12.3145 13.9054 12.6527 13.5433 13.0954 13.3923L15.8839 12.4417C16.0729 12.3773 16.2 12.1997 16.2 12C16.2 11.8003 16.0729 11.6227 15.8839 11.5583L13.0954 10.6077C12.6527 10.4567 12.3145 10.0946 12.1942 9.6426L11.051 5.34665Z"
      fill="white"
    />
    <path
      d="M17.1333 5L16.76 6.86667L15.2667 7.33333L16.76 7.8L17.1333 9.66667L17.5067 7.8L19 7.33333L17.5067 6.86667L17.1333 5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.051 5.34665C10.9966 5.14225 10.8115 5 10.6 5C10.3885 5 10.2034 5.14225 10.149 5.34665L9.00575 9.64261C8.88545 10.0946 8.54734 10.4567 8.10459 10.6077L5.31609 11.5583C5.12707 11.6227 5 11.8003 5 12C5 12.1997 5.12707 12.3773 5.31609 12.4417L8.10459 13.3923C8.54734 13.5433 8.88545 13.9054 9.00575 14.3574L10.149 18.6533C10.2034 18.8577 10.3885 19 10.6 19C10.8115 19 10.9966 18.8577 11.051 18.6533L12.1942 14.3574C12.3145 13.9054 12.6527 13.5433 13.0954 13.3923L15.8839 12.4417C16.0729 12.3773 16.2 12.1997 16.2 12C16.2 11.8003 16.0729 11.6227 15.8839 11.5583L13.0954 10.6077C12.6527 10.4567 12.3145 10.0946 12.1942 9.6426L11.051 5.34665Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M17.1333 5L16.76 6.86667L15.2667 7.33333L16.76 7.8L17.1333 9.66667L17.5067 7.8L19 7.33333L17.5067 6.86667L17.1333 5Z"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);
SvgNewLarge.displayName = 'SvgNewLarge';
const NewLarge = withForgeIconProps(SvgNewLarge, {
  name: 'New',
  usage:
    'Use to indicate new content items or statuses. For use in alerts, not buttons; follow Forge interruption design guidance',
  related: 'alert, sparkle',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default NewLarge;
