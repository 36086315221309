import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgChatLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M23 14.91V2H1V17.87H5L7.56995 21.5699C7.6624 21.7 7.78461 21.806 7.92639 21.8792C8.06817 21.9523 8.22547 21.9905 8.38501 21.9905C8.54455 21.9905 8.70172 21.9523 8.84351 21.8792C8.98529 21.806 9.1075 21.7 9.19995 21.5699L12 17.84H20C20.7837 17.8402 21.5363 17.5338 22.0969 16.9862C22.6576 16.4386 22.9817 15.6935 23 14.91V14.91Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgChatLarge.displayName = 'SvgChatLarge';
const ChatLarge = withForgeIconProps(SvgChatLarge, {
  name: 'Chat',
  usage: 'Use to open a chat window or inbox, or to indicate messages',
  related: 'message, conversation, notification, mail, chatting',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ChatLarge;
