import rawContents from "!!raw-loader!./DefaultSizing.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function DefaultSizing():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Default Sizing" />;
}

export default DefaultSizing;
