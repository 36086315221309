import { withPrefix } from 'gatsby';

import rawContents from "!!raw-loader!./CardsWithImages.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { withPrefix };

function CardsWithImages():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Cards With Images" />;
}

export default CardsWithImages;
