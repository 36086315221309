import Accordion from '../../../static/svg/accordion.svg';
import Avatar from '../../../static/svg/avatar.svg';
import Badge from '../../../static/svg/badge.svg';
import Banner from '../../../static/svg/banner.svg';
import Button from '../../../static/svg/button.svg';
import Card from '../../../static/svg/card.svg';
import Checkbox from '../../../static/svg/checkbox.svg';
import DataTable from '../../../static/svg/DataTable.svg';
import DateInput from '../../../static/svg/dateinput.svg';
import DateRangeInput from '../../../static/svg/DateRangeInput.svg';
import EmptyState from '../../../static/svg/emptystate.svg';
import FileUpload from '../../../static/svg/fileupload.svg';
import Form from '../../../static/svg/form.svg';
import FormError from '../../../static/svg/formerror.svg';
import FormField from '../../../static/svg/formfield.svg';
import Gift from '../../../static/svg/gift.svg';
import GiftLandingCard from '../../../static/svg/giftLandingCard.svg';
import Grid from '../../../static/svg/grid.svg';
import GroupedBarChart from '../../../static/svg/groupedbarchart.svg';
import Heading from '../../../static/svg/heading.svg';
import Hidden from '../../../static/svg/hidden.svg';
import HighVolumeMultiSelect from '../../../static/svg/highvolumemultiselect.svg';
import Indicator from '../../../static/svg/indicator.svg';
import InlineAlert from '../../../static/svg/inlinealert.svg';
import Input from '../../../static/svg/input.svg';
import LineChart from '../../../static/svg/linechart.svg';
import List from '../../../static/svg/list.svg';
import Loader from '../../../static/svg/loader.svg';
import Menu from '../../../static/svg/menu.svg';
import Modal from '../../../static/svg/modal.svg';
import MultiField from '../../../static/svg/multifield.svg';
import NumericRangeInput from '../../../static/svg/NumericRangeInput.svg';
import Paginator from '../../../static/svg/paginator.svg';
import Popover from '../../../static/svg/popover.svg';
import ProgressIndicator from '../../../static/svg/progressindicator.svg';
import PushDrawer from '../../../static/svg/pushdrawer.svg';
import RadioGroup from '../../../static/svg/radiogroup.svg';
import ReadOnlyInput from '../../../static/svg/readonlyinput.svg';
import { default as Portal, default as Root } from '../../../static/svg/root.svg';
import SegmentedButton from '../../../static/svg/segmentedbutton.svg';
import Select from '../../../static/svg/select.svg';
import ShowHide from '../../../static/svg/showhide.svg';
import Signpost from '../../../static/svg/signpost.svg';
import SingleBarChart from '../../../static/svg/singlebarchart.svg';
import Skeleton from '../../../static/svg/skeleton-loader.svg';
import Stepper from '../../../static/svg/stepper.svg';
import Tabs from '../../../static/svg/tabs.svg';
import Tag from '../../../static/svg/tag.svg';
import Textarea from '../../../static/svg/textarea.svg';
import TimeInput from '../../../static/svg/timeinput.svg';
import Toast from '../../../static/svg/toast.svg';
import ToggleSwitch from '../../../static/svg/toggleswitch.svg';
import Tooltip from '../../../static/svg/tooltip.svg';
import Tree from '../../../static/svg/tree.svg';

export const componentImages = {
  // add svgs here
  Accordion: <Accordion />,
  Avatar: <Avatar />,
  Badge: <Badge />,
  Banner: <Banner />,
  Button: <Button />,
  Card: <Card />,
  Checkbox: <Checkbox />,
  DataTable: <DataTable />,
  DateInput: <DateInput />,
  DateRangeInput: <DateRangeInput />,
  EmptyState: <EmptyState />,
  FileUpload: <FileUpload />,
  Form: <Form />,
  FormError: <FormError />,
  FormField: <FormField />,
  Grid: <Grid />,
  GroupedBarChart: <GroupedBarChart />,
  ContributedComponent: <Gift />,
  ContributedComponentLanding: <GiftLandingCard />,
  Heading: <Heading />,
  Hidden: <Hidden />,
  HighVolumeMultiSelect: <HighVolumeMultiSelect />,
  Indicator: <Indicator />,
  InlineAlert: <InlineAlert />,
  Input: <Input />,
  LineChart: <LineChart />,
  List: <List />,
  Loader: <Loader />,
  Menu: <Menu />,
  Modal: <Modal />,
  MultiField: <MultiField />,
  NumericRangeInput: <NumericRangeInput />,
  Paginator: <Paginator />,
  Popover: <Popover />,
  Portal: <Portal />,
  ProgressIndicator: <ProgressIndicator />,
  PushDrawer: <PushDrawer />,
  RadioGroup: <RadioGroup />,
  ReadOnlyInput: <ReadOnlyInput />,
  Root: <Root />,
  SegmentedButton: <SegmentedButton />,
  Select: <Select />,
  ShowHide: <ShowHide />,
  Signpost: <Signpost />,
  SingleBarChart: <SingleBarChart />,
  Skeleton: <Skeleton />,
  Stepper: <Stepper />,
  Tabs: <Tabs />,
  Tag: <Tag />,
  Textarea: <Textarea />,
  TimeInput: <TimeInput />,
  Toast: <Toast />,
  ToggleSwitch: <ToggleSwitch />,
  Tooltip: <Tooltip />,
  Tree: <Tree />,
} as const;

export type ComponentImages = typeof componentImages;
