module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-amplitude-analytics-virtual-bf3378e43b/0/cache/gatsby-plugin-amplitude-analytics-npm-3.2.0-ec7f653980-f81e435db1.zip/node_modules/gatsby-plugin-amplitude-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"589d93a697229e6b62d50cd72cb2ef0b","head":true,"respectDNT":false,"amplitudeConfig":{"saveEvents":true,"includeUtm":true,"includeReferrer":true},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-2e43ee4bc4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
