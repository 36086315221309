import rawContents from "!!raw-loader!./MultiFieldBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function MultiFieldBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Multi Field Basic" />;
}

export default MultiFieldBasic;
