import FormLayout from './FormLayout.gen';
import BasicForm from './BasicForm.gen';
import FormValidation from './FormValidation.gen';
import RequiredVariations from './RequiredVariations.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <FormLayout />
      <BasicForm />
      <FormValidation />
      <RequiredVariations />
    </>
  );
}
