import rawContents from "!!raw-loader!./ToggleSwitchStructuredSig.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ToggleSwitchStructuredSig():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Toggle Switch Structured Sig" />;
}

export default ToggleSwitchStructuredSig;
