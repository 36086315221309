import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHelpSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#9C28B1"
    />
    <path
      d="M6 5.87995C6.013 5.70985 6.04655 5.54196 6.09998 5.37995C6.21025 5.05844 6.39883 4.76945 6.64856 4.53888C6.89829 4.30832 7.20137 4.14345 7.53064 4.05915C7.85991 3.97485 8.20498 3.97379 8.53479 4.05597C8.8646 4.13816 9.16883 4.30114 9.42004 4.53009C9.59571 4.70316 9.7351 4.90931 9.83032 5.13678C9.92554 5.36425 9.97461 5.60845 9.97461 5.85505C9.97461 6.10164 9.92554 6.34584 9.83032 6.57331C9.7351 6.80078 9.59571 7.00693 9.42004 7.18C9.13004 7.47 8.76004 7.65999 8.42004 7.91999C8.25282 8.04363 8.11936 8.20733 8.03198 8.39606C7.94461 8.58479 7.90613 8.79256 7.92004 9.00006"
      stroke="white"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
      fill="white"
    />
  </svg>
);
SvgHelpSmall.displayName = 'SvgHelpSmall';
const HelpSmall = withForgeIconProps(SvgHelpSmall, {
  name: 'Help',
  usage:
    'Use to indicate presence of a Tooltip or Popover with helpful information. For use in alerts, not buttons; follow Forge interruption design guidance',
  related: 'alert, question, guide, tutorial',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default HelpSmall;
