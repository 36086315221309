import { nanoid } from 'nanoid';
import { ReactNode, useState } from 'react';
import List from '../../List';
import { forgeClassHelper } from '../../utils/classes';
import { FileUploadAction, FileUploadFile } from '../fileUploadReducer';
import FileUploadCompleted from './FileUploadCompleted';
import FileUploadDeleted from './FileUploadDeleted';

const classes = forgeClassHelper('file-upload');

export interface FileUploadUploadedProps {
  dispatch: React.Dispatch<FileUploadAction>;
  files: ReadonlyArray<FileUploadFile>;
}

/** Renders a list of uploaded files, either completed or deleted. */
const FileUploadUploaded = ({ dispatch, files }: FileUploadUploadedProps): ReactNode => {
  const [listId] = useState(() => nanoid());
  return (
    files.length > 0 && (
      <div {...classes({ element: 'upload-section' })}>
        <label htmlFor={listId}>Uploaded</label>
        <List {...classes({ element: 'uploaded-list' })} id={listId}>
          {files.map((f) =>
            f.isCompleted ? (
              <FileUploadCompleted key={f.id} dispatch={dispatch} file={f} />
            ) : (
              <FileUploadDeleted key={f.id} dispatch={dispatch} file={f} />
            )
          )}
        </List>
      </div>
    )
  );
};

export default FileUploadUploaded;
