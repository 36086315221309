import SimpleIcon from './SimpleIcon.gen';
import IconInButton from './IconInButton.gen';
import SemanticColor from './semanticColor.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <SimpleIcon />
      <IconInButton />
      <SemanticColor />
    </>
  );
}
