import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFullScreenSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.00085 -0.000366211H0.000854492V0.999634V4.99963H2.00085V1.99963H5.00085V-0.000366211H1.00085ZM0.000854492 10.9996V14.9996V15.9996H1.00085H5.00085V13.9996H2.00085V10.9996H0.000854492ZM14.0009 1.99963H11.0009V-0.000366211H15.0009H16.0009V0.999634V4.99963H14.0009V1.99963ZM14.0009 10.9996V13.9996H11.0009V15.9996H15.0009H16.0009V14.9996V10.9996H14.0009Z"
      fill={props.color}
    />
  </svg>
);
SvgFullScreenSmall.displayName = 'SvgFullScreenSmall';
const FullScreenSmall = withForgeIconProps(SvgFullScreenSmall, {
  name: 'FullScreen',
  usage: 'Use to change the current view to full screen',
  related: 'Expand, screenshot, capture',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default FullScreenSmall;
