import { withPrefix } from 'gatsby';

import rawContents from "!!raw-loader!./AvatarBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { withPrefix };

function AvatarBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Avatar Basic" />;
}

export default AvatarBasic;
