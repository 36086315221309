import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInsertLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 1V0H0V1H1ZM23 1H24V0H23V1ZM1 23H0V24H1V23ZM10.7071 4.29289C10.3166 3.90237 9.68342 3.90237 9.29289 4.29289C8.90237 4.68342 8.90237 5.31658 9.29289 5.70711L10.7071 4.29289ZM17 12L17.7071 12.7071C18.0976 12.3166 18.0976 11.6834 17.7071 11.2929L17 12ZM9.29289 18.2929C8.90237 18.6834 8.90237 19.3166 9.29289 19.7071C9.68342 20.0976 10.3166 20.0976 10.7071 19.7071L9.29289 18.2929ZM1 11C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13V11ZM2 7.58008V1H0V7.58008H2ZM1 2H23V0H1V2ZM22 1V20H24V1H22ZM22 20C22 20.5305 21.7893 21.0392 21.4142 21.4142L22.8284 22.8284C23.5786 22.0783 24 21.0608 24 20H22ZM21.4142 21.4142C21.0392 21.7893 20.5305 22 20 22V24C21.0608 24 22.0783 23.5786 22.8284 22.8284L21.4142 21.4142ZM20 22H1V24H20V22ZM2 23V16.4299H0V23H2ZM9.29289 5.70711L16.2929 12.7071L17.7071 11.2929L10.7071 4.29289L9.29289 5.70711ZM16.2929 11.2929L9.29289 18.2929L10.7071 19.7071L17.7071 12.7071L16.2929 11.2929ZM1 13H17V11H1V13Z"
      fill={props.color}
    />
  </svg>
);
SvgInsertLarge.displayName = 'SvgInsertLarge';
const InsertLarge = withForgeIconProps(SvgInsertLarge, {
  name: 'Insert',
  usage: 'Use to insert a file or data',
  related: 'add',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default InsertLarge;
