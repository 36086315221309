import InfoIcon from './InfoIcon.gen';
import Link from './Link.gen';
import Button from './Button.gen';
import WrappingChildren from './WrappingChildren.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <InfoIcon />
      <Link />
      <Button />
      <WrappingChildren />
    </>
  );
}
