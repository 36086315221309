import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBoldSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.5 16V0H7.46471C8.33137 0 9.14706 0.0656411 9.91177 0.196923C10.6765 0.311795 11.3392 0.516923 11.9 0.812307C12.4608 1.10769 12.9026 1.51795 13.2255 2.04308C13.5654 2.5518 13.7353 3.2 13.7353 3.98769C13.7353 4.33231 13.6843 4.68513 13.5824 5.04615C13.4804 5.39077 13.3359 5.72718 13.149 6.05539C12.9791 6.36718 12.7667 6.64615 12.5118 6.89231C12.2569 7.13846 11.968 7.31897 11.6451 7.43385V7.53231C12.4438 7.76205 13.115 8.18051 13.6588 8.78769C14.2196 9.37846 14.5 10.2072 14.5 11.2738C14.5 12.0944 14.3216 12.8082 13.9647 13.4154C13.6248 14.0062 13.1575 14.4985 12.5627 14.8923C11.985 15.2697 11.2967 15.5487 10.498 15.7292C9.69935 15.9097 8.85817 16 7.97451 16H1.5ZM5.88431 6.25231H7.36275C8.07647 6.25231 8.60327 6.10462 8.94314 5.80923C9.28301 5.51385 9.45294 5.12 9.45294 4.62769C9.45294 4.13539 9.28301 3.78256 8.94314 3.56923C8.60327 3.3559 8.07647 3.24923 7.36275 3.24923H5.88431V6.25231ZM5.88431 12.7508H7.66863C9.36797 12.7508 10.2176 12.16 10.2176 10.9785C10.2176 10.3877 10.0052 9.96923 9.58039 9.72308C9.17255 9.47692 8.53529 9.35385 7.66863 9.35385H5.88431V12.7508Z"
      fill={props.color}
    />
  </svg>
);
SvgBoldSmall.displayName = 'SvgBoldSmall';
const BoldSmall = withForgeIconProps(SvgBoldSmall, {
  name: 'Bold',
  usage:
    'Use in a ComboButton or SegmentedButton to add or remove bold text formatting',
  related: 'edit, font, emphasis, strong, italic, underline, text editor',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default BoldSmall;
