import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAttachmentLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.7117 8.87279L9.97575 12.6814L6.73055 15.9787C6.38124 16.3505 6.18683 16.8417 6.18683 17.3519C6.18683 17.8622 6.38124 18.3533 6.73055 18.725C7.08752 19.0716 7.56528 19.2653 8.06265 19.2653C8.56001 19.2653 9.0379 19.0716 9.39486 18.725L16.4061 11.5288L20.4126 7.51978C21.1104 6.79194 21.5 5.82239 21.5 4.81374C21.5 3.8051 21.1104 2.83543 20.4126 2.10759C20.066 1.75698 19.6534 1.4787 19.1987 1.28873C18.7439 1.09877 18.2561 1.00098 17.7633 1.00098C17.2705 1.00098 16.7826 1.09877 16.3279 1.28873C15.8731 1.4787 15.4605 1.75698 15.114 2.10759L12.4798 4.79368L4.10634 13.3229C3.0753 14.4026 2.5 15.8385 2.5 17.3319C2.5 18.8253 3.0753 20.2612 4.10634 21.3409C4.62361 21.8666 5.2402 22.2841 5.92029 22.5691C6.60038 22.8541 7.33034 23.001 8.06766 23.001C8.80498 23.001 9.53494 22.8541 10.215 22.5691C10.8951 22.2841 11.5118 21.8666 12.0291 21.3409L19.1305 14.1045"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgAttachmentLarge.displayName = 'SvgAttachmentLarge';
const AttachmentLarge = withForgeIconProps(SvgAttachmentLarge, {
  name: 'Attachment',
  usage: 'Use to indicate or add an attachment',
  related: 'attach, paperclip, clip, file',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default AttachmentLarge;
