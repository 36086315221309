import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFullScreenLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.00085 0H0.000854492V1V7H2.00085V2H7.00085V0H1.00085ZM0.000854492 16.9999V22.9994V23.9994H1.00085H7.00085V21.9994H2.00085V16.9999H0.000854492ZM22.0003 2H17.0009V0H23.0003H24.0003V1V7H22.0003V2ZM22.0003 16.9999V21.9994H17.0009V23.9994H23.0003H24.0003V22.9994V16.9999H22.0003Z"
      fill={props.color}
    />
  </svg>
);
SvgFullScreenLarge.displayName = 'SvgFullScreenLarge';
const FullScreenLarge = withForgeIconProps(SvgFullScreenLarge, {
  name: 'FullScreen',
  usage: 'Use to change the current view to full screen',
  related: 'Expand, screenshot, capture',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default FullScreenLarge;
