import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVideoOnSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 5.74027C14.9916 5.59704 14.9438 5.4587 14.8619 5.34086C14.7801 5.22302 14.6673 5.13019 14.536 5.0723C14.4047 5.01441 14.26 4.99369 14.1178 5.01273C13.9756 5.03177 13.8414 5.08983 13.73 5.18021L12.01 6.79008V9.18998L13.73 10.7998C13.8414 10.8902 13.9756 10.9483 14.1178 10.9673C14.26 10.9864 14.4047 10.9656 14.536 10.9078C14.6673 10.8499 14.7801 10.757 14.8619 10.6392C14.9438 10.5214 14.9916 10.3835 15 10.2403V5.74027Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M1 3H12V10C12 10.7956 11.6839 11.5585 11.1213 12.1211C10.5587 12.6837 9.79565 13 9 13H1V3Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgVideoOnSmall.displayName = 'SvgVideoOnSmall';
const VideoOnSmall = withForgeIconProps(SvgVideoOnSmall, {
  name: 'VideoOn',
  usage: 'Use to indicate enabled video, or to turn off video',
  related: 'video, camera',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default VideoOnSmall;
