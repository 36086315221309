import { ReactElement } from 'react';
import parse from 'html-react-parser';

import './card.scss';
import { Link } from 'gatsby';
import { defaultOptions } from '../../../html-parsing-options';
import { componentImages } from '../../page-context-provider/component-images';

type Props = {
  field_card_body: string;
  field_card_link: string;
  field_component_preview: string;
};

const Card = ({ field_card_body, field_card_link, field_component_preview }: Props): ReactElement => {
  // splits the link into 3: ["", "components", "name of component"]
  // use shift() to kick out empty component
  const component = field_card_link.split('/');
  component.shift();
  const description = parse(field_card_body, defaultOptions()) as JSX.Element[];

  if (!component) {
    console.error('No component detected in field card link');
    return <></>;
  } else {
    return (
      <Link to={field_card_link} className="card__container">
        {component[0] === 'components' && (
          <>
            {Object.prototype.hasOwnProperty.call(componentImages, field_component_preview) ? (
              <div className={'card__image'}>
                {componentImages[field_component_preview as keyof typeof componentImages]}
                <div className={'card__image-name'}>{field_component_preview}</div>
              </div>
            ) : (
              <div className={'card__image-contributed'}>
                {componentImages.ContributedComponentLanding}
                <div className={'card__image-name'}>{field_component_preview}</div>
              </div>
            )}
          </>
        )}
        {description && description.length > 0 && <div className="card__description">{description}</div>}
      </Link>
    );
  }
};

export default Card;
