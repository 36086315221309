import { DataTableState } from '../stateTypes';
import { reset_paginator } from './reset_paginator';

export function apply_client_side_filters<RowData>(s: DataTableState<RowData>): void {
  // Only apply for client-side DataTable
  if (s.tableType !== 'client-side') return;

  // Get dataFilters, columnFilters, textSearchers
  const dataFilters = Object.values(s.dataFilter.filters);

  const columnFilters = s.columnIds // prettier-break-line
    .map((cid) => s.columns[cid].filter)
    .filter((cf) => cf.enable);

  const textSearchers = s.columnIds
    .map((cid) => s.columns[cid].clientSideFullTextSearch)
    .filter((searchOption) => searchOption.enable);

  // Filter rows
  const nextRowIds = Object.values(s.rows)
    // ================================
    // 1. Filter Deleted Rows
    // ================================
    .filter((row) => {
      // display both deleted and non-deleted rows if deleted row checkbox is checked.
      if (s.deletedRowsCheckbox.checked) {
        return true;
      }

      // return true only if the row is not deleted
      return row.rowStatus !== 'deleted';
    })

    // ================================
    // 2. Quick Filters
    // ================================
    .filter((row) => {
      // Don't apply if quick filter is disabled or no selected values
      if (s.quickFilter.layout === 'none') return true;
      if (s.quickFilter.selectedOptionIds.length === 0) return true;

      const selectedOptionIdSet = new Set(s.quickFilter.selectedOptionIds);
      return s.quickFilter.options
        .filter((opt) => selectedOptionIdSet.has(opt.id))
        .some((opt) => {
          if (typeof opt.clientSideFilterFn === 'undefined') {
            throw new Error('Missing clientSideFilterFn for client-side quick filter filtering');
          }
          return opt.clientSideFilterFn(row.data);
        });
    })

    // ================================
    // 3. Data Filters
    // ================================
    .filter((row) => {
      // Don't apply if data filter panel is disabled
      if (!s.dataFilter.show) return true;

      return dataFilters.every((df) => {
        // Don't apply filter if no condition values
        if (df.originalConditionValues.length === 0) return true;
        if (typeof df.clientSideFilterFn === 'undefined') {
          throw new Error('Missing clientSideFilterFn for client-side data filtering');
        }

        return df.clientSideFilterFn({
          rowData: row.data,
          conditionValues: df.originalConditionValues,
        });
      });
    })

    // ================================
    // 4. Column Filters
    // ================================
    .filter((row) => {
      // Don't apply if column filter is disabled
      if (!s.showColumnFilters) return true;

      return columnFilters.every((cf) => {
        // Don't apply filter if no condition values
        if (cf.conditionValues.length === 0) return true;
        if (typeof cf.clientSideColumnFilterFn === 'undefined') {
          throw new Error('Missing clientSideFilterFn for client-side column filtering');
        }

        return cf.clientSideColumnFilterFn({
          rowData: row.data,
          conditionValues: cf.conditionValues,
        });
      });
    })

    // ================================
    // 5. Search by text
    // ================================
    .filter((row) => {
      // Don't apply if search box is disabled or inputText is empty
      if (!s.searchBox.show) return true;
      if (s.searchBox.inputText === '') return true;

      return textSearchers.some(({ getTextByRow }) => {
        // Convert to lower case to make DataTable searchBox text case-insensitive
        const displayCellText = getTextByRow(row.data).toLocaleLowerCase();
        return displayCellText.includes(s.searchBox.inputText.toLocaleLowerCase());
      });
    })

    // ================================
    // 6. Convert to row ids
    // ================================
    .map((row) => row.rowId);

  // Only update the reference if the row ids is changed.
  // This can prevent the unnecessary component rendering.
  if (JSON.stringify(nextRowIds.join('-')) !== JSON.stringify(s.filteredAndSortedRowIds.join('-'))) {
    s.filteredAndSortedRowIds = nextRowIds;

    // reset paginator when filtered rows changed
    reset_paginator(s);
  }
}
