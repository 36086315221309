import { AlertData, DataTableHooksAndRowId, ModalAlertData, ToastAlertData } from '../utils/internalTypes';
import Button from '../../Button';
import { AwaitableModal } from '../alerts/AwaitableModal';

type CreateSaveCancelRowActionButtonArgs<RowData> = {
  serverSideSaveRowFn?: (rowData: RowData) => Promise<void>;
  onRowSaved?: (rowData: RowData) => void;
  saveAlerts?: {
    confirmModal?: (rowData: RowData) => AlertData;
    completeModal?: (rowData: RowData) => ModalAlertData;
    completeToast?: (rowData: RowData) => ToastAlertData;
  };
  cancelAlerts?: {
    confirmModal?: (rowData: RowData) => AlertData;
    completeModal?: (rowData: RowData) => ModalAlertData;
    completeToast?: (rowData: RowData) => ToastAlertData;
  };
};
export type CreateSaveCancelRowActionButton<RowData> = typeof createSaveCancelRowActionButton<RowData>;

const DEFAULT_EMPTY_ARGS = { saveAlerts: {}, cancelAlerts: {} };
export function createSaveCancelRowActionButton<RowData>({
  serverSideSaveRowFn,
  onRowSaved,
  saveAlerts: nullableSaveAlerts,
  cancelAlerts: nullableCancelAlerts,
}: CreateSaveCancelRowActionButtonArgs<RowData> = DEFAULT_EMPTY_ARGS) {
  return function SaveCancelRowActionButton({
    useDispatch,
    useSelector,
    rowId,
  }: DataTableHooksAndRowId<RowData>): JSX.Element {
    const saveAlerts = nullableSaveAlerts || {};
    const cancelAlerts = nullableCancelAlerts || {};
    const dispatch = useDispatch();
    const tableType = useSelector((s) => s.tableType);
    const rowData = useSelector((s) => s.rows[rowId].data);
    const hasError = useSelector((s) => Object.values(s.rows[rowId].cellErrors).some((msg) => !!msg));
    const hasEdits = useSelector((s) => {
      const { originalData, data } = s.rows[rowId];
      return JSON.stringify(originalData) !== JSON.stringify(data);
    });

    return (
      <>
        {/* Save Button */}
        <Button
          data-row-action-name="save-edit-row"
          text="Save"
          size="medium"
          variant="primary"
          /**
           * Note:
           * It won't cause any problem to display both the 'Edit Table Button' and the 'Save Row Action Button' on UI.
           * Users can utilize either button to save the row.
           * If user save row using the 'Save Row Action Button', the 'Edit Table Button' won't save it again,
           * but only save the remaining edited rows.
           */
          disabled={hasError || !hasEdits}
          onClick={async (event) => {
            // prevent trigger left click on row
            event.stopPropagation();

            const {
              confirmModal: getConfirmModalData,
              completeModal: getCompleteModalData,
              completeToast: getCompleteToastData,
            } = saveAlerts;

            // ======================
            // Confirm Modal
            // ======================
            if (typeof getConfirmModalData !== 'undefined') {
              const { title, message } = getConfirmModalData(rowData);
              const result = await AwaitableModal(dispatch, {
                alertType: 'attention',
                headerText: title,
                children: message,
              });
              if (result === 'secondary') return;
            }

            dispatch(function before_saving_row(s) {
              s.loader.show = true;
            });

            // ======================
            // Save Row Data Action
            // ======================
            try {
              if (tableType === 'server-side') {
                if (typeof serverSideSaveRowFn === 'undefined') {
                  throw new Error('Missing onSave for server-side row modification.');
                }
                await serverSideSaveRowFn(rowData);
                dispatch(function after_server_side_saving_row_success(s) {
                  s.loader.show = false;
                  s.forceFetchRowDataCounter++; // force refreshing row data
                });
              } else if (tableType === 'client-side') {
                dispatch(function client_side_save_row(s) {
                  s.loader.show = false;
                  s.rows[rowId].originalData = s.rows[rowId].data;
                  s.rows[rowId].rowStatus = 'view';
                });
              }

              // invoke onRowSaved callback when save completed
              onRowSaved?.(rowData);

              // ======================
              // Complete Modal
              // ======================
              if (typeof getCompleteModalData !== 'undefined') {
                const { alertType, title, message } = getCompleteModalData(rowData);
                await AwaitableModal(dispatch, { alertType, headerText: title, children: message });
              }

              // ======================
              // Complete Toast
              // ======================
              if (typeof getCompleteToastData !== 'undefined') {
                dispatch(function push_toast_stack(s) {
                  const { alertType, title, message } = getCompleteToastData(rowData);
                  s.toastStack.push({ alertType, headerText: title, children: message });
                });
              }
            } catch (e) {
              dispatch(function after_server_side_save_row_failure(s) {
                s.loader.show = false;
              });

              // ========================
              // Error Modal
              // ========================
              await AwaitableModal(dispatch, {
                alertType: 'attention',
                headerText: 'Save Row Failure',
                children: (e as Error).message,
              });
            }
          }}
        />

        {/* Cancel Button */}
        <Button
          data-row-action-name="cancel-edit-row"
          text="Cancel"
          size="medium"
          variant="secondary"
          onClick={async (event) => {
            // prevent trigger left click on row
            event.stopPropagation();

            const {
              confirmModal: getConfirmModalData,
              completeModal: getCompleteModalData,
              completeToast: getCompleteToastData,
            } = cancelAlerts;

            // ======================
            // Confirm Modal
            // ======================
            if (hasEdits && typeof getConfirmModalData !== 'undefined') {
              const { title, message } = getConfirmModalData(rowData);
              const result = await AwaitableModal(dispatch, {
                alertType: 'attention',
                headerText: title,
                children: message,
              });
              if (result === 'secondary') return;
            }

            // ======================
            // Cancel Edit Row Data Action
            // ======================
            dispatch(function cancel_edit_row_data(s) {
              // Reset cell errors
              for (const columnId in s.rows[rowId].cellErrors) {
                s.rows[rowId].cellErrors[columnId] = '';
              }
              s.rows[rowId].data = s.rows[rowId].originalData;
              s.rows[rowId].rowStatus = 'view';
            });

            // ======================
            // Complete Modal
            // ======================
            if (typeof getCompleteModalData !== 'undefined') {
              const { alertType, title, message } = getCompleteModalData(rowData);
              await AwaitableModal(dispatch, { alertType, headerText: title, children: message });
            }

            // ======================
            // Complete Toast
            // ======================
            if (typeof getCompleteToastData !== 'undefined') {
              dispatch(function push_toast_stack(s) {
                const { alertType, title, message } = getCompleteToastData(rowData);
                s.toastStack.push({ alertType, headerText: title, children: message });
              });
            }
          }}
        />
      </>
    );
  };
}
