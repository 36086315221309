import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAttentionSimpleSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 1V10"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M8 16C8.82843 16 9.5 15.3284 9.5 14.5C9.5 13.6716 8.82843 13 8 13C7.17157 13 6.5 13.6716 6.5 14.5C6.5 15.3284 7.17157 16 8 16Z"
      fill={props.color}
    />
  </svg>
);
SvgAttentionSimpleSmall.displayName = 'SvgAttentionSimpleSmall';
const AttentionSimpleSmall = withForgeIconProps(SvgAttentionSimpleSmall, {
  name: 'AttentionSimple',
  usage:
    'Use in compact spaces, like mobile, to call attention to an issue or error',
  related: 'alert, warning',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default AttentionSimpleSmall;
