import CalendarSmall from '@athena/forge-icons/dist/CalendarSmall';
import MailSmall from '@athena/forge-icons/dist/MailSmall';
import ProfileSmall from '@athena/forge-icons/dist/ProfileSmall';

import rawContents from "!!raw-loader!./Controlled.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { CalendarSmall, MailSmall, ProfileSmall };

function Controlled():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Controlled" />;
}

export default Controlled;
