import { useEffect } from 'react';
import { DataTableDispatch } from '../store/stateTypes';
import { DataTableProps } from '../DataTable';

type DataFilterPropReceiverProps<RowData> = {
  dispatch: DataTableDispatch<RowData>;
  filters: Exclude<DataTableProps<RowData>['filters'], undefined>;
};

export function DataFilterPropReceiver<RowData>({
  dispatch, // prettier-break-line
  filters,
}: DataFilterPropReceiverProps<RowData>): JSX.Element {
  // check filter ids
  const filterSet = new Set<string>();
  for (const { filterId } of filters) {
    if (filterSet.has(filterId)) {
      throw new Error(`filterId should be unique, but '${filterId}' is duplicated.`);
    }
    filterSet.add(filterId);
  }

  useEffect(() => {
    dispatch(function update_filters(s) {
      s.dataFilter.show = filters.length > 0;
      s.dataFilter.filterIds = filters.map((df) => df.filterId);
      s.dataFilter.filters = {};

      for (const df of filters) {
        s.dataFilter.filters[df.filterId] = {
          ...df,
          conditionValues: [],
          originalConditionValues: [],
        };
      }
    });
  }, [dispatch, filters]);

  return <></>;
}
