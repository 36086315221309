import { DataTableStore } from '../../store/stateTypes';
import Button from '../../../Button';
import EditSmall from '@athena/forge-icons/dist/EditSmall';
import { classes } from '../../utils/dataTableClasses';
import { getPageRowIdsFromState } from '../../utils/getPageRowIds';
import { AwaitableModal } from '../../alerts/AwaitableModal';

// ====================================
// Actions
// ====================================
import { inline_edit_row } from '../../store/actions/inline_edit_row';

type EditTableButtonProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function EditTableButton<RowData>({ store }: EditTableButtonProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();

  // ==================================
  // Edit Table Button
  // ==================================
  const {
    show,
    showSaveAndCancelButtons,
    serverSideSaveMultipleRowsFn,
    onRowsSaved,
    saveAlerts: nullableSaveAlerts,
    cancelAlerts: nullableCancelAlerts,
  } = store.useSelector((s) => s.editTableButton);
  const saveAlerts = nullableSaveAlerts || {};
  const cancelAlerts = nullableCancelAlerts || {};

  // ==================================
  // Get Valid Edit Rows Data
  // ==================================
  const tableType = store.useSelector((s) => s.tableType);
  const rows = store.useSelector((s) => s.rows);
  const filteredAndSortedRowIds = store.useSelector((s) => s.filteredAndSortedRowIds);
  const editRows = filteredAndSortedRowIds
    .map((rid) => rows[rid])
    .filter(({ rowStatus, originalData, data }) => {
      return rowStatus === 'edit' && JSON.stringify(originalData) !== JSON.stringify(data);
    });
  const validEditRowData = editRows
    .filter(({ cellErrors }) => Object.values(cellErrors).every((msg) => !msg))
    .map((row) => row.data);
  const hasErrors = editRows.length !== validEditRowData.length;

  if (!show) return <></>;

  return (
    <div {...classes({ element: 'edit_table_buttons' })}>
      {/* Edit Data Button */}
      {!showSaveAndCancelButtons && (
        <Button
          icon={EditSmall}
          text="Edit Data"
          variant="tertiary"
          onClick={() => {
            dispatch(function change_rows_edit_status(s) {
              s.editTableButton.showSaveAndCancelButtons = true;

              getPageRowIdsFromState(s).forEach((rowId) => {
                inline_edit_row<RowData>(rowId)(s);
              });
            });
          }}
        />
      )}

      {/* Save Edits Button */}
      {showSaveAndCancelButtons && (
        <Button
          icon={EditSmall}
          text="Save Edits"
          variant="primary"
          disabled={hasErrors || validEditRowData.length === 0}
          onClick={async () => {
            const {
              confirmModal: getConfirmModalData,
              completeModal: getCompleteModalData,
              completeToast: getCompleteToastData,
            } = saveAlerts;

            // ======================
            // Confirm Modal
            // ======================
            if (typeof getConfirmModalData !== 'undefined') {
              const { title, message } = getConfirmModalData(validEditRowData);
              const result = await AwaitableModal(dispatch, {
                alertType: 'attention',
                headerText: title,
                children: message,
              });
              if (result === 'secondary') return;
            }

            dispatch(function before_saving_rows(s) {
              s.loader.show = true;
            });

            // ======================
            // Save Rows Data Action
            // ======================
            try {
              if (tableType === 'server-side') {
                if (typeof serverSideSaveMultipleRowsFn === 'undefined') {
                  throw new Error('Missing onSave for server-side row modification.');
                }
                await serverSideSaveMultipleRowsFn(validEditRowData);

                dispatch(function after_server_side_saving_rows_success(s) {
                  s.loader.show = false;
                  s.editTableButton.showSaveAndCancelButtons = false;
                  s.forceFetchRowDataCounter++; // force refreshing data
                });
              } else if (tableType === 'client-side') {
                dispatch(function client_side_save_all_rows(s) {
                  s.loader.show = false;
                  s.editTableButton.showSaveAndCancelButtons = false;

                  getPageRowIdsFromState(s).forEach((rid) => {
                    // Reset cell errors
                    const row = s.rows[rid];
                    for (const columnId in row.cellErrors) {
                      row.cellErrors[columnId] = '';
                    }
                    row.originalData = row.data;
                    row.rowStatus = 'view';
                  });
                });
              }

              // invoke onRowsSaved callback when save completed
              onRowsSaved?.(validEditRowData);

              // ======================
              // Complete Modal
              // ======================
              if (typeof getCompleteModalData !== 'undefined') {
                const { alertType, title, message } = getCompleteModalData(validEditRowData);
                await AwaitableModal(dispatch, { alertType, headerText: title, children: message });
              }

              // ======================
              // Complete Toast
              // ======================
              if (typeof getCompleteToastData !== 'undefined') {
                dispatch(function push_toast_stack(s) {
                  const { alertType, title, message } = getCompleteToastData(validEditRowData);
                  s.toastStack.push({ alertType, headerText: title, children: message });
                });
              }
            } catch (e) {
              dispatch(function after_server_side_save_all_rows_failure(s) {
                s.loader.show = false;
              });

              // ========================
              // Error Modal
              // ========================
              await AwaitableModal(dispatch, {
                alertType: 'attention',
                headerText: 'Save Rows Failure',
                children: (e as Error).message,
              });
            }
          }}
        />
      )}

      {/* Cancel Button */}
      {showSaveAndCancelButtons && (
        <Button
          text="Cancel"
          variant="secondary"
          onClick={async () => {
            const allEditRowsData = editRows.map((row) => row.data); // include valid and invalid edits
            const {
              confirmModal: getConfirmModalData,
              completeModal: getCompleteModalData,
              completeToast: getCompleteToastData,
            } = cancelAlerts;

            // ======================
            // Confirm Modal
            // ======================
            if (allEditRowsData.length > 0 && typeof getConfirmModalData !== 'undefined') {
              const { title, message } = getConfirmModalData(allEditRowsData);
              const result = await AwaitableModal(dispatch, {
                alertType: 'attention',
                headerText: title,
                children: message,
              });
              if (result === 'secondary') return;
            }

            // ======================
            // Cancel Edit Rows Data Action
            // ======================
            dispatch(function cancel_all_edit_rows(s) {
              s.editTableButton.showSaveAndCancelButtons = false;

              for (const rowId of s.filteredAndSortedRowIds) {
                // Reset all the cell errors
                for (const columnId in s.rows[rowId].cellErrors) {
                  s.rows[rowId].cellErrors[columnId] = '';
                }
                s.rows[rowId].data = s.rows[rowId].originalData;
                s.rows[rowId].rowStatus = 'view';
              }
            });

            // ======================
            // Complete Modal
            // ======================
            if (allEditRowsData.length > 0 && typeof getCompleteModalData !== 'undefined') {
              const { alertType, title, message } = getCompleteModalData(allEditRowsData);
              await AwaitableModal(dispatch, { alertType, headerText: title, children: message });
            }

            // ======================
            // Complete Toast
            // ======================
            if (allEditRowsData.length > 0 && typeof getCompleteToastData !== 'undefined') {
              dispatch(function push_toast_stack(s) {
                const { alertType, title, message } = getCompleteToastData(allEditRowsData);
                s.toastStack.push({ alertType, headerText: title, children: message });
              });
            }
          }}
        />
      )}
    </div>
  );
}
