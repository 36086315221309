import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  Ref,
  WeakValidationMap,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import forwardRefToProps from '../utils/forwardRefToProps';
import { forgeClassHelper } from '../utils/classes';
import { TabPaneProps } from '../TabPane';
import TabVisibleContext from './TabVisibleContext';
import Select, { SelectChangeEvent } from '../Select';

const classes = forgeClassHelper('tabs');

export interface TabsProps {
  /** Assumed to be TabPane elements */
  children?: ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Initially shown TabPane's index in ([uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)) mode */
  defaultSelectedIndex?: number;
  /** Triggered when Tabs are changed; may be a select change or click event depending on the state of the Tabs */
  onTabsChange?: (
    event: SelectChangeEvent<false> | React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
    selectedTab: number
  ) => void;
  /** Ref to the top-level <div> that encapsulates Tabs */
  ref?: Ref<HTMLDivElement>;
  /** Currently selected TabPane's index in [controlled](https://reactjs.org/docs/forms.html#controlled-components) mode */
  selectedIndex?: number;
  /** Screen reader label for tab group **/
  tabGroupLabel?: string;
}

interface HTMLButtonElementWithValue extends HTMLButtonElement {
  value: string;
}

export interface TabsComponentProps extends TabsProps {
  /** Ref to the top-level <div> that encapsulates Tabs */
  forwardedRef?: Ref<HTMLDivElement>;
}

const Tabs = ({
  /** Assumed to be TabPane elements */
  children,
  /** Adds a class to the root element of the component */
  className,
  /** Initially shown TabPane's index in ([uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)) mode */
  defaultSelectedIndex = 0,
  /** Ref to the top-level <div> that encapsulates Tabs */
  forwardedRef,
  /** Triggered when Tabs are changed; may be a select change or click event depending on the state of the Tabs */
  onTabsChange: upstreamOnTabsChange,
  /** Currently selected TabPane's index in [controlled](https://reactjs.org/docs/forms.html#controlled-components) mode */
  selectedIndex: upstreamSelectedIndex,
  /** Screen reader label for tab group **/
  tabGroupLabel,
}: TabsComponentProps): ReactElement => {
  const [stateSelectedIndex, setStateSelectedIndex] = useState(() =>
    upstreamSelectedIndex ? null : defaultSelectedIndex
  );

  const controlled = typeof upstreamSelectedIndex === 'number';

  /** Called when the user has initiated a tab change.
   *
   * In controlled mode, just calls the onTabsChange method.
   * In uncontrolled mode, updates internal state.
   */
  const handleTabsChange = useCallback<NonNullable<TabsProps['onTabsChange']>>(
    (e, selectedTab) => {
      if (controlled) {
        if (upstreamOnTabsChange) {
          upstreamOnTabsChange(e, selectedTab);
        }
      } else if (selectedTab !== stateSelectedIndex) {
        // only dispatch when it's a change
        setStateSelectedIndex(selectedTab);

        if (upstreamOnTabsChange) {
          upstreamOnTabsChange(e, selectedTab);
        }
      }
    },
    [stateSelectedIndex, upstreamOnTabsChange, controlled]
  );

  /** Represents the index of what tab is currently selected.
   *
   * If a tabPane is being dynamically hidden via JSX, then its index still
   * "counts" when determining the index of subsequent tabs.
   *
   * Type assertion is safe because `stateSelectedIndex` will only be `null` if
   * `controlled` is a number
   */
  const selectedIndex = controlled ? upstreamSelectedIndex : (stateSelectedIndex as number);

  const [labelsCollapsed, setLabelsCollapsed] = useState(false);
  const resizeTimer = useRef<number>();
  /** stores the selected tab DOM element */
  const selectedTabEl = useRef<HTMLButtonElement>(null);
  const labelDiv = useRef<HTMLUListElement>(null);

  /** Determines if a scrollbar is present. */
  const hasScrollBar = useCallback(() => {
    if (labelDiv.current) {
      /** If the element's content can fit without a need for horizontal
       * scrollbar, its scrollWidth is equal to width of bounding rectangle.
       * When comparing floating point numbers, it's best not to compare equality,
       * so only collapse if the difference in measurements is greater than 1. */
      return Math.abs(labelDiv.current.scrollWidth - labelDiv.current.clientWidth) > 1;
    } else {
      return false;
    }
  }, []);

  /** Collapse the tab labels into a Select component when there isn't enough
   * room to fit everything. */
  const handleCollapse = useCallback(() => {
    const shouldCollapse = hasScrollBar();
    if ((shouldCollapse && !labelsCollapsed) || (!shouldCollapse && labelsCollapsed)) {
      setLabelsCollapsed(shouldCollapse);
    }
  }, [hasScrollBar, labelsCollapsed]);

  const handleResize = useCallback(() => {
    // Reduce overload of triggering window.resize for entire drag of manual resize event
    // Adapted from https://css-tricks.com/snippets/jquery/done-resizing-event/
    clearTimeout(resizeTimer.current);
    resizeTimer.current = window.setTimeout(handleCollapse, 50);
  }, [handleCollapse]);

  /** If the tabs start to generate a scrollbar, collapse the tabs into a Select
   * component instead */
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleCollapse();
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimer.current);
    };
  }, [handleResize, handleCollapse]);

  const isInitialRender = useRef(true);

  /** Update focus if selectedIndex (and thus selectedTabEl) changes */
  useEffect(() => {
    if (!isInitialRender.current) {
      selectedTabEl.current?.focus();
    }
  }, [
    /** selectedIndex is in the dependency array because it's a more
     * stable proxy for selectedTabEl changing than if selectedTabEl was
     * managed with state. */
    selectedIndex,
  ]);

  /** Change isInitialRender after all useEffect hooks that reference it */
  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  /** Update the tab when selected in collapsed mode */
  const handleTabMenuChange = useCallback<(event: SelectChangeEvent<false>) => void>(
    (e) => {
      if (handleTabsChange && e.target.value?.value) {
        const selectedTab = parseInt(e.target.value.value, 10);
        handleTabsChange(e, selectedTab);
      }
    },
    [handleTabsChange]
  );

  /** Update the tab when selected in expanded mode */
  const handleTabLabelClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      if (handleTabsChange) {
        // Button should not have a value, but it was added anyway
        const selectedTab = parseInt((e.target as HTMLButtonElementWithValue).value, 10);
        handleTabsChange(e, selectedTab);
      }
    },
    [handleTabsChange]
  );

  /** Determines which children are TabPanes.
   *
   * Assumes that any child which passes React.isValidElement is either a
   * TabPane, or a custom component which functions similarly to a TabPane.
   * Disregards non-element children such as `false` or `null`, which may be
   * artifacts of application code determining if a tab should be present or not.
   */
  const tabPaneChildren = useMemo(() => {
    /** Capture the index of each child before filtering so that dynamic tabs
     * coming into existence don't cause keys to be reassigned */
    const childrenWithIndex = React.Children.map(children, (child, index) => ({ child, index })) || [];
    /** Filter out values such as `false` or `null` */
    return childrenWithIndex.filter(({ child }) => React.isValidElement(child)) as {
      child: ReactElement<Partial<TabPaneProps>, JSXElementConstructor<Partial<TabPaneProps>>>;
      index: number;
    }[];
  }, [children]);

  /** Handle Keyboard navigation  */
  const handleTabLabelKeypress = useCallback<React.KeyboardEventHandler<HTMLButtonElement>>(
    (e) => {
      const currentTab = parseInt((e.target as HTMLButtonElementWithValue).value, 10);
      const lastTab = tabPaneChildren.length - 1;

      switch (e.key) {
        case 'ArrowLeft': {
          if (currentTab > 0) {
            handleTabsChange(e, currentTab - 1);
          }
          break;
        }
        case 'ArrowRight': {
          if (currentTab < lastTab) {
            handleTabsChange(e, currentTab + 1);
          }
          break;
        }
        case 'Home': {
          if (currentTab !== 0) {
            handleTabsChange(e, 0);
          }
          break;
        }
        case 'End': {
          if (currentTab !== lastTab) {
            handleTabsChange(e, lastTab);
          }
          break;
        }
      }
    },
    [handleTabsChange, tabPaneChildren]
  );

  /** Rendered children
   *
   * Provides each child TabVisibleContext to let them know whether they should
   * be visible.
   */
  const renderedChildren = useMemo(() => {
    return tabPaneChildren.map(({ child, index }) => {
      return (
        <TabVisibleContext.Provider key={index} value={selectedIndex === index}>
          {child}
        </TabVisibleContext.Provider>
      );
    });
  }, [selectedIndex, tabPaneChildren]);

  /** Options for the Select component in collapsed mode */
  const selectOptions = useMemo(
    () =>
      tabPaneChildren
        .filter(({ child }) => child.props.label)
        .map(({ child, index }) => ({
          label: child.props.label,
          value: index.toString(),
          disabled: child.props.disabled,
        })),

    [tabPaneChildren]
  );

  /** Tab labels visible in expanded mode.
   *
   * Hidden through CSS classes in collapsed mode.
   */
  const labels = useMemo(() => {
    // we always render the labels in tab form for viewport clipping calculations
    return tabPaneChildren.map(({ child, index }) => {
      const disabled = child.props.disabled;
      const isSelected = selectedIndex === index;
      return (
        <li
          key={index}
          {...classes({ element: 'label-wrapper' })}
          role="presentation"
          style={{ visibility: labelsCollapsed ? 'hidden' : 'visible' }}
        >
          <button
            type="button"
            role="tab"
            value={index}
            disabled={disabled}
            {...(disabled && { 'aria-disabled': 'true' })}
            aria-selected={isSelected}
            {...classes({ element: 'label', states: { selected: isSelected, disabled: !!disabled } })}
            aria-hidden={labelsCollapsed}
            onClick={handleTabLabelClick}
            onKeyDown={handleTabLabelKeypress}
            data-content={child.props.label}
            tabIndex={isSelected ? 0 : -1}
            {...(isSelected ? { ref: selectedTabEl } : null)}
          >
            <div {...classes({ element: 'label-text' })}>
              {child.props.renderLabel ? child.props.renderLabel(child.props.label, isSelected) : child.props.label}
            </div>
          </button>
        </li>
      );
    });
  }, [handleTabLabelClick, handleTabLabelKeypress, labelsCollapsed, selectedIndex, tabPaneChildren]);

  return (
    <div ref={forwardedRef} {...classes({ extra: className })}>
      <ul ref={labelDiv} {...classes({ element: 'labels' })} role="tablist" aria-label={tabGroupLabel}>
        {labels}
        {labelsCollapsed && (
          <div {...classes({ element: 'label-collapsed-container' })}>
            <div {...classes({ element: 'label-collapsed-wrapper' })}>
              <Select
                onChange={handleTabMenuChange}
                value={selectOptions[selectedIndex]}
                options={selectOptions}
                {...classes({ element: 'label-collapsed' })}
              />
            </div>
          </div>
        )}
      </ul>
      <div {...classes({ element: 'shadow' })} />
      <div {...classes({ element: 'pane-wrapper' })}>{renderedChildren}</div>
    </div>
  );
};

const tabsPropTypes: WeakValidationMap<TabsComponentProps> = {
  /** Assumed to be TabPane elements */
  children: PropTypes.node,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Initially shown TabPane's index in ([uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)) mode */
  defaultSelectedIndex: PropTypes.number,
  /** Triggered when Tabs are changed; may be a select change or click event depending on the state of the Tabs */
  onTabsChange: PropTypes.func,
  /** Currently selected TabPane's index in [controlled](https://reactjs.org/docs/forms.html#controlled-components) mode */
  selectedIndex: PropTypes.number,
  /** Screen reader label for tab group **/
  tabGroupLabel: PropTypes.string,
};
Tabs.propTypes = tabsPropTypes;

export default forwardRefToProps(Tabs);
