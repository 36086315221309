import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgArrowRightSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 15.001L15 8.00098M15 8.00098L8 1.00098M15 8.00098H1"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgArrowRightSmall.displayName = 'SvgArrowRightSmall';
const ArrowRightSmall = withForgeIconProps(SvgArrowRightSmall, {
  name: 'ArrowRight',
  usage: 'Use to indicate the ability to move an item to the right',
  related: 'move, direction',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ArrowRightSmall;
