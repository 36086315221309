import { faker } from '@faker-js/faker';
import format from 'date-fns/format';

import rawContents from "!!raw-loader!./LabResults.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { faker, format };

function LabResults():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Lab Results" />;
}

export default LabResults;
