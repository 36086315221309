import AddDocumentSmall from '@athena/forge-icons/dist/AddDocumentSmall';
import { withPrefix } from 'gatsby';

import rawContents from "!!raw-loader!./RootBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { AddDocumentSmall, withPrefix };

function RootBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Root Basic" />;
}

export default RootBasic;
