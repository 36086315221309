/* eslint-disable react/prop-types */
import React, { ReactElement, useMemo } from 'react';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import { classes } from './DateInput';
import Button from '../Button';
import Select, { StructuredOption } from '../Select';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import LeftSmall from '@athena/forge-icons/dist/LeftSmall';
import RightSmall from '@athena/forge-icons/dist/RightSmall';

/** Translates a locale into an array of options for the month dropdown */
export const getMonthNamesLocale = (locale: Locale): StructuredOption[] => {
  return Array.from(Array(12)).map((_, i) => ({
    label: locale.localize?.month(i, { width: 'wide' }),
    value: i.toString(),
  }));
};

interface DateInputHeaderProps extends ReactDatePickerCustomHeaderProps {
  disabledKeyboardNavigation: boolean;
  locale: Locale;
  minDate?: Date | null;
  maxDate?: Date | null;
}

const DateInputHeader = ({
  changeMonth,
  changeYear,
  date,
  decreaseMonth,
  disabledKeyboardNavigation,
  increaseMonth,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled,
  locale,
  minDate,
  maxDate,
}: DateInputHeaderProps): ReactElement => {
  const tabIndex = disabledKeyboardNavigation ? -1 : 0;
  const yearsOptions = useMemo(() => {
    if (minDate && maxDate) {
      const selectYearStart = getYear(minDate);
      const selectYearEnd = getYear(maxDate);
      return Array.from(Array(selectYearEnd - selectYearStart + 1)).map((_, index) => {
        return (selectYearEnd - index).toString();
      });
    } else {
      return [];
    }
  }, [maxDate, minDate]);
  const monthOptions = useMemo(() => getMonthNamesLocale(locale), [locale]);

  return (
    <div {...classes({ element: 'header' })} role="group">
      <Button
        {...classes({ element: 'header-button', modifiers: 'left' })}
        icon={LeftSmall}
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        tabIndex={tabIndex}
        variant="tertiary"
      />
      <Select
        {...classes({ element: 'header', extra: 'select-month' })}
        options={monthOptions}
        value={monthOptions[getMonth(date)]}
        onChange={({ target }) => {
          if (target.value?.value) {
            changeMonth(parseInt(target.value?.value));
          }
        }}
        tabIndex={tabIndex}
      />
      <Select
        {...classes({ element: 'header', extra: 'select-year' })}
        options={yearsOptions}
        value={getYear(date).toString()}
        onChange={({ target }) => {
          if (target.value?.value) {
            changeYear(parseInt(target.value?.value));
          }
        }}
        tabIndex={tabIndex}
      />
      <Button
        {...classes({ element: 'header-button', modifiers: 'right' })}
        icon={RightSmall}
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        tabIndex={tabIndex}
        variant="tertiary"
      />
    </div>
  );
};

export default DateInputHeader;
