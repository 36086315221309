import React, { ReactElement, ReactNode, RefObject } from 'react';
import { PushDrawerTypes } from './types';
import { forgeClassHelper } from '../../utils/classes';
import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import PanelLeftSmall from '@athena/forge-icons/dist/PanelLeftSmall';
import PanelRightSmall from '@athena/forge-icons/dist/PanelRightSmall';

const classes = forgeClassHelper('push-drawer-content');

interface PushDrawerContentProps {
  background: PushDrawerTypes.Background;
  children: ReactNode;
  footerButtons?: ReactNode;
  handlePushDrawerToggle: () => void;
  hideDividers: boolean;
  icon?: React.ReactNode;
  isOpen: boolean;
  layout: PushDrawerTypes.Layout;
  mode: PushDrawerTypes.DrawerMode;
  placement: PushDrawerTypes.Placement;
  pushDrawerRef: RefObject<HTMLDivElement>;
  title?: string;
}

const PushDrawerContent = ({
  background,
  children,
  footerButtons,
  handlePushDrawerToggle,
  hideDividers,
  icon,
  isOpen,
  layout,
  mode,
  placement,
  pushDrawerRef,
  title = '',
}: PushDrawerContentProps): ReactElement => {
  const OpenIcon = placement === 'left' ? PanelRightSmall : PanelLeftSmall;
  const CloseIcon = mode === 'hidden' ? CloseSmall : PanelLeftSmall;
  const getDrawerContentModifier = (isOpen: boolean, mode: PushDrawerTypes.DrawerMode): string => {
    if (isOpen) return 'expanded';
    switch (mode) {
      case 'hidden':
        return 'hidden';
      case 'persistent':
        return 'pinned';
      default:
        return 'expanded';
    }
  };

  return (
    <aside
      ref={pushDrawerRef}
      {...classes({
        modifiers: {
          [getDrawerContentModifier(isOpen, mode)]: true,
          [placement]: true,
          [background]: true,
          [layout]: mode === 'persistent',
        },
      })}
    >
      {mode === 'persistent' && !isOpen && (
        <>
          {icon}
          <OpenIcon
            {...classes({ element: 'open-icon', modifiers: { [placement]: true } })}
            onClick={handlePushDrawerToggle}
          />
        </>
      )}
      {isOpen && (
        <>
          <div
            {...classes({
              element: 'header',
              modifiers: { [placement]: true, [layout]: true },
            })}
          >
            {title}
            <CloseIcon
              {...classes({ element: 'close-icon', modifiers: { [mode]: true, [placement]: true } })}
              onClick={handlePushDrawerToggle}
            />
          </div>
          <div
            {...classes({
              element: 'padded-content',
              modifiers:
                footerButtons && !hideDividers ? 'top-bottom-divider' : !hideDividers ? 'top-divider' : undefined,
              extra: layout,
            })}
          >
            {children}
          </div>
          {footerButtons && (
            <div {...classes({ element: 'footer', modifiers: { [layout]: true } })}>{footerButtons}</div>
          )}
        </>
      )}
    </aside>
  );
};

export default PushDrawerContent;
