import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCheckInSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.5 12.5L10 8M10 8L5.5 3.5M10 8H1"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 1H15V12C15 13.6569 13.6569 15 12 15H10"
      stroke={props.color}
      strokeWidth={2}
    />
  </svg>
);
SvgCheckInSmall.displayName = 'SvgCheckInSmall';
const CheckInSmall = withForgeIconProps(SvgCheckInSmall, {
  name: 'CheckIn',
  usage: 'Use to start patient check in',
  related: 'enter, start, Leave',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default CheckInSmall;
