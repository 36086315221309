import rawContents from "!!raw-loader!./ReadOnlyInputBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ReadOnlyInputBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Read Only Input Basic" />;
}

export default ReadOnlyInputBasic;
