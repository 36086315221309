import FileUploadWithMockApplication from './FileUploadWithMockApplication.gen';
import FileUploadDragAndDrop from './FileUploadDragAndDrop.gen';
import DragAndDropUploadConditions from './DragAndDropUploadConditions.gen';
import FileUploadInModal from './FileUploadInModal.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <FileUploadWithMockApplication />
      <FileUploadDragAndDrop />
      <DragAndDropUploadConditions />
      <FileUploadInModal />
    </>
  );
}
