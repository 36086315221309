import { useLayoutEffect, useState } from 'react';

export interface UseWindowSizeReturn {
  width: number | null;
  height: number | null;
}
export default function useWindowSize(): UseWindowSizeReturn {
  const [width, setWidth] = useState(() => (typeof window === 'undefined' ? null : window.innerWidth));
  const [height, setHeight] = useState(() => (typeof window === 'undefined' ? null : window.innerHeight));

  useLayoutEffect(() => {
    // Handler to call on window resize
    function handleResize(): void {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return {
    width,
    height,
  };
}
