import { UseSelectGetToggleButtonReturnValue } from 'downshift';
import { createContext } from 'react';
import { MenuSize } from './Menu';

export type MenuTriggerToggleButtonProps = UseSelectGetToggleButtonReturnValue;

export interface MenuTriggerContextData {
  size?: MenuSize;
  triggerProps?: MenuTriggerToggleButtonProps;
}

const MenuTriggerContext = createContext<MenuTriggerContextData>({});
export default MenuTriggerContext;
