import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAllergySmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6.3449 2.47754L1.25225 11.5805C1.08616 11.877 0.999149 12.2127 1.00001 12.5543C1.00086 12.8958 1.08957 13.2313 1.25714 13.5269C1.42471 13.8225 1.66528 14.0679 1.95488 14.2387C2.24448 14.4095 2.57294 14.4996 2.90734 14.5H13.0927C13.4271 14.4996 13.7555 14.4095 14.0451 14.2387C14.3347 14.0679 14.5753 13.8225 14.7429 13.5269C14.9104 13.2313 14.9991 12.8958 15 12.5543C15.0009 12.2127 14.9138 11.877 14.7478 11.5805L9.6551 2.47754C9.48765 2.18042 9.24653 1.93353 8.95596 1.76193C8.66539 1.59032 8.33565 1.5 8 1.5C7.66435 1.5 7.33461 1.59032 7.04404 1.76193C6.75347 1.93353 6.51235 2.18042 6.3449 2.47754Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M10.3803 11.4999C10.6782 11.5038 10.9705 11.4189 11.2199 11.256C11.4694 11.0932 11.6648 10.8597 11.7811 10.5854C11.8973 10.3111 11.9293 10.0084 11.8728 9.71584C11.8164 9.42331 11.6742 9.15424 11.4642 8.94288C11.2543 8.73151 10.986 8.58746 10.6939 8.52908C10.4017 8.4707 10.0988 8.50064 9.82375 8.61509C9.54868 8.72954 9.31391 8.92325 9.14939 9.17164C8.98488 9.42003 8.8981 9.71179 8.90003 10.0097C8.92525 10.395 9.08908 10.7581 9.36117 11.032C9.63326 11.3059 9.99522 11.4721 10.3803 11.4999V11.4999Z"
      fill={props.color}
    />
    <path
      d="M5.99999 12.5C6.19777 12.5 6.39108 12.4413 6.55552 12.3314C6.71997 12.2215 6.84812 12.0654 6.9238 11.8826C6.99949 11.6999 7.0194 11.4989 6.98081 11.3049C6.94223 11.1109 6.84698 10.9327 6.70713 10.7929C6.56728 10.653 6.38903 10.5578 6.19505 10.5192C6.00108 10.4806 5.80003 10.5005 5.6173 10.5761C5.43458 10.6518 5.27846 10.78 5.16858 10.9444C5.0587 11.1089 5 11.3022 5 11.5C5 11.7652 5.10531 12.0196 5.29284 12.2071C5.48038 12.3946 5.73477 12.5 5.99999 12.5Z"
      fill={props.color}
    />
    <path
      d="M7.52025 9.18921C7.76747 9.18921 8.00911 9.11591 8.21467 8.97856C8.42023 8.84121 8.58041 8.64598 8.67502 8.41757C8.76963 8.18916 8.79451 7.93785 8.74628 7.69537C8.69805 7.45289 8.57899 7.23015 8.40418 7.05534C8.22937 6.88052 8.00655 6.76147 7.76408 6.71323C7.52161 6.665 7.2703 6.68973 7.04189 6.78434C6.81349 6.87895 6.61834 7.03921 6.48099 7.24478C6.34363 7.45034 6.27026 7.69198 6.27026 7.93921C6.27026 8.27073 6.4019 8.58865 6.63632 8.82307C6.87074 9.05749 7.18873 9.18921 7.52025 9.18921Z"
      fill={props.color}
    />
  </svg>
);
SvgAllergySmall.displayName = 'SvgAllergySmall';
const AllergySmall = withForgeIconProps(SvgAllergySmall, {
  name: 'Allergy',
  usage: 'Use to indicate, view, or edit patient allergies',
  related: 'allergies, allergen, allergic',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default AllergySmall;
