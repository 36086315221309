import MedicationLarge from '@athena/forge-icons/dist/MedicationLarge';
import QualityLarge from '@athena/forge-icons/dist/QualityLarge';

import rawContents from "!!raw-loader!./SignpostBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { MedicationLarge, QualityLarge };

function SignpostBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Signpost Basic" />;
}

export default SignpostBasic;
