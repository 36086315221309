import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVideoOffSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29289 3H1H0V4V13V14H1H9C10.0609 14 11.0783 13.5786 11.8284 12.8284C12.2272 12.4297 12.5331 11.9554 12.7321 11.4392L11 9.70711V10C11 10.5304 10.7893 11.0392 10.4142 11.4142C10.0391 11.7893 9.53045 12 9 12H2V5H6.29289L4.29289 3ZM11 8.29289V5H7.70711L5.70711 3H12H13V4V4.49367L13.0466 4.45005L13.0725 4.4258L13.1001 4.40346C13.3541 4.19736 13.6603 4.06507 13.9851 4.02159C14.3098 3.97812 14.6401 4.02518 14.9395 4.1572C15.2389 4.28924 15.4963 4.50133 15.6832 4.77037C15.87 5.03918 15.979 5.35435 15.9983 5.68134L16 5.71067V5.74005V10.24V10.2694L15.9983 10.2987C15.979 10.6257 15.87 10.9409 15.6832 11.2097C15.4966 11.4784 15.2393 11.6908 14.9395 11.823C14.8402 11.8668 14.7377 11.9012 14.6331 11.926L11 8.29289ZM14 9.68308L13.01 8.75633V7.22378L14 6.2971V9.68308Z"
      fill={props.color}
    />
    <path
      d="M1 1L15 15"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgVideoOffSmall.displayName = 'SvgVideoOffSmall';
const VideoOffSmall = withForgeIconProps(SvgVideoOffSmall, {
  name: 'VideoOff',
  usage: 'Use to indicate disabled video, or to turn on video',
  related: 'video, camera',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default VideoOffSmall;
