import rawContents from "!!raw-loader!./AlertTypes.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function AlertTypes():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Alert Types" />;
}

export default AlertTypes;
