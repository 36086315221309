import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUploadLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M23 19.7798H22V19.7978L22.0006 19.8158L23 19.7798ZM20 23V24H20.0174L20.0347 23.9994L20 23ZM1 23H0V24H1V23ZM4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711C4.68342 9.09763 5.31658 9.09763 5.70711 8.70711L4.29289 7.29289ZM12 1L12.7071 0.292893C12.3166 -0.0976311 11.6834 -0.0976311 11.2929 0.292893L12 1ZM18.2929 8.70711C18.6834 9.09763 19.3166 9.09763 19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L18.2929 8.70711ZM11 15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15H11ZM22 13V19.7798H24V13H22ZM22.0006 19.8158C22.0106 20.093 21.9658 20.3698 21.869 20.6295L23.7428 21.3285C23.9317 20.8223 24.0188 20.2835 23.9994 19.7438L22.0006 19.8158ZM21.869 20.6295C21.7722 20.889 21.6251 21.1269 21.4358 21.33L22.8991 22.6934C23.2671 22.2984 23.5539 21.8349 23.7428 21.3285L21.869 20.6295ZM21.4358 21.33C21.2466 21.5331 21.0194 21.6968 20.7673 21.8118L21.5969 23.6316C22.0887 23.4074 22.5311 23.0884 22.8991 22.6934L21.4358 21.33ZM20.7673 21.8118C20.515 21.9268 20.2424 21.991 19.9653 22.0006L20.0347 23.9994C20.5745 23.9806 21.1054 23.8557 21.5969 23.6316L20.7673 21.8118ZM20 22H1V24H20V22ZM2 23V13H0V23H2ZM5.70711 8.70711L12.7071 1.70711L11.2929 0.292893L4.29289 7.29289L5.70711 8.70711ZM11.2929 1.70711L18.2929 8.70711L19.7071 7.29289L12.7071 0.292893L11.2929 1.70711ZM13 15V1H11V15H13Z"
      fill={props.color}
    />
  </svg>
);
SvgUploadLarge.displayName = 'SvgUploadLarge';
const UploadLarge = withForgeIconProps(SvgUploadLarge, {
  name: 'Upload',
  usage: 'Use to upload a file or data',
  related: 'upload, document',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default UploadLarge;
