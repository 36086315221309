import { ReactNode, useContext } from 'react';
import { SegmentedButtonContext } from '../SegmentedButtonContext';

interface ReadOnlyFormValuesProps {
  name?: string;
}

/**
 * Renders hidden input elements based on the provided name and checked values.
 *
 * Allows default form submission behavior when SegmentedButton is used uncontrolled.
 *
 * @param {string} name - The name of the input elements.
 * @return {ReactNode} The hidden input elements based on the checked values.
 */
export const ReadOnlyFormValues = ({ name }: ReadOnlyFormValuesProps): ReactNode => {
  const { childRole, checkedValues } = useContext(SegmentedButtonContext);
  if (name) {
    switch (childRole) {
      case 'checkbox':
        return Object.keys(checkedValues)
          .filter((value) => checkedValues[value])
          .map((value) => <input key={value} name={name} type="hidden" value={value} />);
      case 'radio': {
        const checkedValue = Object.keys(checkedValues).find((value) => checkedValues[value]);
        return checkedValue ? <input name={name} type="hidden" value={checkedValue} /> : null;
      }
    }
  }
  return null;
};
