import rawContents from "!!raw-loader!./RowShorthands.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function RowShorthands():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Row Shorthands" />;
}

export default RowShorthands;
