import {
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  Validator,
  WeakValidationMap,
} from 'react';
import PropTypes from 'prop-types';
import { computePosition, offset, arrow, flip, shift, autoUpdate } from '@floating-ui/dom';
import useIsMobile from '../hooks/useIsMobile';
import useKeyPress from '../hooks/useKeyPress';
import Heading from '../Heading';
import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper('popup');

const ESCAPE_KEY_CODE = 27;

export type PopoverPlacement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

type Side = 'top' | 'right' | 'left' | 'bottom';

export interface PopoverProps {
  /** Content displayed in popover */
  children?: ReactNode;
  /** CSS class to apply to the Popover's root element */
  className?: string;
  /** Heading - this uses the Heading component, with variant set to subsection. */
  heading?: string;
  /** Set to true to hide the arrow that points to the Popover's trigger when the Popover is open  */
  hideArrow?: boolean;
  /** Set to true to show the popover */
  isOpen?: boolean;
  /** Function called when the user tries to close. Useful when in controlled mode */
  onClose: () => void;
  /** Tooltip placement relative to the trigger (automatically changes position when near a screen edge). Note: On mobile, the popover displays below the trigger by default, and adjusts to above the trigger if necessary. */
  placement?: PopoverPlacement;
  /** Preventing overflow while maintaining the desired placement as best as possible. */
  shiftElement?: boolean;
  /** Ref to the Popover's target element. The Popover will be positioned relative to this target element */
  targetRef: RefObject<HTMLDivElement | HTMLButtonElement>;
}

/** A dialog for disclosing more details. */
const Popover = ({
  targetRef,
  onClose,
  children,
  className,
  heading,
  hideArrow = false,
  isOpen = false,
  placement = 'right',
  shiftElement = false,
}: PopoverProps): ReactElement => {
  const isMobile = useIsMobile();

  const tooltipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  useKeyPress({ keyCode: ESCAPE_KEY_CODE, shouldListen: isOpen, callback: onClose });

  /** Computing the popover position. */
  const updatePosition = useCallback(() => {
    if (tooltipRef.current && targetRef.current && arrowRef.current) {
      computePosition(targetRef.current, tooltipRef.current, {
        placement: isMobile ? 'bottom' : placement,
        middleware: [
          offset(8),
          shiftElement ? shift() : undefined,
          /**
           * Allows Popover to fallback to the opposite axis if no placements along the preferring placement axis fit
           *
           * e.g., if initial placement is 'right', by default, the placement options (in priority order) are ['right', 'left]
           * But, by setting fallbackAxisSideDirection to 'start', we permit the following placement options (in priority order) ['right', 'left', 'top', 'bottom' ]
           *
           * takes possible values of 'start', 'end', 'none' (default)
           *
           * https://floating-ui.com/docs/flip
           */
          flip({ fallbackAxisSideDirection: 'start' }),
          // arrow() should generally be placed toward the end of middleware array
          hideArrow ? undefined : arrow({ element: arrowRef.current }),
        ],
      }).then(({ x, y, placement, middlewareData }) => {
        if (tooltipRef.current) {
          Object.assign(tooltipRef.current.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
        }

        const side = placement.split('-')[0];

        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[side];

        if (!hideArrow && middlewareData.arrow && arrowRef.current) {
          const { x: arrowX, y: arrowY } = middlewareData.arrow;
          Object.assign(arrowRef.current.style, {
            left: arrowX != null ? `${arrowX}px` : '',
            top: arrowY != null ? `${arrowY}px` : '',
            // Ensure the static side gets unset when flipping to other placements' axes.
            right: '',
            bottom: '',
            [staticSide as Side]: '-4px',
          });
        }
      });
    }
    // isOpen is technically not required to be in the dependency array, but its presence is necessary to function properly.
  }, [hideArrow, isMobile, isOpen, placement, shiftElement, targetRef]); // eslint-disable-line

  useEffect(() => {
    // Calling only when Popover is opened
    if (isOpen && tooltipRef.current && targetRef?.current) {
      const cleanup = autoUpdate(targetRef.current, tooltipRef.current, updatePosition);

      // Adding a 'beforeunload' event listener to the window object that calls the cleanup function.
      // This ensures the cleanup actions are performed when the user is about to leave the webpage or an iframe is unloaded in aOne
      window.addEventListener('beforeunload', cleanup);

      return () => {
        // Remove the 'beforeunload' event listener to prevent memory leaks.
        window.removeEventListener('beforeunload', cleanup);
        cleanup();
      };
    }
  }, [updatePosition, isOpen, targetRef]);

  return (
    <div
      {...classes({
        element: 'tooltip',
        modifiers: {
          invisible: !isOpen,
        },
        extra: className,
      })}
      ref={tooltipRef}
      role="dialog"
      data-popover-placement={placement}
    >
      <button type="button" {...classes({ element: 'dismiss-button' })} onClick={onClose}>
        <CloseSmall {...classes({ element: 'dismiss' })} />
      </button>
      <div {...classes({ element: 'content' })}>
        {heading && (
          <div
            {...classes({
              element: 'header',
            })}
          >
            <Heading variant="subsection" text={heading} />
          </div>
        )}
        {children}
      </div>
      {hideArrow ? <div ref={arrowRef} /> : <div {...classes({ element: 'arrow' })} ref={arrowRef} />}
    </div>
  );
};

const popoverPropTypes: WeakValidationMap<PopoverProps> = {
  /** Function called when the user tries to close. Useful when in controlled mode */
  onClose: PropTypes.func.isRequired,
  /** Content displayed in popover */
  children: PropTypes.node,
  /** CSS class to apply to the Popover's root element */
  className: PropTypes.string,
  /** Ref to the Popover's target element. The Popover will be positioned relative to this target element */
  targetRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired as Validator<
    RefObject<HTMLDivElement | HTMLButtonElement>
  >,
  /** Set to true to show the popover */
  isOpen: PropTypes.bool,
  /** Heading - this uses the Heading component, with variant set to subsection. */
  heading: PropTypes.string,
  /** Set to true to hide the arrow that points to the Popover's trigger when the Popover is open  */
  hideArrow: PropTypes.bool,
  /** Tooltip placement relative to the trigger (automatically changes position when near a screen edge). Note: On mobile, the popover displays below the trigger by default, and adjusts to above the trigger if necessary. */
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  /** Preventing overflow while maintaining the desired placement as best as possible. */
  shiftElement: PropTypes.bool,
};

Popover.propTypes = popoverPropTypes;

export default Popover;
