import AttentionSmall from '@athena/forge-icons/dist/AttentionSmall';
import CriticalSmall from '@athena/forge-icons/dist/CriticalSmall';
import InfoSmall from '@athena/forge-icons/dist/InfoSmall';
import NewSmall from '@athena/forge-icons/dist/NewSmall';
import SuccessSmall from '@athena/forge-icons/dist/SuccessSmall';
import AttentionLarge from '@athena/forge-icons/dist/AttentionLarge';
import CriticalLarge from '@athena/forge-icons/dist/CriticalLarge';
import { ForgeIconComponent } from '@athena/forge-icons/dist/ForgeIconProps';
import InfoLarge from '@athena/forge-icons/dist/InfoLarge';
import NewLarge from '@athena/forge-icons/dist/NewLarge';
import SuccessLarge from '@athena/forge-icons/dist/SuccessLarge';
import PropTypes from 'prop-types';
import { ReactElement, ReactNode, WeakValidationMap } from 'react';
import { forgeClassHelper } from '../utils/classes';
import { AlertTypes } from '../utils/constants';
const classes = forgeClassHelper({ name: 'inline-alert' });

export type InlineAlertType = AlertTypes;

export type InlineAlertProps = {
  /** CSS class applied to the InlineAlert's root `div` */
  className?: string;
  /** Message to display */
  children?: ReactNode;
  /** Determines how large the icon should be */
  iconSize?: 'small' | 'large';
  /** Determines which icon displays next to the message.
   * Type of `success` displays the message in green. */
  type?: InlineAlertType;
};

const Icons = {
  attention: { large: AttentionLarge, small: AttentionSmall },
  critical: { large: CriticalLarge, small: CriticalSmall },
  info: { large: InfoLarge, small: InfoSmall },
  new: { large: NewLarge, small: NewSmall },
  success: { large: SuccessLarge, small: SuccessSmall },
};

const InlineAlert = ({ className, children, iconSize = 'large', type = 'info' }: InlineAlertProps): ReactElement => {
  const Icon: ForgeIconComponent = Icons[type][iconSize];

  return (
    <div role={['attention', 'critical'].includes(type) ? 'alert' : 'status'} {...classes({ extra: className })}>
      <Icon {...classes({ element: 'icon' })} />
      <div {...classes({ element: 'message', modifiers: { success: type === 'success' } })}>{children}</div>
    </div>
  );
};

/** Runtime prop-types for InlineAlert */
const inlineAlertPropTypes: WeakValidationMap<InlineAlertProps> = {
  /** CSS class applied to the InlineAlert's root `div` */
  className: PropTypes.string,

  /** Message to display */
  children: PropTypes.node,

  /** Determines which icon displays next to the message. Can choose from one of `info`, `success`, `attention`, `critical`.
   * Type of `success` displays the message in green. */
  type: PropTypes.oneOf<InlineAlertType>(['attention', 'critical', 'info', 'new', 'success']),
};

InlineAlert.propTypes = inlineAlertPropTypes;

export default InlineAlert;
