import React, { WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { gridRowClasses, getModifiers } from './grid-classes.js';

export type GridRowAlignType = 'top' | 'bottom' | 'middle' | 'stretch';
export interface GridRowColumns {
  small?: number;
  medium?: number;
  large?: number;
}
export interface GridRowProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Vertical alignment of all GridCols in GridRow; stretch is the default behavior */
  children?: React.ReactNode;
  /** Children to display within the `GridRow` */
  className?: string;
  /** Adds a class to the root element of the component */
  align?: GridRowAlignType;
  /** Number of columns in the GridRow by breakpoint; overrides width set by child GridCol and uses auto sizing */
  columns?: GridRowColumns;
  /** Whether the GridRow is nested in a GridCol; sets negative margins to overlap parent gutter padding */
  nested?: boolean;
  /** Remove gutter padding from the small breakpoint width and larger for all GridCols in the GridRow */
  removeGuttersSmall?: boolean;
  /** Remove gutter padding from the medium breakpoint width and larger for all GridCols in the GridRow */
  removeGuttersMedium?: boolean;
  /** Remove gutter padding from the large breakpoint width for all GridCols in the GridRow */
  removeGuttersLarge?: boolean;
  /** Restore removed gutter padding from medium breakpoint width and larger for all GridCols in the GridRow */
  restoreGuttersMedium?: boolean;
  /** Restore removed gutter padding from large breakpoint width for all GridCols in the GridRow */
  restoreGuttersLarge?: boolean;
  /** Stack all GridCols in the GridRow until the medium breakpoint; then restore default auto sizing */
  stackUntilMedium?: boolean;
  /** Stack all GridCols in the GridRow until the large breakpoint; then restore default auto sizing */
  stackUntilLarge?: boolean;
}

export const gridRowAlignments: GridRowAlignType[] = ['top', 'bottom', 'middle', 'stretch'];

/**
 * GridRow
 * @documentedBy Grid
 */
const GridRow = function (props: GridRowProps): React.ReactElement {
  const {
    align,
    children,
    className,
    columns,
    nested,
    removeGuttersSmall,
    removeGuttersMedium,
    removeGuttersLarge,
    restoreGuttersMedium,
    restoreGuttersLarge,
    stackUntilMedium,
    stackUntilLarge,
    ...rowProps
  } = props;

  let modifiers = [];

  align && modifiers.push('align-' + align);

  nested && modifiers.push('nested');

  removeGuttersSmall && modifiers.push('no-gutters-small');
  removeGuttersMedium && modifiers.push('no-gutters-medium');
  removeGuttersLarge && modifiers.push('no-gutters-large');

  restoreGuttersMedium && modifiers.push('gutters-medium');
  restoreGuttersLarge && modifiers.push('gutters-large');

  stackUntilMedium && modifiers.push('stack-until-medium');
  stackUntilLarge && modifiers.push('stack-until-large');

  columns && (modifiers = modifiers.concat(getModifiers('columns', columns)));

  return (
    <div {...gridRowClasses('', modifiers, '', className)} {...rowProps}>
      {children}
    </div>
  );
};

const gridRowPropTypes: WeakValidationMap<GridRowProps & { '...rest': unknown }> = {
  /** Vertical alignment of all GridCols in GridRow; stretch is the default behavior */
  align: PropTypes.oneOf(gridRowAlignments),
  /** Children to display within the `GridRow` */
  children: PropTypes.node,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Number of columns in the GridRow by breakpoint; overrides width set by child GridCol and uses auto sizing */
  columns: PropTypes.shape({
    small: PropTypes.number,
    medium: PropTypes.number,
    large: PropTypes.number,
  }) as PropTypes.Requireable<GridRowColumns>,
  /** Whether the GridRow is nested in a GridCol; sets negative margins to overlap parent gutter padding */
  nested: PropTypes.bool,
  /** Remove gutter padding from the large breakpoint width for all GridCols in the GridRow */
  removeGuttersLarge: PropTypes.bool,
  /** Remove gutter padding from the medium breakpoint width and larger for all GridCols in the GridRow */
  removeGuttersMedium: PropTypes.bool,
  /** Remove gutter padding from the small breakpoint width and larger for all GridCols in the GridRow */
  removeGuttersSmall: PropTypes.bool,
  /** Restore removed gutter padding from large breakpoint width for all GridCols in the GridRow */
  restoreGuttersLarge: PropTypes.bool,
  /** Restore removed gutter padding from medium breakpoint width and larger for all GridCols in the GridRow */
  restoreGuttersMedium: PropTypes.bool,
  /** Stack all GridCols in the GridRow until the large breakpoint; then restore default auto sizing */
  stackUntilLarge: PropTypes.bool,
  /** Stack all GridCols in the GridRow until the medium breakpoint; then restore default auto sizing */
  stackUntilMedium: PropTypes.bool,
  /** Passthrough props are added to the `div` element */
  '...rest': PropTypes.any,
};

GridRow.propTypes = gridRowPropTypes;

export default GridRow;
