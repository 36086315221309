import { DataTableState } from '../store/stateTypes';

export function getPageRowIdsFromState<RowData>(s: DataTableState<RowData>): number[] {
  return getPageRowIds({
    tableType: s.tableType,
    filteredAndSortedRowIds: s.filteredAndSortedRowIds,
    pageIndex: s.pagination.pageIndex,
    pageSize: s.pagination.pageSize,
  });
}

type getClientSidePageRowIdsProps = {
  tableType: 'client-side' | 'server-side';
  filteredAndSortedRowIds: number[];
  pageSize: number;
  pageIndex: number;
};

export function getPageRowIds({
  tableType,
  filteredAndSortedRowIds,
  pageSize,
  pageIndex,
}: getClientSidePageRowIdsProps): number[] {
  if (tableType === 'server-side') {
    return filteredAndSortedRowIds;
  }

  // show 'All Rows' if pageSize <= 0
  if (pageSize <= 0) {
    return filteredAndSortedRowIds;
  }

  const startRowIndex = pageIndex * pageSize;
  const endRowIndex = Math.min(startRowIndex + pageSize, filteredAndSortedRowIds.length);
  return filteredAndSortedRowIds.slice(startRowIndex, endRowIndex);
}
