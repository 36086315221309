import SearchSmall from '@athena/forge-icons/dist/SearchSmall';

import rawContents from "!!raw-loader!./InputProperties.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { SearchSmall };

function InputProperties():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Input Properties" />;
}

export default InputProperties;
