import AllergyLarge from '@athena/forge-icons/dist/AllergyLarge';
import AllergySmall from '@athena/forge-icons/dist/AllergySmall';
import CriticalSmall from '@athena/forge-icons/dist/CriticalSmall';

import rawContents from "!!raw-loader!./SimpleIcon.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { AllergyLarge, AllergySmall, CriticalSmall };

function SimpleIcon():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Simple Icon" />;
}

export default SimpleIcon;
