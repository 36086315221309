import { useLayoutEffect, useState } from 'react';

const useIsMobile = (mobileThreshold = 640): boolean => {
  const [width, setWidth] = useState<null | number>(null);

  useLayoutEffect(() => {
    const updateWidth = (): void => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return !!(width && width <= mobileThreshold);
};

export default useIsMobile;
