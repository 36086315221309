import rawContents from "!!raw-loader!./HiddenInline.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function HiddenInline():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Hidden Inline" />;
}

export default HiddenInline;
