import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSpeakerOffLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.89611 6.88865L5.62597 8.00002H1.00171C0.449424 8.00002 0.00170898 8.44773 0.00170898 9.00002V15C0.00170898 15.5523 0.449424 16 1.00171 16H5.62597L13.3432 22.7526C13.6385 23.011 14.0576 23.0728 14.4149 22.9106C14.7722 22.7485 15.0017 22.3924 15.0017 22V14.9942L13.0017 12.9942V19.7962L6.66021 14.2474C6.47792 14.0879 6.24393 14 6.00171 14H2.00171V10H6.00171C6.24393 10 6.47792 9.9121 6.66021 9.75259L8.31346 8.306L6.89611 6.88865ZM13.0017 10.1658V4.20378L9.82196 6.98607L8.4046 5.56871L13.3432 1.24744C13.6385 0.989057 14.0576 0.927247 14.4149 1.08938C14.7722 1.25152 15.0017 1.60764 15.0017 2.00002V12.1658L13.0017 10.1658ZM16.5016 16.4941C16.5004 16.6685 16.5448 16.8455 16.6396 17.0068C16.8269 17.3253 17.163 17.5019 17.5076 17.5002L16.5016 16.4941ZM19.2126 16.3767L17.7949 14.959C17.9088 14.8222 18.0111 14.6752 18.1002 14.5195C18.3649 14.0568 18.5042 13.533 18.5042 13C18.5042 12.467 18.3649 11.9432 18.1002 11.4805C17.8354 11.0178 17.4544 10.6322 16.9949 10.3621C16.5188 10.0822 16.3597 9.46933 16.6396 8.99323C16.9195 8.51712 17.5324 8.35805 18.0085 8.63795C18.7684 9.08469 19.3984 9.72224 19.8361 10.4873C20.2738 11.2524 20.5042 12.1185 20.5042 13C20.5042 13.8815 20.2738 14.7477 19.8361 15.5127C19.6585 15.8231 19.4493 16.1125 19.2126 16.3767ZM19.0919 19.0844C18.9292 19.4409 18.9857 19.8755 19.2689 20.1805C19.57 20.5047 20.0358 20.5842 20.4173 20.4099L19.0919 19.0844ZM21.8224 18.9865L20.3942 17.5583C20.7467 17.0742 21.0473 16.5525 21.2899 16.0017C21.7509 14.9553 21.9931 13.8258 22.0017 12.6824L22.0018 12.6698L22.0022 12.6572C22.0362 11.6215 21.8179 10.5932 21.366 9.66076C20.9141 8.72836 20.2422 7.91985 19.4082 7.30484C18.9637 6.97706 18.8691 6.351 19.1969 5.90651C19.5247 5.46201 20.1507 5.3674 20.5952 5.69519C21.6901 6.50262 22.5724 7.56417 23.1658 8.78849C23.7573 10.009 24.044 11.3546 24.0016 12.71C23.9892 14.1217 23.6894 15.516 23.1202 16.8079C22.7773 17.5863 22.341 18.3177 21.8224 18.9865Z"
      fill={props.color}
    />
    <path
      d="M1.00171 1L23.0017 23"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgSpeakerOffLarge.displayName = 'SvgSpeakerOffLarge';
const SpeakerOffLarge = withForgeIconProps(SvgSpeakerOffLarge, {
  name: 'SpeakerOff',
  usage: 'Use to indicate muted audio, or to unmute audio',
  related: 'speaker, sound, volume',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default SpeakerOffLarge;
