import rawContents from "!!raw-loader!./ButtonWithLoadingSpinner.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ButtonWithLoadingSpinner():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Button With Loading Spinner" />;
}

export default ButtonWithLoadingSpinner;
