import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import { ReactElement, WeakValidationMap, useContext } from 'react';
import TabVisibleContext from '../Tabs/TabVisibleContext';

const classes = forgeClassHelper('tab-pane');

export interface TabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Content to display inside TabPane */
  children?: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Applies the disabled state class and adds `aria-disabled` attribute to the TabPane element.
   * This won't prevent the default tab content from display if the default selectedIndex tab is disabled.
   */
  disabled?: boolean;
  /** Label text for the TabPane. */
  label: string;
  /** If set to true, TabPane will stay mounted even when not shown */
  mountedWhileHidden?: boolean;
  /** If set to true, will provide default padding for content */
  padded?: boolean;
  /**
   * Custom render function
   *
   * `(label, isSelected) => ReactNode`
   *
   * The parameter `label` is what ever is passed to the `label` prop.
   */
  renderLabel?: (label: React.ReactNode, isSelected: boolean) => React.ReactNode;
}

/**
 * Tab Pane
 *
 * @documentedBy Tabs
 */
const TabPane = ({
  children,
  className,
  disabled,
  padded = true,
  mountedWhileHidden = false,
  style,
  renderLabel,
  ...passthroughProps
}: TabPaneProps): ReactElement => {
  const isVisible = useContext(TabVisibleContext);
  if (!isVisible && !mountedWhileHidden) {
    return <></>;
  }

  const hidden = !isVisible && mountedWhileHidden;
  const renderedStyle = { ...style, ...(hidden ? { display: 'none' } : {}) };
  const ariaHidden = hidden ? true : undefined;

  return (
    <div
      {...passthroughProps}
      aria-hidden={ariaHidden}
      style={renderedStyle}
      {...classes({ modifiers: { padded: !!padded }, extra: className })}
      role="tabpanel"
    >
      {children}
    </div>
  );
};

TabPane.displayName = 'TabPane';

const tabPaneProps: WeakValidationMap<TabPaneProps & { '...rest': unknown }> = {
  /** Content to display inside TabPane */
  children: PropTypes.node,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Applies the disabled state class and adds `aria-disabled` attribute to the TabPane element.
   * This won't prevent the default tab content from display if the default selectedIndex tab is disabled.
   */
  disabled: PropTypes.bool,
  /** Label text for the TabPane. We recommend only using string to avoid a known rendering issue in the collapsed state */
  label: PropTypes.string.isRequired,
  /** If set to true, TabPane will stay mounted even when not shown */
  mountedWhileHidden: PropTypes.bool,
  /** If set to true, will provide default padding for content */
  padded: PropTypes.bool,
  /**
   * Custom render function
   *
   * `(label, isSelected) => ReactNode`
   *
   * The parameter `label` is what ever is passed to the `label` prop.
   */
  renderLabel: PropTypes.func,
  /** Passthrough props to the root `<div>` that wraps the content children */
  '...rest': PropTypes.any,
};
TabPane.propTypes = tabPaneProps;

export default TabPane;
