import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCameraSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10.91 3.85005V2.66011C10.9048 2.48151 10.8294 2.31206 10.7003 2.18855C10.5712 2.06505 10.3986 1.99737 10.22 2.00008H5.75C5.57134 1.99737 5.39877 2.06505 5.26965 2.18855C5.14053 2.31206 5.06529 2.48151 5.06006 2.66011V3.85005H1V14.0001H12C12.7956 14.0001 13.5587 13.684 14.1213 13.1214C14.6839 12.5588 15 11.7957 15 11.0001V3.85005H10.91Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M8 10.5001C9.10457 10.5001 10 9.60469 10 8.50012C10 7.39555 9.10457 6.50012 8 6.50012C6.89543 6.50012 6 7.39555 6 8.50012C6 9.60469 6.89543 10.5001 8 10.5001Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgCameraSmall.displayName = 'SvgCameraSmall';
const CameraSmall = withForgeIconProps(SvgCameraSmall, {
  name: 'Camera',
  usage: 'Use to open a camera application',
  related: 'image, photo, capture, picture, take picture',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default CameraSmall;
