import Checkbox from '../../../Checkbox';
import { DataTableStore } from '../../store/stateTypes';
import { hasRowsInEditStatusFromState } from '../../utils/hasRowsInEditStatusFromState';
import { classes } from '../../utils/dataTableClasses';

// Actions
import { apply_client_side_filters } from '../../store/actions/apply_client_side_filters';
import { apply_server_side_filters } from '../../store/actions/apply_server_side_filters';

type ShowDeletedRowCheckboxProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function ShowDeletedRowCheckbox<RowData>({ store }: ShowDeletedRowCheckboxProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const tableId = store.useSelector((s) => s.tableId);
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));
  const showDeletedRows = store.useSelector((s) => s.deletedRowsCheckbox);
  if (!showDeletedRows.show) {
    return <></>;
  }

  // The "fe_c_data_table__show_deleted_row_checkbox" has no style definition.
  // Retained here to allow users to customize the CSS.
  return (
    <div {...classes({ element: 'show_deleted_row_checkbox' })}>
      <Checkbox
        id={`${tableId}-show-deleted-row-checkbox`}
        description="Show Deleted"
        checked={showDeletedRows.checked}
        disabled={hasRowsInEditStatus}
        onChange={(e) => {
          dispatch(function toggle_show_deleted_rows(s) {
            // Toggle show deleted rows
            s.deletedRowsCheckbox.checked = s.serverSideFetchRowDataArgs.includeDeletedRows =
              !s.deletedRowsCheckbox.checked;
            apply_client_side_filters(s);
            apply_server_side_filters(s);
          });
        }}
      />
    </div>
  );
}
