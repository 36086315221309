import {
  InputHTMLAttributes,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  WeakValidationMap,
} from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';

const classes = forgeClassHelper('checkbox');

export interface CheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {
  /** Highlights the input when defined (specific string doesn't matter) */
  error?: string;
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles?: boolean;
  /** If the checkbox is neither true nor false */
  indeterminate?: boolean;
}

export interface CheckboxInputComponentProps extends CheckboxInputProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const CheckboxInput = ({
  checked,
  error,
  forwardedRef,
  hideRequiredStyles,
  id,
  indeterminate = false,
  required = false,
  defaultChecked,
  disabled = false,
  'aria-labelledby': ariaLabelledby,
  ...inputProps
}: CheckboxInputComponentProps): ReactElement => {
  const useErrorStyles = error && !disabled;
  const useRequiredStyles = required && !hideRequiredStyles && !disabled;

  const inputRef = useRef<HTMLInputElement>(null);
  // Forward the ref if it is passed in
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(forwardedRef, () => inputRef.current, []);

  useEffect(() => {
    if (inputRef.current) {
      /** The indeterminate property cannot be set via HTML attributes, and
       * must be set as a property on the DOM element itself.
       */
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    // Label is only being used here for form control
    <label
      {...classes({
        element: 'form-control-label',
      })}
    >
      <input
        type="checkbox"
        {...inputProps}
        {...classes({
          element: 'input',
          states: {
            disabled,
            error: !!useErrorStyles,
            indeterminate,
          },
        })}
        aria-labelledby={ariaLabelledby}
        aria-checked={indeterminate ? 'mixed' : checked}
        checked={checked}
        id={id}
        disabled={disabled}
        defaultChecked={defaultChecked}
        ref={inputRef}
        required={required}
      />
      <div
        id={`checkbox-mark-${id}`}
        {...classes({
          element: 'input-marker',
          states: {
            disabled,
            error: !!useErrorStyles,
            required: useRequiredStyles,
          },
          modifiers: { checked: !!checked, indeterminate },
        })}
      />
    </label>
  );
};

CheckboxInput.displayName = 'CheckboxInput';

CheckboxInput.disableLabelFor = true;

const checkboxInputPropTypes: WeakValidationMap<CheckboxInputProps & { '...rest': unknown }> = {
  /** Specify an additional element ID to label the input. (The input is automatically labeled by the input label, so specifying this additional label target merely provides an additional label.) */
  'aria-labelledby': PropTypes.string,
  /** Whether the Checkbox should be checked. Used for [controlled](https://reactjs.org/docs/forms.html#controlled-components) components. */
  checked: PropTypes.bool,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Whether the Checkbox should be checked. Used for [uncontrolled](https://reactjs.org/docs/uncontrolled-components.html) Checkboxes. */
  defaultChecked: PropTypes.bool,
  /** Adds the relevant Forge state class to the input and passes the prop down to the native react `<input />` */
  disabled: PropTypes.bool,
  /** Highlights the input when defined (specific string doesn't matter) */
  error: PropTypes.string,
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles: PropTypes.bool,
  /** Id is sent to the `<input />`, and is used to associate the label with the checkbox. */
  id: PropTypes.string.isRequired,
  /** If the checkbox is neither true nor false */
  indeterminate: PropTypes.bool,
  /** Indicates if it is a required field. Passed as an attribute to the native react `<input />` */
  required: PropTypes.bool,
  /** Passthrough props to the `<input>` element */
  '...rest': PropTypes.any,
};
CheckboxInput.propTypes = checkboxInputPropTypes;

export default Object.assign(forwardRefToProps(CheckboxInput), {
  disableLabelFor: true,
} as const);
