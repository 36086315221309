import rawContents from "!!raw-loader!./StepperBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function StepperBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Stepper Basic" />;
}

export default StepperBasic;
