import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgShowLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 17C14.7449 17 16.97 14.7614 16.97 12C16.97 9.23858 14.7449 7 12 7C9.25518 7 7.03003 9.23858 7.03003 12C7.03003 14.7614 9.25518 17 12 17Z"
      fill={props.color}
    />
    <path
      d="M12 18C18.0365 18 22 15.5 22.93 12C22 8.5 18.0365 6 12 6C5.96353 6 2 8.5 1.07001 12C2 15.5 5.96353 18 12 18Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgShowLarge.displayName = 'SvgShowLarge';
const ShowLarge = withForgeIconProps(SvgShowLarge, {
  name: 'Show',
  usage: 'Use to indicate that an item can be hidden, or to hide an item',
  related: 'eye, secret, private',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ShowLarge;
