import rawContents from "!!raw-loader!./TimedOut.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function TimedOut():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Timed Out" />;
}

export default TimedOut;
