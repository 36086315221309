import { Ref, createContext } from 'react';

/** Used when a Forge component needs a ref to an <Input /> which is in its
 * `children` prop.
 *
 * The primary use-case is to allow a Button to have <Input type="file" /> as a
 * child. With access to the Input's ref, the Button can trigger the input's
 * click event, opening the file dialog. This assumes that there is exactly
 * one input child of the Button.
 */
const InputRefContext = createContext<Ref<HTMLInputElement>>(null);
export default InputRefContext;
