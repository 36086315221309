import { Element } from 'domhandler/lib/node';
import { withPrefix } from 'gatsby';
import { attributesToProps, DOMNode, domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { ReactElement } from 'react';
import ForgeLogoInvertedSvg from '../static/svg/forge-logo-inverted.svg';
import SearchBox from './components/search-box';
import Template from './templates/additional-demo-template';
import { gatsbyImagePath } from './gatsbyImagePathConfig';

const imagePrefix = (url: string): string => {
  return gatsbyImagePath() + url;
};

const getAnchorTags = (domNode: Element): ReactElement => {
  const props = attributesToProps(domNode.attribs);
  return (
    <a {...props} href={withPrefix(props.href)}>
      {domToReact(domNode.children)}
    </a>
  );
};

const getImageTags = (domNode: Element): ReactElement => {
  const props = attributesToProps(domNode.attribs);
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} src={imagePrefix(props.src)} />;
};

export function defaultOptions(): HTMLReactParserOptions {
  return {
    replace: (node: DOMNode) => {
      const domNode = node as Element;

      if (domNode.name === 'a' && domNode.attribs.href && domNode.attribs.href.charAt(0) === '/') {
        return getAnchorTags(domNode);
      }

      if (domNode.name === 'img' && domNode.attribs.src && domNode.attribs.src.charAt(0) === '/') {
        return getImageTags(domNode);
      }

      return null;
    },
  };
}

export function textBlockOptions(flag: string): HTMLReactParserOptions {
  return {
    replace: (node: DOMNode) => {
      const domNode = node as Element;
      if (flag === 'When to use' && domNode.name === 'ul') {
        return <ul className="ul-green">{domToReact(domNode.children)}</ul>;
      }

      if (flag === 'When not to use' && domNode.name === 'ul') {
        return <ul className="ul-red">{domToReact(domNode.children)}</ul>;
      }

      if (domNode.name === 'a' && domNode.attribs.href && domNode.attribs.href.charAt(0) === '/') {
        return getAnchorTags(domNode);
      }

      if (domNode.name === 'img' && domNode.attribs.src && domNode.attribs.src.charAt(0) === '/') {
        return getImageTags(domNode);
      }

      if (domNode.name === 'div' && domNode.attribs['data-demo']) {
        return <Template>{domToReact(domNode.children)}</Template>;
      }

      return null;
    },
  };
}

export function pageOptions(bannerRef?: React.MutableRefObject<HTMLDivElement | null>): HTMLReactParserOptions {
  let homepageBackground =
    'linear-gradient(253.67deg, #8C65C2 16.21%, #6C3388 70.4%),linear-gradient(0deg, #FFFFFF, #FFFFFF)'; // Default Background Gradient

  return {
    replace: (node: DOMNode) => {
      const domNode = node as Element;

      if (domNode.name === 'a' && domNode.attribs.href && domNode.attribs.href.charAt(0) === '/') {
        return getAnchorTags(domNode);
      }
      if (domNode.name === 'div' && domNode.attribs['data-component'] === 'homepage-hero') {
        homepageBackground = domNode.attribs['data-homepage-background'] || homepageBackground;
        const headings = domNode.children.filter((child) => {
          const childElement = child as Element;
          return childElement.name === 'h1' || childElement.name === 'h2';
        });

        return (
          <div className="homepage-container" style={{ background: homepageBackground }}>
            <div className="homepage-hero">
              <div className="homepage-hero-content">
                {domToReact(headings)}
                <SearchBox placeholder="Search for a Component" />
              </div>
              <div className="homepage-hero-logo-wrapper">
                <ForgeLogoInvertedSvg className="homepage-hero-logo" />
              </div>
            </div>
          </div>
        );
      }
      if (domNode.name === 'img' && domNode.attribs.src && domNode.attribs.src.charAt(0) === '/') {
        return getImageTags(domNode);
      }
      if (domNode.name === 'div' && domNode.attribs.id === 'page-banner') {
        const props = attributesToProps(domNode.attribs);
        return (
          <div {...props} ref={bannerRef}>
            {domToReact(domNode.children)}
          </div>
        );
      }
    },
  };
}
