import { ReactElement } from 'react';
import { Text, TextProps } from 'react-aria-components';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper({ name: 'aria-text' });

export interface AriaTextProps extends TextProps {
  children: React.ReactNode;
  slot: 'description' | 'errorMessage';
}

const AriaText = ({ children, slot }: AriaTextProps): ReactElement => {
  return (
    <Text {...classes()} slot={slot}>
      {children}
    </Text>
  );
};

export default AriaText;
