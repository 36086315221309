import React, {
  ChangeEventHandler,
  FocusEventHandler,
  ReactElement,
  Ref,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  WeakValidationMap,
} from 'react';
import Input from '../Input';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import FakeEvent from '../utils/fake-event';
import { optionalFunctionValidator, optionalStringValidator } from '../utils/betterPropTypes';
import forwardRefToProps from '../utils/forwardRefToProps';
import { FormFieldBaseInputProps } from '../FormField/FormFieldTypes';
import RadioGroupContext, { useIsChecked, useIsDefaultChecked } from '../RadioGroup/RadioGroupContext';

const classes = forgeClassHelper('radio');

export type RadioValue = number | string | boolean;

export class RadioInputValue {
  /** @deprecated in favor of `label` */
  public text: string;
  constructor(
    public value: RadioValue,
    public label: string,
    public checked: boolean
  ) {
    this.value = value;
    this.label = label;
    this.text = label;
    this.checked = checked;
  }
}
export type RadioInputOnChangeEvent = FakeEvent<RadioInputValue>;
export type RadioInputOnChangeEventHandler = (event: RadioInputOnChangeEvent) => void;
export interface RadioInputProps {
  /** Controlled Input box string value */
  inputText?: string;
  /** Callback called when either the radio checked state changes or its label changes */
  onChange?: RadioInputOnChangeEventHandler;
  /** Placeholder label for label field */
  placeholder?: string;
}

export interface RadioProps extends FormFieldBaseInputProps {
  /** Specify horizontal or vertical alignment */
  alignment?: 'horizontal' | 'vertical';
  /** Whether the Radio is selected; should only be used for [controlled](https://reactjs.org/docs/forms.html#controlled-components)  Radios */
  checked?: boolean;
  /** Radio labels can be set through the label prop or through children. */
  children?: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Whether the Radio should be initially selected; should only be used for [uncontrolled](https://reactjs.org/docs/uncontrolled-components.html) Radios */
  defaultChecked?: boolean;
  /** Applies the disabled state class to the label and adds `disabled` attribute to the input element */
  disabled?: boolean;
  /** Highlights the input when defined (specific string doesn't matter) */
  error?: string;
  /** ID attribute for the Radio element */
  id: string;
  /** Label shown next to the radio button */
  label?: string;
  /** Name attribute. Should be the same as all other radio elements within a group */
  name?: string;
  /** Function that takes a JS event as an argument. Called when the RadioGroup or Radio loses focus */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /** Function that takes a JS event as an argument. Called when the RadioGroup or Radio value changes */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /** Function that takes a JS event as an argument. Called when the RadioGroup or Radio receives focus */
  onFocus?: FocusEventHandler<HTMLInputElement>;
  /** A callback function bound to this component's input ref prop to trigger after the component is mounted or
   * unmounted.
   * ref will be called with the following parameters:
   *  input (Object):
   *  A mounted instance of the input
   */
  ref?: Ref<HTMLInputElement>;
  /** Indicates whether this radio or any other in the same group is required to be checked */
  required?: boolean;
  /** Controls what tabIndex the underlying <input> has */
  tabIndex?: number;
  /** @deprecated in favor of `label`
   *
   * Label text shown next to the radio button */
  text?: string;
  /** Adds free-text radio option */
  textInputProps?: RadioInputProps;
  /** Value attribute of the radio input element. Should be unique within its RadioGroup. */
  value: RadioValue;
}

export interface RadioComponentProps extends RadioProps {
  /** A callback function bound to this component's input ref prop to trigger after the component is mounted or
   * unmounted.
   * ref will be called with the following parameters:
   *  input (Object):
   *  A mounted instance of the input
   */
  forwardedRef?: Ref<HTMLInputElement>;
}

/**
 * Radio
 *
 * @documentedBy RadioGroup
 */
const Radio = ({
  /** inserted by FormField and ignored */
  hideRequiredStyles,
  /** Normal props */
  alignment: upstreamAlignment,
  checked: upstreamChecked,
  children,
  className,
  defaultChecked: upstreamDefaultChecked,
  disabled: upstreamDisabled,
  error: upstreamError,
  forwardedRef,
  id,
  label,
  name: upstreamName,
  onBlur,
  onChange: upstreamOnChange,
  onFocus,
  required: upstreamRequired,
  tabIndex: upstreamTabIndex,
  text,
  textInputProps,
  value,
  ...inputProps
}: RadioComponentProps): ReactElement => {
  const context = useContext(RadioGroupContext);

  const alignment = context?.alignment || upstreamAlignment;
  const disabled = upstreamDisabled || context?.disabled;
  const error = context?.error || upstreamError;
  const name = context?.name || upstreamName;
  const onChange = context?.onChange || upstreamOnChange;
  const required = context?.required || upstreamRequired;
  const tabIndex = context?.tabIndex || upstreamTabIndex;

  const [controlledInputText, setControlledInputText] = useState('');
  const { inputText: upstreamInputText, onChange: onInputChange, placeholder } = textInputProps || {};

  const stringValue = useMemo(() => value.toString(), [value]);

  const inputText = useMemo(
    () => (upstreamInputText === undefined ? controlledInputText : upstreamInputText),
    [controlledInputText, upstreamInputText]
  );

  const checked = useIsChecked({ checked: upstreamChecked, value: stringValue });
  const defaultChecked = useIsDefaultChecked({ defaultChecked: upstreamDefaultChecked, value: stringValue });

  // using an internal ref and also forwarding it:
  const internalRef = useRef<HTMLInputElement>(null);
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(forwardedRef, () => internalRef.current);

  const handleRadioChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (onChange) {
        onChange(e);
      }
      if (onInputChange) {
        onInputChange(
          new FakeEvent({
            value: new RadioInputValue(e.target.value, inputText, e.target.checked),
          })
        );
      }
    },
    [onChange, onInputChange, inputText]
  );

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setControlledInputText(e.target.value);
      if (onChange) {
        /** A user typing into the text box should cause its Radio to become
         * checked. Manufacture a new event by merging the original event and
         * the radio input target */
        onChange({ ...e, target: { ...e.target, ...internalRef.current, value: stringValue } });
      }
      if (onInputChange) {
        onInputChange(
          new FakeEvent({
            value: new RadioInputValue(
              stringValue,
              e.target.value,
              internalRef.current?.checked ?? checked ?? defaultChecked ?? false
            ),
          })
        );
      }
    },
    [checked, defaultChecked, onChange, onInputChange, stringValue]
  );

  return (
    <div
      {...classes({
        modifiers: {
          horizontal: alignment === 'horizontal',
        },
        extra: className,
      })}
    >
      <input
        type="radio"
        {...inputProps}
        id={id}
        {...classes('input')}
        name={name || id}
        value={stringValue}
        onChange={handleRadioChange}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        checked={checked}
        defaultChecked={defaultChecked}
        ref={internalRef}
        disabled={disabled}
        tabIndex={tabIndex}
      />
      <label
        {...classes({
          element: 'description',
          states: {
            disabled: !!disabled,
            error: !!error && !disabled,
          },
        })}
        htmlFor={id}
      >
        {label ?? text}
        {children}
        {textInputProps && (
          <Input
            id={id + '-input'}
            placeholder={placeholder}
            error={error}
            disabled={disabled}
            onChange={handleTextChange}
            value={inputText}
            {...inputProps}
          />
        )}
      </label>
    </div>
  );
};

Radio.displayName = 'Radio';

export const radioPropTypes: WeakValidationMap<RadioProps & { '...rest': unknown }> = {
  alignment: PropTypes.oneOf(['horizontal', 'vertical']),
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  /** Deprecated in favor of `label`
   *
   * Label text shown next to the radio button */
  text: PropTypes.string,
  textInputProps: PropTypes.shape({
    /** Controlled Input box string value */
    inputText: optionalStringValidator,
    /** Callback called when either the radio checked state changes or its text changes */
    onChange: optionalFunctionValidator<RadioInputOnChangeEventHandler>(),
    /** Placeholder text for text field */
    placeholder: optionalStringValidator,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  /** Passthrough props to <div> that wraps the radio input and its label */
  '...rest': PropTypes.any,
};
Radio.propTypes = radioPropTypes;

export default forwardRefToProps(Radio);
