import React, { ComponentType, ReactElement, ReactNode, Ref, WeakValidationMap, useContext } from 'react';
import PropTypes from 'prop-types';
import FormFieldLayout from '../FormFieldLayout';
import Label from '../Label';
import InlineMessage from '../InlineMessage';
import { forgeClassHelper } from '../utils/classes';
import FormError from '../FormError';
import { FormContext, FormLayout } from '../Form/FormContext';
import Input, { InputProps } from '../Input';
import { FormFieldBaseInputProps } from './FormFieldTypes';
import forwardRefToProps from '../utils/forwardRefToProps';

const classes = forgeClassHelper('form-field');

export type ForwardedRefType<InputAsProps extends FormFieldBaseInputProps> = 'ref' extends keyof InputAsProps
  ? InputAsProps['ref']
  : never;

export type ComponentTypeWithMetadata<P> = ComponentType<P> & {
  disableLabelFor?: boolean;
};

export interface FormFieldBaseProps {
  /** Sets the classes on the root element (not the input). */
  className?: string;
  /** Adds the relevant Forge state class and passes the prop down to the input */
  disabled?: boolean;
  /** Highlights the input when defined (specific string doesn't matter) */
  error?: ReactNode;
  /** Tells the error to always render below the input area instead of based on content-size and breakpoint  */
  errorAlwaysBelow?: boolean;
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles?: boolean;
  /** Inline message displayed adjacent to the input. */
  hintText?: string;
  /**
   * Sets the `id` attribute on the input, and is used to associate the label
   * with the input using the `htmlFor`attribute.
   *
   * Will be used as the input's name attribute unless the name prop is provided.
   */
  id: string;
  /** Makes label always go above input. Sets default for all form rows. Overrides `labelWidth`. */
  labelAlwaysAbove?: boolean;
  /** Text of the label. */
  labelText: React.ReactNode;
  /** Width of the label in grid columns. Sets default for all form rows. Defaults to 3/12. */
  labelWidth?: number;
  /**
   * Affects the vertical space that the field occupies.
   * Uses the 'medium' layout if unspecified.
   * If in a Form, overrides the 'layout' set at the Form level for this field.
   */
  layout?: FormLayout;
  /** Sets the `name` attribute on to the input. Defaults to the value of the `id` prop. */
  name?: string;
  /** Ref forwarded to the top-level DOM element */
  ref?: Ref<HTMLDivElement>;
  /** Indicates if this is a required field. Forwarded to the inputAs component */
  required?: boolean;
  /**
   * If true, removes standard grid row outer margins.
   * Set to false if the form is at the top level of page layout.
   * Sets default for all form rows.
   */
  nested?: boolean;
}

export type FormFieldProps<InputAsProps extends FormFieldBaseInputProps = InputProps> = FormFieldBaseProps &
  Omit<InputAsProps, keyof FormFieldBaseProps> & {
    /** The element type of the input. A React functional or class component (e.g., DateInput). */
    inputAs?: ComponentTypeWithMetadata<InputAsProps>;
    /** a ref to the input */
    inputRef?: ForwardedRefType<InputAsProps>;
  };

type FormFieldComponentProps<InputAsProps extends FormFieldBaseInputProps = InputProps> =
  FormFieldProps<InputAsProps> & {
    forwardedRef?: Ref<HTMLDivElement>;
  };

function FormField<InputAsProps extends FormFieldBaseInputProps>({
  className,
  disabled,
  error,
  errorAlwaysBelow,
  forwardedRef,
  hideRequiredStyles,
  hintText,
  id,
  inputAs: InputComponent = Input as ComponentTypeWithMetadata<InputAsProps>,
  inputRef,
  labelAlwaysAbove,
  labelText,
  labelWidth,
  layout,
  name,
  nested,
  required = false,
  ...inputProps
}: FormFieldComponentProps<InputAsProps>): ReactElement {
  const descriptionId = `${id}-description`;
  const labelId = `${id}-label`;
  const errorId = `${id}-error`;
  const labelFor = InputComponent.disableLabelFor ? undefined : id;
  const { requiredVariation } = useContext(FormContext);
  const inputComponentProps = {
    'aria-describedby': descriptionId,
    'aria-errormessage': errorId,
    'aria-invalid': !!error,
    ...inputProps,
    ...classes({ element: 'input' }),
    id: id,
    name: name || id,
    required: required,
    hideRequiredStyles: hideRequiredStyles || requiredVariation === 'allFieldsRequired',
    disabled: disabled,
    error: error,
    'aria-labelledby': labelId,
    ref: inputRef,
    /** double type assertion is necessary because it's difficult to tightly
     * couple the types of inputProps and ref to the rest of the props given how
     * dynamic they are. */
  } as FormFieldBaseInputProps as JSX.IntrinsicAttributes &
    JSX.LibraryManagedAttributes<typeof InputComponent, InputAsProps>;

  const isLabelRequired = required
    ? requiredVariation !== 'blueBarWithLegend' && requiredVariation !== 'allFieldsRequired'
    : false;

  return (
    <FormFieldLayout
      {...classes({ states: { disabled: !!disabled }, extra: className })}
      labelWidth={labelWidth}
      nested={nested}
      layout={layout}
      ref={forwardedRef}
      statusExtras={
        errorAlwaysBelow ? classes({ element: 'status', modifiers: 'below', removeBase: true }).className : undefined
      }
      labelAlwaysAbove={labelAlwaysAbove}
      labelSlot={
        <Label id={labelId} htmlFor={labelFor} text={labelText} disabled={disabled} required={isLabelRequired} />
      }
      messageSlot={hintText && <InlineMessage id={descriptionId} text={hintText} disabled={disabled} />}
      statusIndicatorSlot={
        error && (
          <FormError {...classes('form-error')} id={errorId}>
            {error}
          </FormError>
        )
      }
      inputSlot={<InputComponent {...inputComponentProps} />}
    />
  );
}

/**
 * Any props not listed here are passed to the input.
 */
const formFieldPropTypes: WeakValidationMap<FormFieldProps<InputProps> & { '...rest': unknown }> = {
  /** Sets the classes on the root element (not the input). */
  className: PropTypes.string,
  /** Adds the relevant Forge state class and passes the prop down to the input */
  disabled: PropTypes.bool,
  /** Highlights the input when defined (specific string doesn't matter) */
  error: PropTypes.string,
  /** Tells the error to always render below the input area instead of based on content-size and breakpoint  */
  errorAlwaysBelow: PropTypes.bool,
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles: PropTypes.bool,
  /** Inline message displayed adjacent to the input. */
  hintText: PropTypes.string,
  /**
   * Sets the `id` attribute on the input, and is used to associate the label
   * with the input using the `htmlFor`attribute.
   *
   * Will be used as the input's name attribute unless the name prop is provided.
   */
  id: PropTypes.string.isRequired,
  /** a ref to the input */
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    /** Note: { current: PropTypes.instanceOf(HTMLInputElement) } would be more
     * more correct, but causes a StackOverflow when building gatsby.
     */
    PropTypes.shape({ current: PropTypes.any }),
  ]) as PropTypes.Validator<Ref<HTMLInputElement>>,
  /** Makes label always go above input. Sets default for all form rows. Overrides `labelWidth`. */
  labelAlwaysAbove: PropTypes.bool,
  /** Text of the label. */
  labelText: PropTypes.node.isRequired,
  /** Width of the label in grid columns. Sets default for all form rows. Defaults to 3/12. */
  labelWidth: PropTypes.number,
  /**
   * Affects the vertical space that the field occupies.
   * Uses the 'medium' layout if unspecified.
   * If in a Form, overrides the 'layout' set at the Form level for this field.
   */
  layout: PropTypes.oneOf(['super-compact', 'compact', 'medium', 'large']),
  /** Sets the `name` attribute on to the input. Defaults to the value of the `id` prop. */
  name: PropTypes.string,
  /**
   * If true, removes standard grid row outer margins.
   * Set to false if the form is at the top level of page layout.
   * Sets default for all form rows.
   */
  nested: PropTypes.bool,
  /** Passed down to the input*/
  onBlur: PropTypes.func,
  /** Passed down to the input*/
  onChange: PropTypes.func,
  /** Passed down to the input*/
  onFocus: PropTypes.func,
  /** Passed down to the input */
  required: PropTypes.bool,
  /**
   * Passthrough props to the `inputAs` component.
   */
  '...rest': PropTypes.any,
};
FormField.propTypes = formFieldPropTypes;

export default forwardRefToProps(FormField) as <InputAsProps extends FormFieldBaseInputProps = InputProps>(
  props: FormFieldProps<InputAsProps>
) => ReactElement;
