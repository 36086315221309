import PopoverButton from './PopoverButton.gen';
import PopoverInput from './PopoverInput.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <PopoverButton />
      <PopoverInput />
    </>
  );
}
