import rawContents from "!!raw-loader!./Ordering.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Ordering():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Ordering" />;
}

export default Ordering;
