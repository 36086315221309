import ToggleSwitchAppManager from './ToggleSwitchAppManager.gen';
import ToggleSwitchStructuredSig from './ToggleSwitchStructuredSig.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <ToggleSwitchAppManager />
      <ToggleSwitchStructuredSig />
    </>
  );
}
