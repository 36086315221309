import { DateRangeInputValues } from './DateRangeInputTypes';
import { DateInputOnChangeEvent } from '../DateInput';
import { DateInputValue } from '../DateInput';
import { getNewValues, isDate } from './DateRangeInputUtils';

/** The HTML ids of the first input, second input and checkbox. */
export type DateRangeInputIDs = [string, string, string];

/** Handler arguments. */
export interface DateRangeInputHandlerProps {
  /** REQUIRED all the input values */
  allValues: DateRangeInputValues;
  /** Optional callValidateDate for first/second date. */
  callValidateDate?: (date?: Date) => void;
  /** Optional Date.  Can be null. */
  date?: Date;
  /** REQUIRED strings containing the ids for this component's input fields. */
  ids: DateRangeInputIDs;
  /** Optional onBlur handler from the caller. */
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  /** Optional onBlur handler from the caller for all values. */
  onBlurAllValues?: (event: React.FocusEvent<HTMLInputElement, Element>, value: DateRangeInputValues) => void;
  /** Optional onChange handler from the caller. */
  onChange?: (event: DateInputOnChangeEvent) => void;
  /** Optional onChange handler from the caller for all values. */
  onChangeAllValues?: (event: DateInputOnChangeEvent, value: DateRangeInputValues) => void;
  /** REQUIRED setter for the returned custom validation message. */
  setCustomValidationMsg: React.Dispatch<React.SetStateAction<string | null>>;
  /** REQUIRED Set the first/second date. */
  setDate: React.Dispatch<React.SetStateAction<DateInputValue | undefined>>;
  /** REQUIRED Set if the first/second date is valid. */
  setIsValidDate: React.Dispatch<React.SetStateAction<boolean>>;
  /** REQUIRED set for the checkbox. */
  setShowNoDates: React.Dispatch<React.SetStateAction<boolean>>;
  /** Optional caller function that validates the passed Date. */
  validateDate?: (date?: Date) => string | null;
}

/** This function is the shared body for calls to callValidateDate for first/second date. */
export const callValidateDate = (args: DateRangeInputHandlerProps): void => {
  const { validateDate, date, setCustomValidationMsg } = args;

  if (validateDate !== undefined) {
    if (date !== null) {
      const msg = validateDate(date);

      setCustomValidationMsg(msg);
    } else {
      // If no date, clear out custom validation,
      setCustomValidationMsg(null);
    }
  }
};

/** This function is the shared body for calls to handleDateChangeRaw for first/second date. */
export const handleDateChangeRaw = (
  args: DateRangeInputHandlerProps,
  ev: React.FocusEvent<HTMLInputElement, Element>
): void => {
  const { value } = ev.target;
  const { callValidateDate, setDate, setIsValidDate } = args;

  if (callValidateDate === undefined || (value && /^[tT]*/.test(value.trim()))) {
    // Let DateInput deal with "t" without interference.
    return;
  }

  if (isDate(value)) {
    // A data can be null.
    const date = value === null ? undefined : new Date(value);

    if (callValidateDate !== undefined) {
      callValidateDate(date);
    }
    setDate(date);
    setIsValidDate(true);

    return;
  }
};

/** This function is the shared body for calls to handleDateChange for first/second date. */
export const handleDateChange = (args: DateRangeInputHandlerProps, ev: DateInputOnChangeEvent): void => {
  const value = ev.target.value === null ? undefined : ev.target.value;
  const { callValidateDate, onChange, onChangeAllValues, setDate, setIsValidDate } = args;

  if (callValidateDate !== undefined) {
    callValidateDate(value); // According to DateInput, value is a Date for onChange!
  }
  setDate(value);
  setIsValidDate(true);

  if (onChangeAllValues) {
    onChangeAllValues(ev, getNewValues(args, ev.target.id || '', value));
  } else if (onChange) {
    onChange(ev);
  }
};

/** This function is the shared body for calls to handleDateBlur for first/second date. */
export const handleDateBlur = (
  args: DateRangeInputHandlerProps,
  ev: React.FocusEvent<HTMLInputElement, Element>
): void => {
  const { value } = ev.target;
  const { callValidateDate, onBlur, onBlurAllValues, setDate, setIsValidDate } = args;
  let newValue: Date | undefined = undefined;

  if (callValidateDate !== undefined) {
    if (value === null || value.length === 0) {
      callValidateDate(undefined);
      setDate(undefined);
      setIsValidDate(true);
    } else {
      if (isDate(value)) {
        const date = (newValue = new Date(value));

        callValidateDate(date);
        setDate(date);
        setIsValidDate(true);
      } else {
        callValidateDate(undefined);
        setDate(undefined);
        setIsValidDate(false);
      }
    }
  }

  if (onBlurAllValues) {
    onBlurAllValues(ev, getNewValues(args, ev.target.id, newValue));
  } else if (onBlur) {
    onBlur(ev);
  }
};

/**
 * This toggles "Show items with no quantity" (default) checkbox.
 * Use only if one or both of the numbers are required.
 */
export const handleShowNoDates = (args: DateRangeInputHandlerProps, ev: DateInputOnChangeEvent): void => {
  const { allValues, onChangeAllValues, setShowNoDates } = args;
  setShowNoDates(!allValues.checked);

  if (onChangeAllValues) {
    onChangeAllValues(ev, getNewValues(args, ev.target.id || '', !allValues.checked));
  }
};
