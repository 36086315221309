import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHospitalLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0H0V1V7.58008V23V24H1H20C21.0608 24 22.0783 23.5786 22.8284 22.8284C23.5786 22.0783 24 21.0608 24 20V1V0H23H1ZM2 7.58008V2H22V20C22 20.5305 21.7893 21.0392 21.4142 21.4142C21.0392 21.7893 20.5305 22 20 22H2V7.58008ZM7 5V19H9.334V12.792H14.666V19H17V5H14.666V10.63H9.334V5H7Z"
      fill={props.color}
    />
  </svg>
);
SvgHospitalLarge.displayName = 'SvgHospitalLarge';
const HospitalLarge = withForgeIconProps(SvgHospitalLarge, {
  name: 'Hospital',
  usage: 'Use to refer to a hospital',
  related: 'clinic',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default HospitalLarge;
