import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgComposeLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M21 10.59V18.39C20.9974 19.6119 20.5108 20.7829 19.6469 21.6469C18.7829 22.5108 17.6119 22.9974 16.39 23H1V3H13.62"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3279 5.40348L18.6464 1.72196L20.0531 0.305322C20.1462 0.208764 20.2578 0.131987 20.3812 0.0795274C20.5046 0.0270683 20.6373 0 20.7714 0C20.9055 0 21.0382 0.0270683 21.1616 0.0795274C21.2851 0.131987 21.3967 0.208764 21.4897 0.305322L23.6947 2.51017C23.7912 2.60324 23.868 2.71484 23.9205 2.83826C23.9729 2.96169 24 3.09436 24 3.22847C24 3.36258 23.9729 3.49538 23.9205 3.6188C23.868 3.74223 23.7912 3.85383 23.6947 3.9469L22.3279 5.40348Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4355 17.2959L6.44474 17.9943C6.38475 18.0048 6.32306 18.0008 6.26498 17.9825C6.2069 17.9641 6.15411 17.932 6.11104 17.8889C6.06798 17.8459 6.03596 17.7931 6.01763 17.735C5.9993 17.6769 5.99516 17.6153 6.0057 17.5553L6.70414 13.5646L10.4355 17.2959Z"
      fill={props.color}
    />
    <path
      d="M17.9719 2.51913L7.58384 12.9L11.2651 16.5838L21.6531 6.20295L17.9719 2.51913Z"
      fill={props.color}
    />
  </svg>
);
SvgComposeLarge.displayName = 'SvgComposeLarge';
const ComposeLarge = withForgeIconProps(SvgComposeLarge, {
  name: 'Compose',
  usage: 'Use to create a longer message or document, like an email',
  related: 'write, note, mail, new',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ComposeLarge;
