import React, { Ref, CSSProperties } from 'react';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import Input from '../../Input';
import Portal from '../../Portal';
import Tag from '../../Tag';
import { EmptyPlaceholderComponent } from '.';
import { forgeClassHelper } from '../../utils/classes';
import { Virtuoso } from 'react-virtuoso';
import { HighVolumeMultiSelectOption } from '../HighVolumeMultiSelect';
import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import ExpandSmall from '@athena/forge-icons/dist/ExpandSmall';
interface CollapsibleSectionPanelProps {
  allSelected: boolean;
  canClearAll: boolean;
  containerRef: Ref<HTMLDivElement>;
  filterTerm: string;
  floatingStyles: CSSProperties;
  handleClearAll: (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>) => void;
  handleInputClick: (selectedOptions: HighVolumeMultiSelectOption[]) => void;
  handleSelectAll: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleTagRemoval: (key: string) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  id: string;
  inputHasFocus: boolean;
  keyDownToggleOption: (key: string) => React.KeyboardEventHandler<HTMLDivElement>;
  memoizedOptions: HighVolumeMultiSelectOption[];
  required: boolean;
  selectedMap: { [K: HighVolumeMultiSelectOption['value']]: boolean };
  selectedOptions: HighVolumeMultiSelectOption[];
  selectionPanelRef: Ref<HTMLElement>;
  setFilterTerm: (term: string) => void;
  setFloating: (node: HTMLElement | null) => void;
  setInputHasFocus: (value: boolean) => void;
  setReference: (node: Element | null) => void;
  setShowSelectionPanel: (value: boolean) => void;
  showSelectionPanel: boolean;
  toggleOption: (key: string) => React.ChangeEventHandler<HTMLDivElement>;
}

const classes = forgeClassHelper('high-volume-multiselect');

const CollapsibleSectionPanel = ({
  allSelected,
  canClearAll,
  containerRef,
  filterTerm,
  floatingStyles,
  handleClearAll,
  handleInputClick,
  handleSelectAll,
  handleTagRemoval,
  id,
  inputHasFocus,
  keyDownToggleOption,
  memoizedOptions,
  required,
  selectedMap,
  selectedOptions,
  selectionPanelRef,
  setInputHasFocus,
  setFilterTerm,
  setFloating,
  setReference,
  setShowSelectionPanel,
  showSelectionPanel,
  toggleOption,
}: CollapsibleSectionPanelProps): JSX.Element => {
  return (
    <div {...classes('filter-container')} ref={containerRef}>
      <Input
        {...classes('filter')}
        value={
          (filterTerm && showSelectionPanel) || inputHasFocus
            ? filterTerm
            : selectedOptions.length > 0
              ? `${selectedOptions.length} Selected`
              : ''
        }
        onChange={(e) => {
          if (inputHasFocus) setShowSelectionPanel(true);
          setFilterTerm(e.target.value);
        }}
        placeholder="Type or Select"
        aria-label="Filter"
        onClick={() => handleInputClick(selectedOptions)}
        onFocus={() => setInputHasFocus(true)}
        onBlur={() => setInputHasFocus(false)}
        ref={setReference}
        required={required}
      >
        {selectedOptions.length > 0 && !inputHasFocus && !showSelectionPanel && (
          <>
            <CloseSmall {...classes({ element: 'clear-icon' })} onClick={handleClearAll} size="12px" />
            <span {...classes('pipe')}>|</span>
          </>
        )}

        <ExpandSmall {...classes({ element: 'expand-icon' })} size="12px" />
      </Input>
      {showSelectionPanel && (
        <Portal ref={selectionPanelRef}>
          <div
            {...classes('collapsible-container')}
            ref={setFloating}
            style={floatingStyles ? { ...floatingStyles, zIndex: 1001 } : undefined}
          >
            <div {...classes({ states: { required } })} id={id}>
              <div {...classes('searchSide')}>
                <div {...classes('header')}>
                  <Button
                    variant="tertiary"
                    disabled={allSelected || !memoizedOptions.length}
                    onClick={handleSelectAll}
                    id={`${id}CheckboxSelectAll`}
                    tabIndex={allSelected || !memoizedOptions.length ? -1 : 0}
                  >
                    Select All
                  </Button>
                </div>
                <Virtuoso
                  data={memoizedOptions}
                  itemContent={(idx, { value, label, disabled }: HighVolumeMultiSelectOption) => (
                    <Checkbox
                      {...classes('option')}
                      key={value}
                      id={`${id}Checkbox${value}`}
                      valueAttribute={value}
                      description={label}
                      disabled={disabled}
                      checked={selectedMap[value] ?? false}
                      onChange={toggleOption(value)}
                      tabIndex={disabled ? -1 : 0}
                      onKeyDown={keyDownToggleOption(value)}
                    />
                  )}
                  components={{
                    EmptyPlaceholder: !memoizedOptions.length ? EmptyPlaceholderComponent : undefined,
                  }}
                />
              </div>
              <div {...classes('selectedSide')}>
                <div {...classes('header')}>
                  <span {...classes('label', selectedOptions.length ? '' : 'disabled')}>
                    Selected ({selectedOptions.length})
                  </span>
                  <Button
                    variant="tertiary"
                    disabled={!canClearAll}
                    onClick={handleClearAll}
                    tabIndex={canClearAll ? 0 : -1}
                  >
                    Clear All
                  </Button>
                </div>
                <Virtuoso
                  data={selectedOptions}
                  itemContent={(idx, { label, disabled, value }: HighVolumeMultiSelectOption) => (
                    <Tag
                      key={value}
                      onRemove={handleTagRemoval(value)}
                      size="medium"
                      text={label}
                      isRemovable={!disabled}
                      tabIndex={disabled ? -1 : 0}
                      onKeyDown={keyDownToggleOption(value)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default CollapsibleSectionPanel;
