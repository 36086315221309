import FileUploadMockApplication from '@athena/forge/dist/FileUploadMockApplication';

import rawContents from "!!raw-loader!./FileUploadInModal.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { FileUploadMockApplication };

function FileUploadInModal():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="File Upload In Modal" />;
}

export default FileUploadInModal;
