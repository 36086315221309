import TagPalette from './TagPalette.gen';
import TagBasic from './TagBasic.gen';
import TagAsFilterOption from './TagAsFilterOption.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <TagPalette />
      <TagBasic />
      <TagAsFilterOption />
    </>
  );
}
