import {
  NumericRangeInputOnChangeEvent,
  NumericRangeInputOnFocusEvent,
  NumericRangeInputValues,
} from './NumericRangeInputTypes';

import { FakeEvent, fixEventText, isNumeric, getNewValues, getNumber } from './NumericRangeInputUtils';

/** The HTML ids of the first input, second input and checkbox. */
export type NumericRangeInputIDs = [string, string, string];

/** Handler arguments */
export interface NumericRangeHandlerProps {
  /** REQUIRED all the input values */
  allValues: NumericRangeInputValues;
  /** Optional callValidateValue for first/second input. */
  callValidateValue?: (value?: string) => void;
  /** REQUIRED strings containing the ids for this component's input fields. */
  ids: NumericRangeInputIDs;
  /** REQUIRED indicate if the numbers should be treated as currency. */
  isCurrency: boolean;
  /** REQUIRED use only integers.  Ignored if currency. */
  isInteger: boolean;
  /** Optional onBlur handler from the caller for all values. */
  onBlurAllValues?: (event: NumericRangeInputOnFocusEvent, value: NumericRangeInputValues) => void;
  /** Optional onChange handler from the caller for all values. */
  onChangeAllValues?: (event: NumericRangeInputOnChangeEvent, value: NumericRangeInputValues) => void;
  /** REQUIRED setter for the returned custom validation message. */
  setCustomValidationMsg: React.Dispatch<React.SetStateAction<string | null>>;
  /** REQUIRED Set if the first/second input is valid. */
  setIsValidValue: React.Dispatch<React.SetStateAction<boolean>>;
  /** REQUIRED Set if the first and second input are not required. */
  setShowNoNumbers: React.Dispatch<React.SetStateAction<boolean>>;
  /** REQUIRED set for the checkbox. */
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  /** Optional caller function that validates the passed number. */
  validateValue?: (value?: number) => string | null;
  /** Optional number.  Can be null. */
  value?: string;
}

/** This function is the shared body for calls to callValidateValue for first/second input. */
export const callValidateValue = (args: NumericRangeHandlerProps): void => {
  const { validateValue, value, setCustomValidationMsg } = args;

  if (validateValue !== undefined) {
    if (isNumeric(value) && value !== undefined && value !== null) {
      const msg = validateValue(getNumber(value));

      setCustomValidationMsg(msg);
    } else {
      // If no number, clear out custom validation,
      setCustomValidationMsg(null);
    }
  }
};

/** This function is the shared body for calls to handleNumberChange for first/second input. */
export const handleNumberChange = (
  args: NumericRangeHandlerProps,
  ev: React.FocusEvent<HTMLInputElement, Element>
): void => {
  let { value } = ev.target;
  const { callValidateValue, isCurrency, isInteger, onChangeAllValues, setValue, setIsValidValue } = args;

  value = fixEventText(isCurrency, isInteger, value);

  if (isNumeric(value) && value !== null) {
    if (callValidateValue !== undefined) {
      callValidateValue(value);
    }
    setValue(value);
    setIsValidValue(true);

    const newValue = isCurrency ? value : parseFloat(value).toString().replace(/,/g, '');
    const fakeEvent = new FakeEvent({ value: newValue });

    if (onChangeAllValues) {
      onChangeAllValues(fakeEvent, getNewValues(args, ev.target.id, newValue));
    }
  } else {
    const newValue = value.charAt(0) === '-' || value.charAt(0) === '.' ? value : undefined;
    setValue(newValue);
    setIsValidValue(true);

    if (onChangeAllValues) {
      onChangeAllValues(ev, getNewValues(args, ev.target.id, newValue));
    }
  }
};

/** This function is the shared body for calls to handleNumberBlur for first/second input. */
export const handleNumberBlur = (
  args: NumericRangeHandlerProps,
  ev: React.FocusEvent<HTMLInputElement, Element>
): void => {
  let { value } = ev.target;
  const { callValidateValue, isCurrency, isInteger, onBlurAllValues, setValue, setIsValidValue } = args;
  let newValue: string | undefined = value;

  if (callValidateValue !== undefined) {
    if (value === null || value.length === 0) {
      callValidateValue(undefined);
      setValue(undefined);
      setIsValidValue(true);
      newValue = undefined;
    } else {
      value = fixEventText(isCurrency, isInteger, value);
      if (!isCurrency) {
        value = ev.target.value = parseFloat(value).toString().replace(/,/g, '');
      }

      if (isNumeric(value) && value !== null) {
        callValidateValue(value);
        setValue(value);
        setIsValidValue(true);
        newValue = value;
      } else {
        callValidateValue(undefined);
        setValue(undefined);
        setIsValidValue(false);
        newValue = undefined;
      }
    }
  }

  if (onBlurAllValues) {
    onBlurAllValues(ev, getNewValues(args, ev.target.id, newValue));
  }
};

/** This function is the shared body for calls to handleNumberPaste for first/second input. */
export const handleNumberPaste = (args: NumericRangeHandlerProps, ev: React.ClipboardEvent<HTMLInputElement>): void => {
  const { isCurrency, isInteger, onChangeAllValues } = args;

  const fixedText = fixEventText(isCurrency, isInteger, ev.clipboardData.getData('text'));

  ev.currentTarget.value = isCurrency ? fixedText : parseFloat(fixedText).toString().replace(/,/g, '');
  const fakeEvent = new FakeEvent({ value: ev.currentTarget.value });

  if (onChangeAllValues) {
    onChangeAllValues(fakeEvent, getNewValues(args, ev.currentTarget.id, ev.currentTarget.value));
  }
};

/**
 * This toggles "Show items with no quantity" (default) checkbox.
 * Use only if one or both of the numbers are required.
 */
export const handleShowNoNumbers = (args: NumericRangeHandlerProps, ev: React.ChangeEvent<HTMLInputElement>): void => {
  const { allValues, onChangeAllValues, setShowNoNumbers } = args;
  setShowNoNumbers(!allValues.checked);

  if (onChangeAllValues) {
    onChangeAllValues(ev, getNewValues(args, ev.target.id, !allValues.checked));
  }
};
