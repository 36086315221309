import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPharmacyLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15 13.9987H9M12 10.998V16.9993M17.5 20V22.2205C17.5172 22.4054 17.4622 22.5898 17.3467 22.7352C17.2312 22.8806 17.064 22.9757 16.88 23.0007H7.12003C6.93605 22.9757 6.76888 22.8806 6.65335 22.7352C6.53782 22.5898 6.48287 22.4054 6.50003 22.2205V20M1 7.99731V12.5484C1.00083 15.3095 2.03959 17.9693 3.91003 20H20.09C21.9604 17.9693 22.9992 15.3095 23 12.5484V7.99731H1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3325 6.99707L9.18408 8.17724H16.889C16.889 8.17724 17.2542 7.66938 17.7457 6.99707H10.3325ZM11.306 5.99707H18.4816C19.0531 5.22644 19.6416 4.44918 19.9886 4.03642C20.3662 3.5676 20.5568 2.97011 20.5223 2.36369C20.4879 1.75727 20.2309 1.18661 19.8027 0.765674C19.6083 0.541167 19.3723 0.358155 19.1084 0.227468C18.8445 0.0967814 18.5579 0.0210141 18.2652 0.00427405C17.9724 -0.012466 17.6794 0.0302495 17.4029 0.130034C17.1264 0.229819 16.872 0.38476 16.6543 0.585702L11.306 5.99707Z"
      fill={props.color}
    />
  </svg>
);
SvgPharmacyLarge.displayName = 'SvgPharmacyLarge';
const PharmacyLarge = withForgeIconProps(SvgPharmacyLarge, {
  name: 'Pharmacy',
  usage: 'Use to indicate a pharmacy or pharmacist',
  related: 'rx, prescription, pharmaceutical, chemist',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default PharmacyLarge;
