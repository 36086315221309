import SimpleOptions from './SimpleOptions.gen';
import ComplexOptions from './ComplexOptions.gen';
import RadioChildren from './RadioChildren.gen';
import Standalone from './Standalone.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <SimpleOptions />
      <ComplexOptions />
      <RadioChildren />
      <Standalone />
    </>
  );
}
