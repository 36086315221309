import { ChangeEventHandler, createContext, useContext } from 'react';

export interface RadioGroupContextData {
  name?: string;
  value?: string;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
  tabIndex?: number;
  required?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  alignment?: 'horizontal' | 'vertical';
}

const RadioGroupContext = createContext<RadioGroupContextData>({});
export default RadioGroupContext;

interface UseIsCheckedArgs {
  checked?: boolean;
  value: string;
}
export function useIsChecked({ checked, value }: UseIsCheckedArgs): boolean | undefined {
  const context = useContext(RadioGroupContext);

  // Prefer `checked` from props if available
  if (checked !== undefined) {
    return checked;
  }

  // If a value is supplied through context, use that
  if (context.value !== undefined) {
    return context.value === value;
  }

  return undefined;
}

interface UseIsDefaultCheckedArgs {
  defaultChecked?: boolean;
  value: string;
}
export function useIsDefaultChecked({ defaultChecked, value }: UseIsDefaultCheckedArgs): boolean | undefined {
  const context = useContext(RadioGroupContext);

  // Prefer `defaultChecked` from props if available
  if (defaultChecked !== undefined) {
    return defaultChecked;
  }

  // If a defaultValue is supplied through context, use that
  if (context.defaultValue !== undefined) {
    return context.defaultValue === value;
  }

  return undefined;
}
