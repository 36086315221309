import Button from '../../../Button';
import { DataTableStore } from '../../store/stateTypes';
import { classes } from '../../utils/dataTableClasses';
import { getAppliedFilterCountFromState } from '../../utils/getAppliedFilterCountFromState';

// Actions
import { reset_paginator } from '../../store/actions/reset_paginator';
import { reset_column_filters } from '../../store/actions/reset_column_filters';
import { reset_data_filters } from '../../store/actions/reset_date_filters';
import { reset_quick_filters } from '../../store/actions/reset_quick_filters';
import { apply_client_side_filters } from '../../store/actions/apply_client_side_filters';
import { apply_server_side_filters } from '../../store/actions/apply_server_side_filters';

type DataFilterClearAllButtonProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function DataFilterClearAllButton<RowData>({ store }: DataFilterClearAllButtonProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const appliedFilterCount = store.useSelector((s) => getAppliedFilterCountFromState(s));

  return (
    <Button
      {...classes({ element: 'filter_panel__clear_all_button' })}
      text={`Clear All (${appliedFilterCount})`}
      variant="tertiary"
      onClick={() => {
        dispatch(function user_click_clear_all_button(s) {
          reset_paginator(s);
          reset_quick_filters(s);
          reset_data_filters(s);
          reset_column_filters(s);

          // reset row ids
          s.filteredAndSortedRowIds = Object.values(s.rows)
            .map((r) => r.rowId)
            .sort((a, b) => a - b);

          apply_client_side_filters(s);
          apply_server_side_filters(s);
        });
      }}
    />
  );
}
