import rawContents from "!!raw-loader!./SegmentedButtonDifferentBehaviors.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function SegmentedButtonDifferentBehaviors():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Segmented Button Different Behaviors" />;
}

export default SegmentedButtonDifferentBehaviors;
