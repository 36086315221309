import InputUseWithinAFormField from './InputUseWithinAFormField.gen';
import InputProperties from './InputProperties.gen';
import InputStandalone from './InputStandalone.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <InputUseWithinAFormField />
      <InputProperties />
      <InputStandalone />
    </>
  );
}
