import { ReactElement, useContext } from 'react';
import { Link } from 'gatsby';
import { PageContext } from '../../contexts';

import { Card } from '@athena/forge';
import { MenuItem } from '../../components/page-context-provider/page-context-provider';
import './component-menu-grid.scss';
import { componentImages } from '../page-context-provider/component-images';

export default function ComponentMenuGrid(): ReactElement {
  const { pageContext, childParentMap, menuItems } = useContext(PageContext);

  if (childParentMap && menuItems) {
    const parentPageId = pageContext?.id && childParentMap[pageContext.id];
    const parentMenuItem = menuItems.find((menuItem: MenuItem) => menuItem.route.parameters.node === parentPageId);
    const childMenuItems = parentMenuItem?.childrenMenuItems || [];

    return (
      <section className="component-menu-grid">
        {childMenuItems.map((item) => {
          let componentImage;
          let contribution;
          if (Object.prototype.hasOwnProperty.call(componentImages, item.title)) {
            componentImage = componentImages[item.title as keyof typeof componentImages];
            contribution = false;
          } else {
            componentImage = componentImages.ContributedComponentLanding;
            contribution = true;
          }
          return (
            <Link to={item.url.replace('/forge', '')} key={item.title} className="component-menu-grid__card-container">
              <Card>
                <div className={`component-menu-grid__${contribution ? 'contributed-image' : 'component-image'}`}>
                  {componentImage}
                </div>
                <div>{item.title}</div>
              </Card>
            </Link>
          );
        })}
      </section>
    );
  } else {
    console.error('Error generating component menu grid');
    return <></>;
  }
}
