import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClinicalAccelerateLeftLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M14 1C16.3869 1 18.6762 1.94822 20.364 3.63605C22.0518 5.32387 23 7.61305 23 10C23 12.3869 22.0518 14.6761 20.364 16.364C18.6762 18.0518 16.3869 19 14 19H1M1 19L4.99001 23M1 19L5 15"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgClinicalAccelerateLeftLarge.displayName = 'SvgClinicalAccelerateLeftLarge';
const ClinicalAccelerateLeftLarge = withForgeIconProps(
  SvgClinicalAccelerateLeftLarge,
  {
    name: 'ClinicalAccelerateLeft',
    usage:
      'Use to accelerate problems and medications to the clinical encounter from right to left',
    related: 'ClinicalAccelerateRight',
    isLabelRequired: true,
    isNeutralInteractive: false,
    size: 'large',
  }
);
export default ClinicalAccelerateLeftLarge;
