import Button from '../../../Button';
import Label from '../../../Label';
import { DataTableStore } from '../../store/stateTypes';
import { classes } from '../../utils/dataTableClasses';
import { getAppliedFilterCountFromState } from '../../utils/getAppliedFilterCountFromState';

// Filter Panel Components
import { DataFilterHeader } from './DataFilterHeader';
import { DataFilterTags } from './DataFilterTags';
import { DataFilterClearAllButton } from './DataFilterClearAllButton';

// Actions
import { apply_client_side_filters } from '../../store/actions/apply_client_side_filters';
import { apply_server_side_filters } from '../../store/actions/apply_server_side_filters';
import { reset_column_filters } from '../../store/actions/reset_column_filters';
import { reset_paginator } from '../../store/actions/reset_paginator';
import { reset_quick_filters } from '../../store/actions/reset_quick_filters';

type DataFilterPanelProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function DataFilterPanel<RowData>({ store }: DataFilterPanelProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const showFilterPanel = store.useSelector((s) => s.dataFilter.show);
  const isFilterPanelExpanded = store.useSelector((s) => s.dataFilter.isExpanded);
  const dataFilterIds = store.useSelector((s) => s.dataFilter.filterIds);
  const dataFilters = store.useSelector((s) => s.dataFilter.filters);
  const appliedFilterCount = store.useSelector((s) => getAppliedFilterCountFromState(s));
  const isFilterChanged = store.useSelector((s) => {
    return Object.values(s.dataFilter.filters).some((df) => {
      // Deeply compare the object condition value
      return JSON.stringify(df.conditionValues) !== JSON.stringify(df.originalConditionValues);
    });
  });

  const hasQuickFilters = store.useSelector((s) => s.quickFilter.layout !== 'none');
  const { className } = classes({
    element: 'filter_panel',
    modifiers: {
      'show-top-border': hasQuickFilters,
      'is-collapsed': !isFilterPanelExpanded,
    },
  });

  // ==================================
  // 1. No Filter Panel
  // ==================================
  if (!showFilterPanel) {
    return <></>;
  }

  // ==================================
  // 2. Collapsed Filter Panel
  // ==================================
  if (!isFilterPanelExpanded) {
    return (
      <div className={className}>
        <DataFilterHeader store={store} />
      </div>
    );
  }

  // ==================================
  // 3. Expanded Filter Panel
  // ==================================
  return (
    <div className={className}>
      {/* Filter Header */}
      <DataFilterHeader store={store} />

      {/* Filter Options */}
      <div {...classes({ element: 'filter_panel__options' })}>
        {dataFilterIds.map((fid) => {
          const { filterId, filterLabel, DataFilterComponent } = dataFilters[fid];
          return (
            <div data-filter-id={filterId} key={filterId}>
              <Label text={filterLabel} />
              <DataFilterComponent
                useSelector={store.useSelector}
                useDispatch={store.useDispatch}
                useDispatchAsyncAction={store.useDispatchAsyncAction}
                onFilterConditionValuesChange={(conditionValue) => {
                  dispatch(function update_data_filter_condition(s) {
                    s.dataFilter.filters[filterId].conditionValues = conditionValue;
                  });
                }}
              />
            </div>
          );
        })}
      </div>

      {/* Apply Button & Cancel Button */}
      <div {...classes({ element: 'filter_panel__apply_and_cancel_buttons' })}>
        {/* Apply Button */}
        <Button
          text="Apply"
          disabled={!isFilterChanged}
          onClick={() =>
            dispatch(function filter_by_data_filters(s) {
              // save the filters
              Object.values(s.dataFilter.filters).forEach((df) => {
                df.originalConditionValues = df.conditionValues;
              });

              reset_paginator(s);
              reset_column_filters(s);
              reset_quick_filters(s);
              apply_client_side_filters(s);
              apply_server_side_filters(s);
            })
          }
        />

        {/* Cancel Button */}
        <Button
          text="Cancel"
          variant="secondary"
          disabled={!isFilterChanged}
          onClick={() => {
            dispatch(function restore_condition_values(s) {
              Object.values(s.dataFilter.filters).forEach((df) => {
                df.conditionValues = df.originalConditionValues;
              });
            });
          }}
        />
      </div>

      {/* Filter Tags & Clear All Button */}
      {appliedFilterCount > 0 && (
        <div {...classes({ element: 'filter_panel__filter_tags_and_clear_all' })}>
          {/* Data Filter Tags */}
          <DataFilterTags store={store} />

          {/* Clear All Button */}
          <DataFilterClearAllButton store={store} />
        </div>
      )}
    </div>
  );
}
