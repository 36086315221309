import Button from '../../Button';
import TrashSmall from '@athena/forge-icons/dist/TrashSmall';
import TrashLarge from '@athena/forge-icons/dist/TrashLarge';
import { AlertData, DataTableHooksAndRowId, ModalAlertData, ToastAlertData } from '../utils/internalTypes';
import { delete_row_async } from '../store/asyncActions/delete_row_async';
import { ACTION_BUTTON_SIZES } from '../utils/constants';

export type DeleteRowActionArgs<RowData> = {
  /** Optional function which takes a row object and determines if the row should have its delete button disabled. */
  shouldDeleteRowActionDisabled?: (rowData: RowData) => boolean;
  serverSideDeleteRowFn?: (rowData: RowData) => Promise<void>;
  onRowDeleted?: (rowData: RowData) => void;
  confirmModal?: (rowData: RowData) => AlertData;
  completeModal?: (rowData: RowData) => ModalAlertData;
  completeToast?: (rowData: RowData) => ToastAlertData;
};
export type CreateDeleteRowActionButton<RowData> = typeof createDeleteRowActionButton<RowData>;

export function createDeleteRowActionButton<RowData>({
  serverSideDeleteRowFn,
  shouldDeleteRowActionDisabled,
  onRowDeleted,
  confirmModal,
  completeModal,
  completeToast,
}: DeleteRowActionArgs<RowData> = {}) {
  return function DeleteRowActionButton({
    useDispatchAsyncAction,
    useSelector,
    rowId,
  }: DataTableHooksAndRowId<RowData>): JSX.Element {
    const dispatchAsyncAction = useDispatchAsyncAction();
    const rowData = useSelector((s) => s.rows[rowId].data);
    const tableLayout = useSelector((s) => s.tableLayout);
    const tableType = useSelector((s) => s.tableType);

    return (
      <Button
        data-row-action-name="delete-row"
        icon={{ medium: TrashLarge, compact: TrashSmall }[tableLayout]}
        size={ACTION_BUTTON_SIZES[tableLayout]}
        variant="tertiary"
        disabled={shouldDeleteRowActionDisabled?.(rowData) ?? false}
        onClick={(event) => {
          // prevent trigger left click on row
          event.stopPropagation();

          dispatchAsyncAction(
            delete_row_async({
              tableType,
              rowData,
              rowId,
              serverSideDeleteRowFn,
              onRowDeleted,
              confirmModal,
              completeModal,
              completeToast,
            })
          );
        }}
      />
    );
  };
}
