import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMergeSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.00001 2C3.00001 2.55228 2.55229 3 2 3C1.44772 3 1 2.55229 1 2C1 1.44772 1.44772 1 2.00001 1C2.55229 1 3.00001 1.44771 3.00001 2Z"
      fill={props.color}
    />
    <path
      d="M3 14C3 14.5523 2.55228 15 2 15C1.44771 15 1 14.5523 1 14C1 13.4477 1.44772 13 2 13C2.55229 13 3 13.4477 3 14Z"
      fill={props.color}
    />
    <path
      d="M3.41423 1.99999C3.0237 1.60947 2.39054 1.60947 2.00001 1.99999C1.60949 2.39052 1.60949 3.02368 2.00001 3.41421L3.41423 1.99999ZM2.00066 12.5864C1.61013 12.977 1.61013 13.6101 2.00066 14.0007C2.39118 14.3912 3.02434 14.3912 3.41487 14.0007L2.00066 12.5864ZM15 8.00001L15.7069 8.70734C15.8946 8.51978 16 8.26534 16 8.00001C16 7.73469 15.8946 7.48024 15.7069 7.29269L15 8.00001ZM12.5249 4.11268C12.1342 3.72228 11.5011 3.72248 11.1107 4.11313C10.7203 4.50377 10.7205 5.13694 11.1111 5.52734L12.5249 4.11268ZM11.1111 10.4727C10.7205 10.8631 10.7203 11.4963 11.1107 11.8869C11.5011 12.2775 12.1343 12.2777 12.5249 11.8873L11.1111 10.4727ZM2.00001 3.41421L7.29356 8.70775L8.70777 7.29354L3.41423 1.99999L2.00001 3.41421ZM7.29356 7.29354L2.00066 12.5864L3.41487 14.0007L8.70777 8.70775L7.29356 7.29354ZM11.1111 5.52734L14.2931 8.70734L15.7069 7.29269L12.5249 4.11268L11.1111 5.52734ZM14.2931 7.29269L11.1111 10.4727L12.5249 11.8873L15.7069 8.70734L14.2931 7.29269ZM8.00075 9.00064L15.0001 9.00001L14.9999 7.00001L8.00057 7.00065L8.00075 9.00064ZM2.00001 2L2 2V4C3.10457 4 4.00001 3.10457 4.00001 2H2.00001ZM2 2L2 2H4.88758e-06C4.88758e-06 3.10457 0.895436 4 2 4V2ZM2 2L2.00001 2V0C0.895436 0 4.88758e-06 0.895431 4.88758e-06 2H2ZM2.00001 2L2.00001 2H4.00001C4.00001 0.89543 3.10457 0 2.00001 0V2ZM2 14H2V16C3.10457 16 4 15.1046 4 14H2ZM2 14L2 14H0C0 15.1046 0.895431 16 2 16V14ZM2 14L2 14V12C0.89543 12 0 12.8954 0 14H2ZM2 14H2H4C4 12.8954 3.10457 12 2 12V14Z"
      fill={props.color}
    />
  </svg>
);
SvgMergeSmall.displayName = 'SvgMergeSmall';
const MergeSmall = withForgeIconProps(SvgMergeSmall, {
  name: 'Merge',
  usage:
    'Use to represent the point at which two paths or journeys join to become one path',
  related: 'converge, junction',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default MergeSmall;
