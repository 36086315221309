import { useEffect, useState } from 'react';
import Checkbox from '../../../../Checkbox';
import Loader from '../../../../Loader';
import List from '../../../../List';
import ListItem from '../../../../ListItem';
import { AwaitableModal } from '../../../alerts/AwaitableModal';
import { DataTableColumn, DataTableValueTextOption } from '../../../utils/internalTypes';

type CreateSelectionListColumnFilterArgs<RowData> = {
  rowDataFieldName: keyof RowData;
  fetchOptions: () => Promise<DataTableValueTextOption[]>;
  serverSideQueryParamFn?: (selectedOptions: ReadonlyArray<string>) => string;
};
export type CreateSelectionListColumnFilter<RowData> = typeof createSelectionListColumnFilter<RowData>;

export function createSelectionListColumnFilter<RowData>({
  rowDataFieldName,
  fetchOptions,
  serverSideQueryParamFn,
}: CreateSelectionListColumnFilterArgs<RowData>): DataTableColumn<RowData, string>['columnFilter'] {
  return {
    serverSideQueryParamFn:
      typeof serverSideQueryParamFn !== 'undefined'
        ? ({ conditionValues }) => serverSideQueryParamFn(conditionValues)
        : undefined,

    clientSideColumnFilterFn: ({ rowData, conditionValues }) => {
      const conditionValueSet = new Set(conditionValues);
      return conditionValueSet.has(String(rowData[rowDataFieldName]));
    },

    ColumnFilterComponent: ({ columnId, useSelector, useDispatch, onFilterConditionValuesChange }) => {
      const dispatch = useDispatch();
      const tableId = useSelector((s) => s.tableId);
      const conditionValues = useSelector((s) => s.columns[columnId]?.filter.conditionValues ?? []) as string[];

      // ==============================
      // Fetch the options
      // ==============================
      const [isLoading, setIsLoading] = useState(true);
      const [options, setOptions] = useState<DataTableValueTextOption[]>([]);
      useEffect(() => {
        (async () => {
          try {
            const options = await fetchOptions();
            setOptions(options);
          } catch (e) {
            await AwaitableModal(dispatch, {
              alertType: 'attention',
              headerText: 'Fetch Options Failed',
              children: (e as Error).message,
            });
          }
          setIsLoading(false);
        })();
      }, [dispatch, setIsLoading, setOptions]);

      const isAllSelected = options.length === conditionValues.length;

      return (
        <Loader loading={isLoading} text="Loading the options...">
          {/* Select All Checkbox */}
          <Checkbox
            description="All"
            id={`${tableId}-selection-list-column-filter-${columnId}`}
            className="fe_u_padding--left-small fe_u_padding--bottom-xsmall"
            checked={isAllSelected}
            onChange={() => onFilterConditionValuesChange(isAllSelected ? [] : options.map((opt) => opt.value))}
          />
          {/* Selection List */}
          <List
            variant="selection"
            className="fe_u_font-weight--regular"
            value={conditionValues}
            onChange={(e) => {
              onFilterConditionValuesChange(e.target.value);
            }}
          >
            {options.map((opt) => (
              <ListItem key={opt.value} uniqueKey={opt.value}>
                {opt.label}
              </ListItem>
            ))}
          </List>
        </Loader>
      );
    },
  };
}
