import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPaymentMethodsLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.8907 19.0007H20.1107M15.4407 16.0007H20.1107M10.5007 16.0007H11.5007M7.00073 11.0007H23.0007V20.0007C23.0007 20.7964 22.6847 21.5595 22.1221 22.1221C21.5594 22.6847 20.7964 23.0007 20.0007 23.0007H7.00073V11.0007Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7043 0.985598L17.5757 0L16.5894 0.122547L0.989514 2.0607L0 2.18363L0.120086 3.1735L1.55512 15.0024L1.6763 16.0013L2.67441 15.8739L5.08327 15.5666V13.5504L3.41938 13.7627L2.58639 6.89631L16.2386 5.21233L16.741 9.06457H18.7579L17.7043 0.985598ZM18.8883 10.0646H16.8714L17.0097 11.125C17.0811 11.6726 17.5829 12.0587 18.1306 11.9873C18.6782 11.9159 19.0643 11.414 18.9929 10.8664L18.8883 10.0646ZM6.08327 13.4228V15.439L6.9795 15.3246C7.52734 15.2547 7.91479 14.754 7.84489 14.2061C7.77499 13.6583 7.27421 13.2708 6.72637 13.3407L6.08327 13.4228ZM15.9799 3.22908L2.34552 4.91087L2.22562 3.9225L15.8496 2.22983L15.9799 3.22908Z"
      fill={props.color}
    />
  </svg>
);
SvgPaymentMethodsLarge.displayName = 'SvgPaymentMethodsLarge';
const PaymentMethodsLarge = withForgeIconProps(SvgPaymentMethodsLarge, {
  name: 'PaymentMethods',
  usage: 'Use to indicate or view payment methods',
  related: 'credit, debit, currency, check, cash',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default PaymentMethodsLarge;
