import React, { ReactElement, ReactNode, useCallback, useMemo, useRef, useState, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import ForgePropTypes from '../utils/propTypes';
import { CSSTransition } from 'react-transition-group';
import Heading from '../Heading';
import ListItem from '../ListItem';
import { forgeClassHelper } from '../utils/classes';
import AccordionItemHeader from '../AccordionItemHeader/AccordionItemHeader';
import ExpandSmall from '@athena/forge-icons/dist/ExpandSmall';
import { HeadingTags } from '../utils/constants';
import { EnterHandler, ExitHandler } from 'react-transition-group/Transition';

const classes = forgeClassHelper({ name: 'accordion-item' });

export interface AccordionItemProps {
  /** The content that is shown when the `AccordionItem` is open */
  children: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Set the initial expanded/collapsed state of the AccordionItem */
  defaultExpanded?: boolean;
  /**
   * Set the accordion item to be expanded or collapsed.
   * Setting this property makes this a controlled component
   */
  expanded?: boolean;
  /** Custom content rendered in the header. */
  headerSlot?: React.ReactNode;
  /**
   * Specifies the heading's HTML tag. For more on the importance of heading tags in semantic HTML,
   * [see the W3C's article on this topic](https://www.w3.org/WAI/tutorials/page-structure/headings/).
   */
  headingTag?: HeadingTags;
  /** Text that will be displayed in the heading */
  headingText: string;
  /** If set to true, AccordionItem content will stay mounted after it is collapsed  */
  mountedWhileHidden?: boolean;
  /** Callback for when the Accordion is expanded **/
  onExpandedChange?: (expanded: boolean) => void;
  /** If true, adds default padding to the AccordionItem content */
  padded?: boolean;
  /** Set custom tab order for accessability */
  tabIndex?: number;
}

/** Determines if AccordionItem `expanded` state is being controlled through props */
function isInControlledMode(expanded?: boolean): expanded is boolean {
  return expanded !== undefined;
}

/** Determines if a React child is a valid header for the AccordionItem */
function isValidHeader(child: React.ReactChild | React.ReactFragment | React.ReactPortal): boolean {
  if (!React.isValidElement(child)) {
    return false;
  } else if (
    child.type === AccordionItemHeader ||
    (typeof child.type !== 'string' && (child.type as { displayName?: string }).displayName === 'AccordionItemHeader')
  ) {
    return true;
  } else {
    return false;
  }
}

/** filters children to only contain valid headers */
function filterValidHeaders(children: ReactNode): (React.ReactChild | React.ReactFragment | React.ReactPortal)[] {
  return React.Children.toArray(children).filter((child) => isValidHeader(child));
}

/** filters children to only contain content */
function filterContent(children: ReactNode): (React.ReactChild | React.ReactFragment | React.ReactPortal)[] {
  return React.Children.toArray(children).filter((child) => !isValidHeader(child));
}

/**
 * AccordionItem
 * @documentedBy Accordion
 */

function AccordionItem({
  expanded: upstreamExpanded,
  defaultExpanded = false,
  className,
  headingTag = 'h3',
  headingText,
  mountedWhileHidden = false,
  onExpandedChange, // eslint-disable-line no-unused-vars
  children,
  headerSlot,
  padded = true,
  tabIndex = 0,
  ...passedProps
}: AccordionItemProps): ReactElement {
  const [expandedState, setExpandedState] = useState(() =>
    isInControlledMode(upstreamExpanded) ? upstreamExpanded : defaultExpanded
  );
  const [exited, setExited] = useState(!expandedState);
  const innerRef = useRef<HTMLDivElement>(null);

  /** expanded can be set through either props or state. Prefer props */
  const expanded = useMemo(
    () => (isInControlledMode(upstreamExpanded) ? upstreamExpanded : expandedState),
    [upstreamExpanded, expandedState]
  );

  /** Causes AccordionItem to expand or contract */
  const toggleExpand = useCallback(() => {
    setExpandedState((prevExpanded) => (isInControlledMode(upstreamExpanded) ? upstreamExpanded : !prevExpanded));

    if (onExpandedChange) {
      onExpandedChange(!expanded);
    }
  }, [expanded, onExpandedChange, upstreamExpanded]);

  /**
   * Callback fired before the "entering" status is applied. An extra
   * parameter `isAppearing` is supplied to indicate if the enter stage is
   * occurring on the initial mount
   */
  const handleEnter: EnterHandler<HTMLDivElement> = () => {
    setExited(false);
    if (innerRef.current) {
      innerRef.current.style.height = '0';
    }
  };

  /**
   * Callback fired after the "entering" status is applied. An extra parameter
   * isAppearing is supplied to indicate if the enter stage is occurring on
   * the initial mount
   */
  const handleEntering: EnterHandler<HTMLDivElement> = () => {
    if (innerRef.current) {
      innerRef.current.style.height = `${innerRef.current.scrollHeight}px`;
    }
  };

  /**
   * Callback fired after the "entered" status is applied. An extra parameter
   * isAppearing is supplied to indicate if the enter stage is occurring on
   * the initial mount
   */
  const handleEntered: EnterHandler<HTMLDivElement> = () => {
    if (innerRef.current) {
      innerRef.current.style.height = '';
    }
  };

  /**
   * Callback fired before the "exiting" status is applied.
   */
  const handleExit: ExitHandler<HTMLDivElement> = () => {
    if (innerRef.current) {
      innerRef.current.style.height = `${innerRef.current.scrollHeight}px`;
    }
  };

  /**
   * Callback fired after the "exiting" status is applied.
   */
  const handleExiting: ExitHandler<HTMLDivElement> = () => {
    if (innerRef.current) {
      innerRef.current.style.height = '0';
    }
  };

  /**
   * Callback fired after the "exited" status is applied.
   */
  const handleExited: ExitHandler<HTMLDivElement> = () => {
    setExited(true);
  };

  const itemHeader = filterValidHeaders(children);
  const hasItemHeader = itemHeader?.length > 0;

  const contentChildren = hasItemHeader ? filterContent(children) : children;

  return (
    <ListItem uniqueKey={tabIndex.toString()} {...classes({ states: { expanded }, extra: className })} {...passedProps}>
      {/*
          Use a <div> with role=button instead of a real <button> because a
          real <button> can't have complex content.
          */}
      <div
        {...classes({ element: 'header', states: { expanded } })}
        onClick={toggleExpand}
        onKeyDown={(event) => {
          const eventKey = event.key;
          if (eventKey === 'Enter' || eventKey === ' ') {
            toggleExpand();
          }
        }}
        aria-expanded={!exited}
        role="button"
        tabIndex={tabIndex}
      >
        <ExpandSmall
          title={expanded ? 'Collapse' : 'Expand'}
          aria-hidden="true"
          focusable="false"
          {...classes({ element: 'expand', states: { expanded } })}
        />

        {hasItemHeader ? (
          itemHeader
        ) : (
          <Heading
            text={headingText}
            variant="section"
            headingTag={headingTag}
            {...classes({ element: 'heading-text' })}
          />
        )}
        {headerSlot}
      </div>
      <div
        {...classes({
          element: 'content',
          states: {
            exited,
            expanded: expanded,
          },
        })}
      >
        <CSSTransition<HTMLDivElement>
          nodeRef={innerRef}
          in={expanded}
          classNames="fe_is-expanding"
          timeout={400}
          onEnter={handleEnter}
          onEntering={handleEntering}
          onEntered={handleEntered}
          onExit={handleExit}
          onExiting={handleExiting}
          onExited={handleExited}
          mountOnEnter={!mountedWhileHidden}
          unmountOnExit={!mountedWhileHidden}
        >
          <div
            ref={innerRef}
            {...classes({ element: 'content-inner' })}
            style={exited && mountedWhileHidden ? { display: 'none' } : {}}
          >
            {padded ? <div {...classes({ element: 'padded-content' })}>{contentChildren}</div> : contentChildren}
          </div>
        </CSSTransition>
      </div>
    </ListItem>
  );
}

const accordionItemPropTypes: WeakValidationMap<AccordionItemProps> = {
  /** The content that is shown when the `AccordionItem` is open */
  children: PropTypes.node.isRequired,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Set the initial expanded/collapsed state of the AccordionItem */
  defaultExpanded: PropTypes.bool,
  /**
   * Set the accordion item to be expanded or collapsed.
   * Setting this property makes this a controlled component
   */
  expanded: PropTypes.bool,
  /** Custom content rendered in the header. */
  headerSlot: PropTypes.node,
  /**
   * Specifies the heading's HTML tag. For more on the importance of heading tags in semantic HTML,
   * [see the W3C's article on this topic](https://www.w3.org/WAI/tutorials/page-structure/headings/).
   */
  headingTag: ForgePropTypes.headingTags,
  /** Text that will be displayed in the heading */
  headingText: PropTypes.string.isRequired,
  /** If set to true, AccordionItem content will stay mounted after it is collapsed  */
  mountedWhileHidden: PropTypes.bool,
  /** Callback for when the Accordion is expanded **/
  onExpandedChange: PropTypes.func,
  /** If true, adds default padding to the AccordionItem content */
  padded: PropTypes.bool,
  /** Set custom tab order for accessability */
  tabIndex: PropTypes.number,
};
AccordionItem.propTypes = accordionItemPropTypes;
AccordionItem.displayName = 'AccordionItem';

export default AccordionItem;
