import RawDataPreview from '../../components/raw-data-preview';

import rawContents from "!!raw-loader!./SingleSelectSynchronous.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { RawDataPreview };

function SingleSelectSynchronous():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Single Select Synchronous" />;
}

export default SingleSelectSynchronous;
