import { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import parse from 'html-react-parser';
import { defaultOptions } from '../html-parsing-options';
import Layout from '../components/layout';
import { Banner, BannerItem } from '@athena/forge';
import TertiaryNav from '../components/tertiary-nav/tertiary-nav';
import Header from '../components/content-blocks/header';
import PageHeader from '../components/page-header';
import './component-page.scss';
import { GatsbyPageContext, PageData, ChildBlock } from '../contexts';

export default function NonComponentPage({
  data,
  pageContext,
  location,
}: PageProps<PageData, GatsbyPageContext>): ReactElement {
  if (data.newNodeInfo) {
    // type: node--non_component_page
    const {
      newNodeInfo: {
        childrenBlock,
        title,
        field_non_component_summary: summary,
        field_deprecated,
        field_deprecated_reason,
      },
    } = data;

    const blocks = childrenBlock?.map((block: ChildBlock, index: number) => {
      if (block.drupal_type === 'paragraph--header') {
        return <Header {...block} key={`header-${index}`} />;
      } else {
        return <></>;
      }
    });

    const deprecatedReason = parse(
      field_deprecated_reason
        ? field_deprecated_reason
        : '<p>This component has been deprecated and will be removed in the next version of Forge.</p>',
      defaultOptions()
    ) as JSX.Element[];

    return (
      <>
        <Layout pageContext={pageContext} title={title} summary={summary} pageData={data} location={location}>
          <div className="component-flex">
            <div className="content">
              <PageHeader title={title} summary={summary} />
              {field_deprecated && (
                <div>
                  <Banner alertType="attention">
                    <BannerItem headerText="Deprecated">{deprecatedReason}</BannerItem>
                  </Banner>
                </div>
              )}
              {blocks}
            </div>
            <TertiaryNav />
          </div>
        </Layout>
      </>
    );
  } else {
    console.error(`Could not render component: ${data?.path}`);
    return <></>;
  }
}

export const query = graphql`
  query ($title: String) {
    newNodeInfo: nonComponentPage(title: { eq: $title }) {
      title
      field_non_component_summary
      field_deprecated
      field_deprecated_reason
      childrenBlock {
        id
        field_title
        field_text_body
        field_card_body
        field_card_link
        field_information_body
        field_information_type
        field_don_t_description
        field_do_description
        field_do_image
        field_don_t_image
        field_image
        field_markdown_body
        drupal_type
        childrenBlock {
          id
          field_title
          field_text_body
          field_card_body
          field_card_link
          field_information_body
          field_information_type
          field_don_t_description
          field_do_description
          field_do_image
          field_don_t_image
          field_image
          field_markdown_body
          drupal_type
          childrenBlock {
            id
            field_title
            field_text_body
            field_card_body
            field_card_link
            field_information_body
            field_information_type
            field_don_t_description
            field_do_description
            field_do_image
            field_don_t_image
            field_image
            field_markdown_body
            drupal_type
          }
        }
      }
    }
  }
`;
