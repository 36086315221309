import React, { WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { gridRowClasses, getModifiers } from '../GridRow/grid-classes.js';

export type GridColAlignType = 'top' | 'bottom' | 'middle' | 'stretch';

export const gridColAlignments: GridColAlignType[] = ['top', 'bottom', 'middle', 'stretch'];
export interface GridColOffset {
  small?: number;
  medium?: number;
  large?: number;
}

export interface GridColOrder {
  small?: number;
  medium?: number;
  large?: number;
}

export interface GridColWidth {
  small?: number;
  medium?: number | 'default';
  large?: number | 'default';
}

export interface GridColProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Vertical alignment of GridCols; stretch is the default behavior */
  align?: GridColAlignType;
  /** Children to display within the GridCol */
  children?: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Number of units a GridCol should be offset from the previous GridCol at each breakpoint;
  same value used at larger breakpoints unless specified*/
  offset?: GridColOffset;
  /** Order that a GridCol appears at each breakpoint; same value used at larger breakpoints unless specified*/
  order?: GridColOrder;
  /** Width of the GridCol in units at each breakpoint; same value used at larger breakpoints unless specified.
  Accepts 'default' as a value to set the width to auto size
  */
  width?: GridColWidth;
}
/**
 * GridCol
 * @documentedBy Grid
 */
const GridCol = function (props: GridColProps): React.ReactElement {
  const { align, children, className, offset, order, width, ...colProps } = props;

  let modifiers = [];

  align && modifiers.push('align-' + align);

  offset && (modifiers = modifiers.concat(getModifiers('offset', offset)));
  order && (modifiers = modifiers.concat(getModifiers('order', order)));
  width && (modifiers = modifiers.concat(getModifiers(undefined, width)));

  return (
    <div {...gridRowClasses('col', modifiers, '', className)} {...colProps}>
      {children}
    </div>
  );
};

const gridColPropTypes: WeakValidationMap<GridColProps & { '...rest': unknown }> = {
  /** Vertical alignment of GridCols; stretch is the default behavior */
  align: PropTypes.oneOf(gridColAlignments),
  /** Children to display within the GridCol */
  children: PropTypes.node,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Number of units a GridCol should be offset from the previous GridCol at each breakpoint;
  same value used at larger breakpoints unless specified*/
  offset: PropTypes.shape({
    small: PropTypes.number,
    medium: PropTypes.number,
    large: PropTypes.number,
  }) as PropTypes.Requireable<GridColOffset>,
  /** Order that a GridCol appears at each breakpoint; same value used at larger breakpoints unless specified*/
  order: PropTypes.shape({
    small: PropTypes.number,
    medium: PropTypes.number,
    large: PropTypes.number,
  }) as PropTypes.Requireable<GridColOrder>,
  /** Width of the GridCol in units at each breakpoint; same value used at larger breakpoints unless specified.
  Accepts 'default' as a value to set the width to auto size
  */
  width: PropTypes.shape({
    small: PropTypes.number,
    medium: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['default'])]),
    large: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['default'])]),
  }) as PropTypes.Requireable<GridColWidth>,
  /** Passthrough props are added to the `div` element */
  '...rest': PropTypes.any,
};

GridCol.ALIGNMENTS = gridColAlignments;

GridCol.propTypes = gridColPropTypes;

export default GridCol;
