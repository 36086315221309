import PropTypes from 'prop-types';
import React, { ReactElement, WeakValidationMap } from 'react';
import Button, { ButtonHTMLElement, ButtonProps, ButtonVariant } from '../Button';
import Heading from '../Heading';
import Hidden from '../Hidden';

import NotFoundSvg from './images/NotFound.svg';
import StartListSvg from './images/StartList.svg';
import ExtendedWaitSvg from './images/ExtendedWait.svg';
import TasksCompleteSvg from './images/TasksComplete.svg';
import NoFilterDataSvg from './images/NoFilterData.svg';
import RestrictedSvg from './images/Restricted.svg';
import TimedOutSvg from './images/TimedOut.svg';
import ErrorSvg from './images/Error.svg';

export const emptyStatePresets = {
  NotFound: {
    image: <NotFoundSvg role="img" aria-label="Microscope" />,
    headline: 'Nothing found',
    detail: 'Nothing matched what you searched for.',
    primaryButtonProps: {
      text: 'Search Again',
    },
    secondaryButtonProps: {
      text: 'Go Back',
    },
  },
  ExtendedWait: {
    image: <ExtendedWaitSvg role="img" aria-label="Chair" />,
    headline: 'This may take a while',
    detail: 'You can leave this page and do other work. The system will let you know when this task is done.',
    primaryButtonProps: {
      text: 'Go to Home Page',
    },
    secondaryButtonProps: null,
  },
  TasksComplete: {
    image: <TasksCompleteSvg role="img" aria-label="Coffee mug" />,
    headline: 'All done!',
    detail: 'It’s so satisfying to see an empty task list. Take a break!',
    primaryButtonProps: {
      text: 'Go to Home Page',
    },
    secondaryButtonProps: null,
  },
  NoFilterData: {
    image: <NoFilterDataSvg role="img" aria-label="Empty beaker" />,
    headline: 'No data to show for these settings',
    detail: 'Try changing the settings to see other data.',
    primaryButtonProps: {
      text: 'Update Settings',
    },
    secondaryButtonProps: {
      text: 'Go Back',
    },
  },
  StartList: {
    image: <StartListSvg role="img" aria-label="Add pills" />,
    headline: 'Add to this list',
    detail: 'Get started by adding things here.',
    primaryButtonProps: {
      text: 'Add',
    },
    secondaryButtonProps: null,
  },
  Restricted: {
    image: <RestrictedSvg role="img" aria-label="Padlock" />,
    headline: 'This information requires permission to view',
    detail: 'Contact your athenaNet administrator about getting the permissions to view this restricted information.',
    primaryButtonProps: {
      text: 'Go Back',
    },
    secondaryButtonProps: null,
  },
  TimedOut: {
    image: <TimedOutSvg role="img" aria-label="Clock" />,
    headline: 'Your session has timed out',
    detail: 'Please login again to view this restricted information.',
    primaryButtonProps: {
      text: 'Login',
    },
    secondaryButtonProps: null,
  },
  Error: {
    image: <ErrorSvg role="img" aria-label="Bandage" />,
    headline: '',
    detail: '',
    primaryButtonProps: null,
    secondaryButtonProps: null,
  },
};

/** A supported preset type string */
export type EmptyStatePresetType =
  | 'NotFound'
  | 'ExtendedWait'
  | 'TasksComplete'
  | 'NoFilterData'
  | 'StartList'
  | 'Restricted'
  | 'TimedOut'
  | 'Error';
/** The list of supported preset types */
export const emptyStatePresetTypes: readonly EmptyStatePresetType[] = [
  'NotFound',
  'ExtendedWait',
  'TasksComplete',
  'NoFilterData',
  'StartList',
  'Restricted',
  'TimedOut',
  'Error',
];

export interface EmptyStateProps<PrimaryButtonUseLink extends boolean, SecondaryButtonUseLink extends boolean> {
  /** Replace the default primary and secondary buttons with any valid JSX. */
  buttonRow?: JSX.Element;
  /** Content to display below the headline. Setting this to null removes it. */
  detail?: string | null;
  /** Headline text to display below the image. Setting this to null removes it. */
  headline?: string | null;
  /** Image to display at the top. Setting this to null removes it. */
  image?: JSX.Element | null;
  /** Props passed on to the primary CTA button. These can be any valid [Button](https://guide.forge.athena.io/components/button#props-tables) component props. Setting this to null removes it. */
  primaryButtonProps?: ButtonProps<PrimaryButtonUseLink> | null;
  /** Props passed on to the secondary CTA button. These can be any valid [Button](https://guide.forge.athena.io/components/button#props-tables) component props. Setting this to null removes it. */
  secondaryButtonProps?: ButtonProps<SecondaryButtonUseLink> | null;
  /** Specify a preset with default content applied. The default content can be overridden or removed using other props. If no preset is selected, a default image with no text is shown */
  type?: EmptyStatePresetType;
}

interface EmptyStateButtonProps<UseLink extends boolean> {
  upstreamButtonProps?: ButtonProps<UseLink> | null;
  buttonVariant: ButtonVariant;
  buttonProps: ButtonProps<UseLink>;
}
/** Renders the primary and secondary buttons for EmptyState */
function EmptyStateButton<UseLink extends boolean>({
  upstreamButtonProps,
  buttonVariant,
  buttonProps,
}: EmptyStateButtonProps<UseLink>): ReactElement {
  if (upstreamButtonProps === null || Object.entries(buttonProps).length === 0) {
    return <></>;
  } else {
    /** Typescript has trouble with RefAttributes and IntrinsicAttributes when
     * dealing with generic components. Be very explicit to work around this
     * limitation.
     */
    const refAttributes = {} as React.RefAttributes<ButtonHTMLElement<UseLink>>;
    const buttonPropsWithVariant: ButtonProps<UseLink> = {
      variant: buttonVariant,
      ...buttonProps,
    };
    const modifiedButtonProps: ButtonProps<UseLink> & React.RefAttributes<ButtonHTMLElement<UseLink>> = {
      ...refAttributes,
      ...buttonPropsWithVariant,
    };
    return <Button {...modifiedButtonProps} />;
  }
}

function EmptyState<PrimaryButtonUseLink extends boolean = false, SecondaryButtonUseLink extends boolean = false>({
  type = 'Error',
  image,
  headline,
  detail,
  buttonRow,
  primaryButtonProps: upstreamPrimaryButtonProps,
  secondaryButtonProps: upstreamSecondaryButtonProps,
}: EmptyStateProps<PrimaryButtonUseLink, SecondaryButtonUseLink>): ReactElement {
  const presetProps = emptyStatePresetTypes.indexOf(type) > -1 ? emptyStatePresets[type] : emptyStatePresets['Error'];
  const primaryButtonProps: ButtonProps<PrimaryButtonUseLink> = {
    ...(presetProps.primaryButtonProps as ButtonProps<PrimaryButtonUseLink>),
    ...upstreamPrimaryButtonProps,
  };
  const secondaryButtonProps: ButtonProps<SecondaryButtonUseLink> = {
    ...(presetProps.secondaryButtonProps as ButtonProps<SecondaryButtonUseLink>),
    ...upstreamSecondaryButtonProps,
  };
  return (
    <div className="fe_c_empty-state">
      <div className="fe_c_empty-state__image">{image === null ? null : image || presetProps.image}</div>
      {headline === null ? null : <Heading variant="subsection" text={headline || presetProps.headline} />}
      {detail === null ? null : (
        <Hidden below="desktop">
          <p className="fe_c_empty-state__detail">{detail || presetProps.detail}</p>
        </Hidden>
      )}
      <div className="fe_c_empty-state__buttonRow">
        {buttonRow || (
          <>
            <EmptyStateButton
              upstreamButtonProps={upstreamPrimaryButtonProps}
              buttonProps={primaryButtonProps}
              buttonVariant="primary"
            />
            <EmptyStateButton
              upstreamButtonProps={upstreamSecondaryButtonProps}
              buttonProps={secondaryButtonProps}
              buttonVariant="secondary"
            />
          </>
        )}
      </div>
    </div>
  );
}

export const emptyStatePropTypes: WeakValidationMap<EmptyStateProps<boolean, boolean> & { '...rest': unknown }> = {
  /** Props passed on to the primary CTA button. These can be any valid [Button](https://guide.forge.athena.io/components/button#props-tables) component props. Setting this to null removes it. */
  primaryButtonProps: PropTypes.object,

  /** Props passed on to the secondary CTA button. These can be any valid [Button](https://guide.forge.athena.io/components/button#props-tables) component props. Setting this to null removes it. */
  secondaryButtonProps: PropTypes.object,

  /** Replace the default primary and secondary buttons with any valid JSX. */
  buttonRow: PropTypes.element,

  /** Content to display below the headline. Setting this to null removes it. */
  detail: PropTypes.string,

  /** Headline text to display below the image. Setting this to null removes it. */
  headline: PropTypes.string,

  /** Image to display at the top. Setting this to null removes it. */
  image: PropTypes.element,

  /** Specify a preset with default content applied. The default content can be overridden or removed using other props. If no preset is selected, a default image with no text is shown */
  type: PropTypes.oneOf(emptyStatePresetTypes),
};
EmptyState.propTypes = emptyStatePropTypes;

export default EmptyState;
