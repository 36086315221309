import { useMemo, useState } from 'react';
import Select, { StructuredOption } from '../../../Select';
import Button from '../../../Button';
import { DataTableStore } from '../../store/stateTypes';
import { getSelectedRowIds } from '../../utils/getSelectedRowIds';

// Actions
import { deselect_all_rows } from '../../store/actions/deselect_all_rows';
import { apply_bulk_edit_async } from '../../store/asyncActions/apply_bulk_edit_async';

import { classes } from '../../utils/dataTableClasses';
import { hasRowsInEditStatusFromState } from '../../utils/hasRowsInEditStatusFromState';
import { DataTableBlockUI } from '../../utils/DataTableBlockUI';
import { hasControlPanelFromState } from '../../utils/hasControlPanelFromState';

type BulkEditRowLayoutProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function BulkEditRowLayout<RowData>({ store }: BulkEditRowLayoutProps<RowData>): JSX.Element {
  const EMPTY_STATE = '';
  const dispatch = store.useDispatch();
  const dispatchAsyncAction = store.useDispatchAsyncAction();
  const tableType = store.useSelector((s) => s.tableType);
  const bulkEdit = store.useSelector((s) => s.bulkEdit);
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));
  const [selectOptionValue, setSelectOptionValue] = useState<string>(EMPTY_STATE);
  // This will be NaN if the selectOptionValue is EMPTY_STATE
  const selectOptionIndex = parseInt(selectOptionValue, 10);

  // Get selected Row Ids
  const rows = store.useSelector((s) => s.rows);
  const filteredAndSortedRowIds = store.useSelector((s) => s.filteredAndSortedRowIds);
  const selectedRowIds = getSelectedRowIds({ filteredAndSortedRowIds, rows });

  const options: StructuredOption[] = useMemo(
    () =>
      bulkEdit.options.map((opt, index) => ({
        label: opt.name,
        value: String(index), // number string. e.g. '0', '1', '2', ...
      })),
    [bulkEdit.options]
  );

  const hasTableTopBorder = store.useSelector((s) => {
    if (hasControlPanelFromState(s)) return true;
    if (s.quickFilter.layout !== 'none' || s.dataFilter.show) return false; // has quick filter or data filter panel
    return true;
  });

  // Don't show the bulk edit button if no options
  if (bulkEdit.layout !== 'row' || bulkEdit.options.length === 0) {
    return <></>;
  }

  return (
    <DataTableBlockUI block={hasRowsInEditStatus}>
      <div
        aria-label="bulk-edit-row-panel"
        {...classes({
          element: 'bulk_edit__row_layout',
          modifiers: {
            selected: selectedRowIds.length > 0,
            'has-top-border': hasTableTopBorder,
          },
        })}
      >
        <div className="fe_u_font-weight--semibold">{selectedRowIds.length} selected</div>
        <Select
          value={isNaN(selectOptionIndex) ? null : options[selectOptionIndex]}
          options={options}
          onChange={(e) => setSelectOptionValue(e.target.value?.value || EMPTY_STATE)}
        />

        {/* Change Button */}
        <Button
          text="Change"
          variant="secondary"
          disabled={selectOptionValue === EMPTY_STATE || selectedRowIds.length === 0}
          onClick={() => {
            dispatchAsyncAction(
              apply_bulk_edit_async({
                tableType,
                rows,
                bulkEdit,
                selectedRowIds,
                selectedBulkEditOptionIndex: selectOptionIndex,
              })
            );
          }}
        />

        {/* Deselect All Button */}
        <Button
          text="Deselect all"
          variant="tertiary"
          disabled={selectedRowIds.length === 0}
          onClick={() => dispatch(deselect_all_rows)}
        />
      </div>
    </DataTableBlockUI>
  );
}
