import rawContents from "!!raw-loader!./BannerBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function BannerBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Banner Basic" />;
}

export default BannerBasic;
