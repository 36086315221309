import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHideLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.97609 5.97032C2.91728 7.08305 0.805933 9.09978 0.103544 11.7432L0.0353088 12L0.103544 12.2568C1.20367 16.3971 5.76022 19 12 19C14.2596 19 16.2984 18.6587 18.0324 18.0266L16.4485 16.4428C15.1751 16.7985 13.691 17 12 17C6.34233 17 3.00137 14.745 2.11214 12C2.73622 10.0735 4.56789 8.38838 7.56048 7.55471L5.97609 5.97032ZM18.4911 15.6569C20.2833 14.7416 21.422 13.438 21.8879 12C20.9986 9.25502 17.6577 7 12 7C11.2757 7 10.5894 7.03696 9.94136 7.10716L8.19148 5.35728C9.36952 5.12273 10.6451 5 12 5C18.2398 5 22.7963 7.60291 23.8965 11.7432L23.9647 12L23.8965 12.2568C23.3543 14.2971 21.9729 15.964 19.9643 17.1301L18.4911 15.6569Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.478 8.47223C7.58314 9.37644 7.03003 10.6232 7.03003 12C7.03003 14.7614 9.25518 17 12 17C13.3793 17 14.6273 16.4348 15.5278 15.522L8.478 8.47223ZM16.6371 13.8029C16.8521 13.2436 16.97 12.6357 16.97 12C16.97 9.23858 14.7449 7 12 7C11.3576 7 10.7437 7.12262 10.1801 7.34586L16.6371 13.8029Z"
      fill={props.color}
    />
    <path
      d="M1 1L23 23"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgHideLarge.displayName = 'SvgHideLarge';
const HideLarge = withForgeIconProps(SvgHideLarge, {
  name: 'Hide',
  usage: 'Use to indicate an item is hidden, or to unhide an item',
  related: 'eye, secret, private',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default HideLarge;
