import React, {
  ChangeEventHandler,
  LiHTMLAttributes,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  RefObject,
  SyntheticEvent,
  WeakValidationMap,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import ShowHide from '../ShowHide';
import { forgeClassHelper } from '../utils/classes';
import CheckboxInput from '../Checkbox/CheckboxInput';
import ExpandSmall from '@athena/forge-icons/dist/ExpandSmall';

export const classes = forgeClassHelper({ name: 'treenode' });

export interface TreeNodeProps extends Omit<LiHTMLAttributes<HTMLLIElement>, 'title' | 'onSelect'> {
  /** Allows tree node to have a checkbox */
  checkable?: boolean;
  /** If the tree node is checked (controlled) */
  checked?: boolean;
  /** Children, should only be other tree nodes
   *
   * Note that the `children` property on the `data` prop of the Tree component
   * does not get passed directly as the `children` prop on TreeNode.
   * This is because the `children` property used by the `data` prop on the Tree
   * component does not take an array of TreeNode components but an array of
   * DataNodes, an object abstraction of TreeNode props.
   */
  children?: ReactElement<TreeNodeProps> | ReactElement<TreeNodeProps>[];
  /** Custom class name */
  className?: string;
  /** If the node is disabled */
  disabled?: boolean;
  /** If the node should show dividing lines around it */
  dividers?: boolean;
  /** If the node has children and is expanded */
  expanded?: boolean;
  /** If the node has children and is expanded */
  focused?: boolean;
  /** Unique id for each node */
  id: string;
  /** If the checkbox is neither true nor false */
  indeterminate?: boolean;
  /** Callback for checkbox being checked or unchecked */
  onCheck?: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  /** Callback for branch nodes (nodes with children, can be expanded/collapsed)
   * that is triggered when collapse animation is complete */
  onCollapsed?: (id: string) => void;
  /** Callback for branch nodes (nodes with children, can be expanded/collapsed)
   * that is triggered when expand animation is complete */
  onExpanded?: (id: string) => void;
  /** Callback for row click with event and node id */
  onRowClick?: (event: SyntheticEvent<HTMLLIElement>, id: string) => void;
  /** Callback for leaf nodes (nodes without children, can't be expanded/collapsed)
   * that is triggered when the node is selected by mouse click or keyboard */
  onSelect?: (event: React.MouseEvent<HTMLLabelElement>, id: string) => void;
  /** Callback for branch nodes (nodes with children, can be expanded/collapsed)
   * that is triggered when the node is expanded or collapsed by mouse click or keyboard */
  onToggle?: (event: React.MouseEvent<SVGSVGElement>, id: string) => void;
  /** Custom render function
   *
   * (title) => ReactNode
   *
   * The parameter `title` is whatever is passed to the `title` prop. This
   * parameter is supplied because Tree's renderTitle prop acts as a default
   * for all TreeNode components that it renders.
   */
  renderTitle?: (title?: ReactNode) => ReactNode;
  /** If the node is selected */
  selected?: boolean;
  /** Title text displayed
   * * Can be any valid ReactNode
   */
  title?: ReactNode;
}

interface NodeContentProps
  extends Pick<
    TreeNodeProps,
    'checkable' | 'checked' | 'disabled' | 'id' | 'indeterminate' | 'onCheck' | 'onSelect' | 'renderTitle' | 'title'
  > {
  labelId: string;
}

/** Renders the TreeNode content that is common to whether the node has children
 * or not.
 */
const NodeContent = ({
  checkable,
  checked,
  disabled,
  id,
  indeterminate,
  labelId,
  onCheck,
  onSelect,
  renderTitle,
  title,
}: NodeContentProps): ReactElement => {
  const handleSelect = React.useCallback<MouseEventHandler<HTMLLabelElement>>(
    (event) => onSelect && !disabled && onSelect(event, id),
    [onSelect, disabled, id]
  );
  const handleCheck = React.useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onCheck && !disabled && onCheck(event, id);
    },
    [onCheck, disabled, id]
  );
  return (
    <div
      {...classes({
        element: 'title',
        states: { checkable: !!checkable },
      })}
    >
      {checkable && (
        <CheckboxInput
          {...{ id, checked, disabled, indeterminate, onChange: handleCheck }}
          aria-labelledby={labelId}
          tabIndex={-1}
        />
      )}
      <label
        {...classes({
          element: 'label',
          states: { checkable: !!checkable },
        })}
        id={labelId}
        onClick={handleSelect}
      >
        {renderTitle ? renderTitle(title) : title}
      </label>
    </div>
  );
};

/**
 * Tree Node
 *
 * @documentedBy Tree
 */
const TreeNode = ({
  id,
  'aria-posinset': ariaPosinset,
  'aria-setsize': ariaSetsize,
  'aria-level': ariaLevel,
  checkable,
  checked,
  children,
  className,
  disabled,
  dividers,
  indeterminate,
  expanded,
  focused,
  selected,
  onCheck,
  onCollapsed,
  onExpanded,
  onSelect,
  onToggle,
  onRowClick,
  renderTitle,
  title,
}: TreeNodeProps): ReactElement => {
  const focusElement = useRef<HTMLDivElement | HTMLLIElement>(null);
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      if (focused) {
        focusElement.current?.focus();
      }
    } else {
      didMount.current = true;
    }
  }, [id, focused, expanded]);

  const handleToggle = React.useCallback<MouseEventHandler<SVGSVGElement>>(
    (event) => onToggle && !disabled && onToggle(event, id),
    [onToggle, disabled, id]
  );
  const handleExpanded = React.useCallback(() => onExpanded && onExpanded(id), [onExpanded, id]);
  const handleCollapsed = React.useCallback(() => onCollapsed && onCollapsed(id), [onCollapsed, id]);
  const handleRowClick = React.useCallback<MouseEventHandler<HTMLLIElement>>(
    (event) => {
      // Prevents parent click events from firing and changing focus
      event.stopPropagation();
      // Prevents row clicks from changing focus state before checkbox state change
      if (event.target instanceof Element && event.target.id === `checkbox-mark-${id}`) {
        return;
      } else {
        onRowClick?.(event, id);
      }
    },
    [onRowClick, id]
  );

  const labelId = `treenode-label-${id}`;
  return children ? (
    <li
      role="treeitem"
      aria-expanded={expanded}
      aria-checked={checked}
      aria-selected={selected}
      aria-posinset={ariaPosinset}
      aria-setsize={ariaSetsize}
      aria-labelledby={labelId}
      aria-level={ariaLevel}
      onClick={handleRowClick}
      {...classes({
        element: 'wrapper',
        modifiers: { dividers: !!dividers },
        states: { expanded: !!expanded, checkable: !!checkable },
        extra: className,
      })}
    >
      <div
        {...classes({
          modifiers: 'has-children',
          states: { expanded: !!expanded, selected: !!selected, disabled: !!disabled },
        })}
        tabIndex={focused ? 0 : -1}
        ref={focusElement as RefObject<HTMLDivElement>}
      >
        <div {...classes({ element: 'expand-wrapper' })}>
          <ExpandSmall
            aria-hidden="true"
            focusable="false"
            onClick={handleToggle}
            title={expanded ? 'Collapse' : 'Expand'}
            {...classes({ element: 'expand', states: { expanded: !!expanded, disabled: !!disabled } })}
          />
        </div>
        <NodeContent
          checkable={checkable}
          onCheck={onCheck}
          onSelect={onSelect}
          disabled={disabled}
          id={id}
          checked={checked}
          indeterminate={indeterminate}
          labelId={labelId}
          renderTitle={renderTitle}
          title={title}
        />
      </div>
      <ShowHide show={expanded} appear={false} role="presentation" onShown={handleExpanded} onHidden={handleCollapsed}>
        <ul {...classes({ element: 'children' })} role="group">
          {children}
        </ul>
      </ShowHide>
    </li>
  ) : (
    <li
      {...classes({
        modifiers: { dividers: !!dividers },
        states: { selected: !!selected, disabled: !!disabled, checkable: !!checkable },
        extra: className,
      })}
      aria-checked={checked}
      aria-selected={selected}
      onClick={handleRowClick}
      role="treeitem"
      aria-posinset={ariaPosinset}
      aria-setsize={ariaSetsize}
      aria-level={ariaLevel}
      tabIndex={focused ? 0 : -1}
      ref={focusElement as RefObject<HTMLLIElement>}
    >
      <NodeContent
        checkable={checkable}
        onCheck={onCheck}
        onSelect={onSelect}
        disabled={disabled}
        id={id}
        checked={checked}
        indeterminate={indeterminate}
        labelId={labelId}
        renderTitle={renderTitle}
        title={title}
      />
    </li>
  );
};

const treeNodePropTypes: WeakValidationMap<TreeNodeProps> = {
  /** Allows tree node to have a checkbox */
  checkable: PropTypes.bool,
  /** If the tree node is checked (controlled) */
  checked: PropTypes.bool,
  /** Custom class name */
  className: PropTypes.string,
  /** If the node is disabled */
  disabled: PropTypes.bool,
  /** If the node should show dividing lines around it */
  dividers: PropTypes.bool,
  /** If the node has children and is expanded */
  expanded: PropTypes.bool,
  /** If the node has children and is expanded */
  focused: PropTypes.bool,
  /** Unique id for each node */
  id: PropTypes.string.isRequired,
  /** If the checkbox is neither true nor false */
  indeterminate: PropTypes.bool,
  /** Callback for checkbox being checked or unchecked */
  onCheck: PropTypes.func,
  /** Callback for branch nodes (nodes with children, can be expanded/collapsed)
   * that is triggered when collapse animation is complete */
  onCollapsed: PropTypes.func,
  /** Callback for branch nodes (nodes with children, can be expanded/collapsed)
   * that is triggered when expand animation is complete */
  onExpanded: PropTypes.func,
  /** Callback for row click with event and node id */
  onRowClick: PropTypes.func,
  /** Callback for leaf nodes (nodes without children, can't be expanded/collapsed)
   * that is triggered when the node is selected by mouse click or keyboard */
  onSelect: PropTypes.func,
  /** Callback for branch nodes (nodes with children, can be expanded/collapsed)
   * that is triggered when the node is expanded or collapsed by mouse click or keyboard */
  onToggle: PropTypes.func,
  /** Custom render function
   *
   * (title) => ReactNode
   *
   * The parameter `title` is whatever is passed to the `title` prop. This
   * parameter is supplied because Tree's renderTitle prop acts as a default
   * for all TreeNode components that it renders.
   */
  renderTitle: PropTypes.func,
  /** If the node is selected */
  selected: PropTypes.bool,
  /** Title text displayed
   * * Can be any valid ReactNode
   */
  title: PropTypes.node,
};
TreeNode.propTypes = treeNodePropTypes;

export default TreeNode;
