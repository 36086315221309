import { useEffect, useRef } from 'react';
import { DataTableProps } from '../DataTable';
import { DataTableState, DataTableStore } from '../store/stateTypes';

type StateChangeObserverProps<RowData> = {
  store: DataTableStore<RowData>;
} & Pick<
  DataTableProps<RowData>,
  | 'onStateChanged' // prettier-break-line
  | 'onSelectedRowsChanged'
>;

export function StateChangeObserver<RowData>({
  store,
  onStateChanged,
  onSelectedRowsChanged,
}: StateChangeObserverProps<RowData>): JSX.Element {
  const state = store.useSelector((s) => s);
  const previousStateRef = useRef(state);

  useEffect(() => {
    const prev = previousStateRef.current;
    previousStateRef.current = state;

    // 1. onStateChanged
    onStateChanged?.(state, prev);

    // 2. onSelectedRowsChanged
    if (onSelectedRowsChanged) {
      const currentSelectedRowIds = getSelectedRowIdsFromState(state);
      const previousSelectedRowIds = getSelectedRowIdsFromState(prev);

      if (currentSelectedRowIds.join('-') !== previousSelectedRowIds.join('-')) {
        onSelectedRowsChanged(
          currentSelectedRowIds.map((rowId) => state.rows[rowId].data), // current rows
          previousSelectedRowIds.map((rowId) => prev.rows[rowId].data) // previous rows
        );
      }
    }
  }, [
    state, // prettier-break-line
    onStateChanged,
    onSelectedRowsChanged,
  ]);

  return <></>;
}

// ====================================
// Utils
// ====================================
function getSelectedRowIdsFromState<RowData>(state: DataTableState<RowData>): number[] {
  return state.filteredAndSortedRowIds.filter((rowId) => state.rows[rowId]?.isSelected ?? false);
}
