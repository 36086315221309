import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCallLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.25002 20.0799C1.06857 19.8289 0.981134 19.5222 1.00307 19.2132C1.02501 18.9043 1.15492 18.6128 1.37001 18.39L4.90004 14.8599C5.14179 14.6193 5.46901 14.4843 5.81008 14.4843C6.15115 14.4843 6.47825 14.6193 6.71999 14.8599L8.01003 16.15C8.23293 16.328 8.50253 16.4378 8.7864 16.4661C9.07026 16.4945 9.35626 16.4404 9.61001 16.31C11.0821 15.6349 12.4046 14.6727 13.5 13.4799C14.7042 12.3908 15.668 11.0625 16.33 9.57991C16.459 9.32523 16.5116 9.03872 16.4815 8.75483C16.4513 8.47095 16.3397 8.20182 16.1601 7.97993L14.87 6.68989C14.6342 6.44566 14.5023 6.1195 14.5023 5.77998C14.5023 5.44046 14.6342 5.11417 14.87 4.86995L18.4101 1.36995C18.6305 1.15346 18.9213 1.02331 19.2296 1.00312C19.5379 0.982937 19.8432 1.07407 20.09 1.25996C26.69 6.05996 20.39 13.7 17.58 16.67L17.13 17.1199L16.69 17.56C13.7 20.38 6.06002 26.7099 1.25002 20.0799Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgCallLarge.displayName = 'SvgCallLarge';
const CallLarge = withForgeIconProps(SvgCallLarge, {
  name: 'Call',
  usage: 'Use to indicate a phone number or the ability to call',
  related: 'mobile, contact',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default CallLarge;
