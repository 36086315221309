import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgExportLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M21 19.92L20 19.92L20 19.9233L21 19.92ZM17.9399 23L17.9399 24L17.9432 24L17.9399 23ZM1 23H0V24H1V23ZM1 3V2H0V3H1ZM13 0C12.4477 0 12 0.447715 12 1C12 1.55228 12.4477 2 13 2V0ZM23 1H24C24 0.447715 23.5523 0 23 0V1ZM22 11C22 11.5523 22.4477 12 23 12C23.5523 12 24 11.5523 24 11H22ZM12.2929 10.2929C11.9024 10.6834 11.9024 11.3166 12.2929 11.7071C12.6834 12.0976 13.3166 12.0976 13.7071 11.7071L12.2929 10.2929ZM20 14.48V19.92H22V14.48H20ZM20 19.9233C20.0009 20.1951 19.9482 20.4644 19.845 20.7159L21.6953 21.4753C21.8982 20.9808 22.0017 20.4513 22 19.9168L20 19.9233ZM19.845 20.7159C19.7418 20.9674 19.5901 21.1961 19.3985 21.389L20.8173 22.7985C21.194 22.4194 21.4924 21.9697 21.6953 21.4753L19.845 20.7159ZM19.3985 21.389C19.2069 21.5818 18.9793 21.735 18.7284 21.8398L19.4998 23.6851C19.9929 23.4789 20.4406 23.1777 20.8173 22.7985L19.3985 21.389ZM18.7284 21.8398C18.4776 21.9447 18.2085 21.9991 17.9367 22L17.9432 24C18.4777 23.9983 19.0067 23.8912 19.4998 23.6851L18.7284 21.8398ZM17.9399 22H1V24H17.9399V22ZM2 23V3H0V23H2ZM1 4H8.83997V2H1V4ZM13 2H23V0H13V2ZM22 1V11H24V1H22ZM13.7071 11.7071L23.7071 1.70711L22.2929 0.292893L12.2929 10.2929L13.7071 11.7071Z"
      fill={props.color}
    />
  </svg>
);
SvgExportLarge.displayName = 'SvgExportLarge';
const ExportLarge = withForgeIconProps(SvgExportLarge, {
  name: 'Export',
  usage: 'Use to export a file or data',
  related: 'download, document, chart, graph',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default ExportLarge;
