import React from 'react';
import { classes } from '../../utils/dataTableClasses';
import { DataTableCellTooltipProps, DataTableColumn } from '../../utils/internalTypes';
import { DataTableState, RowObject } from '../../store/stateTypes';

type DetermineRowAlertArgs<RowData> = { rowData: RowData; rowId: number };
type DetermineRowAlertResults = {
  rowAlertType?: 'attention' | 'critical' | 'new' | 'success';
  customStyle?: React.CSSProperties;
  tooltip?: string;
};

type CreateRowAlertColumnArgs<RowData> = {
  determineRowAlert: (args: DetermineRowAlertArgs<RowData>) => DetermineRowAlertResults;
};
export type CreateRowAlertColumn<RowData> = typeof createRowAlertColumn<RowData>;

export function createRowAlertColumn<RowData>(args?: CreateRowAlertColumnArgs<RowData>): DataTableColumn<RowData> {
  const determineRowAlert = args?.determineRowAlert;

  return {
    columnId: 'row-alert',

    // ================================
    // Header
    // ================================
    Header: () => <></>,

    // ================================
    // Cell
    // ================================
    Cell: ({ useSelector, rowId }) => {
      const row = useSelector((s) => s.rows[rowId]);
      const columns = useSelector((s) => s.columns);
      const { rowAlertType, customStyle } = getRowAlertResult({ row, columns, determineRowAlert });

      return (
        <div
          style={customStyle}
          {...classes({
            element: 'row_alert_column',
            modifiers: rowAlertType,
          })}
        />
      );
    },

    // ================================
    // Cell Tooltip
    // ================================
    determineCellTooltip: ({ row, columns }): DataTableCellTooltipProps => {
      const { tooltip } = getRowAlertResult({ row, columns, determineRowAlert });

      return { text: tooltip, placement: 'top' };
    },
  };
}

// ================================
// Get Row Alert Results
// ================================
type GetRowAlertResultArg<RowData> = {
  row: RowObject<RowData>;
  columns: DataTableState<RowData>['columns'];
  determineRowAlert?: (args: DetermineRowAlertArgs<RowData>) => DetermineRowAlertResults;
};

function getRowAlertResult<RowData>({
  row,
  columns,
  determineRowAlert,
}: GetRowAlertResultArg<RowData>): DetermineRowAlertResults {
  const rowAlertResults = determineRowAlert?.({ rowData: row.data, rowId: row.rowId }) ?? {
    rowAlertType: undefined,
    customStyle: undefined,
    tooltip: '',
  };

  // no row alert type
  if (typeof rowAlertResults.rowAlertType !== 'undefined') {
    return rowAlertResults;
  }

  // check cell alerts
  const hasCellAlerts = Object.values(columns)
    .filter((column) => !column.hide)
    .some((column) => {
      const { determineCellAlert = () => '' } = column;
      return determineCellAlert(row.data) !== '';
    });

  if (!hasCellAlerts) {
    return rowAlertResults;
  }

  return {
    rowAlertType: 'attention',
    tooltip: 'There is an alert for a cell in this row',
  };
}
