import type { DataTableStore } from './store/stateTypes';

// Utils
import { classes } from './utils/dataTableClasses';
import { hasRowsInEditStatusFromState } from './utils/hasRowsInEditStatusFromState';
import { DataTableBlockUI } from './utils/DataTableBlockUI';
import { ServerSideDataFetcher } from './utils/ServerSideDataFetcher';

// Internal Components
import { TableTitlePanel } from './controllers/TableTitlePanel';
import { QuickFilterPanel } from './filters/QuickFilter/QuickFilterPanel';
import { DataFilterPanel } from './filters/DataFilterPanel/DataFilterPanel';
import { ControlPanel } from './controllers/ControlPanel';
import { BulkEditRowLayout } from './bulkEdit/BulkEditRowLayout/BulkEditRowLayout';
import { TableElement } from './table/TableElement';
import { TablePaginator } from './controllers/TablePaginator/TablePaginator';
import { AwaitableModalRenderer } from './alerts/AwaitableModal';
import { DataTableToastManager } from './alerts/DataTableToastManager';

/**
 * `DataTableRenderer` is a component to render the DataTable UI based on the the current state.
 * It has no any props.
 *
 * More details: https://athenaconfluence.athenahealth.com/x/lJEoIg
 */
export function createDataTableRenderer<RowData>(TableStore: DataTableStore<RowData>, tableIdWithUuid: string) {
  return function DataTableRenderer(): JSX.Element {
    const rootStyle = TableStore.useSelector((s) => s.rootStyle);
    const showLoader = TableStore.useSelector((s) => s.externalLoader.show || s.loader.show);
    const hasRowsInEditStatus = TableStore.useSelector((s) => hasRowsInEditStatusFromState(s));
    const hasFilterPanels = TableStore.useSelector((s) => s.quickFilter.layout !== 'none' || s.dataFilter.show);

    // class
    const rootClassName = TableStore.useSelector((s) => s.rootClassName);
    const tableStickyHeaders = TableStore.useSelector((s) => s.tableStickyHeaders);
    const hasOpenedColumnFilter = TableStore.useSelector((s) => {
      return Object.values(s.columns).some((column) => column.filter.isOpen);
    });

    return (
      <div
        id={tableIdWithUuid} // 'id' is not changeable after setup
        data-table-id={tableIdWithUuid.split('--')[0]} // 'data-table-id' is not changeable after setup
        {...classes({
          element: '',
          modifiers: { 'sticky-header-and-footer': tableStickyHeaders },
          extra: rootClassName,
        })}
        style={rootStyle}
      >
        {/* TOP: title panel, quick filter panel, data filter panel, control panel */}
        <div {...classes({ element: 'root-top' })}>
          <DataTableBlockUI block={showLoader}>
            <TableTitlePanel store={TableStore} />
          </DataTableBlockUI>

          {/* Block the Quick Filter Panel and Data Filter Panel when user editing the rows */}
          {hasFilterPanels && (
            <DataTableBlockUI block={showLoader || hasRowsInEditStatus}>
              <div {...classes({ element: 'quick-filter-panel-and-data-filter-panel' })}>
                <QuickFilterPanel store={TableStore} />
                <DataFilterPanel store={TableStore} />
              </div>
            </DataTableBlockUI>
          )}

          <DataTableBlockUI block={showLoader}>
            <ControlPanel store={TableStore} />
            <BulkEditRowLayout store={TableStore} />
          </DataTableBlockUI>
        </div>

        {/* MAIN: main table */}
        <div {...classes({ element: 'root-main', modifiers: { 'has-opened-column-filter': hasOpenedColumnFilter } })}>
          {/* TableElement has implemented the Loader internally */}
          <TableElement store={TableStore} />
        </div>

        {/* BOTTOM: bottom paginator */}
        <div {...classes({ element: 'root-bottom' })}>
          {/* Paginator has implemented the BlockUI internally */}
          <TablePaginator store={TableStore} insideControlPanel={false} />
        </div>

        <AwaitableModalRenderer store={TableStore} />
        <DataTableToastManager store={TableStore} />
        <ServerSideDataFetcher store={TableStore} />
      </div>
    );
  };
}
