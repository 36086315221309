import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLinkLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.66875 18.26L7.0281 19.9399C6.70351 20.275 6.31484 20.5414 5.8852 20.7234C5.45556 20.9053 4.99373 20.9993 4.52713 20.9993C4.06052 20.9993 3.59869 20.9053 3.16906 20.7234C2.73942 20.5414 2.35075 20.275 2.02616 19.9399C1.36744 19.2483 1 18.33 1 17.375C1 16.42 1.36744 15.5017 2.02616 14.8101L8.70869 7.94995C9.03255 7.61362 9.421 7.34608 9.85073 7.16333C10.2805 6.98058 10.7427 6.88647 11.2097 6.88647C11.6767 6.88647 12.1389 6.98058 12.5686 7.16333C12.9983 7.34608 13.3868 7.61362 13.7106 7.94995C14.1244 8.37285 14.4268 8.89166 14.591 9.45996C14.7806 10.0886 14.799 10.7564 14.6442 11.3945C14.4895 12.0326 14.1672 12.618 13.7106 13.0901M15.3312 5.73999L16.9719 4.06006C17.2965 3.72498 17.6852 3.45856 18.1148 3.27661C18.5444 3.09466 19.0063 3.00073 19.4729 3.00073C19.9395 3.00073 20.4013 3.09466 20.8309 3.27661C21.2606 3.45856 21.6493 3.72498 21.9738 4.06006C22.6326 4.75171 23 5.67004 23 6.625C23 7.57996 22.6326 8.49829 21.9738 9.18994L15.2913 16.05C14.9674 16.3864 14.579 16.6539 14.1493 16.8367C13.7195 17.0194 13.2573 17.1135 12.7903 17.1135C12.3233 17.1135 11.8611 17.0194 11.4314 16.8367C11.0017 16.6539 10.6132 16.3864 10.2894 16.05C9.87563 15.6271 9.57317 15.1083 9.40902 14.54C9.21941 13.9114 9.20103 13.2436 9.35578 12.6055C9.51053 11.9674 9.83282 11.382 10.2894 10.9099"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgLinkLarge.displayName = 'SvgLinkLarge';
const LinkLarge = withForgeIconProps(SvgLinkLarge, {
  name: 'Link',
  usage: 'Use to insert or indicate a link',
  related: 'hyperlink',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default LinkLarge;
