import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgImageSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 2H15V11C15 11.7956 14.6839 12.5587 14.1213 13.1213C13.5587 13.6839 12.7956 14 12 14H1V2Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M5.5 8C6.60457 8 7.5 7.10457 7.5 6C7.5 4.89543 6.60457 4 5.5 4C4.39543 4 3.5 4.89543 3.5 6C3.5 7.10457 4.39543 8 5.5 8Z"
      fill={props.color}
    />
    <path
      d="M1.5 11.1899V14H12.89C14.22 13.81 14.63 12.7201 14.47 11.1001L11.13 8.33008C10.8792 8.10726 10.5554 7.98413 10.22 7.98413C9.88452 7.98413 9.56082 8.10726 9.31006 8.33008L6.31006 11.0601L5.09998 9.88989C4.86446 9.65902 4.55304 9.52181 4.22375 9.50342C3.89447 9.48502 3.56982 9.58683 3.31006 9.79004L1.5 11.1899Z"
      fill={props.color}
    />
  </svg>
);
SvgImageSmall.displayName = 'SvgImageSmall';
const ImageSmall = withForgeIconProps(SvgImageSmall, {
  name: 'Image',
  usage: 'Use to indicate a photo, or the ability to add an image',
  related: 'photograph, picture, camera, snapshot, screenshot',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ImageSmall;
