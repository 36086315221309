import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRemoveFormattingSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 1.00037L15 15.0004"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 1.33127V2.39792H2.56665L1.5 1.33127ZM6.47368 3.47652V2.39792H5.39508L2.99753 0.000366211H14.5V2.39792H9.52632V6.52916L6.47368 3.47652ZM6.47368 6.30495V16.0004H9.52632V9.35758L6.47368 6.30495Z"
      fill={props.color}
    />
  </svg>
);
SvgRemoveFormattingSmall.displayName = 'SvgRemoveFormattingSmall';
const RemoveFormattingSmall = withForgeIconProps(SvgRemoveFormattingSmall, {
  name: 'RemoveFormatting',
  usage: 'Use to clear text formatting',
  related: 'text editor, text only, remove, edit, font',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default RemoveFormattingSmall;
