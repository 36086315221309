import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNewSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#23A1BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.10328 3.24761C7.06639 3.10161 6.9409 3 6.79747 3C6.65404 3 6.52854 3.10161 6.49166 3.24761L5.71638 6.31615C5.6348 6.63903 5.40552 6.89767 5.10528 7.00548L3.21434 7.6845C3.08617 7.73052 3 7.85736 3 8C3 8.14264 3.08617 8.26948 3.21434 8.3155L5.10528 8.99452C5.40552 9.10233 5.6348 9.36097 5.71638 9.68385L6.49166 12.7524C6.52854 12.8984 6.65404 13 6.79747 13C6.9409 13 7.06639 12.8984 7.10328 12.7524L7.87856 9.68385C7.96014 9.36097 8.18942 9.10233 8.48966 8.99452L10.3806 8.3155C10.5088 8.26948 10.5949 8.14264 10.5949 8C10.5949 7.85736 10.5088 7.73052 10.3806 7.6845L8.48966 7.00548C8.18942 6.89767 7.96014 6.63903 7.87856 6.31615L7.10328 3.24761Z"
      fill="white"
    />
    <path
      d="M11.7342 3L11.481 4.33333L10.4684 4.66667L11.481 5L11.7342 6.33333L11.9873 5L13 4.66667L11.9873 4.33333L11.7342 3Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.10328 3.24761C7.06639 3.10161 6.9409 3 6.79747 3C6.65404 3 6.52854 3.10161 6.49166 3.24761L5.71638 6.31615C5.6348 6.63903 5.40552 6.89767 5.10528 7.00548L3.21434 7.6845C3.08617 7.73052 3 7.85736 3 8C3 8.14264 3.08617 8.26948 3.21434 8.3155L5.10528 8.99452C5.40552 9.10233 5.6348 9.36097 5.71638 9.68385L6.49166 12.7524C6.52854 12.8984 6.65404 13 6.79747 13C6.9409 13 7.06639 12.8984 7.10328 12.7524L7.87856 9.68385C7.96014 9.36097 8.18942 9.10233 8.48966 8.99452L10.3806 8.3155C10.5088 8.26948 10.5949 8.14264 10.5949 8C10.5949 7.85736 10.5088 7.73052 10.3806 7.6845L8.48966 7.00548C8.18942 6.89767 7.96014 6.63903 7.87856 6.31615L7.10328 3.24761Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M11.7342 3L11.481 4.33333L10.4684 4.66667L11.481 5L11.7342 6.33333L11.9873 5L13 4.66667L11.9873 4.33333L11.7342 3Z"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);
SvgNewSmall.displayName = 'SvgNewSmall';
const NewSmall = withForgeIconProps(SvgNewSmall, {
  name: 'New',
  usage:
    'Use to indicate new content items or statuses. For use in alerts, not buttons; follow Forge interruption design guidance',
  related: 'alert, sparkle',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default NewSmall;
