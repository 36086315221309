import { ReactElement } from 'react';
import Label from '../Label';
import Input from '../Input';
import { forgeClassHelper } from '../utils/classes';
import { FormContextData } from '../Form/FormContext';
import { FormFieldBaseInputProps } from '../FormField/FormFieldTypes';

const classes = forgeClassHelper({ name: 'multi-field' });

interface MultiFieldFieldComponentProps extends Omit<FormFieldBaseInputProps, 'id'> {
  context: FormContextData;
  hideLabelContainers: boolean;
  id: string;
  inputAs: React.ComponentType<FormFieldBaseInputProps>;
  labelText?: string;
  showRequiredLabel: (
    hideRequiredStyles: boolean | undefined,
    required: boolean | undefined,
    context: FormContextData
  ) => boolean;
}
/** Renders an individual field within a MultiField
 *
 * This could be a generic component, because `inputProps` extends MultiFieldBaseInputProps,
 * rather than being strictly equal to MultiFieldBaseInputProps. However, since
 * this is an internal component, and generic React component inevitably require
 * aggressive type assertions to play nice, it's easier to make this private
 * component non-generic and do a gentler type assertion in MultiField.tsx
 */
const MultiFieldFieldComponent = ({
  context,
  disabled,
  hideLabelContainers,
  hideRequiredStyles,
  id,
  inputAs,
  labelText,
  required,
  showRequiredLabel,
  error,
  ...inputProps
}: MultiFieldFieldComponentProps): ReactElement => {
  const InputComponent = inputAs || Input;
  const labelId = `${id}-label`;

  return (
    <div {...classes({ element: 'field', modifiers: [id] })}>
      <div
        {...classes({
          element: 'label-container',
          modifiers: { empty: !labelText, hidden: hideLabelContainers },
        })}
      >
        <Label
          id={labelId}
          htmlFor={id}
          text={labelText}
          required={showRequiredLabel(hideRequiredStyles, required, context)}
          disabled={disabled}
          {...classes({
            element: 'label',
            modifiers: { empty: !labelText },
          })}
        />
      </div>
      <InputComponent
        {...inputProps}
        id={id}
        required={required}
        hideRequiredStyles={hideRequiredStyles || (context && context.requiredVariation === 'allFieldsRequired')}
        disabled={disabled}
        error={error}
        aria-describedby={`${id}-input`}
        aria-errormessage={`${id}-input-error`}
        aria-invalid={!!error}
        aria-labelledby={labelId}
      />
    </div>
  );
};

export default MultiFieldFieldComponent;
