import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGraphSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15 1L8 8L6 6L1 11M15 1H10.55M15 1L15 5.68994M1 15H15"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgGraphSmall.displayName = 'SvgGraphSmall';
const GraphSmall = withForgeIconProps(SvgGraphSmall, {
  name: 'Graph',
  usage:
    'Use to indicate a graph or flowsheet, or the ability to switch to a graph or flowsheet view',
  related: 'table, list, chart, flowchart, data',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default GraphSmall;
