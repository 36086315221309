import ChartSmall from '@athena/forge-icons/dist/ChartSmall';

import rawContents from "!!raw-loader!./ButtonWithIcon.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { ChartSmall };

function ButtonWithIcon():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Button With Icon" />;
}

export default ButtonWithIcon;
