import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTeamSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.49719 11.5278H4.12719"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2272 3.39778C13.2272 4.11575 12.6452 4.69778 11.9272 4.69778C11.2092 4.69778 10.6272 4.11575 10.6272 3.39778C10.6272 2.67981 11.2092 2.09778 11.9272 2.09778C12.6452 2.09778 13.2272 2.67981 13.2272 3.39778Z"
      fill={props.color}
      stroke={props.color}
    />
    <path
      d="M11.1671 8.6578L13.0971 7.3578C13.9771 7.9378 14.4571 9.1878 14.5271 10.8978C14.7271 13.2978 12.8271 13.0978 12.8271 13.0978H12.2271M8.22714 8.0778C9.37714 8.9678 9.99714 9.8778 10.1271 11.9978C10.1271 15.2478 7.57714 14.9978 7.57714 14.9978H1.72714C1.48714 14.9978 0.777144 10.0778 3.41714 8.0578L5.82714 10.3378L8.22714 8.0778ZM8.22714 3.3978C8.22714 4.72329 7.15263 5.7978 5.82714 5.7978C4.50166 5.7978 3.42714 4.72329 3.42714 3.3978C3.42714 2.07232 4.50166 0.997803 5.82714 0.997803C7.15263 0.997803 8.22714 2.07232 8.22714 3.3978Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgTeamSmall.displayName = 'SvgTeamSmall';
const TeamSmall = withForgeIconProps(SvgTeamSmall, {
  name: 'Team',
  usage: 'Use to show team of providers',
  related: 'Patient Group',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default TeamSmall;
