import rawContents from "!!raw-loader!./CardWithButtons.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function CardWithButtons():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Card With Buttons" />;
}

export default CardWithButtons;
