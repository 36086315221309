import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVitalsLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2.82283 11.7617H7.23666L8.97423 7.76172L12.8288 15.7617L14.5664 11.7617H20.7977M12 6.61182L14.137 4.49219C15.1178 3.53561 16.4328 3 17.8019 3C19.171 3 20.486 3.53561 21.4667 4.49219C21.9515 4.95875 22.3372 5.51822 22.6007 6.1377C22.8641 6.75717 23 7.42386 23 8.09717C23 8.77048 22.8641 9.43668 22.6007 10.0562C22.3372 10.6756 21.9515 11.2356 21.4667 11.7021L12 21.002L2.53326 11.7021C2.04848 11.2356 1.6628 10.6756 1.39934 10.0562C1.13589 9.43668 1 8.77048 1 8.09717C1 7.42386 1.13589 6.75717 1.39934 6.1377C1.6628 5.51822 2.04848 4.95875 2.53326 4.49219C3.51404 3.53561 4.82896 3 6.19805 3C7.56715 3 8.88219 3.53561 9.86297 4.49219L12 6.61182Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgVitalsLarge.displayName = 'SvgVitalsLarge';
const VitalsLarge = withForgeIconProps(SvgVitalsLarge, {
  name: 'Vitals',
  usage: 'Use to indicate or view vital sign information',
  related: 'heartbeat, heart rate, heart, ecg, ekg, vitals',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default VitalsLarge;
