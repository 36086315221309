import { ReactElement } from 'react';
import { MetadataElement } from '../../contexts';
import { Heading } from '@athena/forge';
import Code from '../code';
import createBEMHelper from '../../bem-helper';

import './imports.scss';

const classes = createBEMHelper({ name: 'imports' });

const tsconfigModuleResolution = `{
  "compilerOptions": {
    "moduleResolution": "Node16",
  }
}`;

export interface ImportsProps {
  componentsMetadata: MetadataElement[];
}
export default function Imports({ componentsMetadata }: ImportsProps): ReactElement {
  const allComponentNames = componentsMetadata.map(({ node }: MetadataElement) => node.displayName);
  const nimbusImportStatement = `import { ${allComponentNames.join(', ')} } from '@athena/forge'`;
  const standaloneImportStatements = allComponentNames
    .map((componentName) => `import ${componentName} from '@athena/forge/${componentName}';`)
    .join('\n');
  const standaloneDistImportStatements = allComponentNames
    .map((componentName) => `import ${componentName} from '@athena/forge/dist/${componentName}';`)
    .join('\n');
  const headingClassName = classes({ element: 'heading', extra: ['guide'] });
  return (
    <div className="cms-content">
      <Heading {...headingClassName} variant="subsection" headingTag="h4" text="In Nimbus applications" />
      <p>
        athenaOne serves the Forge bundle independently from your {"application's"} bundle. Importing Forge components
        directly from <code>{"'@athena/forge'"}</code> takes advantage of this feature.
      </p>
      <div className="inline-codeblock">
        <Code codeString={nimbusImportStatement} language="javascript" />
      </div>
      <Heading {...headingClassName} variant="subsection" headingTag="h4" text="In standalone applications" />
      <p>
        Importing components using the exact path to the module takes advantage of {"webpack's "}
        <a href="https://webpack.js.org/guides/tree-shaking/">tree shaking</a> feature. Webpack will include{' '}
        <em>only</em> that module and its dependencies.
      </p>
      <div className="inline-codeblock">
        <Code codeString={standaloneImportStatements} language="javascript" />
      </div>
      <p>
        To use this import guidance, Typescript applications must use typescript {'>='} <code>4.7.3</code>, and should
        add this setting to their tsconfig.json file:
      </p>
      <div className="inline-codeblock">
        <Code codeString={tsconfigModuleResolution} language="json" />
      </div>
      <p>If this setting {"doesn't"} work for your application, use this import statement instead:</p>
      <div className="inline-codeblock">
        <Code codeString={standaloneDistImportStatements} language="javascript" />
      </div>
    </div>
  );
}
