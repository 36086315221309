import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRefreshLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M.304 18.067a1 1 0 1 0 1.892.65zm2.25-3.466.285-.959a1 1 0 0 0-1.231.633zm3.785 2.171a1 1 0 1 0 .572-1.916zm17.357-10.84a1 1 0 0 0-1.892-.65zM21.446 9.4l-.285.959a1 1 0 0 0 1.231-.633zm-3.785-2.171a1 1 0 0 0-.572 1.916zM1.899 9.025a1 1 0 1 0 1.927.533zM12 2.25v-1h-.03l-.03.002zm8.208 6.869a1 1 0 0 0 1.859-.738zm1.893 5.856a1 1 0 0 0-1.927-.533zM12 21.75v1h.03l.03-.002zm-8.208-6.869a1 1 0 0 0-1.859.738zm-1.596 3.836 1.303-3.791-1.891-.65-1.304 3.791zm.072-3.158 4.071 1.213.572-1.916-4.072-1.214zM21.804 5.283l-1.303 3.791 1.891.65 1.304-3.791zm-.072 3.158-4.071-1.213-.572 1.916 4.072 1.214zm-18.87.85.964.268v.002l.001-.006.012-.04.059-.175c.056-.157.145-.39.274-.673.26-.569.676-1.333 1.298-2.107 1.23-1.533 3.27-3.115 6.59-3.312l-.12-1.996c-3.991.236-6.52 2.176-8.03 4.055a12 12 0 0 0-1.558 2.53A10 10 0 0 0 1.93 8.92l-.028.094-.002.007v.003l-.001.001zM12 3.25c2.419 0 6.255.95 8.208 5.869l1.859-.738C19.72 2.467 14.957 1.25 12 1.25zm9.138 11.458a109 109 0 0 1-.964-.269l-.001.006-.012.04-.059.175c-.056.157-.145.39-.274.673-.26.569-.676 1.333-1.298 2.107-1.231 1.533-3.271 3.115-6.59 3.312l.12 1.996c3.991-.236 6.52-2.175 8.03-4.055a12 12 0 0 0 1.558-2.53 10 10 0 0 0 .422-1.082l.021-.072.007-.022.002-.007v-.003l.001-.001zM12 20.75c-2.419 0-6.255-.95-8.208-5.869l-1.859.738C4.28 21.533 9.043 22.75 12 22.75z"
      fill={props.color}
    />
  </svg>
);
SvgRefreshLarge.displayName = 'SvgRefreshLarge';
const RefreshLarge = withForgeIconProps(SvgRefreshLarge, {
  name: 'Refresh',
  usage: 'Use to reload or refresh a page or view',
  related: 'update, new, recent',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default RefreshLarge;
