import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAllergyLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.39913 3.50391L1.39639 17.5085C1.13539 17.9646 0.998662 18.4811 1.00001 19.0066C1.00136 19.5321 1.14075 20.0482 1.40408 20.5029C1.66741 20.9577 2.04544 21.3353 2.50052 21.598C2.95561 21.8608 3.47177 21.9993 3.99725 22H20.0027C20.5282 21.9993 21.0444 21.8608 21.4995 21.598C21.9546 21.3353 22.3326 20.9577 22.5959 20.5029C22.8593 20.0482 22.9986 19.5321 23 19.0066C23.0013 18.4811 22.8646 17.9646 22.6036 17.5085L14.6009 3.50391C14.3377 3.0468 13.9588 2.66698 13.5022 2.40296C13.0456 2.13895 12.5274 2 12 2C11.4726 2 10.9544 2.13895 10.4978 2.40296C10.0412 2.66698 9.66227 3.0468 9.39913 3.50391Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M15.4634 17.9832C15.9599 17.9897 16.447 17.8483 16.8628 17.5768C17.2785 17.3054 17.6042 16.9163 17.798 16.4591C17.9918 16.0019 18.045 15.4974 17.951 15.0098C17.8569 14.5222 17.6199 14.0738 17.2699 13.7215C16.92 13.3692 16.4729 13.1292 15.986 13.0319C15.4991 12.9346 14.9942 12.9845 14.5358 13.1752C14.0774 13.366 13.6861 13.6888 13.4119 14.1028C13.1377 14.5168 12.993 15.0031 12.9963 15.4996C13.0383 16.1417 13.3113 16.7469 13.7648 17.2034C14.2183 17.6599 14.8216 17.9369 15.4634 17.9832V17.9832Z"
      fill={props.color}
    />
    <path
      d="M8.4962 18.9834C8.79287 18.9834 9.08283 18.8954 9.3295 18.7305C9.57617 18.5657 9.76839 18.3314 9.88192 18.0574C9.99545 17.7833 10.0253 17.4817 9.96743 17.1907C9.90956 16.8998 9.76669 16.6325 9.55692 16.4227C9.34714 16.2129 9.07976 16.0701 8.7888 16.0122C8.49783 15.9543 8.19625 15.9841 7.92217 16.0976C7.64808 16.2112 7.4139 16.4034 7.24908 16.65C7.08426 16.8967 6.99622 17.1868 6.99622 17.4834C6.99622 17.8812 7.15418 18.2627 7.43548 18.544C7.71678 18.8253 8.09838 18.9834 8.4962 18.9834Z"
      fill={props.color}
    />
    <path
      d="M10.9962 13.9834C11.3917 13.9834 11.7784 13.8661 12.1073 13.6464C12.4362 13.4266 12.6925 13.1142 12.8438 12.7488C12.9952 12.3833 13.035 11.9812 12.9578 11.5933C12.8807 11.2053 12.6902 10.8489 12.4105 10.5692C12.1308 10.2895 11.7743 10.099 11.3863 10.0218C10.9984 9.94467 10.5963 9.98423 10.2308 10.1356C9.86537 10.287 9.55313 10.5434 9.33337 10.8723C9.11361 11.2012 8.99622 11.5878 8.99622 11.9834C8.99622 12.5138 9.20683 13.0225 9.5819 13.3976C9.95697 13.7727 10.4658 13.9834 10.9962 13.9834Z"
      fill={props.color}
    />
  </svg>
);
SvgAllergyLarge.displayName = 'SvgAllergyLarge';
const AllergyLarge = withForgeIconProps(SvgAllergyLarge, {
  name: 'Allergy',
  usage: 'Use to indicate, view, or edit patient allergies',
  related: 'allergies, allergen, allergic',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default AllergyLarge;
