import FilterSmall from '@athena/forge-icons/dist/FilterSmall';

import rawContents from "!!raw-loader!./PushDrawerPersistent.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { FilterSmall };

function PushDrawerPersistent():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Push Drawer Persistent" />;
}

export default PushDrawerPersistent;
