import { ButtonSize } from '../../Button';
import { DataTableLayout } from '../DataTable';

/**
 * |                | LTS     | NEW     |
 * | :---           | :---    | :---    |
 * | 56px           | large   | x-large |
 * | 32px (default) | medium  | large   |
 * | 28px           | small   | medium  |
 * | 22px           | tiny    | small   |
 */
export const ACTION_BUTTON_SIZES: Record<DataTableLayout, ButtonSize> = {
  medium: 'large', // 32px
  compact: 'medium', // 28px
};

export const TOOLTIP_OPTIONS = {
  SHOW: true,
  HIDE: false,
  SHOW_WHEN_MOUSE_OVER: undefined,
} as const;

export const DATA_TABLE_FILTER_TAG_LABEL = 'data-table-filter-tag';
