import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPinEmptyLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M14.1 1.00073L23 9.90063M7.16003 16.8408L1 23.0007M20.83 7.73071L16.27 3.17065L8.81995 8.9707C7.50941 8.69404 6.15071 8.74743 4.86597 9.12622C3.58123 9.50501 2.41077 10.1972 1.45996 11.1406L12.84 22.5308C13.7895 21.5841 14.4858 20.414 14.865 19.1279C15.2442 17.8419 15.294 16.481 15.01 15.1707L20.83 7.73071Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgPinEmptyLarge.displayName = 'SvgPinEmptyLarge';
const PinEmptyLarge = withForgeIconProps(SvgPinEmptyLarge, {
  name: 'PinEmpty',
  usage: 'Use to indicate a pinned item or pin an item',
  related: 'thumbtack, top, favorite',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default PinEmptyLarge;
