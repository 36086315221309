import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCopyLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.49576 5H2V22.9999H12.9919C14.6519 22.9999 15.9964 21.6518 15.9919 19.9918L15.9892 19"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M22 7.34086H23V6.89742L22.6714 6.59972L22 7.34086ZM8 18.9999H7V19.9998L7.99999 19.9999L8 18.9999ZM8 1V0H7V1H8ZM15 1L15.6714 0.258861L15.3856 0H15V1ZM22 8.43085C22.5523 8.43085 23 7.98313 23 7.43085C23 6.87856 22.5523 6.43085 22 6.43085V8.43085ZM15 7.43085H14C14 7.98313 14.4477 8.43085 15 8.43085V7.43085ZM21 7.34086L21 16H23L23 7.34086H21ZM19 18L8.00001 17.9999L7.99999 19.9999L19 20L19 18ZM9 18.9999V1H7V18.9999H9ZM8 2H15V0H8V2ZM14.3286 1.74114L21.3286 8.082L22.6714 6.59972L15.6714 0.258861L14.3286 1.74114ZM22 6.43085H15V8.43085H22V6.43085ZM16 7.43085V1H14V7.43085H16ZM21 16C21 17.1045 20.1046 18 19 18L19 20C21.2091 20 23 18.2091 23 16H21Z"
      fill={props.color}
    />
  </svg>
);
SvgCopyLarge.displayName = 'SvgCopyLarge';
const CopyLarge = withForgeIconProps(SvgCopyLarge, {
  name: 'Copy',
  usage:
    'Use in a Button to copy an item to the clipboard or to duplicate an item',
  related: 'create, clone',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default CopyLarge;
