import React, { ReactElement, Ref, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import ForgePropTypes from '../utils/propTypes';
import Heading from '../Heading';

import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';

const classes = forgeClassHelper({ name: 'banner-item' });

export interface BannerItemProps extends React.HTMLAttributes<HTMLDivElement> {
  /** BannerItem content */
  children?: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** header text for each banner item, wrapped in an heading element as specified by headingTag */
  headerText?: string;
  /** Specifies the header's HTML tag. For more on the importance of heading tags in semantic HTML,
   * [see the W3C's article on this topic](https://www.w3.org/WAI/tutorials/page-structure/headings/). */
  headingTag?: string; // TODO-TYPES: should use ForgeTypes headingTags
  /** ref to <div> wrapping the component */
  ref?: Ref<HTMLDivElement>;
}

interface BannerItemComponentProps extends BannerItemProps {
  /** ref to <div> wrapping the component */
  forwardedRef?: Ref<HTMLDivElement>;
}

/**
 * BannerItem
 * @documentedBy Banner
 */
const BannerItem = ({
  headerText,
  headingTag = 'h2',
  className,
  children,
  forwardedRef,
  ...passedProps
}: BannerItemComponentProps): ReactElement => (
  <div {...classes({ extra: className })} ref={forwardedRef} {...passedProps}>
    {headerText && (
      <Heading {...classes({ element: 'header' })} text={headerText} headingTag={headingTag} variant="subsection" />
    )}
    <div {...classes({ element: 'contents' })}>{children}</div>
  </div>
);

const bannerItemPropTypes: WeakValidationMap<BannerItemProps> = {
  /** BannerItem content */
  children: PropTypes.node,
  /** header text for each banner item, wrapped in an heading element as specified by headingTag */
  headerText: PropTypes.string,
  /** Specifies the header's HTML tag. For more on the importance of heading tags in semantic HTML,
   * [see the W3C's article on this topic](https://www.w3.org/WAI/tutorials/page-structure/headings/). */
  headingTag: ForgePropTypes.headingTags,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
};
BannerItem.propTypes = bannerItemPropTypes;
BannerItem.displayName = 'BannerItem';

export default forwardRefToProps(BannerItem);
