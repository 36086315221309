import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgReceiptSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9V7ZM10 9C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7V9ZM6 4C5.44772 4 5 4.44772 5 5C5 5.55228 5.44772 6 6 6V4ZM10 6C10.5523 6 11 5.55228 11 5C11 4.44772 10.5523 4 10 4V6ZM14 5C14 4.44772 13.5523 4 13 4C12.4477 4 12 4.44772 12 5H14ZM13 12H14H13ZM3 15H2V16H3V15ZM4 5C4 4.44772 3.55228 4 3 4C2.44772 4 2 4.44772 2 5H4ZM8 9H10V7H8V9ZM6 6H10V4H6V6ZM12 5V12H14V5H12ZM12 12C12 12.5304 11.7893 13.0389 11.4142 13.414L12.8284 14.8282C13.5785 14.0781 14 13.0609 14 12L12 12ZM11.4142 13.414C11.039 13.7892 10.5302 14 10 14V16C11.0611 16 12.0784 15.5782 12.8284 14.8282L11.4142 13.414ZM10 14H3V16H10V14ZM4 15V5H2V15H4Z"
      fill={props.color}
    />
    <path
      d="M3 5V1L5.5 2L8 1L10.5 2L13 1V5"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.07998 11.4702C8.18998 11.4702 8.54 11.5202 8.6 11.4702C8.71787 11.4418 8.82282 11.3745 8.89785 11.2793C8.97288 11.1841 9.01357 11.0661 9.01357 10.9448C9.01357 10.8236 8.97288 10.7061 8.89785 10.6108C8.82282 10.5156 8.71787 10.4483 8.6 10.4199H8.43996C8.37996 10.4199 8.30003 10.4799 8.26003 10.4199C7.90003 10.0699 7.44999 10.4701 7.33999 10.8701H7.25002C7.22702 10.5906 7.18357 10.3132 7.12002 10.04C6.89002 9.04004 6.47001 8.3999 5.99001 8.3999C5.8197 8.40981 5.65771 8.47668 5.53005 8.58984C5.38083 8.74356 5.29207 8.94626 5.28005 9.16016C5.24523 9.90219 5.42584 10.6381 5.80007 11.2798C5.49856 11.4051 5.25313 11.6367 5.11001 11.9302C5.02388 12.1762 5.02388 12.4439 5.11001 12.6899C5.13824 12.8836 5.23401 13.061 5.38039 13.1909C5.52678 13.3208 5.7144 13.3949 5.91006 13.3999H6.08999C6.39398 13.3317 6.6677 13.1671 6.87002 12.9302L6.94997 12.8198C7.11569 12.556 7.22157 12.2594 7.26003 11.9502H7.35H7.65005C8.00005 11.9502 8.10005 11.6302 8.15005 11.4302L8.07998 11.4702ZM6.28005 10.8301C6.28005 10.8301 5.77001 9.95008 5.99001 9.58008C6.33001 9.88008 6.28005 10.8301 6.28005 10.8301ZM5.81997 12.71C5.17997 12.36 6.16 11.9298 6.35 12.0298C6.54 12.1298 6.10997 12.86 5.81997 12.71Z"
      fill={props.color}
    />
  </svg>
);
SvgReceiptSmall.displayName = 'SvgReceiptSmall';
const ReceiptSmall = withForgeIconProps(SvgReceiptSmall, {
  name: 'Receipt',
  usage: 'Use to indicate or view a receipt',
  related: 'payment, paid, invoice',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default ReceiptSmall;
