import ExpandLarge from '@athena/forge-icons/dist/ExpandLarge';
import ExpandSmall from '@athena/forge-icons/dist/ExpandSmall';
import { ForgeIconComponent } from '@athena/forge-icons/dist/ForgeIconProps';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { nanoid } from 'nanoid';
import PropTypes, { Validator } from 'prop-types';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  HTMLAttributes,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  Ref,
  RefAttributes,
  RefObject,
  WeakValidationMap,
  useCallback,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import MenuTriggerContext from '../Menu/MenuTriggerContext';
import ProgressIndicator from '../ProgressIndicator';
import { SegmentedButtonContext, SegmentedButtonSize } from '../SegmentedButton/SegmentedButtonContext';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';
import { MenuSize } from '../Menu/Menu';
import InputRefContext from '../Input/InputRefContext';

/** A supported button variant string.
 *
 * type ButtonVariant = typeof buttonVariants[number] would be less duplication
 * effort, but is unsupported by react-docgen v5
 * same story with ButtonSize and ButtonType */
export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'segmented';
/** The list of button variants supported */
export const buttonVariants: readonly ButtonVariant[] = ['primary', 'secondary', 'tertiary', 'segmented'];

/** A supported button size string */
export type ButtonSize = 'small' | 'medium' | 'large' | 'x-large';
/** The list of supported button sizes */
export const buttonSizes: readonly ButtonSize[] = ['small', 'medium', 'large', 'x-large'];

/** Supported Button size when an icon is present */
export type ButtonSizeWithIcon = 'large' | 'x-large';
/** The list of supported button sizes when an icon is present */
export type ButtonSizeWithoutIcon = Exclude<ButtonSize, ButtonSizeWithIcon>;

/** A supported Button type */
export type ButtonType = 'button' | 'submit' | 'reset';
/** The list of supported Button types */
export const buttonTypes: readonly ButtonType[] = ['button', 'submit', 'reset'];

/** Determines if a Button can have an Icon based on its size. */
const canHaveIcon = (size: ButtonSize, variant: ButtonVariant): boolean =>
  variant === 'tertiary' || size === 'large' || size === 'x-large';

/** The props that Button destructures for its internal consumption.
 *
 * This is in contrast to ButtonForwardedProps, which are props forwarded to the
 * underlying DOM element.
 */
export interface ButtonDestructuredProps<UseLink extends boolean> {
  /** Aria label for the underlying <a> or <button> */
  'aria-label'?: string;
  /** Content to display inside button before icon and text */
  children?: ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Applies the disabled state class and adds `aria-disabled` attribute to the button element */
  disabled?: boolean;
  /** Applies the full width class to make button the full width of its parent */
  fullWidth?: boolean;
  /** Specifies the link destination if `useLink` is true. Cannot be used if `useLink` if false. */
  href?: UseLink extends true ? string : never;
  /**
   * The icon to display inside the button. Only applicable to large and x-large
   * button sizes. It is not recommended to use the solid, special icons
   * (Attention, Critical, Info, and Success) within Button
   * It's recommended to supply an icon from the @athena/forge-icons package.
   * It is recommended to use a small icon for a large button, eg: `SearchSmall`,
   * or a large icon for a x-large button, eg: `SearchLarge`.
   * It is not recommended to use icons for medium or small buttons.
   */
  icon?: ForgeIconComponent;
  /** The title to apply to the icon. Defaults to empty string */
  iconTitle?: string;
  /** Handles click event on the button. Type is dependent on the useLink prop. */
  onClick?: UseLink extends true ? MouseEventHandler<HTMLAnchorElement> : MouseEventHandler<HTMLButtonElement>;
  /** Set to true to display a loading spinner to the left of the Button's content. This is only supported for large and x-large size buttons. */
  showLoadingSpinner?: boolean;
  /** When specified, this can change button size to: small, medium, large, x-large. Defaults to large */
  size?: ButtonSize;
  /** Button text */
  text?: string;
  /** Sets the `type` attribute on the button element (e.g. 'button', 'submit', or 'reset').
   * Will not be used if `useLink` is true. */
  type?: UseLink extends true ? never : ButtonType;
  /** If true, renders the component as a `<a>` element instead of a `<button>` element.
   *
   * Set to true if the click action navigates the user to a new context (e.g., new page or workflow).
   * If true, an `href` must be provided. Defaults to false.
   */
  useLink?: UseLink;
  /** If used within a `SegmentedButton`, represents the value that this Button
   * represents within a Form */
  value?: string;
  /** Applies styling based on pre-determined variants.
   *
   * Primary, secondary, and  tertiary respectively convey diminishing emphasis
   * on the button. If a button has an icon with no accompanying text, then the
   * tertiary variant is recommended.
   *
   * The segmented variant is primarily used in the implementation of
   * SegmentedButton, and should not need to be specified explicitly.
   */
  variant?: ButtonVariant;
}

/** The prop keys consumed directly by the Button component */
export type KeyofButtonProps = keyof ButtonDestructuredProps<boolean>;

/** The props forwarded by Button to its underlying native DOM element.
 *
 * This type is only applied at the last possible second because the keys of
 * this type change depending on UseLink. This confuses Typescript.
 * Instead, ButtonDestructuredProps and ButtonElementDestructuredProps hold the
 * props that we care about, and type assertions to ButtonForwardedProps are
 * done where necessary.
 */
export type ButtonForwardedProps<UseLink extends boolean> = UseLink extends true
  ? Omit<AnchorHTMLAttributes<HTMLAnchorElement>, KeyofButtonProps>
  : Omit<ButtonHTMLAttributes<HTMLButtonElement>, KeyofButtonProps>;

/** The Button Props for the final Button component
 *
 * Contains advanced type checking. Ensures that incompatible props aren't used together.
 */
export type ButtonProps<UseLink extends boolean> = ButtonDestructuredProps<UseLink> & ButtonForwardedProps<UseLink>;

/** The HTML Element rendered by Button.
 *
 * Dependent on UseLink */
export type ButtonHTMLElement<UseLink extends boolean> = UseLink extends true ? HTMLAnchorElement : HTMLButtonElement;

/** The props used to implement Button.
 *
 * forwardedRef is a prop that does not exist on the final Button component.
 * It represents the `ref` prop given by React.forwardRef, as React.forwardRef
 * introduces a ton of extra compile-time and runtime complexity.
 */
export type ButtonComponentProps<UseLink extends boolean> = ButtonProps<UseLink> & {
  forwardedRef?: Ref<ButtonHTMLElement<UseLink>>;
};

/** The generator for Button css classes. */
const classes = forgeClassHelper('button');

/** Button props that the implementation for ButtonElement explicitly checks for */
export interface ButtonElementDestructuredProps<UseLink extends boolean> {
  'aria-label'?: string;
  children?: ReactNode;
  className?: string;
  disabled: boolean;
  forwardedRef?: Ref<ButtonHTMLElement<UseLink>>;
  fullWidth: boolean;
  href?: UseLink extends true ? string : never;
  icon?: ForgeIconComponent;
  iconTitle?: string;
  iconOnly: boolean;
  onClick: UseLink extends true ? MouseEventHandler<HTMLAnchorElement> : MouseEventHandler<HTMLButtonElement>;
  size: ButtonSize;
  text?: string;
  type: UseLink extends true ? never : ButtonType;
  useLink: UseLink;
  value?: string;
  variant: ButtonVariant;
}

/** Props for ButtonElement */
export type ButtonElementProps<UseLink extends boolean> = ButtonElementDestructuredProps<UseLink> &
  ButtonForwardedProps<UseLink>;

interface ReconcileSizeArgs {
  size: ButtonSize | undefined;
  segmentedButtonSize: SegmentedButtonSize | undefined;
  menuTriggerSize: MenuSize | undefined;
}
/** Button size automatically changes based on context, but can be overridden via props */
const reconcileSize = ({ size, segmentedButtonSize, menuTriggerSize }: ReconcileSizeArgs): ButtonSize => {
  if (size) {
    return size;
  } else if (segmentedButtonSize) {
    return segmentedButtonSize;
  } else if (menuTriggerSize) {
    return menuTriggerSize;
  } else {
    return 'large';
  }
};

interface ReconcileVariantArgs {
  variant: ButtonVariant | undefined;
  isMenuTrigger: boolean;
  isSegmentedButtonChild: boolean;
}
/** Button variant automatically changes based on context, but can be overridden
 * via props
 */
const reconcileVariant = ({ isMenuTrigger, isSegmentedButtonChild, variant }: ReconcileVariantArgs): ButtonVariant => {
  if (variant) {
    return variant;
  } else if (isMenuTrigger || isSegmentedButtonChild) {
    return 'segmented';
  } else {
    return 'primary';
  }
};

/** Renders an <a> or <button> based on the useLink prop. */
function ButtonElement<UseLink extends boolean>({
  'aria-label': ariaLabel,
  children,
  className,
  disabled,
  value,
  forwardedRef,
  fullWidth,
  href,
  icon,
  iconOnly,
  iconTitle,
  onBlur,
  onClick,
  onKeyDown,
  role: upstreamRole,
  size,
  text,
  type,
  useLink,
  variant,
  ...passthroughProps
}: ButtonElementProps<UseLink>): ReactElement {
  const {
    buttonClickHandler: segmentedButtonClickHandler,
    buttonRefHandler: segmentedButtonRefHandler,
    checkedValues: segmentedButtonItemState,
    childRole: segmentedButtonChildRole,
  } = useContext(SegmentedButtonContext);
  const { triggerProps } = useContext(MenuTriggerContext);
  const role = segmentedButtonChildRole ?? upstreamRole;
  const [id] = useState(nanoid());

  /** If Button is a child of SegmentedButton, determines whether this
   * Button is checked
   */
  const segmentedButtonChecked =
    value && typeof segmentedButtonItemState[value] === 'boolean' ? segmentedButtonItemState[value] : undefined;
  const isWithinSegmentedButton = !!segmentedButtonChildRole;

  /** Internal ref object to <a> or <button> DOM node */
  const ref = useRef<ButtonHTMLElement<UseLink> | null>(null);

  /** Adapts segmentedButtonRefHandler to have a standard ref callback signature
   * for use with useImperativeHandle */
  const segmentedButtonRefWrapper = useCallback(
    (domNode: HTMLElement | null): void => {
      if (id && segmentedButtonRefHandler) {
        segmentedButtonRefHandler(id, domNode);
      }
    },
    [id, segmentedButtonRefHandler]
  );

  /** Determines if this Button is being used as a Menu trigger */
  const isMenuTrigger = !!triggerProps;
  const {
    ref: menuTriggerRef,
    onBlur: menuTriggerOnBlur,
    onClick: menuTriggerOnClick,
    onKeyDown: menuTriggerOnKeyDown,
    ...otherMenuTriggerProps
  } = triggerProps || {};

  /** Update all interested parties what the ref is */
  useImperativeHandle<ButtonHTMLElement<UseLink> | null, ButtonHTMLElement<UseLink> | null>(
    forwardedRef,
    () => ref.current
  );
  useImperativeHandle<HTMLElement | null, ButtonHTMLElement<UseLink> | null>(
    segmentedButtonRefWrapper,
    () => ref.current
  );
  useImperativeHandle<HTMLElement | null, ButtonHTMLElement<UseLink> | null>(menuTriggerRef, () => {
    return ref.current;
  });

  /** The default click handler for Button.
   *
   * There are several different callback functions to call when a button is
   * clicked, so call them all.
   */
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    if (value !== undefined) {
      segmentedButtonClickHandler?.(value, e);
    }
    menuTriggerOnClick?.(e);

    (onClick as MouseEventHandler<HTMLElement>)?.(e);
  };
  /** Calls all callback functions that respond to blur */
  const handleBlur = (e: React.FocusEvent<HTMLElement, Element>): void => {
    menuTriggerOnBlur?.(e);
    onBlur?.(e as React.FocusEvent<HTMLAnchorElement> & React.FocusEvent<HTMLButtonElement>);
  };
  /** Calls all callback functions that respond to keyDown */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    onKeyDown?.(e as React.KeyboardEvent<HTMLAnchorElement> & React.KeyboardEvent<HTMLButtonElement>);
    menuTriggerOnKeyDown?.(e);
  };

  /** Class names common to <a /> and <button /> */
  const commonClassName = classes({
    states: { disabled: !!disabled },
    modifiers: {
      'full-width': fullWidth,
      'icon-only': iconOnly,
      'menu-trigger': isMenuTrigger,
      'segmented-child': isWithinSegmentedButton,
      [size]: true,
      [variant]: true,
    },
    extra: className,
  });
  /** Determines what the aria-label should be based on the icon, if present  */
  const iconAriaLabel = iconTitle || (typeof icon === 'function' ? icon.title : undefined);
  /** props common to both <a /> and <button /> */
  const commonProps = {
    'aria-checked': segmentedButtonChecked,
    'aria-disabled': disabled,
    'aria-label': ariaLabel ?? (canHaveIcon(size, variant) && !text ? iconAriaLabel : undefined),
    onBlur: handleBlur,
    onClick: handleClick,
    onKeyDown: handleKeyDown,
    role: role,
    value: value,
    ...otherMenuTriggerProps,
  };
  const finalChildren = isMenuTrigger ? (
    <>
      {children}
      <ButtonIcon
        disabled={disabled}
        forceIcon
        icon={size === 'x-large' ? ExpandLarge : ExpandSmall}
        size={size}
        variant={variant}
      />
    </>
  ) : (
    children
  );

  if (useLink) {
    const linkHref = href ? { href: sanitizeUrl(href) } : null;
    return (
      <a
        {...(passthroughProps as HTMLAttributes<HTMLAnchorElement>)}
        {...classes({ modifiers: ['link'], extra: commonClassName.className })}
        {...commonProps}
        {...linkHref}
        ref={ref as RefObject<HTMLAnchorElement>}
      >
        {finalChildren}
      </a>
    );
  } else {
    return (
      <button
        {...(passthroughProps as HTMLAttributes<HTMLButtonElement>)}
        {...commonClassName}
        {...commonProps}
        type={type}
        ref={ref as RefObject<HTMLButtonElement>}
      >
        {/** button cannot have flex styling, so use an inner div */}
        <div {...classes({ element: 'content', modifiers: size ? [size] : [] })}>{finalChildren}</div>
      </button>
    );
  }
}

/** Chooses an appropriate title for the icon
 *
 * If a title is not explicitly set then it's only appropriate to have a title
 * if there is no accompanying text. In that case, the icon will use its default
 * title.
 */
const pickIconTitle = ({ iconTitle, text }: { iconTitle?: string; text?: string }): string | undefined => {
  if (iconTitle === undefined) {
    // Empty string if there is accompanying text. Otherwise, `undefined` will
    // trigger the icon component to use its default title.
    return text ? '' : undefined;
  } else {
    return iconTitle;
  }
};

/** Props for ButtonIcon */
export type ButtonIconProps = Pick<
  ButtonComponentProps<boolean>,
  'className' | 'disabled' | 'text' | 'icon' | 'iconTitle'
> &
  Required<Pick<ButtonComponentProps<boolean>, 'size' | 'variant'>> & {
    /** Forces the icon to be displayed, despite the Button size or variant */
    forceIcon?: boolean;
  };

/** Renders an Icon within the Button */
const ButtonIcon = ({
  className,
  disabled,
  forceIcon,
  icon,
  iconTitle,
  size,
  text,
  variant,
}: ButtonIconProps): ReactElement => {
  const iconIsNeutral = typeof icon === 'function' ? icon.isNeutralInteractive : false;
  const title = pickIconTitle({ iconTitle, text });
  if (forceIcon || canHaveIcon(size, variant)) {
    if (typeof icon === 'function') {
      const ForgeIcon = icon;
      return (
        <ForgeIcon
          title={title}
          // Variant is selected via CSS as we need to recolor on hover/focus/active
          {...classes({
            element: 'icon',
            states: { disabled: !!disabled },
            modifiers: { [size]: true, [variant]: true, neutral: !!iconIsNeutral, 'force-icon': !!forceIcon },
            extra: className,
          })}
        />
      );
    }
  }
  return <></>;
};

/**
 * BUTTON
 *
 * A component to make actions visible and concrete.
 * This will either render a <button> element or an <a> element, with either button or link styling.
 */
function ButtonComponent<UseLink extends boolean>({
  'aria-label': ariaLabel,
  children,
  className,
  disabled: upstreamDisabled,
  fullWidth = false,
  value,
  forwardedRef,
  href,
  icon,
  iconTitle,
  onClick = () => {
    return;
  },
  showLoadingSpinner = false,
  size: upstreamSize,
  text,
  type = 'button' as UseLink extends true ? never : ButtonType,
  /* The following type assertion is necessary because clients of Button can
   * technically supply <ButtonComponent<true> /> without providing a useLink
   * prop in runtime. Thus the compile-time types and runtime values will be
   * out of sync.
   */
  useLink = false as UseLink,
  variant: upstreamVariant,
  ...propsForRef
}: ButtonComponentProps<UseLink>): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  // Consume MenuTriggerContext when Button is used as Menu trigger
  const { size: menuTriggerSize } = useContext(MenuTriggerContext);
  const { size: segmentedButtonSize } = useContext(SegmentedButtonContext);
  const size = reconcileSize({ size: upstreamSize, segmentedButtonSize, menuTriggerSize });

  const { disabled: segmentedButtonDisabled } = useContext(SegmentedButtonContext);
  // upstream value wins over segmentedButtonContext
  const disabled = !!(upstreamDisabled ?? segmentedButtonDisabled);

  const variant = reconcileVariant({
    variant: upstreamVariant,
    isMenuTrigger: !!menuTriggerSize,
    isSegmentedButtonChild: !!segmentedButtonSize,
  });

  /** Typescript gets really confused by ButtonForwardedProps because
   * its keys change depending on the value of UseLink.
   *
   * Type assert in order to keep the peace. */
  const buttonForwardedProps = propsForRef as JSX.IntrinsicAttributes & ButtonForwardedProps<UseLink>;
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLButtonElement>): void => {
      if (inputRef.current?.getAttribute('type') === 'file') {
        inputRef.current.click();
      }
      onClick?.(e);
    },
    [onClick]
  );

  return (
    <InputRefContext.Provider value={inputRef}>
      <ButtonElement<UseLink>
        {...buttonForwardedProps}
        aria-label={ariaLabel}
        className={className}
        disabled={disabled}
        value={value}
        forwardedRef={forwardedRef}
        fullWidth={fullWidth}
        href={href}
        icon={icon}
        iconOnly={!text && !children}
        iconTitle={iconTitle}
        onClick={handleClick}
        size={size}
        text={text}
        type={type}
        useLink={useLink}
        variant={variant}
      >
        {showLoadingSpinner && (
          <ProgressIndicator
            {...classes({
              element: 'loading-spinner',
              modifiers: { large: size === 'x-large' },
              extra: 'fe_u_margin--right-large',
            })}
            shape="circular"
            size="small"
          />
        )}
        {children}

        <ButtonIcon disabled={disabled} icon={icon} iconTitle={iconTitle} size={size} text={text} variant={variant} />

        {text && (
          <span
            {...classes({
              element: 'text',
              modifiers: [...(useLink ? ['link'] : []), size, variant],
            })}
          >
            {text}
          </span>
        )}
      </ButtonElement>
    </InputRefContext.Provider>
  );
}
ButtonComponent.displayName = 'Button';

/** The type for the Button default export.
 *
 * Button is a generic component with ref forwarding, so type assertion is
 * necessary after React.forwardRef is done.
 */
export type ButtonDefaultExport = {
  <UseLink extends boolean = false>(
    props: ButtonProps<UseLink> & RefAttributes<ButtonHTMLElement<UseLink>>
  ): ReturnType<typeof ButtonComponent>;
};

/** Custom validator for the 'icon' prop */
const iconPropType: Validator<ForgeIconComponent | undefined> = (props, propName, componentName) => {
  const { size: sizeProp = 'large', variant } = props as { size?: ButtonSize; variant?: ButtonVariant };
  const icon = props[propName as keyof ButtonProps<boolean>];

  if (
    // icon prop is supplied
    icon !== undefined &&
    // Tertiary buttons allow icons at any size
    variant !== 'tertiary' &&
    // An icon is supplied to button too small to hold the icon.
    sizeProp !== 'large' &&
    sizeProp !== 'x-large'
  ) {
    return new Error(
      `Invalid combination of prop
       ${propName}, variant and size supplied to ${componentName}.
       Icon can only be used with large or x-large size for primary and secondary variants.`
    );
  } else if (icon !== undefined && typeof icon !== 'function') {
    return new Error(
      `Invalid prop ${propName} supplied to ${componentName}.
      Should be a component defined by @athena/forge-icons.`
    );
  }
  return null;
};

/** Custom validator for the 'href' prop */
const hrefPropType: Validator<string | undefined> = (props, propName, componentName) => {
  const { useLink } = props as ButtonProps<boolean>;
  if (
    useLink &&
    props[propName as keyof ButtonProps<boolean>] &&
    typeof props[propName as keyof ButtonProps<boolean>] === 'string'
  ) {
    return null;
  } else if (useLink && !props[propName as keyof ButtonProps<boolean>]) {
    return new Error(
      `Invalid combination of prop ${propName} and useLink supplied to ${componentName}. If useLink is true, an href must be provided`
    );
  } else if (useLink && props[propName as keyof ButtonProps<boolean>] !== 'string') {
    return new Error(`Invalid ${propName} supplied to ${componentName}. Must be a string.`);
  } else if (!useLink && props[propName as keyof ButtonProps<boolean>]) {
    return new Error(
      `Invalid combination of prop ${propName} and useLink supplied to ${componentName}. If useLink is false, an href cannot be provided`
    );
  }
  return null;
};

/** Custom validator for the 'text' prop */
const textPropType: Validator<string | undefined> = (props, propName, componentName) => {
  if (
    props[propName as keyof ButtonProps<boolean>] &&
    typeof props[propName as keyof ButtonProps<boolean>] !== 'string'
  ) {
    return new Error(`Invalid ${propName} supplied to ${componentName}. Must be a string.`);
  }
  return null;
};

/** Custom validator for the 'showLoadingSpinner' prop */
const showLoadingSpinnerPropType: Validator<boolean | undefined> = (props, propName, componentName) => {
  const { showLoadingSpinner, size } = props;
  if (typeof showLoadingSpinner !== 'undefined' && typeof showLoadingSpinner !== 'boolean') {
    return new Error(`Invalid ${propName} supplied to ${componentName}. Must be a boolean.`);
  } else if (showLoadingSpinner && !(size === undefined || size === 'large' || size === 'x-large')) {
    return new Error(
      `Invalid ${propName} supplied to ${componentName}. The loading spinner is only supported on large and x-large buttons.`
    );
  }
  return null;
};

/** Runtime prop-types for Button */
const buttonPropTypes: WeakValidationMap<ButtonComponentProps<boolean>> = {
  'aria-label': PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: hrefPropType,
  icon: iconPropType,
  iconTitle: PropTypes.string,
  onClick: PropTypes.func,
  showLoadingSpinner: showLoadingSpinnerPropType,
  size: PropTypes.oneOf(buttonSizes),
  text: textPropType,
  type: PropTypes.oneOf(buttonTypes) as Validator<ButtonType>,
  useLink: PropTypes.bool,
  variant: PropTypes.oneOf(buttonVariants),
};
ButtonComponent.propTypes = buttonPropTypes;

const Button = forwardRefToProps(ButtonComponent) as ButtonDefaultExport;
export default Button;
