import './tertiary-nav.scss';
import { ChildBlock, childBlockLinkId, PageContext } from '../../contexts';
import { Fragment, ReactElement, useContext } from 'react';

interface TertiaryNavItemProps {
  field_title?: string;
  childrenBlock?: ChildBlock[];
  headingLevel?: string;
  firstHeading: boolean;
  link: string;
  activeLink: string | undefined;
}

const TertiaryNavItem = ({
  field_title,
  childrenBlock,
  headingLevel = 'main',
  firstHeading,
  link,
  activeLink,
}: TertiaryNavItemProps): ReactElement => {
  const { location } = useContext(PageContext);
  const isComponentPage = location?.pathname.includes('/components/');
  const drupalBlocks = childrenBlock
    ? childrenBlock.map((childBlock, index) => {
        const { drupal_type } = childBlock;
        const linkId = childBlockLinkId(childBlock);
        const key = linkId || index;
        if (drupal_type === 'paragraph--header') {
          return (
            <TertiaryNavItem
              key={key}
              {...childBlock}
              headingLevel={'sub'}
              firstHeading={false}
              link={linkId}
              activeLink={activeLink}
            />
          );
        } else {
          return null;
        }
      })
    : [];
  const blocks = isComponentPage && field_title === 'Coding' ? [...drupalBlocks] : drupalBlocks;

  return (
    <>
      <nav
        className={`tertiary-navigation-link
          ${headingLevel === 'sub' ? 'sub' : 'main'}
          ${firstHeading ? 'first' : ''}
          ${activeLink === link ? 'active' : ''}`}
      >
        <a href={`#${link}`}>{field_title}</a>
      </nav>
      <div>{blocks}</div>
    </>
  );
};

export default TertiaryNavItem;
