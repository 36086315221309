/*
 * Adapted from universe-react 5.10.9
 */

import PropTypes, { Requireable, Validator } from 'prop-types';
import React, { ChangeEventHandler, ReactElement, Ref, useCallback, useMemo, WeakValidationMap } from 'react';
import Radio, { RadioValue, RadioInputProps } from '../Radio';
import { forgeClassHelper } from '../utils/classes';
import ForgePropTypes from '../utils/propTypes';
import forwardRefToProps from '../utils/forwardRefToProps';
import RadioGroupContext from './RadioGroupContext';
import { optionalBoolValidator, optionalStringValidator } from '../utils/betterPropTypes';

export type RadioGroupAlign = 'horizontal' | 'vertical';

export interface RadioOptionObject {
  disabled?: boolean;
  /** @deprecated in favor of `label` */
  text?: string;
  label?: string;
  textInputProps?: RadioInputProps;
  value: RadioValue;
}

export interface RadioGroupProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'defaultValue' | 'value' | 'id'> {
  /** Alignment of radio buttons */
  align?: RadioGroupAlign;
  /** Which radio option should be initially selected; should only be used
   * for an uncontrolled component. */
  defaultValue?: RadioValue;
  /** Impacts the styling of the select in addition to preventing selection. */
  disabled?: boolean;
  /** Highlights the input when defined (specific string doesn't matter) */
  error?: string;
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles?: boolean;
  /** Base id. Also used as name if no name is assigned.
   * Internally used by each radio input element to generate its own id with the form '[id]-option-[number]' */
  id: string;
  /** Name attribute shared by all radio buttons in the group. Defaults to `id` */
  name?: string;
  /** Function that takes a JS event as an argument. Called when the RadioGroup or Radio value changes */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /** If an option is supplied as a primitive, it's both the value and the text of the option. */
  options?: ReadonlyArray<RadioValue | RadioOptionObject>;
  /** Ref forwarded to the first radio element */
  ref?: Ref<HTMLInputElement>;
  /** Indicates whether this group requires a radio button to be checked */
  required?: boolean;
  /** Value of the selected radio option. Should only be used for a controlled RadioGroup. */
  value?: RadioValue;
}

interface RadioGroupComponentProps extends RadioGroupProps {
  /** Ref forwarded to the first radio element */
  forwardedRef?: Ref<HTMLInputElement>;
}

const classes = forgeClassHelper('radiogroup');

export const RadioGroup = function ({
  options,
  defaultValue,
  forwardedRef,
  value,
  onBlur,
  onChange,
  disabled = false,
  tabIndex,
  required = false,
  error,
  align,
  hideRequiredStyles = false,
  className,
  children,
  name: upstreamName,
  id,
  ...rest
}: RadioGroupComponentProps): ReactElement {
  const name = upstreamName || id;
  const stringValue = value?.toString();
  const defaultStringValue = defaultValue?.toString();

  /** Normalize options so that implementation can use a consistent interface */
  const normalizedOptions = useMemo(
    () =>
      options?.map((option) => {
        const {
          label: optionLabel,
          text: optionText,
          value: optionValue,
          disabled: optionDisabled,
          textInputProps,
        } = typeof option === 'object' ? option : ({ value: option } as RadioOptionObject);
        const value = optionValue.toString();
        return {
          label: optionLabel ?? optionText ?? value,
          value: value,
          disabled: optionDisabled,
          textInputProps: textInputProps,
        };
      }),
    [options]
  );

  const hasTwoOptions = useCallback(() => {
    if ((normalizedOptions && normalizedOptions.length === 2) || React.Children.count(children) === 2) {
      return true;
    } else {
      return false;
    }
  }, [normalizedOptions, children]);

  const hasTextInputOptions = useCallback(() => {
    return !!normalizedOptions?.find((option) => {
      return typeof option === 'object';
    });
  }, [normalizedOptions]);

  // if there are only two options without free text then use flex container
  // to set them side by side, otherwise layout is vertical.
  const alignment = align || (hasTwoOptions() && !hasTextInputOptions() ? 'horizontal' : 'vertical');

  const contextData = {
    name,
    value: stringValue,
    defaultValue: defaultStringValue,
    error,
    disabled,
    tabIndex,
    required,
    onChange,
    alignment,
  };

  return (
    <RadioGroupContext.Provider value={contextData}>
      <div
        role="radiogroup"
        {...rest}
        onBlur={onBlur}
        id={id}
        {...classes({
          states: {
            disabled: Boolean(disabled),
            error: Boolean(error && !disabled),
            required: Boolean(required && !hideRequiredStyles && !disabled),
          },
          modifiers: {
            horizontal: alignment === 'horizontal',
          },
          extra: className,
        })}
      >
        {normalizedOptions &&
          normalizedOptions.map((option, i) => {
            const { label, value: optionValue, disabled: optionDisabled, textInputProps } = option;

            return (
              <Radio
                /** Several props are not set because they're
                 * being sent through RadioGroupContext anyway.
                 *
                 * RadioGroupContext primarily exists to support Radio
                 * components as children, but works just as well when RadioGroup
                 * is rendering Radio components itself.
                 *
                 * Less risk of confusion if there's only one source of truth.
                 */
                key={optionValue}
                value={optionValue}
                text={label}
                disabled={optionDisabled ?? disabled}
                id={id + '-option-' + i}
                textInputProps={textInputProps}
                ref={i === 0 ? forwardedRef : null}
              />
            );
          })}
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
};

RadioGroup.displayName = 'RadioGroup';

const radioOptionValue: Requireable<RadioValue> = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
]);

/**
 * RadioGroup's API mirrors that of other Forge inputs, including the same options prop as Select.
 */
export const radioPropTypes: WeakValidationMap<RadioGroupComponentProps> = {
  align: PropTypes.oneOf(['horizontal', 'vertical']),
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  defaultValue: ForgePropTypes.primitives,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hideRequiredStyles: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  /** Function that takes a JS event as an argument. Called when the RadioGroup or Radio loses focus */
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  /** Function that takes a JS event as an argument. Called when the RadioGroup or Radio receives focus */
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      radioOptionValue.isRequired,
      PropTypes.shape({
        disabled: optionalBoolValidator,
        label: optionalStringValidator,
        text: optionalStringValidator,
        textInputProps: PropTypes.object as Validator<RadioInputProps>,
        value: radioOptionValue.isRequired,
      }).isRequired,
    ]).isRequired
  ),
  required: PropTypes.bool,
  /** Controls what tabIndex the underlying Radio options have */
  tabIndex: PropTypes.number,
  value: ForgePropTypes.primitives,
  /**
   * RadioGroup items can be set through the options prop or through children, not both.
   * They must always be Radios.
   */
  children: function (props, propName) {
    let error = null;
    if ((props.options && props.children) || !(props.options || props.children)) {
      error = new Error('RadioGroup options must be set through the options prop or through children, but not both.');
    }
    const prop = props[propName];
    React.Children.forEach(prop, function (child) {
      if (child.type !== Radio) {
        error = new Error('RadioGroup children should be of type Radio.');
      }
    });
    return error;
  },
};
RadioGroup.propTypes = radioPropTypes;
RadioGroup.displayName = 'RadioGroup';

export default forwardRefToProps(RadioGroup);
