import { Fragment, ReactElement } from 'react';

import Text from '../text';
import Image from '../image';
import DoDonts from '../do-donts';
import Information from '../information';
import Card from '../card';
import { Heading } from '@athena/forge';
import Demos from '../demos/demos';
import './header.scss';
import Markdown from '../markdown';
import { ChildBlock, childBlockLinkId } from '../../../contexts';

export interface HeaderChildProps {
  childBlock: ChildBlock;
  field_title: string;
  index: number;
}

/** Renders a single child of the Header component
 *
 * Does not take `reactChildren` into account.
 */
export function HeaderChild({ childBlock, field_title, index }: HeaderChildProps): ReactElement {
  if (childBlock.drupal_type === 'paragraph--header') {
    return <Header {...childBlock} headingTag={'h3'} />;
  } else if (childBlock.drupal_type === 'paragraph--text') {
    return childBlock.field_text_body ? <Text field_title={field_title}>{childBlock.field_text_body}</Text> : <></>;
  } else if (childBlock.drupal_type === 'paragraph--image' && childBlock.field_image) {
    return <Image alt={field_title} field_image={childBlock.field_image} />;
  } else if (
    childBlock.drupal_type === 'paragraph--card' &&
    childBlock.field_card_body &&
    childBlock.field_card_link &&
    childBlock.field_component_preview
  ) {
    return (
      <Card
        field_card_body={childBlock.field_card_body}
        field_card_link={childBlock.field_card_link}
        field_component_preview={childBlock.field_component_preview}
      />
    );
  } else if (
    childBlock.drupal_type === 'paragraph--information' &&
    childBlock.field_information_body &&
    childBlock.field_information_type
  ) {
    return (
      <Information
        field_information_body={childBlock.field_information_body}
        field_information_type={childBlock.field_information_type}
      />
    );
  } else if (
    childBlock.drupal_type === 'paragraph--do_don_t' &&
    childBlock.field_do_description &&
    childBlock.field_do_image &&
    childBlock.field_don_t_description &&
    childBlock.field_don_t_image
  ) {
    return (
      <DoDonts
        field_do_description={childBlock.field_do_description}
        field_do_image={childBlock.field_do_image}
        field_don_t_description={childBlock.field_don_t_description}
        field_don_t_image={childBlock.field_don_t_image}
      />
    );
  } else if (childBlock.drupal_type === 'paragraph--demos') {
    return <Demos />;
  } else if (childBlock.drupal_type === 'paragraph--markdown' && childBlock.field_markdown_body) {
    return <Markdown field_markdown_body={childBlock.field_markdown_body} />;
  } else {
    console.warn('Could not find enough information to render: ', childBlock);
    return <></>;
  }
}

export interface HeaderProps extends ChildBlock {
  headingTag?: string;
}

/** Renders a paragraph--header received by Drupal along with its children. */
const Header = ({ field_title = 'Unknown', childrenBlock, headingTag = 'h2', ...block }: HeaderProps): ReactElement => {
  const linkId = childBlockLinkId({ ...block, field_title });
  const blocks = childrenBlock
    ? childrenBlock.map((childBlock, index) => {
        return (
          <Fragment key={childBlockLinkId(childBlock) || index}>
            <HeaderChild childBlock={childBlock} field_title={field_title} index={index} />
            {childBlock.reactChildren}
          </Fragment>
        );
      })
    : [];

  return (
    <>
      <Heading
        className="guide"
        headingTag={headingTag}
        id={linkId}
        variant={headingTag === 'h2' ? 'section' : 'subsection'}
        text={field_title}
      />
      {blocks}
    </>
  );
};

export default Header;
