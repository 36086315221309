import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAttentionButtonLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M12 5V14"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12 20C12.8284 20 13.5 19.3284 13.5 18.5C13.5 17.6716 12.8284 17 12 17C11.1716 17 10.5 17.6716 10.5 18.5C10.5 19.3284 11.1716 20 12 20Z"
      fill={props.color}
    />
  </svg>
);
SvgAttentionButtonLarge.displayName = 'SvgAttentionButtonLarge';
const AttentionButtonLarge = withForgeIconProps(SvgAttentionButtonLarge, {
  name: 'AttentionButton',
  usage: 'Use in a Button to call attention to an issue or error',
  related: 'alert, warning',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default AttentionButtonLarge;
