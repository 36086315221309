import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPinFilledSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M9.33997 1.00073L15 6.66064Z" fill={props.color} />
    <path d="M1 15.0007L4.92004 11.0808Z" fill={props.color} />
    <path
      d="M13.62 5.28076L10.72 2.38062L8.34497 4.22571L5.96997 6.0708C5.13667 5.89327 4.2723 5.92651 3.45508 6.16748C2.63786 6.40845 1.8937 6.84943 1.29004 7.45068L8.54004 14.7007C9.14359 14.0986 9.58615 13.3546 9.82727 12.5369C10.0684 11.7192 10.1003 10.854 9.92004 10.0208L13.62 5.28076Z"
      fill={props.color}
    />
    <path
      d="M9.33997 1.00073L15 6.66064M1 15.0007L4.92004 11.0808M13.62 5.28076L10.72 2.38062L8.34497 4.22571L5.96997 6.0708C5.13667 5.89327 4.2723 5.92651 3.45508 6.16748C2.63786 6.40845 1.8937 6.84943 1.29004 7.45068L8.54004 14.7007C9.14359 14.0986 9.58615 13.3546 9.82727 12.5369C10.0684 11.7192 10.1003 10.854 9.92004 10.0208L13.62 5.28076Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgPinFilledSmall.displayName = 'SvgPinFilledSmall';
const PinFilledSmall = withForgeIconProps(SvgPinFilledSmall, {
  name: 'PinFilled',
  usage: 'Use to indicate a pinned state',
  related: 'thumbtack, top, favorite, active pin, pinned, unpin, filled pin',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default PinFilledSmall;
