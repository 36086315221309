import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDrawSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.07104 3.00193L7.02576 0.956643L7.80729 0.169623C7.859 0.11598 7.921 0.0733258 7.98957 0.0441819C8.05814 0.015038 8.13184 0 8.20635 0C8.28085 0 8.35456 0.015038 8.42313 0.0441819C8.4917 0.0733258 8.5537 0.11598 8.6054 0.169623L9.83038 1.39454C9.88402 1.44624 9.92667 1.50825 9.95582 1.57681C9.98496 1.64538 10 1.71909 10 1.7936C10 1.8681 9.98496 1.94188 9.95582 2.01045C9.92667 2.07901 9.88402 2.14102 9.83038 2.19272L9.07104 3.00193ZM2.46414 9.60885L0.247076 9.99682C0.213751 10.0027 0.179477 10.0004 0.147211 9.99025C0.114946 9.98007 0.0856145 9.96222 0.0616896 9.93829C0.0377646 9.91436 0.0199803 9.88503 0.00979476 9.85277C-0.000390773 9.8205 -0.00268821 9.78629 0.00316414 9.75297L0.391191 7.53589L2.46414 9.60885ZM0.879911 7.16667L6.65106 1.39952L8.69618 3.44608L2.92504 9.21324L0.879911 7.16667ZM12.9883 14.0949C13.5994 14.9045 14.4263 16 16 16V14.1249C15.3253 14.1249 15.0188 13.7186 14.4361 12.9374L14.4117 12.9051C13.8006 12.0955 12.9737 11 11.4 11C9.82287 11 9.0252 12.0593 8.41895 12.8644L8.36395 12.9374C7.78144 13.7186 7.44411 14.1249 6.8 14.1249C6.12528 14.1249 5.81878 13.7186 5.23606 12.9374L5.18051 12.8637C4.57414 12.0587 3.77668 11 2.2 11C2.13189 11 2.06524 11.002 2 11.0058V12.8883C2.06314 12.8795 2.12957 12.8751 2.2 12.8751C2.87472 12.8751 3.18123 13.2814 3.76395 14.0626L3.78831 14.0949C4.39939 14.9045 5.22629 16 6.8 16C8.37713 16 9.1748 14.9407 9.78105 14.1356L9.83606 14.0626C10.4186 13.2814 10.7253 12.8751 11.4 12.8751C12.0747 12.8751 12.3812 13.2814 12.9639 14.0626L12.9883 14.0949Z"
      fill={props.color}
    />
  </svg>
);
SvgDrawSmall.displayName = 'SvgDrawSmall';
const DrawSmall = withForgeIconProps(SvgDrawSmall, {
  name: 'Draw',
  usage:
    'Use to indicate that the user can draw a shape using the trackpad or similar device',
  related: 'N/A',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default DrawSmall;
