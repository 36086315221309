import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInfoLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#9C28B1"
    />
    <path
      d="M9.56006 10.1202C10.0901 9.34024 10.9 8.57006 11.85 8.94006C12.0882 9.03373 12.2967 9.19002 12.4534 9.39245C12.61 9.59489 12.7091 9.8361 12.74 10.0902C12.7895 10.4917 12.7307 10.899 12.5699 11.2701C12.2699 12.0601 11.9499 12.8402 11.5699 13.6002C11.1581 14.4291 10.862 15.3106 10.6899 16.2201C10.5136 16.8722 10.5961 17.5672 10.92 18.16C11.1194 18.4243 11.3897 18.6267 11.6995 18.7435C12.0092 18.8604 12.3458 18.887 12.67 18.8202C13.4282 18.6594 14.0948 18.2115 14.53 17.5702"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.06 6.8501C12.8884 6.8501 13.56 6.17852 13.56 5.3501C13.56 4.52167 12.8884 3.8501 12.06 3.8501C11.2316 3.8501 10.56 4.52167 10.56 5.3501C10.56 6.17852 11.2316 6.8501 12.06 6.8501Z"
      fill="white"
    />
  </svg>
);
SvgInfoLarge.displayName = 'SvgInfoLarge';
const InfoLarge = withForgeIconProps(SvgInfoLarge, {
  name: 'Info',
  usage:
    'Use to indicate presence of a Tooltip or Popover with more information. For use in alerts, not buttons; follow Forge interruption design guidance',
  related: 'alert, guide, tutorial',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default InfoLarge;
