import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRemoveFormattingLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 1.00037L23 23.0004"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 2.41458V3.5967H3.68212L2.5 2.41458ZM9.76923 6.85538V3.5967H6.51054L2.91421 0.000366211H21.5V3.5967H14.2308V11.3169L9.76923 6.85538ZM9.76923 9.68381V24.0004H14.2308V14.1453L9.76923 9.68381Z"
      fill={props.color}
    />
  </svg>
);
SvgRemoveFormattingLarge.displayName = 'SvgRemoveFormattingLarge';
const RemoveFormattingLarge = withForgeIconProps(SvgRemoveFormattingLarge, {
  name: 'RemoveFormatting',
  usage: 'Use to clear text formatting',
  related: 'text editor, text only, remove, edit, font',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default RemoveFormattingLarge;
