import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFilterSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15 1H1L6.25 6.25V15L9.75 12.98V6.25L15 1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgFilterSmall.displayName = 'SvgFilterSmall';
const FilterSmall = withForgeIconProps(SvgFilterSmall, {
  name: 'Filter',
  usage: 'Use to indicate that data is not filtered, or to filter data',
  related:
    'filled filter, inactive filter, active filter, funnel, sort, refine, search, criteria',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default FilterSmall;
