import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClearbackSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.5193 10L7.51935 6M11.5193 6L7.51935 10M6.01935 13C5.88296 13.0008 5.74779 12.9737 5.62225 12.9204C5.49671 12.8671 5.38347 12.7887 5.28937 12.6899L1.14935 8.37C1.05377 8.26653 1.00067 8.13085 1.00067 7.98999C1.00067 7.84914 1.05377 7.71345 1.14935 7.60999L5.29938 3.30005C5.48401 3.11163 5.73556 3.00376 5.99933 3H14.9993V10C14.9993 10.7956 14.6833 11.5587 14.1207 12.1213C13.5581 12.6839 12.795 13 11.9993 13H6.01935Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgClearbackSmall.displayName = 'SvgClearbackSmall';
const ClearbackSmall = withForgeIconProps(SvgClearbackSmall, {
  name: 'Clearback',
  usage: 'Use to delete the contents of an input',
  related: 'clear, backspace',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ClearbackSmall;
