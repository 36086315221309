import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLeversLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M17.81 8.5H23M1 8.5H12M10.79 15.5H23M1 15.5H5.22M10.5 15.5C10.5 16.8807 9.38071 18 8 18C6.61929 18 5.5 16.8807 5.5 15.5C5.5 14.1193 6.61929 13 8 13C9.38071 13 10.5 14.1193 10.5 15.5ZM17.5 8.5C17.5 9.88071 16.3807 11 15 11C13.6193 11 12.5 9.88071 12.5 8.5C12.5 7.11929 13.6193 6 15 6C16.3807 6 17.5 7.11929 17.5 8.5Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgLeversLarge.displayName = 'SvgLeversLarge';
const LeversLarge = withForgeIconProps(SvgLeversLarge, {
  name: 'Levers',
  usage:
    'Use to view additional filters or to filter information in a compact space, like mobile',
  related: 'filter, sort, data',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default LeversLarge;
