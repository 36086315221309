import InfoLarge from '@athena/forge-icons/dist/InfoLarge';

import rawContents from "!!raw-loader!./InfoIcon.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { InfoLarge };

function InfoIcon():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Info Icon" />;
}

export default InfoIcon;
