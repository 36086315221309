import UploadLarge from '@athena/forge-icons/dist/UploadLarge';
import { nanoid } from 'nanoid';
import { ReactNode, useMemo, useRef, useState } from 'react';
import Button from '../../Button';
import InlineAlert from '../../InlineAlert';
import Input from '../../Input';
import List from '../../List';
import ListItem from '../../ListItem';
import Tag from '../../Tag';
import { forgeClassHelper } from '../../utils/classes';
import {
  FileUploadBehavior,
  FileUploadChangeEvent,
  FileUploadExtensions,
  FileUploadFiles,
  FileUploadLimit,
} from '../FileUpload';
import { FileUploadAction, FileUploadState } from '../fileUploadReducer';

export interface FileUploadPendingProps {
  acceptedFileTypes: FileUploadExtensions;
  behavior: FileUploadBehavior;
  disabled: boolean;
  dispatch: React.Dispatch<FileUploadAction>;
  files: FileUploadFiles;
  fileUploadLimit: FileUploadLimit;
  onDragEnter?: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave?: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
  onChange?: (event: FileUploadChangeEvent) => FileUploadState | undefined;
  totalNumberOfFiles: number;
}

const classes = forgeClassHelper('file-upload');

/** Validates the file upload limit against the selected files total */
const checkIsDropZoneEnabled = (
  disabled: boolean,
  fileUploadLimit: FileUploadLimit,
  selectedFilesTotal: number
): boolean => {
  if (disabled) return false;
  else if (fileUploadLimit === 'unlimited') return true;
  else return selectedFilesTotal < fileUploadLimit;
};

/**
 * Validates the selected files against the accepted file types and the upload limit,
 * and sets any errors encountered during the validation process.
 */
const validate = (
  files: FileUploadFiles,
  totalNumberOfFiles: number,
  acceptedFileTypes: FileUploadExtensions,
  fileUploadLimit: FileUploadLimit
): Set<string> => {
  /**
   * Using a Set for this data structure to ensure no duplication of error messages; error messages
   * are rendered one per error type, not file, for a simplified UI
   *
   * example: if the component is set to only accept PDF files, if a user drag and drops multiple
   * PNG files they will be presented a single error message for that common violation, not a series of
   * errors for each violating file
   */
  const errors = new Set<string>();

  if (typeof fileUploadLimit === 'number' && totalNumberOfFiles > fileUploadLimit) {
    errors.add(`Only ${fileUploadLimit} file uploads are allowed at a time`);
  }

  files.forEach((f) => {
    const fileExtension = '.' + f.fileData.name.split('.').pop() || '';
    const fileType = f.fileData.type;

    // Check for accepted file type
    if (acceptedFileTypes.length > 0) {
      // matches mime-type, such as image/png
      const isMimeTypeValid = acceptedFileTypes.includes(fileType);
      // matches file extension, such as .png
      const isExtensionValid = acceptedFileTypes.includes(fileExtension);

      if (!isMimeTypeValid && !isExtensionValid) {
        errors.add(`${fileExtension} files are not supported`);
      }
    }
  });

  return errors;
};

/** Entry point for the user to upload files.
 *
 * Handles file selection via drag and drop or a button click.
 */
const FileUploadPending = ({
  acceptedFileTypes,
  disabled,
  dispatch,
  files,
  fileUploadLimit,
  behavior,
  onDragEnter,
  onDragLeave,
  onDrop,
  totalNumberOfFiles,
}: FileUploadPendingProps): ReactNode => {
  const [simpleUploadButtonId] = useState(() => nanoid());
  const [selectedFilesListId] = useState(() => nanoid());
  const [errorsListId] = useState(() => nanoid());
  const inputRef = useRef<HTMLInputElement>(null);

  const [inDropZone, setInDropZone] = useState(false);
  const errorMessages = useMemo(
    () => validate(files, totalNumberOfFiles, acceptedFileTypes, fileUploadLimit),
    [files, acceptedFileTypes, totalNumberOfFiles, fileUploadLimit]
  );

  const isMultiUploadEnabled = fileUploadLimit === 'unlimited' || fileUploadLimit > 1;
  /** disabled drop zone when the component is disabled or we have reached the upload limitation */
  const isDropZoneEnabled = checkIsDropZoneEnabled(disabled, fileUploadLimit, totalNumberOfFiles);

  /** Handles the drag leave event over the Drop Zone */
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if (isDropZoneEnabled) {
      setInDropZone(false);
    }
    onDragLeave && onDragLeave(e);
  };

  /** Handles the drag over event over the Drop Zone
   *
   * Manages the component's dropEffect and updates the inDropZone state
   */
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if (isDropZoneEnabled) {
      e.dataTransfer.dropEffect = 'copy';
      !inDropZone && setInDropZone(true);
    } else {
      e.dataTransfer.dropEffect = 'none';
    }
  };

  const addFileListToState = (nextState: 'pending' | 'start', fileList: FileList | null): void => {
    const newFiles: FileUploadFiles = [...(fileList || [])].map((fileData) => {
      return { fileData, id: nanoid() };
    });
    dispatch({ type: nextState, affectedFiles: newFiles });
  };

  const handleUploadStagedFiles = (): void => {
    dispatch({ type: 'start', affectedFiles: files });
  };

  /** Handles the drop event over the Drop Zone
   *
   * Responsible for updating the selected upload files state object and running file validation for error checking and management
   */
  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if (isDropZoneEnabled) {
      addFileListToState('pending', e.dataTransfer.files);
      setInDropZone(false);
    }
    onDrop && onDrop(e);
  };

  /** Handles the drag enter event over the Drop Zone */
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    onDragEnter && onDragEnter(e);
  };

  if (behavior === 'drag-and-drop') {
    return (
      <div
        {...classes({
          element: 'drag-and-drop',
          states: { disabled: !isDropZoneEnabled },
        })}
      >
        <div
          {...classes({
            element: 'upload-container',
            states: { 'in-drop-zone': !!inDropZone, disabled: !isDropZoneEnabled },
          })}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <header className="fe_u_visually-hidden">File Drag and Drop Zone</header>
          <UploadLarge {...classes('upload-icon')} semanticColor={!isDropZoneEnabled ? 'disabled' : 'interactive'} />
          <span {...classes({ element: 'primary-text', states: { disabled: !isDropZoneEnabled } })}>
            Drag {isMultiUploadEnabled ? 'files' : 'file'} here or
          </span>
          <Button disabled={!isDropZoneEnabled} text="Browse Files" variant="tertiary">
            <Input
              ref={inputRef}
              type="file"
              multiple={isMultiUploadEnabled}
              accept={acceptedFileTypes.join(',')}
              onChange={(e) => addFileListToState('pending', e.target.files)}
            />
          </Button>
          <span {...classes({ element: 'secondary-text', states: { disabled: !isDropZoneEnabled } })}>
            {acceptedFileTypes.length ? `Accepted file types: ${acceptedFileTypes.join(', ')}` : null}
          </span>
        </div>
        {errorMessages.size > 0 && (
          <section {...classes('file-selection-error-list-container')} aria-live="polite">
            <header className="fe_u_visually-hidden" id={errorsListId}>
              File Upload Errors
            </header>
            <List {...classes('file-selection-error-list')} aria-labelledby={errorsListId}>
              {Array.from(errorMessages).map((error) => (
                <ListItem {...classes('file-selection-error-item')} uniqueKey={error} key={error}>
                  <InlineAlert iconSize="small" {...classes('file-selection-error-message')} type="attention">
                    {error}
                  </InlineAlert>
                </ListItem>
              ))}
            </List>
          </section>
        )}
        {!!files.length && (
          <section {...classes('selected-container-layout')} aria-live="polite">
            <header className="fe_u_visually-hidden">List of Currently Selected Files</header>
            <div {...classes('selected-tags-container')}>
              <span {...classes('selected-text')} id={selectedFilesListId}>
                Selected{' '}
                {typeof fileUploadLimit === 'number' && fileUploadLimit
                  ? totalNumberOfFiles <= fileUploadLimit
                    ? `(${totalNumberOfFiles}/${fileUploadLimit})`
                    : `- Upload limit exceeded`
                  : ''}
              </span>
              <List {...classes('selected-tag-list')} aria-labelledby={selectedFilesListId}>
                {files.map((f) => {
                  const { id, fileData } = f;
                  return (
                    <ListItem {...classes('selected-tag-list-item')} uniqueKey={id} key={id}>
                      <Tag
                        {...classes('selected-tag')}
                        text={fileData.name}
                        isRemovable
                        onRemove={() => dispatch({ type: 'remove-pending', affectedFile: f })}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
            <Button
              {...classes('upload-button')}
              variant="secondary"
              text="Upload Files"
              disabled={errorMessages.size > 0}
              onClick={handleUploadStagedFiles}
            />
          </section>
        )}
      </div>
    );
  } else {
    return (
      <div {...classes('simple')}>
        <label
          {...classes({ element: 'file-upload-label' })}
          htmlFor={simpleUploadButtonId}
        >{`Upload File${isMultiUploadEnabled ? 's' : ''}`}</label>
        <Button id={simpleUploadButtonId} disabled={disabled} text="Browse Files" variant="secondary">
          <Input
            ref={inputRef}
            type="file"
            multiple={isMultiUploadEnabled}
            accept={acceptedFileTypes.join(',')}
            onChange={(e) => addFileListToState('start', e.target.files)}
          />
        </Button>
      </div>
    );
  }
};

export default FileUploadPending;
