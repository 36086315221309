import rawContents from "!!raw-loader!./MultipleInputs.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function MultipleInputs():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Multiple Inputs" />;
}

export default MultipleInputs;
