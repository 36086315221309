import PropTypes from 'prop-types';
import { ReactElement, ReactNode, Ref, WeakValidationMap, useCallback, useMemo, useRef, useState } from 'react';
import useIsWrapping from '../useIsWrapping';
import { forgeClassHelper } from '../utils/classes';
import FakeEvent from '../utils/fake-event';
import forwardRefToProps from '../utils/forwardRefToProps';
import {
  SegmentedButtonValuesState,
  SegmentedButtonChildRole,
  SegmentedButtonClickHandler,
  SegmentedButtonContext,
  SegmentedButtonGroupRole,
  SegmentedButtonRefHandler,
  SegmentedButtonSize,
} from './SegmentedButtonContext';
import { ReadOnlyFormValues } from './components/ReadOnlyFormValues';

const classes = forgeClassHelper('segmented-button');

export type SegmentedButtonBehavior = 'button' | 'checkbox' | 'clearable-radio' | 'radio';

/** Version of `SegmentedButtonValuesState` that allows `false` values
 * instead of not defining the key.
 *
 * While Record<string, true> is a good type for state management due to its
 * consistency, it's an awkward data structure for client applications to build up
 * to pass to a `value` prop.
 */
export type SegmentedButtonValues<Behavior extends SegmentedButtonBehavior> = Behavior extends 'checkbox'
  ? ReadonlyArray<string>
  : string;

export type SegmentedButtonOnChangeEvent<Behavior extends SegmentedButtonBehavior> = FakeEvent<
  SegmentedButtonValues<Behavior>
>;

export type SegmentedButtonAlignment = 'connected' | 'separated' | 'stacked';

export interface SegmentedButtonProps<Behavior extends SegmentedButtonBehavior = 'button'> {
  /** What behavior Button elements within SegmentedButton should exhibit.
   *
   * If `button`, Button components defined as 'button' elements. The application
   * developer is responsible for configuring their own onClick handlers.
   *
   * if `checkbox`, makes Button components defined as children behave as if they
   * are checkboxes.
   *
   * If `radio`, a radiogroup element is defined, and Button components defined as
   * 'radio' elements.
   *
   * The `clearable-radio` behavior is a specialization of `radio` that allows the
   * selected option to be deselected. This behavior is out of spec with the W3C,
   * as radios are meant to define an option to represent "no selection". It's
   * allowed here, as radios styled as buttons give a stronger impression that
   * clicking on an option should deselect it.
   */
  behavior?: Behavior;
  /** The various buttons to render
   *
   * The only supported children are [Button](/components/button) or
   * [Menu](/components/menu) components. However, A
   * Button can be wrapped in a [Tooltip](/components/tooltip).
   *
   * Each [Button](/components/button) child needs to have the `formValue`
   * prop defined when `behavior` is not `button`.
   *
   * If a [Menu](/components/menu) exists, it's only supported with
   * `behavior === 'button'`.
   *
   * "Split-primary" styling is applied when:
   * 1. The first child is not a Menu
   * 2. The last child is a Menu with no Button trigger text
   * 3. There are only 2 children
   * 4. The `alignment` prop is `connected`, or not defined
   */
  children?: ReactNode;
  /** Class name to apply to the outermost `<div>` */
  className?: string;

  /** Allow disabling of all buttons at once. */
  disabled?: boolean;
  /** Name to give the field when used in a form.
   *
   * This name must be unique within the `<form>`.
   */
  name?: string;
  /** Callback function called when the form value of SegmentedButton changes.
   *
   * This will happen when `behavior` is not `button`, and a child Button is
   * clicked. For more fine-grained control over button clicks, onClick handlers
   * can be passed to each Button child instead.
   */
  onChange?: (event: SegmentedButtonOnChangeEvent<Behavior>) => void;
  /** ref to the outermost `<div>` wrapping the component */
  ref?: Ref<HTMLDivElement>;
  /** The size of the button segments */
  size?: SegmentedButtonSize;
  /** Affects alignment between buttons
   *
   * If `connected`, buttons are close enough to share a border
   *
   * If `separated`, buttons have a small gap between them.
   *
   * If `stacked`, buttons are stacked on top of each other.
   */
  alignment?: SegmentedButtonAlignment;
  /** Controlled value for the SegmentedButton */
  value?: SegmentedButtonValues<Behavior>;
}
interface SegmentedButtonComponentProps<Behavior extends SegmentedButtonBehavior>
  extends SegmentedButtonProps<Behavior> {
  /** ref to the outermost <div> wrapping the component */
  forwardedRef?: Ref<HTMLDivElement>;
}

/** Determines what roles should be applied to the SegmentedButton group element,
 * and the Button child elements */
const elementRoles = (
  behavior: SegmentedButtonBehavior
): {
  groupRole: SegmentedButtonGroupRole;
  childRole: SegmentedButtonChildRole;
} => {
  switch (behavior) {
    case 'button':
      return { groupRole: 'group', childRole: 'button' };
    case 'checkbox':
      return { groupRole: 'group', childRole: 'checkbox' };
    case 'clearable-radio':
      return { groupRole: 'radiogroup', childRole: 'radio' };
    case 'radio':
      return { groupRole: 'radiogroup', childRole: 'radio' };
  }
};

interface HandleClickArgs {
  checkedValues: SegmentedButtonValuesState;
  formValue: string;
  behavior: SegmentedButtonBehavior;
}
/** Clicks are processed differently depending on the behavior prop */
const handleClick = ({ checkedValues, formValue, behavior }: HandleClickArgs): SegmentedButtonValuesState => {
  switch (behavior) {
    case 'checkbox': {
      /** Extract the current value from the previous checked values.
       * This causes the key to not exist when it's being unchecked. */
      const { [formValue]: _, ...newCheckedValues } = checkedValues;
      if (!checkedValues[formValue]) {
        // Add the value back in if it's getting checked
        newCheckedValues[formValue] = true;
      }
      return newCheckedValues;
    }
    case 'radio':
      return checkedValues[formValue] ? checkedValues : { [formValue]: true };

    case 'clearable-radio': {
      return checkedValues[formValue] ? {} : { [formValue]: true };
    }
  }
  return checkedValues;
};

export interface SegmentedButtonDefaultExport {
  <Behavior extends SegmentedButtonBehavior = 'button'>(props: SegmentedButtonProps<Behavior>): ReactElement;
  displayName?: string;
}

/** SegmentedButton
 *
 * An interactive element where users choose an action in a set of related buttons.
 */
const SegmentedButton = <Behavior extends SegmentedButtonBehavior = 'button'>({
  children,
  className,
  disabled = false,
  forwardedRef,
  name,
  onChange,
  behavior = 'button' as Behavior,
  size = 'large',
  alignment: upstreamAlignment = 'connected',
  value: upstreamValue,
}: SegmentedButtonComponentProps<Behavior>): ReactElement => {
  const [stateValues, setStateValues] = useState<SegmentedButtonValuesState>({});
  /** valueRefLookup and refArray both hold the same refs.
   * valueRefLookup is optimized to add and remove refs, while refArray is used
   * to detect if these refs are beginning to render vertically.
   */
  const valueRefLookup = useRef<Record<string, HTMLElement>>({});
  const refArray = useRef<HTMLElement[]>([]);

  /** Determines if a constrained width causes buttons to wrap
   *
   * useIsWrapping relies on completing a render cycle in order to determine if
   * buttons are wrapping, so this will temporarily be false during these
   * re-renders
   */
  const stacked = useIsWrapping(refArray);

  /** Determines how buttons are positioned relative to each other, taking into
   * account whether there's enough space for all children to render horizontally.
   */
  const alignment = stacked ? 'stacked' : upstreamAlignment;
  const { groupRole, childRole } = elementRoles(behavior);

  /** Callback function passed to Button via context that allows SegmentedButton
   * to keep track of refs for each of its children.
   *
   * Each Button uses a unique id to differentiate itself.
   */
  const buttonRefHandler = useCallback<SegmentedButtonRefHandler>((id, ref) => {
    if (ref) {
      valueRefLookup.current[id] = ref;
    } else {
      delete valueRefLookup.current[id];
    }
    refArray.current = Object.values(valueRefLookup.current);
  }, []);

  /** Reconciles value via props against state, preferring props if defined */
  const checkedValues = useMemo<SegmentedButtonValuesState>(() => {
    if (typeof upstreamValue === 'object') {
      /** Convert values into a lookup object for better performance */
      return upstreamValue.reduce<SegmentedButtonValuesState>((acc, value) => {
        acc[value] = true;
        return acc;
      }, {});
    } else if (typeof upstreamValue === 'string') {
      return { [upstreamValue]: true };
    } else {
      return stateValues;
    }
  }, [stateValues, upstreamValue]);

  /** Callback function passed to Button via context that allows SegmentedButton
   * to respond to clicks from each of its children.
   *
   * Each Button uses its formValue prop to differentiate itself.
   */
  const buttonClickHandler = useCallback<SegmentedButtonClickHandler>(
    (formValue) => {
      const newCheckedValues = handleClick({ checkedValues, formValue, behavior });
      if (newCheckedValues !== checkedValues) {
        if (upstreamValue === undefined) {
          setStateValues(newCheckedValues);
        }
        if (onChange) {
          const valueArray = Object.keys(newCheckedValues);
          const eventTargetValue = (
            behavior === 'checkbox' ? valueArray : valueArray[0] || ''
          ) as SegmentedButtonValues<Behavior>;
          onChange(new FakeEvent({ value: eventTargetValue, id: name }));
        }
      }
    },
    [checkedValues, name, onChange, behavior, upstreamValue]
  );

  return (
    <SegmentedButtonContext.Provider
      value={{
        buttonClickHandler,
        buttonRefHandler,
        disabled,
        childRole,
        checkedValues,
        size,
      }}
    >
      <div
        {...classes({ modifiers: { [alignment]: true, [size]: true }, extra: className })}
        ref={forwardedRef}
        role={groupRole}
      >
        {children}
        <ReadOnlyFormValues name={name} />
      </div>
    </SegmentedButtonContext.Provider>
  );
};
SegmentedButton.displayName = 'SegmentedButton';

const segmentedButtonPropTypes: WeakValidationMap<
  Omit<SegmentedButtonProps, 'value' | 'behavior'> & { behavior?: SegmentedButtonBehavior; value?: string | string[] }
> = {
  behavior: PropTypes.oneOf<SegmentedButtonBehavior>(['button', 'checkbox', 'clearable-radio', 'radio']),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf<SegmentedButtonSize>(['small', 'medium', 'large', 'x-large']),
  alignment: PropTypes.oneOf<SegmentedButtonAlignment>(['connected', 'separated', 'stacked']),
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired)]),
};
SegmentedButton.propTypes = segmentedButtonPropTypes;

export default forwardRefToProps(SegmentedButton) as SegmentedButtonDefaultExport;
