import rawContents from "!!raw-loader!./HiddenOnScreen.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function HiddenOnScreen():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Hidden On Screen" />;
}

export default HiddenOnScreen;
