import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMobileLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6 17H18M5 1H19V20C19 20.7956 18.6839 21.5587 18.1213 22.1213C17.5587 22.6839 16.7956 23 16 23H5V1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12 21.5C12.2967 21.5 12.5867 21.4121 12.8334 21.2473C13.08 21.0825 13.2723 20.8481 13.3859 20.574C13.4994 20.2999 13.5291 19.9982 13.4712 19.7073C13.4133 19.4163 13.2704 19.1492 13.0607 18.9394C12.8509 18.7297 12.5836 18.5867 12.2926 18.5288C12.0016 18.4709 11.7001 18.5007 11.426 18.6142C11.1519 18.7278 10.9176 18.9201 10.7528 19.1667C10.588 19.4134 10.5 19.7033 10.5 20C10.5 20.3978 10.658 20.7792 10.9393 21.0605C11.2206 21.3418 11.6022 21.5 12 21.5Z"
      fill={props.color}
    />
  </svg>
);
SvgMobileLarge.displayName = 'SvgMobileLarge';
const MobileLarge = withForgeIconProps(SvgMobileLarge, {
  name: 'Mobile',
  usage:
    'Use to indicate a mobile number or device, or the ability to send an SMS',
  related: 'cell, iphone, android, ios, phone, call, text',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default MobileLarge;
