import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPalmSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3 3.93a1 1 0 0 0-2 0zm11 2.443.994.113a1 1 0 0 0-.295-.828zm-2-4.396a1 1 0 1 0-2 0zm-6 0a1 1 0 0 0-2 0zM9 1a1 1 0 0 0-2 0zm1.5 7.815-.121.993a1 1 0 0 0 1.1-.792zm2.912 2.219-.94-.34zm.12-.54-.994-.114zm-11.223.971-.895.446zM1 3.931v6.08h2V3.93zm.414 7.98c1.543 3.098 4.316 4.32 6.954 4.053 2.583-.26 5.033-1.953 5.984-4.591l-1.88-.678c-.668 1.85-2.417 3.089-4.305 3.28-1.833.185-3.801-.622-4.963-2.956zm13.111-1.305.469-4.12-1.988-.226-.468 4.12zM12 6.373V1.977h-2v4.396zM4 1.977v4.885h2V1.977zM7 1v5.862h2V1zm3.02 5.173-.5 2.442 1.96.401.5-2.442zm.601 1.65c-.946-.116-2.17.218-3.142.81-.975.596-1.979 1.638-1.979 3.113h2c0-.478.33-.983 1.021-1.405.696-.425 1.47-.58 1.858-.533zM14.7 5.658c-.69-.674-2.23-1.115-4.138-.183l.878 1.797c1.293-.632 1.885-.161 1.862-.184zm-.347 5.715a3.4 3.4 0 0 0 .173-.767l-1.987-.226a1.4 1.4 0 0 1-.067.314zM1 10.01c0 .609.103 1.276.414 1.9l1.79-.89C3.068 10.745 3 10.404 3 10.01z"
      fill={props.color}
    />
  </svg>
);
SvgPalmSmall.displayName = 'SvgPalmSmall';
const PalmSmall = withForgeIconProps(SvgPalmSmall, {
  name: 'Palm',
  usage: 'Use to indicate the need to pause, wait, or discontinue an action.',
  related: 'stop, pause, discontinue, wait',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default PalmSmall;
