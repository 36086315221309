import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSuccessSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#118647"
    />
    <path
      d="M4 8.22021L6.79 11L12 5.5"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgSuccessSmall.displayName = 'SvgSuccessSmall';
const SuccessSmall = withForgeIconProps(SvgSuccessSmall, {
  name: 'Success',
  usage:
    'Use to confirm or indicate success. For use in alerts, not buttons; follow Forge interruption design guidance',
  related: 'done, yes, complete',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default SuccessSmall;
