import rawContents from "!!raw-loader!./Inline.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Inline():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Inline" />;
}

export default Inline;
