import RawDataPreview from '../../components/raw-data-preview';

import rawContents from "!!raw-loader!./BasicForm.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { RawDataPreview };

function BasicForm():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Basic Form" />;
}

export default BasicForm;
