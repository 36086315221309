import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMicrophoneOffLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4.93994C9 3.31624 10.3163 2 11.94 2H12.06C13.6837 2 15 3.31624 15 4.93994V11.0601C15 11.3922 14.9449 11.7114 14.8434 12.0092L16.3483 13.5141C16.7629 12.7911 17 11.9533 17 11.0601V4.93994C17 2.21163 14.7883 0 12.06 0H11.94C9.45691 0 7.40168 1.832 7.05235 4.21816L9 6.1658V4.93994ZM9 8.99423L7 6.99423V11.0601C7 13.7884 9.21174 16 11.94 16H12.06C13.1706 16 14.1956 15.6335 15.0207 15.0149L13.5818 13.576C13.1379 13.8451 12.617 14 12.06 14H11.94C10.3163 14 9 12.6838 9 11.0601V8.99423ZM15.9883 15.9825C14.8928 16.9573 13.4744 17.5 12 17.5C10.4087 17.5 8.88263 16.8678 7.7574 15.7426C6.63217 14.6174 6 13.0913 6 11.5C6 10.9477 5.55228 10.5 5 10.5C4.44772 10.5 4 10.9477 4 11.5C4 13.6218 4.84291 15.6565 6.34319 17.1568C7.6058 18.4194 9.247 19.2165 11 19.4373V23C11 23.5523 11.4477 24 12 24C12.5523 24 13 23.5523 13 23V19.4373C14.6384 19.2309 16.1791 18.5212 17.4043 17.3985L15.9883 15.9825ZM18.7022 15.868C19.5427 14.5784 20 13.0628 20 11.5C20 10.9477 19.5523 10.5 19 10.5C18.4477 10.5 18 10.9477 18 11.5C18 12.528 17.7362 13.5288 17.246 14.4118L18.7022 15.868Z"
      fill={props.color}
    />
    <path
      d="M1 1L23 23"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgMicrophoneOffLarge.displayName = 'SvgMicrophoneOffLarge';
const MicrophoneOffLarge = withForgeIconProps(SvgMicrophoneOffLarge, {
  name: 'MicrophoneOff',
  usage: 'Use to indicate a muted microphone, or to unmute',
  related: 'audio, mic',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default MicrophoneOffLarge;
