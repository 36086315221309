import React, { WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
const classes = forgeClassHelper({ name: 'signpost' });

export interface SignpostProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Content to display in signpost */
  children: React.ReactNode;

  /** Adds a class to the root element of the component */
  className?: string;
  /** Link to display below the content */
  link?: React.ReactNode;

  /** Media to display on the left-hand side */
  media?: React.ReactNode;

  /** Specifies the amount of padding around the content */
  padding?: 'default' | 'none';
}

/** Banner with configurable secondary color backgrounds, helper text, and a media slot for an illustration on the left side. Links possible. Used for guiding users through a complex workflow or calling out extra resources. */
const Signpost = ({ className, children, link, media, padding = 'default' }: SignpostProps): React.ReactElement => {
  return (
    <div {...classes({ extra: className, modifiers: { padded: padding === 'default' } })}>
      {media ? <span {...classes({ element: 'media' })}>{media}</span> : <></>}
      <div>
        {children}
        {link && <div {...classes({ element: 'link' })}>{link}</div>}
      </div>
    </div>
  );
};

const signpostPropTypes: WeakValidationMap<SignpostProps> = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  link: PropTypes.node,
  media: PropTypes.node,
  padding: PropTypes.oneOf(['default', 'none']),
};

Signpost.propTypes = signpostPropTypes;

export default Signpost;
