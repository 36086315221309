import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSpeakerOffSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 1.00001C9.5 0.589715 9.24938 0.221054 8.86786 0.0701246C8.48634 -0.0808048 8.05133 0.0166258 7.77064 0.315875L5.47277 2.76567L6.8877 4.1806L7.5 3.52783V4.7929L9.5 6.7929V1.00001ZM9.5 8.20711L7.5 6.20711V12.4722L4.53942 9.31588C4.35038 9.11434 4.08638 9.00001 3.81006 9.00001H2V7.00001H3.81006C4.08638 7.00001 4.35038 6.88567 4.53942 6.68414L6.20322 4.91034L4.78829 3.4954L3.37698 5.00001H1C0.447715 5.00001 0 5.44772 0 6.00001V10C0 10.5523 0.447715 11 1 11H3.37698L7.77064 15.6841C8.05133 15.9834 8.48634 16.0808 8.86786 15.9299C9.24938 15.779 9.5 15.4103 9.5 15V8.20711ZM10.7508 9.45791L11.792 10.4992C11.5694 10.5086 11.3422 10.4442 11.15 10.3C10.8762 10.0947 10.7377 9.77584 10.7508 9.45791ZM12.6567 9.94962L11.1751 8.46802C11.2244 8.31793 11.25 8.16003 11.25 8.00001C11.25 7.67545 11.1447 7.35965 10.95 7.10001C10.6186 6.65818 10.7082 6.03138 11.15 5.70001C11.5918 5.36864 12.2186 5.45818 12.55 5.90001C13.0044 6.50584 13.25 7.24271 13.25 8.00001C13.25 8.6957 13.0427 9.37415 12.6567 9.94962ZM12.448 11.1551C12.4173 11.1825 12.3863 11.2094 12.3548 11.236C11.9329 11.5923 11.8796 12.2232 12.236 12.6452C12.5923 13.0671 13.2232 13.1204 13.6452 12.764C13.7196 12.7012 13.7925 12.6369 13.8639 12.571L12.448 11.1551ZM14.5461 11.839L13.1194 10.4123C13.2836 10.1855 13.4274 9.94368 13.5487 9.6896C13.8321 9.09598 13.9859 8.44893 14.0002 7.79129C14.0093 7.20788 13.8719 6.63145 13.6007 6.1147C13.329 5.59718 12.9318 5.15618 12.4456 4.83223C11.9859 4.52603 11.8616 3.9052 12.1678 3.44557C12.474 2.98595 13.0948 2.86157 13.5544 3.16778C14.3196 3.67751 14.9443 4.37126 15.3715 5.18512C15.7987 5.99901 16.0149 6.90718 15.9999 7.82639L15.9998 7.8312C15.9799 8.77343 15.7596 9.7006 15.3536 10.5511C15.134 11.0112 14.8628 11.4431 14.5461 11.839Z"
      fill={props.color}
    />
    <path
      d="M1 1L15 15"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgSpeakerOffSmall.displayName = 'SvgSpeakerOffSmall';
const SpeakerOffSmall = withForgeIconProps(SvgSpeakerOffSmall, {
  name: 'SpeakerOff',
  usage: 'Use to indicate muted audio, or to unmute audio',
  related: 'speaker, sound, volume',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default SpeakerOffSmall;
