import React from 'react';
import { dateformat } from './DateRangeInputUtils';
import { forgeClassHelper } from '../utils/classes';
import FormError from '../FormError';

const classes = forgeClassHelper({ name: 'date-range-input' });

/** Arguments to BannerAccessory. */
export interface BannerAccessoryProps {
  /** REQUIRED total number of banners to display. */
  bannerCount: number;
  /**
   * REQUIRED number that holds the total number of banners that have
   * been created at this point including this current one.
   */
  bannersCreated: number;
  /** Optional child nodes. */
  children?: React.ReactNode;
  /** REQUIRED prefix id for the banner tags. */
  id: string;
  /** REQUIRED message to display. */
  msg: string;
}

/** Helper tag for Banner and BannerItem tags. */
export const BannerAccessory = (props: BannerAccessoryProps): JSX.Element => {
  // This class does the body of the Banner/BannerItem pairing.
  const { bannerCount, bannersCreated, id, msg } = props;

  return (
    <FormError
      id={`${id}-bannerid`}
      data-testid={`${id}-bannerid`}
      className={bannerCount > 1 && bannersCreated !== bannerCount ? 'fe_u_margin--bottom-small' : ''}
    >
      {msg}
    </FormError>
  );
};

/** Arguments to AttentionBanners. */
export interface AttentionBannersProps {
  bannerCount: number;
  /** REQUIRED boolean tells the error to always render below the input area instead of based on content-size and breakpoint. */
  errorAlwaysBelow: boolean;
  firstDateCustomValidationMsg: string | null;
  hasFirstDateCustomValidationMsg: boolean;
  hasSecondDateCustomValidationMsg: boolean;
  id: string;
  isFirstDateAfterMaxDate: boolean;
  isFirstDateAfterSecondDate: boolean;
  isFirstDateBeforeMinDate: boolean;
  isSecondDateAfterMaxDate: boolean;
  isSecondDateBeforeMinDate: boolean;
  isValidFirstDate: boolean;
  isValidSecondDate: boolean;
  maxDate?: Date;
  minDate?: Date;
  secondDateCustomValidationMsg: string | null;
  showRequiredBannerFirstDate: boolean;
  showRequiredBannerSecondDate: boolean;
  standalone: boolean;
  /** REQUIRED boolean indicating if the DateRangeInput should suppress vertical spacing. */
  suppressVerticalSpacing: boolean;
  /** REQUIRED boolean indicating if the DateRangeInput should suppress the display of warning banners. */
  suppressWarningBanner: boolean;
}

/** Create a JSX of all Banners. */
export const AttentionBanners = (props: AttentionBannersProps): JSX.Element => {
  const {
    bannerCount,
    errorAlwaysBelow,
    firstDateCustomValidationMsg,
    hasFirstDateCustomValidationMsg,
    hasSecondDateCustomValidationMsg,
    id,
    isFirstDateAfterMaxDate,
    isFirstDateAfterSecondDate,
    isFirstDateBeforeMinDate,
    isSecondDateAfterMaxDate,
    isSecondDateBeforeMinDate,
    isValidFirstDate,
    isValidSecondDate,
    maxDate,
    minDate,
    secondDateCustomValidationMsg,
    showRequiredBannerFirstDate,
    showRequiredBannerSecondDate,
    standalone,
    suppressWarningBanner,
  } = props;
  let bannersCreated = 0;

  return suppressWarningBanner === false ? (
    <div
      {...classes({
        extra: `fe_u_flex-container fe-input__input fe_u_margin--right-small ${
          bannerCount > 0 && errorAlwaysBelow ? 'fe_u_margin--top-small' : ''
        }`,
        modifiers: { stacked: true },
      })}
    >
      {(isFirstDateAfterMaxDate || isSecondDateAfterMaxDate) && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-futuredate`}
          msg={`Dates cannot be selected after ${dateformat.format(maxDate).replace(/\//g, '-')}.`}
        />
      )}
      {(isFirstDateBeforeMinDate || isSecondDateBeforeMinDate) && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-pastdate`}
          msg={`Dates cannot be selected before ${dateformat.format(minDate).replace(/\//g, '-')}.`}
        />
      )}
      {isFirstDateAfterSecondDate && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-dateorder`}
          msg={'Second date cannot precede first.'}
        />
      )}
      {hasFirstDateCustomValidationMsg && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-fromcustomvalidation`}
          msg={firstDateCustomValidationMsg ?? ''}
        />
      )}
      {standalone && showRequiredBannerFirstDate && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-firstdatereq`}
          msg={'First date is required.'}
        />
      )}
      {!isValidFirstDate && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-firstdatevalid`}
          msg={'First date is not valid.'}
        />
      )}
      {hasSecondDateCustomValidationMsg && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-tocustomvalidation`}
          msg={secondDateCustomValidationMsg ?? ''}
        />
      )}
      {standalone && showRequiredBannerSecondDate && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-seconddatereq`}
          msg={'Second date is required.'}
        />
      )}
      {!isValidSecondDate && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-seconddatevalid`}
          msg={'Second date is not valid.'}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default BannerAccessory;
