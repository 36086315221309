import InfoLarge from '@athena/forge-icons/dist/InfoLarge';

import rawContents from "!!raw-loader!./WrappingChildren.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { InfoLarge };

function WrappingChildren():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Wrapping Children" />;
}

export default WrappingChildren;
