import { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/layout';
import SecondaryMenuGrid from '../components/secondary-menu-grid';
import ComponentMenuGrid from '../components/component-menu-grid';

interface PageData {
  menuItems: {
    childrenMenuItems: object[];
  };
  landingPage: {
    title: string;
    field_primary_color?: string;
    field_secondary_color?: string;
  };
}

export default function LandingPage({ data: gatsbyData, pageContext, children }: PageProps): ReactElement {
  const data = gatsbyData as PageData;
  const { title } = data.landingPage;

  const hasMenuItems = data.menuItems && data.menuItems.childrenMenuItems.length > 0;

  return (
    <Layout pageContext={pageContext} title={title} legacy isLandingPage>
      {children}
      {hasMenuItems && (title === 'Components' ? <ComponentMenuGrid /> : <SecondaryMenuGrid />)}
    </Layout>
  );
}

export const query = graphql`
  query ($title: String) {
    menuItems(title: { eq: $title }) {
      childrenMenuItems {
        title
      }
    }
    landingPage(title: { eq: $title }) {
      title
    }
  }
`;
