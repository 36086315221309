import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFilterLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M23 1H1L10 10V23L14 20.69V10L23 1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgFilterLarge.displayName = 'SvgFilterLarge';
const FilterLarge = withForgeIconProps(SvgFilterLarge, {
  name: 'Filter',
  usage: 'Use to indicate that data is not filtered, or to filter data',
  related:
    'filled filter, inactive filter, active filter, funnel, sort, refine, search, criteria',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default FilterLarge;
