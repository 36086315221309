import { useContext, useRef, useState, ReactElement, ReactNode } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';

import './profile.scss';
import { ProfileContext } from '../../contexts';
import { Button, Heading, Popover, useFocusLost } from '@athena/forge';
import UserAvatar from '../user-avatar';

type BaseComponentProps = {
  children?: ReactNode;
};

type FlexDirectionRowProps = BaseComponentProps;
type RightColumnProps = BaseComponentProps;
type ProfileDetailsProps = BaseComponentProps;

const Profile = (): ReactElement => {
  const { profileData } = useContext(ProfileContext);
  const { instance } = useMsal();
  const avatarRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useFocusLost(() => {
    setIsOpen(false);
  }, [containerRef]);

  const handleLogout = (): void => {
    instance.logoutPopup();
  };

  const FlexDirectionRow = ({ children }: FlexDirectionRowProps): ReactElement => (
    <div className="forge-guide__profile-popover-container">{children}</div>
  );

  const RightColumn = ({ children }: RightColumnProps): ReactElement => (
    <div className="forge-guide__profile-popover-right-column">{children}</div>
  );
  const ProfileDetails = ({ children }: ProfileDetailsProps): ReactElement => (
    <div className="forge-guide__profile-popover-details">{children}</div>
  );

  return (
    <AuthenticatedTemplate>
      <div className="forge-guide__profile" ref={containerRef}>
        {profileData && (
          <>
            <UserAvatar ref={avatarRef} userDetails={profileData} size="small" onClick={() => setIsOpen(!isOpen)} />
            <Popover isOpen={isOpen} targetRef={avatarRef} placement="bottom" onClose={() => setIsOpen(false)}>
              <Heading headingTag="h4" variant="subsection" text="athenahealth" />
              <FlexDirectionRow>
                <UserAvatar userDetails={profileData} size="large" />
                <RightColumn>
                  <ProfileDetails>
                    <Heading
                      className="forge-guide__profile-popover-name"
                      headingTag="h5"
                      variant="subsection"
                      text={profileData?.displayName}
                    />
                    <p>{profileData?.mail}</p>
                  </ProfileDetails>
                  <Button text="Log Out" onClick={handleLogout} />
                </RightColumn>
              </FlexDirectionRow>
            </Popover>
          </>
        )}
      </div>
    </AuthenticatedTemplate>
  );
};

export default Profile;
