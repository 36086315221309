import RootWrapper from './src/root-wrapper';

export const wrapRootElement = RootWrapper;

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash.length > 0) {
    return false;
  } else {
    return true;
  }
};

/** Given a URI fragment, determines the scroll position of the DOM element with the matching id.
 */
const getTargetOffset = (hash) => {
  const id = window.decodeURI(hash.replace(`#`, ``));
  if (id !== ``) {
    const element = document.getElementById(id);
    if (element) {
      const computedStyles = window.getComputedStyle(element);
      const scrollMarginTop =
        computedStyles.getPropertyValue(`scroll-margin-top`) ||
        computedStyles.getPropertyValue(`scroll-snap-margin-top`) ||
        `0px`;
      return element.getBoundingClientRect().top - parseInt(scrollMarginTop, 10);
    }
  }
  return null;
};

/** Override Gatsby's default scrolling behavior if an HTML fragment is detected in the URI.
 *
 * The documentation for `shouldUpdateScroll` implies that we can just return the ID of the element we want to scroll to,
 * but gatsby-react-router-scroll overrides that behavior. It stores the last scroll position in sessionStorage.
 * This gives inconsistent behavior during the initial render of a page. Instead, we have to disable automatic scroll
 * updates and calculate the scroll offset ourselves within shouldUpdateScroll().
 */
export const onInitialClientRender = () => {
  /** Scroll to an HTML DOM element if its ID is given in the URI fragment. */
  if (window.location.hash.length > 0) {
    const offset = getTargetOffset(window.location.hash);
    if (offset !== null) {
      window.scrollBy({ top: offset });
    }
  } else {
    window.scrollTo({ top: 0 });
  }
};

export const onClientEntry = () => {};
