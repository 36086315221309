import {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
  ReactElement,
  KeyboardEventHandler,
  SyntheticEvent,
  WeakValidationMap,
  ReactNode,
  HTMLAttributes,
} from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import TreeNode, { TreeNodeProps } from '../TreeNode';

const classes = forgeClassHelper({ name: 'tree' });

export interface DataNode extends Omit<TreeNodeProps, 'children'> {
  children?: DataNode[];
  item?: DataNode;
  parent?: DataNode;
}
export interface TreeProps extends Omit<HTMLAttributes<HTMLElement>, 'defaultChecked'> {
  /** Allows tree to have checkboxes */
  checkable?: boolean;
  /**
   * Checked node ids ([Controlled](https://reactjs.org/docs/forms.html#controlled-components))
   * * When a node is checked, all descendant nodes will be represented checked and vice versa when unchecked.
   * * If all descendants of a parent node are checked and uniform, the parent node will also be represented as checked.
   * * A disabled node will not reflect any cascading checkboxes. It may also prevent a parent node value from being represented
   * as checked even if the node id has been explicitly passed into the `checked` prop
   */
  checked?: string[];
  /** Custom class added to the outer <ul> container */
  className?: string;
  /** Data to be displayed as an array of DataNodes, an object abstraction of TreeNode props.
   * * The object properties are passed directly and correspond to the underlying TreeNode props, with the exception of `children`.
   * `children` takes an array of DataNodes instead of actual TreeNode components.
   */
  data: DataNode[];
  /** Checked node ids ([Uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)) */
  defaultChecked?: string[];
  /** Expanded node ids ([Uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)) */
  defaultExpanded?: string[];
  /** Initial selected nodes ([Uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)) */
  defaultSelected?: string;
  /** Show dividers between top level nodes */
  dividers?: boolean;
  /** Expanded node ids ([Controlled](https://reactjs.org/docs/forms.html#controlled-components)) */
  expanded?: string[];
  /** Callback fired when tree items are checked/unchecked */
  onCheck?: (event: SyntheticEvent<HTMLLIElement | HTMLUListElement | HTMLInputElement>, checkedIds: string[]) => void;
  /** Callback fired when node's collapse animation completes */
  onNodeCollapsed?: (id: string) => void;
  /** Callback fired when node's expand animation completes */
  onNodeExpanded?: (id: string) => void;
  /** Callback fired when tree items are selected/unselected */
  onNodeSelect?: (event: SyntheticEvent<HTMLLIElement | HTMLUListElement | HTMLLabelElement>, id: string) => void;
  /** Callback fired when tree items are expanded/collapsed */
  onNodeToggle?: (
    event: SyntheticEvent<HTMLDivElement | HTMLUListElement | HTMLLabelElement | SVGSVGElement>,
    ids: string[]
  ) => void;
  /** Callback for row click with event and node id */
  onRowClick?: (event: SyntheticEvent<HTMLLIElement>, id: string) => void;
  /** Custom render function for all TreeNode titles
   * (title) => ReactNode
   * The parameter `title` is what ever is passed to the `title` prop of the TreeNode.
   */
  renderTitle?: (title: ReactNode) => ReactNode;
  /** Selected node id ([Controlled](https://reactjs.org/docs/forms.html#controlled-components)) */
  selected?: string;
}

// Helper to return a new array with a specific element removed
function remove<T>(array: T[], element: T): T[] {
  const index = array.indexOf(element);
  return array.slice(0, index).concat(array.slice(index + 1));
}

function hasSelection(node: DataNode, selectedId: string): boolean {
  if (node.id === selectedId) return true;
  if (!node.children) return false;
  if (node.disabled) return false;

  return node.children.some((childNode) => hasSelection(childNode, selectedId));
}

function getDefaultExpanded(data: DataNode[], defaultExpanded: string[], selectedId: string): string[] {
  let selectionFound = false;
  data.forEach((node) => {
    if (node.disabled) return;
    if (selectionFound) return;
    selectionFound = hasSelection(node, selectedId);
    if (selectionFound) {
      defaultExpanded.push(node.id);
    }
    if (node.children) {
      getDefaultExpanded(node.children, defaultExpanded, selectedId);
    }
  });

  return defaultExpanded;
}

function getNodeById(id: string, data: DataNode[], parent?: DataNode): DataNode | undefined {
  const dl = data.length;
  for (let i = 0; i < dl; i++) {
    const item = data[i];
    if (item.id === id) {
      return { ...item, parent };
    }
    if (item.children) {
      const recursiveResult = getNodeById(id, item.children, item);
      if (recursiveResult && recursiveResult.id === id) {
        return { ...recursiveResult, item };
      }
    }
  }
  return undefined;
}

function getAllChildIds(node?: DataNode): string[] {
  if (node) {
    const { children, id, disabled } = node;
    if (disabled) return [];
    if (!children) {
      return [id];
    } else {
      const allChildIds = children.reduce((accumulator, childNode) => {
        const ids = getAllChildIds(childNode);
        return [...accumulator, ...ids];
      }, [] as Array<string>);
      return [id, ...allChildIds];
    }
  } else {
    return [];
  }
}

// Check if all nodes are either true or false
function areChildrenUnified(
  nodes: DataNode[],
  checkedIds: string[]
): { isUnified: boolean; unifiedState: boolean | undefined } {
  let isUnified = true;
  let unifiedState: boolean | undefined;

  nodes.forEach((node) => {
    if (!isUnified) return;

    const nodeChecked = checkedIds.includes(node.id);
    if (typeof unifiedState === 'undefined') {
      unifiedState = nodeChecked;
    } else {
      isUnified = unifiedState.toString() === nodeChecked.toString();
    }

    if (node.children && node.children.length > 0) {
      const childResults = areChildrenUnified(node.children, checkedIds);
      unifiedState = unifiedState && childResults.unifiedState;
      isUnified = isUnified && childResults.isUnified;
    }
  });

  return { isUnified, unifiedState };
}

// This is a final pass over the tree to rectify situations where parent nodes should be checked/unchecked after child node changes
// To simplify code/readability, this function will mutate the checkedIds array
function rectifyCheckedIds(nodes: DataNode[], checkedIds: string[]): void {
  nodes.forEach((node) => {
    if (!node.children || node.children.length <= 0) return;
    rectifyCheckedIds(node.children, checkedIds);

    const { isUnified, unifiedState } = areChildrenUnified(node.children, checkedIds);
    const nodeChecked = checkedIds.includes(node.id);
    if (!isUnified) {
      // If indeterminate, we will always remove the checked state
      if (nodeChecked) {
        checkedIds.splice(checkedIds.indexOf(node.id), 1);
      }
    } else {
      if (nodeChecked.toString() === unifiedState?.toString()) return;
      if (unifiedState && !nodeChecked) {
        checkedIds.push(node.id);
      } else {
        checkedIds.splice(checkedIds.indexOf(node.id), 1);
      }
    }
  });
}

function getNodeSiblingIdsById(id: string, data: DataNode[]): string[] {
  const node = getNodeById(id, data);
  let siblings: DataNode[] = [];
  if (node) {
    if (!node.parent) {
      siblings = data;
    } else {
      siblings = node.parent.children || [];
    }
  }
  return siblings.map((node) => node.id);
}

interface TreeNodesProps {
  data: DataNode[];
  dividers?: boolean;
  level?: number;
  checkable: TreeNodeProps['checkable'];
  isExpanded: (id: string) => boolean;
  isChecked: (id: string) => boolean;
  focused?: string;
  isIndeterminate: (node: DataNode) => boolean;
  isSelected: (id: string) => boolean;
  handleNodeCheck: (event: SyntheticEvent<HTMLInputElement | HTMLUListElement, Event>, node: DataNode) => void;
  handleNodeSelect: TreeNodeProps['onSelect'];
  handleNodeToggle: TreeNodeProps['onToggle'];
  onNodeExpanded: TreeNodeProps['onExpanded'];
  onNodeCollapsed: TreeNodeProps['onCollapsed'];
  handleRowClick: TreeNodeProps['onRowClick'];
  renderTitle: TreeNodeProps['renderTitle'];
}
function TreeNodes(props: TreeNodesProps): ReactElement {
  const {
    data,
    dividers,
    level = 1,
    checkable,
    isExpanded,
    isChecked,
    focused,
    isIndeterminate,
    isSelected,
    handleNodeCheck,
    handleNodeSelect,
    handleNodeToggle,
    onNodeExpanded,
    onNodeCollapsed,
    handleRowClick,
    renderTitle,
  } = props;
  return (
    <>
      {data.map((node, index) => {
        return (
          <TreeNode
            key={node.id}
            checkable={checkable}
            dividers={dividers}
            expanded={isExpanded(node.id)}
            checked={isChecked(node.id)}
            focused={focused === node.id}
            indeterminate={isIndeterminate(node)}
            selected={isSelected(node.id)}
            onCheck={(event: React.ChangeEvent<HTMLInputElement>) => handleNodeCheck(event, node)}
            onSelect={handleNodeSelect}
            onToggle={handleNodeToggle}
            onExpanded={onNodeExpanded}
            onCollapsed={onNodeCollapsed}
            onRowClick={handleRowClick}
            renderTitle={renderTitle}
            aria-posinset={index + 1}
            aria-setsize={node.children ? node.children.length : 0}
            aria-level={level}
            {...node}
          >
            {node.children && <TreeNodes {...props} data={node.children} level={level + 1} />}
          </TreeNode>
        );
      })}
    </>
  );
}

const Tree = ({
  className,
  checkable,
  data,
  defaultChecked,
  defaultExpanded,
  defaultSelected,
  checked,
  selected,
  expanded,
  onCheck,
  onNodeSelect,
  onNodeToggle,
  onNodeExpanded,
  onNodeCollapsed,
  onRowClick,
  renderTitle,
  dividers,
  ...rest
}: TreeProps): ReactElement => {
  // Implements WAI ARIA Tree default focus behavior: https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-22
  // * If none of the nodes are selected before the tree receives focus, focus is set on the first node.
  // * If a node is selected before the tree receives focus, focus is set on the selected node.
  const _firstFocused = useMemo(() => {
    if (!data || data.length === 0) return undefined;
    if (defaultSelected) return defaultSelected;

    return data[0].id;
  }, [data, defaultSelected]);

  const _defaultExpanded = useMemo(() => {
    if (!data || data.length === 0) return [];
    if (defaultSelected) {
      // Auto-expand to any default selected
      return getDefaultExpanded(data, defaultExpanded || [], defaultSelected);
    } else {
      return defaultExpanded || [];
    }
  }, [data, defaultSelected, defaultExpanded]);

  const normalizeCheckedIds = useCallback(
    (checkedIds?: string[]): string[] => {
      if (!checkedIds || checkedIds.length === 0) return [];
      const allCheckedIds = checkedIds.reduce((accumulator, nodeId) => {
        const node = getNodeById(nodeId, data);
        const allChildIds = getAllChildIds(node);
        const idsToAdd = allChildIds.filter((childId) => !checkedIds.includes(childId));
        return [...accumulator, ...idsToAdd];
      }, checkedIds);
      rectifyCheckedIds(data, allCheckedIds);

      return allCheckedIds;
    },
    [data]
  );

  const _defaultChecked = useMemo(() => normalizeCheckedIds(defaultChecked), [defaultChecked, normalizeCheckedIds]);
  const _checked = useMemo(() => normalizeCheckedIds(checked), [normalizeCheckedIds, checked]);

  const [selectedId, setSelectedId] = useState(defaultSelected);
  const [expandedIds, setExpandedIds] = useState(_defaultExpanded);
  const [uncontrolledCheckedIds, setCheckedIds] = useState(_defaultChecked);

  // Determines if state is used in a controlled/uncontrolled manner
  const checkedIds = checked !== undefined ? _checked : uncontrolledCheckedIds;

  const [focused, setFocused] = useState(_firstFocused);
  const keyboardOrder = useRef<string[]>();

  const isExpanded = useCallback(
    (id: string): boolean => {
      if (expanded && expanded.includes(id)) {
        return true;
      } else if (expandedIds.includes(id)) {
        return true;
      } else {
        return false;
      }
    },
    [expanded, expandedIds]
  );

  useEffect(() => {
    function traverseVisible(data: DataNode[], arr: string[] = []): string[] {
      data.forEach((item) => {
        if (!item.disabled) {
          arr.push(item.id);
          if (item.children && isExpanded(item.id)) {
            traverseVisible(item.children, arr);
          }
        }
      });
      return arr;
    }
    const currentOrder = traverseVisible(data);
    keyboardOrder.current = currentOrder;
  }, [expanded, expandedIds, data, keyboardOrder, isExpanded]);

  // given an id, toggles the id in and out of expanded array
  const handleNodeToggle = useCallback(
    (event: SyntheticEvent<HTMLLabelElement | HTMLUListElement | SVGSVGElement>, id: string): void => {
      if (!expanded) {
        event.persist();
        if (expandedIds.includes(id)) {
          setExpandedIds((prevExpanded) => {
            const newState = remove(prevExpanded, id);
            onNodeToggle && onNodeToggle(event, newState);
            return newState;
          });
        } else {
          setExpandedIds((prevExpanded) => {
            const newState = [...prevExpanded, id];
            onNodeToggle && onNodeToggle(event, newState);
            return newState;
          });
        }
      }
      if (onNodeToggle && expanded) {
        let updatedExpanded;
        if (expanded.includes(id)) {
          updatedExpanded = remove(expanded, id);
        } else {
          updatedExpanded = [...expanded, id];
        }
        onNodeToggle(event, updatedExpanded);
      }
      setFocused(id);
    },
    [expanded, expandedIds, onNodeToggle]
  );

  const handleNodeSelect = useCallback(
    (event: SyntheticEvent<HTMLLabelElement | HTMLUListElement>, id: string): void => {
      if (!selected) {
        setSelectedId(id);
      }
      onNodeSelect && onNodeSelect(event, id);
      setFocused(id);
    },
    [onNodeSelect, selected]
  );

  const areAllActiveNodesChecked = useCallback(
    (node: DataNode): boolean => {
      if (node.disabled) return true;
      if (!node.children || node.children.length <= 0) return checkedIds.includes(node.id);
      return node.children.every((childNode) => areAllActiveNodesChecked(childNode));
    },
    [checkedIds]
  );

  const handleCheckedStateChanges = useCallback(
    (event: SyntheticEvent<HTMLInputElement | HTMLUListElement, Event>, checkedIds: string[]): void => {
      rectifyCheckedIds(data, checkedIds);
      onCheck && onCheck(event, checkedIds);
      setCheckedIds(checkedIds);
    },
    [data, onCheck]
  );

  const handleNodeCheck = useCallback(
    (event: SyntheticEvent<HTMLInputElement | HTMLUListElement, Event>, node: DataNode): void => {
      const { id } = node;
      // A checkbox can be partially/unchecked but we still need treat it as if it's true and uncheck all children
      const activeNodesChecked = areAllActiveNodesChecked(node);
      if (checkedIds.includes(id) || activeNodesChecked) {
        const idsToRemove = getAllChildIds(node);
        const newCheckedState = checkedIds.filter((id) => idsToRemove.indexOf(id) < 0);
        handleCheckedStateChanges(event, newCheckedState);
      } else {
        const allChildIds = getAllChildIds(node);
        const idsToAdd = allChildIds.filter((childId) => !checkedIds.includes(childId));
        const newCheckedState = [...checkedIds, ...idsToAdd];
        handleCheckedStateChanges(event, newCheckedState);
      }
    },
    [areAllActiveNodesChecked, checkedIds, handleCheckedStateChanges]
  );

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLUListElement>>(
    (event) => {
      const { key } = event;
      let nextFocus: string | undefined;
      const currentOrder = keyboardOrder.current;
      if (focused) {
        // implements WAI ARIA Tree keyboard interaction spec
        // https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-22
        switch (key) {
          case 'Enter': {
            const node = getNodeById(focused, data);
            if (node) {
              if (node.children) {
                handleNodeToggle(event, node.id);
              }
              handleNodeSelect(event, node.id);
            }
            break;
          }
          case ' ': {
            const node = getNodeById(focused, data);
            if (node) {
              if (checkable) {
                handleNodeCheck(event, node);
              } else {
                if (node.children) {
                  handleNodeToggle(event, node.id);
                }
                handleNodeSelect(event, node.id);
              }
            }
            break;
          }
          case 'ArrowDown':
            // focus next item
            nextFocus = currentOrder ? currentOrder[currentOrder.indexOf(focused) + 1] : undefined;
            break;
          case 'ArrowUp':
            // focus previous item
            nextFocus = currentOrder ? currentOrder[currentOrder.indexOf(focused) - 1] : undefined;
            break;
          case 'ArrowRight': {
            const node = getNodeById(focused, data);
            if (node) {
              if (node.children) {
                if (isExpanded(node.id)) {
                  // When focus is on a open node, moves focus to the first child node.
                  nextFocus = currentOrder ? currentOrder[currentOrder.indexOf(focused) + 1] : undefined;
                } else {
                  // When focus is on a closed node, opens the node; focus does not move.
                  handleNodeToggle(event, node.id);
                }
              }
            }
            break;
          }
          case 'ArrowLeft': {
            const node = getNodeById(focused, data);
            if (node) {
              if (isExpanded(node.id)) {
                // When focus is on an open node, closes the node.
                handleNodeToggle(event, node.id);
              } else if (node.parent) {
                // When focus is on a child node that is also either an end node or a closed node, moves focus to its parent node.
                nextFocus = currentOrder ? currentOrder[currentOrder.indexOf(node.parent.id)] : undefined;
              }
            }
            break;
          }
          case 'Home':
            // Moves focus to the first node in the tree without opening or closing a node.
            nextFocus = currentOrder ? currentOrder[0] : undefined;
            break;
          case 'End':
            // Moves focus to the last node in the tree that is focusable without opening a node.
            nextFocus = currentOrder ? currentOrder[currentOrder.length - 1] : undefined;
            break;
          case '*':
            // Expands all closed sibling nodes that are at the same level as the focused node.
            setExpandedIds((previousExpanded) =>
              previousExpanded.concat(getNodeSiblingIdsById(focused, data).filter((nodeId) => !isExpanded(nodeId)))
            );
            break;
          default:
            // A character key has been pressed, as opposed to a modifier key like "Shift"
            if (/^[a-zA-Z]$/.test(key) && currentOrder) {
              /**
               * Focus moves to the next node with a name that starts with the typed character.
               * Search wraps to first node if a matching name is not found among the nodes that follow the focused node.
               * Search ignores nodes that are descendants of closed nodes.
               * This does not work with nodes with non-string titles.
               */
              const startIndex = currentOrder.indexOf(focused) + 1;
              for (let i = 0; i <= currentOrder.length; i += 1) {
                const index = (startIndex + i) % currentOrder.length;
                const node = getNodeById(currentOrder[index], data);
                if (typeof node?.title === 'string' && node.title.substring(0, 1).toLowerCase() === key.toLowerCase()) {
                  nextFocus = node.id;
                  break;
                }
              }
            }
            break;
        }
        setFocused(nextFocus ? nextFocus : focused);
      }
    },
    [checkable, data, focused, handleNodeCheck, handleNodeSelect, handleNodeToggle, isExpanded]
  );

  function isSelected(id: string): boolean {
    if (selected === id || selectedId === id) {
      return true;
    } else {
      return false;
    }
  }

  function isChecked(id: string): boolean {
    return checkedIds.includes(id);
  }

  function isIndeterminate(node: DataNode): boolean {
    if (!node.children) {
      return false;
    } else {
      return node.children.some((childNode) => hasMismatch(childNode, node));
    }
  }

  function hasMismatch(node: DataNode, parentNode: DataNode): boolean {
    if (!node.children) {
      const parentNodeChecked = checkedIds.includes(parentNode.id);
      const nodeChecked = checkedIds.includes(node.id);
      return parentNodeChecked.toString() !== nodeChecked.toString();
    } else {
      return node.children.some((childNode) => hasMismatch(childNode, parentNode));
    }
  }

  const handleRowClick: TreeNodeProps['onRowClick'] = (event, id) => {
    onRowClick && onRowClick(event, id);
    setFocused(id);
  };

  return (
    <ul role="tree" {...classes({ extra: className })} {...rest} onKeyDown={handleKeyDown}>
      <TreeNodes
        data={data}
        dividers={dividers}
        checkable={checkable}
        isExpanded={isExpanded}
        isChecked={isChecked}
        focused={focused}
        isIndeterminate={isIndeterminate}
        isSelected={isSelected}
        handleNodeCheck={handleNodeCheck}
        handleNodeSelect={handleNodeSelect}
        handleNodeToggle={handleNodeToggle}
        onNodeExpanded={onNodeExpanded}
        onNodeCollapsed={onNodeCollapsed}
        handleRowClick={handleRowClick}
        renderTitle={renderTitle}
      />
    </ul>
  );
};

const treePropTypes: WeakValidationMap<TreeProps> = {
  checkable: PropTypes.bool,
  checked: PropTypes.arrayOf(PropTypes.string.isRequired),
  className: PropTypes.string,
  defaultChecked: PropTypes.arrayOf(PropTypes.string.isRequired),
  defaultExpanded: PropTypes.arrayOf(PropTypes.string.isRequired),
  defaultSelected: PropTypes.string,
  dividers: PropTypes.bool,
  expanded: PropTypes.arrayOf(PropTypes.string.isRequired),
  onCheck: PropTypes.func,
  onNodeCollapsed: PropTypes.func,
  onNodeExpanded: PropTypes.func,
  onNodeSelect: PropTypes.func,
  onNodeToggle: PropTypes.func,
  onRowClick: PropTypes.func,
  renderTitle: PropTypes.func,
  selected: PropTypes.string,
};
Tree.propTypes = treePropTypes;

export default Tree;
