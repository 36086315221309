import FilterSmall from '@athena/forge-icons/dist/FilterSmall';

import rawContents from "!!raw-loader!./PushDrawerHidden.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { FilterSmall };

function PushDrawerHidden():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Push Drawer Hidden" />;
}

export default PushDrawerHidden;
