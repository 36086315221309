import {
  ReactElement,
  KeyboardEvent,
  MouseEvent,
  useState,
  useCallback,
  WeakValidationMap,
  Ref,
  HTMLAttributes,
} from 'react';
import PropTypes from 'prop-types';
import { themeContextPropTypes, ThemeContext, useTheme } from './ThemeContext';
import KeyboardUserContext from './KeyboardUserContext';
import ModalManagerContext from './ModalManagerContext';
import ModalManager from '@restart/ui/ModalManager';
import { forgeClassHelper } from '../utils/classes';
import { contextDefaultValues, Theme } from './context';
import PortalProvider from '../PortalProvider';
import forwardRefToProps from '../utils/forwardRefToProps';

const classes = forgeClassHelper({ name: 'root', isPortal: true });

export interface RootProps extends HTMLAttributes<HTMLDivElement> {
  /** Adds a class to the root element of the component */
  className?: string;
  /** A ref to the div element that wraps Root */
  ref?: Ref<HTMLDivElement>;
  /**
   * Theme variables for configuring an instance of Forge. Currently includes:
   *   - rootClasses - Array of classes to add to root DOM elements (in addition to `fe_f_all`)
   */
  theme?: Theme;
}

export interface RootComponentProps extends RootProps {
  /** A ref to the div element that wraps Root */
  forwardedRef?: Ref<HTMLDivElement>;
}
function RootDiv({
  theme: upstreamTheme,
  className,
  forwardedRef,
  children,
  ...passthroughProps
}: RootComponentProps): ReactElement {
  const theme = useTheme(upstreamTheme);
  return (
    <div
      {...passthroughProps}
      {...classes({
        theme,
        extra: className,
      })}
      ref={forwardedRef}
    >
      {children}
    </div>
  );
}

function Root({
  theme,
  onKeyDown: passedKeyDown,
  onMouseMove: passedMouseMove,
  forwardedRef,
  children,
  ...passedProps
}: RootComponentProps): ReactElement {
  const [isKeyboardUser, setIsKeyboardUser] = useState(true);
  const [modalManager] = useState(new ModalManager());

  const onMouseMove = useCallback(
    (e: MouseEvent<HTMLDivElement>): void => {
      if (isKeyboardUser) {
        setIsKeyboardUser(false);
      }
      if (passedMouseMove) {
        passedMouseMove(e);
      }
    },
    [isKeyboardUser, passedMouseMove]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>): void => {
      if (e.key === 'Tab' && !isKeyboardUser) {
        setIsKeyboardUser(true);
      }
      if (passedKeyDown) {
        passedKeyDown(e);
      }
    },
    [isKeyboardUser, passedKeyDown]
  );

  return (
    <ThemeContext.Provider value={theme || contextDefaultValues.theme}>
      <ModalManagerContext.Provider value={modalManager}>
        <KeyboardUserContext.Provider value={isKeyboardUser}>
          <PortalProvider>
            <RootDiv {...passedProps} forwardedRef={forwardedRef} onMouseMove={onMouseMove} onKeyDown={onKeyDown}>
              {children}
            </RootDiv>
          </PortalProvider>
        </KeyboardUserContext.Provider>
      </ModalManagerContext.Provider>
    </ThemeContext.Provider>
  );
}

Root.displayName = 'Root';

const rootPropTypes: WeakValidationMap<RootComponentProps & { '...rest': unknown }> = {
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /**
   * Theme variables for configuring an instance of Forge. Currently includes:
   *   - rootClasses - Array of classes to add to root DOM elements (in addition to `fe_f_all`)
   */
  theme: themeContextPropTypes,
  /** All other props are passed to the root element. */
  '...rest': PropTypes.any,
};
Root.propTypes = rootPropTypes;

export default forwardRefToProps(Root);
