import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLastPageLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.99951 23L14.9995 12L3.99951 1"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0005 1L18.9995 23"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgLastPageLarge.displayName = 'SvgLastPageLarge';
const LastPageLarge = withForgeIconProps(SvgLastPageLarge, {
  name: 'LastPage',
  usage: 'Use to navigate to the last page in table pagination',
  related: 'first page, pagination',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default LastPageLarge;
