import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDragSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 16C5.82843 16 6.5 15.3284 6.5 14.5C6.5 13.6716 5.82843 13 5 13C4.17157 13 3.5 13.6716 3.5 14.5C3.5 15.3284 4.17157 16 5 16Z"
      fill={props.color}
    />
    <path
      d="M5 9.5C5.82843 9.5 6.5 8.82843 6.5 8C6.5 7.17157 5.82843 6.5 5 6.5C4.17157 6.5 3.5 7.17157 3.5 8C3.5 8.82843 4.17157 9.5 5 9.5Z"
      fill={props.color}
    />
    <path
      d="M5 3C5.82843 3 6.5 2.32843 6.5 1.5C6.5 0.671573 5.82843 0 5 0C4.17157 0 3.5 0.671573 3.5 1.5C3.5 2.32843 4.17157 3 5 3Z"
      fill={props.color}
    />
    <path
      d="M11 16C11.8284 16 12.5 15.3284 12.5 14.5C12.5 13.6716 11.8284 13 11 13C10.1716 13 9.5 13.6716 9.5 14.5C9.5 15.3284 10.1716 16 11 16Z"
      fill={props.color}
    />
    <path
      d="M11 9.5C11.8284 9.5 12.5 8.82843 12.5 8C12.5 7.17157 11.8284 6.5 11 6.5C10.1716 6.5 9.5 7.17157 9.5 8C9.5 8.82843 10.1716 9.5 11 9.5Z"
      fill={props.color}
    />
    <path
      d="M11 3C11.8284 3 12.5 2.32843 12.5 1.5C12.5 0.671573 11.8284 0 11 0C10.1716 0 9.5 0.671573 9.5 1.5C9.5 2.32843 10.1716 3 11 3Z"
      fill={props.color}
    />
  </svg>
);
SvgDragSmall.displayName = 'SvgDragSmall';
const DragSmall = withForgeIconProps(SvgDragSmall, {
  name: 'Drag',
  usage: 'Use to indicate an item that can be moved by dragging',
  related: 'move, reorder, list',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default DragSmall;
