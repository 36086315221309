import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgItalicLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M7.5 24L12.416 0H16.5L11.584 24H7.5Z" fill={props.color} />
    <path d="M21.5 2H6.5V0H21.5V2Z" fill={props.color} />
    <path d="M17.5 24H2.5V22H17.5V24Z" fill={props.color} />
  </svg>
);
SvgItalicLarge.displayName = 'SvgItalicLarge';
const ItalicLarge = withForgeIconProps(SvgItalicLarge, {
  name: 'Italic',
  usage:
    'Use in a ComboButton or SegmentedButton to add or remove italic text formatting',
  related: 'bold, font, underline, text, edit, text editor',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ItalicLarge;
