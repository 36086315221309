import rawContents from "!!raw-loader!./HiddenOnPrint.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function HiddenOnPrint():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Hidden On Print" />;
}

export default HiddenOnPrint;
