import PropTypes from 'prop-types';
import React, { FocusEventHandler, useEffect, WeakValidationMap } from 'react';

import Input from '../Input';
import { FormContext, FormContextData } from '../Form/FormContext';

import { AttentionBanners, AttentionBannersProps } from './NumericRangeInputBannerAccessory';
import useAppLogic from './NumericRangeInputAppLogic';
import { DescriptionLabel, NullValueCheckbox, RangeLabel, RangeLabelProps } from './NumericRangeInputComponents';
import { NumericRangeInputComponentProps } from './NumericRangeInputTypes';
import { classes, fixEventText } from './NumericRangeInputUtils';
import { optionalNumberValidator, optionalBoolValidator, optionalStringValidator } from '../utils/betterPropTypes';

/** Component for selecting a numeric range. */
export const NumericRangeInput = ({
  allowNullValuesCheckBox = false,
  error,
  className,
  disabled = false,
  errorAlwaysBelow = false,
  id,
  isCurrency = false,
  isInteger = false,
  labelAlwaysAbove = false,
  highestLowestValueForMin = {},
  minMaxDisabledValues = { max: false, min: false },
  minMaxRequiredValues = { max: false, min: false },
  highestLowestValueForMax = {},
  onBlurAllValues,
  onChangeAllValues,
  onFirstValueBlur,
  onSecondValueBlur,
  placeholderValues = { max: 'Max', min: 'Min' },
  rangeDescription = '',
  rangeLabel = '',
  refFirstValue,
  refSecondValue,
  required = false,
  showNullAmountsCheckboxDescription = 'Show items with no amount',
  showNullQuantitiesCheckboxDescription = 'Show items with no quantity',
  standalone = false,
  suppressVerticalSpacing = false,
  suppressWarningBanner = false,
  validateMinValue,
  validateMaxValue,
  values = {},
}: NumericRangeInputComponentProps): JSX.Element => {
  const {
    bannerCount,
    disabledFirstValue,
    disabledSecondValue,
    firstValue,
    firstValueCustomValidationMsg,
    firstValueErrorMsg,
    handleFirstValueBlur,
    handleFirstValueChange,
    handleFirstValuePaste,
    handleSecondValueBlur,
    handleSecondValueChange,
    handleSecondValuePaste,
    handleShowNoNumbersChange,
    hasFirstValueCustomValidationMsg,
    hasSecondValueCustomValidationMsg,
    isFirstValueAfterMaxValue,
    isFirstValueAfterSecondValue,
    isFirstValueBeforeMinValue,
    isSecondValueAfterMaxValue,
    isSecondValueBeforeMinValue,
    isValidFirstValue,
    isValidSecondValue,
    maxFirstValue,
    maxSecondValue,
    minFirstValue,
    minSecondValue,
    passedFirstValue,
    passedSecondValue,
    passedShowNoNumbersValue,
    placeholderFirstValue,
    placeholderSecondValue,
    requiredFirstValue,
    requiredSecondValue,
    secondValue,
    secondValueCustomValidationMsg,
    secondValueErrorMsg,
    setBannerCount,
    setFirstValue,
    setFirstValueErrorMsg,
    setSecondValue,
    setSecondValueErrorMsg,
    setShowNoNumbers,
    showNoNumbers,
    showRequiredBannerFirstValue,
    showRequiredBannerSecondValue,
  } = useAppLogic({
    id,
    isCurrency,
    isInteger,
    highestLowestValueForMin,
    minMaxDisabledValues,
    minMaxRequiredValues,
    highestLowestValueForMax,
    onBlurAllValues,
    onChangeAllValues,
    placeholderValues,
    validateMinValue,
    validateMaxValue,
    values,
  });

  /** Allow user supplied onBlur handler for blur on first value field
   * onBlurAllValues won't get triggered when user tab between fields
   */
  const handleFirstBlur: FocusEventHandler<HTMLInputElement> = (evt) => {
    handleFirstValueBlur(evt);
    if (onFirstValueBlur) onFirstValueBlur(evt);
  };
  /** Allow user supplied onBlur handler for blur on second value field */
  const handleSecondBlur: FocusEventHandler<HTMLInputElement> = (evt) => {
    handleSecondValueBlur(evt);
    if (onSecondValueBlur) onSecondValueBlur(evt);
  };

  /** Monitor for any external changes to the passed values. */
  useEffect(() => {
    if (passedFirstValue === undefined) {
      setFirstValue(undefined);
    } else {
      const fixedText = fixEventText(isCurrency, isInteger, passedFirstValue);

      setFirstValue(isCurrency ? parseFloat(fixedText).toFixed(2) : parseFloat(fixedText).toString().replace(/,/g, ''));
    }
  }, [isCurrency, isInteger, passedFirstValue, setFirstValue]);
  useEffect(() => {
    if (passedSecondValue === undefined) {
      setSecondValue(undefined);
    } else {
      const fixedText = fixEventText(isCurrency, isInteger, passedSecondValue);

      setSecondValue(
        isCurrency ? parseFloat(fixedText).toFixed(2) : parseFloat(fixedText).toString().replace(/,/g, '')
      );
    }
  }, [isCurrency, isInteger, passedSecondValue, setSecondValue]);
  useEffect(() => {
    setShowNoNumbers(passedShowNoNumbersValue);
  }, [passedFirstValue, passedSecondValue, passedShowNoNumbersValue, setShowNoNumbers]);

  /** These useEffect() cause the min/max input edit fields to change to an error color. */
  useEffect(() => {
    // The only important thing about the text is whether it is zero length or not per Input.
    setFirstValueErrorMsg(
      !isValidFirstValue ||
        hasFirstValueCustomValidationMsg ||
        showRequiredBannerFirstValue ||
        isFirstValueAfterMaxValue ||
        isFirstValueBeforeMinValue
        ? 'error'
        : ''
    );
  }, [
    hasFirstValueCustomValidationMsg,
    isFirstValueAfterMaxValue,
    isFirstValueBeforeMinValue,
    isValidFirstValue,
    setFirstValueErrorMsg,
    showRequiredBannerFirstValue,
  ]);
  useEffect(() => {
    // The only important thing about the text is whether it is zero length or not per Input.
    setSecondValueErrorMsg(
      !isValidSecondValue ||
        hasSecondValueCustomValidationMsg ||
        showRequiredBannerSecondValue ||
        isFirstValueAfterSecondValue ||
        isSecondValueAfterMaxValue ||
        isSecondValueBeforeMinValue
        ? 'error'
        : ''
    );
  }, [
    firstValue,
    hasSecondValueCustomValidationMsg,
    isFirstValueAfterSecondValue,
    isSecondValueAfterMaxValue,
    isSecondValueBeforeMinValue,
    isValidSecondValue,
    setSecondValueErrorMsg,
    showRequiredBannerSecondValue,
  ]);

  let count = 0;

  if (isFirstValueAfterSecondValue) count++;
  if (isFirstValueAfterMaxValue) count++;
  if (isFirstValueBeforeMinValue) count++;
  if (hasFirstValueCustomValidationMsg) count++;
  if (showRequiredBannerFirstValue) count++;
  if (!isValidFirstValue) count++;
  if (isSecondValueAfterMaxValue) count++;
  if (isSecondValueBeforeMinValue) count++;
  if (hasSecondValueCustomValidationMsg) count++;
  if (showRequiredBannerSecondValue) count++;
  if (!isValidSecondValue) count++;

  if (count !== bannerCount) {
    setBannerCount(count);
  }

  const rangeLabelProps: RangeLabelProps = {
    id,
    labelAlwaysAbove,
    labelText: rangeLabel,
    standalone,
    suppressVerticalSpacing,
  };
  const attentionBannerProps: AttentionBannersProps = {
    bannerCount,
    errorAlwaysBelow,
    firstValueCustomValidationMsg,
    hasFirstValueCustomValidationMsg,
    hasSecondValueCustomValidationMsg,
    id,
    isCurrency,
    isFirstValueAfterMaxValue,
    isFirstValueAfterSecondValue,
    isFirstValueBeforeMinValue,
    isSecondValueAfterMaxValue,
    isSecondValueBeforeMinValue,
    isValidFirstValue,
    isValidSecondValue,
    maxFirstValue,
    maxSecondValue,
    minFirstValue,
    minSecondValue,
    secondValueCustomValidationMsg,
    showRequiredBannerFirstValue,
    showRequiredBannerSecondValue,
    standalone,
    suppressVerticalSpacing,
    suppressWarningBanner,
  };

  return (
    <FormContext.Consumer>
      {(context: FormContextData) => (
        <div
          id={id}
          data-testid={`${id}-numeric-range-input-testid`}
          {...classes({ extra: `${classes().className} ${className ?? ''}` })}
        >
          <div
            {...classes({
              extra: suppressVerticalSpacing ? undefined : 'fe_u_margin--top-small fe_u_margin--bottom-small',
            })}
          >
            {labelAlwaysAbove === true && <RangeLabel {...rangeLabelProps} />}
            <div
              {...classes({
                extra: 'fe_u_flex-container',
                modifiers: 'align wrap',
              })}
            >
              {labelAlwaysAbove === false && <RangeLabel {...rangeLabelProps} />}
              <div
                {...classes({
                  extra: 'fe-input__input fe_u_margin--right-small',
                })}
              >
                <div
                  {...classes({
                    modifiers: 'wrap',
                    extra: 'fe_u_flex-container',
                  })}
                >
                  <div
                    {...classes({
                      extra: 'fe_u_flex-align-items--flex-start',
                    })}
                  ></div>
                  <Input
                    id={`${id}-min-number-id`}
                    data-testid={`${id}-min-number-id`}
                    disabled={disabledFirstValue || disabled}
                    currency={isCurrency}
                    type={'text'}
                    min={minFirstValue}
                    max={maxFirstValue}
                    required={
                      requiredFirstValue ||
                      (!minMaxRequiredValues &&
                        required &&
                        (context.requiredVariation === 'blueBarWithRequiredLabel' ||
                          context.requiredVariation === 'allFieldsRequired'))
                    }
                    error={error || firstValueErrorMsg}
                    placeholder={placeholderFirstValue}
                    value={firstValue ?? ''}
                    onChange={handleFirstValueChange}
                    onBlur={handleFirstBlur}
                    onPaste={handleFirstValuePaste}
                    ref={refFirstValue}
                  />
                  <span
                    {...classes({
                      element: 'componentTo',
                      extra: 'fe_u_margin--left-small fe_u_margin--right-small fe_u_flex-align-self--center',
                    })}
                  >
                    to
                  </span>
                  <Input
                    id={`${id}-max-number-id`}
                    data-testid={`${id}-max-number-id`}
                    disabled={disabledSecondValue || disabled}
                    currency={isCurrency}
                    type={'text'}
                    min={minSecondValue}
                    max={maxSecondValue}
                    required={
                      requiredSecondValue ||
                      (!minMaxRequiredValues &&
                        required &&
                        (context.requiredVariation === 'blueBarWithRequiredLabel' ||
                          context.requiredVariation === 'allFieldsRequired'))
                    }
                    error={error || secondValueErrorMsg}
                    placeholder={placeholderSecondValue}
                    value={secondValue ?? ''}
                    onChange={handleSecondValueChange}
                    onBlur={handleSecondBlur}
                    onPaste={handleSecondValuePaste}
                    ref={refSecondValue}
                  />
                  <div
                    {...classes({
                      extra: 'fe_u_flex-align-items--flex-end',
                    })}
                  ></div>
                </div>
                <DescriptionLabel
                  allowNullValuesCheckBox={allowNullValuesCheckBox}
                  bannerCount={bannerCount}
                  errorAlwaysBelow={errorAlwaysBelow}
                  labelText={rangeDescription}
                  suppressVerticalSpacing={suppressVerticalSpacing}
                  suppressWarningBanner={suppressWarningBanner}
                />
                <NullValueCheckbox
                  allowNullValuesCheckBox={
                    allowNullValuesCheckBox ||
                    (!minMaxRequiredValues &&
                      required &&
                      (context.requiredVariation === 'blueBarWithRequiredLabel' ||
                        context.requiredVariation === 'allFieldsRequired'))
                  }
                  handleShowNoNumbersChange={handleShowNoNumbersChange}
                  id={id}
                  isCurrency={isCurrency}
                  requiredFirstValue={requiredFirstValue}
                  requiredSecondValue={requiredSecondValue}
                  showNullAmountsCheckboxDescription={showNullAmountsCheckboxDescription}
                  showNullQuantitiesCheckboxDescription={showNullQuantitiesCheckboxDescription}
                  showNoNumbers={showNoNumbers}
                  suppressVerticalSpacing={suppressVerticalSpacing}
                />
                {errorAlwaysBelow === true && <AttentionBanners {...attentionBannerProps} />}
              </div>
              {errorAlwaysBelow === false && <AttentionBanners {...attentionBannerProps} />}
            </div>
          </div>
        </div>
      )}
    </FormContext.Consumer>
  );
};

const numericRangeInputProps: WeakValidationMap<NumericRangeInputComponentProps> = {
  /**
   * Optional boolean to allow the required inputs to be null. This turns off the overrides for the required values.
   */
  allowNullValuesCheckBox: PropTypes.bool,
  /** Optional string of classes to add to the Forge Root element of NumericRangeInput. */
  className: PropTypes.string,
  /** Optional Forge disabled prop.  Used only for FormField and MultiField. minMaxDisabledValues is more fine grained. */
  disabled: PropTypes.bool,
  /** Optional string of externally determined error. */
  error: PropTypes.string,
  /**
   * Optional boolean tells the error to always render below the input area instead of based on content-size and breakpoint.
   */
  errorAlwaysBelow: PropTypes.bool,
  /**
   * Optional object holding the lower and upper bounds for the range of the max input.
   *
   * NumericRangeInputBounds {
   *  lowest: number | undefined;
   *  highest: number | undefined;
   * }
   */
  highestLowestValueForMax: PropTypes.shape({
    highest: optionalNumberValidator,
    lowest: optionalNumberValidator,
  }),
  /**
   * Optional object holding the lower and upper bounds for the range of the min input.
   *
   * NumericRangeInputBounds {
   *  lowest: number | undefined;
   *  highest: number | undefined;
   * }
   */
  highestLowestValueForMin: PropTypes.shape({
    highest: optionalNumberValidator,
    lowest: optionalNumberValidator,
  }),
  /** REQUIRED string containing the HTML element id for this component. */
  id: PropTypes.string.isRequired,
  /**
   * Optional boolean indicating if this a range of money.
   */
  isCurrency: PropTypes.bool,
  /**
   * Optional boolean indicating if these numbers can only be integers.
   */
  isInteger: PropTypes.bool,
  /**
   * Optional boolean to force the component label to be on top of the range.
   */
  labelAlwaysAbove: PropTypes.bool,
  /**
   * Optional object containing booleans.
   * The field "min" indicates if the minimum is disabled and
   * the field "max" indicates if the maximum is disabled.
   *
   * NumericRangeInputDisabled {
   *  min: boolean | undefined;
   *  max: boolean | undefined;
   * }
   */
  minMaxDisabledValues: PropTypes.shape({
    max: optionalBoolValidator,
    min: optionalBoolValidator,
  }),
  /**
   * Optional object containing booleans.
   * The field "min" indicates if the minimum is required and
   * the field "max" indicates if the maximum is required.
   *
   * NumericRangeInputRequires {
   *  min: boolean | undefined;
   *  max: boolean | undefined;
   * }
   */
  minMaxRequiredValues: PropTypes.shape({
    max: optionalBoolValidator,
    min: optionalBoolValidator,
  }),
  /** Optional user supplied handler for blur on each input.  */
  onBlurAllValues: PropTypes.func,
  /** Optional user supplied handler for changes on each input. */
  onChangeAllValues: PropTypes.func,
  /** Optional user supplied handler for blur on first input. */
  onFirstValueBlur: PropTypes.func,
  /** Optional user supplied handler for blur on second input. */
  onSecondValueBlur: PropTypes.func,
  /**
   * Optional object containing text to show in the inputs if the min or max inputs have no value and are unfocused.
   *
   * NumericRangeInputPlaceholderText {
   *  min: string | undefined;
   *  max: string | undefined;
   * }
   */
  placeholderValues: PropTypes.shape({
    max: optionalStringValidator,
    min: optionalStringValidator,
  }),
  /**
   * Optional string containing a description that helps users select sensible min and max inputs.
   */
  rangeDescription: PropTypes.string,
  /**
   * Optional label for the numeric range.
   */
  rangeLabel: PropTypes.string,
  /** Optional Forge require prop.  Used only for FormField and MultiField. minMaxRequiredValues is more fine grained. */
  required: PropTypes.bool,
  /**
   * Optional label for the checkbox if it is for currency numbers.
   *
   * When unchecked, the user must enter values for the min and/or max input depending on the  existence
   * and state of other optional properties. The checkbox is visible if allowNullValuesCheckBox is set to true
   * or if any of the values in the optional minMaxRequiredValues is set to true.
   *
   * If the checkbox has a check in it, neither input is required to have a value regardless of the settings in minMaxRequiredValues.
   */
  showNullAmountsCheckboxDescription: PropTypes.string,
  /**
   * Optional label for the checkbox if it is for non-currency numbers.
   *
   * When unchecked, the user must enter values for the min and/or max input depending on the  existence
   * and state of other optional properties. The checkbox is visible if allowNullValuesCheckBox is set to true
   * or if any of the values in the optional minMaxRequiredValues is set to true.
   *
   * If the checkbox has a check in it, neither input is required to have a value regardless of the settings in minMaxRequiredValues.
   */
  showNullQuantitiesCheckboxDescription: PropTypes.string,
  /** Optional boolean for standalone DateRanges, indicating that they are not part of a Form and should show their own label and banners (if provided). */
  standalone: PropTypes.bool,
  /**
   * Optional boolean indicating if the NumericRangeInput should suppress vertical spacing.
   */
  suppressVerticalSpacing: PropTypes.bool,
  /**
   * Optional boolean indicating if the NumericRangeInput should suppress the display of error messages.
   */
  suppressWarningBanner: PropTypes.bool,
  /**
   * Optional function taking a number and returning a string or null.
   * This function is implemented by the user of the NumericRangeInput to allow for their own validations for the min input.
   * If null is returned, the min input is acceptable.
   * If a string is returned, it contains a message to display to the user in an error message informing them why the min
   * input value is not valid.
   */
  validateMinValue: PropTypes.func,
  /**
   * Optional function taking a number and returning a string or null.
   * This function is implemented by the user of the NumericRangeInput to allow for their own validations for the max input.
   * If null is returned, the max input is acceptable.
   * If a string is returned, it contains a message to display to the user in an error message informing them why the max
   * input value is not valid.
   */
  validateMaxValue: PropTypes.func,
  /**
   * Optional object for storing the min input, max input, and require checkbox value.  Elements can be undefined if not used.
   *
   * NumericRangeInputValues {
   *  min: number | undefined;
   *  max: number | undefined;
   *  checked: boolean | undefined;
   * }
   */
  values: PropTypes.shape({
    checked: optionalBoolValidator,
    max: optionalNumberValidator,
    min: optionalNumberValidator,
  }),
};

NumericRangeInput.propTypes = numericRangeInputProps;

export default NumericRangeInput;
