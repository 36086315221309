import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBusSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7 10.5C7 11.0523 6.55228 11.5 6 11.5C5.44772 11.5 5 11.0523 5 10.5C5 9.94772 5.44772 9.5 6 9.5C6.55228 9.5 7 9.94772 7 10.5Z"
      fill={props.color}
    />
    <path
      d="M11 10.5C11 11.0523 10.5523 11.5 10 11.5C9.44772 11.5 9 11.0523 9 10.5C9 9.94772 9.44772 9.5 10 9.5C10.5523 9.5 11 9.94772 11 10.5Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.5C6.55228 11.5 7 11.0523 7 10.5C7 9.94772 6.55228 9.5 6 9.5C5.44772 9.5 5 9.94772 5 10.5C5 11.0523 5.44772 11.5 6 11.5ZM10 11.5C10.5523 11.5 11 11.0523 11 10.5C11 9.94772 10.5523 9.5 10 9.5C9.44772 9.5 9 9.94772 9 10.5C9 11.0523 9.44772 11.5 10 11.5Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 5C15 4.44772 14.5523 4 14 4C14 2.68231 13.1151 1.66021 12.05 1.02119C10.9651 0.370218 9.53079 0 8 0C6.46921 0 5.03492 0.370218 3.94997 1.02119C2.88493 1.66021 2 2.68231 2 4C1.44772 4 1 4.44772 1 5V6C1 6.55228 1.44772 7 2 7V13C2 13.5523 2.44772 14 3 14H4V15C4 15.5523 4.44772 16 5 16C5.55228 16 6 15.5523 6 15V14H10V15C10 15.5523 10.4477 16 11 16C11.5523 16 12 15.5523 12 15V14H13C13.5523 14 14 13.5523 14 13V7C14.5523 7 15 6.55228 15 6V5ZM4 5V7H12V5H4ZM12 9H4V12H12V9ZM11.4007 3C11.2922 2.91171 11.1663 2.82335 11.021 2.73617C10.2963 2.30135 9.23063 2 8 2C6.76937 2 5.70366 2.30135 4.97896 2.73617C4.83367 2.82335 4.7078 2.91171 4.59931 3H11.4007Z"
      fill={props.color}
    />
  </svg>
);
SvgBusSmall.displayName = 'SvgBusSmall';
const BusSmall = withForgeIconProps(SvgBusSmall, {
  name: 'Bus',
  usage:
    'Use to indicate transportation or transit, access to transportation, or other related issues',
  related: 'transit, bus, transportation, car',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default BusSmall;
