import { DataTableStore } from '../store/stateTypes';
import { ColumnFilterController } from './ColumnFilterController';
import { SortController } from './SortController';
import { classes } from '../utils/dataTableClasses';
import Tooltip from '../../Tooltip';
import { TOOLTIP_OPTIONS } from '../utils/constants';

type ColumnHeaderProps<RowData> = {
  store: DataTableStore<RowData>;
  columnId: string;
};

/**
 * Column Header
 * https://athenaconfluence.athenahealth.com/x/AFuqIw
 */
export function ColumnHeader<RowData>({ store, columnId }: ColumnHeaderProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const tableId = store.useSelector((s) => s.tableId);

  // header text
  const ColumnHeaderDisplayText = store.useSelector((s) => s.columns[columnId].Header);
  const tableWrapHeaderStyle = store.useSelector((s) => s.tableWrapHeaderStyle);
  const headerAlignRight = store.useSelector((s) => s.columns[columnId].headerAlignRight);
  const headerTooltip = store.useSelector((s) => s.columns[columnId].headerTooltip);
  const hasTooltip = typeof headerTooltip !== 'undefined' && headerTooltip !== null;

  // column filter
  const ColumnFilterComponent = store.useSelector((s) => s.columns[columnId].filter.ColumnFilterComponent);
  const showColumnFilters = store.useSelector((s) => s.showColumnFilters);

  return (
    <div
      {...classes({
        element: 'column-header',
        modifiers: {
          wrap: tableWrapHeaderStyle === 'wrap',
          nowrap: tableWrapHeaderStyle === 'nowrap',
          'align-right': headerAlignRight,
          'has-tooltip': hasTooltip,
        },
      })}
    >
      {/* Column Header Tooltip */}
      <Tooltip
        id={`${tableId}-column-header-tooltip-${columnId}`}
        wrapperClassName={classes({ element: 'column-header__tooltip' }).className}
        text={headerTooltip ?? ''}
        placement="bottom"
        showTip={hasTooltip ? TOOLTIP_OPTIONS.SHOW_WHEN_MOUSE_OVER : TOOLTIP_OPTIONS.HIDE}
      >
        {/* Column Header Display Text (this can be replaced by user) */}
        <ColumnHeaderDisplayText
          columnId={columnId}
          useSelector={store.useSelector}
          useDispatch={store.useDispatch}
          useDispatchAsyncAction={store.useDispatchAsyncAction}
        />
      </Tooltip>

      {/* Column Filter */}
      {showColumnFilters && typeof ColumnFilterComponent !== 'undefined' && (
        <ColumnFilterController store={store} columnId={columnId}>
          <ColumnFilterComponent
            columnId={columnId}
            useSelector={store.useSelector}
            useDispatch={store.useDispatch}
            useDispatchAsyncAction={store.useDispatchAsyncAction}
            onFilterConditionValuesChange={(conditionValues) => {
              dispatch(function update_filter_condition_values(s) {
                s.columns[columnId].filter.conditionValues = conditionValues;
              });
            }}
          />
        </ColumnFilterController>
      )}

      {/* Sort Controller */}
      <SortController store={store} columnId={columnId} />
    </div>
  );
}
