import rawContents from "!!raw-loader!./ListSelection.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ListSelection():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="List Selection" />;
}

export default ListSelection;
