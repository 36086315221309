import EditSmall from '@athena/forge-icons/dist/EditSmall';
import TrashSmall from '@athena/forge-icons/dist/TrashSmall';
import React from 'react';
import {
  Item,
  ItemProps,
  Menu,
  MenuProps,
  Separator,
  SeparatorProps,
  SubMenuProps,
  Submenu,
  useContextMenu,
} from 'react-contexify';
import { DataTableState, DataTableStore } from '../store/stateTypes';
import { DeleteRowActionArgs } from '../rowActionButtons/createDeleteRowActionButton';
import { RestoreRowActionArgs } from '../rowActionButtons/createRestoreRowActionButton';
import { classes } from '../utils/dataTableClasses';
import { getContextMenuId } from '../utils/getContextMenuId';

// ====================================
// Actions
// ====================================
import { delete_row_async } from '../store/asyncActions/delete_row_async';
import { inline_edit_row } from '../store/actions/inline_edit_row';
import { restore_row_async } from '../store/asyncActions/restore_row_async';

// ====================================
// Types
// ====================================
export type ContextMenuItemProps<RowData> = Omit<ItemProps, 'onClick'> & {
  /**
   * - Optional
   * - Type: `({ rowId: number; columnId: string }) => void`
   */
  onClick?: (param: DataTableState<RowData>['contextMenu']) => void;
};
type EnhancedItem<RowData> = React.ComponentType<ContextMenuItemProps<RowData>>;

export type ContextMenu<RowData> = {
  /**
   * `TD.ContextMenu.Menu` is the toppest component for the DataTable ContextMenu.
   * - There's no need to set the `id` for the `Menu` component manually since it's already setup by DataTable.
   * - You need to wrap your custom ContextMenu component under the `DT.Provider`.
   * - See the document from 'react-contexify':
   *   https://github.com/fkhadra/react-contexify/blob/main/src/components/Menu.tsx
   */
  Menu: React.ComponentType<Omit<MenuProps, 'id'>>;

  /**
   * `TD.ContextMenu.Item` is a general context menu item component.
   * - See the document from 'react-contexify':
   *   https://github.com/fkhadra/react-contexify/blob/main/src/components/Item.tsx
   *
   * __Example:__
   * ```tsx
   * const { Menu, Separator, Item } = DT.ContextMenu;
   *
   * function CustomContextMenu() {
   *   return (
   *     <Menu>
   *       <Item>Item1</Item>
   *       <Item>Item2</Item>
   *       <Separator />
   *       <Item>Item3</Item>
   *     </Menu>
   *   )
   * }
   * ```
   */
  Item: EnhancedItem<RowData>;

  /**
   * `TD.ContextMenu.InlineEditRowActionItem` is a context menu item component to trigger the row inline edit action.
   * - Make sure to add the save cancel row action button so that you're able to save the data.
   *   ```tsx
   *   DT.Column.createRowActionColumn({
   *     headerText: 'Actions',
   *     actionButtons: {
   *       view: [],
   *       edit: [DT.RowActionButton.createSaveCancelRowActionButton()],
   *       deleted: [],
   *     },
   *   }),
   *   ```
   *
   * __Example:__
   * ```tsx
   * const { Menu, InlineEditRowActionItem } = DT.ContextMenu;
   *
   * function CustomContextMenu() {
   *   return (
   *     <Menu>
   *       <InlineEditRowActionItem />
   *     </Menu>
   *   )
   * }
   * ```
   */
  InlineEditRowActionItem: React.ComponentType<Omit<ContextMenuItemProps<RowData>, 'children'>>;

  /**
   * `TD.ContextMenu.DeleteRowActionItem` is a context menu item component to trigger the row delete action.
   * - `shouldDeleteRowActionDisabled?: ((rowData: RowData) => boolean)`
   * - `serverSideDeleteRowFn?: ((rowData: RowData) => Promise<void>)`
   * - `onRowDeleted?: ((rowData: RowData) => void)`
   * - `confirmModal?: ((rowData: RowData) => AlertData)`
   * - `completeModal?: ((rowData: RowData) => ModalAlertData)`
   * - `completeToast?: ((rowData: RowData) => ToastAlertData)`
   * - `onClick?: ({ rowId: number; columnId: string }) => void`
   *
   * __Example:__
   * ```tsx
   * const { Menu, RestoreRowActionItem } = DT.ContextMenu;
   *
   * function CustomContextMenu() {
   *   return (
   *     <Menu>
   *       <DeleteRowActionItem
   *         confirmModal={(r) => ({
   *           title: 'Really delete this row?',
   *           message: `The row for ${r.firstName} will be deleted.`,
   *         })}
   *         completeToast={(r) => ({
   *           alertType: 'success',
   *           title: 'Row deleted successfully.',
   *           message: `The row for ${r.firstName} has been deleted.`,
   *         })}
   *       />
   *     </Menu>
   *   )
   * }
   * ```
   */
  DeleteRowActionItem: React.ComponentType<
    DeleteRowActionArgs<RowData> & Omit<ContextMenuItemProps<RowData>, 'children'>
  >;

  /**
   * `TD.ContextMenu.RestoreRowActionItem` is a context menu item component to trigger the row restore action.
   * - `serverSideRestoreRowFn?: ((rowData: RowData) => Promise<void>)`
   * - `onRowRestored?: ((rowData: RowData) => void)`
   * - `confirmModal?: ((rowData: RowData) => AlertData)`
   * - `completeModal?: ((rowData: RowData) => ModalAlertData)`
   * - `completeToast?: ((rowData: RowData) => ToastAlertData)`
   * - `onClick?: ({ rowId: number; columnId: string }) => void`
   *
   * __Example:__
   * ```tsx
   * const { Menu, RestoreRowActionItem } = DT.ContextMenu;
   *
   * function CustomContextMenu() {
   *   return (
   *     <Menu>
   *       <RestoreRowActionItem
   *         confirmModal={(r) => ({
   *           title: 'Really restore this row?',
   *           message: `The row for ${r.firstName} will be restored.`,
   *         })}
   *         completeToast={(r) => ({
   *           alertType: 'success',
   *           title: 'Row restored successfully.',
   *           message: `The row for ${r.firstName} has been restored.`,
   *         })}
   *       />
   *     </Menu>
   *   )
   * }
   * ```
   */
  RestoreRowActionItem: React.ComponentType<
    RestoreRowActionArgs<RowData> & Omit<ContextMenuItemProps<RowData>, 'children'>
  >;

  /**
   * `TD.ContextMenu.Title` is a context menu item component to display a title.
   *
   * __Example:__
   * ```tsx
   * const { Menu, Title } = DT.ContextMenu;
   *
   * function CustomContextMenu() {
   *   return (
   *     <Menu>
   *       <Title>Title</Title>
   *     </Menu>
   *   )
   * }
   * ```
   */
  Title: React.ComponentType<ContextMenuItemProps<RowData>>;

  /**
   * `TD.ContextMenu.Separator` is a context menu component to separate the item.
   * - See the document from 'react-contexify':
   *   https://github.com/fkhadra/react-contexify/blob/main/src/components/Separator.tsx
   *
   * __Example:__
   * ```tsx
   * const { Menu, Separator, Item } = DT.ContextMenu;
   *
   * function CustomContextMenu() {
   *   return (
   *     <Menu>
   *       <Item>Item1</Item>
   *       <Item>Item2</Item>
   *       <Separator />
   *       <Item>Item3</Item>
   *     </Menu>
   *   )
   * }
   * ```
   */
  Separator: React.ComponentType<SeparatorProps>;

  /**
   * `TD.ContextMenu.Submenu` is a component to create the sub menu.
   * - See the document from 'react-contexify':
   *   https://github.com/fkhadra/react-contexify/blob/main/src/components/Submenu.tsx
   *
   * __Example:__
   * ```tsx
   * const { Menu, Submenu, Item } = DT.ContextMenu;
   *
   * function CustomContextMenu() {
   *   return (
   *     <Menu>
   *       <Item>Item1</Item>
   *       <Item>Item2</Item>
   *       <Submenu label="Submenu">
   *         <Item>Item3</Item>
   *       </Submenu>
   *     </Menu>
   *   )
   * }
   * ```
   */
  Submenu: React.ComponentType<SubMenuProps>;
};

export function createContextMenu<RowData>(TableStore: DataTableStore<RowData>): ContextMenu<RowData> {
  // ==================================
  // EnhancedItem (Override onClick callback)
  // ==================================
  const EnhancedItem: EnhancedItem<RowData> = (props) => {
    const { onClick, ...rest } = props;
    return <Item {...rest} onClick={({ props }) => onClick?.(props)} />;
  };

  return {
    // ==================================
    // Menu (Specify the unique id)
    // ==================================
    Menu: (props) => {
      const tableId = TableStore.useSelector((s) => s.tableId);
      return <Menu {...props} id={getContextMenuId(tableId)} theme="light" {...classes({ element: 'context_menu' })} />;
    },

    // ==================================
    // Item (Override onClick callback)
    // ==================================
    Item: (props) => {
      const { onClick, ...rest } = props;
      return <Item {...rest} onClick={({ props }) => onClick?.(props)} />;
    },

    // ==================================
    // Inline Edit Row Action
    // ==================================
    InlineEditRowActionItem: (props) => {
      const dispatch = TableStore.useDispatch();
      const tableId = TableStore.useSelector((s) => s.tableId);
      const contextMenu = useContextMenu({ id: getContextMenuId(tableId) });

      const { rowId } = TableStore.useSelector((s) => s.contextMenu);
      const rowStatus = TableStore.useSelector((s) => s.rows[rowId]?.rowStatus);
      const disabled = rowStatus !== 'view';

      return (
        <EnhancedItem
          disabled={disabled}
          onClick={() => {
            dispatch(inline_edit_row(rowId));

            // Hide the context menu immediately.
            // It's not supposed to show the context menu in edit mode.
            contextMenu.hideAll();
          }}
          {...props}
        >
          <EditSmall semanticColor={disabled ? 'disabled' : 'interactive'} />
          <div>Edit row</div>
        </EnhancedItem>
      );
    },

    // ==================================
    // Delete Row Action
    // ==================================
    DeleteRowActionItem: ({
      shouldDeleteRowActionDisabled,
      serverSideDeleteRowFn,
      onRowDeleted,
      confirmModal,
      completeModal,
      completeToast,
      ...props
    }) => {
      const dispatchAsyncAction = TableStore.useDispatchAsyncAction();
      const tableType = TableStore.useSelector((s) => s.tableType);
      const { rowId } = TableStore.useSelector((s) => s.contextMenu);
      const rowData = TableStore.useSelector((s) => s.rows[rowId]?.data);
      const rowStatus = TableStore.useSelector((s) => s.rows[rowId]?.rowStatus);
      const disabled = rowStatus !== 'view' || (shouldDeleteRowActionDisabled?.(rowData) ?? false);

      return (
        <EnhancedItem
          disabled={disabled}
          onClick={() => {
            dispatchAsyncAction(
              delete_row_async({
                tableType,
                rowData,
                rowId,
                serverSideDeleteRowFn,
                onRowDeleted,
                confirmModal,
                completeModal,
                completeToast,
              })
            );
          }}
          {...props}
        >
          <TrashSmall semanticColor={disabled ? 'disabled' : 'interactive'} />
          <div>Delete row</div>
        </EnhancedItem>
      );
    },

    // ==================================
    // Restore Row Action (text only)
    // ==================================
    RestoreRowActionItem: ({
      serverSideRestoreRowFn,
      onRowRestored,
      confirmModal,
      completeModal,
      completeToast,
      ...props
    }) => {
      const dispatchAsyncAction = TableStore.useDispatchAsyncAction();
      const tableType = TableStore.useSelector((s) => s.tableType);
      const { rowId } = TableStore.useSelector((s) => s.contextMenu);
      const rowData = TableStore.useSelector((s) => s.rows[rowId]?.data);
      const rowStatus = TableStore.useSelector((s) => s.rows[rowId]?.rowStatus);

      return (
        <EnhancedItem
          disabled={rowStatus !== 'deleted'}
          onClick={() => {
            dispatchAsyncAction(
              restore_row_async({
                tableType,
                rowData,
                rowId,
                serverSideRestoreRowFn,
                onRowRestored,
                confirmModal,
                completeModal,
                completeToast,
              })
            );
          }}
          {...props}
        >
          Restore row
        </EnhancedItem>
      );
    },

    // ==================================
    // Title
    // ==================================
    Title: (props) => {
      const { className = '', ...rest } = props;
      return <EnhancedItem {...rest} className={className + ' contexify_item_title'} />;
    },

    // ==================================
    // Separator
    // ==================================
    Separator,

    // ==================================
    // Sub Menu (Append className)
    // ==================================
    Submenu: (props) => {
      const { className = '', ...rest } = props;
      return <Submenu {...rest} className={className + ' contexify_item_submenu'} />;
    },
  };
}
