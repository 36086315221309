import { createContext } from 'react';

export type SegmentedButtonClickHandler = (formValue: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
export type SegmentedButtonRefHandler = (id: string, ref: HTMLElement | null) => void;
export type SegmentedButtonGroupRole = 'group' | 'radiogroup';
export type SegmentedButtonChildRole = 'button' | 'checkbox' | 'radio';
export type SegmentedButtonSize = 'small' | 'medium' | 'large' | 'x-large';

export type SegmentedButtonValuesState = Record<string, true>;
export interface SegmentedButtonContextData {
  disabled?: boolean;
  buttonClickHandler?: SegmentedButtonClickHandler;
  buttonRefHandler?: SegmentedButtonRefHandler;
  checkedValues: SegmentedButtonValuesState;
  childRole?: SegmentedButtonChildRole;
  size?: SegmentedButtonSize;
}

export const defaultSegmentedButtonContext: SegmentedButtonContextData = {
  checkedValues: {},
};

export const SegmentedButtonContext = createContext(defaultSegmentedButtonContext);
