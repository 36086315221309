import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDropAreaLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2_86)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7H20V9H18V7ZM22 7H24V9H22V7ZM24 11V13H22V11H24ZM24 15V17H22V15H24ZM6 20.1875V18.3125H8V20.1875H6ZM24 19V20C24 20.5405 23.8922 21.0584 23.6961 21.5314L21.8486 20.7653C21.9458 20.5309 22 20.273 22 20V19H24ZM21.5314 23.6961C21.0584 23.8922 20.5405 24 20 24H18.9167V22H20C20.273 22 20.5309 21.9458 20.7653 21.8486L21.5314 23.6961ZM8.08333 24H6V22H8.08333V24ZM12.4167 24H10.25V22H12.4167V24ZM16.75 24H14.5833V22H16.75V24Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H18V13C18 15.2091 16.2091 17 14 17H0V0ZM2 2V15H14C15.1046 15 16 14.1046 16 13V2H2Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2_86">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
SvgDropAreaLarge.displayName = 'SvgDropAreaLarge';
const DropAreaLarge = withForgeIconProps(SvgDropAreaLarge, {
  name: 'DropArea',
  usage: 'Use to indicate an area an item can be moved to by dragging',
  related: 'move, reorder, list, drag, handle',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default DropAreaLarge;
