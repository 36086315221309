import { components, GroupBase, InputProps } from 'react-select';
import { ReactElement } from 'react';
import { useSelectContext } from '../context/SelectContext';

const CustomInput = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: InputProps<Option, IsMulti, Group>
): ReactElement => {
  const {
    selectProps: { instanceId },
  } = props;

  const { required } = useSelectContext();
  const ownsId = `select-list-${instanceId}`;

  return <components.Input {...props} required={required} aria-owns={ownsId} role="combobox" />;
};

export default CustomInput;
