import ButtonStyleVariants from './ButtonStyleVariants.gen';
import DisabledButton from './DisabledButton.gen';
import FullWidthButton from './FullWidthButton.gen';
import ButtonSizes from './ButtonSizes.gen';
import ButtonWithIcon from './ButtonWithIcon.gen';
import ButtonWithLoadingSpinner from './ButtonWithLoadingSpinner.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <ButtonStyleVariants />
      <DisabledButton />
      <FullWidthButton />
      <ButtonSizes />
      <ButtonWithIcon />
      <ButtonWithLoadingSpinner />
    </>
  );
}
