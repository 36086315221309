import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgXRayLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6 1V5M18 1V5M12 7.5V19.5M17 11C15.4157 10.3399 13.7163 10 12 10C10.2837 10 8.58431 10.3399 7 11M17 14C15.4157 13.3399 13.7163 13 12 13C10.2837 13 8.58431 13.3399 7 14M15 16.7499C13.0561 16.0801 10.9439 16.0801 9 16.7499M1 2.91016H23V20C23 20.7956 22.6839 21.5585 22.1213 22.1211C21.5587 22.6837 20.7956 23 20 23H1V2.91016Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgXRayLarge.displayName = 'SvgXRayLarge';
const XRayLarge = withForgeIconProps(SvgXRayLarge, {
  name: 'XRay',
  usage: 'Use to indicate or view X-ray information',
  related: 'xray, radiology, radiologist, labs, imaging',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default XRayLarge;
