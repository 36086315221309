import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React, { ReactElement, WeakValidationMap, useCallback, useMemo, useState, useContext } from 'react';
import { forgeClassHelper } from '../utils/classes';
import FakeEvent from '../utils/fake-event';
import forwardRefToProps from '../utils/forwardRefToProps';
import { isUndefined } from '../utils/helpers';
import { ListContext, ListOnSelect, ListValueState } from './ListContext';
import { useOnClickItem } from './listHelpers';
import { FormFieldLayoutContext } from '../FormFieldLayout/FormFieldLayoutContext';
import { FormLayout } from '../Form';

const classes = forgeClassHelper('list');

export type ListValue = string[];

export type ListOnChangeEvent = FakeEvent<ListValue>;

export type ListLayout = 'compact' | 'medium';

export interface ListProps {
  /** Specify the ID of the element that labels the List */
  'aria-labelledby'?: string;
  /** Each child should be a `ListItem` */
  children?: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /**
   * Determines the initial set of selected items, but allows them to be updated internally (uncontrolled).
   * The array values correspond to each ListItem.uniqueKey.
   */
  defaultValue?: ListValue;
  /** Describes the selection items. Usually a question/statement that the items are responses to */
  description?: string;
  /** Impacts the styling of the checkboxes in addition to preventing selection when true */
  disabled?: boolean;
  /** Highlights the input when defined (specific string doesn't matter) */
  error?: string;
  /** Hides any required styling when true. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles?: boolean;
  /** Adds an `id` attribute to the <fieldset> wrapping the list of items. */
  id?: string;
  /** Configures spacing between ListItems
   *
   * If wrapped in a FormField, FormField's layout will influence List's layout
   * | FormField layout | List layout |
   * |------------------|-------------|
   * | `super-compact`  | `compact`   |
   * | `compact`        | `medium`    |
   * | `medium`         | `medium`    |
   * | `large`          | `medium`    |
   */
  layout?: ListLayout;
  /** `name` attribute of the root element */
  name?: string;
  /**
   * Called when any of the items lose focus.
   * Gets a simulated event object. The value (array of ListItem uniqueKey values)
   * is in `event.target.value`.
   */
  onBlur?: (event: ListOnChangeEvent) => void;
  /**
   * Called when the value changes.
   * Gets a simulated event object. The value (array of ListItem uniqueKey values)
   * is in `event.target.value`.
   */
  onChange?: (event: ListOnChangeEvent) => void;
  /** Upstream on click handler */
  onClick?: React.HTMLAttributes<HTMLUListElement>['onClick'];
  /** Upstream on key press */
  onKeyDown?: React.HTMLAttributes<HTMLUListElement>['onKeyDown'];
  /**
   * Function that takes a JS event as an argument. Called when the List receives focus.
   */
  onFocus?: (event: React.FocusEvent<HTMLUListElement, Element>) => void;

  /** Ref forwarded to List's `<fieldset>` element */
  ref?: React.Ref<HTMLFieldSetElement>;

  /** Style the input as required when true. Only used for styling purposes. All validation is handled by parents. */
  required?: boolean;
  /** Set to true to display dividers between each option */
  showDividers?: boolean;
  /** Determines whether the List will be interactive or plain text */
  variant?: 'simple' | 'selection';
  /**
   * Determines the set of selected items, and prevents them from getting updated internally ([controlled](https://reactjs.org/docs/forms.html#controlled-components)).
   * The array values correspond to each child's uniqueKey.
   */
  value?: ListValue;
}

interface ListComponentProps extends ListProps {
  /** Ref forwarded to List's `<fieldset>` element */
  forwardedRef?: React.Ref<HTMLFieldSetElement>;
}

function reconcileValueAndDefaultValue({
  value,
  defaultValue,
}: Pick<ListProps, 'value' | 'defaultValue'>): ListValueState {
  if (Array.isArray(value)) {
    return listValueToState(value);
  } else if (Array.isArray(defaultValue)) {
    return listValueToState(defaultValue);
  }
  return {};
}

const listStateToArray = (listValue: ListValueState): ListValue => {
  return Object.keys(listValue).filter((key) => listValue[key]);
};

const listValueToState = (listValue: ListValue): ListValueState => {
  if (Array.isArray(listValue)) {
    return Object.fromEntries(listValue.map((key) => [key, true]));
  } else {
    return {};
  }
};

const reconcileLayout = (upstreamLayout?: ListLayout, formLayout?: FormLayout): ListLayout => {
  if (typeof upstreamLayout === 'string') {
    return upstreamLayout;
  } else if (formLayout === 'super-compact') {
    return 'compact';
  } else {
    return 'medium';
  }
};

/**
 * List
 *
 * A component to render an unordered list of plain text, or selectable, ListItems.
 *
 */
function List({
  'aria-labelledby': ariaLabelledBy,
  children,
  className,
  defaultValue,
  description,
  disabled = false,
  error,
  forwardedRef,
  showDividers = false,
  hideRequiredStyles = false,
  id: upstreamId,
  layout: upstreamLayout,
  onBlur,
  onChange,
  onClick: upstreamOnClick,
  onKeyDown: upstreamOnKeyDown,
  required = false,
  variant = 'simple',
  value: upstreamValue,
}: ListComponentProps): ReactElement {
  const id = useMemo(() => (upstreamId === undefined ? nanoid() : upstreamId), [upstreamId]);
  const { layout: formLayout } = useContext(FormFieldLayoutContext);
  const layout = reconcileLayout(upstreamLayout, formLayout);

  const setInitialValueFromProps = (): ListValueState => {
    return reconcileValueAndDefaultValue({
      value: upstreamValue,
      defaultValue,
    });
  };

  const [stateValue, setStateValue] = useState(setInitialValueFromProps);
  const value = useMemo(
    () => (upstreamValue ? listValueToState(upstreamValue) : stateValue),
    [upstreamValue, stateValue]
  );

  const onSelect: ListOnSelect = useCallback((uniqueKey: string, selected: boolean) => {
    setStateValue((prev) => {
      return { ...prev, [uniqueKey]: selected };
    });
  }, []);

  /**
   * Toggle whether or not a given item is selected.
   */
  function toggleItem(
    _: React.MouseEvent<HTMLUListElement, MouseEvent> | React.KeyboardEvent<HTMLUListElement>,
    key: string | null
  ): void {
    if (isUndefined(upstreamValue)) {
      setStateValue((prev) => {
        const newVal = toggleValue(prev, key);
        handleChange(newVal);
        return newVal;
      });
    } else {
      handleChange(toggleValue(value, key));
    }
  }

  function toggleValue(previousValue: ListValueState, key: string | null): ListValueState {
    if (typeof key === 'string') {
      return {
        ...previousValue,
        [key]: !previousValue[key],
      };
    } else {
      return previousValue;
    }
  }

  function handleChange(newValue?: ListValueState): void {
    const val = listStateToArray(newValue === undefined ? value : newValue);
    if (onChange) {
      onChange(new FakeEvent({ value: val, id: id }));
    }
  }

  function handleBlur(): void {
    if (onBlur) onBlur(new FakeEvent({ value: listStateToArray(value), id: id }));
  }

  const { onClick, onKeyDown } = useOnClickItem({
    disabled,
    onClickItem: toggleItem,
    onClick: upstreamOnClick,
    onKeyDown: upstreamOnKeyDown,
  });

  return (
    <ListContext.Provider
      value={{
        disabled,
        showDividers,
        error,
        id,
        layout,
        listValue: value,
        onSelect,
        variant,
      }}
    >
      {description && (
        <p {...classes({ element: 'description' })} id={`${id}-description`}>
          {description}
        </p>
      )}
      <fieldset
        {...classes({
          states: {
            disabled,
            error: error ? !disabled : false,
            required: required && !hideRequiredStyles && !disabled,
          },
          modifiers: {
            'required-styles-hidden': hideRequiredStyles,
            compact: layout === 'compact',
            medium: layout === 'medium',
          },
          extra: className,
        })}
        ref={forwardedRef}
        aria-describedby={description ? `${id}-description` : undefined}
        aria-labelledby={ariaLabelledBy}
        {...(required && { 'aria-required': 'true' })}
        {...(disabled && { 'aria-disabled': 'true' })}
        id={id}
      >
        <ul onClick={onClick} onBlur={handleBlur} onKeyDown={onKeyDown} {...classes({ extra: className })}>
          {children}
        </ul>
      </fieldset>
    </ListContext.Provider>
  );
}

const selectionListPropTypes: WeakValidationMap<ListComponentProps> = {
  'aria-labelledby': PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.arrayOf(PropTypes.string.isRequired),
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hideRequiredStyles: PropTypes.bool,
  id: PropTypes.string,
  layout: PropTypes.oneOf(['compact', 'medium']),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  showDividers: PropTypes.bool,
  variant: PropTypes.oneOf(['simple', 'selection']),
  value: PropTypes.arrayOf(PropTypes.string.isRequired),
};
List.propTypes = selectionListPropTypes;
List.displayName = 'List';

export default forwardRefToProps(List);
