import rawContents from "!!raw-loader!./FixedCenter.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function FixedCenter():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Fixed Center" />;
}

export default FixedCenter;
