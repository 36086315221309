import rawContents from "!!raw-loader!./ClickableRow.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ClickableRow():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Clickable Row" />;
}

export default ClickableRow;
