import { Heading } from '@athena/forge';
import { ReactElement } from 'react';
import { MetadataElement } from '../../contexts';
import { forgeVersionType } from '../../versionCheckHelper';

export interface RepoLinkProps {
  componentsMetadata: MetadataElement[];
}
export default function RepoLink({ componentsMetadata }: RepoLinkProps): ReactElement {
  const storybookLink = forgeVersionType() === 'new' ? 'go/forge-storybook' : 'go/forge-storybook-lts';

  return (
    <div className="cms-content">
      <Heading className="guide" headingTag="h3" variant="subsection" text={'Implementation links'} />
      {componentsMetadata.map(({ node: { displayName } }) => (
        <p key={displayName}>
          <a
            href={`https://bitbucket.athenahealth.com/projects/UXDS/repos/forge/browse/%40athena/forge/src/${displayName}`}
          >
            {displayName} directory in Bitbucket
          </a>
        </p>
      ))}
      <Heading className="guide" headingTag="h3" variant="subsection" text={'Implementation details'} />
      <p>
        It is strongly recommended to familiarize yourself with the Forge source code. While this documentation is a
        best effort to document the intent and usage of a component, sometimes some features only become clear when
        looking at the source code. Also, looking at {"Forge's"} source code may help identify and fix bugs in either
        your application or Forge itself.
      </p>
      <Heading className="guide" headingTag="h3" variant="subsection" text={'Storybook files'} />
      <p>
        Forge maintains at least one storybook file per component. While the primary audience for these files is
        typically the Forge team, these storybook files may cover usages of the component not covered by a demo. The
        storybook for the latest version of forge can be found at{' '}
        <a href={`http://${storybookLink}`}>{storybookLink}</a>.
      </p>
      <Heading className="guide" headingTag="h3" variant="subsection" text={'Testing library'} />
      <p>
        Forge strongly encourages using{' '}
        <a href="https://testing-library.com/docs/react-testing-library/intro">testing-library</a> to write tests for
        your application.
      </p>
      <blockquote cite="https://testing-library.com/docs/guiding-principles">
        <p>{'"The more your tests resemble the way your software is used, the more confidence they can give you."'}</p>
        <footer>
          {'- '}
          <a href="https://testing-library.com/docs/guiding-principles">
            https://testing-library.com/docs/guiding-principles
          </a>
        </footer>
      </blockquote>
      <p>
        If {"you're"} having trouble testing a Forge component using testing-library, it would be a good idea to see how
        Forge tests its own components. For the most part, Forge tries to use{' '}
        <a href="https://testing-library.com/docs/queries/byrole/">screen.getByRole</a> as much as it can, as that API
        provides the best feedback on <a href="https://www.a11yproject.com/">a11y</a> compliance. Forge discourages the
        use of{' '}
        <a href="https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelector">document.querySelector</a> and{' '}
        <a href="https://testing-library.com/docs/queries/bytestid/">screen.getByTestId</a> as both APIs encourage using
        implementation details to test your component, and discourage adding roles to your component.
      </p>
      <p>
        With that being said, many of {"Forge's"} components were not built with accessibility in mind. These components
        do break the recommendations listed above.
      </p>
    </div>
  );
}
