import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgImageLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 3H23V18C23 18.7956 22.6839 19.5587 22.1213 20.1213C21.5587 20.6839 20.7956 21 20 21H1V3Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M7 10.76C8.38071 10.76 9.5 9.66764 9.5 8.32007C9.5 6.97249 8.38071 5.87988 7 5.87988C5.61929 5.87988 4.5 6.97249 4.5 8.32007C4.5 9.66764 5.61929 10.76 7 10.76Z"
      fill={props.color}
    />
    <path
      d="M0.5 15.36V20.4999H20.65C23.01 20.1499 23.7199 18.1599 23.4399 15.1799L17.53 10.1C17.0861 9.70325 16.5085 9.4888 15.9132 9.49992C15.3179 9.51105 14.7488 9.74675 14.3199 10.1598L9 15.1598L6.84998 13.0199C6.43879 12.6016 5.88706 12.3504 5.30151 12.3154C4.71596 12.2803 4.13827 12.4638 3.68005 12.83L0.5 15.36Z"
      fill={props.color}
    />
  </svg>
);
SvgImageLarge.displayName = 'SvgImageLarge';
const ImageLarge = withForgeIconProps(SvgImageLarge, {
  name: 'Image',
  usage: 'Use to indicate a photo, or the ability to add an image',
  related: 'photograph, picture, camera, snapshot, screenshot',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ImageLarge;
