import rawContents from "!!raw-loader!./InlineAlertBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function InlineAlertBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Inline Alert Basic" />;
}

export default InlineAlertBasic;
