import rawContents from "!!raw-loader!./SegmentedButtonSeparated.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function SegmentedButtonSeparated():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Segmented Button Separated" />;
}

export default SegmentedButtonSeparated;
