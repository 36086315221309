import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCameraLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.58 5.77047V4.00045C16.5644 3.7228 16.4405 3.46242 16.2347 3.27536C16.029 3.08829 15.7579 2.98953 15.48 3.00045H8.47998C8.2021 2.98953 7.93111 3.08829 7.72534 3.27536C7.51957 3.46242 7.39553 3.7228 7.38 4.00045V5.78048H1V21.0005H20C20.7956 21.0005 21.5587 20.6844 22.1213 20.1218C22.6839 19.5592 23 18.7961 23 18.0005V5.77047H16.58Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M12 17.0005C14.2091 17.0005 16 15.2096 16 13.0005C16 10.7913 14.2091 9.00049 12 9.00049C9.79086 9.00049 8 10.7913 8 13.0005C8 15.2096 9.79086 17.0005 12 17.0005Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgCameraLarge.displayName = 'SvgCameraLarge';
const CameraLarge = withForgeIconProps(SvgCameraLarge, {
  name: 'Camera',
  usage: 'Use to open a camera application',
  related: 'image, photo, capture, picture, take picture',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default CameraLarge;
