import { ReactElement, useEffect, useRef } from 'react';
import { stateClasses } from '../../utils/classes';
import { classesAsStr } from '../Select';
import { customIsOptionDisabled } from '../utils/selectUtils';
import { StructuredOption } from '../Select';
import Tag, { TagProps } from '../../Tag';
import { GroupBase, MultiValueProps } from 'react-select';
import { useSelectContext } from '../context/SelectContext';

const TAG_SIZE_MAP = {
  small: 'small',
  medium: 'medium',
  large: 'medium',
  'x-large': 'large',
} as const;

const CustomMultiValue = <Option extends StructuredOption, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: MultiValueProps<Option, IsMulti, Group>
): ReactElement => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { handleFocusedMultiValueData, size } = useSelectContext();

  const { data, isFocused, removeProps, selectProps } = props;

  const { color, textVariant } = data;
  const tagSize = TAG_SIZE_MAP[size];

  /**
   * Working around the lifecycle of React Select
   *
   * Setting ref during component render doesn't capture updated data as expected
   * But, as long as we allow React Select to finish drawing, the data will have settled and we can reliably make our update
   */
  useEffect(() => {
    if (handleFocusedMultiValueData) handleFocusedMultiValueData(data, isFocused);
  }, [handleFocusedMultiValueData, isFocused, data]);

  const handleOnRemove: TagProps['onRemove'] = (event): void => {
    if (customIsOptionDisabled(data)) return;
    if (removeProps.onClick) {
      // Type assertion is unfortunate, but MouseEvent for HTMLDivElement and HTMLButtonElement
      // are probably close enough to not matter too much.
      removeProps.onClick(event as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>);
    }
  };

  const classes = `${stateClasses({ focused: isFocused })} ${classesAsStr({ element: 'multivalue' })}`;

  return (
    <Tag
      className={classes}
      text={selectProps.getOptionLabel(data)}
      onRemove={handleOnRemove}
      tabIndex={-1}
      isRemovable={!customIsOptionDisabled(data)}
      size={tagSize}
      textVariant={textVariant}
      color={color}
      buttonRef={buttonRef}
      onMouseDown={(event) => {
        /*
          in order to prevent focusing the parent select when removing a tag
          stop the mouse down event from bubbling when it comes from the close button
          */

        if (buttonRef.current?.contains(event.target as Node)) {
          event.preventDefault();
          event.stopPropagation();
        }
      }}
    />
  );
};

export default CustomMultiValue;
