import React, { useState, ReactElement, useEffect, useRef, useCallback, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';
import ToastManager from '../ToastManager';
import ToastTemplate from '../ToastTemplate';
import { Theme } from '../Root/context';
import { themeContextPropTypes } from '../Root/ThemeContext';
import Portal from '../Portal';

export type ToastAlertTypes = 'attention' | 'info' | 'new' | 'success';
const TRANSITION_TIMES = {
  defaultAutoDismiss: 7000,
  enter: 300,
  exit: {
    slow: 3000,
    fast: 200,
  },
};

const defaultManager = new ToastManager();

export interface GenericToastManager {
  register: (key: string, onEject: (key: string) => Promise<void>) => Promise<boolean>;
  unregister: (key: string) => void;
}

export interface ToastAction {
  label: string;
  labelForScreenReader?: string | null;
  onAction: () => void;
}

export interface ToastProps {
  action?: ToastAction;
  alertType?: ToastAlertTypes;
  children?: React.ReactNode;
  className?: string;
  headerText: string;
  id: string;
  manager?: GenericToastManager;
  onDismiss?: () => void;
  onNeverShown?: () => void;
  onShow?: () => void;
  theme?: Theme;
}

function Toast({
  action,
  alertType = 'info',
  id,
  manager = defaultManager,
  onDismiss,
  onNeverShown,
  onShow,
  ...passThroughProps
}: ToastProps): ReactElement {
  const [fading, setFading] = useState(false);
  const prevId = useRef(id);
  const [visible, setVisible] = useState(false);
  const dismissed = useRef(false);
  const registered = useRef(false);

  const toastRef = useRef<HTMLDivElement>(null);

  const resolveOnDismissed = useRef<() => void>();
  const endSlowFade = useRef<() => void>();
  const autoDismissTimeout = useRef<number>();

  const startDismiss = useCallback((): void => {
    dismissed.current = true;
    setVisible(false);
  }, []);

  // Handle the manager telling the toast it needs to exit
  const handleEject = useCallback(
    (key: string): Promise<void> => {
      if (key === id) {
        if (registered.current) {
          startDismiss();
          return new Promise((resolve) => (resolveOnDismissed.current = resolve));
        } else {
          // getting ejected before it finished registering
          dismissed.current = true;
          return Promise.resolve();
        }
      } else {
        // toast was reset since this ejection because of id change
        return Promise.resolve();
      }
    },
    [id, startDismiss]
  );

  const setAutoDismissTimeout = useCallback((delay: number): void => {
    autoDismissTimeout.current = window.setTimeout(() => {
      setFading(true);
    }, delay);
  }, []);

  const unregister = useCallback((): void => {
    manager.unregister(id);
    registered.current = false;
  }, [id, manager]);

  const register = useCallback(async (): Promise<void> => {
    const isRegistered = await manager.register(id, handleEject);
    // It was already ejected. Don't display.
    if (!isRegistered || dismissed.current) {
      onNeverShown && onNeverShown();
    } else {
      registered.current = true;
      setAutoDismissTimeout(TRANSITION_TIMES.defaultAutoDismiss);
      setVisible(true);
    }
  }, [handleEject, id, manager, onNeverShown, setAutoDismissTimeout]);
  useEffect(() => {
    register();
    return () => {
      clearTimeout(autoDismissTimeout.current);
      unregister();
    };
  }, [register, unregister]);

  // If the id changed, update the manager and clean up the old toast
  useEffect(() => {
    if (id !== prevId.current) {
      manager.unregister(prevId.current);
      registered.current = false;
      dismissed.current = false;
      resolveOnDismissed.current = undefined;
      clearTimeout(autoDismissTimeout.current);
      register();

      // If the id changed, this is a new toast, so reset state
      prevId.current = id;
      setVisible(false);
      setFading(false);
    }
  }, [id, manager, register]);

  // Notify the manager that the toast is dismissed
  const finishDismiss = useCallback((): void => {
    if (resolveOnDismissed.current) {
      // on eject
      resolveOnDismissed.current();
      resolveOnDismissed.current = undefined;
    } else {
      // on timeout or user dismissal
      unregister();
    }

    onDismiss && onDismiss();

    // Terminate the slow fade
    if (endSlowFade.current) {
      endSlowFade.current();
      endSlowFade.current = undefined;
    }
  }, [onDismiss, unregister]);

  // cancel timeout and slow fade on hover
  const handleMouseEnter = useCallback((): void => {
    clearTimeout(autoDismissTimeout.current);
    setFading(false);
  }, []);

  // restore timeout on hover end
  const handleMouseLeave = useCallback((): void => {
    if (visible) {
      setAutoDismissTimeout(TRANSITION_TIMES.defaultAutoDismiss);
    }
  }, [setAutoDismissTimeout, visible]);

  return (
    // Outer transition for slide in and fast fade out on dismiss
    // Inner transition for auto-dismiss slow fade
    <CSSTransition
      nodeRef={toastRef}
      appear={true}
      key={id}
      in={visible}
      timeout={{
        appear: TRANSITION_TIMES.enter,
        enter: TRANSITION_TIMES.enter,
        exit: TRANSITION_TIMES.exit.fast,
      }}
      classNames={{
        appear: 'fe_is-enter',
        enter: 'fe_is-enter',
        enterActive: 'fe_is-enter-active',
        exit: 'fe_is-exit-fast',
        exitActive: 'fe_is-exit-active',
      }}
      onEntered={() => onShow && onShow()} // Calling onShow directly sometimes prevents the animation
      onExited={finishDismiss}
      mountOnEnter
      unmountOnExit
    >
      <CSSTransition
        in={!fading}
        timeout={{
          exit: TRANSITION_TIMES.exit.slow,
        }}
        classNames={{
          exit: `fe_is-exit-slow`,
        }}
        addEndListener={(done: () => void) => {
          endSlowFade.current = done;
        }}
        onExited={() => visible && startDismiss()}
        nodeRef={toastRef}
      >
        <Portal>
          <ToastTemplate
            action={action}
            alertType={alertType}
            id={id}
            onDismiss={onDismiss}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={toastRef}
            {...passThroughProps}
          />
        </Portal>
      </CSSTransition>
    </CSSTransition>
  );
}

export const toastPropTypes: WeakValidationMap<ToastProps & { '...rest': unknown }> = {
  /**
   * Identifies individual toasts and differentiates them.
   * If two toasts reuse the same id, the second one might not get displayed.
   */
  id: PropTypes.string.isRequired,

  /** Adds an action button to the toast */
  action: PropTypes.shape({
    onAction: PropTypes.func.isRequired,
    labelForScreenReader: PropTypes.string,
    label: PropTypes.string.isRequired,
  }),

  /** Gives a default left border and header with icon that corresponds to status level */
  alertType: PropTypes.oneOf<ToastAlertTypes>(['attention', 'info', 'new', 'success']),

  /** Adds a class to the root element of the component (first div beneath the portal) */
  className: PropTypes.string,

  /** Title text displayed at the top of the modal */
  headerText: PropTypes.string.isRequired,

  /** Manages which toast is shown at a given time. Find more props info in the [ToastManager source](https://bitbucket.athenahealth.com/projects/UXDS/repos/forge/browse/@athena/forge/src/ToastManager/ToastManager.ts) */
  manager: PropTypes.shape({
    register: PropTypes.func.isRequired,
    unregister: PropTypes.func.isRequired,
  }),

  /** Called when a toast is dismissed either manually or automatically */
  onDismiss: PropTypes.func,

  /** Called when a toast is "dismissed" without ever being shown */
  onNeverShown: PropTypes.func,

  /** Function called when the toast is shown */
  onShow: PropTypes.func,

  /** Theme variables for configuring an instance of Forge. See Root component for more details. */
  theme: themeContextPropTypes,

  /** Passthrough props are assigned to the current `ToastTemplate` */
  '...rest': PropTypes.any,
};
Toast.propTypes = toastPropTypes;

export default Toast;
