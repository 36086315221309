import { Environment, UseSelectStateChange, useSelect } from 'downshift';
import PropTypes from 'prop-types';
import React, {
  JSXElementConstructor,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
  WeakValidationMap,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import MenuItem, { MenuItemProps } from '../MenuItem';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';
import { SegmentedButtonContext } from '../SegmentedButton/SegmentedButtonContext';
import MenuTriggerContext, { MenuTriggerContextData, MenuTriggerToggleButtonProps } from './MenuTriggerContext';

const classes = forgeClassHelper('menu');

/** Option's types for aria-role and className  */
type RoleType = 'option' | 'title' | 'divider' | undefined;
/** Return the desired aria-role for the option's type. */
function getRole(type: RoleType): string {
  switch (type) {
    case 'option':
    case undefined: // Since option is the default, undefined passed into this represents an option.
      return 'option';
    case 'title':
      return 'heading';
    default:
      return '';
  }
}

export type MenuChanges = UseSelectStateChange<ReactNode>;

export type MenuSize = 'small' | 'medium' | 'large' | 'x-large';

export interface MenuProps {
  /** CSS class applied to the Menu's root element */
  className?: string;

  /** Array of items to display in the menu */
  children: ReactNode[];

  /** Ref to the root div */
  ref?: Ref<HTMLDivElement>;

  /** Set to true to hide the label  */
  hideLabel?: boolean;

  /** Opens or closes the menu */
  isOpen?: boolean;

  /** Input label. Required for accessibility reasons, but can be hidden via `hideLabel` */
  label: string;

  /** Callback fired when the menu opens or closes. Read from `changes.isOpen`
   * when updating the `isOpen` prop. */
  onIsOpenChange?: (changes: MenuChanges) => void;

  /** Button component used to toggle opening/closing the menu */
  trigger: ReactNode;

  /** Specify the Menu trigger's size */
  size?: MenuSize;
}

interface MenuComponentProps extends MenuProps {
  /** Ref to the root div */
  forwardedRef?: Ref<HTMLDivElement>;
}

/** Selection menu for navigation and displaying additional actions.  */
const Menu = ({
  children,
  className,
  forwardedRef,
  hideLabel: upstreamHideLabel,
  isOpen: upstreamIsOpen,
  label,
  onIsOpenChange,
  size = 'large',
  trigger,
}: MenuComponentProps): ReactElement => {
  const [environment, setEnvironment] = useState<Environment | undefined>();
  const { childRole } = useContext(SegmentedButtonContext);
  const isInSegmentedButton = childRole !== undefined;
  const hideLabel = upstreamHideLabel || isInSegmentedButton;

  /**
   * Initializes the component's environment and event listeners when mounted to the DOM.
   * This setup is required for the useSelect hook from Downshift to function properly,
   * specifically to fix an issue where MenuItem clicks are not detected when rendered in a Shadow DOM.
   */
  const onMount = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        if (typeof forwardedRef === 'function') {
          forwardedRef(node);
        } else if (forwardedRef) {
          (forwardedRef as MutableRefObject<HTMLDivElement | null>).current = node;
        }
        const rootNode = node.getRootNode();
        setEnvironment({
          addEventListener: rootNode.addEventListener.bind(rootNode),
          removeEventListener: rootNode.removeEventListener.bind(rootNode),
          document,
          Node: window.Node,
        });
      }
    },
    [forwardedRef]
  );

  const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } =
    useSelect<ReactNode>({
      isItemDisabled: (item) => {
        if (React.isValidElement(item)) {
          // Since type="option" is default, undefined === "option" too.
          return item.props.type === 'divider' || item.props.type === 'title';
        } else {
          return false;
        }
      },
      isOpen: upstreamIsOpen,
      onIsOpenChange: onIsOpenChange,
      items: children,
      environment: environment,
    });

  const Trigger = useMemo(() => {
    const triggerProps: MenuTriggerToggleButtonProps = getToggleButtonProps();
    const contextProps: MenuTriggerContextData = {
      triggerProps,
      size,
    };
    return <MenuTriggerContext.Provider value={contextProps}>{trigger}</MenuTriggerContext.Provider>;
  }, [trigger, size, getToggleButtonProps]);

  /** Determines which children are MenuItems.
   *
   * Disregards non-element children such as `false`, `undefined` or `null`, which may be
   * artifacts of application code determining if a MenuItem should be present or not.
   */
  const menuItemChildren = useMemo(() => {
    const childrenWithIndex = React.Children.map(children, (child, index) => ({ child, index })) || [];
    /** Filter out values such as `false` or `null` */
    return childrenWithIndex.filter(({ child }) => React.isValidElement(child)) as {
      child: ReactElement<Partial<MenuItemProps>, JSXElementConstructor<Partial<MenuItemProps>>>;
      index: number;
    }[];
  }, [children]);

  /** Rendered MenuItem
   *
   * Provides each child Menu Item to let them know whether they should be visible.
   */
  const renderedMenuItem = useMemo(() => {
    return menuItemChildren.map(({ child: item, index }) => {
      const { children, className, ...rest } = item.props;
      return (
        <li
          {...classes({
            element: 'li',
            states: {
              active: (item.props.type === 'option' || item.props.type === undefined) && highlightedIndex === index,
            },
            // Assigning className prop to li as outlined in the MenuItem docs
            extra: className,
          })}
          key={`${index}`}
          {...getItemProps({
            item,
            index,
            role: getRole(item.props.type),
          })}
        >
          <MenuItem {...rest}>{children}</MenuItem>
        </li>
      );
    });
  }, [getItemProps, highlightedIndex, menuItemChildren]);

  return (
    <div {...classes()} ref={onMount}>
      <label className={hideLabel ? 'fe_u_visually-hidden' : ''} {...getLabelProps()}>
        {label}
      </label>
      {Trigger}
      <ul
        {...getMenuProps()}
        {...classes({ element: 'ul', extra: !isOpen ? `${className} fe_u_visually-hidden` : className })}
      >
        {isOpen && renderedMenuItem}
      </ul>
    </div>
  );
};

const menuPropTypes: WeakValidationMap<MenuProps & { '...rest': unknown }> = {
  className: PropTypes.string,
  children: PropTypes.any,
  hideLabel: PropTypes.bool,
  isOpen: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onIsOpenChange: PropTypes.func,
  trigger: PropTypes.any.isRequired,
  size: PropTypes.oneOf<MenuSize>(['small', 'medium', 'large', 'x-large']),
};

Menu.propTypes = menuPropTypes;
Menu.displayName = 'Menu';

export default forwardRefToProps(Menu);
