import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgListBulletedLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M23 5H7M23 19H7M23 12H7"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M1.5 6.5C2.32843 6.5 3 5.82843 3 5C3 4.17157 2.32843 3.5 1.5 3.5C0.671573 3.5 0 4.17157 0 5C0 5.82843 0.671573 6.5 1.5 6.5Z"
      fill={props.color}
    />
    <path
      d="M1.5 13.5C2.32843 13.5 3 12.8284 3 12C3 11.1716 2.32843 10.5 1.5 10.5C0.671573 10.5 0 11.1716 0 12C0 12.8284 0.671573 13.5 1.5 13.5Z"
      fill={props.color}
    />
    <path
      d="M1.5 20.5C2.32843 20.5 3 19.8284 3 19C3 18.1716 2.32843 17.5 1.5 17.5C0.671573 17.5 0 18.1716 0 19C0 19.8284 0.671573 20.5 1.5 20.5Z"
      fill={props.color}
    />
  </svg>
);
SvgListBulletedLarge.displayName = 'SvgListBulletedLarge';
const ListBulletedLarge = withForgeIconProps(SvgListBulletedLarge, {
  name: 'ListBulleted',
  usage:
    'Use to indicate a list, or the ability to switch to a list view or add list formatting',
  related: 'table, grid, bullet, text editor',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ListBulletedLarge;
