import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClockLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.75 6.5C12.75 5.94772 12.3023 5.5 11.75 5.5C11.1977 5.5 10.75 5.94772 10.75 6.5H12.75ZM11.75 13.5H10.75C10.75 14.0523 11.1977 14.5 11.75 14.5V13.5ZM16.25 14.5C16.8023 14.5 17.25 14.0523 17.25 13.5C17.25 12.9477 16.8023 12.5 16.25 12.5V14.5ZM22 12C22 17.5228 17.5228 22 12 22V24C18.6274 24 24 18.6274 24 12H22ZM12 22C6.47715 22 2 17.5228 2 12H0C0 18.6274 5.37258 24 12 24V22ZM2 12C2 6.47715 6.47715 2 12 2V0C5.37258 0 0 5.37258 0 12H2ZM12 2C17.5228 2 22 6.47715 22 12H24C24 5.37258 18.6274 0 12 0V2ZM10.75 6.5V13.5H12.75V6.5H10.75ZM11.75 14.5H16.25V12.5H11.75V14.5Z"
      fill={props.color}
    />
  </svg>
);
SvgClockLarge.displayName = 'SvgClockLarge';
const ClockLarge = withForgeIconProps(SvgClockLarge, {
  name: 'Clock',
  usage:
    'Use to indicate when an event occurred, or to enter or edit time-related information',
  related: 'duration, time, timer, hour, minute, second,',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ClockLarge;
