import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMapLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.9506 11C13.6074 11 14.9506 9.65685 14.9506 8C14.9506 6.34315 13.6074 5 11.9506 5C10.2937 5 8.95056 6.34315 8.95056 8C8.95056 9.65685 10.2937 11 11.9506 11Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0003 1C15.884 1 19 4.43995 19 8.69995C19 13.99 13.4554 21 12.0003 23C10.4448 21 5.00059 13.51 5.00059 8.69995C4.95042 4.43995 8.11658 1 12.0003 1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgMapLarge.displayName = 'SvgMapLarge';
const MapLarge = withForgeIconProps(SvgMapLarge, {
  name: 'Map',
  usage:
    'Use to indicate or add location information, like an address or a map',
  related: 'pin, directions',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default MapLarge;
