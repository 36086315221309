import React, { ReactElement, Ref, useContext, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import GridRow from '../GridRow';
import GridCol from '../GridCol';
import { FormContext, FormContextData } from '../Form/FormContext';
import { FormFieldLayoutContext } from './FormFieldLayoutContext';
import { FormLayout, FormRequiredVariation } from '../Form';
import forwardRefToProps from '../utils/forwardRefToProps';

export interface FormFieldLayoutBaseProps<UseFieldset extends boolean> {
  /** Adds a class to the root element of the component */
  className?: string;
  /** Element that serves as the input in the form field. */
  inputSlot?: React.ReactNode;
  /** Adds a modifier to the input slot */
  inputExtras?: string;
  /** Makes label always go above input. Overrides labelWidth. */
  labelAlwaysAbove?: boolean;
  /** Element that serves as the label in the form field. */
  labelSlot?: React.ReactNode;
  /** Width of the label in grid columns. Defaults to 3/12. */
  labelWidth?: number;
  /** Element that serves as the message in the form field. */
  messageSlot?: React.ReactNode;
  /** Changes the way that required fields are indicated. */
  requiredVariation?: FormRequiredVariation;
  /** Adds a modifier to the status slot */
  statusExtras?: string;
  /**
   * If true, form row removes standard grid row outer margin.
   * Set to false if the form row is at the top level of page layout.
   * Defaults to true.
   */
  nested?: boolean;
  /** Ref forwarded to the top-level DOM element */
  ref?: UseFieldset extends true ? Ref<HTMLFieldSetElement> : Ref<HTMLDivElement>;
  /** Element that serves as the status indicator in the form field. */
  statusIndicatorSlot?: React.ReactNode;
  /**
   * Affects the vertical space that the field occupies. Defaults to 'medium'.
   * If in a Form, overrides the 'layout' set at the Form level for this field.
   */
  layout?: FormLayout;
  /**
   * Changes the outer element from a `<div>` to a `<fieldset>` for use with form components that include multiple
   * inputs such as SelectionList or MultiField. When using this, be sure to set the `useLegend` prop to true on the
   * Label component you pass to `inputSlot`.
   **/
  useFieldset?: UseFieldset;
}

export type FormFieldLayoutForwardedProps<UseFieldset extends boolean> = UseFieldset extends true
  ? React.FieldsetHTMLAttributes<HTMLFieldSetElement>
  : React.HTMLAttributes<HTMLDivElement>;

const classes = forgeClassHelper('form-field');

type FormFieldWrapperProps<UseFieldset extends boolean> = FormFieldLayoutForwardedProps<UseFieldset> & {
  /** Ref forwarded to the top-level DOM element */
  forwardedRef?: UseFieldset extends true ? Ref<HTMLFieldSetElement> : Ref<HTMLDivElement>;
  /**
   * Changes the outer element from a `<div>` to a `<fieldset>` for use with form components that include multiple
   * inputs such as SelectionList or MultiField. When using this, be sure to set the `useLegend` prop to true on the
   * Label component you pass to `inputSlot`.
   **/
  useFieldset: UseFieldset;
};
const FormFieldWrapper = <UseFieldset extends boolean>({
  forwardedRef,
  useFieldset,
  ...passthroughProps
}: FormFieldWrapperProps<UseFieldset>): ReactElement => {
  if (useFieldset) {
    return (
      <fieldset
        ref={forwardedRef as Ref<HTMLFieldSetElement>}
        {...(passthroughProps as React.FieldsetHTMLAttributes<HTMLFieldSetElement>)}
      />
    );
  } else {
    return (
      <div ref={forwardedRef as Ref<HTMLDivElement>} {...(passthroughProps as React.HTMLAttributes<HTMLDivElement>)} />
    );
  }
};

export type FormFieldLayoutProps<UseFieldset extends boolean = false> = FormFieldLayoutBaseProps<UseFieldset> &
  FormFieldLayoutForwardedProps<UseFieldset>;
type FormFieldLayoutComponentProps<UseFieldset extends boolean> = FormFieldLayoutProps<UseFieldset> & {
  /** Ref forwarded to the top-level DOM element */
  forwardedRef?: UseFieldset extends true ? Ref<HTMLFieldSetElement> : Ref<HTMLDivElement>;
};

/**
 * FormFieldLayout
 *
 * A component to render an unordered list item.
 * @documentedBy FormField
 */
const FormFieldLayout = <UseFieldset extends boolean>({
  className,
  inputExtras,
  inputSlot,
  labelAlwaysAbove,
  labelSlot,
  labelWidth,
  layout,
  messageSlot,
  nested,
  requiredVariation,
  statusIndicatorSlot,
  statusExtras,
  useFieldset = false as UseFieldset,
  ...rootProps
}: FormFieldLayoutComponentProps<UseFieldset>): ReactElement => {
  const formContext = useContext(FormContext);

  const propsContext = Object.keys(formContext).reduce(
    /** Delete the keys containing undefined values so that
     * the spread operator doesn't override defined values in formContext.
     */
    (contexts, key) => {
      const typedKey = key as keyof FormContextData;
      if (contexts[typedKey] === undefined) {
        delete contexts[typedKey];
      }
      return contexts;
    },
    {
      labelWidth,
      labelAlwaysAbove,
      layout,
      nested,
      requiredVariation,
    } as FormContextData
  );
  const context = {
    ...formContext,
    ...propsContext,
  };

  /** Need to aggressively type assert because Typescript gets confused with
   * generic component props when constructed from another generic component.
   */
  const formFieldWrapperProps = {
    ...(rootProps as FormFieldLayoutForwardedProps<UseFieldset>),
    ...classes({
      modifiers: context.layout,
      extra: className,
    }),
    useFieldset,
  } as JSX.IntrinsicAttributes & FormFieldWrapperProps<UseFieldset>;

  return (
    <FormFieldLayoutContext.Provider value={context}>
      <FormFieldWrapper<UseFieldset> {...formFieldWrapperProps}>
        <GridRow nested={context.nested}>
          {context.labelWidth > 0 && (
            <GridCol
              width={{ small: 12, medium: context.labelAlwaysAbove ? 12 : context.labelWidth }}
              {...classes({
                element: 'label',
                modifiers: { 'forced-above': context.labelAlwaysAbove },
              })}
            >
              {labelSlot}
            </GridCol>
          )}
          <GridCol
            width={{ small: 12, medium: 12 - context.labelWidth }}
            {...classes({ element: 'right', modifiers: { 'label-forced-above': context.labelAlwaysAbove } })}
          >
            <div {...classes({ element: 'input-slot', extra: inputExtras })}>
              {inputSlot}
              {messageSlot && <div {...classes('message')}>{messageSlot}</div>}
            </div>
            <div {...classes({ element: 'status', extra: statusExtras })}>{statusIndicatorSlot}</div>
          </GridCol>
        </GridRow>
      </FormFieldWrapper>
    </FormFieldLayoutContext.Provider>
  );
};

const formFieldLayoutPropTypes: WeakValidationMap<FormFieldLayoutProps<boolean> & { '...rest': unknown }> = {
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Element that serves as the input in the form field. */
  inputSlot: PropTypes.node,
  /** Adds a modifier to the input slot */
  inputExtras: PropTypes.string,
  /** Makes label always go above input. Overrides labelWidth. */
  labelAlwaysAbove: PropTypes.bool,
  /** Element that serves as the label in the form field. */
  labelSlot: PropTypes.node,
  /** Width of the label in grid columns. Defaults to 3/12. */
  labelWidth: PropTypes.number,
  /** Element that serves as the message in the form field. */
  messageSlot: PropTypes.node,
  /** Changes the way that required fields are indicated. */
  requiredVariation: PropTypes.oneOf(['blueBarWithRequiredLabel', 'blueBarWithLegend', 'allFieldsRequired']),
  /** Adds a modifier to the status slot */
  statusExtras: PropTypes.string,
  /**
   * If true, form row removes standard grid row outer margin.
   * Set to false if the form row is at the top level of page layout.
   * Defaults to true.
   */
  nested: PropTypes.bool,
  /** Element that serves as the status indicator in the form field. */
  statusIndicatorSlot: PropTypes.node,
  /**
   * Affects the vertical space that the field occupies. Defaults to 'medium'.
   * If in a Form, overrides the 'layout' set at the Form level for this field.
   */
  layout: PropTypes.oneOf(['compact', 'medium', 'large', 'super-compact']),
  /**
   * Changes the outer element from a `<div>` to a `<fieldset>` for use with form components that include multiple
   * inputs such as SelectionList or MultiField. When using this, be sure to set the `useLegend` prop to true on the
   * Label component you pass to `inputSlot`.
   **/
  useFieldset: PropTypes.bool,
  /**
   * Passthrough props to the top-level DOM element.
   */
  '...rest': PropTypes.any,
};
FormFieldLayout.propTypes = formFieldLayoutPropTypes;

FormFieldLayout.displayName = 'FormFieldLayout';

export default forwardRefToProps(FormFieldLayout) as <UseFieldset extends boolean = false>(
  props: FormFieldLayoutProps<UseFieldset>
) => ReactElement;
