import Headings from './Headings.gen';
import HeadingsWithDescriptions from './HeadingsWithDescriptions.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <Headings />
      <HeadingsWithDescriptions />
    </>
  );
}
