import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStopScreenShareFilledLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7243 2.30988C17.3435 1.90987 16.7105 1.89431 16.3105 2.27512C15.9105 2.65593 15.8949 3.2889 16.2757 3.68891L18.4753 5.99939L16.2757 8.30988C15.8949 8.70988 15.9105 9.34286 16.3105 9.72366C16.7105 10.1045 17.3435 10.0889 17.7243 9.68891L19.8899 7.41415L22.3083 9.72274C22.7078 10.1041 23.3408 10.0894 23.7221 9.68988C24.1035 9.29038 24.0888 8.65739 23.6893 8.27605L21.3043 5.99939L23.6893 3.72274C24.0888 3.34139 24.1035 2.7084 23.7221 2.30891C23.3408 1.90941 22.7078 1.8947 22.3083 2.27605L19.8899 4.58463L17.7243 2.30988ZM15.2673 1.99939H12H0V16.9994L11 16.9994V19.9994H7C6.44772 19.9994 6 20.4471 6 20.9994C6 21.5517 6.44772 21.9994 7 21.9994H12H17C17.5523 21.9994 18 21.5517 18 20.9994C18 20.4471 17.5523 19.9994 17 19.9994H13V16.9994L21 16.9994C22.6569 16.9994 24 15.6562 24 13.9994V10.7313C23.2505 11.1651 22.2758 11.0742 21.6178 10.4461L19.9237 8.82891L18.4485 10.3784C17.6869 11.1784 16.421 11.2096 15.621 10.4479C14.821 9.68632 14.7898 8.42037 15.5515 7.62036L17.0946 5.99939L15.5515 4.37842C14.9252 3.72063 14.8349 2.74784 15.2673 1.99939Z"
      fill={props.color}
    />
  </svg>
);
SvgStopScreenShareFilledLarge.displayName = 'SvgStopScreenShareFilledLarge';
const StopScreenShareFilledLarge = withForgeIconProps(
  SvgStopScreenShareFilledLarge,
  {
    name: 'StopScreenShareFilled',
    usage: 'Use as active state to stop screenshare',
    related: 'Desktop',
    isLabelRequired: true,
    isNeutralInteractive: false,
    size: 'large',
  }
);
export default StopScreenShareFilledLarge;
