import rawContents from "!!raw-loader!./CheckableControlled.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function CheckableControlled():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Checkable Controlled" />;
}

export default CheckableControlled;
