import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLabsSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.84997 6L6.70873 6.51238C6.80117 6.35746 6.84997 6.18041 6.84997 6H5.84997ZM5.84997 1V0C5.29769 0 4.84997 0.447715 4.84997 1L5.84997 1ZM10.15 1H11.15C11.15 0.447715 10.7023 0 10.15 0V1ZM10.15 6H9.15002C9.15002 6.18041 9.19882 6.35746 9.29126 6.51238L10.15 6ZM13.73 12L14.5946 11.4975L14.5887 11.4876L13.73 12ZM11.86 15L11.9304 14.0025C11.907 14.0008 11.8835 14 11.86 14L11.86 15ZM4.14001 15V14C4.11652 14 4.09303 14.0008 4.0696 14.0025L4.14001 15ZM2.27001 12L1.4112 11.4876L1.40543 11.4975L2.27001 12ZM6.84997 6V1H4.84997V6H6.84997ZM5.84997 2H10.15V0H5.84997V2ZM9.15002 1V6H11.15V1H9.15002ZM9.29126 6.51238L12.8712 12.5124L14.5887 11.4876L11.0088 5.48762L9.29126 6.51238ZM12.8654 12.5025C12.9568 12.6597 13.0035 12.8388 13.0007 13.0204L15.0005 13.0514C15.0089 12.5061 14.8685 11.9689 14.5946 11.4975L12.8654 12.5025ZM13.0007 13.0204C12.9979 13.2022 12.9456 13.3798 12.8495 13.534L14.5467 14.592C14.8352 14.1292 14.9921 13.5965 15.0005 13.0514L13.0007 13.0204ZM12.8495 13.534C12.7533 13.6882 12.6169 13.8134 12.455 13.896L13.3634 15.6778C13.8491 15.4301 14.2583 15.0547 14.5467 14.592L12.8495 13.534ZM12.455 13.896C12.2932 13.9784 12.1119 14.0153 11.9304 14.0025L11.7896 15.9975C12.3333 16.0359 12.8774 15.9255 13.3634 15.6778L12.455 13.896ZM11.86 14H4.14001V16H11.86V14ZM4.0696 14.0025C3.88812 14.0153 3.70678 13.9784 3.54501 13.896L2.63663 15.6778C3.12256 15.9255 3.66667 16.0359 4.21042 15.9975L4.0696 14.0025ZM3.54501 13.896C3.38305 13.8134 3.24664 13.6882 3.15051 13.534L1.45324 14.592C1.7417 15.0547 2.15086 15.4301 2.63663 15.6778L3.54501 13.896ZM3.15051 13.534C3.05438 13.3798 3.00208 13.2022 2.99927 13.0204L0.999504 13.0514C1.00794 13.5965 1.16478 14.1292 1.45324 14.592L3.15051 13.534ZM2.99927 13.0204C2.99646 12.8388 3.04323 12.6597 3.1346 12.5025L1.40543 11.4975C1.13148 11.9689 0.99107 12.5061 0.999504 13.0514L2.99927 13.0204ZM3.12877 12.5124L6.70873 6.51238L4.99121 5.48762L1.41126 11.4876L3.12877 12.5124ZM12 8H4V10H12V8Z"
      fill={props.color}
    />
    <path
      d="M10.5 12.5C11.0523 12.5 11.5 12.0523 11.5 11.5C11.5 10.9477 11.0523 10.5 10.5 10.5C9.94772 10.5 9.5 10.9477 9.5 11.5C9.5 12.0523 9.94772 12.5 10.5 12.5Z"
      fill={props.color}
    />
    <path
      d="M7.5 13.5C8.05228 13.5 8.5 13.0523 8.5 12.5C8.5 11.9477 8.05228 11.5 7.5 11.5C6.94772 11.5 6.5 11.9477 6.5 12.5C6.5 13.0523 6.94772 13.5 7.5 13.5Z"
      fill={props.color}
    />
  </svg>
);
SvgLabsSmall.displayName = 'SvgLabsSmall';
const LabsSmall = withForgeIconProps(SvgLabsSmall, {
  name: 'Labs',
  usage: 'Use to indicate or add lab information',
  related: 'result, results, bloodwork, cbc, test, tests, scans',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default LabsSmall;
