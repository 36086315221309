import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVitalsSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.4954 7.99805H9.44671L8.32151 9.99805L5.83002 5.99805L4.70483 7.99805H2.44441M8.00006 4.39795L9.33618 2.99805C9.63915 2.68252 10.0032 2.43134 10.4064 2.25977C10.8096 2.08819 11.2435 2 11.682 2C12.1204 2 12.5544 2.08819 12.9576 2.25977C13.3608 2.43134 13.7249 2.68252 14.0279 2.99805C14.6516 3.64277 15 4.5029 15 5.39795C15 6.293 14.6516 7.15313 14.0279 7.79785L8.00006 13.998L1.97226 7.79785C1.34854 7.15313 1 6.293 1 5.39795C1 4.5029 1.34854 3.64277 1.97226 2.99805C2.27522 2.68252 2.63932 2.43134 3.0425 2.25977C3.44569 2.08819 3.87956 2 4.31804 2C4.75652 2 5.19051 2.08819 5.5937 2.25977C5.99688 2.43134 6.36085 2.68252 6.66382 2.99805L8.00006 4.39795Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgVitalsSmall.displayName = 'SvgVitalsSmall';
const VitalsSmall = withForgeIconProps(SvgVitalsSmall, {
  name: 'Vitals',
  usage: 'Use to indicate or view vital sign information',
  related: 'heartbeat, heart rate, heart, ecg, ekg, vitals',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default VitalsSmall;
