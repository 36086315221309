import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHomeSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15 8.24084C15.0004 8.13696 14.9803 8.03396 14.9408 7.93787C14.9013 7.84177 14.8433 7.75442 14.77 7.68079L8.26001 1.09094C8.2312 1.06192 8.19694 1.03879 8.15918 1.02307C8.12143 1.00735 8.08094 0.999146 8.04004 0.999146C7.99914 0.999146 7.95866 1.00735 7.9209 1.02307C7.88315 1.03879 7.84877 1.06192 7.81995 1.09094L1.22999 7.68079C1.15671 7.75442 1.09868 7.84177 1.05921 7.93787C1.01974 8.03396 0.999641 8.13696 1 8.24084V15.0009H6.10999V9.25085H10V15.0009H15V8.24084Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgHomeSmall.displayName = 'SvgHomeSmall';
const HomeSmall = withForgeIconProps(SvgHomeSmall, {
  name: 'Home',
  usage:
    'Use to return to the homepage or indicate a residence or the homepage',
  related: 'address, mailing, house, apartment, navigation',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default HomeSmall;
