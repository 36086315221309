import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDoctorsBagSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 4V3H0V4H1ZM15 4H16V3H15V4ZM1 15H0V16H1V15ZM11 4V5H12V4H11ZM5 4H4V5H5V4ZM10 10.25C10.5523 10.25 11 9.80228 11 9.25C11 8.69772 10.5523 8.25 10 8.25V10.25ZM6 8.25C5.44772 8.25 5 8.69772 5 9.25C5 9.80228 5.44772 10.25 6 10.25V8.25ZM9 7.25C9 6.69772 8.55228 6.25 8 6.25C7.44772 6.25 7 6.69772 7 7.25H9ZM7 11.25C7 11.8023 7.44772 12.25 8 12.25C8.55228 12.25 9 11.8023 9 11.25H7ZM1 5H15V3H1V5ZM14 4V12H16V4H14ZM14 12C14 12.5304 13.7893 13.0392 13.4142 13.4142L14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12H14ZM13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14V16C13.0609 16 14.0783 15.5786 14.8284 14.8284L13.4142 13.4142ZM12 14H1V16H12V14ZM2 15V4H0V15H2ZM11 3H5V5H11V3ZM6 4V2H4V4H6ZM6 2L5.99995 1.99995L4.58574 0.58574C4.21055 0.960932 4 1.46974 4 2H6ZM5.99995 1.99995L6 2V0C5.46974 0 4.96093 0.210548 4.58574 0.58574L5.99995 1.99995ZM6 2H10V0H6V2ZM10 2L10 1.99995L11.4143 0.58574C11.0391 0.210548 10.5303 0 10 0V2ZM10 1.99995L10 2H12C12 1.46974 11.7895 0.960932 11.4143 0.58574L10 1.99995ZM10 2V4H12V2H10ZM10 8.25H6V10.25H10V8.25ZM7 7.25V11.25H9V7.25H7Z"
      fill={props.color}
    />
  </svg>
);
SvgDoctorsBagSmall.displayName = 'SvgDoctorsBagSmall';
const DoctorsBagSmall = withForgeIconProps(SvgDoctorsBagSmall, {
  name: 'DoctorsBag',
  usage: 'Use to indicate a medical practice or office',
  related: 'provider, doctor, clinic, care, nurse',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default DoctorsBagSmall;
