/* eslint-disable react/prop-types */
import React, { ReactElement, Ref } from 'react';
import { classes } from './DateInput';
import Input, { InputProps } from '../Input';
import CalendarSmall from '@athena/forge-icons/dist/CalendarSmall';

interface DateInputInputControllerProps extends InputProps {
  dataTestidCalendarInput: string;
  error?: string;
}
interface DateInputInputProps extends DateInputInputControllerProps {
  forwardedRef: Ref<HTMLInputElement>;
}

function DateInputInput({
  dataTestidCalendarInput,
  error,
  forwardedRef,
  disabled,
  ...rest
}: DateInputInputProps): ReactElement {
  return (
    <Input
      {...classes({
        element: 'input',
      })}
      error={error}
      data-testid={dataTestidCalendarInput}
      disabled={disabled}
      ref={forwardedRef}
      {...rest}
    >
      <CalendarSmall {...classes({ element: 'icon' })} semanticColor={disabled ? 'disabled' : 'default'} />
    </Input>
  );
}

function DateInputInputController(props: DateInputInputControllerProps, ref: Ref<HTMLInputElement>): ReactElement {
  return <DateInputInput {...props} forwardedRef={ref} />;
}

DateInputInputController.displayName = 'DateInputInput';

export default React.forwardRef(DateInputInputController);
