import rawContents from "!!raw-loader!./Standalone.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Standalone():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Standalone" />;
}

export default Standalone;
