import React, { useContext } from 'react';
import ModalManager from '@restart/ui/ModalManager';

const ModalManagerContext = React.createContext(new ModalManager());

export const useModalManager = (manager?: ModalManager): ModalManager => {
  const contextManager = useContext(ModalManagerContext);
  return manager || contextManager;
};

export default ModalManagerContext;
