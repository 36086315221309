import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUnpinSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0471 0.292901C9.65656 -0.0976277 9.0234 -0.0976345 8.63287 0.292886C8.24234 0.683406 8.24233 1.31657 8.63285 1.7071L9.21131 2.28557L6.83707 4.12996L8.26239 5.55528L10.6366 3.71091L12.2874 5.36177L10.4381 7.73103L11.8631 9.15594L13.7123 6.7867L14.2929 7.36726C14.6834 7.75778 15.3166 7.75779 15.7071 7.36727C16.0976 6.97675 16.0976 6.34359 15.7071 5.95306L14.3425 4.58841L14.3271 4.57266L11.4271 1.67276L11.4165 1.6624L10.0471 0.292901ZM11.2431 9.9502L9.81818 8.52529L9.13175 9.40472C8.94918 9.63862 8.87991 9.94151 8.94267 10.2315C9.08753 10.901 9.06187 11.5962 8.8681 12.2533C8.76995 12.5862 8.63026 12.9039 8.45329 13.1992L5.63649 10.3824L5.62716 10.373L5.61769 10.3636L2.79307 7.53901C3.08839 7.36282 3.40568 7.22364 3.73791 7.12567C4.39553 6.93176 5.09106 6.90502 5.7616 7.04787C6.04992 7.1093 6.35065 7.04038 6.58344 6.85954L7.46647 6.17358L6.04115 4.74825L5.7041 5.01008C4.85739 4.8983 3.99483 4.96478 3.17225 5.20733C2.19517 5.49545 1.30573 6.02317 0.584347 6.74168C0.396191 6.92908 0.290305 7.18363 0.29004 7.4492C0.289774 7.71476 0.395151 7.96952 0.582932 8.1573L3.50576 11.0801L0.292904 14.2929C-0.0976261 14.6834 -0.097636 15.3166 0.292882 15.7071C0.6834 16.0976 1.31657 16.0976 1.7071 15.7071L4.91997 12.4943L7.83293 15.4073C8.22313 15.7975 8.85564 15.7979 9.2463 15.4081C9.96852 14.6877 10.498 13.7972 10.7864 12.819C11.0291 11.996 11.0947 11.1329 10.981 10.286L11.2431 9.9502Z"
      fill={props.color}
    />
    <path
      d="M1 1L15 15"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgUnpinSmall.displayName = 'SvgUnpinSmall';
const UnpinSmall = withForgeIconProps(SvgUnpinSmall, {
  name: 'Unpin',
  usage: 'Use to unpin an item',
  related: 'thumbtack, top, favorite',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default UnpinSmall;
