import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgModalPopupLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7 5H1V23H16C16.7956 23 17.5587 22.6839 18.1213 22.1213C18.6839 21.5587 19 20.7956 19 20V17M19 5H14M19 5V10M19 5L11 13M7 1H23V14C23 14.7956 22.6839 15.5587 22.1213 16.1213C21.5587 16.6839 20.7956 17 20 17H7V1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgModalPopupLarge.displayName = 'SvgModalPopupLarge';
const ModalPopupLarge = withForgeIconProps(SvgModalPopupLarge, {
  name: 'ModalPopup',
  usage: 'Use to open a Modal',
  related: 'popover',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default ModalPopupLarge;
