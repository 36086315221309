import rawContents from "!!raw-loader!./Nesting.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Nesting():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Nesting" />;
}

export default Nesting;
