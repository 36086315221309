import React, { WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper({ name: 'accordion-item' });

export interface AccordionItemHeaderProps extends React.HTMLAttributes<HTMLElement> {
  /** Adds a class to the root element of the component */
  className?: string;
  /** The content of the header */
  children: React.ReactNode;
}

/**
 * @documentedBy Accordion
 */
function AccordionItemHeader({ className, children, ...passedProps }: AccordionItemHeaderProps): React.ReactElement {
  return (
    <div {...classes({ element: 'header-content', extra: className })} {...passedProps}>
      {children}
    </div>
  );
}

const accordionItemHeaderPropTypes: WeakValidationMap<AccordionItemHeaderProps> = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
AccordionItemHeader.propTypes = accordionItemHeaderPropTypes;

export default AccordionItemHeader;
