import React, { ReactElement, Ref, ReactNode, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import CheckboxInput from './CheckboxInput';
import forwardRefToProps from '../utils/forwardRefToProps';

const classes = forgeClassHelper('checkbox');

type Value = string | ReadonlyArray<string> | number | undefined;

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** aria-label to apply to the `<label>` tag */
  'aria-label'?: string;
  /** Specify an additional element ID to label the input. (The input is automatically labeled by the input label, so specifying this additional label target merely provides an additional label.) */
  'aria-labelledby'?: string;
  /** Whether the Checkbox should be checked. Used for [controlled](https://reactjs.org/docs/forms.html#controlled-components) components. */
  checked?: boolean;
  /** Content to render after the description prop */
  children?: ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Whether the Checkbox should be checked. Used for [uncontrolled](https://reactjs.org/docs/uncontrolled-components.html) Checkboxes. */
  defaultChecked?: boolean;
  /** A descriptive label for what the Checkbox indicates; behaves like a standard input label */
  description: ReactNode;
  /** Adds the relevant Forge state class to the input and passes the prop down to the native react `<input />` */
  disabled?: boolean;
  /** Highlights the input when defined (specific string doesn't matter) */
  error?: string;
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles?: boolean;
  /** Id is sent to the `<input />`, and is used to associate the label with the checkbox. */
  id: string;
  /** If the checkbox is neither true nor false */
  indeterminate?: boolean;
  /** A reference to the underlying <input> that is rendered */
  ref?: Ref<HTMLInputElement>;
  /** Indicates if it is a required field. Passed as an attribute to the native react `<input />` */
  required?: boolean;
  /** *This prop should not be used, except internally by certain components.*
   *
   * If `value` is provided and `valueAttribute` is not provided, this prop is used to set the value attribute on `<input>`
   *
   * To set the checkbox status, use `checked` or `defaultChecked`.\
   * To set the value attribute on the `<input>`, use `valueAttribute`.
   */
  value?: Value;
  /** Sets the value attribute on the native `<input type="checkbox">`.*/
  valueAttribute?: string;
}

export type CheckboxComponentProps = CheckboxProps & {
  /** A reference to the underlying <input> that is rendered */
  forwardedRef?: Ref<HTMLInputElement>;
};

const Checkbox = ({
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledby,
  className,
  children,
  error,
  forwardedRef,
  description,
  id,
  indeterminate = false,
  required = false,
  disabled = false,
  hideRequiredStyles = false,
  value,
  valueAttribute,
  ...passThroughProps
}: CheckboxComponentProps): ReactElement => {
  const descriptionLabelId = `description-label-${id}`;

  // combine any provided aria-labelledby with ours
  // (ours: input labelled by description)
  const combinedAriaLabelledby = ariaLabelledby ? `${ariaLabelledby} ${descriptionLabelId}` : descriptionLabelId;

  // set value from valueAttribute prop if provided,
  // if not then use value prop
  const checkboxValue = valueAttribute ? { value: valueAttribute } : value && value !== '' ? { value: value } : null;

  const useErrorStyles = !!(error && !disabled);
  const useRequiredStyles = !!(required && !hideRequiredStyles && !disabled);

  return (
    <span
      {...classes({
        states: {
          disabled: !!disabled,
          error: useErrorStyles,
          required: useRequiredStyles,
        },
        extra: className,
      })}
    >
      <CheckboxInput
        {...passThroughProps}
        aria-labelledby={combinedAriaLabelledby}
        id={id}
        disabled={disabled}
        defaultChecked={passThroughProps.defaultChecked}
        error={error}
        indeterminate={indeterminate}
        ref={forwardedRef}
        required={required}
        {...checkboxValue}
      />
      <label
        {...classes({
          element: 'description',
          states: {
            disabled: !!disabled,
            error: useErrorStyles,
            required: useRequiredStyles,
            indeterminate: !!indeterminate,
          },
          extra: 'fe_c_label',
        })}
        id={descriptionLabelId}
        htmlFor={id}
        aria-label={ariaLabel}
      >
        {description}
        {children}
      </label>
    </span>
  );
};

const checkboxPropTypes: WeakValidationMap<CheckboxComponentProps & { '...rest': unknown }> = {
  /** aria-label to apply to the `<label>` tag */
  'aria-label': PropTypes.string,
  /** Specify an additional element ID to label the input. (The input is automatically labeled by the input label, so specifying this additional label target merely provides an additional label.) */
  'aria-labelledby': PropTypes.string,
  /** Whether the Checkbox should be checked. Used for [controlled](https://reactjs.org/docs/forms.html#controlled-components) components. */
  checked: PropTypes.bool,
  /** Content to render after the description prop */
  children: PropTypes.node,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Whether the Checkbox should be checked. Used for [uncontrolled](https://reactjs.org/docs/uncontrolled-components.html) Checkboxes. */
  defaultChecked: PropTypes.bool,
  /** A descriptive label for what the Checkbox indicates; behaves like a standard input label */
  description: PropTypes.node.isRequired,
  /** Adds the relevant Forge state class to the input and passes the prop down to the native react `<input />` */
  disabled: PropTypes.bool,
  /** Highlights the input when defined (specific string doesn't matter) */
  error: PropTypes.string,
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles: PropTypes.bool,
  /** Id is sent to the `<input />`, and is used to associate the label with the checkbox. */
  id: PropTypes.string.isRequired,
  /** If the checkbox is neither true nor false */
  indeterminate: PropTypes.bool,
  /** Indicates if it is a required field. Passed as an attribute to the native react `<input />` */
  required: PropTypes.bool,
  /** *This prop should not be used, except internally by certain components.*
   *
   * If `value` is provided and `valueAttribute` is not provided, this prop is used to set the value attribute on `<input>`
   *
   * To set the checkbox status, use `checked` or `defaultChecked`.\
   * To set the value attribute on the `<input>`, use `valueAttribute`.
   */
  value: PropTypes.string,
  /** Sets the value attribute on the native `<input type="checkbox">`.*/
  valueAttribute: PropTypes.string,
  /** Passthrough props to the `<input>` element */
  '...rest': PropTypes.any,
};

Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = checkboxPropTypes;

export default Object.assign(forwardRefToProps(Checkbox), {
  disableLabelFor: true,
});
