import React, { Ref } from 'react';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import Input from '../../Input';
import Tag from '../../Tag';
import { EmptyPlaceholderComponent } from '.';
import { forgeClassHelper } from '../../utils/classes';
import { Virtuoso } from 'react-virtuoso';
import { HighVolumeMultiSelectOption } from '../HighVolumeMultiSelect';

const classes = forgeClassHelper('high-volume-multiselect');

interface ExposedSelectionPanelProps {
  allSelected: boolean;
  canClearAll: boolean;
  filterTerm: string;
  forwardedRef?: Ref<HTMLDivElement>;
  handleClearAll: (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>) => void;
  handleSelectAll: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleTagRemoval: (key: string) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  id: string;
  keyDownToggleOption: (key: string) => React.KeyboardEventHandler<HTMLDivElement>;
  memoizedOptions: HighVolumeMultiSelectOption[];
  required: boolean;
  selectedMap: { [K: HighVolumeMultiSelectOption['value']]: boolean };
  selectedOptions: HighVolumeMultiSelectOption[];
  setFilterTerm: (term: string) => void;
  toggleOption: (key: string) => React.ChangeEventHandler<HTMLDivElement>;
}
const ExposedSelectionPanel = ({
  allSelected,
  canClearAll,
  filterTerm,
  forwardedRef,
  handleClearAll,
  handleSelectAll,
  handleTagRemoval,
  id,
  keyDownToggleOption,
  memoizedOptions,
  required,
  selectedMap,
  selectedOptions,
  setFilterTerm,
  toggleOption,
}: ExposedSelectionPanelProps): JSX.Element => {
  return (
    <div {...classes('container')} ref={forwardedRef}>
      <div {...classes({ states: { required } })} id={id}>
        <div {...classes('searchSide')}>
          <div {...classes('header')}>
            <Input
              {...classes('filter')}
              value={filterTerm}
              onChange={(e) => setFilterTerm(e.target.value)}
              placeholder="Search"
              aria-label="Filter"
            />

            <Button
              variant="tertiary"
              disabled={allSelected || !memoizedOptions.length}
              onClick={handleSelectAll}
              id={`${id}CheckboxSelectAll`}
              tabIndex={allSelected || !memoizedOptions.length ? -1 : 0}
            >
              Select All
            </Button>
          </div>
          <Virtuoso
            data={memoizedOptions}
            itemContent={(idx, { value, label, disabled }: HighVolumeMultiSelectOption) => (
              <Checkbox
                {...classes('option')}
                key={value}
                id={`${id}Checkbox${value}`}
                valueAttribute={value}
                description={label}
                disabled={disabled}
                checked={selectedMap[value] ?? false}
                onChange={toggleOption(value)}
                tabIndex={disabled ? -1 : 0}
                onKeyDown={keyDownToggleOption(value)}
              />
            )}
            components={{
              EmptyPlaceholder: !memoizedOptions.length ? EmptyPlaceholderComponent : undefined,
            }}
          />
        </div>
        <div {...classes('selectedSide')}>
          <div {...classes('header')}>
            <span {...classes('label', selectedOptions.length ? '' : 'disabled')}>
              Selected ({selectedOptions.length})
            </span>
            <Button variant="tertiary" disabled={!canClearAll} onClick={handleClearAll} tabIndex={canClearAll ? 0 : -1}>
              Clear All
            </Button>
          </div>
          <Virtuoso
            data={selectedOptions}
            itemContent={(idx, { label, disabled, value }: HighVolumeMultiSelectOption) => (
              <Tag
                key={value}
                onRemove={handleTagRemoval(value)}
                size="medium"
                text={label}
                isRemovable={!disabled}
                tabIndex={disabled ? -1 : 0}
                onKeyDown={keyDownToggleOption(value)}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ExposedSelectionPanel;
