import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCartLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.5.5h2.515a1.97 1.97 0 0 1 1.807 1.181l1.066 2.451H23.5l-2.872 7.982c-.28.78-1.022 1.3-1.854 1.3H8.298l-1.38 2.378h14.95v1.96H6.917c-1.516 0-2.463-1.634-1.705-2.94l1.441-2.484-2.332-6.864L3.015 2.46H.5V.5Zm6.114 5.592 1.822 5.362h10.338l1.93-5.362H6.613Z"
      fill={props.color}
    />
    <path
      d="M10.097 21.159A2.347 2.347 0 0 1 7.745 23.5a2.347 2.347 0 0 1-2.352-2.341 2.347 2.347 0 0 1 2.352-2.341 2.347 2.347 0 0 1 2.352 2.34Zm11.596 0a2.347 2.347 0 0 1-2.352 2.341 2.347 2.347 0 0 1-2.352-2.341 2.347 2.347 0 0 1 2.352-2.341 2.347 2.347 0 0 1 2.352 2.34Z"
      fill={props.color}
    />
  </svg>
);
SvgCartLarge.displayName = 'SvgCartLarge';
const CartLarge = withForgeIconProps(SvgCartLarge, {
  name: 'Cart',
  usage: 'Use to direct to check out',
  related: 'dollar, PaymentMethods',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default CartLarge;
