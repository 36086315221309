import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGraduationLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 1L12.4789 0.122104C12.1804 -0.0407015 11.8196 -0.0407015 11.5211 0.122104L12 1ZM1 7L0.521148 6.1221C0.199887 6.29734 0 6.63406 0 7C0 7.36594 0.199887 7.70266 0.521148 7.8779L1 7ZM12 13L11.5211 13.8779C11.8196 14.0407 12.1804 14.0407 12.4789 13.8779L12 13ZM23 7L23.4789 7.8779C23.8001 7.70266 24 7.36594 24 7C24 6.63406 23.8001 6.29734 23.4789 6.1221L23 7ZM12.2928 6.04383C11.7647 5.88211 11.2056 6.1791 11.0438 6.70717C10.8821 7.23525 11.1791 7.79444 11.7072 7.95617L12.2928 6.04383ZM20 9.45H21C21 9.01051 20.7131 8.62253 20.2928 8.49383L20 9.45ZM19 14C19 14.5523 19.4477 15 20 15C20.5523 15 21 14.5523 21 14H19ZM4 13.5H3C3 13.7271 3.07728 13.9474 3.21913 14.1247L4 13.5ZM16.3931 17.3037C16.9009 17.0866 17.1366 16.499 16.9195 15.9911C16.7024 15.4833 16.1148 15.2476 15.6069 15.4647L16.3931 17.3037ZM20 17L20.9864 16.8356C20.906 16.3534 20.4888 16 20 16C19.5112 16 19.094 16.3534 19.0136 16.8356L20 17ZM19 23L18.0136 22.8356C17.9653 23.1256 18.047 23.4221 18.237 23.6464C18.427 23.8707 18.706 24 19 24V23ZM21 23V24C21.294 24 21.573 23.8707 21.763 23.6464C21.953 23.4221 22.0347 23.1256 21.9864 22.8356L21 23ZM11.5211 0.122104L0.521148 6.1221L1.47885 7.8779L12.4789 1.8779L11.5211 0.122104ZM12.4789 13.8779L23.4789 7.8779L22.5211 6.1221L11.5211 12.1221L12.4789 13.8779ZM23.4789 6.1221L12.4789 0.122104L11.5211 1.8779L22.5211 7.8779L23.4789 6.1221ZM11.7072 7.95617L19.7072 10.4062L20.2928 8.49383L12.2928 6.04383L11.7072 7.95617ZM3.21913 14.1247C6.48349 18.2051 11.8542 19.244 16.3931 17.3037L15.6069 15.4647C11.8255 17.0812 7.43848 16.1973 4.78087 12.8753L3.21913 14.1247ZM19.0136 16.8356L18.0136 22.8356L19.9864 23.1644L20.9864 17.1644L19.0136 16.8356ZM19 24H21V22H19V24ZM21.9864 22.8356L20.9864 16.8356L19.0136 17.1644L20.0136 23.1644L21.9864 22.8356ZM0.521148 7.8779L11.5211 13.8779L12.4789 12.1221L1.47885 6.1221L0.521148 7.8779ZM19 9.45V14H21V9.45H19ZM3 9V13.5H5V9H3Z"
      fill={props.color}
    />
  </svg>
);
SvgGraduationLarge.displayName = 'SvgGraduationLarge';
const GraduationLarge = withForgeIconProps(SvgGraduationLarge, {
  name: 'Graduation',
  usage: 'Use to indicate that an athenahealth coaching program is available',
  related: 'grad, coaching, graduation cap, grad cap',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default GraduationLarge;
