import rawContents from "!!raw-loader!./CustomColumn.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function CustomColumn():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Custom Column" />;
}

export default CustomColumn;
