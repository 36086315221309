import rawContents from "!!raw-loader!./CardBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function CardBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Card Basic" />;
}

export default CardBasic;
