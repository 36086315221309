import { Button, SegmentedButton } from '@athena/forge';
import { ReactElement, useContext } from 'react';
import createBEMHelper from '../../bem-helper';
import { PageContext } from '../../contexts';
import { forgeVersionType } from '../../versionCheckHelper';
import './version-switcher.scss';

const classes = createBEMHelper({ name: 'version-switcher' });

const VersionSwitcher = (): ReactElement => {
  const { location } = useContext(PageContext);
  const handleOnChange = (): void => {
    window.location.href = `${process.env.GATSBY_VERSION_SWITCH_URL}${location?.pathname ?? ''}`;
  };

  const getForgeVersion = (): string => (forgeVersionType() === 'lts' ? 'LTS' : 'New');

  return (
    <div {...classes({ element: 'component' })}>
      <SegmentedButton value={getForgeVersion()} behavior="radio" onChange={handleOnChange}>
        <Button value="LTS" text="LTS" />
        <Button value="New" text="New" />
      </SegmentedButton>
    </div>
  );
};
export default VersionSwitcher;
