import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUnlockSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3 7V6H2V7H3ZM3 15H2V16H3V15ZM13 7H14V6H13V7ZM9 6C9 6.55228 9.44772 7 10 7C10.5523 7 11 6.55228 11 6H9ZM13 3V4H15V3H13ZM2 7V15H4V7H2ZM3 16H10V14H3V16ZM14 12V7H12V12H14ZM13 6H3V8H13V6ZM10 16C12.2091 16 14 14.2091 14 12H12C12 13.1046 11.1046 14 10 14V16ZM11 6V3H9V6H11ZM11 3C11 2.60469 11.1383 2.38169 11.2894 2.24741C11.4588 2.09677 11.7131 2 12 2C12.2869 2 12.5412 2.09677 12.7106 2.24741C12.8617 2.38169 13 2.60469 13 3H15C15 2.06197 14.6383 1.28498 14.0394 0.75259C13.4588 0.236565 12.7131 6.55651e-07 12 1.19209e-07C11.2869 -4.76837e-07 10.5412 0.236562 9.96064 0.752589C9.3617 1.28498 9 2.06197 9 3H11Z"
      fill={props.color}
    />
  </svg>
);
SvgUnlockSmall.displayName = 'SvgUnlockSmall';
const UnlockSmall = withForgeIconProps(SvgUnlockSmall, {
  name: 'Unlock',
  usage:
    'Use to denote content that is not currently private, classified, or encrypted but can be made private',
  related: 'Lock',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default UnlockSmall;
