import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDivergeLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M23 23V18M23 23H18M23 23L12 12M23 1H18M23 1V6M23 1L12 12M12 12H5M5 12C5 13.1045 4.10457 14 3 14C1.89543 14 1 13.1045 1 12C1 10.8954 1.89543 9.99997 3 9.99997C4.10457 9.99997 5 10.8954 5 12Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgDivergeLarge.displayName = 'SvgDivergeLarge';
const DivergeLarge = withForgeIconProps(SvgDivergeLarge, {
  name: 'Diverge',
  usage:
    'Use to represent the point at which a path or a journey splits into two paths',
  related: 'split, decision, junction',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default DivergeLarge;
