import { ReactElement, useEffect } from 'react';
import { components, GroupBase, OptionProps } from 'react-select';
import { classes } from '../Select';
import { StructuredOption } from '../Select';
import Checkbox from '../../Checkbox';
import { useSelectContext } from '../context/SelectContext';

interface CustomOptionProps<Option extends StructuredOption, IsMulti extends boolean, Group extends GroupBase<Option>>
  extends OptionProps<Option, IsMulti, Group> {
  /** despite not being in OptionProps, value is indeed being passed through.
   *
   * It is already normalized with the getOptionValue function.
   */
  value?: string;
}

const CustomOption = <Option extends StructuredOption, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: CustomOptionProps<Option, IsMulti, Group>
): ReactElement => {
  const {
    data,
    innerProps: { id, ref, ...passthroughProps },
    isDisabled,
    isFocused,
    isMulti,
    isSelected,
    selectProps,
    value,
    children,
  } = props;
  const innerPropsWithAria = {
    id,
    ref,
    ...passthroughProps,
    role: 'option',
    'aria-selected': isSelected,
    'aria-label': selectProps.getOptionLabel(data),
  };
  const { handleFocusedOptionData } = useSelectContext();
  /**
   * Working around the lifecycle of React Select
   *
   * Setting ref during component render doesn't capture updated data as expected
   * But, as long as we allow React Select to finish drawing, the data will have settled and we can reliably make our update
   */
  useEffect(() => {
    if (handleFocusedOptionData) {
      handleFocusedOptionData(data, isFocused);
    }
  }, [handleFocusedOptionData, isFocused, data]);

  return (
    <components.Option {...props} innerProps={innerPropsWithAria}>
      {isMulti ? (
        <Checkbox
          id={id as string}
          {...classes({
            element: 'option-checkbox',
          })}
          checked={isSelected}
          description={selectProps.getOptionLabel(data)}
          disabled={isDisabled}
          readOnly
          valueAttribute={value}
          {...passthroughProps}
        />
      ) : (
        children
      )}
    </components.Option>
  );
};

export default CustomOption;
