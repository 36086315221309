import HiddenOnDesktop from './HiddenOnDesktop.gen';
import HiddenInline from './HiddenInline.gen';
import HiddenOnMobile from './HiddenOnMobile.gen';
import HiddenOnPrint from './HiddenOnPrint.gen';
import HiddenOnScreen from './HiddenOnScreen.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <HiddenOnDesktop />
      <HiddenInline />
      <HiddenOnMobile />
      <HiddenOnPrint />
      <HiddenOnScreen />
    </>
  );
}
