import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgShowSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.99999 11C10.6568 11 12 9.65685 12 8C12 6.34315 10.6568 5 8.99999 5C7.34314 5 5.99999 6.34315 5.99999 8C5.99999 9.65685 7.34314 11 8.99999 11Z"
      fill={props.color}
    />
    <path
      d="M16 8L16.9772 8.21223L17.0233 8L16.9772 7.78777L16 8ZM2 8L1.02278 7.78778L0.976691 8L1.02278 8.21222L2 8ZM15.0228 7.78777C14.88 8.44515 14.32 9.24436 13.2608 9.89956C12.2193 10.5438 10.763 11 9 11V13C11.103 13 12.9295 12.4562 14.3129 11.6004C15.6786 10.7556 16.6856 9.55485 16.9772 8.21223L15.0228 7.78777ZM9 11C7.23703 11 5.78067 10.5438 4.73919 9.89956C3.67999 9.24436 3.11998 8.44515 2.97722 7.78778L1.02278 8.21222C1.31435 9.55485 2.32135 10.7556 3.68706 11.6004C5.07051 12.4562 6.89698 13 9 13V11ZM2.97722 8.21222C3.11998 7.55485 3.67999 6.75564 4.73919 6.10044C5.78067 5.45621 7.23703 5 9 5V3C6.89698 3 5.07051 3.54379 3.68706 4.39956C2.32135 5.24436 1.31435 6.44515 1.02278 7.78778L2.97722 8.21222ZM9 5C10.763 5 12.2193 5.45621 13.2608 6.10044C14.32 6.75564 14.88 7.55485 15.0228 8.21223L16.9772 7.78777C16.6856 6.44515 15.6786 5.24436 14.3129 4.39956C12.9295 3.54379 11.103 3 9 3V5Z"
      fill={props.color}
    />
  </svg>
);
SvgShowSmall.displayName = 'SvgShowSmall';
const ShowSmall = withForgeIconProps(SvgShowSmall, {
  name: 'Show',
  usage: 'Use to indicate that an item can be hidden, or to hide an item',
  related: 'eye, secret, private',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ShowSmall;
