import PropTypes from 'prop-types';
import React, { ReactElement, Ref, WeakValidationMap, useRef, useState } from 'react';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';
import MainContent from './components/MainContent';
import PushDrawerContent from './components/PushDrawerContent';
import { PushDrawerTypes } from './components/types';

const classes = forgeClassHelper('push-drawer');
export const MEDIUM_LAYOUT_SIZE = 48;
export const SUPER_COMPACT_LAYOUT_SIZE = 32;

export interface PushDrawerBaseProps extends React.HTMLAttributes<HTMLDivElement> {
  /** The duration of the animation in milliseconds. */
  animationDuration?: number;
  /** The background color of the PushDrawer. Can be either 'light' or 'dark' */
  background?: PushDrawerTypes.Background;
  /** The content visible when the PushDrawer is closed, and is pushed aside
   * when the PushDrawer is opened */
  children: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** The footer buttons of the PushDrawer */
  footerButtons?: React.ReactNode;
  /** Hides borders that divide the header, body, and footer sections */
  hideDividers?: boolean;
  /** Optionally display an icon. Provide an small icon component from the forge-icons package */
  icon?: React.ReactNode;
  /** The layout of the PushDrawer. Can be either 'super-compact' or 'medium' */
  layout?: PushDrawerTypes.Layout;
  /** A callback function to be called when the PushDrawer is opened */
  onOpen?: () => void;
  /** The placement of the PushDrawer. Can be either 'left', 'right', 'top', or 'bottom' */
  placement?: PushDrawerTypes.Placement;
  /** Determines whether the content should be pushed aside when the drawer is opened */
  pushContentAside?: boolean;
  /** The content within the PushDrawer */
  pushDrawerContent: React.ReactNode;
  /** The title of the PushDrawer*/
  title?: string;
  /** Ref forwarded to the top-level DOM element */
  ref?: Ref<HTMLDivElement>;
}

export interface PushDrawerPersistentProps {
  /** The mode of the PushDrawer. Can be either 'persistent' or 'hidden' */
  mode: 'persistent';
  /** A callback function to be called when the PushDrawer is closed */
  onClose?: () => void;
  /** Whether the PushDrawer is open or not */
  isOpen?: boolean;
}

export interface PushDrawerHiddenProps {
  /** The mode of the PushDrawer. Can be either 'persistent' or 'hidden' */
  mode: 'hidden';
  /** A callback function to be called when the PushDrawer is closed */
  onClose: () => void;
  /** Whether the PushDrawer is open or not */
  isOpen: boolean;
}
/** Simplified version of PushDrawerPersistentProps merged with PushDrawerHiddenProps
 * Used only for propTypes
 */
interface PushDrawerSimpleProps extends PushDrawerBaseProps {
  /** The mode of the PushDrawer. Can be either 'persistent' or 'hidden' */
  mode?: PushDrawerTypes.DrawerMode;
  /** A callback function to be called when the PushDrawer is closed */
  onClose?: () => void;
  /** Whether the PushDrawer is open or not */
  isOpen?: boolean;
}

export type PushDrawerProps = PushDrawerBaseProps & (PushDrawerPersistentProps | PushDrawerHiddenProps);

type PushDrawerComponentProps = PushDrawerProps & {
  forwardedRef?: Ref<HTMLDivElement>;
};

const PushDrawer = ({
  animationDuration = 200,
  background = 'light',
  children,
  className,
  footerButtons,
  forwardedRef,
  hideDividers = false,
  icon,
  isOpen: upstreamIsOpen,
  layout = 'medium',
  mode,
  onClose,
  onOpen,
  placement = 'left',
  pushContentAside = true,
  pushDrawerContent,
  title,
  ...rest
}: PushDrawerComponentProps): ReactElement => {
  const [isOpenState, setIsOpenState] = useState(!!upstreamIsOpen);
  const pushDrawerRef = useRef<HTMLDivElement>(null);

  /**
   * Reconciles the internal state of isOpen with the upstream isOpen prop.
   * This function is used to handle the case where the user controls the isOpen prop.
   */
  const reconcileIsOpen = (): boolean => {
    if (upstreamIsOpen === undefined) {
      return isOpenState;
    } else {
      return upstreamIsOpen;
    }
  };
  const isOpen = reconcileIsOpen();

  const handlePushDrawerToggle = (): void => {
    if (isOpen) {
      onClose?.();
    } else {
      onOpen?.();
    }
    if (upstreamIsOpen === undefined) {
      setIsOpenState((prev) => !prev);
    }
  };
  return (
    <div
      {...classes({ extra: className })}
      style={{
        '--fe_c_push-drawer-animationDuration': animationDuration,
        '--fe_c_push-drawer-layoutSize': layout === 'medium' ? MEDIUM_LAYOUT_SIZE : SUPER_COMPACT_LAYOUT_SIZE,
      }}
      ref={forwardedRef}
      {...rest}
    >
      <PushDrawerContent
        background={background}
        footerButtons={footerButtons}
        handlePushDrawerToggle={handlePushDrawerToggle}
        hideDividers={hideDividers}
        icon={icon}
        isOpen={isOpen}
        layout={layout}
        mode={mode}
        placement={placement}
        pushDrawerRef={pushDrawerRef}
        title={title}
      >
        {pushDrawerContent}
      </PushDrawerContent>
      <MainContent
        isOpen={isOpen}
        layout={layout}
        mode={mode}
        placement={placement}
        pushContentAside={pushContentAside}
        pushDrawerRef={pushDrawerRef}
        mediumLayoutSize={MEDIUM_LAYOUT_SIZE}
        superCompactLayoutSize={SUPER_COMPACT_LAYOUT_SIZE}
      >
        {children}
      </MainContent>
    </div>
  );
};

export const PushDrawerPropTypes: WeakValidationMap<PushDrawerSimpleProps & { '...rest': unknown }> = {
  animationDuration: PropTypes.number,
  background: PropTypes.oneOf(['light', 'dark']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  footerButtons: PropTypes.element,
  hideDividers: PropTypes.bool,
  icon: PropTypes.node,
  /** Whether the PushDrawer is open or not */
  isOpen: PropTypes.bool,
  layout: PropTypes.oneOf(['medium', 'super-compact']),
  /** The mode of the PushDrawer. Can be either 'persistent' or 'hidden' */
  mode: PropTypes.oneOf<PushDrawerTypes.DrawerMode>(['hidden', 'persistent']).isRequired,
  /** A callback function to be called when the PushDrawer is closed */
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  pushContentAside: PropTypes.bool,
  pushDrawerContent: PropTypes.node.isRequired,
  title: PropTypes.string,
  /** Props forwarded to the top-level div element */
  '...rest': PropTypes.any,
};

PushDrawer.propTypes = PushDrawerPropTypes;

export default forwardRefToProps(PushDrawer);
