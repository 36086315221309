import Default from './Default.gen';
import MultipleInputs from './MultipleInputs.gen';
import Controlled from './Controlled.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <Default />
      <MultipleInputs />
      <Controlled />
    </>
  );
}
