import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCheckInLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.63636 5.00006L16.2727 11.9994M16.2727 11.9994L8.63636 18.9988M16.2727 11.9994L1 11.9994"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 1H23V20C23 21.6569 21.6569 23 20 23H12"
      stroke={props.color}
      strokeWidth={2}
    />
  </svg>
);
SvgCheckInLarge.displayName = 'SvgCheckInLarge';
const CheckInLarge = withForgeIconProps(SvgCheckInLarge, {
  name: 'CheckIn',
  usage: 'Use to start patient check in',
  related: 'enter, start, Leave',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default CheckInLarge;
