import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDocumentSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13 5.93005H14V5.51171L13.7021 5.21798L13 5.93005ZM13 12H14H13ZM3 15H2V16H3V15ZM3 1V0H2V1H3ZM8 1L8.70211 0.28793L8.41009 0H8V1ZM13 7C13.5523 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5V7ZM8 6H7C7 6.55228 7.44772 7 8 7V6ZM12 5.93005V12H14V5.93005H12ZM12 12C12 12.5305 11.7893 13.0392 11.4142 13.4142L12.8284 14.8284C13.5786 14.0783 14 13.0608 14 12L12 12ZM11.4142 13.4142C11.0392 13.7893 10.5305 14 10 14V16C11.0608 16 12.0783 15.5786 12.8284 14.8284L11.4142 13.4142ZM10 14H3V16H10V14ZM4 15V1H2V15H4ZM3 2H8V0H3V2ZM7.29789 1.71207L12.2979 6.64212L13.7021 5.21798L8.70211 0.28793L7.29789 1.71207ZM13 5H8V7H13V5ZM9 6V1H7V6H9Z"
      fill={props.color}
    />
  </svg>
);
SvgDocumentSmall.displayName = 'SvgDocumentSmall';
const DocumentSmall = withForgeIconProps(SvgDocumentSmall, {
  name: 'Document',
  usage: 'Use to indicate or add a page or document',
  related: 'chart, note, paper, file, attachment',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default DocumentSmall;
