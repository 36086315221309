import { ForgeIconComponent } from '@athena/forge-icons/dist/ForgeIconProps';
import { DataTableColumn, CreateColumnCommonArgs } from '../../utils/internalTypes';
import { classes } from '../../utils/dataTableClasses';
import { IconSemanticColor } from '@athena/forge-icons/dist/ForgeIconProps';

export type DataTableIconColumnOption = {
  label: string;
  icon: ForgeIconComponent;
  semanticColor?: IconSemanticColor;
};

type CreateIconColumnArgs<RowData> = Omit<
  CreateColumnCommonArgs<RowData>,
  'cellValidation' | 'enableClientSideSorting'
> & {
  iconOptions: Record<string, DataTableIconColumnOption>;
  clientSideSortAscFn?: (row1: RowData, row2: RowData) => number;
  /**
   * Use `DT.ColumnFilter.create*` to create columnFilter instance.
   * - `DT.ColumnFilter.createSelectColumnFilter`
   * - `DT.ColumnFilter.createSelectionListColumnFilter`
   */
  // Internal Dev Note:
  // The second type parameter `FilterConditionValue` should be set to `any` to
  // allow users to override it. Overwriting with the `unknown` type is not allowed.
  columnFilter?: DataTableColumn<RowData, any>['columnFilter']; // eslint-disable-line @typescript-eslint/no-explicit-any
  serverSideQueryParamFn?: (selectedValues: string[]) => string;
};
export type CreateIconColumn<RowData> = typeof createIconColumn<RowData>;

export function createIconColumn<RowData>({
  columnId,
  tooltip,
  headerText,
  footer,
  columnFilter,
  clientSideSortAscFn,
  determineCellAlert = () => '',
  determineCellTooltip,
  displayCellText,
  iconOptions,
  rowDataFieldName,
  enableClientSideFullTextSearch = true,
  enableClientSideCsvDownload = true,
  isConfigurable = true,
  defaultHideColumn = false,
}: CreateIconColumnArgs<RowData>): DataTableColumn<RowData> {
  const headerTextArray = typeof headerText === 'string' ? [headerText] : headerText;

  if (typeof displayCellText === 'undefined') {
    displayCellText = (row: RowData): string => {
      const icons = row[rowDataFieldName] as string[];

      return icons.map((iconKey) => iconOptions[iconKey]?.label ?? iconKey).join(', ');
    };
  }

  return {
    columnId,
    configOptionName: isConfigurable ? headerTextArray.join(' ') : '',
    defaultHideColumn,

    // ================================
    // Header
    // ================================
    headerTooltip: tooltip,
    Header: () => (
      <>
        {headerTextArray.map((txt, idx) => (
          <div key={idx}>{txt}</div>
        ))}
      </>
    ),

    // ================================
    // Footer
    // ================================
    Footer: footer,

    // ================================
    // Cell
    // ================================
    Cell: ({ useSelector, rowId }) => {
      const rowData = useSelector((s) => s.rows[rowId].data);
      const tableLayout = useSelector((s) => s.tableLayout);
      const icons = rowData[rowDataFieldName] as string[];

      const isEmpty = icons.length === 0;

      return (
        <div
          {...classes({
            element: 'icon_column',
            modifiers: { medium: tableLayout === 'medium', compact: tableLayout === 'compact' },
          })}
        >
          {isEmpty
            ? '---'
            : icons.map((iconKey, index) => {
                try {
                  const {
                    icon: IconComponent, // prettier-break-line
                    semanticColor = 'interaction-default',
                  } = iconOptions[iconKey];

                  return <IconComponent key={index} semanticColor={semanticColor} />;
                } catch (e) {
                  return <div key={index}>{iconKey}</div>;
                }
              })}
        </div>
      );
    },

    // ================================
    // Cell Alert
    // ================================
    determineCellAlert,

    // ================================
    // Cell Tooltip
    // ================================
    determineCellTooltip,

    // ================================
    // Sorting
    // ================================
    clientSideSortAscFn,

    // ================================
    // Column Filter
    // ================================
    columnFilter,

    // ================================
    // Full-Text Search
    // ================================
    clientSideFullTextSearch: {
      enable: enableClientSideFullTextSearch,
      getTextByRow: displayCellText,
    },

    // ================================
    // Download CSV
    // ================================
    clientSideCsvDownload: {
      enable: enableClientSideCsvDownload,
      headerText: headerTextArray.join(' '),
      getTextByRow: displayCellText,
    },
  };
}
