import { ReactNode } from 'react';
import * as DemoIndex from '../demos';

interface TemplateProps {
  children: ReactNode;
}
/** Transforms <div data-demo="true">ComponentName/DemoName</div> within Drupal
 * content to a live-editable demo.
 *
 * Only used in the "How to use" section of the Icon component. This can either
 * be deleted once Icon is deleted from Drupal, or this can be retained in case
 * we found other use-cases for this feature, such as adding a demo to the top
 * of each page, or for guidelines/icons.
 */
export default function Template({ children }: TemplateProps): ReactNode {
  if (typeof children === 'string') {
    const [componentOrDemoName, demoName] = children.split('/');
    if (componentOrDemoName in DemoIndex) {
      const component = DemoIndex[componentOrDemoName as keyof typeof DemoIndex];
      if (demoName) {
        /** Handles the case of <div data-demo="true">ComponentName/DemoName</div> */
        if (demoName in component) {
          const Demo = component[demoName as keyof typeof component];
          return <Demo />;
        }
      } else {
        /** Handles the case of <div data-demo="true">ComponentName</div> */
        const ComponentDemos = component.default;
        return <ComponentDemos />;
      }
    } else {
      /** Handles the case of <div data-demo="true">DemoName</div>
       * Searches all components for a matching DemoName, returning the first
       * one found.
       */
      for (const componentName in DemoIndex) {
        const componentDemos = DemoIndex[componentName as keyof typeof DemoIndex];
        if (typeof componentDemos === 'object' && children in componentDemos) {
          const Demo = componentDemos[children as keyof typeof componentDemos];
          return <Demo />;
        }
      }
    }
    return children;
  }
}
