import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMergeLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4.40368 4.42468L12.0036 12.0036M4.41782 19.5894L12.0036 12.0036M12.0036 12.0036L23 12M23 12L18 6.99997M23 12L18 17M5 3C5 4.10457 4.10458 5 3.00001 5C1.89544 5 1.00001 4.10457 1.00001 3C1.00001 1.89543 1.89544 1 3.00001 1C4.10457 1 5 1.89543 5 3ZM5 21C5 22.1045 4.10457 23 3 23C1.89543 23 1 22.1045 1 21C1 19.8954 1.89543 19 3 19C4.10457 19 5 19.8954 5 21Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgMergeLarge.displayName = 'SvgMergeLarge';
const MergeLarge = withForgeIconProps(SvgMergeLarge, {
  name: 'Merge',
  usage:
    'Use to represent the point at which two paths or journeys join to become one path',
  related: 'converge, junction',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default MergeLarge;
