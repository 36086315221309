import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCopySmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 12.6429V12.6429C10 13.9447 8.94442 15 7.64261 15C6.92043 15 6.24996 15 6 15H2V4H5"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path d="M14 4.92857V4.927L10 1V4.92857H14Z" fill={props.color} />
    <path
      d="M14 4.927H15V4.50738L14.7006 4.21341L14 4.927ZM6 12H5V13H6V12ZM6 1V0H5V1H6ZM10 1L10.7006 0.286412L10.4088 0H10V1ZM14 4.92857V5.92857C14.5523 5.92857 15 5.48086 15 4.92857H14ZM10 4.92857H9C9 5.48086 9.44772 5.92857 10 5.92857V4.92857ZM13 4.927V9H15V4.927H13ZM11 11H6V13H11V11ZM7 12V1H5V12H7ZM6 2H10V0H6V2ZM9.29943 1.71359L13.2994 5.64059L14.7006 4.21341L10.7006 0.286412L9.29943 1.71359ZM14 3.92857H10V5.92857H14V3.92857ZM11 4.92857V1H9V4.92857H11ZM15 4.92857V4.927H13V4.92857H15ZM13 9C13 10.1046 12.1046 11 11 11V13C13.2091 13 15 11.2091 15 9H13Z"
      fill={props.color}
    />
  </svg>
);
SvgCopySmall.displayName = 'SvgCopySmall';
const CopySmall = withForgeIconProps(SvgCopySmall, {
  name: 'Copy',
  usage:
    'Use in a Button to copy an item to the clipboard or to duplicate an item',
  related: 'create, clone',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default CopySmall;
