import rawContents from "!!raw-loader!./RadioChildren.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function RadioChildren():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Radio Children" />;
}

export default RadioChildren;
