import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCloseSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 1L15 15M15 1L1 15"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgCloseSmall.displayName = 'SvgCloseSmall';
const CloseSmall = withForgeIconProps(SvgCloseSmall, {
  name: 'Close',
  usage: 'Use to close a window or overlay, remove an item, or clear a field',
  related: 'x, delete, dismiss',
  isLabelRequired: false,
  isNeutralInteractive: true,
  size: 'small',
});
export default CloseSmall;
