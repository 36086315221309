import rawContents from "!!raw-loader!./Controlled.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Controlled():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Controlled" />;
}

export default Controlled;
