import PropTypes, { Requireable, Validator } from 'prop-types';
import { SEMANTIC_COLORS, ALERT_TYPES, HEADING_TAGS } from './constants';

export type Primitive = string | number | undefined;

const primitives = PropTypes.oneOfType([PropTypes.string, PropTypes.number]) as Requireable<Primitive>;

interface SelectOption {
  disabled?: boolean;
  text?: string;
  value?: Primitive;
}

const selectOptions: Validator<Array<Primitive | SelectOption> | null | undefined> = PropTypes.arrayOf(
  PropTypes.oneOfType([
    primitives.isRequired,
    PropTypes.shape({
      disabled: PropTypes.bool as Validator<boolean | undefined>,
      text: PropTypes.string as Validator<string | undefined>,
      value: primitives as Validator<Primitive>,
    }).isRequired,
  ]).isRequired
);

const ForgePropTypes = {
  semanticColors: PropTypes.oneOf(SEMANTIC_COLORS),
  alertTypes: PropTypes.oneOf(ALERT_TYPES),
  headingTags: PropTypes.oneOf(HEADING_TAGS),
  primitives,
  selectOptions,
  refType: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default ForgePropTypes;
