import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStopRecordingSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.00001 15.9995C12.4183 15.9995 16 12.4178 16 7.99951C16 3.58123 12.4183 -0.000488281 8.00001 -0.000488281C3.58172 -0.000488281 0 3.58123 0 7.99951C0 12.4178 3.58172 15.9995 8.00001 15.9995Z"
      fill="#CA0D0D"
    />
    <path d="M5 4.99951H11V10.9995H5V4.99951Z" fill="white" />
  </svg>
);
SvgStopRecordingSmall.displayName = 'SvgStopRecordingSmall';
const StopRecordingSmall = withForgeIconProps(SvgStopRecordingSmall, {
  name: 'StopRecording',
  usage: 'Use to stop recording of video meeting',
  related: 'StartRecording',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default StopRecordingSmall;
