import React, { ReactElement, Ref, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from '../utils/helpers';
import { ForgeClasses, forgeClassHelper } from '../utils/classes';
import BEMHelper from 'react-bem-helper';
import { ProgressIndicatorSize, ProgressIndicatorVariant } from './ProgressIndicator';

export interface CircularProgressProps {
  /** Aria attributes specific to determinate progress indicators, passed to the SVG */
  ariaAttributes?: {
    'aria-labelledby'?: string;
    'aria-valuemin'?: number;
    'aria-valuemax'?: number;
    'aria-valuenow'?: number;
  };
  /** Passthrough property from parent ProgressIndicator */
  baseClassname: string;
  /** Custom class(es) to pass to the root of the component */
  className?: string;
  /** Only used in the 'determinate' variant. Value between 0 and 100 (inclusive). */
  currentValue?: number;
  /** Text description to accompany the progress indicator */
  description?: string;
  /** ID to use for the description element */
  descriptionId?: string;
  /** Passthrough property from parent ProgressIndicator */
  shape: string;
  /* Only used in the 'determinate' variant. Displays the current percentage. */
  showValueLabel?: boolean;
  /** The size of circle to display */
  size?: ProgressIndicatorSize;
  /** The variant to display. Use 'indeterminate' for an unspecified or short wait time,
   * or 'determinate' for a known, longer wait time. */
  variant?: ProgressIndicatorVariant;
}

export interface CircularSVGProps {
  circleDiameter: number;
  classes: ForgeClasses<BEMHelper.ReturnObject>;
  currentValue: number;
  size: ProgressIndicatorSize;
  sharedCircleAttributes: {
    cx: number;
    cy: number;
    r: number;
    strokeWidth: number;
    fill: string;
    shapeRendering: string;
  };
  variant: ProgressIndicatorVariant;
}

export interface CircleTrackSVGProps extends CircularSVGProps {
  showValueLabel: boolean;
}

export interface CircleIndicatorSVGProps extends CircularSVGProps {
  circleCenterpoint: number;
  circleRadius: number;
}

function CircleTrackSVG({
  circleDiameter,
  classes,
  currentValue,
  sharedCircleAttributes,
  showValueLabel,
  size,
  variant,
}: CircleTrackSVGProps): ReactElement {
  const valueTextVerticalPosition = '5'; // Magic number to center text in circle (IE11 compatible way of styling)

  const showValue = showValueLabel && variant === 'determinate' && size === 'medium' && !isUndefined(currentValue);

  // text to use as accessible SVG title and possibly also display
  const valueText = variant === 'determinate' ? `${currentValue}%` : 'Loading...';

  return (
    <svg
      width={circleDiameter}
      height={circleDiameter}
      viewBox={`0 0 ${circleDiameter} ${circleDiameter}`}
      {...classes({ element: 'track-wrapper' })}
    >
      <title>{valueText}</title>
      <circle {...classes({ element: 'track' })} {...sharedCircleAttributes} />
      {showValue && (
        <text textAnchor="middle" dy={valueTextVerticalPosition} x="50%" y="50%" {...classes({ element: 'value' })}>
          {valueText}
        </text>
      )}
    </svg>
  );
}

function CircleIndicatorSVG({
  circleDiameter,
  circleRadius,
  classes,
  currentValue,
  sharedCircleAttributes,
  size,
  variant,
}: CircleIndicatorSVGProps): ReactElement {
  const circleCircumference = 2 * Math.PI * circleRadius;

  // Creates a dash 100% of circumference followed by an equal length gap
  const strokeDasharray = variant === 'determinate' ? circleCircumference : undefined;

  // To show 0% of the circle filled, offset is 100% of circumference/dasharray,
  // so to show a difference %, we calculate an inversely proportionate offset
  const strokeDashoffset =
    variant === 'determinate' && !isUndefined(currentValue)
      ? circleCircumference * (1 - currentValue / 100)
      : undefined;

  return (
    <svg
      width={circleDiameter}
      height={circleDiameter}
      viewBox={`0 0 ${circleDiameter} ${circleDiameter}`}
      {...classes({
        element: 'indicator-svg',
        modifiers: [variant, size],
      })}
    >
      <circle
        {...classes({
          element: 'indicator-circle',
          modifiers: [variant, size],
        })}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        {...sharedCircleAttributes}
      />
    </svg>
  );
}

const CircularProgress = React.forwardRef(
  (
    {
      ariaAttributes,
      baseClassname,
      className,
      currentValue = 0,
      description,
      descriptionId,
      shape,
      showValueLabel = true,
      size = 'medium',
      variant = 'indeterminate',
      ...rest
    }: CircularProgressProps,
    ref: Ref<HTMLDivElement>
  ): ReactElement => {
    const classes = forgeClassHelper({ name: baseClassname });

    const strokeWidth = size === 'medium' ? 4 : size === 'small' ? 2 : 2;
    const circleDiameter = size === 'medium' ? 48 : size === 'small' ? 24 : 16;

    const circleCenterpoint = circleDiameter * 0.5; // value to use as x and y coord to specify center of circle
    const circleRadius = circleCenterpoint - strokeWidth * 0.5; // stroke-width is calculated outwards in both directions from the radius

    // attributes that are applied to both indeterminate & determinate variants
    const sharedCircleAttributes = {
      cx: circleCenterpoint,
      cy: circleCenterpoint,
      r: circleRadius,
      strokeWidth: strokeWidth,
      fill: 'none',
      shapeRendering: 'geometricPrecision',
    };

    // props that are used by both indeterminate & determinate variants
    const sharedCircleProps = {
      circleDiameter: circleDiameter,
      classes: classes,
      currentValue: currentValue,
      size: size,
      sharedCircleAttributes: sharedCircleAttributes,
      variant: variant,
    };

    return (
      <div ref={ref} {...classes({ extra: className, modifiers: shape })} {...rest}>
        <div
          role="progressbar"
          {...classes({ element: 'circle-wrapper', modifiers: { tiny: size === 'tiny' } })}
          {...ariaAttributes}
        >
          <CircleTrackSVG showValueLabel={showValueLabel} {...sharedCircleProps} />
          <CircleIndicatorSVG
            circleCenterpoint={circleCenterpoint}
            circleRadius={circleRadius}
            {...sharedCircleProps}
          />
        </div>
        {description && (
          <div {...classes({ element: 'description' })} id={descriptionId}>
            {description}
          </div>
        )}
      </div>
    );
  }
);
CircularProgress.displayName = 'CircularProgress';

export const CircularProgressPropTypes: WeakValidationMap<CircularProgressProps> = {
  /** Aria attributes specific to determinate progress indicators, passed to the SVG */
  ariaAttributes: PropTypes.object,
  /** Custom class(es) to pass to the root of the component */
  className: PropTypes.string,
  /** Only used in the 'determinate' variant. Value between 0 and 100 (inclusive). */
  currentValue: PropTypes.number,
  /** Text description to accompany the progress indicator */
  description: PropTypes.string,
  /** ID to use for the description element */
  descriptionId: PropTypes.string,
  /* Only used in the 'determinate' variant. Displays the current percentage. */
  showValueLabel: PropTypes.bool,
  /** The size of circle to display */
  size: PropTypes.oneOf(['medium', 'small', 'tiny']),
  /** The variant to display. Use 'indeterminate' for an unspecified or short wait time,
   * or 'determinate' for a known, longer wait time. */
  variant: PropTypes.oneOf(['determinate', 'indeterminate']),
};

CircularProgress.propTypes = CircularProgressPropTypes;

export default CircularProgress;
