import Modal, { ModalProps } from '../../Modal';
import { DataTableDispatch, DataTableStore, DataTableAction } from '../store/stateTypes';

type AwaitableModalRendererProps<RowData> = {
  store: DataTableStore<RowData>;
};
export function AwaitableModalRenderer<RowData>({ store }: AwaitableModalRendererProps<RowData>): JSX.Element {
  const awaitableModals = store.useSelector((s) => s.awaitableModals);
  return (
    <>
      {awaitableModals.map((modalProps) => {
        return <Modal key={modalProps.id} {...modalProps} />;
      })}
    </>
  );
}

/**
 * Create Awaitable Modal
 *
 * - AwaitableModal is a unique function designed to create a modal component and await user input upon pressing
 *   either the "primary" or "secondary" buttons.
 * - This functionality is useful to simplify the logic handling the one-off confirm and complete modals.
 *
 * For example:
 * ```tsx
 * <Button
 *   text="Save Data"
 *   onClick={async () => {
 *     const result = await AwaitableModal(dispatch, {
 *       alertType: 'attention',
 *       headerText: 'Save Data?',
 *       children: <>Do you want to save the data?</>
 *     });
 *
 *     // user presses cancel button
 *     if (result === 'secondary') return;
 *
 *     // Do the saving process ...
 *   }}
 * />
 * ```
 *
 * @param dispatch - DataTable store dispatch function
 * @param modalProps - the modal props without id, show, onHide, and onPrimaryClick props
 * @returns {Promise<'primary' | 'secondary'>} - return 'primary' if user click primary button.
 *                                             - return 'secondary' if user click secondary button.
 */
export function AwaitableModal<RowData>(
  dispatch: DataTableDispatch<RowData>,
  modalProps: Omit<ModalProps, 'id' | 'show' | 'onHide' | 'onPrimaryClick' | 'hideDividers' | 'footerSecondaryButtons'>
): Promise<'primary' | 'secondary'> {
  return new Promise((resolve) => {
    dispatch(function push_awaitable_modal(s) {
      const modalId = `${s.tableId}-modal-${s.awaitableModalCounter}`;
      s.awaitableModalCounter++;
      s.awaitableModals.push({
        ...modalProps,
        id: modalId,
        show: true,
        hideDividers: true,
        footerSecondaryButtons: [
          {
            text: 'Cancel',
            onClick: () => {
              dispatch(remove_awaitable_modal_by_id(modalId));
              resolve('secondary');
            },
          },
        ],
        onHide: () => {
          dispatch(remove_awaitable_modal_by_id(modalId));
          resolve('secondary');
        },
        onPrimaryClick: () => {
          dispatch(remove_awaitable_modal_by_id(modalId));
          resolve('primary');
        },
      });
    });
  });
}
export type AwaitableModalType<RowData> = typeof AwaitableModal<RowData>;

// Action
function remove_awaitable_modal_by_id<RowData>(modalId: string): DataTableAction<RowData> {
  return function remove_awaitable_modal_by_id(s) {
    s.awaitableModals = s.awaitableModals.filter((modal) => {
      return modal.id !== modalId;
    });
  };
}
