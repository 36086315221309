import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHelpSimpleSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.96997 11C7.93832 10.5759 8.01321 10.1507 8.18787 9.76292C8.36252 9.37519 8.6314 9.03732 8.96997 8.78001C9.58997 8.25001 10.39 7.94009 10.97 7.36009C11.3098 7.00282 11.5755 6.58167 11.7516 6.12108C11.9277 5.66048 12.0106 5.16937 11.9957 4.6765C11.9808 4.18362 11.8684 3.69863 11.6649 3.2495C11.4614 2.80036 11.1707 2.39616 10.8099 2.06004C10.3091 1.60627 9.70361 1.28363 9.04761 1.12108C8.3916 0.958523 7.70556 0.960993 7.05078 1.1284C6.396 1.29581 5.79289 1.62284 5.29541 2.08031C4.79793 2.53778 4.42155 3.1115 4.19995 3.74999C4.09002 4.07319 4.02284 4.40937 4 4.74999"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M8 16C8.82843 16 9.5 15.3284 9.5 14.5C9.5 13.6716 8.82843 13 8 13C7.17157 13 6.5 13.6716 6.5 14.5C6.5 15.3284 7.17157 16 8 16Z"
      fill={props.color}
    />
  </svg>
);
SvgHelpSimpleSmall.displayName = 'SvgHelpSimpleSmall';
const HelpSimpleSmall = withForgeIconProps(SvgHelpSimpleSmall, {
  name: 'HelpSimple',
  usage: 'Use in compact spaces, like mobile, to get helpful information',
  related: 'question, guide, tutorial, help',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default HelpSimpleSmall;
