import MenuItem from '../../../MenuItem';
import Menu from '../../../Menu';
import Button from '../../../Button';
import { DataTableStore } from '../../store/stateTypes';
import { getSelectedRowIds } from '../../utils/getSelectedRowIds';
import { hasRowsInEditStatusFromState } from '../../utils/hasRowsInEditStatusFromState';
import { DataTableBlockUI } from '../../utils/DataTableBlockUI';
import { classes } from '../../utils/dataTableClasses';

// Actions
import { apply_bulk_edit_async } from '../../store/asyncActions/apply_bulk_edit_async';

type BulkEditMenuLayoutProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function BulkEditMenuLayout<RowData>({ store }: BulkEditMenuLayoutProps<RowData>): JSX.Element {
  const dispatchAsyncAction = store.useDispatchAsyncAction();
  const tableType = store.useSelector((s) => s.tableType);
  const bulkEdit = store.useSelector((s) => s.bulkEdit);
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));

  // Get selected Row Ids
  const rows = store.useSelector((s) => s.rows);
  const filteredAndSortedRowIds = store.useSelector((s) => s.filteredAndSortedRowIds);
  const selectedRowIds = getSelectedRowIds({ filteredAndSortedRowIds, rows });

  // Don't show the bulk edit button if no options
  if (bulkEdit.layout !== 'menu' || bulkEdit.options.length === 0) {
    return <></>;
  }

  // Disable the bulk edit button if no selected rows
  const buttonText = bulkEdit.title?.(selectedRowIds.length) ?? `Menu - Bulk Edit (${selectedRowIds.length})`;
  if (selectedRowIds.length === 0) {
    return (
      <div>
        <Button disabled={true} text={buttonText} variant="secondary" />
      </div>
    );
  }

  // Forge Feedback:
  // 1. Menu component support disabled.
  // 2. MenuItem component `onClick` event.
  return (
    <DataTableBlockUI block={hasRowsInEditStatus}>
      <div {...classes({ element: 'bulk_edit__menu_layout' })}>
        <Menu trigger={<Button text={buttonText} />} label="" hideLabel>
          {bulkEdit.options.map((opt, optIndex) => (
            <MenuItem key={optIndex}>
              <Button
                text={opt.name}
                variant="tertiary"
                onClick={() => {
                  dispatchAsyncAction(
                    apply_bulk_edit_async({
                      tableType,
                      rows,
                      bulkEdit,
                      selectedRowIds,
                      selectedBulkEditOptionIndex: optIndex,
                    })
                  );
                }}
              />
            </MenuItem>
          ))}
        </Menu>
      </div>
    </DataTableBlockUI>
  );
}
