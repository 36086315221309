import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGridLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M24 0H14V10H24V0Z" fill={props.color} />
    <path d="M10 0H0V10H10V0Z" fill={props.color} />
    <path d="M10 14H0V24H10V14Z" fill={props.color} />
    <path
      d="M14 14H24V21C24 21.7956 23.6839 22.5587 23.1213 23.1213C22.5587 23.6839 21.7956 24 21 24H14V14Z"
      fill={props.color}
    />
  </svg>
);
SvgGridLarge.displayName = 'SvgGridLarge';
const GridLarge = withForgeIconProps(SvgGridLarge, {
  name: 'Grid',
  usage: 'Use to indicate a grid, or the ability to switch to a grid view',
  related: 'gallery, tile',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default GridLarge;
