import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDownloadLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M22.999 19.78H21.999V19.798L21.9997 19.816L22.999 19.78ZM19.999 23V24H20.0164L20.0337 23.9994L19.999 23ZM0.999023 23H-0.000976562V24H0.999023V23ZM5.70613 7.29289C5.31561 6.90237 4.68244 6.90237 4.29192 7.29289C3.90139 7.68342 3.90139 8.31658 4.29192 8.70711L5.70613 7.29289ZM11.999 15L11.2919 15.7071C11.6824 16.0976 12.3156 16.0976 12.7061 15.7071L11.999 15ZM19.7061 8.70711C20.0967 8.31658 20.0967 7.68342 19.7061 7.29289C19.3156 6.90237 18.6824 6.90237 18.2919 7.29289L19.7061 8.70711ZM12.999 1C12.999 0.447715 12.5513 0 11.999 0C11.4467 0 10.999 0.447715 10.999 1H12.999ZM21.999 13V19.78H23.999V13H21.999ZM21.9997 19.816C22.0096 20.0931 21.9649 20.3694 21.868 20.6293L23.7419 21.3283C23.9307 20.8221 24.0178 20.2839 23.9984 19.7441L21.9997 19.816ZM21.868 20.6293C21.7711 20.8891 21.6239 21.1273 21.4348 21.3302L22.8982 22.6935C23.2664 22.2983 23.5531 21.8344 23.7419 21.3283L21.868 20.6293ZM21.4348 21.3302C21.2458 21.5331 21.0186 21.6968 20.7663 21.8118L21.596 23.6316C22.0875 23.4075 22.53 23.0887 22.8982 22.6935L21.4348 21.3302ZM20.7663 21.8118C20.514 21.9268 20.2415 21.991 19.9643 22.0006L20.0337 23.9994C20.5736 23.9806 21.1045 23.8557 21.596 23.6316L20.7663 21.8118ZM19.999 22H0.999023V24H19.999V22ZM1.99902 23V13H-0.000976562V23H1.99902ZM4.29192 8.70711L11.2919 15.7071L12.7061 14.2929L5.70613 7.29289L4.29192 8.70711ZM12.7061 15.7071L19.7061 8.70711L18.2919 7.29289L11.2919 14.2929L12.7061 15.7071ZM10.999 1V15H12.999V1H10.999Z"
      fill={props.color}
    />
  </svg>
);
SvgDownloadLarge.displayName = 'SvgDownloadLarge';
const DownloadLarge = withForgeIconProps(SvgDownloadLarge, {
  name: 'Download',
  usage: 'Use in a Button to download a file',
  related: 'save, export, document, import',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default DownloadLarge;
