import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSwitchViewSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.01 2.49939L15 5.49939M15 5.49939L12 8.49939M15 5.49939H7M3.99 7.49939L1 10.4994M1 10.4994L4 13.4994M1 10.4994H9"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgSwitchViewSmall.displayName = 'SvgSwitchViewSmall';
const SwitchViewSmall = withForgeIconProps(SvgSwitchViewSmall, {
  name: 'SwitchView',
  usage: 'Use to switch to an alternate page view',
  related: 'swap',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default SwitchViewSmall;
