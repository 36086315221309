import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTranslateSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6.25671 4.50439C5.59005 6.79011 5.59005 9.21868 6.25671 11.5044M9.75671 11.5044C10.4234 9.21868 10.4234 6.79011 9.75671 4.50439M4.50671 9.25439C6.79243 9.92106 9.221 9.92106 11.5067 9.25439M11.5067 6.75439C9.221 6.08773 6.79243 6.08773 4.50671 6.75439M4.55681 14.0943C6.05721 14.942 7.81754 15.2064 9.50077 14.837C11.184 14.4675 12.6718 13.49 13.6792 12.0919C14.6867 10.6937 15.143 8.97347 14.9607 7.25983C14.7785 5.54618 13.9705 3.95976 12.6916 2.80475C11.4126 1.64974 9.75263 1.00727 8.02934 1.00006C6.30605 0.992856 4.64055 1.62141 3.35197 2.76569C2.0634 3.90996 1.24225 5.48917 1.0457 7.20123C0.849139 8.91329 1.29106 10.638 2.28679 12.0445C2.28679 12.0445 1.12679 14.3946 1.03679 14.6046C0.946786 14.8146 1.22681 15.5443 4.55681 14.0943Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgTranslateSmall.displayName = 'SvgTranslateSmall';
const TranslateSmall = withForgeIconProps(SvgTranslateSmall, {
  name: 'Translate',
  usage:
    'Use to indicate languages spoken, or the ability to translate information',
  related: 'translator, speak, translation, interpreter, interpret',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default TranslateSmall;
