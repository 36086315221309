import rawContents from "!!raw-loader!./MultiSelectInFormField.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function MultiSelectInFormField():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Multi Select In Form Field" />;
}

export default MultiSelectInFormField;
