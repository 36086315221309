import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCalendarLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.29999 1V4M17.3 1V4M22 9.81006H2M2 5H22V20C22 20.7956 21.6839 21.5587 21.1213 22.1213C20.5587 22.6839 19.7956 23 19 23H2V5Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgCalendarLarge.displayName = 'SvgCalendarLarge';
const CalendarLarge = withForgeIconProps(SvgCalendarLarge, {
  name: 'Calendar',
  usage: 'Use to indicate or edit a date, like in DatePicker',
  related: 'schedule, reschedule, appointment, time, month, year',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default CalendarLarge;
