import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLeversSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 11H3M3 11C3 12.1046 3.89543 13 5 13C6.10457 13 7 12.1046 7 11M3 11C3 9.89543 3.89543 9 5 9C6.10457 9 7 9.89543 7 11M15 11H7M15 6H13M13 6C13 7.10457 12.1046 8 11 8C9.89543 8 9 7.10457 9 6M13 6C13 4.89543 12.1046 4 11 4C9.89543 4 9 4.89543 9 6M1 6H9"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgLeversSmall.displayName = 'SvgLeversSmall';
const LeversSmall = withForgeIconProps(SvgLeversSmall, {
  name: 'Levers',
  usage:
    'Use to view additional filters or to filter information in a compact space, like mobile',
  related: 'filter, sort, data',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default LeversSmall;
