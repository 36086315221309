import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMicrophoneOffSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.26087 3.55377C6.35841 2.67964 7.09983 2 7.99999 2C8.96649 2 9.74999 2.7835 9.74999 3.75V7.04289L11.4438 8.73666C11.6408 8.28084 11.75 7.77817 11.75 7.25V3.75C11.75 1.67893 10.0711 0 7.99999 0C6.56745 0 5.32252 0.803264 4.69105 1.98395L6.26087 3.55377ZM6.24999 4.95711L4.31967 3.02679C4.27395 3.2608 4.24999 3.5026 4.24999 3.75V7.25C4.24999 9.32107 5.92892 11 7.99999 11C9.17379 11 10.2216 10.4607 10.9092 9.61636L9.4789 8.18601C9.1686 8.67526 8.62222 9 7.99999 9C7.03349 9 6.24999 8.2165 6.24999 7.25V4.95711ZM11.5145 10.2216C10.8543 11.0909 9.60706 11.75 7.99999 11.75C5.75183 11.75 4.19754 10.4272 3.98784 9.09455C3.90199 8.54898 3.39012 8.1763 2.84454 8.26216C2.29897 8.34801 1.9263 8.85988 2.01215 9.40545C2.3746 11.7088 4.51271 13.333 6.99999 13.6805V15C6.99999 15.5523 7.44771 16 7.99999 16C8.55228 16 8.99999 15.5523 8.99999 15V13.6815C10.5727 13.4648 12.009 12.7433 12.9428 11.6499L11.5145 10.2216ZM13.5177 10.8106C13.7482 10.3794 13.9099 9.9085 13.9882 9.40317C14.0728 8.8574 13.6989 8.34639 13.1532 8.2618C12.6074 8.17721 12.0964 8.55106 12.0118 9.09683C12.0028 9.15483 11.9914 9.21273 11.9775 9.27043L13.5177 10.8106Z"
      fill={props.color}
    />
    <path
      d="M1 1L15 15"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgMicrophoneOffSmall.displayName = 'SvgMicrophoneOffSmall';
const MicrophoneOffSmall = withForgeIconProps(SvgMicrophoneOffSmall, {
  name: 'MicrophoneOff',
  usage: 'Use to indicate a muted microphone, or to unmute',
  related: 'audio, mic',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default MicrophoneOffSmall;
