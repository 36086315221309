import React, { ReactElement, useState, useCallback, WeakValidationMap, Ref } from 'react';
import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import PropTypes from 'prop-types';
import forwardRefToProps from '../utils/forwardRefToProps';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper({ name: 'tag' });

export type TagSize = 'small' | 'medium' | 'large';
const tagSizes: readonly TagSize[] = ['medium', 'small', 'large'];

type DefaultColor = 'default-gray';
const DEFAULT_TAG_COLOR: DefaultColor = 'default-gray';

export type TagColor =
  | 'alert-new'
  | 'alert-positive'
  | 'alert-attention'
  | 'alert-critical'
  | 'blue'
  | 'yellow'
  | 'purple'
  | 'orchid'
  | 'pistachio'
  | 'default-gray';

export const tagColors: readonly TagColor[] = [
  'alert-new',
  'alert-positive',
  'alert-attention',
  'alert-critical',
  'blue',
  'yellow',
  'purple',
  'orchid',
  'pistachio',
  DEFAULT_TAG_COLOR,
];

export type TextVariant = 'default' | 'impact';
export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  /** Ref to the button used to close a removable Tag */
  buttonRef?: Ref<HTMLButtonElement>;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Sets the tag's base color. Set the secondary prop to reduce color's strength. */
  color?: TagColor;
  /** Adds a class that can be used for styling, and hides the remove button */
  isRemovable: boolean;
  /** Handles click event on the remove element */
  onRemove?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  /** Ref to top level span */
  ref?: Ref<HTMLSpanElement>;
  /** Applies a class to change the size of the component */
  size?: TagSize;
  /** Sets the `tabindex` on the button element */
  tabIndex?: number;
  /** Optional prop that truncates text longer than 29 characters with an ellipsis (...) */
  truncateText?: boolean;
  /** If children are provided, the text provided will not be rendered but is still required for accessibility */
  text: string;
  /** Adds a class that can be used for emphatic text styling */
  textVariant?: TextVariant;
}

export interface TagComponentProps extends TagProps {
  /** Ref to top level span */
  forwardedRef?: Ref<HTMLSpanElement>;
}

const Tag = ({
  buttonRef,
  children,
  className,
  color = DEFAULT_TAG_COLOR,
  isRemovable,
  forwardedRef,
  onRemove,
  size = 'medium',
  tabIndex,
  text,
  textVariant = 'default',
  truncateText = true,
  ...rest
}: TagComponentProps): ReactElement => {
  const [active, setActive] = useState(false);

  const toggleActiveClass = useCallback(() => {
    setActive((currentActive) => !currentActive);
  }, []);

  return (
    <span
      {...rest}
      {...classes({
        modifiers: {
          [size]: !!size,
          [color]: !!color,
          'is-removable': isRemovable,
        },
        extra: className,
      })}
      onTouchStart={toggleActiveClass}
      onTouchEnd={() => {
        toggleActiveClass();
        onRemove && onRemove();
      }}
      onTouchCancel={toggleActiveClass}
      ref={forwardedRef}
      title={text}
    >
      <button
        {...classes({ element: 'remove', states: { active }, modifiers: { 'is-removable': isRemovable } })}
        onClick={onRemove}
        tabIndex={tabIndex}
        type="button"
        disabled={!isRemovable}
        ref={buttonRef}
      >
        <CloseSmall
          {...classes({ element: 'close-icon' })}
          semanticColor={
            color === 'alert-new' || color === 'alert-positive' || color === 'alert-critical' || color === 'blue'
              ? 'inverted'
              : 'default'
          }
          size="10px"
          title={`Remove ${text}`}
        />
      </button>

      {/** Text must be in the DOM after the button in order for the CSS
       * element+element Selector to recolor text based on button state.
       *
       * The `order` CSS property causes the button to render independent of
       * DOM order.
       */}
      <span
        {...classes({
          element: 'text',
          modifiers: {
            'is-removable': isRemovable,
            'impact-text': textVariant !== 'default',
            'truncate-text': truncateText,
          },
        })}
      >
        {children ? children : text}
      </span>
    </span>
  );
};

const tagPropTypes: WeakValidationMap<TagProps & { '...rest': unknown }> = {
  className: PropTypes.string,
  color: PropTypes.oneOf(tagColors),
  isRemovable: PropTypes.bool.isRequired,
  onRemove: PropTypes.func,
  size: PropTypes.oneOf(tagSizes),
  tabIndex: PropTypes.number,
  text: PropTypes.string.isRequired,
  textVariant: PropTypes.oneOf(['default', 'impact']),
  truncateText: PropTypes.bool,
  /** Additional props are passed to the component's root element. */
  '...rest': PropTypes.any,
};

Tag.propTypes = tagPropTypes;

export default forwardRefToProps(Tag);
