import React, { ReactElement } from 'react';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper('label');

export interface LabelProps {
  /** Adds a class to the root element of the component */
  className?: string;
  /** Adds a state class to the root element of the component and changes the font color */
  disabled?: boolean;
  /** The associated input's id. Usually required, but there are rare exceptions (e.g. checkbox) */
  htmlFor?: string;
  /** id of the legend or label */
  id?: string;
  /** Whether '- Required' should be appended to the end of the label text */
  required?: boolean;
  /** Text label of the associated field */
  text?: React.ReactNode;
  /** Uses `<legend>` element instead of `<label>` for use inside of a `<fieldset>` */
  useLegend?: boolean;
}

const Label = ({
  className,
  disabled,
  htmlFor,
  id,
  required = false,
  text,
  useLegend = false,
  ...props
}: LabelProps): ReactElement => {
  const WrapperElement = useLegend ? 'legend' : 'label';

  return (
    <WrapperElement
      htmlFor={useLegend ? undefined : htmlFor}
      {...props}
      {...classes({
        states: { disabled: !!disabled },
        modifiers: { legend: useLegend },
        extra: className,
      })}
      id={id}
    >
      {text}
      {required && text && (
        <span {...classes({ element: 'required' })} aria-hidden="true">
          {' '}
          - Required
        </span>
      )}
    </WrapperElement>
  );
};

export default Label;
