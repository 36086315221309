import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMoreHorizontalSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.5 9.5C2.32843 9.5 3 8.82843 3 8C3 7.17157 2.32843 6.5 1.5 6.5C0.671573 6.5 0 7.17157 0 8C0 8.82843 0.671573 9.5 1.5 9.5Z"
      fill={props.color}
    />
    <path
      d="M8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z"
      fill={props.color}
    />
    <path
      d="M14.5 9.5C15.3284 9.5 16 8.82843 16 8C16 7.17157 15.3284 6.5 14.5 6.5C13.6716 6.5 13 7.17157 13 8C13 8.82843 13.6716 9.5 14.5 9.5Z"
      fill={props.color}
    />
  </svg>
);
SvgMoreHorizontalSmall.displayName = 'SvgMoreHorizontalSmall';
const MoreHorizontalSmall = withForgeIconProps(SvgMoreHorizontalSmall, {
  name: 'MoreHorizontal',
  usage: 'Use in small spaces to open a menu with additional options',
  related: 'kebab, meatball, navigation',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default MoreHorizontalSmall;
