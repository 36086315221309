import { useEffect, useState } from 'react';
import RadioGroup from '../../../../RadioGroup';
import { AwaitableModal } from '../../../alerts/AwaitableModal';
import { DataTableFilter, CreateDataFilterCommonArgs, DataTableValueTextOption } from '../../../utils/internalTypes';

type CreateRadioGroupFilterArgs<RowData> = CreateDataFilterCommonArgs<RowData> & {
  fetchOptions: () => Promise<DataTableValueTextOption[]>;
  serverSideQueryParamFn?: (radioValue: string) => string;
};
export type CreateRadioGroupFilter<RowData> = typeof createRadioGroupFilter<RowData>;

export function createRadioGroupFilter<RowData>({
  filterId,
  filterLabel,
  rowDataFieldName,
  fetchOptions,
  serverSideQueryParamFn,
}: CreateRadioGroupFilterArgs<RowData>): DataTableFilter<RowData, string> {
  const RadioOptionMap: Record<string, DataTableValueTextOption> = {};

  return {
    filterId,
    filterLabel,

    serverSideQueryParamFn:
      typeof serverSideQueryParamFn !== 'undefined'
        ? ({ conditionValues }) => serverSideQueryParamFn(conditionValues[0])
        : undefined,

    clientSideFilterFn: ({ rowData, conditionValues }) => {
      const fieldValue = rowData[rowDataFieldName] as string;
      if (typeof fieldValue !== 'string') {
        throw new Error(`The filtered field (${String(rowDataFieldName)}) should be string type.`);
      }
      return fieldValue === conditionValues[0];
    },

    DataFilterComponent: ({ useSelector, useDispatch, onFilterConditionValuesChange }) => {
      // Each input component has distinct EMPTY_STATE, such as '', undefined, null, ...
      const EMPTY_STATE = '';
      const dispatch = useDispatch();
      const tableId = useSelector((s) => s.tableId);
      const conditionValues = useSelector((s) => s.dataFilter.filters[filterId]?.conditionValues ?? []) as string[];

      // ==============================
      // Fetch the options
      // ==============================
      const [isLoading, setIsLoading] = useState(true);
      const [options, setOptions] = useState<DataTableValueTextOption[]>([]);
      useEffect(() => {
        (async () => {
          try {
            const options = await fetchOptions();
            setOptions(options);

            for (const opt of options) {
              RadioOptionMap[opt.value] = opt;
            }
          } catch (e) {
            await AwaitableModal(dispatch, {
              alertType: 'attention',
              headerText: 'Fetch Options Failed',
              children: (e as Error).message,
            });
          }
          setIsLoading(false);
        })();
      }, [dispatch, setIsLoading, setOptions]);

      if (isLoading) {
        return <div>Loading the options...</div>;
      }

      return (
        <RadioGroup
          id={`${tableId}-radio-group-filter-${filterId}`}
          options={options}
          value={conditionValues.length === 0 ? EMPTY_STATE : conditionValues[0]}
          onChange={(e) => {
            const value = e.target.value;

            if (typeof value !== 'string') {
              throw new Error(`The value (${value}) should be string type.`);
            }
            onFilterConditionValuesChange(value === EMPTY_STATE ? [] : [value]);
          }}
        />
      );
    },

    describeConditionValue: (conditionValue) => RadioOptionMap[conditionValue]?.label ?? '',
  };
}
