import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCriticalLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.39913 3.50391L1.39639 17.5085C1.13539 17.9646 0.998662 18.4811 1.00001 19.0066C1.00136 19.5321 1.14075 20.0482 1.40408 20.5029C1.66741 20.9577 2.04544 21.3353 2.50052 21.598C2.95561 21.8608 3.47177 21.9993 3.99725 22H20.0027C20.5282 21.9993 21.0444 21.8608 21.4995 21.598C21.9546 21.3353 22.3326 20.9577 22.5959 20.5029C22.8593 20.0482 22.9986 19.5321 23 19.0066C23.0013 18.4811 22.8646 17.9646 22.6036 17.5085L14.6009 3.50391C14.3377 3.0468 13.9588 2.66698 13.5022 2.40296C13.0456 2.13895 12.5274 2 12 2C11.4726 2 10.9544 2.13895 10.4978 2.40296C10.0412 2.66698 9.66227 3.0468 9.39913 3.50391Z"
      fill="#CA0D0D"
      stroke="#CA0D0D"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M11.9961 4.85352V14.0435"
      stroke="white"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M11.9961 20.1633C12.8245 20.1633 13.4961 19.4783 13.4961 18.6333C13.4961 17.7883 12.8245 17.1035 11.9961 17.1035C11.1677 17.1035 10.4961 17.7883 10.4961 18.6333C10.4961 19.4783 11.1677 20.1633 11.9961 20.1633Z"
      fill="white"
    />
  </svg>
);
SvgCriticalLarge.displayName = 'SvgCriticalLarge';
const CriticalLarge = withForgeIconProps(SvgCriticalLarge, {
  name: 'Critical',
  usage:
    'Use only to call attention to patient safety or critical business issues. For use in alerts, not buttons; follow Forge interruption design guidance',
  related: 'alert, warning',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default CriticalLarge;
