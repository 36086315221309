import PlusButtonSmall from '@athena/forge-icons/dist/PlusButtonSmall';

import rawContents from "!!raw-loader!./SegmentedButtonBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { PlusButtonSmall };

function SegmentedButtonBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Segmented Button Basic" />;
}

export default SegmentedButtonBasic;
