/**
 * Replacement to remove lodash dependency
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_debounce
 */
export function debounce<This, Args extends unknown[]>(
  func: (this: This, ...args: Args) => void,
  wait: number,
  immediate = false
): (this: This, ...args: Args) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

  return function (this: This, ...args: Args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      timeoutId = undefined;
      if (!immediate) {
        func.apply(this, args);
      }
    }, wait);

    if (immediate && timeoutId === undefined) {
      func.apply(this, args);
    }
  };
}

/**
 * Replacement to remove lodash dependency
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isundefined
 */
export const isUndefined = <T>(value: T): boolean => value === undefined;
