import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVideoOffLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00571 4H1H0V5V19V20H1H15C16.0609 20 17.0783 19.5786 17.8284 18.8284C17.9982 18.6586 18.1512 18.4751 18.2862 18.2805L16.8247 16.8189C16.7261 17.0386 16.5877 17.2407 16.4142 17.4142C16.0392 17.7893 15.5304 18 15 18H2V6H6.00571L4.00571 4ZM17 14.1659V14V9.99997V9.56673V6H8.83414L6.83414 4H18H19V5V7.69177L20.1961 6.57048L20.2212 6.54692L20.2479 6.52514C20.5772 6.25653 20.9745 6.08434 21.3957 6.02779C21.8168 5.97125 22.2454 6.0324 22.634 6.2047C23.0224 6.37694 23.3556 6.65343 23.5965 7.00359C23.8374 7.35365 23.9766 7.76368 23.9987 8.18816L24.0001 8.21538L24 8.24263L23.98 15.7426L23.9799 15.7672L23.9786 15.7918C23.9566 16.2162 23.8174 16.6263 23.5765 16.9763C23.3357 17.3263 23.0027 17.6029 22.6141 17.7752C22.2254 17.9475 21.7968 18.0087 21.3757 17.9521C21.0447 17.9077 20.7282 17.7919 20.4478 17.6136L17 14.1659ZM21.5099 15.9186L19 13.5666V10.4332L21.53 8.06142C21.5691 8.03406 21.6144 8.01638 21.6619 8.01C21.7169 8.00261 21.7727 8.0106 21.8233 8.03304C21.874 8.05553 21.9175 8.0916 21.9489 8.13722C21.977 8.17807 21.9944 8.22515 21.9999 8.27414L21.9801 15.7041C21.9748 15.7537 21.9573 15.8014 21.9288 15.8427C21.8973 15.8885 21.8539 15.9245 21.8034 15.9469C21.7529 15.9693 21.6969 15.9773 21.6419 15.9699C21.5941 15.9635 21.5488 15.9458 21.5099 15.9186Z"
      fill={props.color}
    />
    <path
      d="M1 1L23 23"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgVideoOffLarge.displayName = 'SvgVideoOffLarge';
const VideoOffLarge = withForgeIconProps(SvgVideoOffLarge, {
  name: 'VideoOff',
  usage: 'Use to indicate disabled video, or to turn on video',
  related: 'video, camera',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default VideoOffLarge;
