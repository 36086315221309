import ExpandSmall from '@athena/forge-icons/dist/ExpandSmall';
import { SelectOption } from '../Select';
import { MouseEventHandler, ReactElement, TouchEventHandler } from 'react';
import { components, DropdownIndicatorProps, GroupBase } from 'react-select';

const CustomDropdownIndicator = <Option extends SelectOption, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
): ReactElement => {
  const {
    innerProps: { onMouseDown, onTouchEnd, 'aria-hidden': ariaHidden },
    isDisabled,
  } = props;
  return (
    <components.DropdownIndicator {...props}>
      <ExpandSmall
        size={12}
        semanticColor={isDisabled ? 'disabled' : undefined}
        onMouseDown={onMouseDown as MouseEventHandler<SVGElement> | undefined}
        onTouchEnd={onTouchEnd as TouchEventHandler<SVGElement> | undefined}
        aria-hidden={ariaHidden}
      />
    </components.DropdownIndicator>
  );
};

export default CustomDropdownIndicator;
