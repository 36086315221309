import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRegulatorySmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.57999 1L5.51999 5.06006M15 6.41992L10.94 10.48M11.5 9.5L6.5 4.5M13.5 6.5L9.5 2.5M9 7L1 15M15 15H9"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgRegulatorySmall.displayName = 'SvgRegulatorySmall';
const RegulatorySmall = withForgeIconProps(SvgRegulatorySmall, {
  name: 'Regulatory',
  usage: 'Use to indicate regulatory requirement or legal involvement',
  related: 'gavel, law, regulation, statute, ruling',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default RegulatorySmall;
