import { createRef, ReactElement, ReactNode, Ref, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { Modal as ReactOverlaysModal } from '@restart/ui';
import ModalManager from '@restart/ui/ModalManager';
import { CSSTransition } from 'react-transition-group';
import { BaseModalProps, ModalHandle, ModalTransitionComponent } from '@restart/ui/Modal';
import { forgeClassHelper } from '../utils/classes';
import { useTheme } from '../Root/ThemeContext';
import { PortalContextProps, withPortalData } from '../PortalProvider/PortalContext';
import { useModalManager } from '../Root/ModalManagerContext';
import { useKeyboardUser } from '../Root/KeyboardUserContext';
import React from 'react';

const classes = forgeClassHelper({ name: 'overlay', isPortal: true });

export type FadeProps = React.ComponentProps<ModalTransitionComponent> & { children?: ReactNode };

const Fade = (props: FadeProps): ReactElement => {
  const { children, ...transitionProps } = props;
  return (
    <CSSTransition timeout={400} classNames="fade" {...transitionProps}>
      {children}
    </CSSTransition>
  );
};

const FadeUp = (props: FadeProps): ReactElement => {
  const { children, ...transitionProps } = props; // eslint-disable-line react/prop-types
  return (
    <CSSTransition timeout={400} classNames="fade-up" {...transitionProps}>
      {children}
    </CSSTransition>
  );
};

interface OverlayBasic {
  'aria-describedby'?: string;
  'aria-labelledby'?: string;
  children?: ReactNode;
  className?: string;
  dialogClass?: { className?: string };
  show?: boolean;
  manager?: ModalManager;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
}

export type OverlayProps = OverlayBasic & React.HTMLAttributes<HTMLDivElement> & Omit<BaseModalProps, 'children'>;
export type OverlayContainerProps = OverlayProps & PortalContextProps;

const Overlay = ({
  children,
  className,
  dialogClass,
  show = false,
  manager: upstreamManager,
  portalData: { portalNode },
  ...rest
}: OverlayContainerProps): ReactElement => {
  const theme = useTheme();
  const modalRef: Ref<ModalHandle> = createRef<ModalHandle>();
  const manager = useModalManager(upstreamManager);
  const shouldTrapFocus = useKeyboardUser();

  let blockerClasses = 'fe_c_overlay__blocker';
  if (theme) {
    blockerClasses = blockerClasses + ' ' + theme.rootClasses.join(' ');
  }

  return (
    <ReactOverlaysModal
      aria-modal="true"
      renderBackdrop={(props) => <div className={blockerClasses} {...props} />}
      transition={FadeUp}
      backdropTransition={Fade}
      manager={manager}
      ref={modalRef}
      show={show}
      container={portalNode}
      {...rest}
      autoFocus={shouldTrapFocus}
      enforceFocus={shouldTrapFocus}
      {...classes({
        extra: className,
      })}
    >
      <div
        {...classes({
          element: 'dialog',
          extra: dialogClass ? dialogClass.className : '',
        })}
      >
        {children}
      </div>
    </ReactOverlaysModal>
  );
};

const overlayPropTypes: WeakValidationMap<OverlayProps & { '...rest': unknown }> = {
  /** HTML ID of element that describes the overlay */
  'aria-describedby': PropTypes.string,
  /** HTML ID of element that labels the overlay */
  'aria-labelledby': PropTypes.string,
  /** Content to display inside the overlay */
  children: PropTypes.node,
  /** Additional CSS class applied to the overlay */
  className: PropTypes.string,
  /**  class object to be passed to the dialog div */
  dialogClass: PropTypes.object,
  /** Callback fired before the Modal transitions in. Used to manage other animations while modal is opening.
      Generated by the underlying React Transition Group https://reactcommunity.org/react-transition-group/ */
  onEnter: PropTypes.func,
  /** Callback fired after the Overlay finishes transitioning in. Used to manage other animations while is opening.
      Generated by the underlying React Transition Group https://reactcommunity.org/react-transition-group/ */
  onEntered: PropTypes.func,
  /** Callback fired as the Overlay begins to transition in. Used to manage other animations while overlay is opening.
      Generated by the underlying React Transition Group https://reactcommunity.org/react-transition-group/ */
  onEntering: PropTypes.func,
  /** Callback fired right before the Overlay transitions out. Used to manage other animations while is closing.
      Generated by the underlying React Transition Group https://reactcommunity.org/react-transition-group/ */
  onExit: PropTypes.func,
  /** Callback fired after the Overlay finishes transitioning out. Used to manage other animations while is closing.
      Generated by the underlying React Transition Group https://reactcommunity.org/react-transition-group/ */
  onExited: PropTypes.func,
  /** Callback fired as the Overlay begins to transition out. Used to manage other animations while is closing.
      Generated by the underlying React Transition Group https://reactcommunity.org/react-transition-group/ */
  onExiting: PropTypes.func,
  /** A callback fired when the Overlay is opening */
  onShow: PropTypes.func,
  /**  Set the visibility of the Overlay */
  show: PropTypes.bool,
  /** Passthrough props are passed through to the @restart/ui [Modal](https://react-restart.github.io/ui/Modal) component */
  '...rest': PropTypes.any,
};

Overlay.propTypes = overlayPropTypes;

export default withPortalData(Overlay);
