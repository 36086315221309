import rawContents from "!!raw-loader!./CheckboxStandalone.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function CheckboxStandalone():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Checkbox Standalone" />;
}

export default CheckboxStandalone;
