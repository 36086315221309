import { DataTableState } from '../stateTypes';

export function apply_server_side_filters<RowData>(s: DataTableState<RowData>): void {
  // Only apply for server-side DataTable
  if (s.tableType !== 'server-side') return;

  // ==================================
  // Quick Filters
  // ==================================
  const selectedOptionIdSet = new Set(s.quickFilter.selectedOptionIds);
  s.serverSideFetchRowDataArgs.quickFilterQueryParams = s.quickFilter.options
    .filter((opt) => selectedOptionIdSet.has(opt.id))
    .map((opt) => {
      if (typeof opt.serverSideQueryParamFn === 'undefined') {
        throw new Error('Missing serverSideQueryParamFn for server-side quick filter filtering');
      }
      return opt.serverSideQueryParamFn();
    });

  // ==================================
  // Data Filters
  // ==================================
  s.serverSideFetchRowDataArgs.dataFilterQueryParams = [];
  if (s.dataFilter.show) {
    for (const { conditionValues, serverSideQueryParamFn } of Object.values(s.dataFilter.filters)) {
      if (conditionValues.length === 0) continue;
      if (typeof serverSideQueryParamFn === 'undefined') {
        throw new Error('Missing serverSideQueryParamFn for server-side data filtering');
      }

      const queryParam = serverSideQueryParamFn({ conditionValues });
      s.serverSideFetchRowDataArgs.dataFilterQueryParams.push(queryParam);
    }
  }

  // ==================================
  // Column Filters
  // ==================================
  s.serverSideFetchRowDataArgs.columnFilterQueryParams = [];
  if (s.showColumnFilters) {
    const columnFilters = s.columnIds
      .map((cid) => s.columns[cid])
      .filter((c) => c.filter.enable)
      .map((c) => c.filter);

    for (const { serverSideQueryParamFn, conditionValues } of columnFilters) {
      if (conditionValues.length === 0) continue;
      if (typeof serverSideQueryParamFn === 'undefined') {
        throw new Error('Missing serverSideQueryParamFn for server-side column filtering');
      }

      const queryParam = serverSideQueryParamFn({ conditionValues });
      s.serverSideFetchRowDataArgs.columnFilterQueryParams.push(queryParam);
    }
  }
}
