import Input from '../../../../Input';
import { DataTableFilter, CreateDataFilterCommonArgs } from '../../../utils/internalTypes';

type CreateTextInputFilterArgs<RowData> = CreateDataFilterCommonArgs<RowData> & {
  caseSensitive?: boolean; // Default: false
  serverSideQueryParamFn?: (text: string) => string;
};
export type CreateTextInputFilter<RowData> = typeof createTextInputFilter<RowData>;

export function createTextInputFilter<RowData>({
  filterId,
  filterLabel,
  rowDataFieldName,
  caseSensitive = false,
  serverSideQueryParamFn,
}: CreateTextInputFilterArgs<RowData>): DataTableFilter<RowData, string> {
  return {
    filterId,
    filterLabel,
    serverSideQueryParamFn:
      typeof serverSideQueryParamFn !== 'undefined'
        ? ({ conditionValues }) => serverSideQueryParamFn(conditionValues[0])
        : undefined,
    clientSideFilterFn: ({ rowData, conditionValues }) => {
      let fieldValue = rowData[rowDataFieldName] as string;
      if (typeof fieldValue !== 'string') {
        throw new Error(`The filtered field (${String(rowDataFieldName)}) should be string type.`);
      }

      let conditionValue = conditionValues[0];
      if (!caseSensitive) {
        fieldValue = fieldValue.toLowerCase();
        conditionValue = conditionValue.toLowerCase();
      }

      return fieldValue.includes(conditionValue);
    },

    DataFilterComponent: ({ useSelector, onFilterConditionValuesChange }) => {
      // Each input component has distinct EMPTY_STATE, such as '', undefined, null, ...
      const EMPTY_STATE = '';
      const conditionValues = useSelector((s) => s.dataFilter.filters[filterId]?.conditionValues ?? []) as string[];

      return (
        <Input
          id={`text-input-filter-${filterId}`}
          role={`text-input-filter`}
          value={conditionValues.length === 0 ? EMPTY_STATE : conditionValues[0]}
          onChange={(e) => {
            const value = e.target.value;
            onFilterConditionValuesChange(value === EMPTY_STATE ? [] : [value]);
          }}
        />
      );
    },

    describeConditionValue: (conditionValue) => conditionValue,
  };
}
