import rawContents from "!!raw-loader!./VerticalAlignment.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function VerticalAlignment():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Vertical Alignment" />;
}

export default VerticalAlignment;
