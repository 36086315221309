import { DataTableStore } from '../store/stateTypes';
import { DataTableBlockUI } from '../utils/DataTableBlockUI';
import { hasRowsInEditStatusFromState } from '../utils/hasRowsInEditStatusFromState';

type CustomBulkEditComponentWrapperProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function CustomBulkEditComponentWrapper<RowData>({
  store,
}: CustomBulkEditComponentWrapperProps<RowData>): JSX.Element {
  const CustomBulkEditComponent = store.useSelector((s) => s.CustomBulkEditComponent);
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));

  if (typeof CustomBulkEditComponent === 'undefined') {
    return <></>;
  }

  return (
    <DataTableBlockUI block={hasRowsInEditStatus}>
      <CustomBulkEditComponent
        useSelector={store.useSelector}
        useDispatch={store.useDispatch}
        useDispatchAsyncAction={store.useDispatchAsyncAction}
      />
    </DataTableBlockUI>
  );
}
