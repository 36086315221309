import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgExportSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13 12H14H13ZM1 15H0V16H1V15ZM1 3V2H0V3H1ZM14 7C14 7.55228 14.4477 8 15 8C15.5523 8 16 7.55228 16 7H14ZM15 1H16C16 0.447715 15.5523 0 15 0V1ZM9 0C8.44772 0 8 0.447715 8 1C8 1.55228 8.44772 2 9 2V0ZM8.29289 6.29289C7.90237 6.68342 7.90237 7.31658 8.29289 7.70711C8.68342 8.09763 9.31658 8.09763 9.70711 7.70711L8.29289 6.29289ZM12 9.89001V12H14V9.89001H12ZM12 12C12 12.5305 11.7893 13.0392 11.4142 13.4142L12.8284 14.8284C13.5786 14.0783 14 13.0608 14 12L12 12ZM11.4142 13.4142C11.0392 13.7893 10.5305 14 10 14V16C11.0608 16 12.0783 15.5786 12.8284 14.8284L11.4142 13.4142ZM10 14H1V16H10V14ZM2 15V3H0V15H2ZM1 4H5.70996V2H1V4ZM16 7V1H14V7H16ZM15 0H9V2H15V0ZM9.70711 7.70711L15.7071 1.70711L14.2929 0.292893L8.29289 6.29289L9.70711 7.70711Z"
      fill={props.color}
    />
  </svg>
);
SvgExportSmall.displayName = 'SvgExportSmall';
const ExportSmall = withForgeIconProps(SvgExportSmall, {
  name: 'Export',
  usage: 'Use to export a file or data',
  related: 'download, document, chart, graph',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default ExportSmall;
