import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgProviderSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.8905 13.4317C13.0555 13.4317 14 12.5368 14 11.433C14 10.3292 13.0555 9.43433 11.8905 9.43433C10.7255 9.43433 9.78101 10.3292 9.78101 11.433C9.78101 12.5368 10.7255 13.4317 11.8905 13.4317Z"
      fill={props.color}
    />
    <path
      d="M6.63789 1.32976C6.30037 1.00997 4.00103 0.330448 4.00103 2.85877C4.00103 6.01668 7.94576 8.8548 7.94576 8.8548C7.94576 8.8548 11.901 6.04666 11.901 2.85877C11.901 0.360428 9.59115 1.00997 9.26418 1.32976M7.94574 8.82489V13.0521C7.93365 13.5517 7.7245 14.0288 7.3594 14.3896C6.9943 14.7503 6.49976 14.9684 5.97337 15.0007C5.70796 14.9982 5.44576 14.9454 5.20214 14.8456C4.95852 14.7457 4.73836 14.6008 4.55465 14.4193C4.37093 14.2378 4.22728 14.0234 4.13221 13.7886C4.03713 13.5538 3.99257 13.3034 4.00101 13.0521C4.00101 11.7529 4.92918 11.433 5.97337 11.433H11.2471"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgProviderSmall.displayName = 'SvgProviderSmall';
const ProviderSmall = withForgeIconProps(SvgProviderSmall, {
  name: 'Provider',
  usage: 'Use to indicate a medical provider',
  related: 'provider, clinic, care, nurse',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default ProviderSmall;
