import AvatarBasic from './AvatarBasic.gen';
import AvatarSquare from './AvatarSquare.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <AvatarBasic />
      <AvatarSquare />
    </>
  );
}
