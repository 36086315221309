import CalendarSmall from '@athena/forge-icons/dist/CalendarSmall';
import MailSmall from '@athena/forge-icons/dist/MailSmall';
import ProfileSmall from '@athena/forge-icons/dist/ProfileSmall';

import rawContents from "!!raw-loader!./MenuBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { CalendarSmall, MailSmall, ProfileSmall };

function MenuBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Menu Basic" />;
}

export default MenuBasic;
