import { useState, useEffect, memo, useContext, ReactElement, ChangeEvent, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import './search-box.scss';
import { Hit } from '@algolia/client-search';
import useSearch from '../../hooks/useSearch';
import { SearchResult } from '../../hooks/useSearch';
import { PageContext } from '../../contexts';
import { Input, useFocusLost } from '@athena/forge';
import SearchResults from './search-results';
import { ForgeIconComponent } from '@athena/forge-icons/dist/ForgeIconProps';

interface SearchBoxProps {
  placeholder?: string;
  icon?: ForgeIconComponent;
}

const SearchBox = ({ placeholder = 'Search Forge', icon }: SearchBoxProps): ReactElement => {
  const search = useSearch();
  const { pageContext } = useContext(PageContext);
  const [searchResults, setSearchResults] = useState<Hit<SearchResult>[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setShowResults(searchResults.length > 0 && searchQuery.length > 0);
  }, [searchResults, searchQuery]);

  const searchBoxRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (): void => {
    setShowResults(false);
  };

  useFocusLost(handleClickOutside, [searchBoxRef]);

  const handleSearch = async (query: string): Promise<void> => {
    const results = await search(query);
    setSearchResults(results);
    setIsSearching(false);
  };

  const debouncedHandleSearch = useDebouncedCallback((value) => {
    handleSearch(value);
    window.amplitude.getInstance().logEvent('searches', {
      pageTitle: pageContext?.title,
      searchQuery: value,
    });
  }, 500);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(event.currentTarget.value);
    if (!event.currentTarget.value) {
      setShowResults(false);
    } else {
      setIsSearching(true);
      debouncedHandleSearch(event.currentTarget.value);
    }
  };

  const handleFocus = async (): Promise<void> => {
    if (!searchQuery || searchQuery.length === 0 || showResults) return;
    handleSearch(searchQuery);
  };

  return (
    <div ref={searchBoxRef} className="forge-guide__search-box">
      <Input placeholder={placeholder} icon={icon} value={searchQuery} onChange={handleChange} onFocus={handleFocus} />
      {showResults && (
        <SearchResults isSearching={isSearching} searchQuery={searchQuery} searchResults={searchResults} />
      )}
    </div>
  );
};

export default memo(SearchBox);
