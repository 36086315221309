import { useEffect, useState } from 'react';
import Loader from '../../../../Loader';
import Select from '../../../../Select';
import { AwaitableModal } from '../../../alerts/AwaitableModal';
import { DataTableColumn, DataTableValueTextOption } from '../../../utils/internalTypes';

type CreateSelectColumnFilterArgs<RowData> = {
  isMulti: boolean;
  rowDataFieldName: keyof RowData;
  fetchOptions: () => Promise<DataTableValueTextOption[]>;
  serverSideQueryParamFn?: (selectedOptions: ReadonlyArray<DataTableValueTextOption>) => string;
};
export type CreateSelectColumnFilter<RowData> = typeof createSelectColumnFilter<RowData>;

export function createSelectColumnFilter<RowData>({
  isMulti,
  rowDataFieldName,
  fetchOptions,
  serverSideQueryParamFn,
}: CreateSelectColumnFilterArgs<RowData>): DataTableColumn<RowData, DataTableValueTextOption>['columnFilter'] {
  return {
    serverSideQueryParamFn:
      typeof serverSideQueryParamFn !== 'undefined'
        ? ({ conditionValues }) => serverSideQueryParamFn(conditionValues)
        : undefined,

    clientSideColumnFilterFn: ({ rowData, conditionValues }) => {
      const conditionValueSet = new Set(conditionValues.map((opt) => opt.value));
      return conditionValueSet.has(String(rowData[rowDataFieldName]));
    },

    ColumnFilterComponent: ({ columnId, useSelector, useDispatch, onFilterConditionValuesChange }) => {
      const dispatch = useDispatch();
      const conditionValues = useSelector((s) => s.columns[columnId]?.filter.conditionValues ?? []);

      // ==============================
      // Fetch the options
      // ==============================
      const [isLoading, setIsLoading] = useState(true);
      const [options, setOptions] = useState<DataTableValueTextOption[]>([]);
      useEffect(() => {
        (async () => {
          try {
            const options = await fetchOptions();
            setOptions(options);
          } catch (e) {
            await AwaitableModal(dispatch, {
              alertType: 'attention',
              headerText: 'Fetch Options Failed',
              children: (e as Error).message,
            });
          }
          setIsLoading(false);
        })();
      }, [dispatch, setIsLoading, setOptions]);

      return (
        <Loader loading={isLoading} text="Loading the options...">
          <Select
            isMulti={isMulti}
            isClearable={true}
            value={conditionValues as DataTableValueTextOption[]}
            options={options}
            onChange={(e) => {
              const val = e.target.value;
              const options = (isMulti ? val : [val]) as DataTableValueTextOption[];
              onFilterConditionValuesChange(options);
            }}
          />
        </Loader>
      );
    },
  };
}
