import { ReactElement } from 'react';
import parse from 'html-react-parser';
import './text.scss';
import { textBlockOptions } from '../../../html-parsing-options';

type Props = {
  children: string;
  field_title: string;
};

const Text = ({ field_title, children }: Props): ReactElement => {
  return <div className="cms-content">{children && parse(children, textBlockOptions(field_title))}</div>;
};

export default Text;
