import rawContents from "!!raw-loader!./FormField.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function FormField():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Form Field" />;
}

export default FormField;
