import rawContents from "!!raw-loader!./HeadingsWithDescriptions.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function HeadingsWithDescriptions():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Headings With Descriptions" />;
}

export default HeadingsWithDescriptions;
