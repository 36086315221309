import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAppointmentLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2 5V4H1V5H2ZM22 5H23V4H22V5ZM22 20H23H22ZM2 23H1V24H2V23ZM8.29999 1C8.29999 0.447715 7.85227 0 7.29999 0C6.7477 0 6.29999 0.447715 6.29999 1H8.29999ZM6.29999 4C6.29999 4.55228 6.7477 5 7.29999 5C7.85227 5 8.29999 4.55228 8.29999 4H6.29999ZM18.3 1C18.3 0.447715 17.8523 0 17.3 0C16.7477 0 16.3 0.447715 16.3 1H18.3ZM16.3 4C16.3 4.55228 16.7477 5 17.3 5C17.8523 5 18.3 4.55228 18.3 4H16.3ZM22 8C22.5523 8 23 7.55228 23 7C23 6.44772 22.5523 6 22 6V8ZM2 6C1.44772 6 1 6.44772 1 7C1 7.55228 1.44772 8 2 8V6ZM16 16C16.5523 16 17 15.5523 17 15C17 14.4477 16.5523 14 16 14V16ZM8 14C7.44772 14 7 14.4477 7 15C7 15.5523 7.44772 16 8 16V14ZM13 11C13 10.4477 12.5523 10 12 10C11.4477 10 11 10.4477 11 11H13ZM11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19H11ZM2 6H22V4H2V6ZM21 5V20H23V5H21ZM21 20C21 20.5304 20.7893 21.0392 20.4142 21.4142L21.8284 22.8284C22.5786 22.0783 23 21.0609 23 20L21 20ZM20.4142 21.4142C20.0392 21.7893 19.5304 22 19 22V24C20.0609 24 21.0783 23.5786 21.8284 22.8284L20.4142 21.4142ZM19 22H2V24H19V22ZM3 23V5H1V23H3ZM6.29999 1V4H8.29999V1H6.29999ZM16.3 1V4H18.3V1H16.3ZM22 6H2V8H22V6ZM16 14H8V16H16V14ZM11 11V19H13V11H11Z"
      fill={props.color}
    />
  </svg>
);
SvgAppointmentLarge.displayName = 'SvgAppointmentLarge';
const AppointmentLarge = withForgeIconProps(SvgAppointmentLarge, {
  name: 'Appointment',
  usage: 'Use to schedule an appointment',
  related: 'calendar, date, visit, encounter, add, follow up',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default AppointmentLarge;
