import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInfoSimpleSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.5 6.27658C6.04217 5.487 6.84544 4.71749 7.80929 5.09728C8.04595 5.19187 8.25328 5.34689 8.41021 5.54699C8.56715 5.74709 8.668 5.98506 8.70276 6.23656C8.7473 6.63773 8.68864 7.04351 8.53216 7.41585C8.22091 8.20543 7.90967 8.98508 7.52814 9.74467C7.11901 10.575 6.82192 11.4556 6.6446 12.3634C6.47479 13.0167 6.56093 13.7098 6.88555 14.3023C7.08412 14.5683 7.35525 14.7719 7.66676 14.8889C7.97826 15.0058 8.31701 15.0311 8.64259 14.9618C9.40014 14.7988 10.0654 14.3513 10.5 13.7125"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.01006 2.99838C8.84182 2.99838 9.51609 2.32717 9.51609 1.49919C9.51609 0.67121 8.84182 0 8.01006 0C7.1783 0 6.50403 0.67121 6.50403 1.49919C6.50403 2.32717 7.1783 2.99838 8.01006 2.99838Z"
      fill={props.color}
    />
  </svg>
);
SvgInfoSimpleSmall.displayName = 'SvgInfoSimpleSmall';
const InfoSimpleSmall = withForgeIconProps(SvgInfoSimpleSmall, {
  name: 'InfoSimple',
  usage: 'Use in compact spaces, like mobile, to get more information',
  related: 'guide, tutorial',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default InfoSimpleSmall;
