import { graphConfig } from './authConfig';

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function baseFetch(
  accessToken: string,
  path: string,
  headers?: { [key: string]: string }
): Promise<Response> {
  const _headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  _headers.append('Authorization', bearer);

  if (headers) {
    Object.keys(headers).forEach((key) => {
      _headers.append(key, headers[key]);
    });
  }

  const options = {
    method: 'GET',
    headers: _headers,
  };

  return await fetch(`${graphConfig.graphEndpoint}${path}`, options);
}

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph<T>(accessToken: string, path: string): Promise<T> {
  const response = await baseFetch(accessToken, path);
  return response.json();
}

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraphFile(accessToken: string, path: string): Promise<void | Blob> {
  const response = await baseFetch(accessToken, path);
  return response.blob();
}
