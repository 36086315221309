import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFishboneLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 12L1 22M23 22L16 12M22.92 2L16.05 12H8.05005L1.05005 2"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgFishboneLarge.displayName = 'SvgFishboneLarge';
const FishboneLarge = withForgeIconProps(SvgFishboneLarge, {
  name: 'Fishbone',
  usage: 'Use in a Button to open fishbone diagrams in clinical settings',
  related: 'data, results, lab, labs, bloodwork, cbc',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default FishboneLarge;
