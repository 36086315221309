import { ReactElement, ReactNode, Ref, WeakValidationMap, useImperativeHandle } from 'react';
import ReactDOM from 'react-dom';
import { PortalContextProps, withPortalDataAndRef } from '../PortalProvider/PortalContext';
import PropTypes from 'prop-types';

export interface PortalProps extends PortalContextProps {
  /** The portal contents */
  children: ReactNode;
  /** The DOM node where the portal contents are mounted */
  ref?: Ref<HTMLElement>;
}

interface PortalComponentProps extends PortalProps {
  /** The DOM node where the portal contents are mounted */
  forwardedRef?: Ref<HTMLElement>;
}

/** Portal
 *
 * Creates a portal to a div within document.body, compatible with the Shadow DOM
 */
const Portal = ({ portalData: { portalNode }, children, forwardedRef }: PortalComponentProps): ReactElement => {
  useImperativeHandle(forwardedRef, () => portalNode, [portalNode]);
  /** Fragment is necessary for react-docgen to recognize this as a functional component */
  return <>{ReactDOM.createPortal(children, portalNode)}</>;
};
Portal.displayName = 'Portal';

const portalPropTypes: WeakValidationMap<PortalProps> = {
  /** The portal contents */
  children: PropTypes.node.isRequired,
};
Portal.propTypes = portalPropTypes;

export default withPortalDataAndRef(Portal);
