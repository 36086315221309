import rawContents from "!!raw-loader!./SkeletonText.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function SkeletonText():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Skeleton Text" />;
}

export default SkeletonText;
