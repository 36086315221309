import { RefObject, useEffect } from 'react';

type NodeOrRef = RefObject<Node> | Node | null;

const isRefObject = (nodeOrRef: NodeOrRef): nodeOrRef is RefObject<Node> => {
  return !!nodeOrRef && Object.prototype.hasOwnProperty.call(nodeOrRef, 'current');
};

/** Determines if a target DOM node is a child of ref */
const refContainsTarget = (nodeOrRef: NodeOrRef, target: Event['target']): boolean => {
  const node = isRefObject(nodeOrRef) ? nodeOrRef.current : nodeOrRef;
  return !!node?.contains(target as Node);
};

/** Call a callback function if focus shifts outside of a given list of refs
 *
 * Something problematic about onBlur is that it fires even when navigating
 * between elements of your component. It can also to fail to fire in certain
 * situations. Instead of relying on the 'blur' event, this hook looks at both
 * 'mousedown' and 'focus' to determine if focus is still on a child of any
 * of the provided refs.
 */
const useFocusLost = (callback: (event: Event) => void, refs: NodeOrRef[]): void => {
  useEffect(() => {
    /** Executes the callback function if event.target is a child of any
     * of the refs.
     */
    const checkForFocus = async (event: Event): Promise<void> => {
      // Drills into an open shadow Root.
      const targetElement = event.composedPath()[0];
      // Only call the callback if none of the given refs have focus
      if (targetElement instanceof Node && refs.every((ref) => !refContainsTarget(ref, targetElement))) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', checkForFocus, true);
    // Also listen for focus on other elements, as we want to trigger save
    // while tabbing through inputs if the tab is out of the current form.
    document.addEventListener('focus', checkForFocus, true);

    return () => {
      document.removeEventListener('mousedown', checkForFocus, true);
      document.removeEventListener('focus', checkForFocus, true);
    };
  }, [callback, refs]);
};
export default useFocusLost;
