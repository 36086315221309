/* The target type for a FakeEvent */
export interface FakeEventTarget<Value, RawValue> {
  value: Value;
  rawValue?: RawValue;
  id?: string;
  addEventListener: () => void;
  dispatchEvent: () => boolean;
  removeEventListener: () => void;
}

/**
 * Fake Event Object for Use by custom inputs
 * Used under the theory that better to return an obviously fake event than have unintended consequences from recycled
 * true Event objects. May need to be updated/expanded depending on feedback.
 */
export default class FakeEvent<Value, RawValue = undefined> {
  private description?: string;
  public target: FakeEventTarget<Value, RawValue>;
  public currentTarget: FakeEventTarget<Value, RawValue>;
  public stopPropagation: () => void;
  public persist: () => void;
  public nativeEvent: FakeEvent<Value, RawValue>;
  public bubbles: false;
  public cancelable: false;
  public defaultPrevented: boolean;
  public eventPhase: number;
  public isTrusted: true;
  public preventDefault: () => void;
  public isDefaultPrevented: () => boolean;
  public isPropagationStopped: () => boolean;
  public timeStamp: number;
  public type: string;

  constructor(target: Pick<FakeEventTarget<Value, RawValue>, 'value' | 'rawValue' | 'id'>) {
    const _noop = (): void => {
      return;
    };
    const value: FakeEventTarget<Value, RawValue> = {
      value: target.value,
      rawValue: target.rawValue,
      id: target.id,
      addEventListener: _noop,
      dispatchEvent: () => true,
      removeEventListener: _noop,
    };
    this.description =
      'Object sent to event handlers for triggers not produced by user input; provides a limited set of properties relative to true Event objects';
    this.target = value; // Shape it like the fake events from Cleave
    this.currentTarget = value;
    // Methods to better resemble a SyntheticEvent for compatibility with Redux-Form
    this.stopPropagation = _noop;
    this.persist = _noop;
    this.nativeEvent = this;
    this.bubbles = false;
    this.cancelable = false;
    this.defaultPrevented = false;
    this.eventPhase = Event.AT_TARGET;
    this.isTrusted = true;
    this.preventDefault = () => {
      this.defaultPrevented = true;
    };
    this.isDefaultPrevented = () => this.defaultPrevented;
    this.isPropagationStopped = () => true;
    this.timeStamp = Date.now();
    this.type = 'click';
  }
}
