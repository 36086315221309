import { useEffect, useRef, useState } from 'react';
import { DataTableStore } from '../../store/stateTypes';
import { classes } from '../../utils/dataTableClasses';
import { getAppliedFilterCountFromState } from '../../utils/getAppliedFilterCountFromState';
import { getHiddenDataFilterTagCount } from '../../utils/getHiddenDataFilterTagCount';

// Filter Panel Components
import { DataFilterTags } from './DataFilterTags';
import { DataFilterClearAllButton } from './DataFilterClearAllButton';

// Forge Components
import Tag from '../../../Tag';
import Button from '../../../Button';

// Forge Icon
import FilterSmall from '@athena/forge-icons/dist/FilterSmall';
import FilterActiveSmall from '@athena/forge-icons/dist/FilterActiveSmall';

type DataFilterHeaderProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function DataFilterHeader<RowData>({ store }: DataFilterHeaderProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const isFilterPanelExpanded = store.useSelector((s) => s.dataFilter.isExpanded);
  const appliedFilterCount = store.useSelector((s) => getAppliedFilterCountFromState(s));

  const headerRef = useRef<HTMLDivElement>(null);
  const [hiddenTagCount, setHiddenTagCount] = useState(0);
  useEffect(() => {
    if (!isFilterPanelExpanded) {
      const count = getHiddenDataFilterTagCount(headerRef);
      setHiddenTagCount(count);
    }
  }, [isFilterPanelExpanded, appliedFilterCount]);

  // ==================================
  // 1. Only Title: filter panel is expanded or has no applied filters
  // ==================================
  if (isFilterPanelExpanded || appliedFilterCount === 0) {
    return (
      <div {...classes({ element: 'filter_panel__header' })}>
        <DataFilterTitle store={store} />
      </div>
    );
  }

  // ==================================
  // 2. Full Summary: filter panel is collapsed and has applied filters
  // ==================================
  return (
    <div {...classes({ element: 'filter_panel__header' })} ref={headerRef}>
      {/* 1. Filter Title */}
      <DataFilterTitle store={store} />

      {/* 2. Filter Tags */}
      <div {...classes({ element: 'filter_panel__header__filter_tags' })}>
        <DataFilterTags store={store} />
      </div>

      {/* 3. Show More Chip & Clear All Button*/}
      <div {...classes({ element: 'filter_panel__header__show_more_chip_and_clear_all' })}>
        {/* Show More Chip */}
        {hiddenTagCount > 0 && (
          <Tag
            aria-label="data-filter-show-more-chip"
            text={`+${hiddenTagCount}`}
            color="blue"
            isRemovable={false}
            onClick={() => {
              dispatch(function user_click_show_more_chip(s) {
                s.dataFilter.isExpanded = true;
              });
            }}
          />
        )}

        {/* Clear All Button */}
        <DataFilterClearAllButton store={store} />
      </div>
    </div>
  );
}

type DataFilterTitleProps<RowData> = {
  store: DataTableStore<RowData>;
};
export function DataFilterTitle<RowData>({ store }: DataFilterTitleProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const isFilterPanelHideable = store.useSelector((s) => s.dataFilter.isHideable);
  const hasAppliedFilters = store.useSelector((s) => getAppliedFilterCountFromState(s) > 0);
  const FilterIcon = hasAppliedFilters ? FilterActiveSmall : FilterSmall;

  // ==================================
  // 1. Unclickable Filter Title
  // ==================================
  if (!isFilterPanelHideable) {
    return (
      <div {...classes({ element: 'filter_panel__header__title', modifiers: 'unclickable' })}>
        <FilterIcon semanticColor="default" />
        <span>Filters</span>
      </div>
    );
  }

  // ==================================
  // 2. Clickable Filter Title
  // ==================================
  return (
    <Button
      {...classes({ element: 'filter_panel__header__title' })}
      icon={FilterIcon}
      text="Filters"
      variant="tertiary"
      onClick={() => {
        dispatch(function user_click_expand_filter_button(s) {
          s.dataFilter.isExpanded = !s.dataFilter.isExpanded;
        });
      }}
    />
  );
}
