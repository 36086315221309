import { Validator } from 'prop-types';

/** Generates validators for non-nullable, but optional types.
 */
function generateOptionalValidationFunction<T>(expectedType: string | (new (...args: unknown[]) => T)): Validator<T> {
  return function (props, propName, componentName, location, propFullName) {
    const { [propName]: data } = props;

    if (data === null) {
      return new Error(`Null ${propName} is not allowed`);
    }

    if (
      data !== undefined &&
      ((expectedType === 'array' && Array.isArray(data)) ||
        (typeof expectedType === 'string' && typeof data !== expectedType) ||
        (typeof expectedType !== 'string' && !(data instanceof expectedType)))
    ) {
      return new Error(
        'Invalid ' +
          location +
          ' `' +
          propFullName +
          '` of type ' +
          ('`' + typeof data + '` supplied to `' + componentName + '`, expected ') +
          ('`' + expectedType + '`.')
      );
    }

    return null;
  };
}

export const optionalBoolValidator = generateOptionalValidationFunction<boolean | undefined>('boolean');
export const optionalDateValidator = generateOptionalValidationFunction<Date | undefined>(Date);
export const optionalNumberValidator = generateOptionalValidationFunction<number | undefined>('number');
export const optionalStringValidator = generateOptionalValidationFunction<string | undefined>('string');
/** This validator is a function in order to support the precise function signature
 * as a generic type */
export const optionalFunctionValidator = <T>(): Validator<T> =>
  generateOptionalValidationFunction<() => void | undefined>('function') as Validator<T>;
export const requiredFunctionValidator = <T>(): Validator<T> =>
  generateOptionalValidationFunction<() => void | undefined>('function') as Validator<T>;
