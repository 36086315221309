import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgChartSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6 9C5.44772 9 5 9.44772 5 10C5 10.5523 5.44772 11 6 11V9ZM8 11C8.55228 11 9 10.5523 9 10C9 9.44772 8.55228 9 8 9V11ZM6 6C5.44772 6 5 6.44772 5 7C5 7.55228 5.44772 8 6 8V6ZM10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6V8ZM10.34 2C9.78768 2 9.33997 2.44772 9.33997 3C9.33997 3.55228 9.78768 4 10.34 4V2ZM13 3H14V2H13V3ZM13 13H12V13.0236L12.0011 13.0472L13 13ZM11.12 15V16H11.1273L11.1346 15.9999L11.12 15ZM3 15H2V16H3V15ZM3 3V2H2V3H3ZM5.65002 4C6.20231 4 6.65002 3.55228 6.65002 3C6.65002 2.44772 6.20231 2 5.65002 2V4ZM10 3V4H11V3H10ZM6 3H5V4H6V3ZM6 11H8V9H6V11ZM6 8H10V6H6V8ZM10.34 4H13V2H10.34V4ZM12 3V13H14V3H12ZM12.0011 13.0472C12.0069 13.169 11.9881 13.2907 11.9458 13.4053L13.822 14.0979C13.9571 13.732 14.0173 13.3426 13.9989 12.9528L12.0011 13.0472ZM11.9458 13.4053C11.9035 13.5199 11.8386 13.6247 11.7551 13.7135L13.2124 15.0833C13.4797 14.799 13.687 14.4638 13.822 14.0979L11.9458 13.4053ZM11.7551 13.7135C11.6715 13.8025 11.571 13.8736 11.4594 13.9228L12.2666 15.7527C12.6237 15.5952 12.9452 15.3676 13.2124 15.0833L11.7551 13.7135ZM11.4594 13.9228C11.3479 13.972 11.2275 13.9983 11.1054 14.0001L11.1346 15.9999C11.5246 15.9942 11.9096 15.9102 12.2666 15.7527L11.4594 13.9228ZM11.12 14H3V16H11.12V14ZM4 15V3H2V15H4ZM3 4H5.65002V2H3V4ZM7 2H9V0H7V2ZM9 2L9 2.00001L10.4142 0.585801C10.0392 0.210754 9.53047 0 9 0V2ZM9 2.00001L9 2H11C11 1.46955 10.7893 0.960864 10.4142 0.585801L9 2.00001ZM9 2V3H11V2H9ZM10 2H6V4H10V2ZM7 3V2H5V3H7ZM7 2L7 2.00001L5.58579 0.585801C5.21072 0.960864 5 1.46955 5 2H7ZM7 2.00001L7 2V0C6.46953 0 5.96083 0.210754 5.58579 0.585801L7 2.00001Z"
      fill={props.color}
    />
  </svg>
);
SvgChartSmall.displayName = 'SvgChartSmall';
const ChartSmall = withForgeIconProps(SvgChartSmall, {
  name: 'Chart',
  usage: 'Use to indicate or open a patient chart',
  related: 'record, patient record',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ChartSmall;
