import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFeedbackSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1H0V2V12.2339V13.2339H1H3.4871L4.86469 15.1515C5.04842 15.4124 5.29202 15.6257 5.57541 15.7734C5.86063 15.9221 6.17778 16 6.5 16C6.82222 16 7.13937 15.9221 7.42459 15.7734C7.70798 15.6257 7.95158 15.4124 8.13531 15.1515L9.5129 13.2339H11.9997H12C13.0446 13.2341 14.0479 12.8259 14.7954 12.0963C15.543 11.3666 15.9753 10.3735 15.9997 9.32908L16 9.31741V9.30574V7.65287V7H14V7.65287V9.29289C13.9852 9.81066 13.7696 10.3027 13.3984 10.6651C13.0247 11.0298 12.5229 11.2341 12.0003 11.2339H12H9H8.4871L8.18785 11.6505L6.5078 13.9891L6.50773 13.989L6.50011 13.9999L6.5 14L6.49989 13.9999L6.49997 13.9999L6.4922 13.9891L4.81215 11.6505L4.5129 11.2339H4H2V3H10V1H1ZM15.205 3.59614L13.4208 1.81748L14.1074 1.13802C14.2006 1.04944 14.3245 1 14.4533 1C14.582 1 14.7058 1.04944 14.7991 1.13802L15.8616 2.1972C15.9504 2.29011 16 2.41353 16 2.5419C16 2.67027 15.9504 2.79375 15.8616 2.88667L15.205 3.59614ZM10.118 8.66218L8.21358 8.99694C8.18477 9.00231 8.15502 9.00059 8.12702 8.99194C8.09901 8.98329 8.0736 8.96796 8.05287 8.9473C8.03214 8.92663 8.01676 8.9013 8.00808 8.87338C7.99941 8.84546 7.99768 8.81581 8.00307 8.78708L8.33888 6.88858L10.118 8.66218ZM8.84845 6.29084L12.9175 2.23589L14.6963 4.00968L10.6272 8.06462L8.84845 6.29084Z"
      fill={props.color}
    />
  </svg>
);
SvgFeedbackSmall.displayName = 'SvgFeedbackSmall';
const FeedbackSmall = withForgeIconProps(SvgFeedbackSmall, {
  name: 'Feedback',
  usage:
    'Use to indicate the ability to leave feedback. Can link to an embedded survey',
  related: 'comment, form, survey, write, compose',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default FeedbackSmall;
