import rawContents from "!!raw-loader!./TagBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function TagBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Tag Basic" />;
}

export default TagBasic;
