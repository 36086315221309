import rawContents from "!!raw-loader!./ShowHideBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ShowHideBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Show Hide Basic" />;
}

export default ShowHideBasic;
