import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUploadSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15 12H16H15ZM1 15H0V16H1V15ZM2.84287 4.7431C2.45235 5.13364 2.45236 5.7668 2.8429 6.15732C3.23343 6.54783 3.86659 6.54782 4.25711 6.15729L2.84287 4.7431ZM7.99999 1L8.70711 0.292909C8.51957 0.105363 8.26521 0 7.99999 0C7.73476 0 7.4804 0.105363 7.29287 0.292909L7.99999 1ZM11.7429 6.15729C12.1334 6.54782 12.7665 6.54783 13.1571 6.15732C13.5476 5.7668 13.5476 5.13364 13.1571 4.7431L11.7429 6.15729ZM7 9.91016C7 10.4624 7.44772 10.9102 8 10.9102C8.55229 10.9102 9 10.4624 9 9.91015L7 9.91016ZM14 9V12H16V9H14ZM14 12C14 12.5304 13.7893 13.0389 13.4142 13.414L14.8284 14.8282C15.5785 14.0781 16 13.0609 16 12L14 12ZM13.4142 13.414C13.039 13.7892 12.5302 14 12 14V16C13.0611 16 14.0784 15.5782 14.8284 14.8282L13.4142 13.414ZM12 14H1V16H12V14ZM2 15V9H0V15H2ZM4.25711 6.15729L8.70711 1.70709L7.29287 0.292909L2.84287 4.7431L4.25711 6.15729ZM7.29287 1.70709L11.7429 6.15729L13.1571 4.7431L8.70711 0.292909L7.29287 1.70709ZM9 9.91015L8.99999 0.999999L6.99999 1L7 9.91016L9 9.91015Z"
      fill={props.color}
    />
  </svg>
);
SvgUploadSmall.displayName = 'SvgUploadSmall';
const UploadSmall = withForgeIconProps(SvgUploadSmall, {
  name: 'Upload',
  usage: 'Use to upload a file or data',
  related: 'upload, document',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default UploadSmall;
