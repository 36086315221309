import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgItalicSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M5 16L8.27731 0H11L7.72269 16H5Z" fill={props.color} />
    <path d="M14 2H4.5V0H14V2Z" fill={props.color} />
    <path d="M11.5 16H2V14H11.5V16Z" fill={props.color} />
  </svg>
);
SvgItalicSmall.displayName = 'SvgItalicSmall';
const ItalicSmall = withForgeIconProps(SvgItalicSmall, {
  name: 'Italic',
  usage:
    'Use in a ComboButton or SegmentedButton to add or remove italic text formatting',
  related: 'bold, font, underline, text, edit, text editor',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ItalicSmall;
