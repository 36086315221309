import React, { createContext, Dispatch, useCallback, useContext, useReducer, useRef } from 'react';
import { createContext as createSelectorContext, useContextSelector } from 'use-context-selector';
import { produce } from 'immer';

// Types
type Provider = (props: { children: React.ReactNode }) => JSX.Element;
export type InferDispatch<IState> = Dispatch<InferAction<IState>>;
export type InferDispatchAsyncAction<IState> = (asyncAction: InferAsyncAction<IState>) => Promise<void>;
export type InferAction<IState> = (state: IState) => void;
export type InferAsyncAction<IState> = (dispatch: InferDispatch<IState>) => Promise<void>;
type InferReducer<IState> = (state: IState, action: InferAction<IState>) => IState;
type InferSelector<IState> = <ISelected>(selector: (state: Readonly<IState>) => ISelected) => ISelected;
export type StoreOption<IState> = {
  initialState: IState;
  onDispatch?: (args: {
    actionName: string;
    currentState: Readonly<IState>;
    nextState: Readonly<IState>;
    durationTimeInMilliseconds: number;
  }) => void;
};
export type Store<IState> = {
  useDispatch: () => InferDispatch<IState>;
  useDispatchAsyncAction: () => InferDispatchAsyncAction<IState>;
  useSelector: InferSelector<IState>;
  Provider: Provider;

  /**
   * The inferTypes is not used in runtime, it's only used as a convenient way to
   * retrieve the type of State, Action, and AsyncAction.
   *
   * For example:
   * ```ts
   * const Store = createStore({ initialState: { count: 0 } });
   * type IAction = typeof Store.inferTypes.Action;
   * type IAsyncAction = typeof Store.inferTypes.AsyncAction;
   * ```
   */
  inferTypes: {
    State: IState;
    Action: InferAction<IState>;
    AsyncAction: InferAsyncAction<IState>;
  };
  testUtils: {
    testDispatchActions: (state: IState, actions: InferAction<IState>[]) => IState;
    testDispatchAsyncActions: (state: IState, asyncActions: InferAsyncAction<IState>[]) => Promise<IState>;
  };
};

/**
 * Create a store with context API
 *
 * @param option.initialState            - initial state of the store
 * @param option.onDispatch              - on action dispatch (optional)
 * @returns store.useDispatch            - The react hook to get the dispatch function
 *          store.useDispatchAsyncAction - The react hook to get the dispatchAsyncAction function
 *          store.useSelector            - The react hook to get the state from store
 *          store.Provider               - The react component to connect the store
 *          store.infer                  - Used to infer State, Action, AsyncAction types
 *          store.testUtils              - Used to test pure actions and async actions
 */
export function createStore<IState>({ initialState, onDispatch }: StoreOption<IState>): Store<IState> {
  // Contexts
  const StateContext = createSelectorContext<IState>(initialState);
  const DispatchContext = createContext<InferDispatch<IState>>(() => {
    // eslint-disable-next-line no-console
    console.error(
      '[Store] Provider is missing. Please wrap the component within the `store.Provider` to enable dispatching an action to the store.'
    );
  });

  // Reducer
  const reducer = createImmerReducer({ onDispatch });

  return {
    useDispatch: () => useContext(DispatchContext),
    useDispatchAsyncAction: () => {
      const dispatch = useContext(DispatchContext);
      return useCallback((asyncAction) => asyncAction(dispatch), [dispatch]);
    },
    useSelector: (selector) => useContextSelector(StateContext, selector),
    Provider: ({ children }) => {
      const [state, dispatch] = useReducer(reducer, initialState);
      return (
        <StateContext.Provider value={state}>
          <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
      );
    },
    inferTypes: createInferTypes(),
    testUtils: createTestUtils(reducer),
  };
}

type UseStoreHook<IState> = () => {
  /** store object which is compatible with the interface of the `createStore` */
  store: Store<IState>;
  state: IState;
  dispatch: InferDispatch<IState>;
  dispatchAsyncAction: InferDispatchAsyncAction<IState>;
};

/**
 * Create useStoreHook without context API
 *
 * @param option.initialState - initial state of the store
 * @param option.onDispatch   - on action dispatch (optional)
 * @returns useStoreHook      - The react hook to get the state, dispatch, and store function without context API
 */
export function createUseStoreHook<IState>({ initialState, onDispatch }: StoreOption<IState>): UseStoreHook<IState> {
  // Reducer
  const reducer = createImmerReducer({ onDispatch });

  return function useStore() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const dispatchAsyncAction: InferDispatchAsyncAction<IState> = useCallback(
      (asyncAction) => asyncAction(dispatch),
      [dispatch]
    );

    // use 'ref' to keep the store using the same object reference
    const storeRef = useRef<Store<IState>>({
      useDispatch: () => dispatch,
      useDispatchAsyncAction: () => dispatchAsyncAction,
      useSelector: (selector) => selector(state),
      Provider: () => <>The Provider is not needed for a non-context store.</>,
      inferTypes: createInferTypes(),
      testUtils: createTestUtils(reducer),
    });

    // update useSelector whenever re-render happened, even the state is not changed
    storeRef.current.useSelector = (selector) => selector(state);

    return {
      store: storeRef.current,
      state,
      dispatch,
      dispatchAsyncAction,
    };
  };
}

// ====================================
// Utils
// ====================================

// 1. createImmerReducer
function createImmerReducer<IState>({ onDispatch }: Pick<StoreOption<IState>, 'onDispatch'>): InferReducer<IState> {
  return function reducer(state, action) {
    // check action name
    if (action.name.length === 0 && process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn('[Store] Action name is missing. You should always give a name for the action.');
    }

    const startTime = Date.now();
    const nextState = produce(state, action);
    const durationTime = Date.now() - startTime;
    if (durationTime > 500) {
      const msg = `[Store] '${action.name}' action is consuming a significant amount of time (${durationTime} ms) and may cause the browser to become unresponsive.`;
      console.warn(msg); // eslint-disable-line no-console
    }

    onDispatch?.({
      actionName: action.name,
      currentState: state,
      nextState,
      durationTimeInMilliseconds: durationTime,
    });

    return nextState;
  };
}

// 2. createInferTypes
function createInferTypes<IState>(): Store<IState>['inferTypes'] {
  // Set values to `undefined` because `inferTypes` is not used in runtime.
  return {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    State: undefined!,
    Action: undefined!,
    AsyncAction: undefined!,
    // eslint-enable @typescript-eslint/no-non-null-assertion
  };
}

// 3. createTestUtils
function createTestUtils<IState>(reducer: InferReducer<IState>): Store<IState>['testUtils'] {
  return {
    testDispatchActions: (state, actions) => actions.reduce(reducer, state),
    testDispatchAsyncActions: async (state, asyncActions) => {
      const dispatch: InferDispatch<IState> = (action) => {
        state = reducer(state, action);
      };

      for (const asyncAction of asyncActions) {
        await asyncAction(dispatch);
      }
      return state;
    },
  };
}
