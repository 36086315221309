import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStarEmptySmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1L9.66 6.33008L15 6.3501L10.69 9.65991L12.33 15L8 11.72L3.67 15L5.31 9.65991L1 6.3501L6.34 6.33008L8 1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgStarEmptySmall.displayName = 'SvgStarEmptySmall';
const StarEmptySmall = withForgeIconProps(SvgStarEmptySmall, {
  name: 'StarEmpty',
  usage: 'Use to mark an item as a favorite',
  related: 'pin, heart, like, unlike',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default StarEmptySmall;
