import CheckboxBasic from './CheckboxBasic.gen';
import CheckboxStandalone from './CheckboxStandalone.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <CheckboxBasic />
      <CheckboxStandalone />
    </>
  );
}
