import Tag from '../../../Tag';
import { DataTableStore } from '../../store/stateTypes';
import { DATA_TABLE_FILTER_TAG_LABEL } from '../../utils/constants';

// Actions
import { reset_paginator } from '../../store/actions/reset_paginator';
import { reset_column_filters } from '../../store/actions/reset_column_filters';
import { reset_quick_filters } from '../../store/actions/reset_quick_filters';
import { apply_client_side_filters } from '../../store/actions/apply_client_side_filters';
import { apply_server_side_filters } from '../../store/actions/apply_server_side_filters';

type DataFilterTagsProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function DataFilterTags<RowData>({ store }: DataFilterTagsProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const filters = store.useSelector((s) => {
    return s.dataFilter.filterIds
      .map((fid) => s.dataFilter.filters[fid])
      .filter((df) => df.originalConditionValues.length > 0);
  });

  const filterTagElements = filters.flatMap((df) => {
    return df.originalConditionValues.map((conditionValue, index) => {
      const conditionValueString = df.describeConditionValue(conditionValue);
      return (
        <Tag
          key={`${df.filterId}-${index}`}
          aria-label={DATA_TABLE_FILTER_TAG_LABEL}
          text={`${df.filterLabel}: ${conditionValueString}`}
          isRemovable
          onRemove={() => {
            dispatch(function remove_data_filter_tag(s) {
              const filter = s.dataFilter.filters[df.filterId];
              filter.conditionValues = filter.conditionValues.filter(
                (cv) => JSON.stringify(cv) !== JSON.stringify(conditionValue)
              );
              filter.originalConditionValues = filter.conditionValues;

              // apply data filters
              reset_paginator(s);
              reset_column_filters(s);
              reset_quick_filters(s);

              apply_client_side_filters(s);
              apply_server_side_filters(s);
            });
          }}
        >
          <span className="fe_u_font-weight--semibold">{df.filterLabel}: </span>
          <span>{conditionValueString}</span>
        </Tag>
      );
    });
  });

  return <>{filterTagElements}</>;
}
