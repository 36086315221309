import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFirstPageLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20 1L9 12.11L20 23"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.00098 1L5 23"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgFirstPageLarge.displayName = 'SvgFirstPageLarge';
const FirstPageLarge = withForgeIconProps(SvgFirstPageLarge, {
  name: 'FirstPage',
  usage: 'Use to navigate to the first page in table pagination',
  related: 'last page, pagination',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default FirstPageLarge;
