import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNotificationBellLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={26}
    height={24}
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4 20C4 20 4.71214 18.3365 5 17.2188C5.77222 14.2202 5.53027 9.29004 5.53027 9.29004C5.49811 7.13166 5.7912 5.04817 7.29016 3.49487C8.78912 1.94157 10.8418 1.04468 13 1C15.1582 1.04468 17.2109 1.94157 18.7098 3.49487C20.2088 5.04817 20.4687 7.13166 20.4365 9.29004C20.4365 9.29004 20.2278 14.2202 21 17.2188C21.4726 19.0537 22 20 22 20H4Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 20.9999C16 21.7955 15.6839 22.5586 15.1213 23.1212C14.5587 23.6838 13.7956 23.9999 13 23.9999C12.2044 23.9999 11.4413 23.6838 10.8787 23.1212C10.3161 22.5586 10 21.7955 10 20.9999V19.9999H16V20.9999Z"
      fill={props.color}
    />
  </svg>
);
SvgNotificationBellLarge.displayName = 'SvgNotificationBellLarge';
const NotificationBellLarge = withForgeIconProps(SvgNotificationBellLarge, {
  name: 'NotificationBell',
  usage: 'Use to view notifications',
  related: 'bell, notify, new, notification',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default NotificationBellLarge;
