import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPaymentMethodsSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 8.00037H12M4 8.00037H5M1 13.0004V3.00037H15V10.0004C15 10.796 14.6839 11.5591 14.1213 12.1217C13.5587 12.6843 12.7956 13.0004 12 13.0004H1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgPaymentMethodsSmall.displayName = 'SvgPaymentMethodsSmall';
const PaymentMethodsSmall = withForgeIconProps(SvgPaymentMethodsSmall, {
  name: 'PaymentMethods',
  usage: 'Use to indicate or view payment methods',
  related: 'credit, debit, currency, check, cash',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default PaymentMethodsSmall;
