import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLeaveLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4.21997 22.999V21.999H4.20198L4.184 21.9997L4.21997 22.999ZM1 19.999H0V20.0164L0.00060302 20.0337L1 19.999ZM1 0.999023V-0.000976562H0V0.999023H1ZM15.2929 18.2919C14.9024 18.6824 14.9024 19.3156 15.2929 19.7061C15.6834 20.0967 16.3166 20.0967 16.7071 19.7061L15.2929 18.2919ZM23 11.999L23.7071 12.7061C24.0976 12.3156 24.0976 11.6824 23.7071 11.2919L23 11.999ZM16.7071 4.29192C16.3166 3.90139 15.6834 3.90139 15.2929 4.29192C14.9024 4.68244 14.9024 5.31561 15.2929 5.70613L16.7071 4.29192ZM9 10.999C8.44772 10.999 8 11.4467 8 11.999C8 12.5513 8.44772 12.999 9 12.999V10.999ZM11 21.999H4.21997V23.999H11V21.999ZM4.184 21.9997C3.90686 22.0096 3.63055 21.9649 3.37073 21.868L2.67175 23.7419C3.17786 23.9307 3.71614 24.0178 4.25594 23.9984L4.184 21.9997ZM3.37073 21.868C3.11091 21.7711 2.87273 21.6239 2.66983 21.4348L1.30649 22.8982C1.7017 23.2664 2.16564 23.5531 2.67175 23.7419L3.37073 21.868ZM2.66983 21.4348C2.46688 21.2458 2.30323 21.0186 2.18823 20.7663L0.368415 21.596C0.592513 22.0875 0.911321 22.53 1.30649 22.8982L2.66983 21.4348ZM2.18823 20.7663C2.0732 20.514 2.00903 20.2415 1.9994 19.9643L0.00060302 20.0337C0.0193585 20.5736 0.14434 21.1045 0.368415 21.596L2.18823 20.7663ZM2 19.999V0.999023H0V19.999H2ZM1 1.99902H11V-0.000976562H1V1.99902ZM16.7071 19.7061L23.7071 12.7061L22.2929 11.2919L15.2929 18.2919L16.7071 19.7061ZM23.7071 11.2919L16.7071 4.29192L15.2929 5.70613L22.2929 12.7061L23.7071 11.2919ZM9 12.999H23V10.999H9V12.999Z"
      fill={props.color}
    />
  </svg>
);
SvgLeaveLarge.displayName = 'SvgLeaveLarge';
const LeaveLarge = withForgeIconProps(SvgLeaveLarge, {
  name: 'Leave',
  usage: 'Use to leave the current page',
  related: 'exit, navigation, CheckIn',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default LeaveLarge;
