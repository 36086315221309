import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAnticlockwiseLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2.99996 13.8311C2.98809 15.0243 3.21156 16.2082 3.65755 17.315C4.10355 18.4218 4.76334 19.4299 5.5992 20.2816C6.43507 21.1332 7.4306 21.8118 8.52889 22.2784C9.62719 22.745 10.8067 22.9906 12 23.001C13.1931 22.9932 14.373 22.7498 15.4719 22.2847C16.5707 21.8196 17.5668 21.1421 18.403 20.291C19.2393 19.4398 19.8993 18.4319 20.3449 17.325C20.7906 16.2182 21.0132 15.0342 21 13.8411C21.007 12.6647 20.7778 11.4988 20.326 10.4126C19.8742 9.32641 19.2089 8.34195 18.3696 7.51759C17.5303 6.69322 16.534 6.04568 15.4399 5.61341C14.3458 5.18114 13.1761 4.97292 12 5.00105H4.99996M4.99996 5.00105L8.99001 1.00098M4.99996 5.00105L9 9.00098"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgAnticlockwiseLarge.displayName = 'SvgAnticlockwiseLarge';
const AnticlockwiseLarge = withForgeIconProps(SvgAnticlockwiseLarge, {
  name: 'Anticlockwise',
  usage:
    'Use to undo the last action or rotate an item counterclockwise. Customize `title` element to indicate use',
  related: 'undo, rotate, rotation, portrait, landscape',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default AnticlockwiseLarge;
