import rawContents from "!!raw-loader!./ProgressIndicatorBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ProgressIndicatorBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Progress Indicator Basic" />;
}

export default ProgressIndicatorBasic;
