import rawContents from "!!raw-loader!./Button.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Button():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Button" />;
}

export default Button;
