exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-basic-page-tsx": () => import("./../../../src/templates/basic-page.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-component-page-tsx": () => import("./../../../src/templates/component-page.tsx" /* webpackChunkName: "component---src-templates-component-page-tsx" */),
  "component---src-templates-icon-page-tsx": () => import("./../../../src/templates/icon-page.tsx" /* webpackChunkName: "component---src-templates-icon-page-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-non-component-page-tsx": () => import("./../../../src/templates/non-component-page.tsx" /* webpackChunkName: "component---src-templates-non-component-page-tsx" */),
  "component---src-templates-release-notes-page-tsx": () => import("./../../../src/templates/release-notes-page.tsx" /* webpackChunkName: "component---src-templates-release-notes-page-tsx" */)
}

