import { ChangeEvent, ReactElement, WeakValidationMap, Validator, Ref } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';

const classes = forgeClassHelper({ name: 'stepper' });

export type StepperNavigableSteps = boolean | boolean[];

export interface StepperProps {
  /** Adds a class to the root element of the component */
  className?: string;
  /** Determines which steps are complete (i.e., which steps have check marks) */
  completedSteps?: boolean[];
  /** Informs whether the presentational format of the steps is "compact" styling */
  compact?: boolean;
  /** Defines steps that are interactive. Can be a boolean array or a single boolean for all steps. */
  navigableSteps?: StepperNavigableSteps;
  /** Asserts whether or not incomplete steps will be labeled with their step count i.e., positional index + 1 */
  numerical?: boolean;
  /** Updates step to selected step if provided */
  onSelect?: (idx: number) => void;
  /** Ref to the outermost HTMLOListElement */
  ref?: Ref<HTMLOListElement>;
  /** Provides the index of the currently selected step */
  selected: number;
  /** Provides the sum total of steps OR an array of labels for each step */
  steps: number | string[];
  /** Additional props passed to the component's root element */
  '...rest'?: unknown;
}

interface StepperContainerProps extends StepperProps {
  /** Ref to the outermost HTMLOListElement */
  forwardedRef?: Ref<HTMLOListElement>;
}

const Stepper = ({
  className,
  completedSteps,
  forwardedRef,
  navigableSteps,
  numerical = true,
  onSelect,
  selected,
  steps,
  compact = false,
  ...rest
}: StepperContainerProps): ReactElement => {
  const handleSelect = (e: ChangeEvent<unknown>, idx: number): void => {
    e.preventDefault();
    if (onSelect) onSelect(idx);
  };
  /**
   * Determines if a step is navigable
   *
   * - if we do not have navigable steps, or we are evaluating the current step, return false
   * - otherwise, return navigable status contingent on the navigableSteps data type and corresponding value
   */
  const isNavigableStep = (index: number): boolean => {
    if (!navigableSteps || index === selected) {
      return false;
    }
    return Array.isArray(navigableSteps) ? navigableSteps[index] : navigableSteps;
  };

  /**
   * Determines if a step is completed.
   *
   * If we have an array of steps AND we have a step at that index, we must determine whether or not that is the selected step to determine it's completion status:
   * - if it IS the selected step, return false (regardless of positional value in the array)
   * - if not, return the positional value at the index
   *
   * Otherwise, we determine whether or not it is completed by evaluating the position of the indices
   * - if it is before, it is complete; if not, it is false
   */
  const isStepCompleted = (index: number): boolean => {
    if (completedSteps && completedSteps.length) return !!completedSteps[index] && index !== selected;
    return index < selected;
  };

  const numberOfSteps = Array.isArray(steps) ? steps.length : steps;
  const stepsJSX = [];

  for (let idx = 0; idx < numberOfSteps; idx++) {
    const isCompleted = isStepCompleted(idx);
    const isSelectedStep = selected === idx;

    const innerStepJSX = (
      <div {...classes({ element: 'step' })}>
        {(isCompleted || isSelectedStep) && (
          <span {...classes({ element: 'status-label' })}>{isCompleted ? 'Completed: ' : 'Current: '}</span>
        )}
        <div
          {...classes({
            element: 'ball',
            states: { complete: isCompleted, selected: isSelectedStep },
          })}
        >
          {numerical && !isCompleted && idx + 1}
        </div>
        {Array.isArray(steps) && steps[idx] && (
          <div
            {...classes({
              element: 'description',
              states: { complete: isCompleted, selected: isSelectedStep },
            })}
          >
            {steps[idx]}
          </div>
        )}
      </div>
    );
    stepsJSX.push(
      <li
        key={Array.isArray(steps) ? steps[idx] : idx}
        {...classes({
          element: 'item',
          states: {
            selected: isSelectedStep,
            complete: isCompleted,
          },
        })}
      >
        {isNavigableStep(idx) ? (
          <button
            {...classes({ element: 'link', states: { complete: isCompleted } })}
            onClick={(event) => handleSelect(event, idx)}
            type="button"
          >
            {innerStepJSX}
          </button>
        ) : (
          innerStepJSX
        )}
      </li>
    );
  }

  return (
    <ol
      {...classes({
        modifiers: compact ? 'compact' : undefined,
        extra: className,
      })}
      ref={forwardedRef}
      {...rest}
    >
      {stepsJSX}
    </ol>
  );
};

const stepperPropTypes: WeakValidationMap<StepperProps & { '...rest': unknown }> = {
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Informs whether the presentational format of the steps is "compact" styling */
  compact: PropTypes.bool,
  /** Determines which steps are complete (i.e., which steps have check marks) */
  completedSteps: PropTypes.arrayOf(PropTypes.bool) as Validator<boolean[]>,
  /** Defines steps that are interactive. Can be a boolean array or a single boolean for all steps. */
  navigableSteps: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool),
  ]) as Validator<StepperNavigableSteps>,

  /** Asserts whether or not incomplete steps will be labeled with their step count i.e., positional index + 1 */
  numerical: PropTypes.bool,

  /** Updates step to selected step if provided */
  onSelect: PropTypes.func,

  /** Provides the index of the currently selected step */
  selected: PropTypes.number.isRequired,

  /** Provides the sum total of steps OR an array of labels for each step */
  steps: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.number]).isRequired as Validator<
    string[] | number
  >,

  /** Additional props are passed to the component's root element */
  '...rest': PropTypes.any,
};

Stepper.propTypes = stepperPropTypes;
Stepper.displayName = 'Stepper';

export default forwardRefToProps(Stepper);
