import BEMHelper, { withDefaults, ConstructorOptions, StringConstructorOptions, ReturnObject } from 'react-bem-helper';
import { NAMESPACE, COMPONENT_PREFIX, STATE_PREFIX, GLOBAL_FEATURE } from './constants';
import classnames from 'classnames';
import { Theme } from '../Root/context';

export type BEMHelperConstructorArg = Partial<ConstructorOptions>;

export type ForgeClassHelperArg =
  | undefined
  | string
  | ({
      isPortal?: boolean;
    } & BEMHelperConstructorArg);

const helperFactory = withDefaults({
  prefix: `${NAMESPACE}_${COMPONENT_PREFIX}_`,
  outputIsString: false,
});

const stateHelper = BEMHelper({
  prefix: `${NAMESPACE}_`,
  name: `${STATE_PREFIX}`,
  modifierDelimiter: '-',
  outputIsString: true,
});

/**
 * Generates Forge state classes using an extremely flexible API. Based on react-bem-helper.
 * @param {string} or {string[]} or {array} states - set of states to generate classes for. See https://github.com/marcohamersma/react-bem-helper#modifiers for more details.
 * @param {string} or {string[]} or {array} extra - additional classes. Accepts same argument structures as states
 *
 * @return {string} all of the Forge state classes
 * For examples, see the unit tests.
 */
export const stateClasses = (states?: BEMHelper.WordSet, extra?: BEMHelper.WordSet): string => {
  const fullStr = stateHelper('', states, extra);

  // The first class will just be fe_is, so remove that.
  const firstSpace = fullStr.indexOf(' ');
  return firstSpace === -1 ? '' : fullStr.substring(firstSpace + 1);
};

export type ElementObject = BEMHelperConstructorArg & {
  states?: BEMHelper.WordSet;
  element?: string;
  modifiers?: BEMHelper.WordSet;
  theme?: Theme;
  extra?: BEMHelper.WordSet;
  removeBase?: boolean;
};
export type ForgeClassHelperOptions = undefined | string | ElementObject;

function isObject(obj: unknown): boolean {
  const type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
}

const isForgeClassHelperArg = (obj: ForgeClassHelperArg): obj is BEMHelperConstructorArg => isObject(obj);
const isElementObject = (obj: ForgeClassHelperOptions): obj is ElementObject => isObject(obj);

/** The type of the function returned by forgeClassHelper */
export type ForgeClasses<T> = (
  first?: ForgeClassHelperOptions,
  modifiers?: BEMHelper.WordSet | undefined,
  states?: BEMHelper.WordSet | undefined,
  extra?: BEMHelper.WordSet | undefined,
  theme?: ElementObject['theme']
) => T;

/**
 * forgeClassHelper is a utility method to accelerate generating BEM-style Forge classNames.
 * This is a factory method that returns a method for generating classNames.
 * @param {object} options:
 *  name (required) - the component name
 *  isPortal - if true the GLOBAL_FEATURE class will be added to generated class names.
 *  theme - adds root classes specified in the theme (only in portals).
 *  outputIsString - if true, the generated method will return a string. By default, returns an object with a className
 * field for use with spread syntax.
 * @returns {function} generates className as described below
 */
export function forgeClassHelper<T extends ForgeClassHelperArg>(
  options: T
): ForgeClasses<T extends StringConstructorOptions ? string : ReturnObject> {
  const elemClasses = helperFactory(options as Required<BEMHelperConstructorArg>);
  const isPortal = isForgeClassHelperArg(options) && options.isPortal;
  /**
   * Generates Forge classes using an extremely flexible API. Based on react-bem-helper.
   * @param {string} element - If set, indicates the part (or element in BEM terminology) to generate classes for. If
   * omitted, generates classes for the component root.
   * @param {string} or {string[]} or {array} modifiers - set of variations (modifiers in BEM terminology) to generate
   * classes for. See https://github.com/marcohamersma/react-bem-helper#modifiers for more details.
   * @param {string} or {string[]} or {array} states - set of states to generate classes for. Accepts same argument
   * structures as modifiers.
   * @param {string} or {string[]} or {array} extra - any other classes to include. Accepts same argument structures as
   * modifiers.
   * Instead of separate parameters, this function can be called with a single object with optional `element`,
   * `modifiers`, `states`, and `extra` fields. This form is more explicit, but sometimes more verbose.
   * When using this form the base class can be removed by passing the removeBase flag set to true.
   *
   * @return {object} or {string} all of the Forge classes (and included extras) needed for an element
   * For examples, see the unit tests.
   */
  const forgeClasses: ForgeClasses<T extends StringConstructorOptions ? string : ReturnObject> = (
    first,
    modifiers,
    states,
    extra,
    theme
    // eslint-disable-next-line max-params
  ) => {
    if (isElementObject(first)) {
      first = {
        ...first,
        ...{
          extra: classnames(
            first.states && stateClasses(first.states),
            !first.element && isPortal && GLOBAL_FEATURE,
            !first.element && isPortal && first.theme && first.theme.rootClasses,
            first.extra
          ),
        },
      };
    } else {
      extra = classnames(
        extra,
        states && stateClasses(states),
        !first && isPortal && GLOBAL_FEATURE,
        !first && isPortal && theme && theme.rootClasses
      );
    }

    //Below cast is technically lying, but the BEMHelper typescript definitions
    //are more rigid than their javascript library supports.
    const classes = elemClasses(first as string, modifiers, extra) as T extends StringConstructorOptions
      ? string
      : ReturnObject;

    if (isElementObject(first) && first.removeBase && classes.className) {
      const firstSpace = classes.className.indexOf(' ');
      const className = firstSpace === -1 ? '' : classes.className.substring(firstSpace + 1);
      classes.className = className;
    }

    return classes;
  };
  return forgeClasses;
}
