import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLockLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 9.00008V24.0001H16.907C19.1675 24.0001 21 22.2092 21 20.0001V9.00008H3ZM13.2325 15C13.2325 14.4477 12.7744 14 12.2093 14C11.6441 14 11.186 14.4477 11.186 15V18C11.186 18.5523 11.6441 19 12.2093 19C12.7744 19 13.2325 18.5523 13.2325 18V15Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C10.8954 2 10 2.89543 10 4V9C10 9 9.55228 9.00008 9 9.00008C8.44772 9.00008 8 9 8 9V4C8 1.79086 9.79086 0 12 0C14.2091 0 16 1.79086 16 4V9C16 9 15.5523 9 15 9C14.4477 9 14 9 14 9V4C14 2.89543 13.1046 2 12 2Z"
      fill={props.color}
    />
  </svg>
);
SvgLockLarge.displayName = 'SvgLockLarge';
const LockLarge = withForgeIconProps(SvgLockLarge, {
  name: 'Lock',
  usage: 'Use to denote private, classified, or encrypted content',
  related: 'Unlock',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default LockLarge;
