import rawContents from "!!raw-loader!./LoaderBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function LoaderBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Loader Basic" />;
}

export default LoaderBasic;
