import rawContents from "!!raw-loader!./TasksComplete.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function TasksComplete():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Tasks Complete" />;
}

export default TasksComplete;
