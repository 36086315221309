import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVaccineLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 23L4.74001 19.2598L4.37 19.6299M19.29 1L23 4.70996M21.15 2.8501L18.89 5.10986M7 12H17M4.75003 19.25C4.0132 18.4621 3.61931 17.4136 3.65493 16.3354C3.69056 15.2573 4.15273 14.2376 4.93997 13.5L16.11 2.33008L21.6701 7.89014L10.5 19.0601C9.76246 19.8473 8.74251 20.3096 7.66433 20.3452C6.58615 20.3808 5.53795 19.9868 4.75003 19.25Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgVaccineLarge.displayName = 'SvgVaccineLarge';
const VaccineLarge = withForgeIconProps(SvgVaccineLarge, {
  name: 'Vaccine',
  usage: 'Use to indicate a vaccine, or to view or edit vaccine information',
  related: 'vaccination, vaccines, shot, injection',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default VaccineLarge;
