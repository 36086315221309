import { useEffect } from 'react';
import { AwaitableModal } from '../alerts/AwaitableModal';
import { DataTableStore } from '../store/stateTypes';
import { refresh_row_data } from '../store/actions/refresh_row_data';

type ServerSideDataFetcherProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function ServerSideDataFetcher<RowData>({ store }: ServerSideDataFetcherProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const fetchRowData = store.useSelector((s) => s.serverSideFetchRowDataFn);
  const fetchRowDataArgs = store.useSelector((s) => s.serverSideFetchRowDataArgs);
  const forceFetchRowDataCounter = store.useSelector((s) => s.forceFetchRowDataCounter);
  const tableType = store.useSelector((s) => s.tableType);
  useEffect(() => {
    if (tableType !== 'server-side') return;

    (async function () {
      dispatch(function before_fetch_row_data(s) {
        s.loader.show = true;
      });

      try {
        const { rowData, totalCount } = await fetchRowData(fetchRowDataArgs);
        dispatch(function fetch_row_data_success(s) {
          s.loader.show = false;
          s.pagination.serverSideTotalCount = totalCount;
          refresh_row_data(rowData)(s);
        });
      } catch (e) {
        dispatch(function fetch_row_data_failed(s) {
          s.loader.show = false;
        });

        await AwaitableModal(dispatch, {
          alertType: 'attention',
          headerText: 'Fetch Data Failed',
          children: (e as Error).message,
        });
      }
    })();
  }, [dispatch, fetchRowData, fetchRowDataArgs, forceFetchRowDataCounter, tableType]);

  return <></>;
}
