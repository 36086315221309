import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgEditSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4101 5.19228L10.8416 1.63497L12.2148 0.276032C12.4012 0.0988794 12.649 0 12.9065 0C13.1641 0 13.4117 0.0988794 13.5981 0.276032L15.7231 2.39439C15.9008 2.58023 16 2.82706 16 3.0838C16 3.34055 15.9008 3.5875 15.7231 3.77333L14.4101 5.19228Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.23608 15.3244L0.427169 15.9939C0.369534 16.0046 0.31004 16.0012 0.254032 15.9839C0.198023 15.9666 0.147193 15.9359 0.105733 15.8946C0.0642733 15.8533 0.0335123 15.8026 0.0161664 15.7468C-0.00117957 15.6909 -0.00463679 15.6316 0.00613294 15.5742L0.67776 11.7772L4.23608 15.3244Z"
      fill={props.color}
    />
    <path
      d="M9.83508 2.47178L1.6969 10.5817L5.25436 14.1293L13.3925 6.01935L9.83508 2.47178Z"
      fill={props.color}
    />
  </svg>
);
SvgEditSmall.displayName = 'SvgEditSmall';
const EditSmall = withForgeIconProps(SvgEditSmall, {
  name: 'Edit',
  usage: 'Use to make changes to an item or information',
  related: 'update, compose, note, change',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default EditSmall;
