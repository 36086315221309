import RawDataPreview from '../../components/raw-data-preview';

import rawContents from "!!raw-loader!./RequiredDateRange.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { RawDataPreview };

function RequiredDateRange():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Required Date Range" />;
}

export default RequiredDateRange;
