import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClockwiseLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M21.0001 13.8299C21.0119 15.0232 20.7885 16.2071 20.3425 17.3139C19.8965 18.4207 19.2367 19.4288 18.4008 20.2805C17.565 21.1321 16.5694 21.8107 15.4711 22.2773C14.3728 22.7439 13.1933 22.9895 12.0001 22.9999C10.8069 22.9921 9.62698 22.7487 8.52814 22.2836C7.42931 21.8186 6.43325 21.141 5.59699 20.2899C4.76073 19.4387 4.10074 18.4308 3.6551 17.3239C3.20945 16.2171 2.98687 15.033 3.00007 13.8399C2.99304 12.6635 3.22219 11.4976 3.67402 10.4114C4.12584 9.32525 4.79115 8.34079 5.63044 7.51643C6.46972 6.69206 7.46599 6.04465 8.56012 5.61238C9.65425 5.1801 10.824 4.97182 12.0001 4.99995H19.0001M19.0001 4.99995L15.0101 1M19.0001 4.99995L15.0001 9"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgClockwiseLarge.displayName = 'SvgClockwiseLarge';
const ClockwiseLarge = withForgeIconProps(SvgClockwiseLarge, {
  name: 'Clockwise',
  usage:
    'Use to redo the last action or rotate an item clockwise. Customize `title` element to indicate use',
  related: 'rotate, rotation, undo, portrait, landscape, navigation',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ClockwiseLarge;
