import Toast from '../../Toast';
import { DataTableStore } from '../store/stateTypes';

type DataTableToastManagerProps<RowData> = {
  store: DataTableStore<RowData>;
};

/**
 * The DataTableToastManager an internal component under the DataTable component.
 * 1. It allows to display of multiple toasts on the screen, with the existing toast remaining
 *    visible when a new one appears.
 * 2. It stacks the toasts, causing the new toast to overlay the previous one.
 * 3. Upon closing the top toast, users will see the underlying toast.
 */
export function DataTableToastManager<RowData>({ store }: DataTableToastManagerProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const tableId = store.useSelector((s) => s.tableId);
  const toastStack = store.useSelector((s) => s.toastStack);

  return (
    <>
      {toastStack.map((toastProps, index) => (
        <Toast
          {...toastProps}
          id={`${tableId}-toast-alert`}
          key={index}
          onDismiss={() => {
            dispatch(function pop_toast_stack(s) {
              s.toastStack.pop();
            });
          }}
        />
      ))}
    </>
  );
}
