import { useRef } from 'react';
import { DataTableState, DataTableStore } from '../../store/stateTypes';
import { ColumnConfigPopoverHeader } from './ColumnConfigPopoverHeader';
import { ColumnConfigDraggableOptions } from './ColumnConfigDraggableOptions';

// Utils
import { hasRowsInEditStatusFromState } from '../../utils/hasRowsInEditStatusFromState';
import { DataTableBlockUI } from '../../utils/DataTableBlockUI';
import { classes } from '../../utils/dataTableClasses';

// Forge components, hook, and icons
import Popover from '../../../Popover';
import Button from '../../../Button';
import useClickOutside from '../../../useClickOutside';
import SettingsSmall from '@athena/forge-icons/dist/SettingsSmall';

type ColumnConfigButtonProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function ColumnConfigButton<RowData>({ store }: ColumnConfigButtonProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const configButton = store.useSelector((s) => s.columnConfig);
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));
  const configButtonRef = useRef<HTMLButtonElement>(null);

  // Handle Popover Click Outside
  // https://guide.forge.athena.io/components/popover#demos
  const containerRef = useRef<HTMLDivElement>(null); // contain the filter 'button' and 'popover'
  useClickOutside(containerRef, () => {
    if (configButton.isPopoverOpen) {
      dispatch(close_column_config_button_popover);
    }
  });

  // column config button is disabled
  if (!configButton.enable) {
    return <></>;
  }

  return (
    <DataTableBlockUI block={hasRowsInEditStatus}>
      <div
        {...classes({ element: 'column_config' })}
        ref={containerRef} // containerRef contain the both filter 'button' and 'popover'
        onMouseDown={(e) => e.stopPropagation()}
      >
        {/* Column Config Button */}
        <Button
          ref={configButtonRef}
          icon={SettingsSmall}
          variant="tertiary"
          text="Configure Columns"
          onClick={() => {
            dispatch(function use_click_column_config_button(s) {
              s.columnConfig.isPopoverOpen = !s.columnConfig.isPopoverOpen;
            });
          }}
        />

        {/* Column Config Popover */}
        <Popover
          targetRef={configButtonRef}
          isOpen={configButton.isPopoverOpen}
          placement="bottom"
          onClose={() => dispatch(close_column_config_button_popover)}
        >
          <div {...classes({ element: 'column_config__popover' })}>
            {/* Column Config Popover Header */}
            <ColumnConfigPopoverHeader store={store} />

            {/* ColumnConfigOptions */}
            <ColumnConfigDraggableOptions store={store} />
          </div>
        </Popover>
      </div>
    </DataTableBlockUI>
  );
}

// ====================================
// Internal Actions
// ====================================
function close_column_config_button_popover<RowData>(s: DataTableState<RowData>): void {
  s.columnConfig.isPopoverOpen = false;
}
