import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgShareLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3 15C4.65685 15 6 13.6569 6 12C6 10.3431 4.65685 9 3 9C1.34315 9 0 10.3431 0 12C0 13.6569 1.34315 15 3 15Z"
      fill={props.color}
    />
    <path
      d="M21 6C22.6569 6 24 4.65685 24 3C24 1.34315 22.6569 0 21 0C19.3431 0 18 1.34315 18 3C18 4.65685 19.3431 6 21 6Z"
      fill={props.color}
    />
    <path
      d="M21 24C22.6569 24 24 22.6569 24 21C24 19.3431 22.6569 18 21 18C19.3431 18 18 19.3431 18 21C18 22.6569 19.3431 24 21 24Z"
      fill={props.color}
    />
    <path
      d="M21 3L3 12L21 21"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgShareLarge.displayName = 'SvgShareLarge';
const ShareLarge = withForgeIconProps(SvgShareLarge, {
  name: 'Share',
  usage: 'Use to open sharing options',
  related: 'send, export',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ShareLarge;
