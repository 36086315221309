import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStopRecordingLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 23.9995C18.6274 23.9995 24 18.6269 24 11.9995C24 5.37209 18.6274 -0.000488281 12 -0.000488281C5.37259 -0.000488281 0 5.37209 0 11.9995C0 18.6269 5.37259 23.9995 12 23.9995Z"
      fill="#CA0D0D"
    />
    <path d="M7 6.99951H17V16.9995H7V6.99951Z" fill="white" />
  </svg>
);
SvgStopRecordingLarge.displayName = 'SvgStopRecordingLarge';
const StopRecordingLarge = withForgeIconProps(SvgStopRecordingLarge, {
  name: 'StopRecording',
  usage: 'Use to stop recording of video meeting',
  related: 'StartRecording',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default StopRecordingLarge;
