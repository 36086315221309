import { Hit } from '@algolia/client-search';
import { ProgressIndicator } from '@athena/forge';
import { ReactElement, useRef } from 'react';
import { SearchResult } from '../../hooks/useSearch';
import SearchBoxResults from './search-box-results';

interface SearchResultsProps {
  isSearching: boolean;
  searchQuery: string;
  searchResults: Hit<SearchResult>[];
}

const NoHits = (): ReactElement => (
  <>
    <div className="fe_u_text-align--center fe_u_font-size--large fe_u_font-weight--semibold">
      We could not find what you are looking for
    </div>
    <div className="fe_u_text-align--center">You can try changing your search term to expand your search.</div>
  </>
);

const SearchResults = ({ isSearching, searchQuery, searchResults }: SearchResultsProps): ReactElement => {
  const searchResultsRef = useRef<HTMLDivElement>(null);

  return (
    <div className="forge-guide__search-results" ref={searchResultsRef} tabIndex={-1}>
      {isSearching ? (
        <div className="fe_u_flex-container fe_u_flex-justify-content--center">
          <ProgressIndicator
            animateTransitions={false}
            shape="circular"
            variant="indeterminate"
            description="Searching..."
            size="small"
          />
        </div>
      ) : (
        <>
          {searchQuery.length > 2 && searchResults.length === 0 && <NoHits />}
          <SearchBoxResults hits={searchResults} />
        </>
      )}
    </div>
  );
};

export default SearchResults;
