import { Time } from '@internationalized/date';

import rawContents from "!!raw-loader!./HourCycle.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { Time };

function HourCycle():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Hour Cycle" />;
}

export default HourCycle;
