import { DataTableAction } from '../stateTypes';

/**
 * This action will be used whenever update the row data from external resource, such as
 * the first time init the DataTable or retrieved the data from the backend service.
 */
export function refresh_row_data<RowData>(data: ReadonlyArray<RowData>): DataTableAction<RowData> {
  return function refresh_row_data(s) {
    s.filteredAndSortedRowIds = Array.from({ length: data.length }, (_, i) => i);

    // Ensure no stale data is in memory.
    s.rows = {};

    s.filteredAndSortedRowIds.forEach((rowId) => {
      const rowData = data[rowId];

      // Verify the rowData type in runtime
      if (typeof rowData !== 'object') {
        throw new Error('Row Data should be an object');
      }

      s.rows[rowId] = {
        rowId,
        // set row.rowStatus to 'deleted' if rowData.deleted has truthy value, e.g. '2022-11-20T13:02:47.210Z', 1, true.
        rowStatus: rowData !== null && 'deleted' in rowData && !!rowData.deleted ? 'deleted' : 'view',

        // set row.isSelected to true if rowData.isSelected is true
        isSelected: rowData !== null && 'isSelected' in rowData && rowData.isSelected === true,

        // set row.isExpanded to true if rowData.isExpanded is true
        isExpanded: rowData !== null && 'isExpanded' in rowData && rowData.isExpanded === true,

        cellErrors: {},
        originalData: rowData,
        data: rowData,
      };
    });
  };
}
