import { useLayoutEffect } from 'react';
import useForceRerender from '../useForceRerender';

/** Forces a component to re-render immediately after the initial render.
 *
 * Typically used when third party libraries don't manage their initial state
 * correctly.
 */
export default function useForceInitialRerender(): void {
  const forceRerender = useForceRerender();
  useLayoutEffect(() => {
    forceRerender();
  }, [
    /** forceRerender will never change, so this is effectively an empty
     * dependency array */
    forceRerender,
  ]);
}
