import { JSXElementConstructor, ReactElement, ReactNode, Validator, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import List, { ListProps } from '../List';
import { forgeClassHelper } from '../utils/classes';
import AccordionItem from '../AccordionItem';

const classes = forgeClassHelper({ name: 'accordion' });

export interface AccordionProps extends ListProps {
  /** Must be `AccordionItem` components
   *
   * This can't be enforced through Typescript.
   */
  children: ReactNode;
  /** Passes a classname to the root element of this component */
  className?: string;
}

function Accordion({ children, className, ...passedProps }: AccordionProps): ReactElement {
  return (
    <List {...classes({ extra: className })} {...passedProps}>
      {children}
    </List>
  );
}

function checkChildComponent(childType: string | JSXElementConstructor<unknown>): Error | null {
  if (
    typeof childType !== 'function' ||
    ((childType as { displayName?: string }).displayName !== 'AccordionItem' &&
      childType.name !== 'AccordionItem' &&
      childType !== AccordionItem)
  ) {
    return new Error('Accordion only accepts AccordionItem components as children.');
  }
  return null;
}

const accordionPropTypes: WeakValidationMap<AccordionProps> = {
  /** Must be `AccordionItem` components */
  children: PropTypes.oneOfType([
    // When there are multiple children
    PropTypes.arrayOf<ReactNode>((children, index) => {
      return checkChildComponent(children[index].type);
    }),
    // When there is only one child
    PropTypes.objectOf(function (children, index) {
      return checkChildComponent(children.type);
    }) as Validator<ReactNode>,
  ]).isRequired,
  /** Passes a classname to the root element of this component */
  className: PropTypes.string,
};
Accordion.propTypes = accordionPropTypes;

export default Accordion;
