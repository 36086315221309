import React, { forwardRef, useState, useEffect, Ref, ReactNode } from 'react';

const ARIA_LIVE_REGISTER_DELAY = 1; // should be > 0 to be announce consistently for NVDA screen readers (Win 7 firefox)

export interface ScreenReaderAnnounceProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  defaultRenderChildren?: boolean;
}

const ScreenReaderAnnounce = forwardRef((props: ScreenReaderAnnounceProps, ref: Ref<HTMLDivElement>) => {
  const { children, defaultRenderChildren, ...rest } = props;
  const [renderChildren, setRenderChildren] = useState(defaultRenderChildren || false);

  useEffect(() => {
    const renderTimeout = setTimeout(() => {
      setRenderChildren(true);
    }, ARIA_LIVE_REGISTER_DELAY);

    return () => {
      clearTimeout(renderTimeout);
    };
  }, []);

  const accessibilityProps = {
    'aria-live': 'polite',
    'aria-relevant': 'additions',
    role: 'alert',
  } as const;

  return (
    <div ref={ref} {...accessibilityProps} {...rest}>
      {renderChildren && children}
    </div>
  );
});

ScreenReaderAnnounce.displayName = 'ScreenReaderAnnounce';

export default ScreenReaderAnnounce;
