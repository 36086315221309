import { Menu, MenuItem } from '@athena/forge';
import forgePackageJson from '@athena/forge/package.json';
import { Link } from 'gatsby';
import { ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import semverMajor from 'semver/functions/major';
import semverSatisfies from 'semver/functions/satisfies';
import createBEMHelper from '../../bem-helper';
import { VersionContext } from '../../contexts';
import { Version, VersionLeaf, VersionResponse } from '../../hooks/useVersion';
import { appendVersionString, forgeVersionType } from '../../versionCheckHelper';
import VersionsMenuTrigger from './versions-menu-trigger';
import './versions-menu.scss';

const classes = createBEMHelper({ name: 'version-menu' });

const MAX_VERSIONS_PER_CATEGORY = 4;

function versionLink(v?: VersionLeaf): ReactNode {
  return (
    v && (
      <MenuItem key={v.version}>
        <a href={v.link}>
          Forge {appendVersionString(v)}
          {v.version}
        </a>
      </MenuItem>
    )
  );
}

function renderVersionCategory({
  versions,
  category,
  title,
}: {
  versions: VersionLeaf[];
  category: string;
  title: string;
}): ReactNode[] {
  return versions.length > 0
    ? [
        <MenuItem key={`${category}-divider`} type="divider" />,
        <MenuItem key={`${category}-title`} type="title">
          {title}
        </MenuItem>,
        ...versions.map((version) => versionLink(version)),
      ]
    : [];
}

/** Tests that a version received from the API matches the given
 * Major/Minor/patch version */
function versionsMatch(apiVersion: VersionLeaf, fullVersion: string): boolean {
  return semverSatisfies(fullVersion, `~${apiVersion.version}.0`);
}

/** Transforms a list of versions into a list of rendered MenuItem elements */
function renderVersions(versions: Version[]): ReactNode[] {
  versions.sort((a, b) => {
    return Number(b.version) - Number(a.version);
  });

  /** Flatten forge versions into a 1D array */
  const forgeVersions = versions
    .map((majorVersion) => {
      return majorVersion.type === 'leaf' ? majorVersion : majorVersion.versions;
    })
    .flat();

  /** The major version number of the current LTS */
  const ltsMajorVersion = process.env.GATSBY_FORGE_LTS_VERSION
    ? parseInt(process.env.GATSBY_FORGE_LTS_VERSION, 10)
    : semverMajor(forgePackageJson.version);

  /** Categorizes previousVersions so that category headers can be added later */
  const { currentVersion, newVersions, ltsVersions, otherVersions } = forgeVersions.reduce<{
    currentVersion?: VersionLeaf;
    newVersions: VersionLeaf[];
    ltsVersions: VersionLeaf[];
    otherVersions: VersionLeaf[];
  }>(
    (categorizedVersions, v) => {
      const majorVersion = Number(v.version.split('.')[0]);
      if (versionsMatch(v, forgePackageJson.version)) {
        categorizedVersions.currentVersion = v;
      }
      if (majorVersion > ltsMajorVersion && categorizedVersions.newVersions.length < MAX_VERSIONS_PER_CATEGORY) {
        /** New Guide category */
        categorizedVersions.newVersions.push(v);
      } else if (
        majorVersion === ltsMajorVersion &&
        categorizedVersions.ltsVersions.length < MAX_VERSIONS_PER_CATEGORY
      ) {
        /** LTS Guide category */
        categorizedVersions.ltsVersions.push(v);
      } else {
        /** All other versions to to "Previous Guides" category, including
         * overflow from LTS/New Guide categories */
        categorizedVersions.otherVersions.push(v);
      }
      return categorizedVersions;
    },
    { newVersions: [], ltsVersions: [], otherVersions: [] }
  );

  const currentVersionAndReleaseNotes = [
    <MenuItem key="current-version-title" type="title">
      Forge {appendVersionString()}Guide Version
    </MenuItem>,
    versionLink(currentVersion),
    <MenuItem key="release-notes-divider" type="divider" />,
    <MenuItem key="release-notes">
      <Link to="/resources/release-notes">Read Forge {appendVersionString()}Release Notes</Link>
    </MenuItem>,
  ];

  const newLinks = renderVersionCategory({
    versions: newVersions,
    category: 'new',
    title: 'New Forge Guides',
  });

  const ltsLinks = renderVersionCategory({
    versions: ltsVersions,
    category: 'lts',
    title: 'LTS Forge Guides',
  });

  const otherLinks = renderVersionCategory({
    versions: otherVersions,
    category: 'other',
    title: 'Previous Guides',
  });

  return [...currentVersionAndReleaseNotes, ...newLinks, ...ltsLinks, ...otherLinks];
}

const VersionsMenu = (): ReactElement => {
  const { versions } = useContext<VersionResponse>(VersionContext);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const versionMenuItems = useMemo(() => renderVersions(versions), [versions]);

  return (
    <div {...classes({ element: 'picker', modifiers: { lts: forgeVersionType() === 'lts' } })}>
      <div className="fe_u_margin--medium">
        <Menu
          {...classes({ element: 'dropdown-menu' })}
          isOpen={menuIsOpen}
          onIsOpenChange={(changes) => setMenuIsOpen(changes.isOpen || false)}
          trigger={<VersionsMenuTrigger isOpen={menuIsOpen} />}
          label="versions"
          hideLabel
        >
          {versionMenuItems}
        </Menu>
      </div>
    </div>
  );
};

export default VersionsMenu;
