import { DATA_TABLE_FILTER_TAG_LABEL } from './constants';

export function getHiddenDataFilterTagCount(filterPanelHeaderRef: React.RefObject<HTMLDivElement>): number {
  if (filterPanelHeaderRef.current === null) return 0;

  const tags = filterPanelHeaderRef.current.querySelectorAll(`.fe_c_tag[aria-label="${DATA_TABLE_FILTER_TAG_LABEL}"]`);
  const hiddenTags = Array.from(tags)
    .map((ele) => ele.getBoundingClientRect().y)
    .filter((y, index, [baseY]) => y !== baseY);

  return hiddenTags.length;
}
