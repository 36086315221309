import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgThumbsUpLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.5 8.44059H5.95L5.98 22.1106H1.5V8.44059ZM5.98 21.4606H14.98C19.05 22.0106 20.28 17.0006 20.28 17.0006C20.28 17.0006 22.34 9.28059 21.5 9.28059H14.44C15.93 2.42059 15.2 1.14059 13.05 1.00059C10.9 0.860592 10.62 2.78059 9.62 5.56059C8.62 8.34059 6.69 9.65059 6.69 9.65059L5.96 8.60059L5.99 21.4706L5.98 21.4606Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgThumbsUpLarge.displayName = 'SvgThumbsUpLarge';
const ThumbsUpLarge = withForgeIconProps(SvgThumbsUpLarge, {
  name: 'ThumbsUp',
  usage: 'Use to show positive interactions or thumbs up',
  related: 'ThumbsDown',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ThumbsUpLarge;
