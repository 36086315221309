import DateRangeInput, { DateRangeInputValues } from '../../../../DateRangeInput';
import { DataTableFilter, CreateDataFilterCommonArgs } from '../../../utils/internalTypes';

// https://day.js.org/docs/en/query/is-same-or-before
// https://day.js.org/docs/en/query/is-same-or-after
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

type CreateDateRangeInputFilterArgs<RowData> = CreateDataFilterCommonArgs<RowData> & {
  allowNullValues?: boolean; // Default: false
  serverSideQueryParamFn?: (dateRange: DateRangeInputValues) => string;
};
export type CreateDateRangeInputFilter<RowData> = typeof createDateRangeInputFilter<RowData>;

export function createDateRangeInputFilter<RowData>({
  allowNullValues = false,
  filterId,
  filterLabel,
  rowDataFieldName,
  serverSideQueryParamFn,
}: CreateDateRangeInputFilterArgs<RowData>): DataTableFilter<RowData, DateRangeInputValues> {
  return {
    filterId,
    filterLabel,

    serverSideQueryParamFn:
      typeof serverSideQueryParamFn !== 'undefined'
        ? ({ conditionValues }) => serverSideQueryParamFn(conditionValues[0])
        : undefined,

    clientSideFilterFn: ({ rowData, conditionValues }) => {
      const fieldStringValue = rowData[rowDataFieldName];
      if (typeof fieldStringValue !== 'string') {
        throw new Error(`The filtered field (${String(rowDataFieldName)}) should be string type.`);
      }

      const fieldValue = new Date(fieldStringValue);
      const { start, end, checked } = conditionValues[0];

      // If no filter values are set, skip filtering
      if (!start && !end && !checked) return true;

      const isValidDate = fieldValue instanceof Date && !isNaN(fieldValue.getTime());
      if (!isValidDate) return checked ?? false;

      // In the case where checked is set, but start and end aren't,
      // then we want _only_ nulls but this row has a value.
      if (!start && !end) return false;

      return (
        (!start || dayjs(fieldValue).isSameOrAfter(start)) && // prettier-break-line
        (!end || dayjs(fieldValue).isSameOrBefore(end))
      );
    },

    DataFilterComponent: ({ useSelector, onFilterConditionValuesChange }) => {
      // Forge Feedback:
      const EMPTY_STATE: DateRangeInputValues = {
        start: null,
        end: null,
        checked: false,
      };

      const tableId = useSelector((s) => s.tableId);
      const conditionValues = useSelector(
        (s) => s.dataFilter.filters[filterId]?.conditionValues ?? []
      ) as DateRangeInputValues[];

      return (
        <DateRangeInput
          id={`${tableId}-date-range-input-filter-${filterId}`}
          allowNullValuesCheckBox={allowNullValues}
          errorAlwaysBelow={true}
          onChangeAllValues={(e, value) => {
            onFilterConditionValuesChange(value.start || value.end ? [value] : []);
          }}
          suppressVerticalSpacing={true}
          // UXDS-8312: DateRangeInput input cannot be cleaned up by "value" prop.
          values={conditionValues.length === 0 ? EMPTY_STATE : conditionValues[0]}
        />
      );
    },

    describeConditionValue: ({ start, end, checked }) => {
      const startDate = start ? dayjs(start).format('MM-DD-YYYY') : '';
      const endDate = end ? dayjs(end).format('MM-DD-YYYY') : '';

      if (startDate === '' && endDate === '') return allowNullValues ? 'Unspecified' : '';
      if (startDate !== '' && endDate === '') return `${startDate} or after`;
      if (startDate === '' && endDate !== '') return `${endDate} or before`;

      return startDate === endDate
        ? startDate // the same date
        : `${startDate} - ${endDate}`;
    },
  };
}
