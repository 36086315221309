import React, { ReactElement, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper({ name: 'menu-item' });

export interface MenuItemProps {
  /** Content to display inside the menu item */
  children?: React.ReactNode;

  /** CSS class to apply to the MenuItem's `<li>` element */
  className?: string;

  /** Menu item type. The menu item can only be selected if it has a type of `option`.   */
  type?: 'title' | 'option' | 'divider';
}

/**
 * MenuItem
 * Item displayed in the Menu component
 *
 * @documentedBy Menu
 * */
function MenuItem({ className, children, type = 'option' }: MenuItemProps): ReactElement {
  switch (type) {
    case 'divider':
      return <hr {...classes({ element: 'horizontal-rule', extra: className })} />;
    case 'title': {
      return <span {...classes({ element: 'title', extra: className })}>{children}</span>;
    }
    case 'option':
      return <span {...classes({ element: 'menu-option', extra: className })}>{children}</span>;
  }
}

const menuPropTypes: WeakValidationMap<MenuItemProps> = {
  /** Content to display inside the menu item */
  children: PropTypes.node,

  /** CSS class to apply to the MenuItem's `<li>` element */
  className: PropTypes.string,

  /** Menu item type. The menu item can only be selected if it has a type of `option`. */
  type: PropTypes.oneOf(['title', 'option', 'divider']),
  // type: PropTypes.oneOf(['title', 'option', 'divider', 'raw']), // Switch to this if we implement the checkbox option.
};
MenuItem.propTypes = menuPropTypes;

export default MenuItem;
