import rawContents from "!!raw-loader!./BadgeBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function BadgeBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Badge Basic" />;
}

export default BadgeBasic;
