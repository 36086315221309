import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSuccessLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#118647"
    />
    <path
      d="M5 12.5601L9.41 17L19 7"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgSuccessLarge.displayName = 'SvgSuccessLarge';
const SuccessLarge = withForgeIconProps(SvgSuccessLarge, {
  name: 'Success',
  usage:
    'Use to confirm or indicate success. For use in alerts, not buttons; follow Forge interruption design guidance',
  related: 'done, yes, complete',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default SuccessLarge;
