import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPharmacySmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 9.99858H6M8 7.99773V11.9994M0.999979 4.99646V8.7881C0.974779 10.9985 1.61977 13.1645 2.84995 15.0007H13.15C14.3802 13.1645 15.0252 10.9985 15 8.7881V4.99646H0.999979Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5275 1.74354C13.5706 2.17943 13.4734 2.6178 13.2499 2.99438V2.99487H8.2197L10.4598 0.571893C10.6202 0.392232 10.8168 0.248474 11.0365 0.15006C11.2562 0.051647 11.4942 0.000732422 11.7349 0.000732422C11.9756 0.000732422 12.2135 0.051647 12.4332 0.15006C12.6529 0.248474 12.8495 0.392232 13.0099 0.571893C13.3029 0.897188 13.4843 1.30765 13.5275 1.74354ZM7.29517 3.99487L6.35986 5.00652H11.6399L12.4534 3.99487H7.29517Z"
      fill={props.color}
    />
  </svg>
);
SvgPharmacySmall.displayName = 'SvgPharmacySmall';
const PharmacySmall = withForgeIconProps(SvgPharmacySmall, {
  name: 'Pharmacy',
  usage: 'Use to indicate a pharmacy or pharmacist',
  related: 'rx, prescription, pharmaceutical, chemist',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default PharmacySmall;
