import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClearbackLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M18.94 14.64L12.94 8.64001M18.94 8.64001L12.94 14.64M8 19L1 12L8 5H23V16C23 16.7956 22.6839 17.5587 22.1213 18.1213C21.5587 18.6839 20.7956 19 20 19H8Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgClearbackLarge.displayName = 'SvgClearbackLarge';
const ClearbackLarge = withForgeIconProps(SvgClearbackLarge, {
  name: 'Clearback',
  usage: 'Use to delete the contents of an input',
  related: 'clear, backspace',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ClearbackLarge;
