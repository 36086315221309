import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUnlockLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4 10.0001L4 9.00006L3 9.00006V10.0001H4ZM4 23.0001H3V24.0001H4V23.0001ZM20 10H21V9L20 9L20 10ZM14 9C14 9.55229 14.4477 10 15 10C15.5523 10 16 9.55229 16 9H14ZM20 4V5H22V4H20ZM3 10.0001V23.0001H5V10.0001H3ZM4 24.0001H17V22.0001H4V24.0001ZM21 20.0001V10H19V20.0001H21ZM20 9L4 9.00006L4 11.0001L20 11L20 9ZM17 24.0001C19.2091 24.0001 21 22.2092 21 20.0001H19C19 21.1046 18.1046 22.0001 17 22.0001V24.0001ZM16 9V4H14V9H16ZM18 2C19.1046 2 20 2.89543 20 4H22C22 1.79086 20.2091 0 18 0V2ZM16 4C16 2.89543 16.8954 2 18 2V0C15.7909 0 14 1.79086 14 4H16Z"
      fill={props.color}
    />
  </svg>
);
SvgUnlockLarge.displayName = 'SvgUnlockLarge';
const UnlockLarge = withForgeIconProps(SvgUnlockLarge, {
  name: 'Unlock',
  usage:
    'Use to denote content that is not currently private, classified, or encrypted but can be made private',
  related: 'Lock',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default UnlockLarge;
