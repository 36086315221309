import { createContext, forwardRef, NamedExoticComponent, Ref, RefAttributes, useContext } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ComponentWithDisplayName, ComponentWithRefContext, DistributiveOmit } from '../utils/types';
import createCache, { EmotionCache, Options as EmotionCacheOptions } from '@emotion/cache';
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import { ForwardedRefProps, ForwardedRefType } from '../utils/forwardRefToProps';

/** objects passed through context
 *
 */
export interface PortalData {
  /** The cache used by emotion-js to decide how and where to autogenerate styles
   *
   * This is mounted in the original shadow DOM if present.
   */
  emotionCache: EmotionCache | undefined;
  /** Represents the HTMLElement where portals can be attached while retaining
   * forge and application styling.
   *
   * Fallback logic exists to set this to `document.body` if the div for <Root>
   * cannot be determined. This shouldn't be the case so long as the consumer
   * of RootContainerContext is a child of Root.
   */
  portalNode: HTMLElement | null;
}

/** Contains portalData prop with NonNullable PortalData elements */
export interface PortalContextProps {
  portalData: { [P in keyof PortalData]: NonNullable<PortalData[P]> };
}
export type PortalContextAndRefProps<RefType> = PortalContextProps & ForwardedRefProps<RefType>;

/** Global emotionCache, for use either when a shadow root does not exist
 * or a Forge component has been used without a <Root> as its parent.
 */
let emotionCacheSingleton: EmotionCache | undefined;

/** Creates a new emotion cache or re-uses emotionCacheSingleton if it's already
 * been defined */
export function createEmotionCache(node?: Node | null): EmotionCache {
  const baseRules: EmotionCacheOptions = {
    /** so we can better identify emotion rules created on behalf of Forge */
    key: 'fe-c',
  };
  if (node) {
    return createCache({
      ...baseRules,
      /** Generate styles as text so that the creation of new rules can be
       * detected with a MutationObserver */
      speedy: false,
      /** The node where emotion-js will add its dynamic styles */
      container: node,
    });
  } else {
    if (!emotionCacheSingleton) {
      emotionCacheSingleton = createCache(baseRules);
    }
    return emotionCacheSingleton;
  }
}

export type OmitRefAndPortalData<Props> = DistributiveOmit<Props, 'ref' | 'forwardedRef' | 'portalData'>;
export type OmitPortalData<Props> = DistributiveOmit<Props, 'portalData'>;

export type WithPortalDataAndRefReturn<Props> = NamedExoticComponent<
  OmitRefAndPortalData<Props> & RefAttributes<ForwardedRefType<Props>>
>;

/** A Higher Order Component (HOC) used to consume values from PortalContext.
 * Conditionally renders the wrapped FunctionComponent when portalData is available.
 *
 * Passes a non-null portalData as a prop to the provided component.
 *
 * This is important in order to ensure that all required styling is available
 * before rendering child components. Rendering shadow-dom sensitive components
 * without this HOC runs the risk of styling flashes as the emotion-js cache
 * detaches from document.head and re-attaches itself to shadow-root, or portals
 * get created as children of document.body only to reattach themselves as a
 * child of <Root>.
 *
 * If the resulting component is not wrapped in a Root component, then
 * portals will be created directly off of document.body. Forge styling will
 * not be correctly applied.
 */
export function withPortalData<Props extends PortalContextProps>(
  WrappedComponent: ComponentWithDisplayName<Props>
): ComponentWithDisplayName<OmitPortalData<Props>> {
  const WithPortalData: ComponentWithRefContext<OmitPortalData<Props>, ForwardedRefType<Props>> = (props) => {
    const portalData = usePortalContext();

    if (portalData.portalNode && portalData.emotionCache) {
      const wrappedComponentProps = {
        ...props,
        portalData: portalData,
        /** Typescript does not like that typeof props !== Props, hence the
         * aggressive type assertion.
         *
         * > Conversion of to type 'Props' may be a mistake because neither
         * > type sufficiently overlaps with the other
         *
         * In this case, it feels like due diligence has been done to ensure the
         * types are accurate:
         * 1. portalData don't exist in keyof props, but is
         *    added by in this object.
         * 2. All other props are spread across this object.
         */
      } as unknown as Props;

      return (
        <EmotionCacheProvider value={portalData.emotionCache}>
          <WrappedComponent {...wrappedComponentProps} />
        </EmotionCacheProvider>
      );
    } else {
      return null;
    }
  };
  WithPortalData.displayName = `withPortalData(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;
  hoistNonReactStatics(WithPortalData, WrappedComponent);
  return WithPortalData;
}

/** Version of withPortalData that supports ref forwarding. */
export function withPortalDataAndRef<Props extends PortalContextAndRefProps<ForwardedRefType<Props>>>(
  WrappedComponent: ComponentWithDisplayName<Props>
): WithPortalDataAndRefReturn<Props> {
  const WithPortalData: ComponentWithRefContext<OmitRefAndPortalData<Props>, Ref<ForwardedRefType<Props>>> = (
    props,
    ref
  ) => {
    const portalData = usePortalContext();

    if (portalData.portalNode && portalData.emotionCache) {
      const wrappedComponentProps = {
        ...props,
        portalData: portalData,
        forwardedRef: ref,
        /** Typescript does not like that typeof props !== Props, hence the
         * aggressive type assertion.
         *
         * > Conversion of to type 'Props' may be a mistake because neither
         * > type sufficiently overlaps with the other
         *
         * In this case, it feels like due diligence has been done to ensure the
         * types are accurate:
         * 1. forwardedRef and portalData don't exist in keyof props, but are
         *    added by in this object.
         * 2. ref is stripped out by React.forwardRef if it happened to exist.
         * 3. All other props are spread across this object.
         */
      } as unknown as Props;

      return (
        <EmotionCacheProvider value={portalData.emotionCache}>
          <WrappedComponent {...wrappedComponentProps} />
        </EmotionCacheProvider>
      );
    } else {
      return null;
    }
  };
  WithPortalData.displayName = `withPortalDataAndRef(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;
  hoistNonReactStatics(WithPortalData, WrappedComponent);
  return forwardRef(WithPortalData) as WithPortalDataAndRefReturn<Props>;
}

/** Default context for components that are not wrapped in Root.
 *
 * Styling will be off, but this default value is primarily intended for unit
 * tests, where styling isn't applied anyway. It's worth noting that while
 * Forge could easily wrap its tests in <Root>, it's not reasonable for client
 * applications to do the same.
 */
export const defaultPortalContext: PortalData = {
  emotionCache: createEmotionCache(),
  portalNode: typeof document === 'undefined' ? null : document.body,
};
export const PortalContext = createContext<PortalData>(defaultPortalContext);

/** Retrieves information necessary to determine how to mount components that
 * portal */
export const usePortalContext = (): PortalData => useContext(PortalContext);
