import { Time } from '@internationalized/date';

import rawContents from "!!raw-loader!./ControlledAndUncontrolled.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { Time };

function ControlledAndUncontrolled():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Controlled And Uncontrolled" />;
}

export default ControlledAndUncontrolled;
