import { useEffect, useState } from 'react';
import { AwaitableModal } from '../../../alerts/AwaitableModal';
import { DataTableFilter, CreateDataFilterCommonArgs, DataTableValueTextOption } from '../../../utils/internalTypes';
import SegmentedButton, { SegmentedButtonBehavior } from '../../../../SegmentedButton';
import Button from '../../../../Button';

type CreateSegmentedButtonFilterArgs<RowData> = CreateDataFilterCommonArgs<RowData> & {
  behavior: Exclude<SegmentedButtonBehavior, 'button'>;
  fetchOptions: () => Promise<DataTableValueTextOption[]>;
  serverSideQueryParamFn?: (conditionValues: readonly string[]) => string;
};
export type CreateSegmentedButtonFilter<RowData> = typeof createSegmentedButtonFilter<RowData>;

export function createSegmentedButtonFilter<RowData>({
  filterId,
  filterLabel,
  rowDataFieldName,
  behavior,
  fetchOptions,
  serverSideQueryParamFn,
}: CreateSegmentedButtonFilterArgs<RowData>): DataTableFilter<RowData, string> {
  const SegmentedButtonOptionMap: Record<string, DataTableValueTextOption> = {};

  return {
    filterId,
    filterLabel,

    serverSideQueryParamFn:
      typeof serverSideQueryParamFn !== 'undefined'
        ? ({ conditionValues }) => serverSideQueryParamFn(conditionValues)
        : undefined,

    clientSideFilterFn: ({ rowData, conditionValues }) => {
      const fieldValue = rowData[rowDataFieldName] as string;
      if (typeof fieldValue !== 'string') {
        throw new Error(`The filtered field (${String(rowDataFieldName)}) should be string type.`);
      }

      const conditionValueSet = new Set(conditionValues);
      return conditionValueSet.has(fieldValue);
    },

    DataFilterComponent: ({ useSelector, useDispatch, onFilterConditionValuesChange }) => {
      const dispatch = useDispatch();
      const tableId = useSelector((s) => s.tableId);
      const conditionValues = useSelector((s) => s.dataFilter.filters[filterId]?.conditionValues ?? []) as string[];

      // ==============================
      // Fetch the options
      // ==============================
      const [isLoading, setIsLoading] = useState(true);
      const [options, setOptions] = useState<DataTableValueTextOption[]>([]);
      useEffect(() => {
        (async () => {
          try {
            const options = await fetchOptions();
            const filterOptions = options.map((opt) => ({ label: opt.label, value: opt.value }));
            setOptions(filterOptions);

            for (const opt of options) {
              SegmentedButtonOptionMap[opt.value] = opt;
            }
          } catch (e) {
            await AwaitableModal(dispatch, {
              alertType: 'attention',
              headerText: 'Fetch Options Failed',
              children: (e as Error).message,
            });
          }
          setIsLoading(false);
        })();
      }, [dispatch, setIsLoading, setOptions]);

      if (isLoading) {
        return <div>Loading the options...</div>;
      }

      return (
        <SegmentedButton
          name={`${tableId}-segmented-button-filter-${filterId}`}
          value={conditionValues}
          behavior={behavior}
          alignment={behavior === 'checkbox' ? 'separated' : 'connected'}
          onChange={(e) => {
            // This line is unnecessary if running a version of React before 17.
            // https://guide.forge.athena.io/components/checkboxbutton#demos-checkbox-button-basic
            e.persist();

            const value = e.target.value;
            const newValues = Array.isArray(value) ? value : [value];

            // ========================
            // Radio
            // ========================
            if (behavior === 'radio') {
              onFilterConditionValuesChange(newValues);
              return;
            }

            // ========================
            // Clearable Radio
            // ========================
            if (behavior === 'clearable-radio') {
              onFilterConditionValuesChange(newValues[0] === '' ? [] : newValues);
              return;
            }

            // ========================
            // Checkbox
            // ========================
            if (behavior === 'checkbox') {
              onFilterConditionValuesChange(newValues);
              return;
            }
          }}
        >
          {options.map(({ label, value }) => (
            <Button key={value} value={value}>
              {label}
            </Button>
          ))}
        </SegmentedButton>
      );
    },

    describeConditionValue: (conditionValue) => SegmentedButtonOptionMap[conditionValue]?.label ?? '',
  };
}
