import AttentionLarge from '@athena/forge-icons/dist/AttentionLarge';
import ProfileLarge from '@athena/forge-icons/dist/ProfileLarge';
import SuccessLarge from '@athena/forge-icons/dist/SuccessLarge';

import rawContents from "!!raw-loader!./CustomNodes.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { AttentionLarge, ProfileLarge, SuccessLarge };

function CustomNodes():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Custom Nodes" />;
}

export default CustomNodes;
