import { ReactElement, useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import parse from 'html-react-parser';
import { defaultOptions } from '../html-parsing-options';
import Layout from '../components/layout';
import { Banner, BannerItem } from '@athena/forge';
import PropsTables from '../components/props-tables';
import TertiaryNav from '../components/tertiary-nav/tertiary-nav';
import Header from '../components/content-blocks/header';
import PageHeader from '../components/page-header';
import './component-page.scss';
import { ChildBlock, ComponentPageNode, GatsbyPageContext, MetadataElement, PageData } from '../contexts';
import Imports from '../components/imports';
import RepoLink from '../components/repo-link';

export interface DataWithNewNodeInfo extends Omit<PageData, 'newNodeInfo'> {
  newNodeInfo: ComponentPageNode;
}
export interface ComponentPageWithNodeInfoProps {
  data: DataWithNewNodeInfo;
  pageContext: PageProps<PageData, GatsbyPageContext>['pageContext'];
  location: PageProps<PageData, GatsbyPageContext>['location'];
}

/** Merges Drupal content from the "Coding" section with autogenerated content.
 *
 * Any Drupal content other than "Repository", "Import statements" and "Props"
 * appear first in the "Coding" section. Drupal replaces autogenerated content
 * if they have the same title.
 */
function mergeCodeSections(componentsMetadata: MetadataElement[], childBlock: ChildBlock): ChildBlock {
  interface CodeSections {
    repositorySection: ChildBlock;
    importSection: ChildBlock;
    propsSection: ChildBlock;
    customSections: ChildBlock[];
  }
  const defaultSections: CodeSections = {
    repositorySection: {
      drupal_type: 'paragraph--header',
      field_title: 'Repository',
      id: 'repository',
      reactChildren: <RepoLink componentsMetadata={componentsMetadata} />,
    },
    importSection: {
      drupal_type: 'paragraph--header',
      field_title: 'Import statements',
      id: 'imports',
      reactChildren: <Imports componentsMetadata={componentsMetadata} />,
    },
    propsSection: {
      drupal_type: 'paragraph--header',
      field_title: 'Props',
      id: 'props-tables',
      useIdForHyperlink: true,
      reactChildren: <PropsTables componentsMetadata={componentsMetadata} />,
    },
    customSections: [],
  };
  const { repositorySection, importSection, propsSection, customSections } = (
    childBlock.childrenBlock || []
  ).reduce<CodeSections>((sections, child) => {
    if (child.field_title === 'Repository') {
      sections.repositorySection = child;
    } else if (child.field_title === 'Import statements') {
      sections.importSection = child;
    } else if (child.field_title === 'Props') {
      sections.propsSection = child;
    } else {
      sections.customSections.push(child);
    }
    return sections;
  }, defaultSections);
  return {
    ...childBlock,
    childrenBlock: [...customSections, repositorySection, importSection, propsSection],
  };
}

/** Renders the component page given that we have newNodeInfo */
export function ComponentPageWithNodeInfo({
  data,
  pageContext,
  location,
}: ComponentPageWithNodeInfoProps): ReactElement {
  const {
    newNodeInfo: {
      childrenBlock: rootChildrenBlock,
      title,
      field_summary: summary,
      field_deprecated,
      field_deprecated_reason,
      field_contributed,
      field_contributed_credits,
    },
  } = data;

  const componentsMetadata = data?.parentTable?.edges.concat(data.childTables?.edges || []);

  /** Add autogenerated sections to rootChildrenBlock */
  const amendedRootChildrenBlock = useMemo(
    () =>
      rootChildrenBlock?.map((childBlock) => {
        if (childBlock.field_title === 'Coding' && componentsMetadata && componentsMetadata.length > 0) {
          return mergeCodeSections(componentsMetadata, childBlock);
        } else {
          return childBlock;
        }
      }),
    [componentsMetadata, rootChildrenBlock]
  );

  const blocks = amendedRootChildrenBlock?.map((block, index) => {
    if (block.drupal_type === 'paragraph--header') {
      return <Header {...block} key={`header-${index}`} />;
    } else {
      return <></>;
    }
  });

  const deprecatedReason = parse(
    field_deprecated_reason ??
      '<p>This component has been deprecated and will be removed in the next version of Forge.</p>',
    defaultOptions()
  ) as JSX.Element[];

  const contributionCredits = parse(
    field_contributed_credits ?? '<p>This component has been contributed by another team.</p>',
    defaultOptions()
  ) as JSX.Element[];

  return (
    <Layout pageContext={pageContext} title={title} summary={summary} pageData={data} location={location}>
      <div className="component-flex">
        <div className="content">
          <PageHeader title={title} summary={summary} contributed={field_contributed} />
          {field_deprecated && (
            <div>
              <Banner alertType="attention">
                <BannerItem headerText="Deprecated">{deprecatedReason}</BannerItem>
              </Banner>
            </div>
          )}
          {field_contributed && (
            <div>
              <Banner alertType="info">
                <BannerItem headerText="Community Contribution">{contributionCredits}</BannerItem>
              </Banner>
            </div>
          )}
          {blocks}
        </div>
        <TertiaryNav childrenBlock={amendedRootChildrenBlock} />
      </div>
    </Layout>
  );
}

export default function ComponentPage({
  data: { path, newNodeInfo, ...passthroughData },
  pageContext,
  location,
}: PageProps<PageData, GatsbyPageContext>): ReactElement {
  if (newNodeInfo) {
    return (
      <ComponentPageWithNodeInfo
        data={{ ...passthroughData, newNodeInfo: newNodeInfo }}
        pageContext={pageContext}
        location={location}
      />
    );
  } else {
    console.warn(`Could not render component: ${path}`);
    return <></>;
  }
}

export const query = graphql`
  query ($title: String) {
    nodePage(title: { eq: $title }) {
      title
      body {
        summary
        value
      }
    }
    parentTable: allComponentMetadata(filter: { displayName: { eq: $title } }) {
      edges {
        node {
          id
          displayName
          props {
            id
            name
            required
            defaultValue {
              value
            }
            description {
              text
            }
            type {
              name
              raw
              value
            }
          }
        }
      }
    }
    childTables: allComponentMetadata(
      filter: { displayName: { ne: $title }, fields: { documentedBy: { eq: $title } } }
      sort: { fields: displayName, order: ASC }
    ) {
      edges {
        node {
          id
          displayName
          props {
            id
            name
            required
            defaultValue {
              value
            }
            description {
              text
            }
            type {
              name
              raw
              value
            }
          }
        }
      }
    }
    newNodeInfo: component(title: { eq: $title }) {
      childrenBlock {
        field_title
        childrenBlock {
          field_title
          id
          type
          childrenBlock {
            field_title
            field_text_body
            field_card_body
            field_card_link
            field_component_preview
            field_information_body
            field_information_type
            field_don_t_description
            field_do_description
            field_do_image
            field_don_t_image
            field_image
            field_markdown_body
            drupal_type
          }
          drupal_type
        }
        drupal_type
      }
      field_summary
      field_deprecated
      field_deprecated_reason
      field_contributed
      field_contributed_credits
      title
      field_component_preview
    }
  }
`;
