import React, { ReactElement, ReactNode, useCallback, useRef, useState, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import ForgePropTypes from '../utils/propTypes';
import { forgeClassHelper } from '../utils/classes';
import Heading from '../Heading';
import { HeadingTags } from '../utils/constants';
import { ButtonProps } from '../Button';

const classes = forgeClassHelper({ name: 'card' });

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Text to tell the screen reader what clicking on the card will do (if `onClick` is provided) */
  ariaLabel?: string;

  /** Single button or array of buttons to be displayed at the bottom of the `Card`. Can be `Button` or a custom component */
  buttons?: React.ReactNode;

  /** Card content */
  children?: React.ReactNode;

  /** Adds a class to the root element of the component */
  className?: string;

  /** Text to display in the card heading description */
  headingDescriptionText?: string;

  /** Text to display in the card heading */
  headingText?: string;

  /** Specifies the heading's HTML tag. For more on the importance of heading tags in semantic HTML,
   * [see the W3C's article on this topic](https://www.w3.org/WAI/tutorials/page-structure/headings/). */
  headingTag?: HeadingTags;

  /** Slot intended for media that will be full width at the top of the card &#8212; images default to full width of the card */
  mediaSlot?: React.ReactNode;

  /** Handles click and keypress event on card &#8212; does not fire when
   * clicking on an interactive element inside card, such as a button
   *
   * Note that despite the name "onClick", the event parameter may be a
   * KeyboardEvent. This callback is also called if the Enter or Space keys are
   * pressed.
   */
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;

  /** Determines whether padding will be added around the content inside card (except for content in the `mediaSlot`
   * &#8212; padding is never added there) */
  padded?: boolean;
}

interface WrapCardChildrenProps {
  /** The original children given to `Card` */
  children: ReactNode;
  /** A function used to set hovered state in the `Card` */
  setHovered: React.Dispatch<React.SetStateAction<boolean>>;
}
/** Takes every element passed to the `buttons` prop, clones it, and adds/wraps events so that
 * the Card hover state won't show if hovering a button
 */
function WrapCardChildren({ children, setHovered }: WrapCardChildrenProps): ReactElement {
  const wrappedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child) || !child.props) return child;

    return React.cloneElement(child as ReactElement<ButtonProps<false>>, {
      onMouseEnter: child.props.onMouseEnter
        ? (e: React.MouseEvent) => {
            child.props.onMouseEnter(e);
            setHovered(false);
          }
        : () => setHovered(false),
      onMouseLeave: child.props.onMouseLeave
        ? (e: React.MouseEvent) => {
            child.props.onMouseLeave(e);
            setHovered(true);
          }
        : () => setHovered(true),
    });
  });
  return <>{wrappedChildren}</>;
}

function Card({
  headingTag = 'h2',
  padded = true,
  ariaLabel,
  buttons,
  className,
  children,
  headingText,
  mediaSlot,
  onClick,
  headingDescriptionText,
  ...rest
}: CardProps): React.ReactElement {
  const [hovered, setHovered] = useState(false);
  const buttonContainer = useRef<HTMLDivElement>(null);

  function isButtonContainerChild(e: React.KeyboardEvent | React.MouseEvent): boolean {
    if (!buttonContainer.current) return false;
    return buttonContainer.current.contains(e.target as Node) && buttonContainer.current !== e.target;
  }

  const label = ariaLabel ? ariaLabel : headingText;
  const handleOnClick = useCallback(
    (e: React.MouseEvent) => {
      if (!isButtonContainerChild(e) && onClick) onClick(e);
    },
    [onClick]
  );
  const handleOnMouseEnter = useCallback(() => setHovered(true), []);
  const handleOnMouseLeave = useCallback(() => setHovered(false), []);
  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (!isButtonContainerChild(e) && onClick && (e.key === 'Enter' || e.charCode === 32)) {
        onClick(e);
      }
    },
    [onClick]
  );
  const clickableProps = {
    'aria-label': label,
    onClick: handleOnClick,
    onMouseEnter: handleOnMouseEnter,
    onMouseLeave: handleOnMouseLeave,
    onKeyPress: handleKeyPress,
    role: 'button',
  };

  return (
    <div
      {...rest}
      {...classes({
        modifiers: onClick && 'clickable',
        states: { hovered },
        extra: className,
      })}
      {...(onClick ? clickableProps : null)}
      data-testid="card"
    >
      <div {...classes('media-slot')}>{mediaSlot}</div>
      <div {...classes('content', { padded })}>
        {headingText && (
          <Heading
            headingTag={headingTag}
            text={headingText}
            headingDescription={headingDescriptionText}
            variant="section"
          />
        )}
        {children}
        {buttons && (
          <div {...classes('button-container')} ref={buttonContainer}>
            <WrapCardChildren setHovered={setHovered}>{buttons}</WrapCardChildren>
          </div>
        )}
      </div>
    </div>
  );
}

// propTypes comments are used to generate documentation and *must* be included
const CardPropTypes: WeakValidationMap<CardProps & { '...rest': unknown }> = {
  /** Text to tell the screen reader what clicking on the card will do (if `onClick` is provided) */
  ariaLabel: PropTypes.string,
  /** Single button or array of buttons to be displayed at the bottom of the `Card`. Can be `Button` or a custom component */
  buttons: PropTypes.node,
  /** Card content */
  children: PropTypes.node,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Text to display in the card heading description */
  headingDescriptionText: PropTypes.string,
  /** Specifies the heading's HTML tag. For more on the importance of heading tags in semantic HTML,
   * [see the W3C's article on this topic](https://www.w3.org/WAI/tutorials/page-structure/headings/). */
  headingTag: ForgePropTypes.headingTags,
  /** Text to display in the card heading */
  headingText: PropTypes.string,
  /** Slot intended for media that will be full width at the top of the card &#8212; images default to full width of the
   * card */
  mediaSlot: PropTypes.node,
  /** Handles click event on card &#8212; does not fire when clicking on an interactive element inside card, such as a
   * button */
  onClick: PropTypes.func,
  /** Determines whether padding will be added around the content inside card (except for content in the `mediaSlot`
   * &#8212; padding is never added there)
   *
   * Note that despite the name "onClick", the event parameter may be a
   * KeyboardEvent. This callback is also called if the Enter or Space keys are
   * pressed.
   */
  padded: PropTypes.bool,
  /** Props passed through to the root element of the component */
  '...rest': PropTypes.any,
};

Card.propTypes = CardPropTypes;

export default Card;
