import { ReactElement } from 'react';
import Header from '../content-blocks/header';
import './icon-table.scss';
import { IconNodes, IconNode, IconsMeta, IconMeta } from '../../contexts';
import IconCombo from './icon-combo';
import { createDataTable } from '@athena/forge';

interface IconTableProps {
  icons: IconNodes;
  iconsMeta: IconsMeta;
}

interface IconGroup {
  Large?: string;
  Small?: string;
}

type IconLookup = Record<string, IconGroup>;
type MetaData = { [Name: string]: IconMeta };

interface IconOrderAndGroups {
  distinctIconNames: string[];
  iconLookup: IconLookup;
}

type RowData = {
  icon: IconGroup;
  name: string;
  usage?: string;
  related?: string;
  labelRequired?: string;
  neutralInteractive?: string;
};
const DT = createDataTable<RowData>({ tableId: 'icon-table' });

export default function IconTable({ icons, iconsMeta }: IconTableProps): ReactElement {
  if (icons.edges && iconsMeta) {
    const meta: MetaData = iconsMeta.nodes.reduce((accumulator: MetaData, node: IconMeta) => {
      accumulator[node.Name] = node;
      return accumulator;
    }, {});

    const iconOrderAndGroups = icons.edges.reduce(
      ({ distinctIconNames, iconLookup }: IconOrderAndGroups, icon: IconNode) => {
        const sizeMod = icon.node.name.endsWith('Large') ? 'Large' : 'Small';
        const name = icon.node.name.split(sizeMod)[0];
        const isNewName = !Object.prototype.hasOwnProperty.call(iconLookup, name);
        if (isNewName) {
          distinctIconNames.push(name);
        }
        iconLookup[name] = { ...iconLookup[name], [sizeMod]: icon.node.svg.originalContent };
        return {
          distinctIconNames: distinctIconNames,
          iconLookup: iconLookup,
        };
      },
      { distinctIconNames: [], iconLookup: {} }
    );

    const data = iconOrderAndGroups.distinctIconNames.map((name, index) => {
      const iconGroup = iconOrderAndGroups.iconLookup[name];
      return {
        id: index,
        icon: iconGroup,
        name: name,
        usage: meta[name]?.Usage,
        related: meta[name]?.Related,
        labelRequired: meta[name]?.Label_Required,
        neutralInteractive: meta[name]?.Neutral_Interactive,
      };
    });

    return (
      <>
        <Header field_title={'Icon Table'} />
        <div className="icon-table">
          <DT.DataTable
            tableType="client-side"
            tableLayout="medium"
            tableStickyHeaders={true}
            columns={[
              {
                columnId: 'value',
                Header: () => <>Icon</>,
                Cell: ({ useSelector, rowId }) => {
                  const value = useSelector((s) => s.rows[rowId].data);
                  const { icon } = value;
                  return (
                    <>
                      <IconCombo Large={icon.Large} Small={icon.Small} />
                    </>
                  );
                },
              },
              DT.Column.createTextColumn({
                rowDataFieldName: 'name',
                columnId: 'name',
                headerText: 'Name',
              }),
              DT.Column.createTextColumn({
                rowDataFieldName: 'usage',
                columnId: 'usage',
                headerText: 'Usage',
              }),
              DT.Column.createTextColumn({
                rowDataFieldName: 'related',
                columnId: 'related',
                headerText: 'Related',
              }),
              DT.Column.createTextColumn({
                rowDataFieldName: 'labelRequired',
                columnId: 'labelRequired',
                headerText: 'Label Required?',
              }),
              DT.Column.createTextColumn({
                rowDataFieldName: 'neutralInteractive',
                columnId: 'neutralInteractive',
                headerText: 'Neutral Interactive?',
              }),
            ]}
            rowData={data}
          />
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
