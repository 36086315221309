import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMicrophoneOnLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19 11.5C19 13.3565 18.2625 15.137 16.9497 16.4497C15.637 17.7625 13.8565 18.5 12 18.5C10.1435 18.5 8.36305 17.7625 7.05029 16.4497C5.73754 15.137 5 13.3565 5 11.5M12 19V23M11.94 1H12.06C14.236 1 16 2.76394 16 4.93994V11.0601C16 13.2361 14.236 15 12.06 15H11.94C9.764 15 8 13.2361 8 11.0601V4.93994C8 2.76394 9.764 1 11.94 1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgMicrophoneOnLarge.displayName = 'SvgMicrophoneOnLarge';
const MicrophoneOnLarge = withForgeIconProps(SvgMicrophoneOnLarge, {
  name: 'MicrophoneOn',
  usage: 'Use to indicate a live microphone, or to mute',
  related: 'audio, mic',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default MicrophoneOnLarge;
