import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTranslateLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.1111 4.54128V18.5134M5.11108 11.5273H19.1111M8.61108 5.53929C7.27775 9.42044 7.27775 13.6343 8.61108 17.5154M15.6111 17.5154C16.9444 13.6343 16.9444 9.42044 15.6111 5.53929M6.11108 15.0204C9.99997 16.3511 14.2222 16.3511 18.1111 15.0204M18.1111 8.03431C14.2222 6.70363 9.99997 6.70363 6.11108 8.03431M7.88116 21.1181C10.2422 22.1229 12.8902 22.2299 15.3249 21.4187C17.7595 20.6076 19.8121 18.9345 21.0948 16.7157C22.3775 14.4969 22.8014 11.8864 22.2864 9.37682C21.7715 6.86728 20.3534 4.63286 18.3 3.09588C16.2466 1.55891 13.7002 0.825755 11.142 1.03505C8.58388 1.24435 6.19123 2.38159 4.41607 4.2318C2.64092 6.08201 1.6063 8.51682 1.50775 11.0766C1.4092 13.6363 2.2535 16.1433 3.88116 18.124C3.88116 18.124 1.77114 22.0565 1.61114 22.4158C1.45114 22.775 1.93116 23.9726 7.50116 21.3378L7.88116 21.1181Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgTranslateLarge.displayName = 'SvgTranslateLarge';
const TranslateLarge = withForgeIconProps(SvgTranslateLarge, {
  name: 'Translate',
  usage:
    'Use to indicate languages spoken, or the ability to translate information',
  related: 'translator, speak, translation, interpreter, interpret',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default TranslateLarge;
