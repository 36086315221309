import rawContents from "!!raw-loader!./Offsets.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Offsets():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Offsets" />;
}

export default Offsets;
