import EditLarge from '@athena/forge-icons/dist/EditLarge';
import EditSmall from '@athena/forge-icons/dist/EditSmall';

import rawContents from "!!raw-loader!./EditRowInDialog.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { EditLarge, EditSmall };

function EditRowInDialog():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Edit Row In Dialog" />;
}

export default EditRowInDialog;
