import rawContents from "!!raw-loader!./SelectWithUserEnteredOptions.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function SelectWithUserEnteredOptions():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Select With User Entered Options" />;
}

export default SelectWithUserEnteredOptions;
