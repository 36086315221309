import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRefreshSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M.052 11.943a1 1 0 0 0 1.896.637zm1.797-2.21.292-.956a1 1 0 0 0-1.24.638zm2.36 1.766a1 1 0 0 0 .583-1.913zm11.739-7.442a1 1 0 0 0-1.896-.637zm-1.797 2.21-.292.956a1 1 0 0 0 1.24-.638zM11.791 4.5a1 1 0 0 0-.583 1.913zM1.086 5.934a1 1 0 0 0 1.93.521zM8 1.5v-1h-.03l-.03.002zm5.018 4.695a1 1 0 0 0 1.864-.723zm1.897 3.871a1 1 0 0 0-1.93-.521zM8 14.5v1h.03l.03-.002zM2.982 9.805a1 1 0 0 0-1.864.723zM1.948 12.58l.849-2.528L.9 9.415l-.849 2.528zm-.391-1.89 2.651.81.584-1.914-2.651-.809zm12.495-7.27-.849 2.528 1.896.637.849-2.528zm.391 1.89-2.651-.81-.584 1.914 2.651.809zM2.05 6.194l.965.262v.004-.002l.006-.021.035-.106c.033-.097.088-.243.168-.423.16-.36.419-.845.803-1.335.757-.965 1.999-1.951 4.034-2.075L7.939.502C5.214.667 3.481 2.028 2.453 3.338a8.4 8.4 0 0 0-1.056 1.757 7 7 0 0 0-.305.81l-.006.024v.003h-.001c0 .001 0 .002.965.262M8 2.5c.746 0 1.7.155 2.606.678.889.514 1.79 1.415 2.412 3.017l1.864-.723c-.779-2.01-1.977-3.275-3.275-4.025C10.325.706 9.004.5 8 .5zm5.95 7.306-.965-.262V9.54v.002l-.006.021-.035.106a6.4 6.4 0 0 1-.971 1.758c-.757.965-1.999 1.951-4.034 2.075l.122 1.996c2.725-.165 4.458-1.526 5.486-2.836a8.4 8.4 0 0 0 1.056-1.757 7 7 0 0 0 .305-.81l.004-.018.002-.006v-.003h.001c0-.001 0-.002-.965-.262M8 13.5c-.746 0-1.7-.155-2.606-.678-.889-.514-1.79-1.415-2.412-3.017l-1.864.723c.779 2.01 1.977 3.275 3.275 4.025 1.282.741 2.603.947 3.607.947z"
      fill={props.color}
    />
  </svg>
);
SvgRefreshSmall.displayName = 'SvgRefreshSmall';
const RefreshSmall = withForgeIconProps(SvgRefreshSmall, {
  name: 'Refresh',
  usage: 'Use to reload or refresh a page or view',
  related: 'update, new, recent',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default RefreshSmall;
