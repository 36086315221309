import { classes } from '../../utils/dataTableClasses';
import { DataTableColumn } from '../../utils/internalTypes';
import { ACTION_BUTTON_SIZES } from '../../utils/constants';
import Button from '../../../Button';
import ExpandSmall from '@athena/forge-icons/dist/ExpandSmall';

export type CreateRowExpandArrowColumn<RowData> = typeof createRowExpandArrowColumn<RowData>;

export function createRowExpandArrowColumn<RowData>(): DataTableColumn<RowData> {
  return {
    columnId: 'row-expand-arrow',
    Header: () => <></>,
    Cell: ({ useDispatch, useSelector, rowId }) => {
      const dispatch = useDispatch();
      const isExpanded = useSelector((s) => s.rows[rowId].isExpanded);
      const tableLayout = useSelector((s) => s.tableLayout);
      const isRowExpandable = useSelector((s) => s.determineExpandableRow(s.rows[rowId].data));

      if (!isRowExpandable) {
        return <></>;
      }

      return (
        <Button
          {...classes({
            element: 'row_expand_arrow_column',
            modifiers: { 'arrow-down': isExpanded },
            extra: `fe_c_data_table--layout-${tableLayout}`,
          })}
          size={ACTION_BUTTON_SIZES[tableLayout]}
          variant="tertiary"
          icon={ExpandSmall}
          iconTitle={isExpanded ? 'Collapse' : 'Expand'}
          onClick={(e) => {
            /**
             * Prevent the `onClick` event from propagating to avoid triggering the event on the
             * row. Without `stopPropagation`, an issue may arise where the expand arrow doesn't
             * work if the user has set the `onRowClickLeft` callback to 'toggle-expand-row'.
             */
            e.stopPropagation();

            dispatch(function toggle_expand_row(s) {
              const { isExpanded } = s.rows[rowId];
              s.rows[rowId].isExpanded = !isExpanded;
            });
          }}
        />
      );
    },

    // Don't show arrow when row is deleted
    DeleteCell: () => <></>,
  };
}
