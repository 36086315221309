import TrashSmall from '@athena/forge-icons/dist/TrashSmall';
import { ReactNode } from 'react';
import Button from '../../Button';
import InlineAlert from '../../InlineAlert';
import ListItem from '../../ListItem';
import { forgeClassHelper } from '../../utils/classes';
import { FileUploadAction, FileUploadFile } from '../fileUploadReducer';

const classes = forgeClassHelper('file-upload');

interface FileUploadCompletedProps {
  dispatch: React.Dispatch<FileUploadAction>;
  file: FileUploadFile;
}

/** Renders a file that has been successfully uploaded */
const FileUploadCompleted = ({ dispatch, file }: FileUploadCompletedProps): ReactNode => {
  return (
    <ListItem uniqueKey={file.id} {...classes({ element: 'completed-list-item' })}>
      <InlineAlert {...classes({ element: 'completed-status' })} iconSize="small" type="success">
        <span className="fe_u_visually-hidden">{`Uploaded `}</span>
        <div {...classes({ element: 'completed-content' })}>
          <a href={file.url} download={file.fileData.name}>
            {file.fileData.name}
          </a>
          {file.metaData && <span {...classes({ element: 'completed-meta-data' })}>{file.metaData}</span>}
          <Button
            size="small"
            variant="tertiary"
            icon={TrashSmall}
            iconTitle={`Delete ${file.fileData.name}`}
            onClick={() => dispatch({ type: 'delete', affectedFile: file })}
          />
        </div>
      </InlineAlert>
    </ListItem>
  );
};

export default FileUploadCompleted;
