import { ReactElement, useRef, useState } from 'react';
import FileUpload, { FileUploadChangeEvent, FileUploadProps, FileUploadRef } from '../FileUpload/FileUpload';
import { FileUploadAction, FileUploadFile } from '../FileUpload/fileUploadReducer';
import ToggleSwitch from '../ToggleSwitch';
import Button from '../Button';
import { layout } from '@athena/forge-shared';

export interface FileUploadMockApplicationProps extends Partial<FileUploadProps> {
  percentIncrement?: number;
  uploadControls?: boolean;
}

/** A mock application for FileUpload is needed in storybook, tests, and demos,
 * because FileUpload cannot actually upload files to a server. However, we also
 * don't want FileUploadMockApplication to be in the top-level index.ts file,
 * since it cannot be used in application code. It could be defined in this file,
 * and imported in test code, but that would slow down the tests, as all of
 * @athena/forge would need to be parsed.
 *
 * As a compromise, it's only importable via
 * @athena/forge/dist/FileUploadMockApplication.
 * One demo replicates this implementation, and the other demos cite it.
 */
export default function FileUploadMockApplication({
  uploadControls = false,
  percentIncrement = 20,
  ...props
}: FileUploadMockApplicationProps): ReactElement {
  const fileUploadRef = useRef<FileUploadRef>(null);
  const [uploadsSucceed, setUploadsSucceed] = useState(true);
  const [autoIncrement, setAutoIncrement] = useState(true);
  const manualIncrementFunction = useRef<() => void>();

  const autoOrManualIncrement = (callback: () => void): void => {
    manualIncrementFunction.current = callback;
    if (autoIncrement) {
      window.setTimeout(callback, 500);
    }
  };

  const updatePercentComplete = (
    files: ReadonlyArray<FileUploadFile & { percentComplete: number }>
  ): FileUploadAction | undefined => {
    if (fileUploadRef.current) {
      const newFiles = files.map((f) => ({
        ...f,
        percentComplete: Math.min(f.percentComplete + percentIncrement, 100),
      }));
      const inProgressFiles = newFiles.filter(
        (f) => f.percentComplete > 0 && (uploadsSucceed ? f.percentComplete < 100 : f.percentComplete < 50)
      );
      const erroredFiles = newFiles.filter((f) => !uploadsSucceed && f.percentComplete >= 50);
      const completeFiles = newFiles
        .filter((f) => f.percentComplete === 100)
        .map((f) => ({ ...f, metaData: '(uploaded by Bullwinkle Moose)' }));
      if (inProgressFiles.length > 0) {
        fileUploadRef.current.dispatch({ type: 'update-progress', affectedFiles: inProgressFiles });
        autoOrManualIncrement(() => updatePercentComplete(inProgressFiles));
      }
      if (erroredFiles.length > 0) {
        fileUploadRef.current.dispatch({ type: 'error', affectedFiles: erroredFiles });
      }
      if (completeFiles.length > 0) {
        fileUploadRef.current.dispatch({ type: 'completed', affectedFiles: completeFiles });
      }
    }
    return;
  };
  const handleChange = (event: FileUploadChangeEvent): FileUploadAction | undefined => {
    if (fileUploadRef.current) {
      const action = event.target.value.action;
      /** Application logic would pick up here to do the actual file upload.
       *
       * This implementation emulates a file upload by incrementing the percent
       * complete via setTimeout.
       */
      switch (action.type) {
        case 'start': {
          const newFiles = action.affectedFiles.map((f) => ({
            ...f,
            percentComplete: 0,
          }));
          autoOrManualIncrement(() => updatePercentComplete(newFiles));
          return { type: 'start', affectedFiles: newFiles };
        }
        case 'retry': {
          const retriedFile = { ...action.affectedFile, percentComplete: 0 };
          autoOrManualIncrement(() => updatePercentComplete([retriedFile]));
          return { type: 'retry', affectedFile: retriedFile };
        }
      }
      return;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: layout['global-margin'].small }}>
      {uploadControls && (
        <>
          <ToggleSwitch
            descriptions={{ checked: 'Uploads succeed', unchecked: 'Uploads fail' }}
            onChange={() => setUploadsSucceed((prev) => !prev)}
            checked={uploadsSucceed}
          />
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: layout['global-margin'].small }}
          >
            <ToggleSwitch
              descriptions={{ checked: 'Auto increment', unchecked: 'Manual increment' }}
              onChange={() => setAutoIncrement((prev) => !prev)}
              checked={autoIncrement}
            />
            {!autoIncrement && (
              <Button
                variant="secondary"
                text="Increment progress bar"
                onClick={() => manualIncrementFunction.current?.()}
              />
            )}
          </div>
          {/* Explicit margins necessary while within a flex container */}
          <hr style={{ marginLeft: 0, marginRight: 0 }} />
        </>
      )}
      <FileUpload {...props} onChange={handleChange} ref={fileUploadRef} />
    </div>
  );
}
