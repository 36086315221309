import rawContents from "!!raw-loader!./NotFound.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function NotFound():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Not Found" />;
}

export default NotFound;
