import RawDataPreview from '../../components/raw-data-preview';

import rawContents from "!!raw-loader!./MultiSelectAsynchronous.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { RawDataPreview };

function MultiSelectAsynchronous():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Multi Select Asynchronous" />;
}

export default MultiSelectAsynchronous;
