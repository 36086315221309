import ProfileSmall from '@athena/forge-icons/dist/ProfileSmall';

import rawContents from "!!raw-loader!./SelectWithGroupedOptions.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { ProfileSmall };

function SelectWithGroupedOptions():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Select With Grouped Options" />;
}

export default SelectWithGroupedOptions;
