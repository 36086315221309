import rawContents from "!!raw-loader!./ComplexWithLeftPanel.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ComplexWithLeftPanel():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Complex With Left Panel" />;
}

export default ComplexWithLeftPanel;
