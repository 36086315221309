import { ComponentType, ReactElement, SVGProps } from 'react';
import { Heading } from '@athena/forge';
import './page-header.scss';
import parse from 'html-react-parser';
import guidelines from '../../../static/svg/guidelines.svg';
import components from '../../../static/svg/components.svg';
import community from '../../../static/svg/community.svg';
import gettingstarted from '../../../static/svg/gettingstarted.svg';
import gift from '../../../static/svg/gift.svg';
import { defaultOptions } from '../../html-parsing-options';

const illustrations = { guidelines, components, community, gettingstarted, gift } as const;

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  summary?: string;
  legacy?: boolean;
  isLandingPage?: boolean;
  bodyColor?: string;
  headerColor?: string;
  contributed?: boolean;
}
export default function PageHeader({
  title,
  subtitle,
  summary,
  legacy,
  isLandingPage,
  bodyColor,
  headerColor,
  contributed,
}: PageHeaderProps): ReactElement {
  const illustrationKey = title.toLowerCase().replace(/\s/g, '');
  const Illustration: ComponentType<SVGProps<SVGSVGElement>> | undefined = Object.prototype.hasOwnProperty.call(
    illustrations,
    illustrationKey
  )
    ? illustrations[illustrationKey as keyof typeof illustrations]
    : undefined;

  const Contributed: ComponentType<SVGProps<SVGSVGElement>> | undefined =
    illustrations['gift' as keyof typeof illustrations];

  return (
    <div
      className={`${legacy ? 'legacy' : ''} ${isLandingPage ? 'landingPage' : ''} page-header`}
      style={{ backgroundColor: headerColor }}
    >
      {contributed && !isLandingPage && (
        <div style={{ height: '0px' }}>
          <Contributed className="contribution" />
        </div>
      )}

      <Heading
        className={`${legacy ? 'legacy' : ''} ${isLandingPage ? 'landingPage' : ''} page-header__heading`}
        text={title}
        headingDescription={<>{subtitle || (summary && !legacy && parse(summary, defaultOptions()))}</>}
      />

      {isLandingPage && Illustration && (
        <div className={`page-header__illustration-holder ${illustrationKey}`}>
          <Illustration className="page-header__illustration" />
        </div>
      )}
    </div>
  );
}
