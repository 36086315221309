import HighVolumeMultiSelect from './HighVolumeMultiSelect.gen';
import HighVolumeMultiSelectCollapsible from './HighVolumeMultiSelectCollapsible.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <HighVolumeMultiSelect />
      <HighVolumeMultiSelectCollapsible />
    </>
  );
}
