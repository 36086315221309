import { ReactElement } from 'react';
import { Label, LabelProps } from 'react-aria-components';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper({ name: 'aria-label' });

export interface AriaLabelProps extends LabelProps {
  children: React.ReactNode;
}

const AriaLabel = ({ children }: AriaLabelProps): ReactElement => {
  return <Label {...classes()}>{children}</Label>;
};

export default AriaLabel;
