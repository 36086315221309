import React, { ReactElement, useRef, useState, useEffect, useContext } from 'react';
import { PageContext } from '../../contexts';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import * as Forge from '@athena/forge';
import * as ForgeIcons from '@athena/forge-icons';
import * as ForgeShared from '@athena/forge-shared';
import CopyToClipboard from 'react-copy-to-clipboard';
import LinkableHeading from '../linkable-heading';
import RawDataPreview from '../raw-data-preview';

import './demo-container.scss';

export interface DemoContainerProps {
  scope: Record<string, unknown>;
  contents: string;
  description: string;
}

const defaultScope = {
  ...Forge,
  ...ForgeIcons,
  ...ForgeShared,
  ...React,
  RawDataPreview,
} satisfies Record<string, unknown>;

/** Formats the demo code for live-editing */
const formatJsCode = (jsCode: string): string => {
  return (
    jsCode
      // Comment out all imports
      .replace(/^import.*from\s+['"][^'"]+['"];?$/ms, '/*\n$&\n*/')
      // Replace default export with render() function
      .replace(/export default (\w+)/gm, 'render(<$1 />)')
      // Remove the first blank line
      .replace(/^\s*\n/, '')
  );
};

/** Generates a live-editable demo with a linkable header */
export default function DemoContainer({ scope, contents, description }: DemoContainerProps): ReactElement {
  const [showDemo, setShowDemo] = useState(false);
  const { pageContext } = useContext(PageContext);

  const wrapperRef = useRef(null);
  const [demoHeading, setDemoHeading] = useState<string | null>(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const demoHeadingTag = (wrapper as HTMLDivElement).previousSibling;
      setDemoHeading(demoHeadingTag && demoHeadingTag.textContent);
    }
  }, []);

  function handleCopyToClipboard(): void {
    window.amplitude.getInstance().logEvent('copy demo code', {
      pageTitle: pageContext?.title,
      demo: demoHeading,
    });
  }

  function logToggleCode(show: boolean): void {
    window.amplitude.getInstance().logEvent('toggle demo code', {
      pageTitle: pageContext?.title,
      demo: demoHeading,
      show: show,
    });
  }

  const editorStyle = {
    fontFamily: "'Source Code Pro', monospace",
    fontSize: '14px',
    lineHeight: '1.5',
  };

  const [code, setCode] = useState(() => formatJsCode(contents));

  return (
    <LinkableHeading idPrefix="demos" text={description}>
      <div className="live-code" ref={wrapperRef}>
        <LiveProvider code={code} noInline scope={{ ...defaultScope, ...scope }}>
          <LivePreview className="live-code__preview" />
          <div className="live-code__toolbar">
            <Forge.Button
              text={showDemo ? 'Hide Code' : 'Show/Edit Code'}
              icon={showDemo ? ForgeIcons.CollapseSmall : ForgeIcons.ExpandSmall}
              variant="tertiary"
              aria-expanded={showDemo}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();
                logToggleCode(!showDemo);
                setShowDemo(!showDemo);
              }}
            />
          </div>
          <Forge.ShowHide show={showDemo}>
            <div className="live-code__editor-wrapper">
              <CopyToClipboard text={code} onCopy={handleCopyToClipboard}>
                <Forge.Button text="Copy code" variant="secondary" size="small" className="live-code__copy-code" />
              </CopyToClipboard>
              <LiveEditor style={editorStyle} onChange={(code) => setCode(code)} />
            </div>
            <LiveError className="live-code__error" />
          </Forge.ShowHide>
        </LiveProvider>
      </div>
    </LinkableHeading>
  );
}
