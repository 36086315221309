import rawContents from "!!raw-loader!./ListBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ListBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="List Basic" />;
}

export default ListBasic;
