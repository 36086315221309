import { ReactElement, Ref, useImperativeHandle, useRef, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import LinearProgress from './LinearProgress';
import CircularProgress from './CircularProgress';
import { nanoid } from 'nanoid';
import forwardRefToProps from '../utils/forwardRefToProps';

export type ProgressIndicatorShape = 'circular' | 'linear';
export type ProgressIndicatorSize = 'medium' | 'small' | 'tiny';
export type ProgressIndicatorVariant = 'determinate' | 'indeterminate';

export interface ProgressIndicatorProps {
  /** Whether to animate the component mounting and unmounting */
  animateTransitions?: boolean;
  /** Custom class(es) to pass to the top level of the component */
  className?: string;
  /** Only used in the 'determinate' variant. Value between 0 and 100 (inclusive). Update this prop to show progress. */
  currentValue?: number;
  /** Text description to accompany the progress indicator */
  description?: string;
  /** Ref to the wrapper <div> */
  ref?: Ref<HTMLDivElement>;
  /** The shape of the progress indicator's "track" */
  shape?: ProgressIndicatorShape;
  /** Determines if ProgressIndicator will be mounted.
   * E.g., set to `false` to hide the component when loading is complete. */
  show?: boolean;
  /* Only used in the 'determinate' variant. Displays the currentValue as a percentage . */
  showValueLabel?: boolean;
  /** Only used for the 'circular' shape. The size of circle to display */
  size?: ProgressIndicatorSize;
  /** The variant to display. Use 'indeterminate' for a short wait time (1.0 to 10 seconds)
   * or when progress isn't detectable. Use 'determinate' when the wait is longer (more than 10 seconds)
   * and the progress rate can be determined. */
  variant?: ProgressIndicatorVariant;
}

interface ProgressIndicatorComponentProps extends ProgressIndicatorProps {
  /** Ref to the wrapper <div> */
  forwardedRef?: Ref<HTMLDivElement>;
}

function ProgressIndicator({
  animateTransitions = true,
  currentValue = 0,
  description,
  forwardedRef,
  shape = 'linear',
  show = true,
  showValueLabel = true,
  variant = 'indeterminate',
  ...rest
}: ProgressIndicatorComponentProps): ReactElement {
  const descriptionId = useRef(`ProgressIndicatorDescription-${nanoid()}`);
  const progressRef = useRef<HTMLDivElement>(null);
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(forwardedRef, () => progressRef.current, []);

  const inOutTransitionTime = 200;
  const baseClassname = 'progress-indicator';

  const determinateProps =
    variant === 'determinate'
      ? {
          ariaAttributes: {
            'aria-valuemin': 0,
            'aria-valuemax': 100,
            'aria-valuenow': currentValue,
          },
          currentValue,
          showValueLabel,
        }
      : null;

  const sharedProps = {
    ...determinateProps,
    ariaAttributes: {
      ...(determinateProps?.ariaAttributes || {}),
      ...(description ? { 'aria-labelledby': descriptionId.current } : {}),
    },
    baseClassname,
    description,
    descriptionId: descriptionId.current,
    shape,
    variant,
  };

  const ProgressComponent = shape === 'linear' ? LinearProgress : CircularProgress;

  const wrappedComponent = animateTransitions ? (
    <CSSTransition
      nodeRef={progressRef}
      timeout={inOutTransitionTime}
      classNames="scale-in-out"
      in={show}
      unmountOnExit
      appear
    >
      <ProgressComponent ref={progressRef} {...sharedProps} {...rest} />
    </CSSTransition>
  ) : (
    <ProgressComponent {...sharedProps} {...rest} />
  );

  return show ? wrappedComponent : <></>;
}

export const ProgressIndicatorPropTypes: WeakValidationMap<ProgressIndicatorProps> = {
  /** Whether to animate the component mounting and unmounting */
  animateTransitions: PropTypes.bool,
  /** Custom class(es) to pass to the top level of the component */
  className: PropTypes.string,
  /** Only used in the 'determinate' variant. Value between 0 and 100 (inclusive). Update this prop to show progress. */
  currentValue: PropTypes.number,
  /** Text description to accompany the progress indicator */
  description: PropTypes.string,
  /** The shape of the progress indicator's "track" */
  shape: PropTypes.oneOf(['circular', 'linear']),
  /** Determines if ProgressIndicator will be mounted.
   * E.g., set to `false` to hide the component when loading is complete. */
  show: PropTypes.bool,
  /* Only used in the 'determinate' variant. Displays the currentValue as a percentage . */
  showValueLabel: PropTypes.bool,
  /** Only used for the 'circular' shape. The size of circle to display */
  size: PropTypes.oneOf(['medium', 'small', 'tiny']),
  /** The variant to display. Use 'indeterminate' for a short wait time (1.0 to 10 seconds)
   * or when progress isn't detectable. Use 'determinate' when the wait is longer (more than 10 seconds)
   * and the progress rate can be determined. */
  variant: PropTypes.oneOf(['determinate', 'indeterminate']),
};

ProgressIndicator.propTypes = ProgressIndicatorPropTypes;

export default forwardRefToProps(ProgressIndicator);
