import rawContents from "!!raw-loader!./Gutters.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Gutters():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Gutters" />;
}

export default Gutters;
