import React, { Fragment, ReactElement, useRef, WeakValidationMap } from 'react';
import PropTypes, { Validator } from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import Button from '../Button';
import useControllableState from '../useControllableState';
import LeftSmall from '@athena/forge-icons/dist/LeftSmall';
import RightSmall from '@athena/forge-icons/dist/RightSmall';
import Input from '../Input';

const classes = forgeClassHelper({ name: 'paginator' });

export type PaginatorDisplayMode = 'default' | 'compact' | 'withPageJump';

type PageCount = number | 'infinite';

export interface PaginatorProps extends React.HTMLAttributes<HTMLElement> {
  /** Adds a class to the root element of the component */
  className?: string;
  /** Initial selected index in [uncontrolled](https://reactjs.org/docs/uncontrolled-components.html) mode */
  defaultSelectedIndex?: number;
  /** Different display modes, including compact for mobile, and page jump input for legacy compatibility  */
  displayMode?: PaginatorDisplayMode;
  /** callback function when selected index changes */
  onSelectedIndexChange?: (value: number) => void;
  /** total number of pages, either number or 'infinite' */
  pageCount?: PageCount;
  /** The id assigned to the text input used for jumping pages.
   * Makes this field accessible.
   * Required if `displayMode` is set to withPageJump.
   */
  pageInputId?: string;
  /** current selected index for [controlled](https://reactjs.org/docs/forms.html#controlled-components) mode */
  selectedIndex?: number;
}

const Paginator = ({
  className,
  displayMode = 'default',
  pageCount = 'infinite',
  pageInputId,
  selectedIndex,
  onSelectedIndexChange,
  defaultSelectedIndex = 0,
  ...passedProps
}: PaginatorProps): ReactElement => {
  const [index, handleChange] = useControllableState(defaultSelectedIndex, selectedIndex, onSelectedIndexChange);

  /**
   * A list of refs for all of the navigable buttons in the paginator.
   *
   * We use this to prevent page changes via the onBlur of the "Jump to" input
   * if we've clicked on a button in the navigation.
   * See bug {@link https://athenajira.athenahealth.com/browse/UXDS-7154 UXDS-7154}.
   */
  const buttonRefs = useRef<HTMLButtonElement[]>([]);
  buttonRefs.current = [];
  const addToRefs = (buttonNum: number): ((el: HTMLButtonElement) => void) => {
    return (el: HTMLButtonElement) => {
      /** Element has been unmounted */
      if (el === null) {
        delete buttonRefs.current[buttonNum];
      } else {
        buttonRefs.current[buttonNum] = el;
      }
    };
  };

  const pages: JSX.Element[] = [];

  const addPage = (pageNum: number, isCurrent?: string, isLast?: boolean): void => {
    const lastMessage = isLast ? ', last page' : '';
    const ariaLabelButton = `Go to page ${pageNum}${lastMessage}`;

    pages.push(
      <li {...classes({ element: 'list-item', states: isCurrent })} key={'page' + pageNum}>
        {isCurrent ? (
          <div
            aria-live="assertive"
            aria-label={`You are on page ${pageNum}`}
            aria-current="page"
            {...classes({ element: 'link', states: isCurrent })}
          >
            <span aria-hidden="true">{pageNum}</span>
          </div>
        ) : (
          <button
            type="button"
            aria-label={ariaLabelButton}
            onClick={(e) => {
              e.preventDefault();
              if (!pageNum) throw new Error('pageNum was unexpectedly undefined');
              const newIndex = pageNum - 1;
              handleChange(newIndex);
            }}
            ref={addToRefs(pageNum)}
            {...classes({ element: 'link' })}
          >
            {pageNum}
          </button>
        )}
      </li>
    );
  };

  const addEllipsis = (num: number): void => {
    pages.push(
      <li {...classes({ element: 'list-item', modifiers: 'ellipsis' })} key={'ellip' + num}>
        &hellip;
      </li>
    );
  };

  if (index === 0) {
    addPage(1, 'current');
  } else {
    addPage(1);
  }

  if (pageCount === 'infinite') {
    if (index === 3) {
      addPage(2);
    } else if (index > 3) {
      addEllipsis(0);
    }

    const count = index < 3 ? 3 : 4;
    for (let i = 0; i < count; i++) {
      const pageNum = index < 3 ? i + 2 : index + i;

      if (pageNum - 1 === index) {
        addPage(pageNum, 'current');
      } else {
        addPage(pageNum);
      }
    }
    addEllipsis(1);
  } else {
    // pageCount as a number
    if (index > 3 && pageCount > 7) {
      addEllipsis(0);
    } else if ((pageCount === 7 && index > 2) || (pageCount > 7 && index === 3)) {
      addPage(2);
    }

    let count = pageCount - 2;
    if (pageCount > 5) {
      count = 3;
      if (index < 3 || index >= pageCount - 4) {
        count = 4;
      }
    }

    for (let i = 0; i < count; i++) {
      let pageNum;
      if (index < 3 || pageCount <= 5) {
        pageNum = i + 2;
      } else if (index >= pageCount - 4) {
        pageNum = pageCount - 4 + i;
      } else {
        pageNum = index + i;
      }

      if (pageNum - 1 === index) {
        addPage(pageNum, 'current');
      } else {
        addPage(pageNum);
      }
    }

    if (pageCount === 7 && index < 3) {
      addPage(pageCount - 1);
    }
    if (pageCount > 7 && index < pageCount - 4) {
      addEllipsis(1);
    }
    if (pageCount > 1) {
      if (index === pageCount - 1) {
        addPage(pageCount, 'current', true);
      } else {
        addPage(pageCount, '', true);
      }
    }
  }

  const renderArrow = (dir: string): ReactElement => {
    let targetIndex = index - 1;
    if (dir === 'right') {
      targetIndex = index + 1;
    }

    let isDisabled = false;
    if (
      (dir === 'left' && index === 0) ||
      (dir === 'right' && pageCount !== 'infinite' && pageCount && index === pageCount - 1)
    ) {
      isDisabled = true;
    }

    const btnOnClick = isDisabled
      ? undefined
      : (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          handleChange(targetIndex);
        };
    return (
      <Button
        aria-label={dir === 'left' ? 'Previous page' : 'Next page'}
        icon={dir === 'left' ? LeftSmall : RightSmall}
        onClick={btnOnClick}
        disabled={isDisabled}
        {...classes({ element: 'arrow' })}
        variant="tertiary"
        ref={addToRefs(targetIndex)}
      />
    );
  };

  function jumpToPage(num: number): void {
    if (pageCount !== 'infinite') {
      if (num >= pageCount) {
        num = pageCount - 1;
      } else if (num < 0) {
        num = 0;
      }
    }
    handleChange(num);
  }

  return (
    <nav
      aria-label="pagination"
      {...passedProps}
      {...classes({
        extra: className,
      })}
    >
      <ul {...classes({ element: 'list' })}>
        <li {...classes({ element: 'list-item' })}>{renderArrow('left')}</li>

        {displayMode === 'compact' ? (
          <li {...classes({ element: 'list-item', modifiers: 'compact-count' })}>
            {index + 1}
            {pageCount !== 'infinite' ? `/${pageCount}` : null}
          </li>
        ) : (
          <Fragment>{pages}</Fragment>
        )}

        <li {...classes({ element: 'list-item' })}>{renderArrow('right')}</li>
      </ul>

      {displayMode === 'withPageJump' && (
        <div {...classes({ element: 'jump-container' })}>
          <label {...classes({ element: 'jump-label' })} htmlFor={pageInputId}>
            Jump to:
          </label>
          <Input
            type="number"
            name={pageInputId}
            id={pageInputId}
            onBlur={(e) => {
              if (e.target.value && !buttonRefs.current.includes(e.relatedTarget as HTMLButtonElement)) {
                jumpToPage(parseInt(e.target.value, 10) - 1);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.currentTarget.value) {
                jumpToPage(parseInt(e.currentTarget.value, 10) - 1);
              }
            }}
            {...classes({ element: 'jump-input' })}
          />
        </div>
      )}
    </nav>
  );
};

const paginatorPropTypes: WeakValidationMap<
  PaginatorProps & {
    '...rest': unknown;
  }
> = {
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Initial selected index in [uncontrolled](https://reactjs.org/docs/uncontrolled-components.html) mode */
  defaultSelectedIndex: PropTypes.number,
  /** Different display modes, including compact for mobile, and page jump input for legacy compatibility  */
  displayMode: PropTypes.oneOf(['default', 'compact', 'withPageJump']),
  /** callback function when selected index changes */
  onSelectedIndexChange: PropTypes.func,
  /** total number of pages, either number or 'infinite' */
  pageCount: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['infinite'])]) as Validator<PageCount>,
  /** The id assigned to the text input used for jumping pages.
   * Makes this field accessible.
   * Required if `displayMode` is set to withPageJump.
   */
  pageInputId: function (props, propName, componentName) {
    if (props[propName] && typeof props[propName] !== 'string') {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Must be a string.`);
    }
    if (props.displayMode === 'withPageJump' && props[propName] === undefined) {
      return new Error(`${propName} must be set in ${componentName} if the displayMode is withPageJump`);
    }
    return null;
  } as Validator<string | undefined>,
  /** current selected index for [controlled](https://reactjs.org/docs/forms.html#controlled-components) mode */
  selectedIndex: PropTypes.number,
  /** Any other props are passed to the root element. */
  '...rest': PropTypes.any,
};

Paginator.propTypes = paginatorPropTypes;

export default Paginator;
