import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgProfileSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.50005 15H2.50005C2.50005 15.5523 2.94777 16 3.50005 16V15ZM3.50005 11.8501H4.50005C4.50005 11.8385 4.49985 11.8268 4.49944 11.8152L3.50005 11.8501ZM12.4936 11.8501L11.4942 11.8152C11.4938 11.8268 11.4936 11.8385 11.4936 11.8501H12.4936ZM12.4936 12H11.4936C11.4936 12.0168 11.494 12.0336 11.4948 12.0504L12.4936 12ZM9.79555 15V16C9.8138 16 9.83204 15.9995 9.85026 15.9985L9.79555 15ZM4.50005 15V11.8501H2.50005V15H4.50005ZM4.49944 11.8152C4.4654 10.8401 4.81856 9.89123 5.48148 9.17623L4.01487 7.81644C2.99247 8.91916 2.4482 10.382 2.50066 11.885L4.49944 11.8152ZM5.48148 9.17623C6.14441 8.46123 7.06318 8.03811 8.03673 7.9992L7.95687 6.0008C6.45457 6.06083 5.03726 6.71374 4.01487 7.81644L5.48148 9.17623ZM7.95687 7.9992C8.93042 8.03811 9.84919 8.46123 10.5121 9.17623L11.9787 7.81644C10.9563 6.71374 9.53903 6.06083 8.03673 6.0008L7.95687 7.9992ZM10.5121 9.17623C11.175 9.89123 11.5282 10.8401 11.4942 11.8152L13.4929 11.885C13.5454 10.382 13.0011 8.91916 11.9787 7.81644L10.5121 9.17623ZM11.4936 11.8501V12H13.4936V11.8501H11.4936ZM11.4948 12.0504C11.5196 12.5419 11.3488 13.0233 11.02 13.3889L12.507 14.7263C13.1895 13.9675 13.5437 12.969 13.4923 11.9496L11.4948 12.0504ZM11.02 13.3889C10.6912 13.7545 10.2313 13.9746 9.74084 14.0015L9.85026 15.9985C10.8693 15.9427 11.8245 15.4852 12.507 14.7263L11.02 13.3889ZM9.79555 14H3.50005V16H9.79555V14ZM9.99458 4C9.99458 5.10527 9.09947 6 7.9968 6V8C10.2054 8 11.9946 6.20843 11.9946 4H9.99458ZM7.9968 6C6.89409 6 5.99891 5.10523 5.99891 4H3.99891C3.99891 6.20848 5.7882 8 7.9968 8V6ZM5.99891 4C5.99891 2.89475 6.89406 2 7.99675 2V0C5.78812 0 3.99891 1.79154 3.99891 4H5.99891ZM7.99675 2C9.09944 2 9.99458 2.89475 9.99458 4H11.9946C11.9946 1.79154 10.2054 0 7.99675 0V2Z"
      fill={props.color}
    />
  </svg>
);
SvgProfileSmall.displayName = 'SvgProfileSmall';
const ProfileSmall = withForgeIconProps(SvgProfileSmall, {
  name: 'Profile',
  usage: 'Use to indicate a patient or user profile',
  related: 'avatar, person',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ProfileSmall;
