import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCollaborateSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4.26498 10.8507L5.56498 12.2007C5.66498 12.3007 5.71498 12.4307 5.71498 12.5507C5.71498 12.7007 5.64498 12.8507 5.50498 12.9607C5.25498 13.1607 4.86498 13.1307 4.64498 12.9007L3.64498 10.7107L2.64498 12.9007C2.42498 13.1307 2.04498 13.1507 1.78498 12.9607C1.53498 12.7607 1.50498 12.4207 1.72498 12.2007L3.18498 10.6907C3.30498 10.5707 3.46498 10.5007 3.64498 10.5007C3.82498 10.5007 3.98498 10.5707 4.10498 10.6907L4.25498 10.8507"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.655 10.6807C4.06921 10.6807 4.405 11.0165 4.405 11.4307V13.6007C4.405 13.9016 4.66393 14.1907 5.045 14.1907C5.43228 14.1907 5.97912 14.1932 6.42793 14.1957C6.65259 14.1969 6.85314 14.1982 6.99753 14.1991L7.2303 14.2007C7.6445 14.2036 7.97792 14.5418 7.97498 14.956C7.97204 15.3702 7.63388 15.7036 7.21968 15.7006L6.98778 15.6991C6.84373 15.6981 6.64366 15.6969 6.41957 15.6957C5.97088 15.6932 5.42772 15.6907 5.045 15.6907C3.88607 15.6907 2.905 14.7797 2.905 13.6007V11.4307C2.905 11.0165 3.24079 10.6807 3.655 10.6807Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.795 5.75069C2.86488 5.75069 2.095 6.51538 2.095 7.45069V8.20069H5.395C5.45272 8.20069 5.495 8.15841 5.495 8.10069V7.45069C5.495 6.52056 4.73031 5.75069 3.795 5.75069ZM0.0950012 7.45069C0.0950012 5.406 1.76513 3.75069 3.795 3.75069C5.83969 3.75069 7.495 5.42081 7.495 7.45069V8.10069C7.495 9.26297 6.55729 10.2007 5.395 10.2007H0.0950012V7.45069Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.805 2.08069C3.34661 2.08069 2.975 2.45229 2.975 2.91069C2.975 3.36908 3.3466 3.74069 3.805 3.74069C4.2634 3.74069 4.635 3.36908 4.635 2.91069C4.635 2.45229 4.2634 2.08069 3.805 2.08069ZM0.975001 2.91069C0.975001 1.34772 2.24204 0.0806885 3.805 0.0806885C5.36797 0.0806885 6.635 1.34772 6.635 2.91069C6.635 4.47365 5.36797 5.74069 3.805 5.74069C2.24204 5.74069 0.975001 4.47365 0.975001 2.91069Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.205 11.4707C11.2748 11.4707 10.505 12.2354 10.505 13.1707V13.9207H13.805C13.8627 13.9207 13.905 13.8784 13.905 13.8207V13.1707C13.905 12.2405 13.1403 11.4707 12.205 11.4707ZM8.50497 13.1707C8.50497 11.126 10.1751 9.47067 12.205 9.47067C14.2497 9.47067 15.905 11.1408 15.905 13.1707V13.8207C15.905 14.983 14.9673 15.9207 13.805 15.9207H8.50497V13.1707Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.205 7.81067C11.7466 7.81067 11.375 8.18227 11.375 8.64067C11.375 9.09906 11.7466 9.47067 12.205 9.47067C12.6634 9.47067 13.035 9.09906 13.035 8.64067C13.035 8.18227 12.6634 7.81067 12.205 7.81067ZM9.37497 8.64067C9.37497 7.0777 10.642 5.81067 12.205 5.81067C13.7679 5.81067 15.035 7.0777 15.035 8.64067C15.035 10.2036 13.7679 11.4707 12.205 11.4707C10.642 11.4707 9.37497 10.2036 9.37497 8.64067Z"
      fill={props.color}
    />
    <path
      d="M11.6149 5.1907L10.3149 3.8407C10.2149 3.7407 10.1649 3.6107 10.1649 3.4907C10.1649 3.3407 10.2349 3.1907 10.3749 3.0807C10.6249 2.8807 11.0149 2.9107 11.2349 3.1407L12.225 5.3607L13.2349 3.1407C13.4549 2.9107 13.8349 2.8907 14.0949 3.0807C14.3449 3.2807 14.3749 3.6207 14.1549 3.8407L12.6949 5.3507C12.5749 5.4707 12.4149 5.5407 12.2349 5.5407C12.0549 5.5407 11.8949 5.4707 11.7749 5.3507L11.6249 5.1907"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.69499 1.0857C7.69775 0.671494 8.03577 0.337954 8.44998 0.340715L8.43998 1.84068C8.02577 1.83792 7.69223 1.4999 7.69499 1.0857ZM8.43998 1.84068L8.68742 1.84225C8.84086 1.84319 9.0539 1.84444 9.29229 1.84569C9.76858 1.84819 10.3478 1.8507 10.755 1.8507C11.2183 1.8507 11.475 2.17932 11.475 2.4407V4.6107C11.475 5.02491 11.8108 5.3607 12.225 5.3607C12.6392 5.3607 12.975 5.02491 12.975 4.6107V2.4407C12.975 1.22207 11.9117 0.350698 10.755 0.350698C10.3521 0.350698 9.77637 0.348208 9.30016 0.345709C9.06231 0.34446 8.84972 0.343211 8.6966 0.342275L8.44998 0.340715L8.43998 1.84068Z"
      fill={props.color}
    />
  </svg>
);
SvgCollaborateSmall.displayName = 'SvgCollaborateSmall';
const CollaborateSmall = withForgeIconProps(SvgCollaborateSmall, {
  name: 'Collaborate',
  usage: 'Use to show collaboration or coaching',
  related: 'N/A',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default CollaborateSmall;
