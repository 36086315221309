import NumericRangeInput, { NumericRangeInputValues } from '../../../../NumericRangeInput';
import { DataTableFilter, CreateDataFilterCommonArgs } from '../../../utils/internalTypes';

type CreateNumericRangeInputFilterArgs<RowData> = CreateDataFilterCommonArgs<RowData> & {
  allowNullValues?: boolean; // Default: false
  currency?: boolean; // Default: false
  integer?: boolean; // Default: true
  serverSideQueryParamFn?: (numericRange: NumericRangeInputValues) => string;
};
export type CreateNumericRangeInputFilter<RowData> = typeof createNumericRangeInputFilter<RowData>;

export function createNumericRangeInputFilter<RowData>({
  allowNullValues = false,
  filterId,
  filterLabel,
  rowDataFieldName,
  currency = false,
  integer = true,
  serverSideQueryParamFn,
}: CreateNumericRangeInputFilterArgs<RowData>): DataTableFilter<RowData, NumericRangeInputValues> {
  return {
    filterId,
    filterLabel,
    serverSideQueryParamFn:
      typeof serverSideQueryParamFn !== 'undefined'
        ? ({ conditionValues }) => serverSideQueryParamFn(conditionValues[0])
        : undefined,
    clientSideFilterFn: ({ rowData, conditionValues }): boolean => {
      const fieldValue = rowData[rowDataFieldName] as number;
      const isInvalid = typeof fieldValue !== 'number' && fieldValue !== null;
      if (isInvalid) {
        throw new Error(`The filtered field (${String(rowDataFieldName)}) should be number type.`);
      }

      const conditionValue = conditionValues[0];
      const [minIsSet, min] = checkAndParseNumber(conditionValue.min);
      const [maxIsSet, max] = checkAndParseNumber(conditionValue.max);
      const checked = conditionValue.checked;

      // If no filter values are set, skip filtering
      if (!minIsSet && !maxIsSet && !checked) {
        return true;
      }

      const [rowIsSet, rowValue] = checkAndParseNumber(fieldValue);

      if (!rowIsSet) {
        return checked ?? false;
      } else if (!minIsSet && !maxIsSet) {
        // In the case where allowNulls is set, but min and max aren't,
        // then we want _only_ nulls but this row has a value.
        return false;
      } else {
        return (!minIsSet || rowValue >= min) && (!maxIsSet || rowValue <= max);
      }
    },

    DataFilterComponent: ({ useSelector, onFilterConditionValuesChange }) => {
      // Each input component has distinct EMPTY_STATE, such as '', undefined, null, ...
      const EMPTY_STATE: NumericRangeInputValues = {
        min: undefined,
        max: undefined,
        checked: undefined,
      };

      const conditionValues = useSelector(
        (s) => s.dataFilter.filters[filterId]?.conditionValues ?? []
      ) as NumericRangeInputValues[];
      const tableId = useSelector((s) => s.tableId);

      return (
        <NumericRangeInput
          allowNullValuesCheckBox={allowNullValues}
          id={`${tableId}-numeric-range-input-filter-${filterId}`}
          errorAlwaysBelow={true}
          isCurrency={currency}
          onBlurAllValues={(e, { min, max, checked }) => {
            const isEmpty = typeof max === 'undefined' && typeof min === 'undefined';
            onFilterConditionValuesChange(isEmpty ? [] : [{ min, max, checked }]);
          }}
          suppressVerticalSpacing={true}
          values={conditionValues.length === 0 ? EMPTY_STATE : conditionValues[0]}
        />
      );
    },

    describeConditionValue: (filterValue) => {
      const [minIsSet, min] = checkAndParseNumber(filterValue.min);
      const [maxIsSet, max] = checkAndParseNumber(filterValue.max);
      let minStr;
      let maxStr;
      if (currency) {
        const minDigits = Number.isInteger(min) ? 0 : 2;
        const maxDigits = Number.isInteger(max) ? 0 : 2;
        minStr =
          minIsSet &&
          min.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: minDigits,
            maximumFractionDigits: minDigits,
          });
        maxStr =
          maxIsSet &&
          max.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: maxDigits,
            maximumFractionDigits: maxDigits,
          });
      } else {
        minStr = min;
        maxStr = max;
      }

      if (minIsSet && maxIsSet) {
        if (minStr === maxStr) {
          return 'Exactly ' + minStr;
        }
        return minStr + ' - ' + maxStr;
      } else if (minIsSet) {
        return minStr + (currency ? ' or more' : ' or above');
      } else {
        return maxStr + (currency ? ' or less' : ' or below');
      }
    },
  };
}

// ====================================
// Utils
// ====================================
const checkAndParseNumber = (value: number | string | undefined | null): [boolean, number] => {
  if (value == null) return [false, NaN];
  if (typeof value === 'number') return [true, value];
  const num = parseFloat(value);
  return [!isNaN(num), num];
};
