import { VersionContext } from '../../contexts';
import useVersion from '../../hooks/useVersion';
import { ReactElement, ReactNode } from 'react';

type VersionContextProviderProps = {
  children?: ReactNode;
};

/**
 * For best performance, context providers should wrap children in useMemo or
 * be refactored into a separate component: https://www.youtube.com/watch?v=CDGBTjMBJzg
 */
const VersionContextProvider = ({ children }: VersionContextProviderProps): ReactElement => {
  const versions = useVersion();

  return <VersionContext.Provider value={{ versions }}>{children}</VersionContext.Provider>;
};

export default VersionContextProvider;
