import ControlledAndUncontrolled from './ControlledAndUncontrolled.gen';
import CustomValidation from './CustomValidation.gen';
import HourCycle from './HourCycle.gen';
import MinMaxTime from './MinMaxTime.gen';
import Standalone from './Standalone.gen';
import FormField from './FormField.gen';
export default function Demos(): React.ReactElement {
  return (
    <>
      <Standalone />
      <ControlledAndUncontrolled />
      <CustomValidation />
      <HourCycle />
      <MinMaxTime />
      <FormField />
    </>
  );
}
