import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgThumbsUpSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.5 5.73114H3.48V14.5H1.5V5.73114ZM3.76 13.7924H9.91002C12.69 14.1271 13.53 11.1149 13.53 11.1149C13.53 11.1149 14.94 6.47702 14.36 6.47702H9.54002C10.56 2.35557 10.06 1.59056 8.59001 1.5045C7.12001 1.41844 6.93001 2.5755 6.25001 4.23939C5.57001 5.90327 4.25001 6.69696 4.25001 6.69696L3.75 6.06583L3.77 13.7924H3.76Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgThumbsUpSmall.displayName = 'SvgThumbsUpSmall';
const ThumbsUpSmall = withForgeIconProps(SvgThumbsUpSmall, {
  name: 'ThumbsUp',
  usage: 'Use to show positive interactions or thumbs up',
  related: 'ThumbsDown',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ThumbsUpSmall;
