import rawContents from "!!raw-loader!./PopoverButton.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function PopoverButton():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Popover Button" />;
}

export default PopoverButton;
