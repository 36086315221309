import rawContents from "!!raw-loader!./AccordionBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function AccordionBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Accordion Basic" />;
}

export default AccordionBasic;
