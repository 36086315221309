type DataTableBlockUiProps = {
  block: boolean;
  children: React.ReactNode;
};

/**
 * The DataTableBlockUI component serves the purpose of both blocking the UI and disabling components.
 *
 * Note:
 * - The DataTableBlockUI component should be substituted with the Forge BlockUI component.
 */
export const DataTableBlockUI = ({ children, block }: DataTableBlockUiProps): JSX.Element => {
  // This class replicates much of what Forge Loader creates for blocking
  // mouse clicks by the user. It has no constraint on its height unlike
  // Forge Loader that has a minimum height based on the spinner dimensions.
  //
  // It checks the loading state variable used by the Forge Loader in here.
  return (
    <div className="block-ui fe_c_loader" aria-busy={block}>
      {children}
      {block && <div className="block-ui-overlay" />}
    </div>
  );
};
