import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLeaveSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 1V0H0V1H1ZM9.79289 11.7929C9.40237 12.1834 9.40237 12.8166 9.79289 13.2071C10.1834 13.5976 10.8166 13.5976 11.2071 13.2071L9.79289 11.7929ZM15 8L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L15 8ZM11.2071 2.79289C10.8166 2.40237 10.1834 2.40237 9.79289 2.79289C9.40237 3.18342 9.40237 3.81658 9.79289 4.20711L11.2071 2.79289ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9V7ZM7 14H4V16H7V14ZM4 14C3.46955 14 2.96083 13.7893 2.58577 13.4142L1.17156 14.8284C1.92172 15.5786 2.93916 16 4 16V14ZM2.58577 13.4142C2.21071 13.0392 2 12.5305 2 12H0C0 13.0608 0.421395 14.0783 1.17156 14.8284L2.58577 13.4142ZM2 12V1H0V12H2ZM1 2H7V0H1V2ZM11.2071 13.2071L15.7071 8.70711L14.2929 7.29289L9.79289 11.7929L11.2071 13.2071ZM15.7071 7.29289L11.2071 2.79289L9.79289 4.20711L14.2929 8.70711L15.7071 7.29289ZM6 9H15V7H6V9Z"
      fill={props.color}
    />
  </svg>
);
SvgLeaveSmall.displayName = 'SvgLeaveSmall';
const LeaveSmall = withForgeIconProps(SvgLeaveSmall, {
  name: 'Leave',
  usage: 'Use to leave the current page',
  related: 'exit, navigation, CheckIn',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default LeaveSmall;
