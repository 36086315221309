import Button from '../../Button';
import EditSmall from '@athena/forge-icons/dist/EditSmall';
import EditLarge from '@athena/forge-icons/dist/EditLarge';
import { DataTableHooksAndRowId } from '../utils/internalTypes';
import { inline_edit_row } from '../store/actions/inline_edit_row';
import { ACTION_BUTTON_SIZES } from '../utils/constants';

export type CreateInlineEditRowActionButton<RowData> = typeof createInlineEditRowActionButton<RowData>;

export function createInlineEditRowActionButton<RowData>() {
  return function EditRowActionButton({
    useDispatch,
    useSelector,
    rowId,
  }: DataTableHooksAndRowId<RowData>): JSX.Element {
    const dispatch = useDispatch();
    const tableLayout = useSelector((s) => s.tableLayout);

    return (
      <Button
        data-row-action-name="inline-edit"
        icon={{ medium: EditLarge, compact: EditSmall }[tableLayout]}
        size={ACTION_BUTTON_SIZES[tableLayout]}
        variant="tertiary"
        onClick={(event) => {
          event.stopPropagation(); // prevent trigger left click on row
          dispatch(inline_edit_row(rowId));
        }}
      />
    );
  };
}
