import { DataTableAsyncAction, DataTableState } from '../stateTypes';
import { AlertData, ModalAlertData, ToastAlertData } from '../../utils/internalTypes';
import { AwaitableModal } from '../../alerts/AwaitableModal';

type RestoreRowAsyncActionArgs<RowData> = {
  tableType: DataTableState<RowData>['tableType'];
  rowData: RowData;
  rowId: number;
  serverSideRestoreRowFn?: (rowData: RowData) => Promise<void>;
  onRowRestored?: (rowData: RowData) => void;
  confirmModal?: (rowData: RowData) => AlertData;
  completeModal?: (rowData: RowData) => ModalAlertData;
  completeToast?: (rowData: RowData) => ToastAlertData;
};

export function restore_row_async<RowData>({
  tableType,
  rowData,
  rowId,
  serverSideRestoreRowFn,
  onRowRestored,
  confirmModal: getConfirmModalData,
  completeModal: getCompleteModalData,
  completeToast: getCompleteToastData,
}: RestoreRowAsyncActionArgs<RowData>): DataTableAsyncAction<RowData> {
  return async (dispatch) => {
    // ==========================
    // Confirm Modal
    // ==========================
    if (typeof getConfirmModalData !== 'undefined') {
      const { title, message } = getConfirmModalData(rowData);
      const result = await AwaitableModal(dispatch, {
        alertType: 'attention',
        headerText: title,
        children: message,
      });
      if (result === 'secondary') return;
    }

    dispatch(function before_restoring_row(s) {
      s.loader.show = true;
    });

    // ==========================
    // Restore Rows Action
    // ==========================
    try {
      if (tableType === 'server-side') {
        if (serverSideRestoreRowFn === undefined) {
          throw new Error('Missing onRestore for server-side row restoration.');
        }

        await serverSideRestoreRowFn(rowData);
        dispatch(function after_restoring_row_success(s) {
          s.loader.show = false;
          s.forceFetchRowDataCounter++; // force refreshing row data
        });
      } else if (tableType === 'client-side') {
        dispatch(function client_side_restore_row(s) {
          s.loader.show = false;
          s.rows[rowId].rowStatus = 'view';
        });
      }

      // invoke onRestored callback when restored completed
      onRowRestored?.(rowData);

      // ==========================
      // Complete Modal
      // ==========================
      if (typeof getCompleteModalData !== 'undefined') {
        const { alertType, title, message } = getCompleteModalData(rowData);
        await AwaitableModal(dispatch, { alertType, headerText: title, children: message });
      }

      // ==========================
      // Complete Toast
      // ==========================
      if (typeof getCompleteToastData !== 'undefined') {
        dispatch(function push_toast_stack(s) {
          const { alertType, title, message } = getCompleteToastData(rowData);
          s.toastStack.push({ alertType, headerText: title, children: message });
        });
      }
    } catch (e) {
      dispatch(function after_server_side_restore_row_failure(s) {
        s.loader.show = false;
      });

      // ========================
      // Error Modal
      // ========================
      await AwaitableModal(dispatch, {
        alertType: 'attention',
        headerText: 'Restore Row Failure',
        children: (e as Error).message,
      });
    }
  };
}
