import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFirstPageSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.00098 1L3 15"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.001 1L7.00098 8L14.001 15"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgFirstPageSmall.displayName = 'SvgFirstPageSmall';
const FirstPageSmall = withForgeIconProps(SvgFirstPageSmall, {
  name: 'FirstPage',
  usage: 'Use to navigate to the first page in table pagination',
  related: 'last page, pagination',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default FirstPageSmall;
