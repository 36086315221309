import { ReactElement } from 'react';
import parse from 'html-react-parser';

import './do-donts.scss';
import { defaultOptions } from '../../../html-parsing-options';

interface DoDontsProps {
  field_do_description: string;
  field_do_image: string;
  field_don_t_description: string;
  field_don_t_image: string;
}

interface BoxProps {
  isDo: boolean;
  description: string;
  image: string;
}

const Box = ({ isDo, description, image }: BoxProps): ReactElement => {
  const color = isDo ? '#118647' : '#CA0D0D';

  return (
    <div className="do-dont__container">
      <div className="do-dont__top-container">
        <div className="do-dont__description-container">
          <div className="do-dont__description-prefix" style={{ color }}>
            {isDo ? 'Do:' : "Don't:"}
          </div>
          <div className="do-dont__description">{description && parse(description, defaultOptions())}</div>
        </div>
      </div>
      <div className="do-dont__color-block" style={{ backgroundColor: color }} />
      <div className="do-dont__bottom-container">
        <img className="do-dont__image" alt={description} src={image} />
      </div>
    </div>
  );
};

const DoDonts = ({
  field_do_description,
  field_do_image,
  field_don_t_description,
  field_don_t_image,
}: DoDontsProps): ReactElement => {
  return (
    <div className="do-dont__wrapper">
      <Box isDo={true} description={field_do_description} image={field_do_image} />
      <Box isDo={false} description={field_don_t_description} image={field_don_t_image} />
    </div>
  );
};

export default DoDonts;
