import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCallSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.16178 13.1365C1.04419 12.9781 0.987578 12.7826 1.00229 12.5859C1.01699 12.3891 1.102 12.2042 1.24183 12.0651L3.4845 9.81231C3.56075 9.73602 3.6513 9.67553 3.75094 9.63424C3.85057 9.59295 3.95742 9.57178 4.06528 9.57178C4.17313 9.57178 4.27986 9.59295 4.3795 9.63424C4.47913 9.67553 4.56969 9.73602 4.64593 9.81231L5.46686 10.6334C5.60662 10.7442 5.7753 10.8125 5.95279 10.8303C6.13028 10.848 6.30911 10.8145 6.46805 10.7335C7.40851 10.3059 8.253 9.69287 8.95101 8.93122C9.71544 8.23797 10.326 7.39209 10.7432 6.44819C10.823 6.28893 10.8559 6.11039 10.8382 5.93315C10.8204 5.75592 10.7528 5.58727 10.6431 5.44696L9.82216 4.626C9.74618 4.55061 9.68586 4.46094 9.64471 4.36213C9.60355 4.26332 9.58238 4.15726 9.58238 4.05022C9.58238 3.94319 9.60355 3.83725 9.64471 3.73844C9.68586 3.63963 9.74618 3.54995 9.82216 3.47456L12.0749 1.24184C12.214 1.102 12.3988 1.01699 12.5955 1.00229C12.7922 0.987578 12.9877 1.0442 13.1461 1.16178C17.3511 4.22555 13.3363 9.09147 11.5442 10.9838L11.2638 11.2742L10.9734 11.5546C9.09119 13.3267 4.22544 17.3617 1.16178 13.1365Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgCallSmall.displayName = 'SvgCallSmall';
const CallSmall = withForgeIconProps(SvgCallSmall, {
  name: 'Call',
  usage: 'Use to indicate a phone number or the ability to call',
  related: 'mobile, contact',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default CallSmall;
