import EditSmall from '@athena/forge-icons/dist/EditSmall';

import rawContents from "!!raw-loader!./MenuUsingTertiaryButton.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { EditSmall };

function MenuUsingTertiaryButton():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Menu Using Tertiary Button" />;
}

export default MenuUsingTertiaryButton;
