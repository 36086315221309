import NotFound from './NotFound.gen';
import ExtendedWait from './ExtendedWait.gen';
import TasksComplete from './TasksComplete.gen';
import NoFilterData from './NoFilterData.gen';
import StartList from './StartList.gen';
import Restricted from './Restricted.gen';
import TimedOut from './TimedOut.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <NotFound />
      <ExtendedWait />
      <TasksComplete />
      <NoFilterData />
      <StartList />
      <Restricted />
      <TimedOut />
    </>
  );
}
