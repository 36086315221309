import React, { ReactElement, Ref, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';
import { AlertTypes } from '../utils/constants';

const classes = forgeClassHelper({ name: 'badge' });

/** A supported preset type string */
export type BadgeType = AlertTypes;
/** The list of supported preset types */
export const badgeTypes: readonly BadgeType[] = ['attention', 'critical', 'info', 'new', 'success'];

export interface BadgeProps {
  /** Content to display inside the badge */
  badgeContent: number;

  /** Child to decorate with the badge. The badge will display in the top-right corner of this element. */
  children: React.ReactNode;

  /** CSS class to apply to the Element's root div */
  className?: string;

  /** Set to true to hide the badge. The child content will continue to display. */
  hideBadge?: boolean;

  /** Maximum value to display. If the number passed exceeds this number, the number will be truncated to the max with a plus sign appended. */
  max?: number;

  /** ref to the outermost <div> if present. */
  ref?: Ref<HTMLDivElement>;

  /** The badge auto-hides when badgeContent is zero. Set this to true to show the badge when badgeContent is zero. */
  showZero?: boolean;

  /** Type determines the badge's color */
  type?: BadgeType;
}

interface BadgeComponentProps extends BadgeProps {
  /** ref to the outermost <div> if present. */
  forwardedRef?: Ref<HTMLDivElement>;
}

/** Badge shows a count of unread or new items. */
function Badge({
  badgeContent,
  className,
  children,
  forwardedRef,
  hideBadge = false,
  max = undefined,
  showZero = false,
  type = 'attention',
}: BadgeComponentProps): ReactElement {
  const showBadge = !hideBadge && (showZero || badgeContent > 0);
  if (!showBadge) return <>{children}</>;
  return (
    <div ref={forwardedRef} {...classes({ extra: className })}>
      {children}
      <div {...classes({ element: 'badge', extra: className, modifiers: type })}>
        {max && max < badgeContent ? `${max}+` : badgeContent}
      </div>
    </div>
  );
}

export const BadgePropTypes: WeakValidationMap<BadgeProps> = {
  /** Content to display inside the badge (a number that represents the number of items) */
  badgeContent: PropTypes.number.isRequired,

  /** Child to decorate with the badge. The badge will display in the top-right corner of this element. */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,

  /** CSS class to apply to the Element's root div */
  className: PropTypes.string,

  /** Set to true to hide the badge. The child content will continue to display. */
  hideBadge: PropTypes.bool,

  /** Maximum value to display. If the number passed exceeds this number, the number will be truncated to the max with a plus sign appended. */
  max: PropTypes.number,

  /** The badge auto-hides when badgeContent is zero. Set this to true to show the badge when badgeContent is zero. */
  showZero: PropTypes.bool,

  /** Type determines the badge's color */
  type: PropTypes.oneOf(badgeTypes),
};

Badge.propTypes = BadgePropTypes;
Badge.displayName = 'Badge';

export default forwardRefToProps(Badge);
