import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUnderlineSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M1 14H15V16H1V14Z" fill={props.color} />
    <path
      d="M8.01214 13C7.09093 13 6.25861 12.8959 5.51517 12.6877C4.7879 12.4665 4.1576 12.1216 3.62426 11.6532C3.10709 11.1847 2.70305 10.5861 2.41214 9.85736C2.13739 9.11562 2.00002 8.21772 2.00002 7.16366V0H4.81214V7.33934C4.81214 8.02903 4.88487 8.61461 5.03032 9.0961C5.19194 9.56456 5.41012 9.94194 5.68487 10.2282C5.97578 10.5145 6.31517 10.7227 6.70305 10.8529C7.09093 10.97 7.52729 11.0285 8.01214 11.0285C8.49699 11.0285 8.94143 10.97 9.34548 10.8529C9.74952 10.7227 10.097 10.5145 10.3879 10.2282C10.6788 9.94194 10.9051 9.56456 11.0667 9.0961C11.2283 8.61461 11.3091 8.02903 11.3091 7.33934V0H14V7.16366C14 8.21772 13.8626 9.11562 13.5879 9.85736C13.3132 10.5861 12.9091 11.1847 12.3758 11.6532C11.8586 12.1216 11.2283 12.4665 10.4849 12.6877C9.7576 12.8959 8.93335 13 8.01214 13Z"
      fill={props.color}
    />
  </svg>
);
SvgUnderlineSmall.displayName = 'SvgUnderlineSmall';
const UnderlineSmall = withForgeIconProps(SvgUnderlineSmall, {
  name: 'Underline',
  usage:
    'Use in a ComboButton or SegmentedButton to add or remove underline text formatting',
  related: 'bold, font, underline, text, edit, text editor',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default UnderlineSmall;
