import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHeartEmptySmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.00006 4.39905L9.33618 2.99915C9.63915 2.68362 10.0032 2.43244 10.4064 2.26086C10.8096 2.08929 11.2435 2.00085 11.682 2.00085C12.1204 2.00085 12.5544 2.08929 12.9576 2.26086C13.3608 2.43244 13.7249 2.68362 14.0279 2.99915C14.6516 3.64387 15 4.504 15 5.39905C15 6.2941 14.6516 7.15447 14.0279 7.79919L8.00006 13.9991L1.97226 7.79919C1.34854 7.15447 1 6.2941 1 5.39905C1 4.504 1.34854 3.64387 1.97226 2.99915C2.27522 2.68362 2.63932 2.43244 3.0425 2.26086C3.44569 2.08929 3.87956 2.00085 4.31804 2.00085C4.75652 2.00085 5.19051 2.08929 5.5937 2.26086C5.99688 2.43244 6.36085 2.68362 6.66382 2.99915L8.00006 4.39905Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgHeartEmptySmall.displayName = 'SvgHeartEmptySmall';
const HeartEmptySmall = withForgeIconProps(SvgHeartEmptySmall, {
  name: 'HeartEmpty',
  usage: 'Use to like an item',
  related: 'star, love, heart, favorite, unlike',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default HeartEmptySmall;
