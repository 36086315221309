import { withPrefix } from 'gatsby';

import rawContents from "!!raw-loader!./ListWithImage.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { withPrefix };

function ListWithImage():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="List With Image" />;
}

export default ListWithImage;
