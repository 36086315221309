import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStarEmptyLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1L14.62 9.37988L23 9.3999L16.23 14.6101L18.8 23L12 17.8401L5.2 23L7.77 14.6101L1 9.3999L9.38 9.37988L12 1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgStarEmptyLarge.displayName = 'SvgStarEmptyLarge';
const StarEmptyLarge = withForgeIconProps(SvgStarEmptyLarge, {
  name: 'StarEmpty',
  usage: 'Use to mark an item as a favorite',
  related: 'pin, heart, like, unlike',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default StarEmptyLarge;
