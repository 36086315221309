import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgThumbsDownLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M21.6926 14.6827L17.2426 14.6627L17.2726 0.992691L21.7526 1.01269L21.6926 14.6827ZM17.2726 1.79269L8.27256 1.75269C4.20256 1.18269 2.95256 6.20269 2.95256 6.20269C2.95256 6.20269 0.852563 13.9127 1.70256 13.9127L8.76256 13.9427C7.24256 20.7927 7.97256 22.0727 10.1226 22.2327C12.2726 22.3927 12.5626 20.4627 13.5726 17.6927C14.5826 14.9227 16.5226 13.6227 16.5226 13.6227L17.2526 14.6727L17.2826 1.80269L17.2726 1.79269Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgThumbsDownLarge.displayName = 'SvgThumbsDownLarge';
const ThumbsDownLarge = withForgeIconProps(SvgThumbsDownLarge, {
  name: 'ThumbsDown',
  usage: 'Use to show negative interactions or thumbs down',
  related: 'ThumbsUp',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ThumbsDownLarge;
