import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgListTaskLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 5.46138C9 4.93043 9.3731 4.5 9.83333 4.5H23.1667C23.6269 4.5 24 4.93043 24 5.46138C24 5.99234 23.6269 6.42277 23.1667 6.42277H9.83333C9.3731 6.42277 9 5.99234 9 5.46138ZM9 12C9 11.469 9.3731 11.0386 9.83333 11.0386H23.1667C23.6269 11.0386 24 11.469 24 12C24 12.531 23.6269 12.9614 23.1667 12.9614H9.83333C9.3731 12.9614 9 12.531 9 12ZM9 18.5386C9 18.0077 9.3731 17.5772 9.83333 17.5772H23.1667C23.6269 17.5772 24 18.0077 24 18.5386C24 19.0696 23.6269 19.5 23.1667 19.5H9.83333C9.3731 19.5 9 19.0696 9 18.5386Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.74372 2.74408C7.08543 3.06951 7.08543 3.59715 6.74372 3.92259L3.24372 7.25592C2.90201 7.58136 2.34799 7.58136 2.00628 7.25592L0.256281 5.58926C-0.0854272 5.26382 -0.0854272 4.73618 0.256281 4.41074C0.59799 4.08531 1.15201 4.08531 1.49372 4.41074L2.625 5.48816L5.50628 2.74408C5.84799 2.41864 6.40201 2.41864 6.74372 2.74408Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.74372 9.74408C7.08543 10.0695 7.08543 10.5972 6.74372 10.9226L3.24372 14.2559C2.90201 14.5814 2.34799 14.5814 2.00628 14.2559L0.256281 12.5893C-0.0854272 12.2638 -0.0854272 11.7362 0.256281 11.4107C0.59799 11.0853 1.15201 11.0853 1.49372 11.4107L2.625 12.4882L5.50628 9.74408C5.84799 9.41864 6.40201 9.41864 6.74372 9.74408Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.74372 16.7441C7.08543 17.0695 7.08543 17.5972 6.74372 17.9226L3.24372 21.2559C2.90201 21.5814 2.34799 21.5814 2.00628 21.2559L0.256281 19.5893C-0.0854272 19.2638 -0.0854272 18.7362 0.256281 18.4107C0.59799 18.0853 1.15201 18.0853 1.49372 18.4107L2.625 19.4882L5.50628 16.7441C5.84799 16.4186 6.40201 16.4186 6.74372 16.7441Z"
      fill={props.color}
    />
  </svg>
);
SvgListTaskLarge.displayName = 'SvgListTaskLarge';
const ListTaskLarge = withForgeIconProps(SvgListTaskLarge, {
  name: 'ListTask',
  usage:
    'Use to indicate a task list, or the ability to switch to a task list view',
  related: 'table, grid, list, checklist, tasks, task list',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ListTaskLarge;
