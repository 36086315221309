import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React, {
  FocusEventHandler,
  FormEventHandler,
  ReactElement,
  Ref,
  WeakValidationMap,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import Checkbox from '../Checkbox';
import { ListContext } from '../List/ListContext';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';

const classes = forgeClassHelper('list-item');

export interface ListItemProps extends React.LiHTMLAttributes<HTMLElement> {
  /** Aria-label to apply to the item */
  'aria-label'?: string;
  /** value added to styling classes  */
  active?: boolean;
  /** Content to render in SelectionItem */
  children?: React.ReactNode;

  /** Adds a class to the root element of the component */
  className?: string;

  /** Impacts the styling of the checkbox in addition to preventing selection */
  disabled?: boolean;

  /** Highlights the checkbox when defined (specific string doesn't matter) */
  error?: string;

  /** Function that takes a JS event as an argument. Called when the SelectionList or ListItem loses focus */
  onBlur?: FocusEventHandler<HTMLElement>;
  /** Function that takes a fake event as an argument. This event contains a target object with value and id properties. Called when the SelectionList or ListItem value changes */
  onChange?: FormEventHandler<HTMLElement>;
  /** Upstream onClick */
  onClick?: React.MouseEventHandler<HTMLElement>;
  /**
   * Function that takes a JS event as an argument. Called when the SelectionList or ListItem receives focus
   */
  onFocus?: FocusEventHandler<HTMLElement>;

  /** Determines whether the item is selected. */
  selected?: boolean;

  /** A unique identifier for this item in its list. Equivalent to a checkbox element's value attribute.
   *
   * Required if List.variant === "selection"
   */
  uniqueKey?: string;

  /**
   * Sets the value attribute on the native `<input type="checkbox">`.
   * Note that ListItem treats value differently than other inputs - the value does not change depending on the
   * status of `selected`.
   */
  value?: string;
  /** Ref forwarded to the <li> element */
  ref?: Ref<HTMLLIElement>;
}

interface ListItemComponentProps extends ListItemProps {
  /** Ref forwarded to the <li> element */
  forwardedRef?: Ref<HTMLLIElement>;
}

/**
 * ListItem
 *
 * A component to render an unordered list item.
 * @documentedBy List
 */
const ListItem = ({
  active = false,
  'aria-label': ariaLabel,
  children,
  className,
  disabled = false,
  error,
  forwardedRef,
  onClick: upstreamOnClick,
  selected: upstreamSelected,
  uniqueKey,
  value,
  ...rest
}: ListItemComponentProps): ReactElement => {
  const {
    disabled: listDisabled,
    error: listError,
    id: listId,
    layout,
    listValue,
    onSelect,
    padded,
    showDividers,
    variant,
  } = useContext(ListContext);

  const id = `${listId ?? nanoid()}-${uniqueKey}`;

  /** Update List state when selected changes via props */
  useEffect(() => {
    if (upstreamSelected !== undefined && uniqueKey) {
      onSelect(uniqueKey, upstreamSelected);
    }
  }, [onSelect, upstreamSelected, uniqueKey]);
  const selected = useMemo(() => {
    if (upstreamSelected !== undefined) {
      return upstreamSelected;
    } else if (uniqueKey && listValue[uniqueKey] !== undefined) {
      return listValue[uniqueKey];
    } else {
      return false;
    }
  }, [listValue, uniqueKey, upstreamSelected]);
  const isDisabled = disabled || listDisabled;
  const isError = error || listError;

  const listItemSize = layout === 'compact' ? 'small' : 'medium';

  const checkboxId = `${id}-checkbox`;
  const labelId = `${id}-label`;

  const itemProps = {
    ...rest,
    'aria-disabled': isDisabled,
    'data-key': uniqueKey,
  };

  // Handle the click event for the list item
  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (isDisabled) {
      event.stopPropagation();
      return;
    }
    if (!isDisabled && upstreamOnClick) {
      upstreamOnClick(event);
    }
  };

  return (
    <li
      {...itemProps}
      {...classes({
        extra: className,
        modifiers: { [listItemSize]: true, padded: !!padded, dividers: !!showDividers },
        states: { disabled: isDisabled, active },
      })}
      id={id}
      onClick={handleClick}
      aria-label={ariaLabel}
      ref={forwardedRef}
    >
      {variant === 'simple' ? (
        children
      ) : (
        <>
          <Checkbox
            {...classes({ element: 'checkbox' })}
            value={value || uniqueKey}
            description={''}
            disabled={isDisabled}
            error={isError}
            id={checkboxId}
            checked={selected}
            /* This is required to stop the onClick of the parent list being fired twice,
               once for the label and once for the checkbox itself */
            onClick={(event) => {
              event.stopPropagation();
            }}
            /* Setting checked makes this a controlled input
               controlled inputs should either have an onChange handler or be read-only
               in this case, the onChange handler is instantiated by the parent list,
               and so we stop propagation from the checkbox. */
            onChange={(event) => {
              event.stopPropagation();
            }}
            aria-labelledby={labelId}
          />
          <label {...classes({ element: 'label' })} htmlFor={checkboxId} id={labelId}>
            {children}
          </label>
        </>
      )}
    </li>
  );
};

const selectionListItemPropTypes: WeakValidationMap<ListItemProps & { '...rest': unknown }> = {
  /** value to be added to styling classes  */
  active: PropTypes.bool,
  /** Aria-label to apply to the item */
  'aria-label': PropTypes.string,
  /** Content to render in SelectionItem */
  children: PropTypes.node,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Impacts the styling of the checkbox in addition to preventing selection */
  disabled: PropTypes.bool,
  /** Highlights the checkbox when defined (specific string doesn't matter) */
  error: PropTypes.string,
  /**
   * Function that takes a JS event as an argument. Called when the SelectionList or ListItem loses focus
   */

  onBlur: PropTypes.func,
  /** Upstream on click handler */
  onClick: PropTypes.func,
  /**
   * Function that takes a fake event as an argument. This event contains a target object with value and id properties. Called when the SelectionList or ListItem value changes
   */
  onChange: PropTypes.func,
  /**
   * Function that takes a JS event as an argument. Called when the SelectionList or ListItem receives focus
   */
  onFocus: PropTypes.func,
  /** Determines whether the item is selected. */
  selected: PropTypes.bool,
  /** A unique identifier for this item in its list. Equivalent to a checkbox element's value attribute. */
  uniqueKey: PropTypes.string.isRequired,
  /**
   * Sets the value attribute on the native `<input type="checkbox">`.
   * Note that ListItem treats value differently than other inputs - the value does not change depending on the
   * status of `selected`.
   */
  value: PropTypes.string,
  /**
   * Passthrough props
   */
  '...rest': PropTypes.any,
};
ListItem.propTypes = selectionListItemPropTypes;

export default forwardRefToProps(ListItem);
