import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDollarSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M9 0H7V3H9V0Z" fill={props.color} />
    <path d="M9 13H7V16H9V13Z" fill={props.color} />
    <path
      d="M7.39 14C6.79 13.94 6.18 13.7902 5.58 13.5504C4.97 13.3106 4.45 13.0008 4 12.6112L5.02 11.1823C5.48 11.5221 5.94 11.8018 6.41 12.0017C6.88 12.2115 7.38 12.3114 7.92 12.3114C8.56 12.3114 9.04 12.1715 9.35 11.9017C9.66 11.632 9.82 11.2423 9.82 10.7427C9.82 10.343 9.69 10.0033 9.43 9.73355C9.17 9.46378 8.84 9.22398 8.44 9.00416C8.04 8.78435 7.61 8.57452 7.16 8.37469C6.7 8.17485 6.27 7.93505 5.88 7.66528C5.48 7.3955 5.15 7.06578 4.89 6.6761C4.63 6.29642 4.5 5.81682 4.5 5.2373C4.5 4.38801 4.76 3.6786 5.28 3.10908C5.8 2.53955 6.51 2.14988 7.4 2H8.67C9.3 2.06994 9.84 2.2398 10.29 2.49958C10.74 2.75937 11.15 3.06911 11.52 3.41882L10.36 4.64779C9.99 4.32806 9.64 4.08826 9.3 3.92839C8.96 3.76853 8.85 3.6786 8.38 3.6786C7.84 3.6786 7.43 3.80849 7.13 4.04829C6.83 4.29808 6.68 4.65779 6.68 5.13739C6.68 5.50708 6.81 5.80683 7.07 6.04663C7.33 6.28643 7.66 6.50624 8.06 6.70608C8.46 6.90591 8.89 7.10575 9.34 7.30558C9.8 7.50541 10.23 7.74521 10.62 8.02498C11.02 8.30475 11.35 8.65445 11.61 9.05412C11.87 9.45379 12 9.97336 12 10.5828C12 11.4621 11.69 12.1615 11.22 12.781C10.69 13.4804 9.62 13.98 8.67 13.98H7.4L7.39 14Z"
      fill={props.color}
    />
  </svg>
);
SvgDollarSmall.displayName = 'SvgDollarSmall';
const DollarSmall = withForgeIconProps(SvgDollarSmall, {
  name: 'Dollar',
  usage: 'Use to indicate a dollar amount',
  related: 'money, cash, currency, payment',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default DollarSmall;
