import rawContents from "!!raw-loader!./CheckableUncontrolled.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function CheckableUncontrolled():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Checkable Uncontrolled" />;
}

export default CheckableUncontrolled;
