import { MutableRefObject } from 'react';
import ShowSmall from '@athena/forge-icons/dist/ShowSmall';
import HideSmall from '@athena/forge-icons/dist/HideSmall';
import { forgeClassHelper } from '../../utils/classes';

const classes = forgeClassHelper({ name: 'input' });

/** Show Hide Icon ("eyeball") Sub-component
 *
 * This component manages which version of the "eyeball" icon to show
 *
 * It is engineered to render the icon representing the ACTION to execute - NOT the current state
 * Effectively, the icon rendered will assume the opposite state of the text's presentation state
 *
 * E.g., if the password is hidden, the Open eyeball will render as "show" is the action to execute; if the password is shown, the Closed eyeball will render as "hide" is the action to execute
 *
 */

interface ShowHideComponentProps {
  showInput: boolean;
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
  isShowHideClicked: MutableRefObject<boolean>;
}

const ShowHideComponent = ({ showInput, setShowInput, isShowHideClicked }: ShowHideComponentProps): JSX.Element => {
  const action = showInput ? 'hide' : 'show';
  const iconToggle = (): void => {
    isShowHideClicked.current = true;
    setShowInput(!showInput);
  };
  return action === 'show' ? (
    <div {...classes({ element: 'show-hide-icon' })} onMouseDown={iconToggle}>
      <ShowSmall />
    </div>
  ) : (
    <div {...classes({ element: 'show-hide-icon' })} onMouseDown={iconToggle}>
      <HideSmall />
    </div>
  );
};

export default ShowHideComponent;
