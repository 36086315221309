import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgComposeSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M14.0018 6.92004V12C14.0018 12.7956 13.6858 13.5587 13.1232 14.1213C12.5606 14.6839 11.7975 15 11.0018 15H1.00183V2H9.2218"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8818 3.57349L12.4418 1.13342L13.3818 0.193481C13.5089 0.0694871 13.6793 0 13.8568 0C14.0343 0 14.2048 0.0694871 14.3318 0.193481L15.7919 1.65344C15.9159 1.78046 15.9852 1.95091 15.9852 2.12842C15.9852 2.30593 15.9159 2.47637 15.7919 2.60339L14.8818 3.57349Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.91191 10.5535L5.30192 11.0134C5.26096 11.0204 5.21883 11.0175 5.17924 11.0049C5.13965 10.9923 5.10376 10.9703 5.07438 10.9409C5.045 10.9115 5.02302 10.8757 5.01042 10.8361C4.99781 10.7965 4.99489 10.7543 5.00187 10.7134L5.46183 8.10339L7.91191 10.5535Z"
      fill={props.color}
    />
    <path
      d="M11.7462 1.65675L6.16611 7.23486L8.60525 9.67486L14.1853 4.09675L11.7462 1.65675Z"
      fill={props.color}
    />
  </svg>
);
SvgComposeSmall.displayName = 'SvgComposeSmall';
const ComposeSmall = withForgeIconProps(SvgComposeSmall, {
  name: 'Compose',
  usage: 'Use to create a longer message or document, like an email',
  related: 'write, note, mail, new',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ComposeSmall;
