import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgEditLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7479 7.18218L16.8179 2.25212L18.7079 0.372119C18.8374 0.248975 18.99 0.152745 19.157 0.0890384C19.3239 0.025332 19.5018 -0.00455554 19.6804 0.0010257C19.859 0.00660695 20.0347 0.0476252 20.1974 0.121631C20.36 0.195637 20.5064 0.30119 20.6279 0.432178L23.5679 3.37212C23.6989 3.49366 23.8044 3.64002 23.8784 3.80266C23.9524 3.9653 23.9934 4.14103 23.999 4.31963C24.0046 4.49823 23.9747 4.67612 23.911 4.84307C23.8473 5.01001 23.7511 5.16269 23.6279 5.29216L21.7479 7.18218Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.85794 23.0621L0.597927 23.9921C0.517323 24.0061 0.434602 24.0006 0.356594 23.976C0.278586 23.9513 0.207662 23.9081 0.149807 23.8502C0.0919522 23.7924 0.0487544 23.7214 0.0240745 23.6434C-0.000605414 23.5654 -0.00608841 23.4827 0.00796123 23.4021L0.937893 18.1421L5.85794 23.0621Z"
      fill={props.color}
    />
    <path
      d="M15.9241 3.2757L2.04578 17.1443L6.96558 22.0675L20.8439 8.19894L15.9241 3.2757Z"
      fill={props.color}
    />
  </svg>
);
SvgEditLarge.displayName = 'SvgEditLarge';
const EditLarge = withForgeIconProps(SvgEditLarge, {
  name: 'Edit',
  usage: 'Use to make changes to an item or information',
  related: 'update, compose, note, change',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default EditLarge;
