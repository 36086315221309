import { useContext, useRef, useState, useEffect, ReactElement } from 'react';
import './side-nav.scss';
import { Link } from 'gatsby';
import { PageContext } from '../../contexts';
import { MenuItem } from '../page-context-provider/page-context-provider';
import { componentImages } from '../page-context-provider/component-images';

export default function SideNav(): ReactElement {
  const { pageContext, childParentMap, menuItems } = useContext(PageContext);
  const [parentMenuItem, setParentMenuItem] = useState<MenuItem | undefined>();

  const navRef = useRef<HTMLElement>(null);
  const [height, setHeight] = useState<number>();
  const [activeItemRatio, setActiveItemRatio] = useState<number>(0);

  const updateDimensions = (): void => {
    setHeight(window.innerHeight);
  };

  // continuously sets the height to window on first render and subsequent resizes
  useEffect(() => {
    setHeight(window.innerHeight);
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // sets the active item whenever one of childParentMap, menuItems, pageContext changes
  useEffect(() => {
    const parentPageId = pageContext?.id && childParentMap && childParentMap[pageContext?.id];
    const parentMenuItem = menuItems?.find((menuItem: MenuItem) => menuItem.route.parameters.node === parentPageId);
    setParentMenuItem(parentMenuItem);
    parentMenuItem?.childrenMenuItems.forEach((menuItem: MenuItem, index: number) => {
      const activeLink = pageContext?.id?.toString() === menuItem.route.parameters.node;
      if (activeLink) {
        // items are in a certain order in childrenMenuItems
        // goes from 0.0 to 1, where the last item goes to 1.0 (47 / (48-1))
        setActiveItemRatio(index / (parentMenuItem?.childrenMenuItems.length - 1));
      }
    });
  }, [childParentMap, menuItems, pageContext]);

  // when a new item is selected, scrolls to the item
  useEffect(() => {
    const currentNav = navRef.current;
    if (currentNav && height !== undefined) {
      const scroll = activeItemRatio * (currentNav.scrollHeight - height);
      currentNav.scrollTo({ top: scroll });
    }
  }, [activeItemRatio, height]);

  return (
    <nav className="side-nav" ref={navRef}>
      <ul className="side-nav-list">
        {parentMenuItem ? (
          parentMenuItem.childrenMenuItems.map((menuItem: MenuItem, index: number) => {
            const activeLink = pageContext?.id?.toString() === menuItem.route.parameters.node;
            const link = menuItem.url.replace('/forge', '');
            const componentImage = Object.prototype.hasOwnProperty.call(componentImages, menuItem.title)
              ? componentImages[menuItem.title as keyof typeof componentImages]
              : componentImages.ContributedComponent;
            const isComponentPage = link.includes('/components/');
            return (
              <li key={index} className={`side-nav-list-item ${activeLink ? 'side-nav-list-item__active' : ''}`}>
                <Link to={link} className="side-nav-link">
                  {menuItem.title}
                  {isComponentPage && componentImages && <div className={'side-nav-image'}>{componentImage}</div>}
                </Link>
              </li>
            );
          })
        ) : (
          <></> // in bougie / high-end websites they would render these floating grey bars here 😎
        )}
      </ul>
    </nav>
  );
}
