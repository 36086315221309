import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInsertSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 1V0H0V1H1ZM15 1H16V0H15V1ZM15 12H16H15ZM1 15H0V16H1V15ZM8.71828 4.29407C8.32841 3.9029 7.69525 3.90184 7.30407 4.29171C6.9129 4.68159 6.91184 5.31475 7.30171 5.70593L8.71828 4.29407ZM11 8L11.7071 8.70711C12.0972 8.31704 12.0977 7.68479 11.7083 7.29407L11 8ZM7.29289 10.2929C6.90237 10.6834 6.90237 11.3166 7.29289 11.7071C7.68342 12.0976 8.31658 12.0976 8.70711 11.7071L7.29289 10.2929ZM1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM2 5.17993V1H0V5.17993H2ZM1 2H15V0H1V2ZM14 1V12H16V1H14ZM14 12C14 12.5305 13.7893 13.0392 13.4142 13.4142L14.8284 14.8284C15.5786 14.0783 16 13.0608 16 12L14 12ZM13.4142 13.4142C13.0392 13.7893 12.5305 14 12 14V16C13.0608 16 14.0783 15.5786 14.8284 14.8284L13.4142 13.4142ZM12 14H1V16H12V14ZM2 15V10.8201H0V15H2ZM7.30171 5.70593L10.2917 8.70593L11.7083 7.29407L8.71828 4.29407L7.30171 5.70593ZM10.2929 7.29289L7.29289 10.2929L8.70711 11.7071L11.7071 8.70711L10.2929 7.29289ZM1 9H11V7H1V9Z"
      fill={props.color}
    />
  </svg>
);
SvgInsertSmall.displayName = 'SvgInsertSmall';
const InsertSmall = withForgeIconProps(SvgInsertSmall, {
  name: 'Insert',
  usage: 'Use to insert a file or data',
  related: 'add',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default InsertSmall;
