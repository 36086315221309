import RawDataPreview from '../../components/raw-data-preview';

import rawContents from "!!raw-loader!./FormValidation.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { RawDataPreview };

function FormValidation():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Form Validation" />;
}

export default FormValidation;
