import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCartSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0h1.497c.819 0 1.53.479 1.84 1.174l.54 1.206H16l-2.026 5.483a2.004 2.004 0 0 1-1.889 1.293H5.47l-.515.863h9.422v1.979H4.871c-1.621 0-2.474-1.695-1.736-2.934l.598-1.004L2.22 3.72l-.78-1.741H0V0Zm4.63 4.359.983 2.818h6.413l1.042-2.818H4.63Z"
      fill={props.color}
    />
    <path
      d="M6.614 14.497c0 .83-.697 1.503-1.557 1.503S3.5 15.327 3.5 14.497c0-.83.697-1.503 1.557-1.503s1.557.673 1.557 1.503Zm7.267 0c0 .83-.697 1.503-1.557 1.503s-1.557-.673-1.557-1.503c0-.83.697-1.503 1.557-1.503s1.557.673 1.557 1.503Z"
      fill={props.color}
    />
  </svg>
);
SvgCartSmall.displayName = 'SvgCartSmall';
const CartSmall = withForgeIconProps(SvgCartSmall, {
  name: 'Cart',
  usage: 'Use to direct to check out',
  related: 'dollar, PaymentMethods',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default CartSmall;
