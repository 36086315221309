import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNotificationBellSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3 13.7232C3 13.7232 3.39563 12.6093 3.55556 11.8608C3.98457 9.85284 3.85015 6.55137 3.85015 6.55137C3.83228 5.10602 3.99511 3.71083 4.82787 2.67067C5.66062 1.63052 6.80103 1.02992 8 1C9.19897 1.02992 10.3394 1.63052 11.1721 2.67067C12.0049 3.71083 12.1493 5.10602 12.1314 6.55137C12.1314 6.55137 12.0154 9.85284 12.4444 11.8608C12.707 13.0895 13 13.7232 13 13.7232H3Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91304 13.9911C9.91304 14.5239 9.70692 15.0348 9.34 15.4116C8.97308 15.7883 8.47542 16 7.95652 16C7.43762 16 6.93996 15.7883 6.57304 15.4116C6.20612 15.0348 6 14.5239 6 13.9911V13.3214H9.91304V13.9911Z"
      fill={props.color}
    />
  </svg>
);
SvgNotificationBellSmall.displayName = 'SvgNotificationBellSmall';
const NotificationBellSmall = withForgeIconProps(SvgNotificationBellSmall, {
  name: 'NotificationBell',
  usage: 'Use to view notifications',
  related: 'bell, notify, new, notification',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default NotificationBellSmall;
