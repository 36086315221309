import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMedicationLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15.7148 15.716L8.23778 8.23898M2.60901 21.391C2.12293 20.9049 1.73308 20.4074 1.45751 19.8092C1.18195 19.211 1.02769 18.5642 1.00358 17.9061C0.979482 17.2479 1.086 16.5915 1.31708 15.9747C1.54815 15.358 1.89915 14.7931 2.3498 14.3129L14.3131 2.34955C14.7934 1.8989 15.3583 1.54791 15.975 1.31683C16.5917 1.08576 17.2482 0.979238 17.9063 1.00334C18.5645 1.02744 19.2114 1.1817 19.8095 1.45727C20.4077 1.73284 20.9453 2.12419 21.3914 2.60877C21.8759 3.05481 22.2674 3.5924 22.543 4.19058C22.8185 4.78877 22.9728 5.43576 22.9969 6.09393C23.021 6.75209 22.9144 7.40827 22.6833 8.02501C22.4522 8.64175 22.1012 9.20662 21.6506 9.68691L9.68727 21.6502C9.20698 22.1009 8.64224 22.4519 8.0255 22.6829C7.40876 22.914 6.75234 23.0208 6.09417 22.9967C5.436 22.9726 4.78914 22.8181 4.19095 22.5425C3.59276 22.2669 3.09509 21.8771 2.60901 21.391Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <circle cx={6.68994} cy={14.6912} r={1.25} fill={props.color} />
    <circle cx={9.68994} cy={16.6912} r={1.25} fill={props.color} />
    <circle cx={5.68994} cy={18.6912} r={1.25} fill={props.color} />
  </svg>
);
SvgMedicationLarge.displayName = 'SvgMedicationLarge';
const MedicationLarge = withForgeIconProps(SvgMedicationLarge, {
  name: 'Medication',
  usage: 'Use to indicate, view, or edit patient medications',
  related: 'pill, meds, prescription',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default MedicationLarge;
