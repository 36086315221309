import rawContents from "!!raw-loader!./SegmentedButtonControlledRadio.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function SegmentedButtonControlledRadio():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Segmented Button Controlled Radio" />;
}

export default SegmentedButtonControlledRadio;
