import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgModalPopupSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 1V0H4v1zm10 0h1V0h-1zM5 11H4v1h1zm9-.13a1 1 0 1 0-2 0zM13 12h1zM1 15H0v1h1zM1 3V2H0v1zm4 1a1 1 0 0 0 0-2zm7 0h1a1 1 0 0 0-1-1zM8 3a1 1 0 0 0 0 2zm3 5a1 1 0 1 0 2 0zm.707-2.293a1 1 0 0 0-1.414-1.414zM7.293 7.293a1 1 0 0 0 1.414 1.414zM5 2h10V0H5zm9-1v7h2V1zm0 7a2 2 0 0 1-.586 1.414l1.414 1.414A4 4 0 0 0 16 8zm-.586 1.414A2 2 0 0 1 12 10v2a4 4 0 0 0 2.828-1.172zM12 10H5v2h7zm-6 1V1H4v10zm6-.13V12h2v-1.13zM12 12a2 2 0 0 1-.586 1.414l1.414 1.414A4 4 0 0 0 14 12zm-.586 1.414A2 2 0 0 1 10 14v2a4 4 0 0 0 2.828-1.172zM10 14H1v2h9zm-8 1V3H0v12zM1 4h4V2H1zm7 1h4V3H8zm3-1v4h2V4zm-.707.293-3 3 1.414 1.414 3-3z"
      fill={props.color}
    />
  </svg>
);
SvgModalPopupSmall.displayName = 'SvgModalPopupSmall';
const ModalPopupSmall = withForgeIconProps(SvgModalPopupSmall, {
  name: 'ModalPopup',
  usage: 'Use to open a Modal',
  related: 'popover',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default ModalPopupSmall;
