import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClockSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.75 5C8.75 4.44772 8.30228 4 7.75 4C7.19772 4 6.75 4.44772 6.75 5H8.75ZM7.75 9H6.75C6.75 9.55228 7.19772 10 7.75 10V9ZM10.25 10C10.8023 10 11.25 9.55228 11.25 9C11.25 8.44772 10.8023 8 10.25 8V10ZM14 8C14 11.3137 11.3137 14 8 14V16C12.4183 16 16 12.4183 16 8H14ZM8 14C4.68629 14 2 11.3137 2 8H0C0 12.4183 3.58172 16 8 16V14ZM2 8C2 4.68629 4.68629 2 8 2V0C3.58172 0 0 3.58172 0 8H2ZM8 2C11.3137 2 14 4.68629 14 8H16C16 3.58172 12.4183 0 8 0V2ZM6.75 5V9H8.75V5H6.75ZM7.75 10H10.25V8H7.75V10Z"
      fill={props.color}
    />
  </svg>
);
SvgClockSmall.displayName = 'SvgClockSmall';
const ClockSmall = withForgeIconProps(SvgClockSmall, {
  name: 'Clock',
  usage:
    'Use to indicate when an event occurred, or to enter or edit time-related information',
  related: 'duration, time, timer, hour, minute, second,',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ClockSmall;
