import { ReactElement, useContext } from 'react';

import { MenuTriggerContext } from '@athena/forge';
import ExpandSmall from '@athena/forge-icons/dist/ExpandSmall';
import forgePackageJson from '@athena/forge/package.json';
import createBEMHelper from '../../bem-helper';
import { appendVersionString, forgeVersionType } from '../../versionCheckHelper';

const version = forgePackageJson.version;

const classes = createBEMHelper({ name: 'version-menu-trigger' });

interface VersionsMenuTriggerProps {
  isOpen: boolean;
}

const VersionsMenuTrigger = ({ isOpen }: VersionsMenuTriggerProps): ReactElement => {
  const { triggerProps } = useContext(MenuTriggerContext);
  const isLts = forgeVersionType() === 'lts';

  return (
    <div {...classes({ element: 'component', modifiers: { lts: isLts } })} {...triggerProps}>
      <div className="forge-version">
        Forge {appendVersionString()}
        {version.match(/\d*\.\d*\.\d*/g)}
      </div>
      <ExpandSmall
        {...classes({ element: 'icon', modifiers: { expanded: !!isOpen } })}
        title={isOpen ? 'Collapse' : 'Expand'}
        semanticColor={isLts ? 'brand-default' : 'inverted'}
      />
    </div>
  );
};

VersionsMenuTrigger.displayName = 'VersionsMenuTrigger';

export default VersionsMenuTrigger;
