import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSearchLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 23L7 17M23 10C23 14.9706 18.9706 19 14 19C9.02944 19 5 14.9706 5 10C5 5.02944 9.02944 1 14 1C18.9706 1 23 5.02944 23 10Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgSearchLarge.displayName = 'SvgSearchLarge';
const SearchLarge = withForgeIconProps(SvgSearchLarge, {
  name: 'Search',
  usage: 'Use to search for an item or information',
  related: 'magnifying glass, find, look, view',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default SearchLarge;
