import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLinkSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.44855 12.1822L4.94827 12.6925C4.49626 13.0842 3.9085 13.283 3.31152 13.2455C2.71454 13.2081 2.2668 13.0026 1.7566 12.4924C1.2464 11.9822 1.04192 11.5379 1.00448 10.9425C0.967038 10.3472 1.16539 9.76114 1.55654 9.31078L5.55871 5.30861C5.80774 5.06532 6.11585 4.89119 6.45275 4.80345C6.78966 4.71571 7.14362 4.71741 7.47968 4.80834C7.96189 4.91444 8.40318 5.15761 8.75039 5.50867C9.02873 5.79211 9.24061 6.13386 9.37072 6.50921C9.512 6.91036 9.54646 7.34137 9.47075 7.75989M10.5515 3.81779L11.0517 3.30751C11.5037 2.91575 12.0915 2.71704 12.6885 2.75447C13.2855 2.7919 13.7532 3.01738 14.2434 3.50757C14.7336 3.99775 14.9581 4.46214 14.9955 5.05748C15.033 5.65281 14.8346 6.23886 14.4435 6.68922L10.4413 10.6914C10.1923 10.9347 9.88415 11.1088 9.54725 11.1966C9.21034 11.2843 8.85638 11.2826 8.52032 11.1917C8.03811 11.0856 7.59682 10.8424 7.24961 10.4913C6.97127 10.2079 6.75939 9.86614 6.62928 9.49079C6.51333 9.17069 6.46561 8.82988 6.48919 8.49025"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgLinkSmall.displayName = 'SvgLinkSmall';
const LinkSmall = withForgeIconProps(SvgLinkSmall, {
  name: 'Link',
  usage: 'Use to insert or indicate a link',
  related: 'hyperlink',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default LinkSmall;
