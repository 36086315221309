import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHideSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.76901 3.47613C4.01487 3.71205 3.33096 4.02713 2.7311 4.40194C1.3764 5.24839 0.380422 6.44987 0.0923405 7.78981L0.0471497 8L0.0923405 8.21019C0.380422 9.55013 1.3764 10.7516 2.7311 11.5981C4.10291 12.4552 5.91434 13 8 13C9.96813 13 11.6921 12.5149 13.0328 11.7399L11.5519 10.259C10.5998 10.7069 9.39747 11 8 11C6.25833 11 4.81976 10.5448 3.79089 9.90194C2.85375 9.31638 2.31044 8.61446 2.10547 8C2.31044 7.38554 2.85375 6.68362 3.79089 6.09806C4.4904 5.661 5.37928 5.31067 6.42405 5.13117L4.76901 3.47613ZM12.45 9.74294C13.2403 9.19211 13.7078 8.55976 13.8945 8C13.6896 7.38554 13.1462 6.68361 12.2091 6.09806C11.1802 5.4552 9.74168 5 8 5C7.90282 5 7.80658 5.00142 7.7113 5.00421L5.90062 3.19352C6.56137 3.06792 7.26359 3 8 3C10.0857 3 11.8971 3.5448 13.2689 4.40194C14.6236 5.24839 15.6196 6.44987 15.9077 7.7898L15.9529 8L15.9077 8.2102C15.6649 9.33935 14.9194 10.3702 13.8802 11.1731L12.45 9.74294Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.63028 5.33739C5.67936 5.83752 5.03 6.84216 5.03 8C5.03 9.65685 6.35971 11 8 11C9.15689 11 10.1593 10.3319 10.6496 9.35674L6.63028 5.33739ZM10.9596 8.25252C10.9665 8.16927 10.97 8.08505 10.97 8C10.97 6.34315 9.64029 5 8 5C7.90566 5 7.81234 5.00444 7.72024 5.01313L10.9596 8.25252Z"
      fill={props.color}
    />
    <path
      d="M1 1L15 15"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgHideSmall.displayName = 'SvgHideSmall';
const HideSmall = withForgeIconProps(SvgHideSmall, {
  name: 'Hide',
  usage: 'Use to indicate an item is hidden, or to unhide an item',
  related: 'eye, secret, private',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default HideSmall;
