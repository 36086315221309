import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgListNumberedSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15 3.5H6M15 12.5H6M15 8H6"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M0.265991 5.71803V4.75203H1.21799V2.45603H0.377991V1.71403C0.629991 1.6627 0.844658 1.60436 1.02199 1.53903C1.19932 1.4737 1.37199 1.38736 1.53999 1.28003H2.42199V4.75203H3.21999V5.71803H0.265991Z"
      fill={props.color}
    />
    <path
      d="M0.056 14.762V14.076C0.317333 13.8333 0.56 13.6047 0.784 13.39C1.01267 13.1753 1.211 12.9747 1.379 12.788C1.547 12.5967 1.67767 12.417 1.771 12.249C1.869 12.0763 1.918 11.9153 1.918 11.766C1.918 11.5653 1.86667 11.4183 1.764 11.325C1.666 11.227 1.53533 11.178 1.372 11.178C1.22267 11.178 1.08967 11.2223 0.973 11.311C0.861 11.395 0.751333 11.4907 0.644 11.598L0 10.954C0.116667 10.8327 0.231 10.7277 0.343 10.639C0.455 10.5457 0.571667 10.471 0.693 10.415C0.819 10.3543 0.949667 10.31 1.085 10.282C1.225 10.254 1.37667 10.24 1.54 10.24C1.764 10.24 1.96933 10.275 2.156 10.345C2.34267 10.415 2.50367 10.513 2.639 10.639C2.77433 10.765 2.87933 10.919 2.954 11.101C3.02867 11.2783 3.066 11.4767 3.066 11.696C3.066 11.8733 3.02633 12.0553 2.947 12.242C2.87233 12.424 2.77433 12.606 2.653 12.788C2.53167 12.97 2.394 13.1473 2.24 13.32C2.09067 13.4927 1.94133 13.656 1.792 13.81C1.904 13.796 2.03 13.7843 2.17 13.775C2.31467 13.761 2.436 13.754 2.534 13.754H3.276V14.762H0.056Z"
      fill={props.color}
    />
  </svg>
);
SvgListNumberedSmall.displayName = 'SvgListNumberedSmall';
const ListNumberedSmall = withForgeIconProps(SvgListNumberedSmall, {
  name: 'ListNumbered',
  usage: 'Use in a Rich Text Editor to create a numbered list',
  related: 'list, bulleted list',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ListNumberedSmall;
