import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFlagLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3 13H21L17.72 7.05005L21 1H6C5.20435 1 4.44127 1.31605 3.87866 1.87866C3.31605 2.44127 3 3.20435 3 4V13ZM3 13V23"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgFlagLarge.displayName = 'SvgFlagLarge';
const FlagLarge = withForgeIconProps(SvgFlagLarge, {
  name: 'Flag',
  usage: 'Use to mark information for follow up',
  related: 'attention, pin, flagged',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default FlagLarge;
