import rawContents from "!!raw-loader!./HighVolumeMultiSelectCollapsible.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function HighVolumeMultiSelectCollapsible():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="High Volume Multi Select Collapsible" />;
}

export default HighVolumeMultiSelectCollapsible;
