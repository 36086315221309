import { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/layout';
import parse from 'html-react-parser';
import { pageOptions } from '../html-parsing-options';
import { GatsbyPageContext, PageData } from '../contexts';

export default function Home({ data, pageContext }: PageProps<PageData, GatsbyPageContext>): ReactElement {
  const bodyHTML = data.nodePage?.body?.value || '';
  return (
    <Layout pageContext={pageContext} title={data.nodePage?.title || ''} disableSideNav disableFooter>
      {bodyHTML !== '' && <div className="fg_markdown cms-content">{parse(bodyHTML, pageOptions())}</div>}
    </Layout>
  );
}

export const query = graphql`
  {
    nodePage(path: { alias: { eq: "/home" } }) {
      title
      body {
        value
      }
    }
  }
`;
