import { useEffect } from 'react';
import { DataTableProps } from '../DataTable';
import { DataTableDispatch } from '../store/stateTypes';

type QuickFilterPropReceiverProps<RowData> = {
  dispatch: DataTableDispatch<RowData>;
  quickFilter: DataTableProps<RowData>['quickFilter'];
};

export function QuickFilterPropReceiver<RowData>({
  dispatch, // prettier-break-line
  quickFilter,
}: QuickFilterPropReceiverProps<RowData>): JSX.Element {
  // check quick filter option ids
  if (typeof quickFilter !== 'undefined') {
    const optionSet = new Set<string>();
    for (const { id } of quickFilter.options) {
      if (optionSet.has(id)) {
        throw new Error(`Quick filter option id should be unique, but '${id}' is duplicated.`);
      }
      optionSet.add(id);
    }
  }

  useEffect(() => {
    dispatch(function update_quick_filter(s) {
      s.quickFilter = { ...s.quickFilter, ...quickFilter };
    });
  }, [dispatch, quickFilter]);

  return <></>;
}
