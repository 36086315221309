import React, { WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper('inline-message');

export interface InlineMessageProps {
  /** DOM ID of the message */
  id?: string;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Adds disabled styling to the message */
  disabled?: boolean;
  /** The message display text */
  text?: string;
}

const InlineMessage = ({ className, text, disabled = false, ...props }: InlineMessageProps): React.ReactElement => {
  return (
    <span
      {...props}
      {...classes({
        states: { disabled },
        extra: className,
      })}
    >
      {text}
    </span>
  );
};

export const inlineMessagePropTypes: WeakValidationMap<InlineMessageProps> = {
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Adds disabled styling to the message */
  disabled: PropTypes.bool,
  /** The message display text */
  text: PropTypes.string,
};

InlineMessage.propTypes = inlineMessagePropTypes;

export default InlineMessage;
