import { createContext } from 'react';

export type ListOnSelect = (uniqueKey: string, selected: boolean) => void;
export type ListValueState = Record<string, boolean>;
interface ListContextProps {
  disabled: boolean;
  error?: string;
  id?: string;
  layout?: 'compact' | 'medium';
  listValue: ListValueState;
  onSelect: ListOnSelect;
  padded?: boolean;
  showDividers?: boolean;
  variant?: 'simple' | 'selection';
}

const defaultValue: ListContextProps = {
  listValue: {},
  disabled: false,
  onSelect: () => {
    return;
  },
};

// Create context with default values
export const ListContext = createContext<ListContextProps>(defaultValue);
