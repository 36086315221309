import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFoodSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 1.00049C15 0.448204 14.5523 0.000488281 14 0.000488281C13.2945 0.000488281 12.6939 0.450726 12.2787 0.881475C11.8221 1.35513 11.3937 2.00588 11.0364 2.78258C10.3187 4.34292 9.83937 6.51548 10.0499 9.08225C10.0925 9.60109 10.526 10.0005 11.0466 10.0005H13V15.0005C13 15.5528 13.4477 16.0005 14 16.0005C14.5523 16.0005 15 15.5528 15 15.0005V1.00049Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0.000488281C2.55228 0.000488281 3 0.448204 3 1.00049V4.00049H3.5V1.00049C3.5 0.448204 3.94772 0.000488281 4.5 0.000488281C5.05228 0.000488281 5.5 0.448204 5.5 1.00049V4.00049H6V1.00049C6 0.448204 6.44772 0.000488281 7 0.000488281C7.55228 0.000488281 8 0.448204 8 1.00049V4.00049C8 5.48699 6.91886 6.72097 5.5 6.95901V15.0005C5.5 15.5528 5.05228 16.0005 4.5 16.0005C3.94772 16.0005 3.5 15.5528 3.5 15.0005V6.95901C2.08114 6.72097 1 5.48699 1 4.00049V1.00049C1 0.448204 1.44772 0.000488281 2 0.000488281Z"
      fill={props.color}
    />
  </svg>
);
SvgFoodSmall.displayName = 'SvgFoodSmall';
const FoodSmall = withForgeIconProps(SvgFoodSmall, {
  name: 'Food',
  usage:
    'Use to indicate food, dining/restaurants, access to food, food stability, or other related issues',
  related: 'fork, knife, eat, kitchen, dining',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default FoodSmall;
