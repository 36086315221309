import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgReceiptLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4 8.63989V1L6.67 2.52002L9.33 1L12 2.52002L14.67 1L17.33 2.52002L20 1V8.13989"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3 11.76C12.7477 11.76 12.3 12.2077 12.3 12.76C12.3 13.3123 12.7477 13.76 13.3 13.76V11.76ZM15.89 13.76C16.4423 13.76 16.89 13.3123 16.89 12.76C16.89 12.2077 16.4423 11.76 15.89 11.76V13.76ZM8.10999 8.68994C7.5577 8.68994 7.10999 9.13766 7.10999 9.68994C7.10999 10.2422 7.5577 10.6899 8.10999 10.6899V8.68994ZM15.89 10.6899C16.4423 10.6899 16.89 10.2422 16.89 9.68994C16.89 9.13766 16.4423 8.68994 15.89 8.68994V10.6899ZM8.10999 5.62012C7.5577 5.62012 7.10999 6.06783 7.10999 6.62012C7.10999 7.1724 7.5577 7.62012 8.10999 7.62012V5.62012ZM15.89 7.62012C16.4423 7.62012 16.89 7.1724 16.89 6.62012C16.89 6.06783 16.4423 5.62012 15.89 5.62012V7.62012ZM21 8.14014C21 7.58785 20.5523 7.14014 20 7.14014C19.4477 7.14014 19 7.58785 19 8.14014H21ZM4 23H3V24H4V23ZM5 8.64014C5 8.08785 4.55228 7.64014 4 7.64014C3.44772 7.64014 3 8.08785 3 8.64014H5ZM13.3 13.76H15.89V11.76H13.3V13.76ZM8.10999 10.6899H15.89V8.68994H8.10999V10.6899ZM8.10999 7.62012H15.89V5.62012H8.10999V7.62012ZM19 8.14014V20H21V8.14014H19ZM19 20C19 20.5304 18.7893 21.0389 18.4142 21.414L19.8284 22.8282C20.5785 22.0781 21 21.0609 21 20H19ZM18.4142 21.414C18.039 21.7892 17.5302 22 17 22V24C18.0611 24 19.0784 23.5782 19.8284 22.8282L18.4142 21.414ZM17 22H4V24H17V22ZM5 23V8.64014H3V23H5Z"
      fill={props.color}
    />
    <path
      d="M12.0799 18C12.2499 18 12.78 18.08 12.87 18C13.0478 17.9709 13.2095 17.8796 13.3263 17.7422C13.443 17.6048 13.507 17.4303 13.507 17.25C13.507 17.0698 13.443 16.8952 13.3263 16.7578C13.2095 16.6204 13.0478 16.5292 12.87 16.5H12.62C12.53 16.5 12.4099 16.59 12.3599 16.5C11.7999 16.01 11.1199 16.5802 10.9599 17.1402H10.8199C10.7825 16.7391 10.7157 16.3414 10.62 15.9502C10.27 14.4902 9.61999 13.6201 8.90999 13.6201C8.65391 13.6185 8.40687 13.7151 8.21993 13.8902C7.99272 14.1004 7.85641 14.3911 7.83993 14.7002C7.78632 15.7565 8.05864 16.8038 8.61996 17.7002C8.18783 17.8833 7.83385 18.2122 7.61996 18.6299C7.46912 18.9706 7.44785 19.3547 7.56002 19.71C7.61149 19.9907 7.75971 20.2446 7.97896 20.4273C8.19822 20.61 8.47457 20.71 8.75997 20.71C8.8497 20.7198 8.94026 20.7198 9.02999 20.71C9.48894 20.6158 9.90579 20.3774 10.2199 20.0298L10.3299 19.8799C10.5861 19.5106 10.7508 19.0857 10.81 18.6402H10.9399C11.0897 18.6298 11.2402 18.6298 11.39 18.6402C11.93 18.6402 12.09 18.1902 12.15 17.8902L12.0799 18ZM9.34994 17.0899C9.34994 17.0899 8.58 15.8401 8.92 15.3101C9.43 15.7601 9.34994 17.1099 9.34994 17.1099V17.0899ZM8.65999 19.77C7.65999 19.28 9.16993 18.66 9.46993 18.77C9.99993 19.05 9.10999 20.0001 8.65999 19.7901V19.77Z"
      fill={props.color}
    />
  </svg>
);
SvgReceiptLarge.displayName = 'SvgReceiptLarge';
const ReceiptLarge = withForgeIconProps(SvgReceiptLarge, {
  name: 'Receipt',
  usage: 'Use to indicate or view a receipt',
  related: 'payment, paid, invoice',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default ReceiptLarge;
