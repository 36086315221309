import rawContents from "!!raw-loader!./HiddenOnDesktop.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function HiddenOnDesktop():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Hidden On Desktop" />;
}

export default HiddenOnDesktop;
