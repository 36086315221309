import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgEmergencySmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#F0F0F0"
    />
    <path
      d="M12 8H4M8 4V12"
      stroke="#CA0D0D"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgEmergencySmall.displayName = 'SvgEmergencySmall';
const EmergencySmall = withForgeIconProps(SvgEmergencySmall, {
  name: 'Emergency',
  usage:
    'Use to indicate emergency information, like an urgent care location or an emergency contact',
  related: 'alert, 911',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default EmergencySmall;
