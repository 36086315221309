import SegmentedButtonBasic from './SegmentedButtonBasic.gen';
import SegmentedButtonSeparated from './SegmentedButtonSeparated.gen';
import SegmentedButtonSplitPrimary from './SegmentedButtonSplitPrimary.gen';
import SegmentedButtonIconsInAnchorElement from './SegmentedButtonIconsInAnchorElement.gen';
import SegmentedButtonControlledRadio from './SegmentedButtonControlledRadio.gen';
import SegmentedButtonDifferentBehaviors from './SegmentedButtonDifferentBehaviors.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <SegmentedButtonBasic />
      <SegmentedButtonSeparated />
      <SegmentedButtonSplitPrimary />
      <SegmentedButtonIconsInAnchorElement />
      <SegmentedButtonControlledRadio />
      <SegmentedButtonDifferentBehaviors />
    </>
  );
}
