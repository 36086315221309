import { useState, useEffect } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { callMsGraph } from '../msGraph';
import { loginRequest } from './../authConfig';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';

export type ProfileData = {
  displayName: string;
  givenName: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  officeLocation: string;
  surname: string;
  userPrincipalName: string;
};

export default function useProfile(): ProfileData | undefined {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [profileData, setProfileData] = useState<ProfileData>();
  const authenticationEnabled = process.env.GATSBY_FEATURE_FLAG_AUTHENTICATION === 'true';

  useEffect(() => {
    if (authenticationEnabled) {
      if (!account) {
        setProfileData(undefined);
      } else if (!profileData && inProgress === InteractionStatus.None) {
        (async () => {
          const request = {
            ...loginRequest,
            account: account,
          };
          try {
            const tokenResponse = await instance.acquireTokenSilent(request);
            const profileData = await callMsGraph<ProfileData>(tokenResponse.accessToken, '/me');
            setProfileData(profileData);
          } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(request);
            }
          }
        })();
      }
    }
  }, [account, authenticationEnabled, inProgress, instance, profileData]);

  return profileData;
}
