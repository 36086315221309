import { ReactNode } from 'react';

/** Represents a file managed by FileUpload
 *
 * Many of these properties are optional, as they are only used in specific
 * stages of the upload process. Some properties, like errorMessage or isCompleted,
 * get set or cleared when changing states. Others, like percentComplete, default
 * to 'indeterminate', but can be set to a number by the application.
 */
export interface FileUploadFile {
  /** Additional data to display next to the file, either when in error, or when uploaded */
  metaData?: ReactNode;
  /** Error message to display if the file failed to upload */
  errorMessage?: ReactNode;
  /** Flags a file being successfully uploaded */
  isCompleted?: boolean;
  /** Unique ID for the file. Needed in case multiple files are uploaded with
   * the same name. */
  id: string;
  /** File object used to download the file */
  fileData: File;
  /** When in progress, what percent complete to fill the progress indicator
   *
   * If `undefined`, or `'indeterminate'`, the progress indicator will be indeterminate
   */
  percentComplete?: number | 'indeterminate';
  /** When complete, the URL of the uploaded file. Creates a blob URL if not provided */
  url?: string;
}

/** Replaces the current state
 *
 * Recommended only when tight control over the behavior of FileUpload is required.
 */
export interface FileUploadActionSetState {
  type: 'set-state';
  state: FileUploadState;
}
/** Adds files to the pending list */
export interface FileUploadActionPending {
  type: 'pending';
  affectedFiles: ReadonlyArray<FileUploadFile>;
}
/** Removes a file from the pending list */
export interface FileUploadActionRemovePending {
  type: 'remove-pending';
  affectedFile: FileUploadFile;
}
/** Starts a file upload, removing it from the pending list
 *
 * Each file in affectedFiles can optionally have `percentComplete` set to a
 * number. Otherwise, it will default to 'indeterminate'.
 */
export interface FileUploadActionStart {
  type: 'start';
  affectedFiles: ReadonlyArray<FileUploadFile>;
}
/** Updates the progress of a file in the pending list
 *
 * Update `percentComplete` within affectedFiles to update the progress.
 */
export interface FileUploadActionUpdateProgress {
  type: 'update-progress';
  affectedFiles: ReadonlyArray<FileUploadFile>;
}
/** Marks files in the in-progress list as having an error
 *
 * Modify `errorMessage` within affectedFiles to update the error message
 */
export interface FileUploadActionError {
  type: 'error';
  affectedFiles: ReadonlyArray<FileUploadFile>;
}
/** Retries uploading a file. File may either be in the error or completed lists
 *
 * Like the `start` action, `affectedFile.percentComplete` can be set to a number.
 */
export interface FileUploadActionRetry {
  type: 'retry';
  affectedFile: FileUploadFile;
}
/** Marks a file as uploaded
 *
 * Optionally, `metaData` can be set on each file to display next to the file.
 * This may include information such as date/time uploaded, upload-by, etc.
 *
 * It's also recommended to set `url` so that a direct hyperlink can be created
 * to the completed file. Otherwise, a blob URL will be created.
 */
export interface FileUploadActionCompleted {
  type: 'completed';
  affectedFiles: ReadonlyArray<FileUploadFile>;
}
/** Marks a file as deleted, or removes an errored file from the error list.
 *
 * Optionally, `errorMessage` can be set on each file to display next to the file.
 */
export interface FileUploadActionDelete {
  type: 'delete';
  affectedFile: FileUploadFile;
}
/** Undoes a file deletion, moving it back to the completed list
 *
 * If your application has hard-deleted the file and needs to re-upload it,
 * return a `retry` event from the `onChange` handler instead.
 */
export interface FileUploadActionUndoDelete {
  type: 'undo-delete';
  affectedFile: FileUploadFile;
}
export type FileUploadAction =
  | FileUploadActionSetState
  | FileUploadActionPending
  | FileUploadActionRemovePending
  | FileUploadActionStart
  | FileUploadActionUpdateProgress
  | FileUploadActionError
  | FileUploadActionRetry
  | FileUploadActionCompleted
  | FileUploadActionDelete
  | FileUploadActionUndoDelete;

export interface FileUploadState {
  pending: ReadonlyArray<FileUploadFile>;
  incomplete: ReadonlyArray<FileUploadFile>;
  uploaded: ReadonlyArray<FileUploadFile>;
}

export const fileUploadFilesDefaultValue: FileUploadState = {
  pending: [],
  incomplete: [],
  uploaded: [],
};

/**
 * Reduces the pending file upload state based on the given action.
 */
export function fileUploadPendingReducer(
  state: FileUploadState['pending'],
  action: FileUploadAction
): FileUploadState['pending'] {
  switch (action.type) {
    case 'pending':
      // Add to pending list
      return state.concat(action.affectedFiles);
    case 'start':
      // Remove from pending list
      return state.filter((f) => !action.affectedFiles.some((affectedFile) => affectedFile.id === f.id));
    case 'remove-pending':
      // Remove from pending list
      return state.filter((f) => f.id !== action.affectedFile.id);
    default:
      return state;
  }
}

/**
 * Reduces the file upload in progress state based on the given action.
 */
export function fileUploadIncompleteReducer(
  state: FileUploadState['incomplete'],
  action: FileUploadAction
): FileUploadState['incomplete'] {
  switch (action.type) {
    case 'start':
      // Add to in progress list, ensuring there is no error message
      return state.concat(
        action.affectedFiles.map<FileUploadFile>((f) => ({
          percentComplete: 'indeterminate',
          ...f,
          errorMessage: null,
        }))
      );
    case 'update-progress':
      // Find affected files and update in place
      return state.map((f) => {
        const affectedFile = action.affectedFiles.find((affectedFile) => affectedFile.id === f.id);
        /** Since `update-progress` is dispatched directly from the application,
         * errorMessage may not have been cleared. */
        return affectedFile ? { ...affectedFile, errorMessage: null } : f;
      });
    case 'delete':
      // Delete from the in progress list
      return state.filter((f) => f.id !== action.affectedFile.id);
    case 'retry': {
      // Unconditionally clear the error message
      const fileInProgress: FileUploadFile = {
        percentComplete: 'indeterminate',
        ...action.affectedFile,
        errorMessage: null,
      };
      const index = state.findIndex((f) => f.id === fileInProgress.id);
      if (index >= 0) {
        // Error and uploading states both happen in the same list. Update in place.
        return state.map((f) => (fileInProgress.id === f.id ? fileInProgress : f));
      } else {
        // Retries can also come from completed, then deleted files. Add to in progress list
        return state.concat(fileInProgress);
      }
    }
    case 'error':
      // Error and uploading states both happen in the same list. Update in place.
      return state.map((f) => {
        const affectedFile = action.affectedFiles.find((affectedFile) => affectedFile.id === f.id);
        // Set a default error message that can be overridden if defined in affectedFile.
        return affectedFile ? { errorMessage: 'Could not be uploaded at this time', ...affectedFile } : f;
      });
    case 'completed':
      // Remove from in progress list
      return state.filter((f) => !action.affectedFiles.some((affectedFile) => affectedFile.id === f.id));
  }
  return state;
}

export function fileUploadUploadedReducer(
  state: FileUploadState['uploaded'],
  action: FileUploadAction
): FileUploadState['uploaded'] {
  switch (action.type) {
    case 'completed': {
      // Add to uploaded list
      const completedFiles = action.affectedFiles.map((f) => ({
        url: URL.createObjectURL(f.fileData),
        ...f,
        isCompleted: true,
      }));
      return state.concat(completedFiles);
    }
    case 'retry':
      // Remove from uploaded list
      return state.filter((f) => action.affectedFile.id !== f.id);
    case 'delete':
    case 'undo-delete': {
      const affectedFile = { ...action.affectedFile, isCompleted: action.type === 'undo-delete' };
      /** Delete and undo-delete files are co-located in the UI.
       * When switching between these states, update the file in place. */
      return state.map((f) => (f.id === affectedFile.id ? affectedFile : f));
    }
  }
  return state;
}

export default function fileUploadReducer(state: FileUploadState, action: FileUploadAction): FileUploadState {
  switch (action.type) {
    case 'set-state':
      return action.state;
    case undefined:
      return state;
    default:
      return {
        pending: fileUploadPendingReducer(state.pending, action),
        incomplete: fileUploadIncompleteReducer(state.incomplete, action),
        uploaded: fileUploadUploadedReducer(state.uploaded, action),
      };
  }
}
