import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAddToDxSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.7176 0.293355C12.3273 -0.0974241 11.6941 -0.0978376 11.3034 0.292432C10.9126 0.682701 10.9122 1.31587 11.3024 1.70664L12.7176 0.293355ZM15 3.99391L15.7064 4.70173C15.8942 4.51434 15.9998 4.26002 16 3.99474C16.0002 3.72946 15.895 3.47497 15.7076 3.28726L15 3.99391ZM11.2936 6.27999C10.9027 6.67012 10.902 7.30329 11.2922 7.69421C11.6823 8.08513 12.3155 8.08577 12.7064 7.69564L11.2936 6.27999ZM1 2.99391C0.447715 2.99391 0 3.44162 0 3.99391C0 4.54619 0.447715 4.99391 1 4.99391V2.99391ZM11.3024 1.70664L14.2924 4.70055L15.7076 3.28726L12.7176 0.293355L11.3024 1.70664ZM14.2936 3.28608L11.2936 6.27999L12.7064 7.69564L15.7064 4.70173L14.2936 3.28608ZM1 4.99391H15V2.99391H1V4.99391Z"
      fill={props.color}
    />
    <path
      d="M1.25 15.9695V6.98781H3.72998C4.9414 6.91611 6.13403 7.3128 7.06006 8.09554C7.48053 8.53953 7.80352 9.06623 8.00842 9.64189C8.21333 10.2176 8.29559 10.8296 8.25 11.4388C8.2668 12.1172 8.16175 12.7932 7.93994 13.4347C7.76362 13.9633 7.46688 14.4439 7.073 14.8387C6.67912 15.2335 6.19895 15.5318 5.67004 15.7101C5.07416 15.91 4.44856 16.0079 3.81995 15.9995L1.25 15.9695ZM2.90002 14.6822H3.62C4.01811 14.7069 4.4171 14.6496 4.79211 14.514C5.16713 14.3784 5.51022 14.1672 5.80005 13.8937C6.36227 13.2044 6.63472 12.3244 6.56006 11.4388C6.5971 11.007 6.54805 10.5722 6.41589 10.1594C6.28374 9.7466 6.07108 9.36398 5.79004 9.03363C5.49608 8.77102 5.153 8.56886 4.78064 8.43871C4.40828 8.30857 4.01391 8.25298 3.62 8.27517H2.90002V14.6822Z"
      fill={props.color}
    />
    <path
      d="M8.75 15.9696L10.8199 12.3269L8.88 8.98376H10.52L11.25 10.3111C11.35 10.5007 11.45 10.6903 11.55 10.8998L11.85 11.4887H11.9C11.98 11.2991 12.07 11.0994 12.15 10.8998L12.41 10.3111L13 8.98376H14.5699L12.65 12.5166L14.72 15.9696H13.08L12.28 14.5924C12.18 14.3828 12.07 14.1732 11.96 13.9636C11.85 13.7541 11.74 13.5545 11.62 13.3549H11.5699L11.28 13.9536L11 14.5924L10.3101 15.9696H8.75Z"
      fill={props.color}
    />
  </svg>
);
SvgAddToDxSmall.displayName = 'SvgAddToDxSmall';
const AddToDxSmall = withForgeIconProps(SvgAddToDxSmall, {
  name: 'AddToDx',
  usage: 'Use to add a problem to a diagnosis',
  related: 'medical, issue, data',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default AddToDxSmall;
