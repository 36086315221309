import Input from '../../../Input';
import { createSortByNumber } from '../../sorts/createSortByNumber';
import { validate_row_data } from '../../store/actions/validate_row_data';
import { classes } from '../../utils/dataTableClasses';
import { DataTableColumn, CreateColumnCommonArgs } from '../../utils/internalTypes';

export type CreateNumericColumn<RowData> = typeof createNumericColumn<RowData>;

export function createNumericColumn<RowData>({
  columnId,
  tooltip,
  headerText,
  footer,
  cellValidation = () => '',
  determineCellAlert = () => '',
  determineCellTooltip,
  displayCellText,
  rowDataFieldName,
  enableClientSideSorting = false,
  enableClientSideFullTextSearch = true,
  enableClientSideCsvDownload = true,
  isConfigurable = true,
  defaultHideColumn = false,
}: CreateColumnCommonArgs<RowData>): DataTableColumn<RowData> {
  const headerTextArray = typeof headerText === 'string' ? [headerText] : headerText;

  if (typeof displayCellText === 'undefined') {
    displayCellText = (row) => {
      const cellValue = row[rowDataFieldName];

      return typeof cellValue === 'number' ? String(cellValue) : '';
    };
  }

  return {
    columnId,
    configOptionName: isConfigurable ? headerTextArray.join(' ') : '',
    defaultHideColumn,

    // ================================
    // Header
    // ================================
    headerAlignRight: true,
    headerTooltip: tooltip,
    Header: () => (
      <>
        {headerTextArray.map((txt, idx) => (
          <div key={idx}>{txt}</div>
        ))}
      </>
    ),

    // ================================
    // Footer
    // ================================
    Footer: footer,

    // ================================
    // Cell
    // ================================
    cellStyle: () => ({ justifyContent: 'flex-end', textAlign: 'right' }),
    Cell: ({ useSelector, rowId }) => {
      const rowData = useSelector((s) => s.rows[rowId].data);

      const cellText = displayCellText ? displayCellText(rowData) : '';
      return <>{cellText === '' ? '---' : cellText}</>;
    },

    // ================================
    // EditCell
    // ================================
    EditCell: ({ useDispatch, useSelector, rowId }) => {
      const dispatch = useDispatch();
      const cellError = useSelector((s) => s.rows[rowId].cellErrors[columnId]);
      const originalValue = useSelector((s) => s.rows[rowId].originalData[rowDataFieldName]);
      const value = useSelector((s) => s.rows[rowId].data[rowDataFieldName]);
      if (typeof value !== 'number' && value !== null && value !== '') {
        throw new Error(`The value (${value}) should be a number, empty, or null.`);
      }

      return (
        <Input
          {...classes({ element: 'td__input', modifiers: { unsaved: value !== originalValue } })}
          type="number"
          value={typeof value === 'number' ? value : ''}
          error={cellError}
          onBlur={() => dispatch(validate_row_data(rowId))}
          onChange={(e) => {
            dispatch(function update_numeric_cell(s) {
              const value = e.target.value;
              const isEmpty = e.target.value === '';
              // @ts-expect-error The field type has already been verified in runtime
              s.rows[rowId].data[rowDataFieldName] = isEmpty ? null : Number(value);
            });
          }}
        />
      );
    },

    // ================================
    // Cell Alert
    // ================================
    determineCellAlert,

    // ================================
    // Cell Tooltip
    // ================================
    determineCellTooltip,

    // ================================
    // Data Validator
    // ================================
    dataValidator: (rowData) => {
      const fieldName = String(rowDataFieldName);
      const value = rowData[rowDataFieldName];

      if (typeof value !== 'number' && value !== null) return `The '${fieldName}' field should be number type.`;
      if (Number.isNaN(value)) return `The '${fieldName}' field cannot be NaN.`;
      return cellValidation(rowData);
    },

    // ================================
    // Sorting
    // ================================
    clientSideSortFn: enableClientSideSorting // prettier-break-line
      ? createSortByNumber({ rowDataFieldName })
      : undefined,

    // ================================
    // Full-Text Search
    // ================================
    clientSideFullTextSearch: {
      enable: enableClientSideFullTextSearch,
      getTextByRow: displayCellText,
    },

    // ================================
    // Download CSV
    // ================================
    clientSideCsvDownload: {
      enable: enableClientSideCsvDownload,
      headerText: headerTextArray.join(' '),
      getTextByRow: displayCellText,
    },
  };
}
