import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMegaphoneSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.79714 14L6.34714 11L4.19714 11.81L5.69714 15L7.79714 14Z"
      fill={props.color}
    />
    <path
      d="M4.19714 11.81C3.75734 11.8936 3.3023 11.8338 2.89904 11.6394C2.49578 11.445 2.16561 11.1262 1.95714 10.73L1.66714 10.11C1.55767 9.8502 1.50086 9.57125 1.50001 9.28933C1.49916 9.0074 1.55428 8.72812 1.66219 8.46766C1.77009 8.2072 1.92863 7.97076 2.1286 7.77204C2.32858 7.57331 2.56601 7.41626 2.82714 7.31L3.49714 7L4.29714 6.58C5.62119 5.96959 6.87914 5.18383 7.91714 4.16M4.19714 11.81L5.69714 15L7.79714 14L6.34714 11M4.19714 11.81L6.34714 11M6.34714 11C7.68241 10.3866 9.11331 9.95777 10.5771 9.83M7.91714 4.16C8.1955 3.88051 8.45919 3.58678 8.70714 3.28C9.26498 2.56387 9.76014 1.80105 10.1871 1L14.4971 10.18C13.6022 9.98386 12.6923 9.86344 11.7771 9.82C11.3774 9.79987 10.9769 9.80987 10.5771 9.83M7.91714 4.16L9.24714 6.995L10.5771 9.83"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgMegaphoneSmall.displayName = 'SvgMegaphoneSmall';
const MegaphoneSmall = withForgeIconProps(SvgMegaphoneSmall, {
  name: 'Megaphone',
  usage: 'Use to indicate or learn more about a new feature or highlight',
  related: 'update, release',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default MegaphoneSmall;
