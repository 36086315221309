import { ReactElement, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import ForgePropTypes from '../utils/propTypes';
import { forgeClassHelper } from '../utils/classes';
import { HeadingTags } from '../utils/constants';

export type HeadingVariant = 'page' | 'section' | 'subsection';

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  className?: string;
  text?: string;
  headingDescription?: string | JSX.Element;
  headingTag?: HeadingTags;
  variant?: HeadingVariant;
}

const componentName = 'heading';
const classes = forgeClassHelper(componentName);

const Heading = ({
  text,
  headingDescription,
  headingTag: Hx = 'h1',
  variant = 'page',
  className,
  ...otherProps
}: HeadingProps): ReactElement => {
  const variantInsensitive = variant.toLowerCase();
  const subclasses = forgeClassHelper(`${componentName}--${variantInsensitive}`); // fix this in BEMHelper if come across another example in the future
  const hasDescription = !!headingDescription;
  // Nested <p> blocks generate console errors. Don't force a <p> if we have a JSX.Element
  const DescriptionElement = typeof headingDescription === 'string' ? 'p' : 'div';

  return (
    <>
      <Hx
        {...otherProps}
        {...classes({
          modifiers: [variantInsensitive, ...(hasDescription ? ['has-description'] : [])],
          extra: className,
        })}
      >
        {text}
      </Hx>
      {headingDescription && (
        <DescriptionElement
          {...subclasses({
            element: 'description',
          })}
        >
          {headingDescription}
        </DescriptionElement>
      )}
    </>
  );
};

const headingPropTypes: WeakValidationMap<HeadingProps> = {
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** The heading text */
  text: PropTypes.string,
  /** Optional description text that goes below the heading text */
  headingDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Specifies the heading's HTML tag. For more on the importance of heading tags in semantic HTML,
   * [see the W3C's article on this topic](https://www.w3.org/WAI/tutorials/page-structure/headings/). */
  headingTag: ForgePropTypes.headingTags,
  /** The visual style of the heading */
  variant: PropTypes.oneOf(['page', 'section', 'subsection']),
};
Heading.propTypes = headingPropTypes;

export default Heading;
