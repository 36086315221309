import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFoodLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 1.00049C21 0.448204 20.5523 0.000488281 20 0.000488281C19.1312 0.000488281 18.3357 0.571749 17.7244 1.21538C17.0705 1.9038 16.4402 2.86883 15.9071 4.0448C14.8379 6.40379 14.1121 9.71693 14.4302 13.6525C14.4722 14.1718 14.906 14.5719 15.427 14.5719H19V23.0005C19 23.5528 19.4477 24.0005 20 24.0005C20.5523 24.0005 21 23.5528 21 23.0005V1.00049Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0.000488281C4.55228 0.000488281 5 0.448204 5 1.00049V5.85763C5 5.90569 5.00182 5.95345 5.00539 6.00084C5.01432 6.00061 5.02328 6.00049 5.03226 6.00049H6.87097V1.00049C6.87097 0.448204 7.31868 0.000488281 7.87097 0.000488281C8.42325 0.000488281 8.87097 0.448204 8.87097 1.00049V6.00049H10.7366C10.7401 5.95321 10.7419 5.90557 10.7419 5.85763V1.00049C10.7419 0.448204 11.1897 0.000488281 11.7419 0.000488281C12.2942 0.000488281 12.7419 0.448204 12.7419 1.00049V5.85763C12.7419 8.02053 11.0019 9.73762 8.87097 9.8516V23.0005C8.87097 23.5528 8.42325 24.0005 7.87097 24.0005C7.31868 24.0005 6.87097 23.5528 6.87097 23.0005V9.8516C4.74004 9.73762 3 8.02053 3 5.85763V1.00049C3 0.448204 3.44772 0.000488281 4 0.000488281Z"
      fill={props.color}
    />
  </svg>
);
SvgFoodLarge.displayName = 'SvgFoodLarge';
const FoodLarge = withForgeIconProps(SvgFoodLarge, {
  name: 'Food',
  usage:
    'Use to indicate food, dining/restaurants, access to food, food stability, or other related issues',
  related: 'fork, knife, eat, kitchen, dining',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default FoodLarge;
