import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClinicalAccelerateRightLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 23C7.61305 23 5.32381 22.0518 3.63599 20.364C1.94816 18.6761 1 16.3869 1 14C1 11.6131 1.94816 9.32387 3.63599 7.63605C5.32381 5.94822 7.61305 5 10 5H23M23 5L19.01 1M23 5L19 9"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgClinicalAccelerateRightLarge.displayName = 'SvgClinicalAccelerateRightLarge';
const ClinicalAccelerateRightLarge = withForgeIconProps(
  SvgClinicalAccelerateRightLarge,
  {
    name: 'ClinicalAccelerateRight',
    usage:
      'Use to accelerate problems and medications to the clinical encounter from left to right',
    related: 'ClinicalAccelerateLeft',
    isLabelRequired: true,
    isNeutralInteractive: false,
    size: 'large',
  }
);
export default ClinicalAccelerateRightLarge;
