/* eslint-disable react/prop-types */
import { ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { forgeClassHelper } from '../utils/classes';
import { useTheme } from '../Root/ThemeContext';
import { PortalContext, PortalData, createEmotionCache } from './PortalContext';
import { nanoid } from 'nanoid';

const classes = forgeClassHelper({ name: 'portal-provider', isPortal: true, outputIsString: true });

export type PortalProviderProps = {
  /** Passes a classname to the portalled element created by this component */
  className?: string;
  /** non-portalled children to receive the PortalContext */
  children?: ReactNode;
};

/** If mounted in a shadow DOM, copy all styles from the original shadow root
 * to a new shadow root */
const copyStaticStyles = (originalShadowRoot: ShadowRoot, portalShadowRoot: ShadowRoot): void => {
  if (originalShadowRoot.adoptedStyleSheets && originalShadowRoot.adoptedStyleSheets.length > 0) {
    portalShadowRoot.adoptedStyleSheets = originalShadowRoot.adoptedStyleSheets;
  } else {
    /** Where static styles get cloned from the original shadow Root */
    const styleNode = document.createElement('div');
    styleNode.setAttribute('class', classes({ element: 'static-styles' }));
    portalShadowRoot.appendChild(styleNode);

    /** Constructable stylesheets don't exist, so clone all non-emotion styles
     * present in the original shadow DOM  */
    const styleTags = originalShadowRoot.querySelectorAll('style:not([data-emotion])');
    const styleLinks = originalShadowRoot.querySelectorAll('link[type="text/css"]');

    /** Clone the styles onto a new shadow root */
    [...styleTags, ...styleLinks].forEach((node) => styleNode.appendChild(node.cloneNode(true)));
  }
};

/** emotion-js can only create an emotion cache with a single container.
 * The problem is, Select straddles two separate shadow DOMs. We need
 * to use a MutationObserver to copy <style> tags that get dynamically added
 * by emotion and clone them to the portal's shadow DOM.
 */
const copyDynamicStyles = (emotionStyleNode: HTMLElement, portalShadowRoot: ShadowRoot): (() => void) => {
  const portalEmotionNode = document.createElement('div');
  portalEmotionNode.setAttribute('class', classes({ element: 'portal-emotion' }));
  portalShadowRoot.appendChild(portalEmotionNode);

  /** Clone a style node and add it to portalEmotionNode */
  const cloneNode = (node: Node): Node => portalEmotionNode.appendChild(node.cloneNode(true));

  /** Styles that may have been added while the relevant DOM nodes were resolving */
  const initialStyles = emotionStyleNode.querySelectorAll('style');
  initialStyles.forEach(cloneNode);

  /** Watch for mutations made
   *
   * We do not need to observe changes to attributes or style contents,
   * as emotion-js styles are purely additive
   */
  const styleObserver = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      mutation.addedNodes.forEach(cloneNode);
    });
  });
  styleObserver.observe(emotionStyleNode, { childList: true });

  /** Clean up on unmount or on the off chance that a dependency changes.
   *
   * Cloned styles are not cleaned up given that if this useEffect were to
   * re-fire, the styles would not get re-cloned.
   */
  return () => {
    styleObserver.disconnect();
  };
};

/** Creates a portal to document.body, preparing this environment to use Forge
 * styling.
 *
 * If being rendered within a shadow DOM, a new shadow root is created, and
 * all <style> and <link type="text/css"> elements are copied from the original
 * shadow root.
 */
function PortalProvider({ children, className }: PortalProviderProps): ReactElement {
  const theme = useTheme();
  /** The DOM node where child components should portal to in order to get Forge
   * styling
   *
   * This is created in state because it will have a different parent depending
   * on whether PortalProvider is rendered in a shadow DOM or not. Creating this
   * DOM node outside of React gives us a stable HTMLDivElement, regardless of
   * where it is eventually mounted.
   */
  const [portalNode] = useState<HTMLDivElement | null>(() => {
    if (typeof document === 'undefined') {
      return null;
    } else {
      const portalNode = document.createElement('div');
      portalNode.id = nanoid();
      return portalNode;
    }
  });
  if (portalNode) {
    // Update className outside of useState constructor in case theme or className change.
    portalNode.className = classes({ theme: theme, extra: className });
  }
  /** The DOM node where emotion attaches its styles when a part of the shadow DOM.
   *
   * This is managed with state so that an updated emotionCache can be published
   * after renderPortalNodes finishes mutating the DOM.
   */
  const [emotionStyleNode, setEmotionStyleNode] = useState<HTMLDivElement | null>(null);
  /** Information that emotion-js uses to determine how and where to attach its
   * dynamic styles */
  const emotionCache = useMemo(() => {
    if (!emotionStyleNode) {
      return undefined;
    } else if (emotionStyleNode.getRootNode() instanceof ShadowRoot) {
      return createEmotionCache(emotionStyleNode);
    } else {
      return createEmotionCache();
    }
  }, [emotionStyleNode]);

  /** The context that gets published for consumption by components which portal */
  const portalContext = useMemo<PortalData>(
    () => ({
      emotionCache,
      portalNode,
    }),
    [emotionCache, portalNode]
  );

  /** Function used to clean up side-effects of renderPortalNodes function */
  const renderPortalNodesCleanup = useRef<null | (() => void)>(null);

  /** Mutates the DOM outside of react in order to create the right environment
   * for mounting portals.
   *
   * This must be done outside of React because creating a shadow root is not a
   * first class citizen of React. At best, react-shadow-root will create a
   * shadow root after its first render, but we need tight control
   * over DOM node objects and useEffect execution order for PortalContext
   * subscribers.
   */
  const renderPortalNodes = useCallback(
    (newEmotionStyleNode: HTMLDivElement | null): void => {
      setEmotionStyleNode(newEmotionStyleNode);
      /** Clean up any side effects from the last time this function was called.
       *
       * Should only be applicable when unmounting
       */
      renderPortalNodesCleanup.current?.();
      renderPortalNodesCleanup.current = null;
      if (newEmotionStyleNode && portalNode) {
        const originalShadowRoot = newEmotionStyleNode.getRootNode();
        if (originalShadowRoot instanceof ShadowRoot) {
          /** Shadow DOM available
           *
           * Effectively renders:
           *
           * ReactDom.createPortal(
           *   <div {...classes({element: 'shadow-dom'})}>
           *     <ReactShadowRoot mode="open" stylesheets={originalShadowRoot.adoptedStyleSheets}>
           *       {originalShadowRoot.adoptedStyleSheets
           *         ? null
           *         : <div {...classes({ element: 'static-styles' })} >
           *             <style />
           *             ....
           *           </div>
           *       }
           *       <div {...classes({ element: 'portal-emotion' })}>
           *         <style />
           *         ....
           *       </div>
           *       <div ref={portalNode} {...classes({ theme: theme, extra: className })} />
           *     </ReactShadowRoot>
           *   </div>,
           *   document.body
           * )
           */

          /** Wraps a new shadow Root so that the rest of document.body is
           * isolated from the new shadow Root */
          const shadowDomWrapper = document.createElement('div');
          shadowDomWrapper.setAttribute('class', classes({ element: 'shadow-dom' }));
          shadowDomWrapper.setAttribute('style', 'position:relative;contain:style;');
          document.body.appendChild(shadowDomWrapper);

          /** The new shadow Root for components that portal */
          const portalShadowRoot = shadowDomWrapper.attachShadow({ mode: 'open' });

          /** Copy static styles from the original shadow root to the new shadow root */
          copyStaticStyles(originalShadowRoot, portalShadowRoot);

          /** Set up a watcher for new styles added by emotion-js */
          const dynamicCleanup = copyDynamicStyles(newEmotionStyleNode, portalShadowRoot);

          /** Add the portal Node that has been living in state */
          portalShadowRoot.appendChild(portalNode);

          /** Clean up function for when PortalProvider is unmounted */
          renderPortalNodesCleanup.current = () => {
            portalShadowRoot.removeChild(portalNode);
            dynamicCleanup();
            document.body.removeChild(shadowDomWrapper);
          };
        } else {
          /** No shadow DOM. We still need to attach portalNode to the DOM though.
           *
           * Effectively renders:
           *
           * ReactDom.createPortal(
           *   <div ref={portalNode} {...classes({ theme: theme, extra: className })}>,
           *   document.body
           * )
           */
          document.body.appendChild(portalNode);
          renderPortalNodesCleanup.current = () => {
            document.body.removeChild(portalNode);
          };
        }
      }
      return;
    },
    [portalNode]
  );

  /** This is _not_ the final state of what this component renders. Manual DOM
   * manipulation is performed in a ref callback function.
   */
  return (
    <PortalContext.Provider value={portalContext}>
      {
        /** Create a div to mount emotion-js styles.
         *
         * Although this div is not technically necessary when no shadow DOM is
         * present, its continued presence makes state management within
         * renderPortalNodes easier.
         */
        <div ref={renderPortalNodes} className={classes({ element: 'main-emotion' })} />
      }
      {children}
    </PortalContext.Provider>
  );
}

export default PortalProvider;
