import { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import Code from '../../code';
import { Language } from 'prism-react-renderer';
import remarkGfm from 'remark-gfm';
import { withPrefix } from 'gatsby';

type MarkdownProps = {
  field_markdown_body: string;
};

const Markdown = ({ field_markdown_body }: MarkdownProps): ReactElement => {
  return (
    <div className="cms-content">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <Code codeString={String(children).replace(/\n$/, '')} language={match[1] as Language} />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
          a({ node, children, href, ...props }) {
            if (href) {
              return (
                <a href={withPrefix(href)} {...props}>
                  {children}
                </a>
              );
            } else {
              return <a {...props}>{children}</a>;
            }
          },
        }}
      >
        {field_markdown_body}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;
