const rawSeed = 40000;

/**
 * Hashing function to generate a consistent (between renders) pseudo-random number
 * Blatantly stolen from Robert Jenkins.
 * See: http://www.burtleburtle.net/bob/hash/doobs.html
 * @param {Number} seed a random seed
 * @param {Number} a any integer that will remain consistent between renders
 * @param {Number} b a second integer that will remain consistent between renders
 * @param {Number} min the minimum return value
 * @param {Number} max the maximum return value
 * @returns {Number} a pseudo-random integer that is consistent between renders
 */
const pseudoRandomInt = ({
  seed = rawSeed,
  a,
  b = 0,
  min = 60,
  max = 280,
}: {
  seed?: number;
  a: number;
  b?: number;
  min?: number;
  max?: number;
}): number => {
  seed -= a;
  seed -= b;
  seed ^= b >> 13;

  a -= b;
  a -= seed;
  a ^= seed << 8;

  b -= seed;
  b -= a;
  b ^= a >> 13;

  seed -= a;
  seed -= b;
  seed ^= b >> 12;

  a -= b;
  a -= seed;
  a ^= seed << 16;

  b -= seed;
  b -= a;
  b ^= a >> 5;

  seed -= a;
  seed -= b;
  seed ^= b >> 3;

  a -= b;
  a -= seed;
  a ^= seed << 10;

  b -= seed;
  b -= a;
  b ^= a >> 15;

  return min + (Math.abs(b) % (max - min + 1));
};

export default pseudoRandomInt;
