import React, { useMemo, useState } from 'react';
import {
  callValidateDate,
  DateRangeInputIDs,
  DateRangeInputHandlerProps,
  handleDateBlur,
  handleDateChange,
  handleDateChangeRaw,
  handleShowNoDates,
} from './DateRangeInputHandlers';
import { compareDatesGreaterThan, compareDatesLessThan, truncateToMidnight } from './DateRangeInputUtils';
import { DateInputOnChangeEvent, DateInputValue } from '../DateInput';
import { DateRangeInputProps, DateRangeInputValues } from './DateRangeInputTypes';

export interface AppLogicProps {
  bannerCount: number;
  disabledFirstDate?: boolean;
  disabledSecondDate?: boolean;
  firstDate?: DateInputValue;
  firstDateCustomValidationMsg: string | null;
  firstDateErrorMsg: string;
  handleFirstDateBlur: (ev: React.FocusEvent<HTMLInputElement, Element>) => void;
  handleFirstDateChange: (ev: DateInputOnChangeEvent) => void;
  handleFirstDateChangeRaw: (ev: React.FocusEvent<HTMLInputElement, Element>) => void;
  handleFirstDateSelect: (value: Date) => void;
  handleSecondDateBlur: (ev: React.FocusEvent<HTMLInputElement, Element>) => void;
  handleSecondDateChange: (ev: DateInputOnChangeEvent) => void;
  handleSecondDateChangeRaw: (ev: React.FocusEvent<HTMLInputElement, Element>) => void;
  handleSecondDateSelect: (value: Date) => void;
  handleShowNoDateItemsChange: (ev: DateInputOnChangeEvent) => void;
  hasFirstDateCustomValidationMsg: boolean;
  suppressVerticalSpacing: boolean;
  suppressWarningBanner: boolean;
  hasSecondDateCustomValidationMsg: boolean;
  id: string;
  isFirstDateAfterMaxDate: boolean;
  isFirstDateAfterSecondDate: boolean;
  isFirstDateBeforeMinDate: boolean;
  isSecondDateAfterMaxDate: boolean;
  isSecondDateBeforeMinDate: boolean;
  isValidFirstDate: boolean;
  isValidSecondDate: boolean;
  maxDate?: Date;
  minDate?: Date;
  openToFirstDate?: Date;
  openToSecondDate?: Date;
  passedFirstDate?: DateInputValue;
  passedSecondDate?: DateInputValue;
  passedShowNoDateItems: boolean;
  placeholderFirstDate?: string;
  placeholderSecondDate?: string;
  requiredFirstDate?: boolean;
  requiredSecondDate?: boolean;
  secondDate?: DateInputValue;
  secondDateCustomValidationMsg: string | null;
  secondDateErrorMsg: string;
  setBannerCount: React.Dispatch<React.SetStateAction<number>>;
  setFirstDate: React.Dispatch<React.SetStateAction<DateInputValue | undefined>>;
  setFirstDateErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  setSecondDate: React.Dispatch<React.SetStateAction<DateInputValue | undefined>>;
  setSecondDateErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  setShowNoDates: React.Dispatch<React.SetStateAction<boolean>>;
  showNoDates: boolean;
  showRequiredBannerFirstDate: boolean;
  showRequiredBannerSecondDate: boolean;
}

export type UseAppLogicProps = Pick<
  DateRangeInputProps,
  | 'id'
  | 'earliestLatestDates'
  | 'startEndDisabledDates'
  | 'startEndRequiredDates'
  | 'onBlurAllValues'
  | 'onChangeAllValues'
  | 'validateStartDate'
  | 'validateEndDate'
> &
  Required<
    Pick<
      DateRangeInputProps,
      | 'earliestLatestDates'
      | 'openToDates'
      | 'placeholderText'
      | 'startEndDisabledDates'
      | 'startEndRequiredDates'
      | 'suppressVerticalSpacing'
      | 'suppressWarningBanner'
      | 'values'
    >
  >;

export const useAppLogic = ({
  id,
  onBlurAllValues,
  onChangeAllValues,
  earliestLatestDates,
  startEndDisabledDates,
  startEndRequiredDates,
  openToDates,
  placeholderText,
  suppressVerticalSpacing,
  suppressWarningBanner,
  validateStartDate,
  validateEndDate,
  values,
}: UseAppLogicProps): AppLogicProps => {
  const { start: passedFirstDate, end: passedSecondDate, checked: passedShowNoDateItems = false } = values;
  const { earliest: passedMinDate, latest: passedMaxDate } = earliestLatestDates;
  const { start: disabledFirstDate, end: disabledSecondDate } = startEndDisabledDates;
  const { start: requiredFirstDate, end: requiredSecondDate } = startEndRequiredDates;
  const { start: openToFirstDate, end: openToSecondDate } = openToDates;
  const { start: placeholderFirstDate, end: placeholderSecondDate } = placeholderText;

  /** Declare useState instances for the range values.  Default to any passed value. */
  const [firstDate, setFirstDate] = useState<DateInputValue | undefined>(passedFirstDate);
  const [secondDate, setSecondDate] = useState<DateInputValue | undefined>(passedSecondDate);

  // Dates should only have the specificity to day rather than hours. From DateInput.
  const minDate = useMemo(() => truncateToMidnight(passedMinDate), [passedMinDate]);
  const maxDate = useMemo(() => truncateToMidnight(passedMaxDate), [passedMaxDate]);

  const [showNoDates, setShowNoDates] = useState(passedShowNoDateItems);
  const [isValidFirstDate, setIsValidFirstDate] = useState(true);
  const [isValidSecondDate, setIsValidSecondDate] = useState(true);
  const [firstDateErrorMsg, setFirstDateErrorMsg] = useState('');
  const [secondDateErrorMsg, setSecondDateErrorMsg] = useState('');
  const [firstDateCustomValidationMsg, setFirstDateCustomValidationMsg] = useState<string | null>(null);
  const [secondDateCustomValidationMsg, setSecondDateCustomValidationMsg] = useState<string | null>(null);
  const [bannerCount, setBannerCount] = useState(0);
  const allValues: DateRangeInputValues = { start: firstDate, end: secondDate, checked: showNoDates };
  // These are the HTML ids of the 3 inputs used in this control.  The last one concerns the
  // checkbox and it may not exist.
  const ids: DateRangeInputIDs = [`${id}-start-date-id`, `${id}-end-date-id`, `${id}-showitemscbid`];

  /**
   * The following booleans help prevent
   * coding errors when error conditions are added or modified. The useEffects
   * check these to set a state variable controlling whether a date entry field
   * has an error color associated with it.  These same values individually are
   * used to hide/show the banner messages corresponding to the associated entry
   * field.
   */
  // Booleans for whether the required banner should be shown.
  const showRequiredBannerFirstDate = !!(
    requiredFirstDate &&
    !showNoDates &&
    (firstDate === null || firstDate instanceof Date === false)
  );
  const showRequiredBannerSecondDate = !!(
    requiredSecondDate &&
    !showNoDates &&
    (secondDate === null || secondDate instanceof Date === false)
  );

  // Booleans to know if there is a custom validation message for either or both of the DateInputs.
  const hasFirstDateCustomValidationMsg =
    firstDateCustomValidationMsg !== null && firstDateCustomValidationMsg.length > 0;
  const hasSecondDateCustomValidationMsg =
    secondDateCustomValidationMsg !== null && secondDateCustomValidationMsg.length > 0;
  // Booleans for before/after min/max dates.
  const isFirstDateAfterMaxDate = !!(firstDate && maxDate && compareDatesGreaterThan(firstDate, maxDate));
  const isFirstDateBeforeMinDate = !!(firstDate && minDate && compareDatesLessThan(firstDate, minDate));
  const isSecondDateAfterMaxDate = !!(secondDate && maxDate && compareDatesGreaterThan(secondDate, maxDate));
  const isSecondDateBeforeMinDate = !!(secondDate && minDate && compareDatesLessThan(secondDate, minDate));
  // Boolean for the one check that most likely changes the most.
  const isFirstDateAfterSecondDate = !!(
    isValidFirstDate &&
    firstDate &&
    isValidSecondDate &&
    secondDate &&
    compareDatesGreaterThan(firstDate, secondDate)
  );

  // With the exception of date, these arguments don't change.
  const firstDateDateRangeInputHandlerProps: DateRangeInputHandlerProps = {
    ids,
    allValues,
    validateDate: validateStartDate,
    setCustomValidationMsg: setFirstDateCustomValidationMsg,
    date: undefined,
    setDate: setFirstDate,
    setIsValidDate: setIsValidFirstDate,
    setShowNoDates,
    onBlurAllValues,
    onChangeAllValues,
  };
  const secondDateDateRangeInputHandlerProps: DateRangeInputHandlerProps = {
    ids,
    allValues,
    validateDate: validateEndDate,
    setCustomValidationMsg: setSecondDateCustomValidationMsg,
    date: undefined,
    setDate: setSecondDate,
    setIsValidDate: setIsValidSecondDate,
    setShowNoDates,
    onBlurAllValues,
    onChangeAllValues,
  };

  /** These functions deal with custom validation.  Call them and update internal custom messages. */
  const callValidateFirstDate = (date?: Date): void => {
    callValidateDate({ ...firstDateDateRangeInputHandlerProps, date: date });
  };
  const callValidateSecondDate = (date?: Date): void => {
    callValidateDate({ ...secondDateDateRangeInputHandlerProps, date: date });
  };

  // Assign to the respective DateRangeInputHandlerProps since they have just been declared.
  firstDateDateRangeInputHandlerProps.callValidateDate = callValidateFirstDate;
  secondDateDateRangeInputHandlerProps.callValidateDate = callValidateSecondDate;

  /** These functions deal with date changes that are entered by the keyboard. */
  const handleFirstDateChangeRaw = (ev: React.FocusEvent<HTMLInputElement, Element>): void => {
    handleDateChangeRaw(firstDateDateRangeInputHandlerProps, ev);
  };
  const handleSecondDateChangeRaw = (ev: React.FocusEvent<HTMLInputElement, Element>): void => {
    handleDateChangeRaw(secondDateDateRangeInputHandlerProps, ev);
  };

  /** These functions deal with date changes from the calendar widget.  Note that they are Date objects! */
  const handleFirstDateSelect = (value: Date): void => {
    callValidateFirstDate(value);
    setFirstDate(value);
    setIsValidFirstDate(true);
  };
  const handleSecondDateSelect = (value: Date): void => {
    callValidateSecondDate(value);
    setSecondDate(value);
    setIsValidSecondDate(true);
  };

  /** These functions deal with loss of focus from the DateInput edit. */
  const handleFirstDateBlur = (ev: React.FocusEvent<HTMLInputElement, Element>): void => {
    handleDateBlur(firstDateDateRangeInputHandlerProps, ev);
  };
  const handleSecondDateBlur = (ev: React.FocusEvent<HTMLInputElement, Element>): void => {
    handleDateBlur(secondDateDateRangeInputHandlerProps, ev);
  };

  /** These functions deal with changes in the DateInput edits. */
  const handleFirstDateChange = (ev: DateInputOnChangeEvent): void => {
    handleDateChange(firstDateDateRangeInputHandlerProps, ev);
  };
  const handleSecondDateChange = (ev: DateInputOnChangeEvent): void => {
    handleDateChange(secondDateDateRangeInputHandlerProps, ev);
  };

  /**
   * This toggles "Show items with no date" (default) checkbox.
   * Use only if one or both of the dates are required.
   */
  const handleShowNoDateItemsChange = (ev: DateInputOnChangeEvent): void => {
    handleShowNoDates(firstDateDateRangeInputHandlerProps, ev);
  };

  return {
    bannerCount,
    disabledFirstDate,
    disabledSecondDate,
    firstDate,
    firstDateCustomValidationMsg,
    firstDateErrorMsg,
    handleFirstDateBlur,
    handleFirstDateChange,
    handleFirstDateChangeRaw,
    handleFirstDateSelect,
    handleSecondDateBlur,
    handleSecondDateChange,
    handleSecondDateChangeRaw,
    handleSecondDateSelect,
    handleShowNoDateItemsChange,
    hasFirstDateCustomValidationMsg,
    suppressVerticalSpacing,
    suppressWarningBanner,
    hasSecondDateCustomValidationMsg,
    id,
    isFirstDateAfterMaxDate,
    isFirstDateAfterSecondDate,
    isFirstDateBeforeMinDate,
    isSecondDateAfterMaxDate,
    isSecondDateBeforeMinDate,
    isValidFirstDate,
    isValidSecondDate,
    maxDate,
    minDate,
    openToFirstDate,
    openToSecondDate,
    passedFirstDate,
    passedSecondDate,
    passedShowNoDateItems,
    placeholderFirstDate,
    placeholderSecondDate,
    requiredFirstDate,
    requiredSecondDate,
    secondDate,
    secondDateCustomValidationMsg,
    secondDateErrorMsg,
    setBannerCount,
    setFirstDate,
    setFirstDateErrorMsg,
    setSecondDate,
    setSecondDateErrorMsg,
    setShowNoDates,
    showNoDates,
    showRequiredBannerFirstDate,
    showRequiredBannerSecondDate,
  };
};

export default useAppLogic;
