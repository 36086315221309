import rawContents from "!!raw-loader!./ExtendedWait.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ExtendedWait():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Extended Wait" />;
}

export default ExtendedWait;
