import { ReactNode } from 'react';
import { ValidationResult as AriaValidationResult } from 'react-aria-components';

export {
  FieldError as default,
  FieldErrorContext as AriaFieldErrorContext,
  type FieldErrorProps as AriaFieldErrorProps,
  type ValidationResult as AriaValidationResult,
} from 'react-aria-components';

export type AriaErrorMessageFunction = (v: AriaValidationResult) => ReactNode;
export type AriaErrorMessage = ReactNode | AriaErrorMessageFunction;
