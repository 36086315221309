import React, { ReactElement, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper({ name: 'indicator' });

/** A supported preset type string */
export type IndicatorType = 'alert' | 'success' | 'warning' | 'new';
/** The list of supported preset types */
export const indicatorTypes: readonly IndicatorType[] = ['alert', 'success', 'warning', 'new'];

export interface IndicatorProps {
  /** Content to decorate with the indicator. This content is automatically displayed to the right of the indicator with 16px of padding between, and aligned vertically with the indicator. This is an optional prop for convenience. Leave this prop blank to render the Indicator by itself.  */
  children?: React.ReactNode;

  /** CSS class to apply to the Element's root div */
  className?: string;

  /** Determines the Indicator's color  */
  type?: IndicatorType;
}

/** A small indicator to distinguish between items that are read and unread in a Table, card group, etc. */
function Indicator({ className, children, type }: IndicatorProps): ReactElement {
  return (
    <div {...classes()}>
      <div {...classes({ element: 'indicator', extra: className, modifiers: type })} />
      <div>{children}</div>
    </div>
  );
}

const IndicatorPropTypes: WeakValidationMap<IndicatorProps> = {
  /** Content to decorate with the indicator. This content is automatically displayed to the right of the indicator with 16px of padding between, and aligned vertically with the indicator. This is an optional prop for convenience. Leave this prop blank to render the Indicator by itself.  */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  /** CSS class to apply to the Element's root div */
  className: PropTypes.string,

  /** Determines the Indicator's color  */
  type: PropTypes.oneOf(indicatorTypes),
};

Indicator.propTypes = IndicatorPropTypes;

export default Indicator;
