import { useMemo, useContext, forwardRef } from 'react';

import { Avatar, AvatarProps } from '@athena/forge';
import { ProfileData } from '../../hooks/useProfile';
import { ProfileContext } from '../../contexts';

const AVATAR_COLOR = '#31B0AC';

type UserAvatarBaseProps = {
  userDetails: ProfileData;
};

type UserAvatarProps = UserAvatarBaseProps & Omit<AvatarProps, 'initials'>;

const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(({ userDetails, onClick, size }, ref) => {
  const initials = useMemo<string>(() => {
    if (userDetails?.givenName === undefined) return '';
    return `${userDetails?.givenName[0]}${userDetails?.surname[0]}`;
  }, [userDetails]);

  const { profilePhotoUrl } = useContext(ProfileContext);

  return profilePhotoUrl ? (
    <Avatar
      ref={ref}
      initials={initials}
      image={profilePhotoUrl}
      size={size}
      style={{ backgroundColor: AVATAR_COLOR }}
      onClick={onClick}
    />
  ) : (
    <Avatar ref={ref} initials={initials} size={size} style={{ backgroundColor: AVATAR_COLOR }} onClick={onClick} />
  );
});

UserAvatar.displayName = 'UserAvatar';

export default UserAvatar;
