import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGenAiSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.04766 7.65803C1.79143 6.35771 3.19283 5.47998 4.8 5.47998H11.2C12.8072 5.47998 14.2086 6.35771 14.9523 7.65803C15.5638 7.883 16 8.47012 16 9.15998V10.44C16 11.1298 15.5638 11.717 14.9523 11.9419C14.2086 13.2423 12.8072 14.12 11.2 14.12H4.8C3.19283 14.12 1.79143 13.2423 1.04766 11.9419C0.436212 11.717 0 11.1298 0 10.44V9.15998C0 8.47012 0.436211 7.883 1.04766 7.65803ZM4.8 6.43998C3.49212 6.43998 2.3579 7.18715 1.80247 8.28014L1.69755 8.48659L1.47066 8.53296C1.17914 8.59254 0.96 8.85121 0.96 9.15998V10.44C0.96 10.7488 1.17914 11.0074 1.47066 11.067L1.69755 11.1134L1.80247 11.3198C2.3579 12.4128 3.49212 13.16 4.8 13.16H11.2C12.5079 13.16 13.6421 12.4128 14.1975 11.3198L14.3024 11.1134L14.5293 11.067C14.8209 11.0074 15.04 10.7488 15.04 10.44V9.15998C15.04 8.85121 14.8209 8.59254 14.5293 8.53296L14.3024 8.48659L14.1975 8.28014C13.6421 7.18715 12.5079 6.43998 11.2 6.43998H4.8Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.80002 8.36002C4.00473 8.36002 3.36002 9.00473 3.36002 9.80002C3.36002 10.5953 4.00473 11.24 4.80002 11.24C5.59531 11.24 6.24002 10.5953 6.24002 9.80002C6.24002 9.00473 5.59531 8.36002 4.80002 8.36002ZM2.40002 9.80002C2.40002 8.47454 3.47454 7.40002 4.80002 7.40002C6.12551 7.40002 7.20002 8.47454 7.20002 9.80002C7.20002 11.1255 6.12551 12.2 4.80002 12.2C3.47454 12.2 2.40002 11.1255 2.40002 9.80002Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2 8.36002C10.4048 8.36002 9.76005 9.00473 9.76005 9.80002C9.76005 10.5953 10.4048 11.24 11.2 11.24C11.9953 11.24 12.64 10.5953 12.64 9.80002C12.64 9.00473 11.9953 8.36002 11.2 8.36002ZM8.80005 9.80002C8.80005 8.47454 9.87457 7.40002 11.2 7.40002C12.5255 7.40002 13.6 8.47454 13.6 9.80002C13.6 11.1255 12.5255 12.2 11.2 12.2C9.87457 12.2 8.80005 11.1255 8.80005 9.80002Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41992 1H4.95999C6.2677 1 7.20296 1.56664 7.8002 2.32036C8.38412 3.05727 8.63999 3.96421 8.63999 4.68V5.96H7.67999V5.08949C7.02212 4.97751 6.34502 4.81253 5.78733 4.38354C5.08491 3.84321 4.65133 2.95804 4.48331 1.53634L4.41992 1ZM7.60996 4.10012C7.51906 3.70588 7.33695 3.2815 7.04778 2.91656C6.70815 2.48794 6.2178 2.13571 5.52957 2.00974C5.71197 2.89904 6.02453 3.35484 6.37265 3.62262C6.69718 3.87226 7.09879 4.00023 7.60996 4.10012Z"
      fill={props.color}
    />
  </svg>
);
SvgGenAiSmall.displayName = 'SvgGenAiSmall';
const GenAiSmall = withForgeIconProps(SvgGenAiSmall, {
  name: 'GenAi',
  usage: 'Use to indicate AI generated content',
  related: 'alert, warning',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default GenAiSmall;
