import MailLarge from '@athena/forge-icons/dist/MailLarge';

import rawContents from "!!raw-loader!./IconInButton.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { MailLarge };

function IconInButton():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Icon In Button" />;
}

export default IconInButton;
