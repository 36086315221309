import ClockSmall from '@athena/forge-icons/dist/ClockSmall';
import PropTypes from 'prop-types';
import { ForwardedRef, ReactElement, ReactNode, WeakValidationMap, useCallback, useContext } from 'react';
import { DateFieldStateContext, DateInput, DateSegment, TimeFieldStateContext } from 'react-aria-components';
import { DateSegment as DateSegmentType } from 'react-stately';
import { AriaFieldErrorContext } from '../AriaFieldError';
import { TimeInputSize } from '../TimeInput';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';
import { AriaDateInputContext, AriaDateInputContextData } from './AriaDateInputContext';

const classes = forgeClassHelper({ name: 'aria-date-input' });

export type AriaDateInputChildren = (segment: DateSegmentType) => ReactElement;

export interface AriaDateInputProps extends Omit<AriaDateInputContextData, 'size'> {
  children?: AriaDateInputChildren;
  size?: TimeInputSize;
}

interface AriaDateInputComponentProps extends AriaDateInputProps {
  forwardedRef: ForwardedRef<HTMLDivElement>;
}

interface LastAriaDateInputSegmentProps {
  /** Whether the input is disabled */
  disabled?: boolean;
}
/** Additional DOM nodes added after the last segment
 *
 * Includes a clock icon and an empty div used to render the hover border.
 */
const LastAriaDateInputSegment = ({ disabled }: LastAriaDateInputSegmentProps): ReactNode => {
  return (
    <>
      <div {...classes({ element: 'hover-border' })} />
      <ClockSmall
        {...classes({ element: 'clock-icon' })}
        semanticColor={disabled ? 'interaction-disabled' : 'interaction-default'}
      />
    </>
  );
};

/** Renders the "input" field that the user types a date or time into
 *
 * @documentedBy TimeField
 */
const AriaDateInput = ({ forwardedRef, ...props }: AriaDateInputComponentProps): ReactElement => {
  const validation = useContext(AriaFieldErrorContext);
  const context = useContext(AriaDateInputContext);
  const { children, className, disabled, isRequiredStyling, lastSegmentTypeRef, refOfSegments, size } = {
    ...context,
    ...props,
  };

  const dateFieldState = useContext(DateFieldStateContext);
  const timeFieldState = useContext(TimeFieldStateContext);
  const state = dateFieldState || timeFieldState;
  const lastSegmentType = state.segments[state.segments.length - 1].type;
  if (lastSegmentTypeRef) {
    lastSegmentTypeRef.current = lastSegmentType;
  }

  const defaultChildren: AriaDateInputChildren = useCallback(
    (segment) => (
      <>
        <DateSegment
          {...classes({ element: 'segment' })}
          ref={
            /** Callback function to keep track of all editable segments */
            (node) => {
              if (refOfSegments) refOfSegments.current[segment.type] = node;
            }
          }
          segment={segment}
        />
        {lastSegmentType === segment.type && (
          /** DateInput does not provide a straightforward way add custom
           * DOM nodes that are not directly tied to a segment, so use the
           * context provided by react-area-components to learn what segments
           * are available.
           */
          <LastAriaDateInputSegment disabled={disabled} />
        )}
      </>
    ),
    [refOfSegments, lastSegmentType, disabled]
  );

  return (
    <DateInput
      {...classes({
        modifiers: { [size]: true },
        states: {
          disabled: !!disabled,
          error: validation.isInvalid,
          required: !!isRequiredStyling && !disabled,
        },
        extra: className,
      })}
      ref={forwardedRef}
    >
      {(segment) => (children ? children(segment) : defaultChildren(segment))}
    </DateInput>
  );
};

const ariaDateInputPropTypes: WeakValidationMap<AriaDateInputProps> = {
  children: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isRequiredStyling: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};
AriaDateInput.propTypes = ariaDateInputPropTypes;

export default forwardRefToProps(AriaDateInput);
