import { DataTableAsyncAction, DataTableState } from '../stateTypes';
import { AlertData, ModalAlertData, ToastAlertData } from '../../utils/internalTypes';
import { AwaitableModal } from '../../alerts/AwaitableModal';

type DeleteRowAsyncActionArgs<RowData> = {
  tableType: DataTableState<RowData>['tableType'];
  rowData: RowData;
  rowId: number;
  serverSideDeleteRowFn?: (rowData: RowData) => Promise<void>;
  onRowDeleted?: (rowData: RowData) => void;
  confirmModal?: (rowData: RowData) => AlertData;
  completeModal?: (rowData: RowData) => ModalAlertData;
  completeToast?: (rowData: RowData) => ToastAlertData;
};

export function delete_row_async<RowData>({
  tableType,
  rowData,
  rowId,
  serverSideDeleteRowFn,
  onRowDeleted,
  confirmModal: getConfirmModalData,
  completeModal: getCompleteModalData,
  completeToast: getCompleteToastData,
}: DeleteRowAsyncActionArgs<RowData>): DataTableAsyncAction<RowData> {
  return async (dispatch) => {
    // ==========================
    // Confirm Modal
    // ==========================
    if (typeof getConfirmModalData !== 'undefined') {
      const { title, message } = getConfirmModalData(rowData);
      const result = await AwaitableModal(dispatch, {
        alertType: 'attention',
        headerText: title,
        children: message,
      });
      if (result === 'secondary') {
        // Note: Consider to add onCancel, CancelModal, CancelToast in the future
        return;
      }
    }

    dispatch(function before_deleting_row(s) {
      s.loader.show = true;
    });

    // ==========================
    // Delete Rows Action
    // ==========================
    try {
      if (tableType === 'server-side') {
        if (serverSideDeleteRowFn === undefined) {
          throw new Error("Missing 'serverSideDeleteRowFn' for server-side row deletion.");
        }

        await serverSideDeleteRowFn(rowData);
        dispatch(function after_deleting_row_success(s) {
          s.loader.show = false;
          s.forceFetchRowDataCounter++; // force refreshing row data
        });
      } else if (tableType === 'client-side') {
        dispatch(function client_side_delete_row(s) {
          s.loader.show = false;
          s.rows[rowId].rowStatus = 'deleted';
        });
      }

      // invoke onRowDeleted callback when deleted completed
      onRowDeleted?.(rowData);

      // ==========================
      // Complete Modal
      // ==========================
      if (typeof getCompleteModalData !== 'undefined') {
        const { alertType, title, message } = getCompleteModalData(rowData);
        await AwaitableModal(dispatch, { alertType, headerText: title, children: message });
      }

      // ==========================
      // Complete Toast
      // ==========================
      if (typeof getCompleteToastData !== 'undefined') {
        dispatch(function push_toast_stack(s) {
          const { alertType, title, message } = getCompleteToastData(rowData);
          s.toastStack.push({ alertType, headerText: title, children: message });
        });
      }
    } catch (e) {
      dispatch(function after_server_side_delete_row_failure(s) {
        s.loader.show = false;
      });

      // ========================
      // Error Modal
      // ========================
      await AwaitableModal(dispatch, {
        alertType: 'attention',
        headerText: 'Delete Row Failure',
        children: (e as Error).message,
      });
    }
  };
}
