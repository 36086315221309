import { ReactElement, ReactNode } from 'react';
import { Root } from '@athena/forge';
import MainNav from '../main-nav';
import SideNav from '../side-nav';
import PageHeader from '../page-header';
import PageContextProvider from '../page-context-provider';
import PageMetadata from '../page-metadata';
import LoginModal from '../login-modal';
import { GatsbyPageContext, PageData } from '../../contexts';
import { PageProps } from 'gatsby';
import createBEMHelper from '../../bem-helper';
import { forgeVersionType } from '../../versionCheckHelper';
import './layout.scss';

const classes = createBEMHelper({ name: 'layout' });

interface LayoutProps {
  pageContext: GatsbyPageContext;
  children: ReactNode;
  title: string;
  subtitle?: string;
  summary?: string;
  legacy?: boolean;
  isLandingPage?: boolean;
  pageData?: PageData;
  disableSideNav?: boolean;
  disableFooter?: boolean;
  location?: PageProps['location'];
}

export default function Layout({
  children,
  pageContext,
  title,
  subtitle,
  summary,
  legacy,
  isLandingPage,
  pageData,
  disableSideNav,
  disableFooter,
  location,
}: LayoutProps): ReactElement {
  const isLts = forgeVersionType() === 'lts';
  return (
    <PageContextProvider pageContext={pageContext} pageData={pageData} location={location}>
      <PageMetadata title={title} />
      <Root className="forge-guide">
        <LoginModal />
        {disableSideNav ? (
          <>
            <header {...classes({ element: 'header', modifiers: { lts: isLts, isHomePage: true } })}>
              <MainNav isHomePage />
            </header>
            <main {...classes({ element: 'homepage-main' })}>
              <div {...classes({ element: 'homepage-main-content' })}>{children}</div>
            </main>
            {!disableFooter && (
              <footer>
                <div {...classes({ element: 'homepage-footer-content' })}>
                  <p>Copyright © {new Date().getFullYear()} athenahealth, Inc.</p>
                </div>
              </footer>
            )}
          </>
        ) : (
          <>
            <header {...classes({ element: 'header', modifiers: { lts: isLts, isLandingPage: true } })}>
              <MainNav />
            </header>
            <div {...classes({ element: 'body' })} style={{ backgroundColor: pageContext.bodyColor }}>
              {!isLandingPage && (
                <aside {...classes({ element: 'aside' })}>
                  <SideNav />
                </aside>
              )}
              <main {...classes({ element: 'main', modifiers: { isLandingPage: !!isLandingPage } })}>
                <div {...classes({ element: 'main-content' })}>
                  {legacy && (
                    <PageHeader
                      title={title}
                      subtitle={subtitle}
                      summary={summary}
                      legacy={true}
                      isLandingPage={isLandingPage}
                      bodyColor={pageContext.bodyColor}
                      headerColor={pageContext.headerColor}
                    />
                  )}
                  <div
                    {...classes({ element: 'page-header-background' })}
                    style={{ backgroundColor: isLandingPage ? undefined : pageContext.headerColor }}
                  ></div>
                  {children}
                </div>
              </main>
            </div>
          </>
        )}
      </Root>
    </PageContextProvider>
  );
}
