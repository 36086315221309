import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSearchSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 15L4.75 11.25M15 7C15 10.3137 12.3137 13 9 13C5.68629 13 3 10.3137 3 7C3 3.68629 5.68629 1 9 1C12.3137 1 15 3.68629 15 7Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgSearchSmall.displayName = 'SvgSearchSmall';
const SearchSmall = withForgeIconProps(SvgSearchSmall, {
  name: 'Search',
  usage: 'Use to search for an item or information',
  related: 'magnifying glass, find, look, view',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default SearchSmall;
