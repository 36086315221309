import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNoteAddedLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 13L9.30005 16.8799C9.36859 16.9377 9.45537 16.9695 9.54504 16.9695C9.63472 16.9695 9.72149 16.9377 9.79004 16.8799L19 7M1 1H23V20C23 20.7956 22.6839 21.5587 22.1213 22.1213C21.5587 22.6839 20.7956 23 20 23H1V1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgNoteAddedLarge.displayName = 'SvgNoteAddedLarge';
const NoteAddedLarge = withForgeIconProps(SvgNoteAddedLarge, {
  name: 'NoteAdded',
  usage: 'Use to indicate that a note was added',
  related: 'paper, sticky, add',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default NoteAddedLarge;
