import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAnticlockwiseSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2.49999 9.3911C2.49207 10.1208 2.62814 10.8449 2.90038 11.5219C3.17262 12.1989 3.57568 12.8156 4.08654 13.3367C4.5974 13.8578 5.20604 14.273 5.87755 14.5586C6.54907 14.8442 7.2703 14.9946 7.99999 15.0011C8.72922 14.9959 9.45024 14.8468 10.1217 14.5622C10.7932 14.2777 11.4018 13.8634 11.9128 13.3431C12.4238 12.8229 12.827 12.2069 13.0994 11.5304C13.3717 10.8539 13.5079 10.1303 13.5 9.40111C13.5042 8.68232 13.3641 7.96996 13.0879 7.30632C12.8117 6.64269 12.4051 6.04124 11.8922 5.53765C11.3793 5.03406 10.7705 4.63857 10.1019 4.37462C9.43333 4.11068 8.71859 3.98367 7.99999 4.00109H3.49999M3.49999 4.00109L6.49003 1.00098M3.49999 4.00109L6.50003 7.00098"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgAnticlockwiseSmall.displayName = 'SvgAnticlockwiseSmall';
const AnticlockwiseSmall = withForgeIconProps(SvgAnticlockwiseSmall, {
  name: 'Anticlockwise',
  usage:
    'Use to undo the last action or rotate an item counterclockwise. Customize `title` element to indicate use',
  related: 'undo, rotate, rotation, portrait, landscape',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default AnticlockwiseSmall;
