import { DataTableStore } from '../store/stateTypes';
import { classes } from '../utils/dataTableClasses';

type ColumnFooterProps<RowData> = {
  store: DataTableStore<RowData>;
  columnId: string;
};

export function ColumnFooter<RowData>({ store, columnId }: ColumnFooterProps<RowData>): JSX.Element {
  const Footer = store.useSelector((s) => s.columns[columnId].Footer);

  return (
    <div {...classes({ element: 'column_footer' })}>
      {typeof Footer !== 'undefined' && (
        <Footer
          columnId={columnId}
          useSelector={store.useSelector}
          useDispatch={store.useDispatch}
          useDispatchAsyncAction={store.useDispatchAsyncAction}
        />
      )}
    </div>
  );
}
