import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPinFilledLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.1057 1.00073L23 9.89488Z" fill={props.color} />
    <path d="M1 23.0007L7.16007 16.8409Z" fill={props.color} />
    <path
      d="M20.8314 7.72649L16.2742 3.16912L12.5421 6.06855L8.80995 8.96798C7.50048 8.689 6.14218 8.74123 4.85798 9.11991C3.57378 9.49858 2.40439 10.1915 1.45578 11.1364L12.8486 22.5292C13.7971 21.5831 14.4925 20.4139 14.8714 19.1289C15.2503 17.844 15.3005 16.4844 15.0172 15.175L20.8314 7.72649Z"
      fill={props.color}
    />
    <path
      d="M14.1057 1.00073L23 9.89488M1 23.0007L7.16007 16.8409M20.8314 7.72649L16.2742 3.16912L12.5421 6.06855L8.80995 8.96798C7.50048 8.689 6.14218 8.74123 4.85798 9.11991C3.57378 9.49858 2.40439 10.1915 1.45578 11.1364L12.8486 22.5292C13.7971 21.5831 14.4925 20.4139 14.8714 19.1289C15.2503 17.844 15.3005 16.4844 15.0172 15.175L20.8314 7.72649Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgPinFilledLarge.displayName = 'SvgPinFilledLarge';
const PinFilledLarge = withForgeIconProps(SvgPinFilledLarge, {
  name: 'PinFilled',
  usage: 'Use to indicate a pinned state',
  related: 'thumbtack, top, favorite, active pin, pinned, unpin, filled pin',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default PinFilledLarge;
