import { Time } from '@internationalized/date';

import rawContents from "!!raw-loader!./MinMaxTime.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { Time };

function MinMaxTime():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Min Max Time" />;
}

export default MinMaxTime;
