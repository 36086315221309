import { useState, useRef, useEffect, ReactElement } from 'react';
import './tertiary-nav.scss';

interface IMenuItem {
  title: string;
  link: string;
}

interface ITertiaryNavProps {
  items?: IMenuItem[];
}

export default function TertiaryNav({ items }: ITertiaryNavProps): ReactElement {
  const [active, setActive] = useState<(string | null)[]>([]);
  const navRef = useRef(null);

  useEffect(() => {
    const navObserver = new IntersectionObserver(
      ([event]) => {
        event.target.toggleAttribute('stuck', event.intersectionRatio < 1);
      },
      {
        rootMargin: '-61px 0px 0px 0px',
        threshold: [0.99, 1],
      }
    );
    const navElement: HTMLElement | null = navRef.current;
    if (navElement) {
      navObserver.observe(navElement);
    }

    const headersObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActive((prevActive) => [...prevActive, entry.target.id]);
        } else {
          setActive((prevActive) => prevActive.filter((id) => id !== entry.target.id));
        }
      });
    });

    const elements = items?.map((menuItem) => document.getElementById(menuItem.link));
    if (elements) {
      elements.forEach((el) => {
        if (el) {
          headersObserver.observe(el);
        }
      });
    }

    return () => {
      if (elements) {
        elements.forEach((el) => {
          if (el) {
            headersObserver.unobserve(el);
          }
        });
      }
      if (navElement) {
        navObserver.unobserve(navElement);
      }
    };
  }, [items]);

  return (
    <nav ref={navRef} className="tertiary-nav">
      <ul className="tertiary-nav-list">
        {items &&
          items.map((menuItem, index: number) => {
            const activeLink = active.includes(menuItem.link);
            return (
              <li key={index} className="tertiary-nav-list-item">
                <a
                  href={`#${menuItem.link}`}
                  className={activeLink ? 'tertiary-nav-link tertiary-nav-link__active' : 'tertiary-nav-link'}
                  title={menuItem.title.replace(/&nbsp;/g, ' ')}
                >
                  {menuItem.title.replace(/&nbsp;/g, ' ')}
                </a>
              </li>
            );
          })}
      </ul>
    </nav>
  );
}
