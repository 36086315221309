import React, { FormEvent, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import FormFieldLayout from '../FormFieldLayout';
import FormLegend from '../FormLegend';
import GridRow from '../GridRow';
import GridCol from '../GridCol';
import { FormContext } from './FormContext';
import { FormRequiredVariation, FormLayout } from './FormContext';
export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  /** Text used on the submit button */
  buttonText?: string;
  /** Form elements to render */
  children?: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Function called when the user clicks a submit button in a form. Passed a submit event parameter. */
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  /** Whether the default submit button should be used */
  includeSubmitButton?: boolean;
  /** Makes label always go above input. Sets default for all form rows. Overrides `labelWidth`. */
  labelAlwaysAbove?: boolean;
  /** Width of the label in grid columns. Sets default for all form rows. Defaults to 3/12. */
  labelWidth?: number;
  /** Reduces bottom margin of form fields. Sets default for all form fields. */
  layout?: FormLayout;
  /**
   * If true, removes standard grid row outer margins.
   * Set to false if the form is at the top level of page layout.
   * Sets default for all form rows.
   */
  nested?: boolean;
  /** Prevents browser validation from triggering error popups and interfering with custom validation. */
  noValidate?: boolean;
  /** Changes the way that required fields are indicated. */
  requiredVariation?: FormRequiredVariation;
}

const Form = ({
  children,
  onSubmit,
  buttonText = 'Submit',
  includeSubmitButton = true,
  noValidate = true,
  labelWidth = 3,
  labelAlwaysAbove = false,
  layout = 'medium',
  nested = true,
  requiredVariation = 'blueBarWithRequiredLabel',
  ...formProps
}: FormProps): React.ReactElement => {
  const contextProps = {
    labelWidth,
    labelAlwaysAbove,
    layout,
    nested,
    requiredVariation,
  };

  return (
    <form onSubmit={onSubmit} {...formProps} noValidate={noValidate}>
      <FormContext.Provider value={contextProps}>
        {contextProps.requiredVariation !== 'blueBarWithRequiredLabel' && (
          <GridRow nested={contextProps.nested}>
            <GridCol>
              <FormLegend allFieldsRequired={contextProps.requiredVariation === 'allFieldsRequired'} />
            </GridCol>
          </GridRow>
        )}
        {children}
        {includeSubmitButton && (
          <FormFieldLayout
            inputSlot={
              <Button text={buttonText} type="submit" size={layout === 'super-compact' ? 'medium' : undefined} />
            }
          />
        )}
      </FormContext.Provider>
    </form>
  );
};

const formPropTypes: WeakValidationMap<FormProps & { '...rest': unknown }> = {
  /** Text used on the submit button */
  buttonText: PropTypes.string,
  /** Form elements to render */
  children: PropTypes.node,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Whether the default submit button should be used */
  includeSubmitButton: PropTypes.bool,
  /** Makes label always go above input. Sets default for all form rows. Overrides `labelWidth`. */
  labelAlwaysAbove: PropTypes.bool,
  /** Width of the label in grid columns. Sets default for all form rows. Defaults to 3/12. */
  labelWidth: PropTypes.number,
  /** Reduces bottom margin of form fields. Sets default for all form fields. */
  layout: PropTypes.oneOf(['super-compact', 'compact', 'medium', 'large']),
  /**
   * If true, removes standard grid row outer margins.
   * Set to false if the form is at the top level of page layout.
   * Sets default for all form rows.
   */
  nested: PropTypes.bool,
  /** Prevents browser validation from triggering error popups and interfering with custom validation. */
  noValidate: PropTypes.bool,
  /** Function called when the user clicks a submit button in a form. Passed a submit event parameter. */
  onSubmit: PropTypes.func,
  /** Changes the way that required fields are indicated. */
  requiredVariation: PropTypes.oneOf(['blueBarWithRequiredLabel', 'blueBarWithLegend', 'allFieldsRequired']),
  /**
   * Passthrough props to the `form` element.
   */
  '...rest': PropTypes.any,
};

Form.propTypes = formPropTypes;

export default Form;
