import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMegaphoneLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6.11449 9.77L4.90107 10.36L3.83807 10.85C3.42706 11.0176 3.05344 11.2647 2.73882 11.5772C2.4242 11.8896 2.17482 12.2612 2.00509 12.6704C1.83537 13.0795 1.74867 13.5182 1.75002 13.961C1.75136 14.4037 1.84072 14.8419 2.01292 15.25L2.47422 16.25C2.80085 16.865 3.31463 17.3609 3.9417 17.6663C4.56877 17.9718 5.27682 18.0711 5.96406 17.95M6.11449 9.77L9.36365 16.6M6.11449 9.77C8.22987 8.83554 10.1672 7.54314 11.8406 5.95M5.96406 17.95L8.33074 23L11.6501 21.43L9.36365 16.6M5.96406 17.95L9.36365 16.6M9.36365 16.6C11.475 15.6335 13.7379 15.0581 16.0525 14.86M11.8406 5.95C12.283 5.51576 12.6983 5.0549 13.0841 4.57C13.9711 3.45186 14.7564 2.25714 15.4308 1L22.25 15.43C20.8308 15.122 19.3885 14.9314 17.9378 14.86C17.3066 14.8247 16.6838 14.8247 16.0525 14.86M11.8406 5.95L16.0525 14.86"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
SvgMegaphoneLarge.displayName = 'SvgMegaphoneLarge';
const MegaphoneLarge = withForgeIconProps(SvgMegaphoneLarge, {
  name: 'Megaphone',
  usage: 'Use to indicate or learn more about a new feature or highlight',
  related: 'update, release',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default MegaphoneLarge;
