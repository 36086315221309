// cspell:words formkit, unconfigurable
import { useRef } from 'react';
import { DataTableState, DataTableStore } from '../../store/stateTypes';
import { classes } from '../../utils/dataTableClasses';
import { dragAndDrop } from '@formkit/drag-and-drop';

// Forge components and icons
import ToggleSwitch from '../../../ToggleSwitch';
import DragSmall from '@athena/forge-icons/dist/DragSmall';

// The quantity of each chunk
const CONFIG_OPTION_CHUNK_SIZE = 10;

// class names
const DRAGGABLE_OPTION_PREFIX = 'column_config__popover_body__draggable_option';
const className = {
  draggableItem: classes(DRAGGABLE_OPTION_PREFIX).className,
  dragIcon: classes(DRAGGABLE_OPTION_PREFIX + '__drag_icon').className,
  columnName: classes(DRAGGABLE_OPTION_PREFIX + '__display_column_name').className,
  toggleSwitch: classes(DRAGGABLE_OPTION_PREFIX + '__toggle_switch').className,
};

type ColumnConfigDraggableOptionsProps<RowData> = {
  store: DataTableStore<RowData>;
};
export function ColumnConfigDraggableOptions<RowData>({
  store,
}: ColumnConfigDraggableOptionsProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const columns = store.useSelector((s) => s.columns);
  const configurableColumnIds = store.useSelector((s) => s.columnConfig.configurableColumnIds);

  const parentRef = useRef<HTMLDivElement>(null);
  if (parentRef.current && typeof window !== 'undefined') {
    // Only invoke 'dragAndDrop' in browser environment
    // https://github.com/formkit/drag-and-drop/blob/main/src/utils.ts#L29-L34

    // Use the low-level `dragAndDrop` function instead of `useDragAndDrop` because it integrates
    // more effectively with the state manager.
    // https://drag-and-drop.formkit.com/#sortability (select 'Native' demo)
    dragAndDrop({
      parent: parentRef.current,
      getValues: () => configurableColumnIds,
      setValues: (newOrderOfColumnIds) => {
        dispatch(function update_configurable_column_options_order(s: DataTableState<RowData>) {
          s.columnConfig.configurableColumnIds = newOrderOfColumnIds;
        });
      },
      config: {
        handleEnd: () => dispatch(update_columns_order),
      },
    });
  }

  // calculate the number of chunks for the config options
  const numOfChunks = Math.ceil(configurableColumnIds.length / CONFIG_OPTION_CHUNK_SIZE);

  return (
    <div
      ref={parentRef}
      style={{ gridTemplateColumns: `repeat(${numOfChunks} 1fr)` }}
      {...classes({ element: 'column_config__popover_body' })}
    >
      {configurableColumnIds.map((columnId, index) => {
        // identify which chunk contains the option
        const chunkIndex = Math.ceil((index + 1) / CONFIG_OPTION_CHUNK_SIZE);
        const { configOptionName, hide } = columns[columnId];

        return (
          <div key={columnId} className={className.draggableItem} style={{ gridColumnStart: chunkIndex }}>
            {/* Drag Icon */}
            <DragSmall className={className.dragIcon} />

            {/* Column Name */}
            <div className={className.columnName}>{configOptionName}</div>

            {/* Toggle Switch */}
            <ToggleSwitch
              size="small"
              className={className.toggleSwitch}
              descriptions={{ id: columnId, checked: '', unchecked: '' }}
              checked={!hide}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(function user_click_column_config_toggle_switch(s) {
                  const column = s.columns[columnId];
                  column.hide = !column.hide;
                });
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

// ====================================
// Internal Actions
// ====================================
export function update_columns_order<RowData>(s: DataTableState<RowData>): void {
  let i = 0;

  // Left Unconfigurable Columns
  const leftUnconfigurableColumnIds: string[] = [];
  while (i < s.columnIds.length && s.columns[s.columnIds[i]].configOptionName === '') {
    leftUnconfigurableColumnIds.push(s.columnIds[i]);
    i++;
  }

  // Skip Middle Configurable Columns
  while (i < s.columnIds.length && s.columns[s.columnIds[i]].configOptionName !== '') {
    i++;
  }

  // Right Unconfigurable Columns
  const rightUnconfigurableColumnIds: string[] = [];
  while (i < s.columnIds.length && s.columns[s.columnIds[i]].configOptionName === '') {
    rightUnconfigurableColumnIds.push(s.columnIds[i]);
    i++;
  }

  if (i !== s.columnIds.length) {
    // prettier-ignore
    throw new Error('Unconfigurable columns between configurable ones are not allowed. Please fix the DataTable column order.');
  }

  // update column ids
  s.columnIds = [
    ...leftUnconfigurableColumnIds,
    ...s.columnConfig.configurableColumnIds,
    ...rightUnconfigurableColumnIds,
  ];
}
