import rawContents from "!!raw-loader!./ListControlled.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ListControlled():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="List Controlled" />;
}

export default ListControlled;
