import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVaccineSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.78006 12.2202C3.30053 11.7807 3.01367 11.1697 2.98184 10.52C2.95001 9.87034 3.17575 9.23425 3.61002 8.75L9.81009 2.5498L13.45 6.18994L7.25003 12.3901C6.76578 12.8244 6.12981 13.0499 5.48013 13.0181C4.83046 12.9862 4.21955 12.6997 3.78006 12.2202ZM3.78006 12.2202L1 15M13 1L15 3M11.63 4.37012L13.18 2.81982M4.5 8H11.5"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgVaccineSmall.displayName = 'SvgVaccineSmall';
const VaccineSmall = withForgeIconProps(SvgVaccineSmall, {
  name: 'Vaccine',
  usage: 'Use to indicate a vaccine, or to view or edit vaccine information',
  related: 'vaccination, vaccines, shot, injection',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default VaccineSmall;
