import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFlagSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2 9.5H14L11.9 5.3999L14 1H5C4.20435 1 3.44127 1.31605 2.87866 1.87866C2.31605 2.44127 2 3.20435 2 4V9.5ZM2 9.5V15"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgFlagSmall.displayName = 'SvgFlagSmall';
const FlagSmall = withForgeIconProps(SvgFlagSmall, {
  name: 'Flag',
  usage: 'Use to mark information for follow up',
  related: 'attention, pin, flagged',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default FlagSmall;
