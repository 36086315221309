import { loremIpsum } from 'lorem-ipsum';

import rawContents from "!!raw-loader!./ComplexStyleModal.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { loremIpsum };

function ComplexStyleModal():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Complex Style Modal" />;
}

export default ComplexStyleModal;
