import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVideoOnLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 8.24007C22.9876 8.00018 22.9089 7.76804 22.7727 7.57014C22.6366 7.37225 22.4482 7.21634 22.2286 7.11897C22.009 7.02161 21.7669 6.98691 21.5288 7.01887C21.2907 7.05084 21.0661 7.1478 20.88 7.29964L18 9.99983V13.9998L20.86 16.68C21.0461 16.8318 21.2707 16.9288 21.5088 16.9608C21.7469 16.9927 21.9892 16.958 22.2087 16.8607C22.4283 16.7633 22.6165 16.6074 22.7527 16.4095C22.8888 16.2116 22.9675 15.9799 22.98 15.7401L23 8.24007Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M1 5H18V16C18 16.7956 17.6839 17.5585 17.1213 18.1211C16.5587 18.6837 15.7956 19 15 19H1V5Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgVideoOnLarge.displayName = 'SvgVideoOnLarge';
const VideoOnLarge = withForgeIconProps(SvgVideoOnLarge, {
  name: 'VideoOn',
  usage: 'Use to indicate enabled video, or to turn off video',
  related: 'video, camera',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default VideoOnLarge;
