import { NumericRangeHandlerProps } from './NumericRangeInputHandlers';
import * as NR from './NumericRangeInputTypes';
import { forgeClassHelper } from '../utils/classes';

export const classes = forgeClassHelper({ name: 'numeric-range-input' });

/** Can the passed parameter be a valid number? */
export const isNumeric = (value: number | string | undefined): boolean => {
  if (value === undefined) return false;
  if (typeof value === 'number') return true;
  return !isNaN(parseFloat(value));
};

export const getNumber = (value: string | number): number => {
  const num = typeof value === 'number' ? value : parseFloat(value);
  return num;
};

/** This function takes a string and ensures it is acceptable to the UI. */
export const fixEventText = (isCurrency: boolean, isInteger: boolean, value: string): string => {
  if (value !== null && !isCurrency) {
    if (isInteger) {
      // Remove extraneous negative signs but keep decimal.  It will be handled next.
      value =
        value.charAt(0) === '-' ? '-' + value.substring(1).replace(/[^0-9\\.]/g, '') : value.replace(/[^0-9\\.]/g, '');

      value = Math.round(parseFloat(value)).toFixed(0);
    } else {
      // If floating point is allowed, get rid of any extra "." or "-".
      let badCharIndex = value.indexOf('.'); //Actually, good.  The first decimal point.
      if (badCharIndex !== -1 && (badCharIndex = value.indexOf('.', badCharIndex + 1)) !== -1) {
        // Is there a second decimal point?
        value = value.substring(0, badCharIndex); // Keep all until second decimal point.
      }
      value =
        value.charAt(0) === '-' ? '-' + value.substring(1).replace(/[^0-9.]/g, '') : value.replace(/[^0-9.]/g, '');
    }
  }

  return value;
};

/** Helper function to display a formatted message with a currency value. */
export const displayCurrencyMsg = (msgFormat: string, amount: number | string | undefined): string => {
  if (amount === undefined) {
    // This code should not be reached since the amount had to have been one of two
    // defined numbers that were compared with each other in order to even be here.
    return '';
  }

  // athenanet only deals with American dollars!
  const dollars = getNumber(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  // string.format() does not exist!
  return msgFormat.replace('{0}', dollars);
};

/** Helper function to create a new NR.NumericRangeInputValues object for the current values. */
export const getNewValues = (
  args: NumericRangeHandlerProps,
  id: string,
  newValue: boolean | string | undefined
): NR.NumericRangeInputValues => {
  const { allValues, ids } = args;

  if (id === ids[0]) {
    return {
      ...allValues,
      min: typeof newValue === 'number' || typeof newValue === 'string' ? getNumber(newValue) : undefined,
    };
  } else if (id === ids[1]) {
    return {
      ...allValues,
      max: typeof newValue === 'number' || typeof newValue === 'string' ? getNumber(newValue) : undefined,
    };
  } else {
    return { ...allValues, checked: typeof newValue === 'boolean' ? newValue : undefined };
  }
};

/** Fake class to return to the caller of an HTML event in NumericRange. */
export class FakeEvent implements NR.FakeEvent {
  private _noop: () => void;
  target: NR.NumericRangeEventProps;
  stopPropagation: () => void;
  preventDefault: () => void;
  persist: () => void;
  constructor(target: NR.NumericRangeEventProps) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._noop = () => {};
    this.target = {
      value: target.value,
      rawValue: target.rawValue,
      id: target.id,
    }; // Shape it like the fake events from Cleave
    // Methods to better resemble a SyntheticEvent for compatibility with Redux-Form
    this.stopPropagation = this._noop;
    this.preventDefault = this._noop;
    this.persist = this._noop;
  }
}
