import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUnderlineLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M1 22H23V24H1V22Z" fill={props.color} />
    <path
      d="M12.0172 20C10.7121 20 9.533 19.8398 8.4798 19.5195C7.44949 19.1792 6.55657 18.6487 5.80101 17.9279C5.06835 17.2072 4.49596 16.2863 4.08384 15.1652C3.69461 14.024 3.5 12.6426 3.5 11.021V0H7.48384V11.2913C7.48384 12.3524 7.58687 13.2533 7.79293 13.994C8.02188 14.7147 8.33098 15.2953 8.7202 15.7357C9.13232 16.1762 9.61313 16.4965 10.1626 16.6967C10.7121 16.8769 11.3303 16.967 12.0172 16.967C12.704 16.967 13.3337 16.8769 13.9061 16.6967C14.4784 16.4965 14.9707 16.1762 15.3828 15.7357C15.7949 15.2953 16.1155 14.7147 16.3444 13.994C16.5734 13.2533 16.6879 12.3524 16.6879 11.2913V0H20.5V11.021C20.5 12.6426 20.3054 14.024 19.9162 15.1652C19.5269 16.2863 18.9545 17.2072 18.199 17.9279C17.4663 18.6487 16.5734 19.1792 15.5202 19.5195C14.4899 19.8398 13.3222 20 12.0172 20Z"
      fill={props.color}
    />
  </svg>
);
SvgUnderlineLarge.displayName = 'SvgUnderlineLarge';
const UnderlineLarge = withForgeIconProps(SvgUnderlineLarge, {
  name: 'Underline',
  usage:
    'Use in a ComboButton or SegmentedButton to add or remove underline text formatting',
  related: 'bold, font, underline, text, edit, text editor',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default UnderlineLarge;
