import Uncontrolled from './Uncontrolled.gen';
import Controlled from './Controlled.gen';
import CheckableUncontrolled from './CheckableUncontrolled.gen';
import CheckableControlled from './CheckableControlled.gen';
import CustomNodes from './CustomNodes.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <Uncontrolled />
      <Controlled />
      <CheckableUncontrolled />
      <CheckableControlled />
      <CustomNodes />
    </>
  );
}
