import dayjs from 'dayjs';
import DateInput from '../../../../DateInput';
import { DataTableFilter, CreateDataFilterCommonArgs } from '../../../utils/internalTypes';

type CreateDateInputFilterArgs<RowData> = CreateDataFilterCommonArgs<RowData> & {
  serverSideQueryParamFn?: (date: Date) => string;
};
export type CreateDateInputFilter<RowData> = typeof createDateInputFilter<RowData>;

export function createDateInputFilter<RowData>({
  filterId,
  filterLabel,
  rowDataFieldName,
  serverSideQueryParamFn,
}: CreateDateInputFilterArgs<RowData>): DataTableFilter<RowData, Date> {
  return {
    filterId,
    filterLabel,
    serverSideQueryParamFn:
      typeof serverSideQueryParamFn !== 'undefined'
        ? ({ conditionValues }) => serverSideQueryParamFn(conditionValues[0])
        : undefined,
    clientSideFilterFn: ({ rowData, conditionValues }) => {
      return dayjs(rowData[rowDataFieldName] as Date | string) // prettier-break-line
        .isSame(dayjs(conditionValues[0]));
    },

    DataFilterComponent: ({ useSelector, onFilterConditionValuesChange }) => {
      // Each input component has distinct EMPTY_STATE, such as '', undefined, null, ...
      const EMPTY_STATE = null;
      const conditionValues = useSelector((s) => s.dataFilter.filters[filterId]?.conditionValues ?? []) as Date[];

      return (
        <DateInput
          value={conditionValues.length === 0 ? EMPTY_STATE : conditionValues[0]}
          onChange={(e) => {
            const value = e.target.value;
            onFilterConditionValuesChange(value === EMPTY_STATE ? [] : [value]);
          }}
        />
      );
    },

    describeConditionValue: (conditionValue) => {
      return dayjs(conditionValue).format('MM-DD-YYYY');
    },
  };
}
