import { DataTableState } from '../store/stateTypes';
import { hasAppliedColumnFiltersFromState } from './hasAppliedColumnFiltersFromState';

export function hasControlPanelFromState<RowData>(s: DataTableState<RowData>): boolean {
  return (
    s.bulkEdit.layout === 'button' ||
    s.bulkEdit.layout === 'menu' ||
    typeof s.CustomBulkEditComponent !== 'undefined' ||
    s.editTableButton.show ||
    s.deletedRowsCheckbox.show ||
    s.searchBox.show ||
    s.columnConfig.enable ||
    hasAppliedColumnFiltersFromState(s) ||
    (s.pagination.layout !== 'none' &&
      (s.pagination.paginatorLocation === 'top-right' || s.pagination.paginatorLocation === 'top-and-bottom')) ||
    s.entityName.showInControlPanel
  );
}
