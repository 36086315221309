import { ReactNode } from 'react';
import List from '../../List';
import { forgeClassHelper } from '../../utils/classes';
import { FileUploadAction, FileUploadFile } from '../fileUploadReducer';
import FileUploadError from './FileUploadError';
import FileUploadInProgress from './FileUploadInProgress';

const classes = forgeClassHelper('file-upload');

export interface FIleUploadIncompleteProps {
  dispatch: React.Dispatch<FileUploadAction>;
  files: ReadonlyArray<FileUploadFile>;
}

/** Renders files that are either actively uploading, or encountered an error
 * while uploading */
const FileUploadIncomplete = ({ dispatch, files }: FIleUploadIncompleteProps): ReactNode => {
  return (
    files.length > 0 && (
      <List {...classes({ element: 'incomplete-list' })}>
        {files.map((f) =>
          f.errorMessage ? (
            <FileUploadError key={f.id} dispatch={dispatch} file={f} />
          ) : (
            <FileUploadInProgress key={f.id} dispatch={dispatch} file={f} />
          )
        )}
      </List>
    )
  );
};

export default FileUploadIncomplete;
