import rawContents from "!!raw-loader!./MultiFieldCustomInputs.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function MultiFieldCustomInputs():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Multi Field Custom Inputs" />;
}

export default MultiFieldCustomInputs;
