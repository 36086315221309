import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMailLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 6L12 12L23 6M1 3H23V18C23 18.7956 22.6839 19.5587 22.1213 20.1213C21.5587 20.6839 20.7956 21 20 21H1V3Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgMailLarge.displayName = 'SvgMailLarge';
const MailLarge = withForgeIconProps(SvgMailLarge, {
  name: 'Mail',
  usage: 'Use to open an email or inbox, or to indicate an email address',
  related: 'envelope',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default MailLarge;
