import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBusLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.47013 4H18.5299C18.2473 3.6923 17.8212 3.37179 17.2096 3.066C15.928 2.4252 14.0876 2 12 2C9.91244 2 8.07196 2.4252 6.79036 3.066C6.17878 3.37179 5.75272 3.6923 5.47013 4ZM18.9995 6H5.00047L5 11H19L18.9995 6ZM19 13H5V19H19V13ZM21 5.875V5C21 3.3126 19.6581 2.05416 18.1041 1.27715C16.4902 0.470229 14.3307 0 12 0C9.66928 0 7.50977 0.470229 5.89593 1.27715C4.34191 2.05416 3 3.3126 3 5V5.875C2.44772 5.875 2 6.32272 2 6.875V9.6875C2 10.2398 2.44772 10.6875 3 10.6875V21H6V23C6 23.5523 6.44772 24 7 24C7.55228 24 8 23.5523 8 23V21H16V23C16 23.5523 16.4477 24 17 24C17.5523 24 18 23.5523 18 23V21H20C20.5523 21 21 20.5523 21 20V10.6875C21.5523 10.6875 22 10.2398 22 9.6875V6.875C22 6.32272 21.5523 5.875 21 5.875Z"
      fill={props.color}
    />
    <path
      d="M10 16C10 16.8284 9.32843 17.5 8.5 17.5C7.67157 17.5 7 16.8284 7 16C7 15.1716 7.67157 14.5 8.5 14.5C9.32843 14.5 10 15.1716 10 16Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 17.5C9.32843 17.5 10 16.8284 10 16C10 15.1716 9.32843 14.5 8.5 14.5C7.67157 14.5 7 15.1716 7 16C7 16.8284 7.67157 17.5 8.5 17.5Z"
      fill={props.color}
    />
    <path
      d="M17 16C17 16.8284 16.3284 17.5 15.5 17.5C14.6716 17.5 14 16.8284 14 16C14 15.1716 14.6716 14.5 15.5 14.5C16.3284 14.5 17 15.1716 17 16Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 17.5C16.3284 17.5 17 16.8284 17 16C17 15.1716 16.3284 14.5 15.5 14.5C14.6716 14.5 14 15.1716 14 16C14 16.8284 14.6716 17.5 15.5 17.5Z"
      fill={props.color}
    />
  </svg>
);
SvgBusLarge.displayName = 'SvgBusLarge';
const BusLarge = withForgeIconProps(SvgBusLarge, {
  name: 'Bus',
  usage:
    'Use to indicate transportation or transit, access to transportation, or other related issues',
  related: 'transit, bus, transportation, car',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default BusLarge;
