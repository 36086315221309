import rawContents from "!!raw-loader!./FileUploadWithMockApplication.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function FileUploadWithMockApplication():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="File Upload With Mock Application" />;
}

export default FileUploadWithMockApplication;
