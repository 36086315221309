import algoliasearch from 'algoliasearch';
import { Hit } from '@algolia/client-search';

export interface SearchResult {
  type: 'prop' | 'page' | 'faq' | 'icon' | 'guidelines' | 'gettingStarted' | 'community';
  path: string | null;
  summary: string;
  title: string;
  component: string;
  propDetails: {
    required: boolean;
    defaultValue: null | {
      value: string;
    };
    type: {
      name: string;
    };
  };
  isComponent: boolean;
  drupalInternalNodeId: string;
  fullText: {
    fullyHighlighted: boolean;
    matchLevel: 'none' | 'partial' | 'full';
    matchedWords: string[];
    value: string;
  };
  entries: Hit<SearchResult>[];
}

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID || '',
  process.env.GATSBY_ALGOLIA_SEARCH_KEY || ''
);

const index = searchClient.initIndex(process.env.GATSBY_ALGOLIA_INDEX || 'forge-guide-dev');

function useSearch(): (query: string) => Promise<Hit<SearchResult>[]> {
  const search = async (query: string): Promise<Hit<SearchResult>[]> => {
    const results = await index.search<SearchResult>(query, {
      attributesToSnippet: ['summary:10'],
      snippetEllipsisText: '...',
    });

    return results.hits;
  };

  return search;
}
export default useSearch;
