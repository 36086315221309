import { ReactElement, useContext } from 'react';
import { PageContext } from '../../contexts';
import { GridCol, GridRow, Card } from '@athena/forge';
import { MenuItem } from '../../components/page-context-provider/page-context-provider';
import { Link } from 'gatsby';
import './secondary-menu-grid.scss';

export default function SecondaryMenuGrid(): ReactElement {
  const { pageContext, childParentMap, menuItems } = useContext(PageContext);

  if (pageContext && childParentMap && menuItems) {
    const parentPageId = pageContext.id && childParentMap[pageContext.id];
    const parentMenuItem = menuItems.find((menuItem: MenuItem) => menuItem.route.parameters.node === parentPageId);
    const childMenuItems = parentMenuItem?.childrenMenuItems || [];

    return (
      <section className="secondary-menu-grid">
        <GridRow>
          {childMenuItems.map((item) => (
            <GridCol width={{ small: 12, medium: 6, large: 4 }} key={item.title}>
              <Link to={item.url.replace('/forge', '')}>
                <Card
                  headingText={item.title}
                  onClick={() => {
                    return;
                  }}
                  className="secondary-menu-grid__card"
                >
                  <p className="secondary-menu-grid__card-description">{item.description}</p>
                </Card>
              </Link>
            </GridCol>
          ))}
        </GridRow>
      </section>
    );
  } else {
    console.error('Could not render Secondary menu grid');
    return <></>;
  }
}
