import rawContents from "!!raw-loader!./HighVolumeMultiSelect.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function HighVolumeMultiSelect():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="High Volume Multi Select" />;
}

export default HighVolumeMultiSelect;
