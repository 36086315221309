import React, { useEffect, useState } from 'react';
import { NumericRangeInputComponentProps, NumericRangeInputValues } from './NumericRangeInputTypes';
import {
  NumericRangeHandlerProps,
  NumericRangeInputIDs,
  callValidateValue,
  handleNumberChange,
  handleNumberBlur,
  handleNumberPaste,
  handleShowNoNumbers,
} from './NumericRangeInputHandlers';
import { getNumber, isNumeric } from './NumericRangeInputUtils';

/** Properties returned from useAppLogic(). */
export interface AppLogicProps {
  /** Created from useState().  A number containing the number of banner to be displayed. */
  bannerCount: number;
  /** An optional boolean that indicates if the first input is disabled. */
  disabledFirstValue?: boolean;
  /** An optional boolean that indicates if the second input is disabled. */
  disabledSecondValue?: boolean;
  /** Created from useState().  A string holding the value of the first input. */
  firstValue: string | undefined;
  /**
   * Created from useState().  A string holding the a validation message for the first input.
   * To be displayed in a banner. */
  firstValueCustomValidationMsg: string | null;
  /**
   * Created from useState().  A string holding the an error message for the first input.
   * To be displayed in a banner. */
  firstValueErrorMsg: string;
  /** The handler for the first input's onBlur. */
  handleFirstValueBlur: (ev: React.FocusEvent<HTMLInputElement, Element>) => void;
  /** The handler for the first input's onChange. */
  handleFirstValueChange: (ev: React.FocusEvent<HTMLInputElement, Element>) => void;
  /** The handler for the first input's onPaste. */
  handleFirstValuePaste: (ev: React.ClipboardEvent<HTMLInputElement>) => void;
  /** The handler for the second input's onBlur. */
  handleSecondValueBlur: (ev: React.FocusEvent<HTMLInputElement, Element>) => void;
  /** The handler for the second input's onChange. */
  handleSecondValueChange: (ev: React.FocusEvent<HTMLInputElement, Element>) => void;
  /** The handler for the second input's onPaste. */
  handleSecondValuePaste: (ev: React.ClipboardEvent<HTMLInputElement>) => void;
  /** The handler for the checkbox input's onChange. */
  handleShowNoNumbersChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  /** A boolean indicating whether the the custom validation message for the first input should be displayed. */
  hasFirstValueCustomValidationMsg: boolean;
  /** A boolean indicating whether the the custom validation message for the second input should be displayed. */
  hasSecondValueCustomValidationMsg: boolean;
  /** A boolean indicating whether the first input is greater than the maximum allowed value if one defined. */
  isFirstValueAfterMaxValue: boolean;
  /** A boolean indicating whether the first input is greater than the second input. */
  isFirstValueAfterSecondValue: boolean;
  /** A boolean indicating whether the first input is less than the minimum allowed value if one defined. */
  isFirstValueBeforeMinValue: boolean;
  /** A boolean indicating whether the second input is greater than the maximum allowed value if one defined. */
  isSecondValueAfterMaxValue: boolean;
  /** A boolean indicating whether the second input is less than the minimum allowed value if one defined. */
  isSecondValueBeforeMinValue: boolean;
  /** A boolean indicating whether the current first input value is valid. */
  isValidFirstValue: boolean;
  /** A boolean indicating whether the current second input value is valid. */
  isValidSecondValue: boolean;
  /** A number holding the maximum value allowed for the first value. */
  maxFirstValue?: number;
  /** A number holding the maximum value allowed for the second value. */
  maxSecondValue?: number;
  /** A number holding the minimum value allowed for the first value. */
  minFirstValue?: number;
  /** A number holding the minimum value allowed for the second value. */
  minSecondValue?: number;
  /**
   * An optional string holding the a value for the first input that was passed to this component from the caller.
   * It may be a state variable so that the caller can update the value of the first input externally. */
  passedFirstValue?: string;
  /**
   * An optional string holding the a value for the second input that was passed to this component from the caller.
   * It may be a state variable so that the caller can update the value of the first input externally. */
  passedSecondValue?: string;
  /**
   * An optional boolean holding the a value for the checkbox input that was passed to this component from the caller.
   * It may be a state variable so that the caller can update the value of the first input externally. */
  passedShowNoNumbersValue: boolean;
  /** An optional string containing text to show in the first input if it has no value and is unfocused. */
  placeholderFirstValue?: string;
  /** An optional string containing text to show in the second input if it has no value and is unfocused. */
  placeholderSecondValue?: string;
  /** An optional boolean that indicates if the first input is required to have a value. */
  requiredFirstValue?: boolean;
  /** An optional boolean that indicates if the second input is required to have a value. */
  requiredSecondValue?: boolean;
  /** Created from useState().  A string holding the value of the second input. */
  secondValue: string | undefined;
  /**
   * Created from useState().  A string holding the a validation message for the second input.
   * To be displayed in a banner. */
  secondValueCustomValidationMsg: string | null;
  /**
   * Created from useState().  A string holding the an error message for the second input.
   * To be displayed in a banner. */
  secondValueErrorMsg: string;
  /** Created from useState().  Update the number containing the number of banner to be displayed. */
  setBannerCount: React.Dispatch<React.SetStateAction<number>>;
  /** Created from useState().  Update the string containing the value of the first input. */
  setFirstValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  /** Created from useState().  Update the string containing an error message for the first input. */
  setFirstValueErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  /** Created from useState().  Update the string containing the value of the second input. */
  setSecondValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  /** Created from useState().  Update the string containing an error message for the second input. */
  setSecondValueErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  /** Created from useState().  Update the boolean containing the value of the checkbox input. */
  setShowNoNumbers: React.Dispatch<React.SetStateAction<boolean>>;
  /** Created from useState().  A boolean containing the state of the checkbox. */
  showNoNumbers: boolean;
  /** A boolean indicating that a banner indicating that the first input needs a value. */
  showRequiredBannerFirstValue: boolean;
  /** A boolean indicating that a banner indicating that the second input needs a value. */
  showRequiredBannerSecondValue: boolean;
}

export type UseAppLogicProps = Pick<
  NumericRangeInputComponentProps,
  'id' | 'onBlurAllValues' | 'onChangeAllValues' | 'validateMinValue' | 'validateMaxValue' | 'values'
> &
  Required<
    Pick<
      NumericRangeInputComponentProps,
      | 'isCurrency'
      | 'isInteger'
      | 'highestLowestValueForMin'
      | 'minMaxDisabledValues'
      | 'minMaxRequiredValues'
      | 'highestLowestValueForMax'
      | 'placeholderValues'
    >
  >;

export const useAppLogic = ({
  id,
  /** Is currency?  Defaults to false. */
  isCurrency,
  /** Use only integers.  Defaults to false.  Ignored if isCurrency is true. */
  isInteger,
  highestLowestValueForMin,
  /** The first and second inputs are not disabled */
  minMaxDisabledValues,
  /** The first and second inputs are not required */
  minMaxRequiredValues,
  highestLowestValueForMax,
  onBlurAllValues,
  onChangeAllValues,
  /** Text to show in the inputs if the first or second inputs have no value and are unfocused. */
  placeholderValues,
  validateMinValue,
  validateMaxValue,
  /** No default values and null values checkbox is false. */
  values,
}: UseAppLogicProps): AppLogicProps => {
  const {
    min: passedFirstValue,
    max: passedSecondValue,
    checked: passedShowNoNumbersValue = false,
  } = values as NumericRangeInputValues;
  const { lowest: minFirstValue, highest: maxFirstValue } = highestLowestValueForMin;
  const { lowest: minSecondValue, highest: maxSecondValue } = highestLowestValueForMax;
  const { min: disabledFirstValue, max: disabledSecondValue } = minMaxDisabledValues;
  const { min: requiredFirstValue, max: requiredSecondValue } = minMaxRequiredValues;
  const { min: placeholderFirstValue = 'Min', max: placeholderSecondValue = 'Max' } = placeholderValues;

  // Declare useState instances for the range values.  Default to any passed value.
  const [firstValue, setFirstValue] = useState<string | undefined>(
    passedFirstValue ? passedFirstValue.toString() : undefined
  );
  const [secondValue, setSecondValue] = useState<string | undefined>(
    passedSecondValue ? passedSecondValue.toString() : undefined
  );

  useEffect(() => {
    setFirstValue(passedFirstValue?.toString());
  }, [passedFirstValue, setFirstValue]);
  useEffect(() => {
    setSecondValue(passedSecondValue?.toString());
  }, [passedSecondValue, setSecondValue]);

  const [showNoNumbers, setShowNoNumbers] = useState(passedShowNoNumbersValue);
  const [isValidFirstValue, setIsValidFirstValue] = useState(true);
  const [isValidSecondValue, setIsValidSecondValue] = useState(true);
  const [firstValueErrorMsg, setFirstValueErrorMsg] = useState('');
  const [secondValueErrorMsg, setSecondValueErrorMsg] = useState('');
  const [firstValueCustomValidationMsg, setFirstValueCustomValidationMsg] = useState<string | null>(null);
  const [secondValueCustomValidationMsg, setSecondValueCustomValidationMsg] = useState<string | null>(null);
  const [bannerCount, setBannerCount] = useState(0);
  const allValues: NumericRangeInputValues = {
    min: firstValue ? getNumber(firstValue) : undefined,
    max: secondValue ? getNumber(secondValue) : undefined,
    checked: showNoNumbers,
  };
  // These are the HTML ids of the 3 inputs used in this control.  The last one concerns the
  // checkbox and it may not exist.
  const ids: NumericRangeInputIDs = [`${id}-min-number-id`, `${id}-max-number-id`, `${id}-showitemscbid`];

  // The following booleans help prevent
  // coding errors when error conditions are added or modified. The useEffects
  // check these to set a state variable controlling whether an entry field
  // has an error color associated with it.  These same values individually are
  // used to hide/show the banner messages corresponding to the associated entry
  // field.
  //
  // Booleans for whether the required banner should be shown.
  const showRequiredBannerFirstValue = !!(
    requiredFirstValue &&
    !showNoNumbers &&
    (firstValue === null || !isNumeric(firstValue))
  );
  const showRequiredBannerSecondValue = !!(
    requiredSecondValue &&
    !showNoNumbers &&
    (secondValue === null || !isNumeric(secondValue))
  );
  // Booleans to know if there is a custom validation message for either or both of the Inputs.
  const hasFirstValueCustomValidationMsg =
    firstValueCustomValidationMsg !== null && firstValueCustomValidationMsg.length > 0;
  const hasSecondValueCustomValidationMsg =
    secondValueCustomValidationMsg !== null && secondValueCustomValidationMsg.length > 0;
  // Booleans for before/after min/max values.
  const isFirstValueAfterMaxValue = !!(firstValue && maxFirstValue && getNumber(firstValue) > getNumber(maxFirstValue));
  const isFirstValueBeforeMinValue = !!(
    firstValue &&
    minFirstValue &&
    getNumber(firstValue) < getNumber(minFirstValue)
  );
  const isSecondValueAfterMaxValue = !!(
    secondValue &&
    maxSecondValue &&
    getNumber(secondValue) > getNumber(maxSecondValue)
  );
  const isSecondValueBeforeMinValue = !!(
    secondValue &&
    minSecondValue &&
    getNumber(secondValue) < getNumber(minSecondValue)
  );
  // Boolean for the one check that most likely changes the most.
  const isFirstValueAfterSecondValue = !!(
    isValidFirstValue &&
    firstValue &&
    isValidSecondValue &&
    secondValue &&
    getNumber(firstValue) > getNumber(secondValue)
  );

  // With the exception of value, these arguments don't change.
  const firstValueHandlerProps: NumericRangeHandlerProps = {
    allValues,
    ids,
    isCurrency,
    isInteger,
    onBlurAllValues,
    onChangeAllValues,
    setCustomValidationMsg: setFirstValueCustomValidationMsg,
    setIsValidValue: setIsValidFirstValue,
    setShowNoNumbers,
    setValue: setFirstValue,
    validateValue: validateMinValue,
    value: undefined,
  };
  const secondValueHandlerProps: NumericRangeHandlerProps = {
    allValues,
    ids,
    isCurrency,
    isInteger,
    onBlurAllValues,
    onChangeAllValues,
    setCustomValidationMsg: setSecondValueCustomValidationMsg,
    setIsValidValue: setIsValidSecondValue,
    setShowNoNumbers,
    setValue: setSecondValue,
    validateValue: validateMaxValue,
    value: undefined,
  };

  // These functions deal with custom validation.  Call them and update internal custom messages.
  const callValidateMinValue = (value?: string): void => {
    callValidateValue({ ...firstValueHandlerProps, value: value });
  };
  const callValidateMaxValue = (value?: string): void => {
    callValidateValue({ ...secondValueHandlerProps, value: value });
  };

  // Assign to the respective NumericRangeHandlerProps since they have just been declared!
  firstValueHandlerProps.callValidateValue = callValidateMinValue;
  secondValueHandlerProps.callValidateValue = callValidateMaxValue;

  // These functions deal with loss of focus from the Input edits.
  const handleFirstValueBlur = (ev: React.FocusEvent<HTMLInputElement, Element>): void => {
    handleNumberBlur(firstValueHandlerProps, ev);
  };
  const handleSecondValueBlur = (ev: React.FocusEvent<HTMLInputElement, Element>): void => {
    handleNumberBlur(secondValueHandlerProps, ev);
  };

  // These functions deal with changes in the Input edits.
  const handleFirstValueChange = (ev: React.FocusEvent<HTMLInputElement, Element>): void => {
    handleNumberChange(firstValueHandlerProps, ev);
  };
  const handleSecondValueChange = (ev: React.FocusEvent<HTMLInputElement, Element>): void => {
    handleNumberChange(secondValueHandlerProps, ev);
  };

  // These functions deal with pasting into the Input edits.
  const handleFirstValuePaste = (ev: React.ClipboardEvent<HTMLInputElement>): void => {
    handleNumberPaste(firstValueHandlerProps, ev);
  };
  const handleSecondValuePaste = (ev: React.ClipboardEvent<HTMLInputElement>): void => {
    handleNumberPaste(secondValueHandlerProps, ev);
  };

  // This toggles "Show items with no quantity" (default) checkbox.
  // Use only if one or both of the numbers are required.
  const handleShowNoNumbersChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    handleShowNoNumbers(firstValueHandlerProps, ev);
  };

  return {
    bannerCount,
    disabledFirstValue,
    disabledSecondValue,
    firstValue,
    firstValueCustomValidationMsg,
    firstValueErrorMsg,
    handleFirstValueBlur,
    handleFirstValueChange,
    handleFirstValuePaste,
    handleSecondValueBlur,
    handleSecondValueChange,
    handleSecondValuePaste,
    handleShowNoNumbersChange,
    hasFirstValueCustomValidationMsg,
    hasSecondValueCustomValidationMsg,
    isFirstValueAfterMaxValue,
    isFirstValueAfterSecondValue,
    isFirstValueBeforeMinValue,
    isSecondValueAfterMaxValue,
    isSecondValueBeforeMinValue,
    isValidFirstValue,
    isValidSecondValue,
    maxFirstValue,
    maxSecondValue,
    minFirstValue,
    minSecondValue,
    passedFirstValue: passedFirstValue ? passedFirstValue.toString() : undefined,
    passedSecondValue: passedSecondValue ? passedSecondValue.toString() : undefined,
    passedShowNoNumbersValue,
    placeholderFirstValue,
    placeholderSecondValue,
    requiredFirstValue,
    requiredSecondValue,
    secondValue,
    secondValueCustomValidationMsg,
    secondValueErrorMsg,
    setBannerCount,
    setFirstValue,
    setFirstValueErrorMsg,
    setSecondValue,
    setSecondValueErrorMsg,
    setShowNoNumbers,
    showNoNumbers,
    showRequiredBannerFirstValue,
    showRequiredBannerSecondValue,
  };
};

export default useAppLogic;
