import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStartScreenShareSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0.99939H0V1.99939V10.9994V11.9994H1H7V12.9994H5C4.44772 12.9994 4 13.4471 4 13.9994C4 14.5517 4.44772 14.9994 5 14.9994H8H11C11.5523 14.9994 12 14.5517 12 13.9994C12 13.4471 11.5523 12.9994 11 12.9994H9V11.9994H12C14.2091 11.9994 16 10.2085 16 7.99939H14C14 9.10396 13.1046 9.99939 12 9.99939H8H2V2.99939H9V0.99939H1ZM10 1.99939C10 2.55167 10.4477 2.99939 11 2.99939H12.5858L10.2929 5.29228C9.90237 5.68281 9.90237 6.31597 10.2929 6.7065C10.6834 7.09702 11.3166 7.09702 11.7071 6.7065L14 4.4136V5.99939C14 6.55167 14.4477 6.99939 15 6.99939C15.5523 6.99939 16 6.55167 16 5.99939V1.99939C16 1.4471 15.5523 0.99939 15 0.99939H11C10.4477 0.99939 10 1.4471 10 1.99939Z"
      fill={props.color}
    />
  </svg>
);
SvgStartScreenShareSmall.displayName = 'SvgStartScreenShareSmall';
const StartScreenShareSmall = withForgeIconProps(SvgStartScreenShareSmall, {
  name: 'StartScreenShare',
  usage: 'Use to initiate a screenshare',
  related: 'Desktop',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default StartScreenShareSmall;
