import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAttachmentSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.50018 5.42666L7.94767 6.96591L5.37364 9.51484C5.12326 9.80329 4.9917 10.1756 5.00538 10.557C5.01906 10.9384 5.17697 11.3005 5.44737 11.5703C5.71776 11.8402 6.08049 11.9978 6.46265 12.0114C6.8448 12.0251 7.218 11.8938 7.50703 11.6439L12.8455 6.34618L13.146 6.04638C13.6935 5.48629 14 4.7347 14 3.9522C14 3.16971 13.6935 2.41824 13.146 1.85815C12.5843 1.30871 11.8292 1.00098 11.0427 1.00098C10.2562 1.00098 9.50103 1.30871 8.93936 1.85815L8.5887 2.21798L3.3004 7.46569C2.88824 7.87526 2.5611 8.36201 2.33794 8.89805C2.11478 9.43409 2 10.0089 2 10.5893C2 11.1698 2.11478 11.7446 2.33794 12.2806C2.5611 12.8166 2.88824 13.3034 3.3004 13.713C4.13828 14.5383 5.26804 15.001 6.44529 15.001C7.62253 15.001 8.75242 14.5383 9.59029 13.713L12.7754 10.5544"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgAttachmentSmall.displayName = 'SvgAttachmentSmall';
const AttachmentSmall = withForgeIconProps(SvgAttachmentSmall, {
  name: 'Attachment',
  usage: 'Use to indicate or add an attachment',
  related: 'attach, paperclip, clip, file',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default AttachmentSmall;
