import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLabsLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M18.0092 13.9989H5.99087M8.99541 8.9989V0.998901H15.0046V8.9989L20.1826 18.6588C20.4162 19.1251 20.524 19.6441 20.4955 20.1647C20.4671 20.6852 20.3033 21.1895 20.0202 21.6276C19.7371 22.0657 19.3447 22.4225 18.8814 22.663C18.4181 22.9034 17.9 23.0192 17.3782 22.9989H6.62178C6.10003 23.0192 5.58192 22.9034 5.11863 22.663C4.65535 22.4225 4.26288 22.0657 3.97981 21.6276C3.69674 21.1895 3.53293 20.6852 3.50447 20.1647C3.47601 19.6441 3.58383 19.1251 3.81745 18.6588L8.99541 8.9989Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <path
      d="M13.4771 17.7189C14.0293 17.7189 14.4771 17.2712 14.4771 16.7189C14.4771 16.1666 14.0293 15.7189 13.4771 15.7189C12.9248 15.7189 12.4771 16.1666 12.4771 16.7189C12.4771 17.2712 12.9248 17.7189 13.4771 17.7189Z"
      fill={props.color}
    />
    <path
      d="M15.4871 20.2789C16.0393 20.2789 16.4871 19.8312 16.4871 19.2789C16.4871 18.7266 16.0393 18.2789 15.4871 18.2789C14.9348 18.2789 14.4871 18.7266 14.4871 19.2789C14.4871 19.8312 14.9348 20.2789 15.4871 20.2789Z"
      fill={props.color}
    />
    <path
      d="M12.4871 21.2789C13.0393 21.2789 13.4871 20.8312 13.4871 20.2789C13.4871 19.7266 13.0393 19.2789 12.4871 19.2789C11.9348 19.2789 11.4871 19.7266 11.4871 20.2789C11.4871 20.8312 11.9348 21.2789 12.4871 21.2789Z"
      fill={props.color}
    />
  </svg>
);
SvgLabsLarge.displayName = 'SvgLabsLarge';
const LabsLarge = withForgeIconProps(SvgLabsLarge, {
  name: 'Labs',
  usage: 'Use to indicate or add lab information',
  related: 'result, results, bloodwork, cbc, test, tests, scans',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default LabsLarge;
