import { DataTableState } from '../store/stateTypes';

type getSelectedRowIdsProps<RowData> = {
  filteredAndSortedRowIds: number[];
  rows: DataTableState<RowData>['rows'];
};

export function getSelectedRowIds<RowData>({
  filteredAndSortedRowIds,
  rows,
}: getSelectedRowIdsProps<RowData>): number[] {
  return filteredAndSortedRowIds.filter((rid) => rows[rid]?.isSelected ?? false);
}
