import PropTypes from 'prop-types';
import React, { ReactElement, Ref, WeakValidationMap } from 'react';
import { forgeClassHelper } from '../utils/classes';
import { isUndefined } from '../utils/helpers';
import { ProgressIndicatorVariant } from './ProgressIndicator';

export interface LinearProgressProps {
  /** Aria attributes specific to determinate progress indicators, passed to the track wrapper */
  ariaAttributes?: {
    'aria-labelledby'?: string;
    'aria-valuemin'?: number;
    'aria-valuemax'?: number;
    'aria-valuenow'?: number;
  };
  /** Passthrough property from parent ProgressIndicator */
  baseClassname?: string;
  /** Custom class(es) to pass to the root of the component */
  className?: string;
  /** Only used in the 'determinate' variant. Value between 0 and 100 (inclusive). */
  currentValue?: number;
  /** Text description to accompany the progress indicator */
  description?: string;
  /** ID to use for the description element */
  descriptionId?: string;
  /** Passthrough property from parent ProgressIndicator */
  shape: string;
  /* Only used in the 'determinate' variant. Displays the current percentage. */
  showValueLabel?: boolean;
  /** The variant to display. Use 'indeterminate' for an unspecified or short wait time,
   * or 'determinate' for a known, longer wait time. */
  variant?: ProgressIndicatorVariant;
}

const LinearProgress = React.forwardRef(
  (
    {
      ariaAttributes,
      baseClassname,
      className,
      currentValue,
      description,
      descriptionId,
      shape,
      showValueLabel,
      variant,
      ...rest
    }: LinearProgressProps,
    ref: Ref<HTMLDivElement>
  ): ReactElement => {
    const classes = forgeClassHelper({ name: baseClassname });

    // creates inline style with line of width equal to currentValue
    const indicatorWidth =
      variant === 'determinate' && !isUndefined(currentValue) ? { style: { width: `${currentValue}%` } } : null;

    return (
      <div ref={ref} {...classes({ modifiers: shape, extra: className })} {...rest}>
        {description && (
          <div {...classes({ element: 'description' })} id={descriptionId}>
            {description}
          </div>
        )}

        <div role="progressbar" {...classes({ element: 'track-wrapper' })} {...ariaAttributes}>
          <div {...classes({ element: 'track' })}>
            <div {...classes({ element: 'indicator', modifiers: variant })} {...indicatorWidth}></div>
          </div>
          {variant === 'determinate' && showValueLabel && !isUndefined(currentValue) && (
            <div {...classes({ element: 'value' })}>{`${currentValue}%`}</div>
          )}
        </div>
      </div>
    );
  }
);
LinearProgress.displayName = 'LinearProgress';

export const LinearProgressPropTypes: WeakValidationMap<LinearProgressProps> = {
  /** Aria attributes specific to determinate progress indicators, passed to the track wrapper */
  ariaAttributes: PropTypes.object,
  /** Custom class(es) to pass to the root of the component */
  className: PropTypes.string,
  /** Only used in the 'determinate' variant. Value between 0 and 100 (inclusive). */
  currentValue: PropTypes.number,
  /** Text description to accompany the progress indicator */
  description: PropTypes.string,
  /** ID to use for the description element */
  descriptionId: PropTypes.string,
  /* Only used in the 'determinate' variant. Displays the current percentage. */
  showValueLabel: PropTypes.bool,
  /** The variant to display. Use 'indeterminate' for an unspecified or short wait time,
   * or 'determinate' for a known, longer wait time. */
  variant: PropTypes.oneOf(['determinate', 'indeterminate']),
};

LinearProgress.propTypes = LinearProgressPropTypes;

export default LinearProgress;
