import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHelpLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#9C28B1"
    />
    <path
      d="M12 15C11.9684 14.5759 12.0432 14.1507 12.2179 13.7629C12.3925 13.3752 12.6614 13.0373 13 12.78C13.62 12.25 14.42 11.9401 15 11.3601C15.3399 11.0028 15.6055 10.5817 15.7816 10.1211C15.9577 9.66048 16.0406 9.16937 16.0258 8.6765C16.0109 8.18362 15.8985 7.69863 15.6949 7.2495C15.4914 6.80036 15.2008 6.39616 14.84 6.06004C14.3391 5.60627 13.7336 5.28363 13.0776 5.12108C12.4216 4.95852 11.7356 4.96099 11.0808 5.1284C10.426 5.29581 9.82292 5.62284 9.32544 6.08031C8.82796 6.53778 8.45157 7.1115 8.22998 7.74999C8.12005 8.07319 8.05287 8.40937 8.03003 8.74999"
      stroke="white"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12 20C12.8284 20 13.5 19.3284 13.5 18.5C13.5 17.6716 12.8284 17 12 17C11.1716 17 10.5 17.6716 10.5 18.5C10.5 19.3284 11.1716 20 12 20Z"
      fill="white"
    />
  </svg>
);
SvgHelpLarge.displayName = 'SvgHelpLarge';
const HelpLarge = withForgeIconProps(SvgHelpLarge, {
  name: 'Help',
  usage:
    'Use to indicate presence of a Tooltip or Popover with helpful information. For use in alerts, not buttons; follow Forge interruption design guidance',
  related: 'alert, question, guide, tutorial',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default HelpLarge;
