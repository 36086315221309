import React, { ReactElement, RefObject, useLayoutEffect, useRef, useState } from 'react';
import { forgeClassHelper } from '../../utils/classes';
import { PushDrawerTypes } from './types';

const classes = forgeClassHelper('push-drawer-main-content');

interface MainContentProps {
  children: React.ReactNode;
  isOpen: boolean;
  layout: PushDrawerTypes.Layout;
  mediumLayoutSize: number;
  mode: PushDrawerTypes.DrawerMode;
  placement: PushDrawerTypes.Placement;
  pushContentAside: boolean;
  pushDrawerRef: RefObject<HTMLDivElement>;
  superCompactLayoutSize: number;
}

const MainContent = ({
  children,
  isOpen,
  layout,
  mediumLayoutSize,
  mode,
  placement,
  pushContentAside,
  pushDrawerRef,
  superCompactLayoutSize,
}: MainContentProps): ReactElement => {
  const sizeRef = useRef<{ width?: number; height?: number }>({});
  const size =
    mode === 'hidden' ? 0 : mode === 'persistent' && layout === 'medium' ? mediumLayoutSize : superCompactLayoutSize;
  const [style, setStyle] = useState({
    '--fe_c_push-drawer-width': size,
    '--fe_c_push-drawer-height': size,
  });

  useLayoutEffect(() => {
    if (isOpen && pushDrawerRef.current && pushContentAside) {
      const { width, height } = pushDrawerRef.current.getBoundingClientRect();
      sizeRef.current = { width, height };
      setStyle({ '--fe_c_push-drawer-width': width, '--fe_c_push-drawer-height': height });
    }
  }, [isOpen, pushDrawerRef, pushContentAside]);

  return (
    <div
      {...classes({
        modifiers: {
          [mode]: true,
          isOpen,
          [placement]: true,
          'no-push': !pushContentAside,
        },
      })}
      style={style}
    >
      {children}
    </div>
  );
};

export default MainContent;
