import { ReactElement } from 'react';
import './image.scss';

type ImageProps = {
  alt: string;
  field_image: string;
};

const Image = ({ alt, field_image }: ImageProps): ReactElement => {
  return <img alt={alt} className="drupal-image" src={field_image} />;
};

export default Image;
