import { useEffect } from 'react';

interface Props {
  keyCode: number;
  shouldListen: boolean;
  callback: () => void;
}

/** Call a function when a key is pressed */
const useKeyPress = ({ keyCode, shouldListen, callback }: Props): void => {
  useEffect(() => {
    const downHandler = (event: KeyboardEvent): void => {
      if (event.keyCode === keyCode) callback();
    };
    if (shouldListen) window.addEventListener('keydown', downHandler);
    return () => window.removeEventListener('keydown', downHandler);
  }, [callback, keyCode, shouldListen]);
};

export default useKeyPress;
