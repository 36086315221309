import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFishboneSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.44995 8L1 15M15 15L10.58 8M15 1L10.63 8H5.5L1.07996 1"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgFishboneSmall.displayName = 'SvgFishboneSmall';
const FishboneSmall = withForgeIconProps(SvgFishboneSmall, {
  name: 'Fishbone',
  usage: 'Use in a Button to open fishbone diagrams in clinical settings',
  related: 'data, results, lab, labs, bloodwork, cbc',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default FishboneSmall;
