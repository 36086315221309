import { Fragment, memo, ReactElement, useContext } from 'react';
import { Hit } from '@algolia/client-search';
import parse from 'html-react-parser';
import { Link } from 'gatsby';
import { SearchResult } from '../../hooks/useSearch';
import { defaultOptions } from '../../html-parsing-options';
import { componentImages, ComponentImages } from '../page-context-provider/component-images';
import { SVGIcons } from '../page-context-provider/page-context-provider';
import { PageContext } from '../../contexts';
import IconCombo from '../icon-table/icon-combo';
import { createDataTable } from '@athena/forge';

interface SearchBoxResultsProps {
  hits: Hit<SearchResult>[];
}

function formatTitle(hit: Hit<SearchResult>): ReactElement {
  const parsedTitle = hit._highlightResult?.title && parse(hit._highlightResult.title.value, defaultOptions());
  if (hit.isComponent) {
    return <span>Component | {parsedTitle}</span>;
  } else if (hit.type === 'icon') {
    return <span>Icon | {parsedTitle}</span>;
  } else if (hit.type === 'faq') {
    return <span>FAQ | {parsedTitle}</span>;
  } else {
    return <>{parsedTitle}</>;
  }
}

function getIconSvg(name: string, svgIcons: SVGIcons[] | undefined): ReactElement {
  const Large = svgIcons?.find((icon) => icon.name === `${name}Large`)?.svg.content;
  const Small = svgIcons?.find((icon) => icon.name === `${name}Small`)?.svg.content;
  return <IconCombo {...{ Small, Large }} />;
}

function getComponentSvg(hit: Hit<SearchResult>): ReactElement | undefined {
  const path = hit.path?.split('/');
  if (path?.length === 3 && path[1] === 'components') {
    //the following logic applies to components only
    return Object.prototype.hasOwnProperty.call(componentImages, hit.component) ? (
      componentImages[hit.component as keyof ComponentImages]
    ) : (
      <div className="forge-guide__search-result-contributed-component-svg">{componentImages.ContributedComponent}</div>
    );
  } else {
    return undefined;
  }
}
type RowData = {
  rowKey: string;
  title?: string | JSX.Element | JSX.Element[];
  type: string;
  required: boolean;
  defaultValue: string;
  description?: string | JSX.Element | JSX.Element[];
};
const DT = createDataTable<RowData>({ tableId: 'search-box-table' });

function SearchBoxResults({ hits }: SearchBoxResultsProps): ReactElement {
  const { svgIcons } = useContext(PageContext);

  const renderedHits = hits.map((hit, index) => {
    if (hit.type === 'prop') {
      const { required, defaultValue, type } = hit.propDetails;
      const propRow = {
        rowKey: `${hit.objectID}`,
        title: hit._highlightResult?.title && parse(hit._highlightResult.title.value),
        type: type.name,
        required,
        defaultValue: defaultValue === null ? '' : defaultValue.value,
        description: hit._snippetResult
          ? hit._snippetResult?.summary && parse(hit._snippetResult.summary.value)
          : hit._highlightResult?.summary && parse(hit._highlightResult.summary.value),
      };

      return (
        <li key={index} className="forge-guide__search-result-card">
          <Link to={`${hit.path}#props-tables`}>
            <h3 className="forge-guide__search-result-title">{`Component | ${hit.component} | Props`}</h3>
            <DT.DataTable
              tableType="client-side"
              tableLayout="compact"
              columns={[
                {
                  columnId: 'title',
                  Header: () => <>Name</>,
                  Cell: ({ useSelector, rowId }) => {
                    const value = useSelector((s) => s.rows[rowId].data);
                    const { title, required } = value;
                    return (
                      <>
                        {title}
                        {required && ' (required)'}
                      </>
                    );
                  },
                },
                DT.Column.createTextColumn({
                  rowDataFieldName: 'defaultValue',
                  columnId: 'defaultValue',
                  headerText: 'Default',
                }),
                DT.Column.createTextColumn({
                  rowDataFieldName: 'type',
                  columnId: 'type',
                  headerText: 'Type',
                }),
                DT.Column.createTextColumn({
                  rowDataFieldName: 'description',
                  columnId: 'description',
                  headerText: 'Description',
                }),
              ]}
              rowData={[propRow]}
            />
          </Link>
        </li>
      );
    } else if (hit.path) {
      const title = formatTitle(hit);
      const Svg = hit.type === 'icon' ? getIconSvg(hit.title, svgIcons) : getComponentSvg(hit);
      return (
        <li key={index} className="forge-guide__search-result-card">
          <Link to={hit.path}>
            <h3 className="forge-guide__search-result-title">{title}</h3>
            <div className="forge-guide__search-result-summary">
              {Svg && <div className={'forge-guide__search-result-image'}>{Svg}</div>}

              <span className="forge-guide__search-result-text">
                {hit._snippetResult
                  ? hit._snippetResult?.summary && parse(hit._snippetResult.summary.value)
                  : hit._highlightResult?.summary && parse(hit._highlightResult.summary.value)}
                {(hit.type === 'guidelines' || hit.type === 'gettingStarted' || hit.type === 'community') &&
                hit._highlightResult &&
                hit._highlightResult.fullText &&
                hit._highlightResult.fullText.matchedWords
                  ? parse('<em>' + hit._highlightResult?.fullText?.matchedWords?.join(' ') + '</em>')
                  : ''}
              </span>
            </div>
          </Link>
        </li>
      );
    } else {
      return <Fragment key={index} />;
    }
  });
  return <ul className="fe_u_list-style--no-bullet">{renderedHits}</ul>;
}

export default memo(SearchBoxResults);
