import { SegmentType } from '@react-stately/datepicker';
import { MutableRefObject, Ref, createContext } from 'react';
import { TimeInputSize } from '../TimeInput';
import { DateInputProps as BaseAriaDateInputProps } from 'react-aria-components';

export type AraDateInputSegmentNodes = Partial<Record<SegmentType, HTMLDivElement | null>>;

export interface AriaDateInputContextData extends Omit<BaseAriaDateInputProps, 'children'> {
  /** Class name to apply to the wrapper <div> */
  className?: string;
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Whether to style the input as required */
  isRequiredStyling?: boolean;
  /** Ref to the wrapper <div> */
  ref?: Ref<HTMLDivElement>;
  /** Ref to the DOM nodes for each SegmentType */
  refOfSegments?: MutableRefObject<AraDateInputSegmentNodes>;
  /** Ref to the last editable SegmentType */
  lastSegmentTypeRef?: MutableRefObject<SegmentType | undefined>;
  /** How large the input should be */
  size: TimeInputSize;
}

export const defaultAriaDateInputContext: AriaDateInputContextData = {
  size: 'large',
};
export const AriaDateInputContext = createContext(defaultAriaDateInputContext);
