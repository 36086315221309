import { DataTableSortDirection } from '../utils/internalTypes';
type CreateSortByNumber<RowData> = {
  rowDataFieldName: keyof RowData;
};
export function createSortByNumber<RowData>({ rowDataFieldName }: CreateSortByNumber<RowData>) {
  return (row1: RowData, row2: RowData, direction: DataTableSortDirection): number => {
    const val1 = row1[rowDataFieldName] as number | string;
    const val2 = row2[rowDataFieldName] as number | string;

    if (typeof val1 !== 'number' && val1 !== null && val1 !== '') {
      throw new Error(`The field (${val1}, ${val2}) should be number type or empty string.`);
    }

    if (typeof val2 !== 'number' && val2 !== null && val2 !== '') {
      throw new Error(`The field (${val1}, ${val2}) should be number type or empty string.`);
    }

    if (direction === 'asc') {
      if (val1 === null || val1 === '') return 1;
      if (val2 === null || val2 === '') return -1;

      return Number(val1) - Number(val2);
    }

    if (direction === 'desc') {
      if (val1 === null || val1 === '') return 1;
      if (val2 === null || val2 === '') return -1;

      return Number(val2) - Number(val1);
    }

    return 0;
  };
}
