import AllergyLarge from '@athena/forge-icons/dist/AllergyLarge';
import AllergySmall from '@athena/forge-icons/dist/AllergySmall';

import rawContents from "!!raw-loader!./semanticColor.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { AllergyLarge, AllergySmall };

function semanticColor():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Semantic Color" />;
}

export default semanticColor;
