import rawContents from "!!raw-loader!./TagPalette.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function TagPalette():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Tag Palette" />;
}

export default TagPalette;
