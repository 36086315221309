import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStandardScreenLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0H4H9V2H5V6H3V1V0ZM3 22.9994V18.0001H5V21.9994H9V23.9994H4H3V22.9994ZM15 2H18.9985V6H20.9985V1V0H19.9985H15V2ZM18.9985 21.9994V18.0001H20.9985V22.9994V23.9994H19.9985H15V21.9994H18.9985ZM8.01463 5H16.1024V18C16.1024 18.5523 15.6547 19 15.1024 19H8.01463V5ZM6.01463 3H8.01463H16.1024H18.1024V5V18C18.1024 19.6569 16.7593 21 15.1024 21H8.01463H6.01463V19V5V3Z"
      fill={props.color}
    />
  </svg>
);
SvgStandardScreenLarge.displayName = 'SvgStandardScreenLarge';
const StandardScreenLarge = withForgeIconProps(SvgStandardScreenLarge, {
  name: 'StandardScreen',
  usage: 'Use to change to semi-full screen view',
  related: 'Expand, screenshot, capture',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default StandardScreenLarge;
