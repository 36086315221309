import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNoteLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6 16H17M6 12H14M6 8H18M1 1H23V20C23 20.7956 22.6839 21.5587 22.1213 22.1213C21.5587 22.6839 20.7956 23 20 23H1V1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
SvgNoteLarge.displayName = 'SvgNoteLarge';
const NoteLarge = withForgeIconProps(SvgNoteLarge, {
  name: 'Note',
  usage: 'Use to indicate or edit a note',
  related: 'paper, sticky, add',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default NoteLarge;
