import { OverlayProps } from '@restart/ui/esm/Overlay';
import PropTypes from 'prop-types';
import React, {
  ReactElement,
  ReactHTMLElement,
  ReactNode,
  WeakValidationMap,
  isValidElement,
  useMemo,
  useRef,
  useState,
} from 'react';
import { themeContextPropTypes, useTheme } from '../Root/ThemeContext';
import { Theme } from '../Root/context';
import useForceInitialRerender from '../useForceInitialRerender';
import { forgeClassHelper } from '../utils/classes';
import TooltipOverlay from './TooltipOverlay';

const classes = forgeClassHelper({ name: 'tooltip', isPortal: true });

export type PlacementTypes =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';

export interface TooltipProps extends Omit<OverlayProps, 'children' | 'target'> {
  /** The target component, element, or text that will have the tooltip applied to it */
  children: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Used to associate the tooltip text with the child element */
  id: string;
  /** The placement of the tooltip around its target */
  placement?: PlacementTypes;
  /** Controls whether the tooltip is visible. When `undefined` tooltip is visible on hover. */
  showTip?: boolean;
  /** The text displayed in the tooltip */
  text: ReactNode;
  /** Theme variables for configuring an instance of Forge. See Root component for more details. */
  theme?: Theme;
  /** Underline the content getting a tooltip. */
  underline?: boolean;
  /** Adds a class to the outer element getting a tooltip. */
  wrapperClassName?: string;
}

function Tooltip({
  children,
  className,
  wrapperClassName,
  id,
  placement = 'top',
  showTip,
  text,
  theme: upstreamTheme,
  underline = false,
  ...passedProps
}: TooltipProps): ReactElement {
  const [showTipControlState, setShowTipControlState] = useState<boolean | undefined>(false);
  const targetRef = useRef<HTMLDivElement>(null);
  const theme = useTheme(upstreamTheme);

  const showTipControl = useMemo(() => {
    /** If text is falsey or boolean true, then the Tooltip has no meaningful
     * content to display. */
    if (!text || text === true) {
      return false;
      /** Prefer showTip prop over what is in state */
    } else if (showTip !== undefined) {
      return showTip;
    } else {
      return showTipControlState;
    }
  }, [showTip, showTipControlState, text]);

  /**
   * This implementation is a workaround for a an issue with restart-ui on initial render
   *
   * Specifically, its use of `usePopper` doesn't account for rootElement being
   * `null` on initial render. Thus, if `showTip=true` on initial render, the
   * Tooltip is rendered at the bottom of the screen until Overlay is re-rendered.
   */
  useForceInitialRerender();

  function handleShow(): void {
    if (showTip === undefined) {
      setShowTipControlState(true);
    }
  }
  function handleHide(): void {
    if (showTip === undefined) {
      setShowTipControlState(false);
    }
  }

  return (
    <div
      {...classes({
        element: 'wrapper',
        modifiers: { underline },
        extra: `${wrapperClassName ?? ''}`,
      })}
      ref={targetRef}
      onMouseEnter={handleShow}
      onFocus={handleShow}
      onMouseLeave={handleHide}
      onBlur={handleHide}
    >
      {React.Children.map(children, (child) => {
        if (isValidElement(child)) {
          return React.cloneElement(child as ReactHTMLElement<HTMLElement>, { 'aria-describedby': id });
        } else {
          return <span aria-describedby={id}>{child}</span>;
        }
      })}
      <TooltipOverlay
        className={className}
        theme={theme}
        show={showTipControl}
        flip={true}
        placement={placement}
        offset={[0, 10]}
        id={id}
        target={targetRef.current}
        {...passedProps}
      >
        {text}
      </TooltipOverlay>
    </div>
  );
}

const tooltipPropTypes: WeakValidationMap<TooltipProps & { '...rest': unknown }> = {
  id: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  placement: PropTypes.oneOf([
    'top',
    'bottom',
    'left',
    'right',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end',
  ]),
  showTip: PropTypes.bool,
  theme: themeContextPropTypes,
  underline: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  /** Passthrough props are passed through to the @restart/ui [Overlay](https://react-restart.github.io/ui/Overlay) a third party component used to position the tooltip */
  '...rest': PropTypes.any,
};
Tooltip.propTypes = tooltipPropTypes;

export default Tooltip;
