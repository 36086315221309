import { useRef } from 'react';
import FilterSmall from '@athena/forge-icons/dist/FilterSmall';
import FilterActiveSmall from '@athena/forge-icons/dist/FilterActiveSmall';
import Tooltip from '../../Tooltip';
import Popover from '../../Popover';
import Button from '../../Button';
import useClickOutside from '../../useClickOutside';
import { classes } from '../utils/dataTableClasses';
import { TOOLTIP_OPTIONS } from '../utils/constants';
import { filter_by_column_filters } from '../store/actions/filter_by_column_filters';
import { DataTableAction, DataTableState, DataTableStore } from '../store/stateTypes';
import { DataTableBlockUI } from '../utils/DataTableBlockUI';
import { hasRowsInEditStatusFromState } from '../utils/hasRowsInEditStatusFromState';

type ColumnFilterControllerProps<RowData> = {
  children: JSX.Element;
  columnId: string;
  store: DataTableStore<RowData>;
};

export function ColumnFilterController<RowData>({
  children,
  columnId,
  store,
}: ColumnFilterControllerProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const tableId = store.useSelector((s) => s.tableId);
  const columnFilter = store.useSelector((s) => s.columns[columnId]?.filter ?? { enable: false });
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));
  const filterIconButtonRef = useRef<HTMLButtonElement>(null);

  // Handle Popover Click Outside
  // https://guide.forge.athena.io/components/popover#demos
  const containerRef = useRef<HTMLDivElement>(null); // contain the filter 'icon' and 'popover'
  useClickOutside(containerRef, () => {
    if (columnFilter.isOpen) {
      dispatch(close_all_popovers_and_apply_column_filters);
    }
  });

  // column filter is disabled
  if (!columnFilter.enable) {
    return <></>;
  }

  const filterCount = columnFilter.conditionValues.length;
  return (
    <DataTableBlockUI block={hasRowsInEditStatus}>
      <div
        {...classes({ element: 'column-header__column-filter' })}
        ref={containerRef} // containerRef contain the both filter 'icon' and 'popover'
        //
        // Important Notes:
        // - The 'mousedown' event will occur prior to the 'click' event.
        // - By stopping the propagation of the 'mousedown' event on 'container' element, it prevents both the
        //   'mousedown outside popover' event and the 'click filter icon' event from being triggered simultaneously.
        onMouseDown={(e) => e.stopPropagation()}
      >
        {/* Column Filter Icon */}
        <Tooltip
          id={`${tableId}-column-filter-tooltip-${columnId}`}
          text={getColumnFilterTooltipText(filterCount)}
          showTip={filterCount === 0 ? TOOLTIP_OPTIONS.HIDE : TOOLTIP_OPTIONS.SHOW_WHEN_MOUSE_OVER}
        >
          <Button
            {...classes({ element: 'column-header__column-filter__button' })}
            ref={filterIconButtonRef}
            variant="tertiary"
            onClick={() => dispatch(user_click_column_filter_icon(columnId))}
          >
            <FilterIcon filterCount={filterCount} />
          </Button>
        </Tooltip>

        {/* Column Filter Popover */}
        <Popover
          targetRef={filterIconButtonRef}
          isOpen={columnFilter.isOpen}
          placement="bottom"
          onClose={() => dispatch(close_all_popovers_and_apply_column_filters)}
        >
          <div {...classes({ element: 'column-header__column-filter__popover' })}>
            {/* Column Filter */}
            <div>{children}</div>

            {/* Clear Filter Button */}
            <Button
              text="Clear Filter"
              variant="tertiary"
              disabled={filterCount === 0}
              onClick={() => dispatch(user_click_clear_column_filter_button(columnId))}
            />
          </div>
        </Popover>
      </div>
    </DataTableBlockUI>
  );
}

// ====================================
// Utils
// ====================================
function getColumnFilterTooltipText(filterCount: number): string {
  const s = filterCount === 1 ? '' : 's';
  return `${filterCount} active filter${s}`;
}

// ====================================
// FilterIcon Component
// ====================================
type FilterIconProps = {
  filterCount: number;
};
function FilterIcon({ filterCount }: FilterIconProps): JSX.Element {
  const FilterIcon = filterCount > 0 ? FilterActiveSmall : FilterSmall;
  return <FilterIcon title="" semanticColor="interactive" />;
}

// ====================================
// User Actions
// ====================================
function user_click_clear_column_filter_button<RowData>(columnId: string): DataTableAction<RowData> {
  return function user_click_clear_column_filter_button(s) {
    s.columns[columnId].filter.conditionValues = [];
    close_all_popovers_and_apply_column_filters(s);
  };
}

function user_click_column_filter_icon<RowData>(columnId: string): DataTableAction<RowData> {
  return function user_click_column_filter_icon(s) {
    const isAllClosed = Object.values(s.columns).every((c) => !c.filter.isOpen);
    if (isAllClosed) {
      // Simply open the popover. No column filters need to be applied.
      s.columns[columnId].filter.isOpen = true;
      return;
    }

    // Has opened popovers. Apply the column filters before open the popover.
    const isOpen = s.columns[columnId].filter.isOpen;
    close_all_popovers_and_apply_column_filters(s);
    s.columns[columnId].filter.isOpen = !isOpen;
  };
}

// ====================================
// Internal Actions
// ====================================
function close_all_popovers_and_apply_column_filters<RowData>(s: DataTableState<RowData>): void {
  // always close all the popovers before applying the column filters.
  for (const cid in s.columns) {
    s.columns[cid].filter.isOpen = false;
  }

  filter_by_column_filters(s);
}
