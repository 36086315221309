import AlertTypes from './AlertTypes.gen';
import LabResults from './LabResults.gen';
import MultipleSources from './MultipleSources.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <AlertTypes />
      <LabResults />
      <MultipleSources />
    </>
  );
}
