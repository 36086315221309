import React, { Ref, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import { FormFieldBaseInputProps } from '../FormField/FormFieldTypes';
import forwardRefToProps from '../utils/forwardRefToProps';
const classes = forgeClassHelper('read-only-input');

export interface ReadOnlyInputProps extends React.HTMLAttributes<HTMLDivElement>, FormFieldBaseInputProps {
  /** Adds a class to the root element of the component */
  className?: string;
  /** Adds disabled styling to the HTMLParagraphElement */
  disabled?: boolean;
  /** Text to be rendered in the read-only area */
  text?: string;
  /** Use children if more structured text is necessary */
  children?: React.ReactNode;
  /** Ref to the <div> that wraps the text */
  ref?: Ref<HTMLDivElement>;
}

interface ReadOnlyComponentProps extends ReadOnlyInputProps {
  /** Ref to the <div> that wraps the text */
  forwardedRef: Ref<HTMLDivElement>;
}

const ReadOnlyInput = ({
  /** Props from FormField that must be intercepted
   * given that they cannot be sent to a <div> */
  error,
  hideRequiredStyles,
  name,
  required,
  /** Only pass valid props below to div since this isn't a real 'field' */
  className,
  disabled,
  forwardedRef,
  text,
  children,
  ...inputProps
}: ReadOnlyComponentProps): React.ReactElement => {
  const newText =
    text !== undefined &&
    text.split('\n').map((item, i) => (
      <p {...classes({ element: 'text', states: { disabled: !!disabled } })} key={i}>
        {item === '' ? '\u00A0' : item}
      </p>
    ));

  return (
    <div
      ref={forwardedRef}
      role="note"
      {...classes({
        extra: className,
      })}
      {...inputProps}
    >
      {text && <>{newText}</>}
      {children}
    </div>
  );
};

const readOnlyInputPropTypes: WeakValidationMap<ReadOnlyInputProps & { '...rest': unknown }> = {
  /** Use children if more structured text is necessary */
  children: PropTypes.node,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Text to be rendered in the read-only area */
  text: PropTypes.string,
  /** Passthrough props to the component's root `<div>` element since `ReadOnlyInput` is not a real field */
  '...rest': PropTypes.any,
};
ReadOnlyInput.propTypes = readOnlyInputPropTypes;

export default forwardRefToProps(ReadOnlyInput);
