import MultiFieldBasic from './MultiFieldBasic.gen';
import MultiFieldCustomInputs from './MultiFieldCustomInputs.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <MultiFieldBasic />
      <MultiFieldCustomInputs />
    </>
  );
}
