import rawContents from "!!raw-loader!./ModalWithDisabledClose.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ModalWithDisabledClose():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Modal With Disabled Close" />;
}

export default ModalWithDisabledClose;
