import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';

interface PageMetadataProps {
  title?: string;
}

const PageMetadata = ({ title }: PageMetadataProps): ReactElement => (
  <Helmet>
    <title>{title + ' | Forge Design System | athenahealth'}</title>
    {/* For browsers that don't support .svg favicons (pretty much just Safari right now) */}
    <link rel="icon" href={withPrefix('/images/favicon-32x32.png')} type="image/png" />
    {/* .svg favicons gives us the ability to use media queries for dark mode */}
    <link rel="icon" href={withPrefix('/svg/favicon.svg')} type="image/svg+xml" />
    <link rel="manifest" href={withPrefix('/manifest.json')} />
    <link rel="apple-touch-icon" href={withPrefix('/images/apple-touch-icon.png')} />
  </Helmet>
);
export default PageMetadata;
