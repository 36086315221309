import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAddToDxLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM22.82 7C23.3723 7 23.82 6.55228 23.82 6C23.82 5.44772 23.3723 5 22.82 5V7ZM18.7499 0.295738C18.361 -0.096351 17.7278 -0.0988934 17.3357 0.29006C16.9436 0.679013 16.9411 1.31217 17.33 1.70426L18.7499 0.295738ZM23 6L23.7099 6.70426C24.0967 6.3144 24.0967 5.6856 23.7099 5.29574L23 6ZM17.33 10.2957C16.9411 10.6878 16.9436 11.321 17.3357 11.7099C17.7278 12.0989 18.361 12.0964 18.7499 11.7043L17.33 10.2957ZM1 7H22.82V5H1V7ZM17.33 1.70426L22.29 6.70426L23.7099 5.29574L18.7499 0.295738L17.33 1.70426ZM22.29 5.29574L17.33 10.2957L18.7499 11.7043L23.7099 6.70426L22.29 5.29574Z"
      fill={props.color}
    />
    <path
      d="M2.25 24V11H5.80005C7.53269 10.8972 9.237 11.4748 10.55 12.61C11.1532 13.2529 11.6158 14.0145 11.9084 14.8461C12.2011 15.6776 12.3174 16.561 12.25 17.44C12.2654 18.4047 12.1167 19.3651 11.8101 20.28C11.5499 21.0502 11.1231 21.7536 10.5601 22.34C9.99495 22.8983 9.31137 23.3221 8.56006 23.58C7.70762 23.872 6.811 24.0141 5.91003 24H2.25ZM4.59998 22.13H5.59998C6.1692 22.1674 6.73991 22.0852 7.27551 21.8889C7.81111 21.6926 8.29974 21.3864 8.70996 20.99C9.51556 19.9904 9.90592 18.7194 9.80005 17.44C9.85465 16.8161 9.78556 16.1875 9.5968 15.5904C9.40804 14.9932 9.10323 14.4392 8.69995 13.96C8.28335 13.5788 7.79407 13.2857 7.26135 13.0984C6.72863 12.9111 6.16348 12.8334 5.59998 12.87H4.59998V22.13Z"
      fill={props.color}
    />
    <path
      d="M12.75 24L15.85 18.79L13 14H15.46L16.5601 15.89L17 16.74C17.1467 17.0267 17.2966 17.3067 17.45 17.58H17.53C17.65 17.31 17.77 17.03 17.91 16.74L18.28 15.89L19.22 14H21.58L18.7 19L21.8 24H19.34L18.14 22C17.99 21.71 17.82 21.41 17.66 21.11L17.15 20.23H17L16.5699 21.1C16.4399 21.39 16.29 21.69 16.15 22L15.15 24H12.75Z"
      fill={props.color}
    />
  </svg>
);
SvgAddToDxLarge.displayName = 'SvgAddToDxLarge';
const AddToDxLarge = withForgeIconProps(SvgAddToDxLarge, {
  name: 'AddToDx',
  usage: 'Use to add a problem to a diagnosis',
  related: 'medical, issue, data',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default AddToDxLarge;
