import rawContents from "!!raw-loader!./FullWidthButton.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function FullWidthButton():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Full Width Button" />;
}

export default FullWidthButton;
