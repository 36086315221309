import SelectStandalone from './SelectStandalone.gen';
import MultiSelectInFormField from './MultiSelectInFormField.gen';
import SelectWithUserEnteredOptions from './SelectWithUserEnteredOptions.gen';
import SelectWithGroupedOptions from './SelectWithGroupedOptions.gen';
import SelectWithStyledStatusTag from './SelectWithStyledStatusTag.gen';
import SingleSelectSynchronous from './SingleSelectSynchronous.gen';
import MultiSelectAsynchronous from './MultiSelectAsynchronous.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <SelectStandalone />
      <MultiSelectInFormField />
      <SelectWithUserEnteredOptions />
      <SelectWithGroupedOptions />
      <SelectWithStyledStatusTag />
      <SingleSelectSynchronous />
      <MultiSelectAsynchronous />
    </>
  );
}
