import SkeletonText from './SkeletonText.gen';
import SkeletonCircle from './SkeletonCircle.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <SkeletonText />
      <SkeletonCircle />
    </>
  );
}
