import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAddDocumentSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6 14C6.55228 14 7 13.5523 7 13C7 12.4477 6.55228 12 6 12V14ZM2 12C1.44772 12 1 12.4477 1 13C1 13.5523 1.44772 14 2 14V12ZM5 11C5 10.4477 4.55228 10 4 10C3.44772 10 3 10.4477 3 11H5ZM3 15C3 15.5523 3.44772 16 4 16C4.55228 16 5 15.5523 5 15H3ZM9.70996 12H8.70996V14H9.70996V12ZM14 5H15V4.56515L14.682 4.2686L14 5ZM9.70996 1L10.3919 0.268602L10.1038 0H9.70996V1ZM4 1V0H3V1H4ZM3 7.66998V8.66998H5V7.66998H3ZM9 6H8C8 6.55228 8.44772 7 9 7V6ZM6 12H2V14H6V12ZM3 11V15H5V11H3ZM9.70996 14H11V12H9.70996V14ZM11 14C12.0608 14 13.0783 13.5786 13.8284 12.8284L12.4142 11.4142C12.0392 11.7893 11.5305 12 11 12V14ZM13.8284 12.8284C14.5786 12.0783 15 11.0608 15 10H13C13 10.5305 12.7893 11.0392 12.4142 11.4142L13.8284 12.8284ZM15 10V5H13V10H15ZM14.682 4.2686L10.3919 0.268602L9.02801 1.7314L13.318 5.7314L14.682 4.2686ZM9.70996 0H4V2H9.70996V0ZM3 1V7.66998H5V1H3ZM8 1V6H10V1H8ZM9 7H14V5H9V7Z"
      fill={props.color}
    />
  </svg>
);
SvgAddDocumentSmall.displayName = 'SvgAddDocumentSmall';
const AddDocumentSmall = withForgeIconProps(SvgAddDocumentSmall, {
  name: 'AddDocument',
  usage: 'Use to add a note to an item or entity',
  related: 'note, paper, file, attachment',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default AddDocumentSmall;
