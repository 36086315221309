import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDesktopLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 21V17M12 21H7M12 21H17M12 17H20C21.6569 17 23 15.6569 23 14V3H1V17H12Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
SvgDesktopLarge.displayName = 'SvgDesktopLarge';
const DesktopLarge = withForgeIconProps(SvgDesktopLarge, {
  name: 'Desktop',
  usage: 'Use to represent a computer',
  related: 'N/A',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default DesktopLarge;
