import Button from '../../../Button';
import RefreshSmall from '@athena/forge-icons/dist/RefreshSmall';
import { DataTableStore } from '../../store/stateTypes';

type ServerSideRefreshButtonProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function ServerSideRefreshButton<RowData>({ store }: ServerSideRefreshButtonProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();

  return (
    <Button
      aria-label="server-side-refresh-button"
      icon={RefreshSmall}
      variant="tertiary"
      onClick={() =>
        dispatch(function update_refresh_data_table(s) {
          s.forceFetchRowDataCounter++;
        })
      }
    />
  );
}
