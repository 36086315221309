import rawContents from "!!raw-loader!./Restricted.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Restricted():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Restricted" />;
}

export default Restricted;
