import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDivergeSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11 0C10.4477 0 10 0.447715 10 1C10 1.55228 10.4477 2 11 2V0ZM15 1L16 1C16 0.447715 15.5523 0 15 0V1ZM14 5C14 5.55229 14.4477 6 15 6C15.5523 6 16 5.55229 16 5H14ZM16 11C16 10.4477 15.5523 10 15 10C14.4477 10 14 10.4477 14 11H16ZM15 15V16C15.5523 16 16 15.5523 16 15H15ZM11 14C10.4477 14 10 14.4477 10 15C10 15.5523 10.4477 16 11 16V14ZM11 2H15V0H11V2ZM14 1L14 5H16L16 1L14 1ZM14 11V15H16V11H14ZM15 14H11V16H15V14ZM7.29288 8.7071L14.2929 15.7071L15.7071 14.2929L8.7071 7.29289L7.29288 8.7071ZM8.7071 8.7071L15.7071 1.70711L14.2929 0.292893L7.29289 7.29289L8.7071 8.7071ZM2 8H2V10C3.10457 10 4 9.10457 4 8H2ZM2 8L2 8H0C0 9.10457 0.89543 10 2 10V8ZM2 8H2V6C0.895431 6 0 6.89543 0 8H2ZM2 8L2 8H4C4 6.89543 3.10457 6 2 6V8ZM7.99999 7L3 7L3 9L7.99999 9L7.99999 7Z"
      fill={props.color}
    />
  </svg>
);
SvgDivergeSmall.displayName = 'SvgDivergeSmall';
const DivergeSmall = withForgeIconProps(SvgDivergeSmall, {
  name: 'Diverge',
  usage:
    'Use to represent the point at which a path or a journey splits into two paths',
  related: 'split, decision, junction',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default DivergeSmall;
