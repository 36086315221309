import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHelpSimpleLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.9721 17.9802C11.8888 17.2654 11.9831 16.5412 12.2465 15.8713C12.51 15.2014 12.9346 14.6064 13.4828 14.1391C14.8726 12.7918 16.6854 11.6857 17.5112 9.83557C17.9784 8.7937 18.1149 7.63411 17.9025 6.51246C17.69 5.39081 17.1387 4.36087 16.3227 3.56126C15.5511 2.87579 14.6197 2.39422 13.6137 2.16053C12.6077 1.92684 11.5591 1.94865 10.5637 2.22362C9.56818 2.49859 8.65751 3.01811 7.91486 3.73482C7.17221 4.45152 6.62131 5.3424 6.31226 6.32639C6.13776 6.86041 6.03277 7.41477 6 7.97554"
      stroke={props.color}
      strokeWidth={2.07}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12.0385 25C13.143 25 14.0385 24.1038 14.0385 22.9983C14.0385 21.8929 13.143 20.9967 12.0385 20.9967C10.9339 20.9967 10.0385 21.8929 10.0385 22.9983C10.0385 24.1038 10.9339 25 12.0385 25Z"
      fill={props.color}
    />
  </svg>
);
SvgHelpSimpleLarge.displayName = 'SvgHelpSimpleLarge';
const HelpSimpleLarge = withForgeIconProps(SvgHelpSimpleLarge, {
  name: 'HelpSimple',
  usage: 'Use in compact spaces, like mobile, to get helpful information',
  related: 'question, guide, tutorial, help',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default HelpSimpleLarge;
