import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInfoSimpleLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 9.76918C8.85572 8.58171 10.1393 7.42414 11.6617 7.99293C12.0316 8.12746 12.3582 8.35987 12.6069 8.66557C12.8557 8.97127 13.0173 9.33885 13.0746 9.72923C13.1453 10.3347 13.0525 10.9482 12.8059 11.5055C12.3183 12.693 11.8109 13.8705 11.2537 15.028C10.601 16.2856 10.1287 17.6294 9.85072 19.0195C9.71304 19.5072 9.67556 20.0177 9.74056 20.5202C9.80555 21.0228 9.97167 21.507 10.2288 21.9432C10.5574 22.3497 10.9911 22.6577 11.4824 22.8337C11.9738 23.0097 12.504 23.0469 13.0149 22.9411C14.2154 22.716 15.2839 22.0374 16 21.0452"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 4C13.1046 4 14 3.10458 14 2C14 0.895425 13.1046 0 12 0C10.8954 0 10 0.895425 10 2C10 3.10458 10.8954 4 12 4Z"
      fill={props.color}
    />
  </svg>
);
SvgInfoSimpleLarge.displayName = 'SvgInfoSimpleLarge';
const InfoSimpleLarge = withForgeIconProps(SvgInfoSimpleLarge, {
  name: 'InfoSimple',
  usage: 'Use in compact spaces, like mobile, to get more information',
  related: 'guide, tutorial',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default InfoSimpleLarge;
