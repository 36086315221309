import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ReactElement, useState, useEffect } from 'react';

import './login-modal.scss';
import { Button, Modal } from '@athena/forge';
import { loginRequest } from './../../authConfig';
import AthenaLogoWhite from './../../../static/svg/athena-logo-white.svg';

/** The forge guide used to have a feature that required authentication.
 * That feature has since been removed, but the code remains in case we have
 * a need for authentication again in a future feature.
 */
const LoginModal = (): ReactElement => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [showModal, setShowModal] = useState(false);
  const authenticationEnabled = process.env.GATSBY_FEATURE_FLAG_AUTHENTICATION === 'true';

  useEffect(() => {
    if (authenticationEnabled && inProgress === InteractionStatus.None) {
      setShowModal(!isAuthenticated);
    }
  }, [isAuthenticated, inProgress, authenticationEnabled]);

  const handleLogin = (): void => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <Modal
      show={showModal}
      headerText="Log In, Please!"
      onHide={() => setShowModal(false)}
      onPrimaryClick={() => setShowModal(false)}
      disableClose={true}
      includeButtonRow={false}
    >
      <div>
        <p>
          The Forge Guide now has a login step. This will allow us to add new features that are customized for you (like
          design score ratings and, in the future, discussion boards).
        </p>
        <p>
          Use your athena credentials to log in. You can leave yourself logged in – no need to log in and out every
          time.
        </p>
        <p>Logging in is required to use the Forge Guide website.</p>
      </div>
      <div className="forge-guide__button-wrapper">
        <Button onClick={handleLogin}>
          Log in with&nbsp;
          <AthenaLogoWhite />
        </Button>
      </div>
    </Modal>
  );
};

export default LoginModal;
