import rawContents from "!!raw-loader!./StartList.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function StartList():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Start List" />;
}

export default StartList;
