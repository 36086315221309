import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStopScreenShareFilledSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2929 1.29228C10.6834 0.901759 11.3166 0.901759 11.7071 1.29228L13 2.58518L14.2929 1.29228C14.6834 0.901759 15.3166 0.901759 15.7071 1.29228C16.0976 1.68281 16.0976 2.31597 15.7071 2.7065L14.4142 3.99939L15.7071 5.29228C16.0976 5.68281 16.0976 6.31597 15.7071 6.7065C15.3166 7.09702 14.6834 7.09702 14.2929 6.7065L13 5.4136L11.7071 6.7065C11.3166 7.09702 10.6834 7.09702 10.2929 6.7065C9.90237 6.31597 9.90237 5.68281 10.2929 5.29228L11.5858 3.99939L10.2929 2.7065C9.90237 2.31597 9.90237 1.68281 10.2929 1.29228ZM7 12.9994H5C4.44772 12.9994 4 13.4471 4 13.9994C4 14.5517 4.44772 14.9994 5 14.9994H8H11C11.5523 14.9994 12 14.5517 12 13.9994C12 13.4471 11.5523 12.9994 11 12.9994H9V11.9994H13C14.6569 11.9994 16 10.6562 16 8.99939V7.73201C15.2351 8.1741 14.2402 8.06797 13.5858 7.4136L13 6.82782L12.4142 7.4136C11.6332 8.19465 10.3668 8.19465 9.58579 7.4136C8.80474 6.63256 8.80474 5.36622 9.58579 4.58518L10.1716 3.99939L9.58579 3.4136C8.93142 2.75924 8.82529 1.76425 9.26738 0.99939H8H0V11.9994H7V12.9994Z"
      fill={props.color}
    />
  </svg>
);
SvgStopScreenShareFilledSmall.displayName = 'SvgStopScreenShareFilledSmall';
const StopScreenShareFilledSmall = withForgeIconProps(
  SvgStopScreenShareFilledSmall,
  {
    name: 'StopScreenShareFilled',
    usage: 'Use as active state to stop screenshare',
    related: 'Desktop',
    isLabelRequired: true,
    isNeutralInteractive: false,
    size: 'small',
  }
);
export default StopScreenShareFilledSmall;
