import TrashSmall from '@athena/forge-icons/dist/TrashSmall';
import { ReactNode } from 'react';
import Button from '../../Button';
import InlineAlert from '../../InlineAlert';
import ListItem from '../../ListItem';
import { forgeClassHelper } from '../../utils/classes';
import { FileUploadAction, FileUploadFile } from '../fileUploadReducer';

const classes = forgeClassHelper('file-upload');

export interface FileUploadErrorProps {
  dispatch: React.Dispatch<FileUploadAction>;
  file: FileUploadFile;
}

/** Renders a file that was uploading, but had an error */
const FileUploadError = ({ dispatch, file }: FileUploadErrorProps): ReactNode => {
  return (
    <ListItem uniqueKey={file.id} {...classes({ element: 'error-list-item' })}>
      {file.fileData.name}
      <InlineAlert {...classes({ element: 'error-alert' })} iconSize="small" type="attention">
        {/* InlineAlert automatically interrupts screen readers with its content,
        but the first {file.fileData.name} is outside this content.
        Restate the file name for screen readers. */}
        <span className="fe_u_visually-hidden">{`${file.fileData.name} `}</span>
        <div {...classes({ element: 'error-message' })}>
          {file.errorMessage}
          <Button
            size="small"
            variant="tertiary"
            icon={TrashSmall}
            onClick={() => dispatch({ type: 'delete', affectedFile: file })}
          />
          <Button
            {...classes({ element: 'retry-button' })}
            size="small"
            variant="tertiary"
            text="Retry"
            onClick={() => dispatch({ type: 'retry', affectedFile: file })}
          />
        </div>
      </InlineAlert>
    </ListItem>
  );
};

export default FileUploadError;
