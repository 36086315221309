import forgePackageJson from '@athena/forge/package.json';
import { VersionLeaf } from './hooks/useVersion';

export type ForgeVersionType = 'new' | 'lts' | 'unsupported';
export const forgeVersionType = (v?: VersionLeaf): ForgeVersionType => {
  const majorVersion = Number((v?.version ?? forgePackageJson.version).split('.')[0]);
  if (process.env.GATSBY_FORGE_LTS_VERSION) {
    const ltsMajorVersion = Number(process.env.GATSBY_FORGE_LTS_VERSION);
    if (majorVersion > ltsMajorVersion) {
      return 'new';
    } else if (majorVersion === ltsMajorVersion) {
      return 'lts';
    }
  }
  return 'unsupported';
};

export const appendVersionString = (v?: VersionLeaf): string => {
  switch (forgeVersionType(v)) {
    case 'new':
      return 'New ';
    case 'lts':
      return 'LTS ';
    default:
      return '';
  }
};
