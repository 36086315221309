import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClockwiseSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.5 9.39C13.5079 10.1197 13.3719 10.8437 13.0996 11.5207C12.8274 12.1978 12.4243 12.8145 11.9135 13.3356C11.4026 13.8566 10.794 14.2719 10.1224 14.5575C9.45093 14.8431 8.7297 14.9935 8.00001 15C7.27078 14.9948 6.54976 14.8457 5.8783 14.5611C5.20685 14.2766 4.59815 13.8623 4.08716 13.342C3.57618 12.8218 3.17301 12.2058 2.90064 11.5293C2.62828 10.8528 2.49209 10.1292 2.50001 9.40001C2.49578 8.68122 2.63595 7.96892 2.91212 7.30529C3.18828 6.64165 3.59488 6.0402 4.10779 5.53661C4.62071 5.03302 5.22949 4.63747 5.89808 4.37352C6.56667 4.10958 7.28141 3.98258 8.00001 3.99999H12.5M12.5 3.99999L9.51004 1M12.5 3.99999L9.50005 7"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgClockwiseSmall.displayName = 'SvgClockwiseSmall';
const ClockwiseSmall = withForgeIconProps(SvgClockwiseSmall, {
  name: 'Clockwise',
  usage:
    'Use to redo the last action or rotate an item clockwise. Customize `title` element to indicate use',
  related: 'rotate, rotation, undo, portrait, landscape, navigation',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ClockwiseSmall;
