import { useDebouncedCallback } from 'use-debounce';
import Input from '../../../Input';
import SearchSmall from '@athena/forge-icons/dist/SearchSmall';
import { DataTableStore } from '../../store/stateTypes';
import { hasRowsInEditStatusFromState } from '../../utils/hasRowsInEditStatusFromState';
import { DataTableBlockUI } from '../../utils/DataTableBlockUI';
import { classes } from '../../utils/dataTableClasses';

// Action
import { apply_client_side_filters } from '../../store/actions/apply_client_side_filters';

type ClientSideSearchBoxProps<RowData> = {
  store: DataTableStore<RowData>;
};

const SEARCH_DEBOUNCE_TIMEOUT = process.env.NODE_ENV === 'test' ? 0 : 500;

export function ClientSideSearchBox<RowData>({ store }: ClientSideSearchBoxProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const tableType = store.useSelector((s) => s.tableType);
  const searchBox = store.useSelector((s) => s.searchBox);
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));

  // Search by text when user stop typing after 500 ms
  const searchByTextWhenStopTyping = useDebouncedCallback(() => {
    dispatch(apply_client_side_filters);
  }, SEARCH_DEBOUNCE_TIMEOUT);

  if (!searchBox.show || tableType !== 'client-side') {
    return <></>;
  }

  return (
    <DataTableBlockUI block={hasRowsInEditStatus}>
      <div {...classes({ element: 'client_side_search_box' })}>
        <Input
          clearable={true}
          icon={SearchSmall}
          onChange={(e) => {
            dispatch(function update_search_text(s) {
              s.searchBox.inputText = e.target.value;
            });
            searchByTextWhenStopTyping();
          }}
        />
      </div>
    </DataTableBlockUI>
  );
}
