import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTrashSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.73993 0C5.25993 0 4.91996 0.790234 5.01996 1.49023C5.06071 1.66513 5.11754 1.83563 5.18988 2H10.81C10.9069 1.82801 10.9649 1.63679 10.9799 1.43994C11.0499 0.759941 10.7199 0.0200195 10.2499 0.0200195L5.73993 0Z"
      fill={props.color}
    />
    <path
      d="M1 2.5H15M6.5 12V6M9.5 10V6M3 15V3H13V12.1201C12.9922 12.5062 12.9084 12.887 12.7534 13.2407C12.5984 13.5944 12.3753 13.9142 12.0967 14.1816C11.8181 14.4491 11.4895 14.6589 11.1298 14.7993C10.77 14.9398 10.3861 15.008 10 15H3Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgTrashSmall.displayName = 'SvgTrashSmall';
const TrashSmall = withForgeIconProps(SvgTrashSmall, {
  name: 'Trash',
  usage: 'Use to view deleted items, or to delete an item',
  related: 'garbage, bin, trashcan',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default TrashSmall;
