import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNoteAddedSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 1V0H0V1H1ZM15 1H16V0H15V1ZM15 12H16H15ZM1 15H0V16H1V15ZM5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L5.70711 7.29289ZM7 10L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L7 10ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L11.7071 6.70711ZM1 2H15V0H1V2ZM14 1V12H16V1H14ZM14 12C14 12.5304 13.7893 13.0392 13.4142 13.4142L14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12L14 12ZM13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14V16C13.0609 16 14.0783 15.5786 14.8284 14.8284L13.4142 13.4142ZM12 14H1V16H12V14ZM2 15V1H0V15H2ZM4.29289 8.70711L6.29289 10.7071L7.70711 9.29289L5.70711 7.29289L4.29289 8.70711ZM7.70711 10.7071L11.7071 6.70711L10.2929 5.29289L6.29289 9.29289L7.70711 10.7071Z"
      fill={props.color}
    />
  </svg>
);
SvgNoteAddedSmall.displayName = 'SvgNoteAddedSmall';
const NoteAddedSmall = withForgeIconProps(SvgNoteAddedSmall, {
  name: 'NoteAdded',
  usage: 'Use to indicate that a note was added',
  related: 'paper, sticky, add',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default NoteAddedSmall;
