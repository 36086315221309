import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTrashLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.86 0C6.74 0 6.05 1.7598 6.86 2.7998H17.16C17.94 1.7998 17.25 0 16.16 0H7.86Z"
      fill={props.color}
    />
    <path
      d="M1.5 3H22.5M12 19V7M8 19V7M16 15V7M20 3V20C20 20.7956 19.6837 21.5587 19.1211 22.1213C18.5585 22.6839 17.7956 23 17 23H4V3H20Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgTrashLarge.displayName = 'SvgTrashLarge';
const TrashLarge = withForgeIconProps(SvgTrashLarge, {
  name: 'Trash',
  usage: 'Use to view deleted items, or to delete an item',
  related: 'garbage, bin, trashcan',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default TrashLarge;
