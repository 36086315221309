import React from 'react';
import FormError from '../FormError';

import { classes, displayCurrencyMsg } from './NumericRangeInputUtils';

/** Arguments to BannerAccessory. */
export interface BannerAccessoryProps {
  /** REQUIRED total number of banners to display. */
  bannerCount: number;
  /**
   * REQUIRED number that holds the total number of banners that have
   * been created at this point including this current one.
   */
  bannersCreated: number;
  /** Optional child nodes. */
  children?: React.ReactNode;
  /** REQUIRED prefix id for the banner tags. */
  id: string;
  /** REQUIRED message to display. */
  msg: string;
}

/** Helper tag for Banner and BannerItem tags. */
export const BannerAccessory = (props: BannerAccessoryProps): JSX.Element => {
  // This class does the body of the Banner/BannerItem pairing.
  const { bannerCount, bannersCreated, id, msg } = props;

  return (
    <FormError
      id={`${id}-bannerid`}
      data-testid={`${id}-bannerid`}
      className={bannerCount > 1 && bannersCreated !== bannerCount ? 'fe_u_margin--bottom-small' : ''}
    >
      {msg}
    </FormError>
  );
};

/** Arguments to AttentionBanners. */
export interface AttentionBannersProps {
  /** REQUIRED number containing a count of the banners to be displayed. */
  bannerCount: number;
  /** REQUIRED boolean tells the error to always render below the input area instead of based on content-size and breakpoint. */
  errorAlwaysBelow: boolean;
  /**
   * Optional string holding the current custom banner message for the first input.
   * It is null if there is nothing to display.
   */
  firstValueCustomValidationMsg: string | null;
  /** REQUIRED boolean indicating  that there is a custom validation message to display for the first input. */
  hasFirstValueCustomValidationMsg: boolean;
  /** REQUIRED boolean indicating that there is a custom validation message to display for the second input. */
  hasSecondValueCustomValidationMsg: boolean;
  /** REQUIRED string holding the HTML id for this component. */
  id: string;
  /** REQUIRED boolean indicating whether this component concerns currency or not. */
  isCurrency: boolean;
  /** REQUIRED boolean indicating whether the first input is greater than the maximum allowed value if one defined. */
  isFirstValueAfterMaxValue: boolean;
  /** REQUIRED boolean indicating whether the first input is greater than the second input. */
  isFirstValueAfterSecondValue: boolean;
  /** REQUIRED boolean indicating whether the first input is less than the minimum allowed value if one defined. */
  isFirstValueBeforeMinValue: boolean;
  /** REQUIRED boolean indicating whether the second input is greater than the maximum allowed value if one defined. */
  isSecondValueAfterMaxValue: boolean;
  /** REQUIRED boolean indicating whether the second input is less than the minimum allowed value if one defined. */
  isSecondValueBeforeMinValue: boolean;
  /** REQUIRED boolean indicating whether the current first input value is valid. */
  isValidFirstValue: boolean;
  /** REQUIRED boolean indicating whether the current second input value is valid. */
  isValidSecondValue: boolean;
  /** Optional number holding the maximum value allowed for the first value. */
  maxFirstValue?: number;
  /** Optional number holding the maximum value allowed for the second value. */
  maxSecondValue?: number;
  /** Optional number holding the minimum value allowed for the first value. */
  minFirstValue?: number;
  /** Optional number holding the minimum value allowed for the second value. */
  minSecondValue?: number;
  /**
   * Optional string holding the current custom banner message for the second input.
   * It is null if there is nothing to display.
   */
  secondValueCustomValidationMsg: string | null;
  /** REQUIRED boolean indicating that */
  showRequiredBannerFirstValue: boolean;
  /** REQUIRED boolean indicating that */
  showRequiredBannerSecondValue: boolean;
  /** Indicates that the input is not part of a Form. */
  standalone: boolean;
  /** REQUIRED boolean indicating if the NumericRangeInput should suppress vertical spacing. */
  suppressVerticalSpacing: boolean;
  /** REQUIRED boolean indicating if the NumericRangeInput should suppress the display of warning banners. */
  suppressWarningBanner: boolean;
}

/** Create a JSX of all Banners. */
export const AttentionBanners = (props: AttentionBannersProps): JSX.Element => {
  const {
    bannerCount,
    errorAlwaysBelow,
    firstValueCustomValidationMsg,
    hasFirstValueCustomValidationMsg,
    hasSecondValueCustomValidationMsg,
    id,
    isCurrency,
    isFirstValueAfterMaxValue,
    isFirstValueAfterSecondValue,
    isFirstValueBeforeMinValue,
    isSecondValueAfterMaxValue,
    isSecondValueBeforeMinValue,
    isValidFirstValue,
    isValidSecondValue,
    maxFirstValue,
    maxSecondValue,
    minFirstValue,
    minSecondValue,
    secondValueCustomValidationMsg,
    showRequiredBannerFirstValue,
    showRequiredBannerSecondValue,
    standalone,
    suppressWarningBanner,
  } = props;
  let bannersCreated = 0;

  return suppressWarningBanner === false ? (
    <div
      {...classes({
        extra: `fe_u_flex-container fe-input__input fe_u_margin--right-small ${
          bannerCount > 0 && errorAlwaysBelow ? 'fe_u_margin--top-small' : ''
        }`,
        modifiers: { stacked: true },
      })}
    >
      {isFirstValueAfterSecondValue && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-numericorder`}
          msg={'Minimum cannot exceed maximum.'}
        />
      )}
      {isFirstValueAfterMaxValue && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-firstmaxvalue`}
          msg={
            isCurrency
              ? displayCurrencyMsg('First amount cannot be larger than {0}.', maxFirstValue)
              : `First quantity cannot be larger than ${maxFirstValue}.`
          }
        />
      )}
      {isFirstValueBeforeMinValue && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-firstminvalue`}
          msg={
            isCurrency
              ? displayCurrencyMsg('First amount cannot be smaller than {0}.', minFirstValue)
              : `First quantity cannot be smaller than ${minFirstValue}.`
          }
        />
      )}
      {hasFirstValueCustomValidationMsg && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-firstcustomvalidation`}
          msg={firstValueCustomValidationMsg ?? ''}
        />
      )}
      {standalone && showRequiredBannerFirstValue && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-firstnumberreq`}
          msg={isCurrency ? 'First amount is required.' : 'First quantity is required.'}
        />
      )}
      {!isValidFirstValue && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-firstnumbervalid`}
          msg={isCurrency ? 'First amount is not valid.' : 'First quantity is not valid.'}
        />
      )}
      {isSecondValueAfterMaxValue && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-secondmaxvalue`}
          msg={
            isCurrency
              ? displayCurrencyMsg('Second amount cannot be larger than {0}.', maxSecondValue)
              : `Second quantity cannot be larger than ${maxSecondValue}.`
          }
        />
      )}
      {isSecondValueBeforeMinValue && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-secondminvalue`}
          msg={
            isCurrency
              ? displayCurrencyMsg('Second amount cannot be smaller than {0}.', minSecondValue)
              : `Second quantity cannot be smaller than ${minSecondValue}.`
          }
        />
      )}
      {hasSecondValueCustomValidationMsg && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-secondcustomvalidation`}
          msg={secondValueCustomValidationMsg ?? ''}
        />
      )}
      {standalone && showRequiredBannerSecondValue && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-secondnumberreq`}
          msg={isCurrency ? 'Second amount is required.' : 'Second quantity is required.'}
        />
      )}
      {!isValidSecondValue && (
        <BannerAccessory
          bannerCount={bannerCount}
          bannersCreated={++bannersCreated}
          id={`${id}-secondnumbervalid`}
          msg={isCurrency ? 'Second amount is not valid.' : 'Second quantity is not valid.'}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default BannerAccessory;
