import { ReactElement } from 'react';
import dashify from 'dashify';
import './linkable-heading.scss';
import ShareSmall from '@athena/forge-icons/dist/ShareSmall';

type HLevels = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface LinkableHeadingProps {
  text: string;
  hLevel?: HLevels;
  idPrefix?: string;
  children?: ReactElement;
}

export default function LinkableHeading({
  text,
  hLevel: HLevel = 'h3',
  idPrefix = '',
  children,
}: LinkableHeadingProps): ReactElement {
  const kebabText = dashify(text);
  const id = `${idPrefix}-${kebabText}`;
  return (
    <div className="linkable-header__container">
      <HLevel className={'linkable-header guide'} id={id}>
        {text}
        {'  '}
        <a className="linkable-header__link" href={`#${id}`}>
          <ShareSmall semanticColor="interactive" />
        </a>
      </HLevel>
      {children}
    </div>
  );
}
