import rawContents from "!!raw-loader!./IndicatorBasic.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function IndicatorBasic():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Indicator Basic" />;
}

export default IndicatorBasic;
