import React from 'react';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper('form-legend');

export interface FormLegendProps {
  /** Whether all the fields in the form are required.
    If true, will render text 'All fields are required', else will render legend for the required field pattern. */
  allFieldsRequired?: boolean;
  /** Adds a class to the root element of the component */
  className?: string;
}

const FormLegend = ({ allFieldsRequired = false, className, ...props }: FormLegendProps): React.ReactElement => {
  const text = allFieldsRequired ? 'All fields are required' : 'Required field';

  return (
    <div
      {...classes({
        modifiers: { 'all-required': allFieldsRequired },
        extra: className,
      })}
      aria-hidden="true"
      {...props}
    >
      {text}
    </div>
  );
};

export default FormLegend;
