import { useState, useEffect } from 'react';

export interface VersionLeaf {
  type: 'leaf';
  version: string;
  link: string;
}
export type Version = VersionNode | VersionLeaf;
export interface VersionNode {
  type: 'node';
  versions: VersionLeaf[];
  version: string;
}
export interface VersionResponse {
  versions: Version[];
}

export default function useVersion(): Version[] {
  const [versions, setVersions] = useState<Version[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        const fetchResponse = await fetch(`${process.env.GATSBY_VERSIONS_API_URL}?version=>1.2.7`, {
          signal: abortController.signal,
        });
        const resultData: VersionResponse = await fetchResponse.json();
        setVersions(resultData.versions);
      } catch (error) {
        console.error(error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, []);

  return versions;
}
