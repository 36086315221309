import FileUploadMockApplication from '@athena/forge/dist/FileUploadMockApplication';

import rawContents from "!!raw-loader!./DragAndDropUploadConditions.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { FileUploadMockApplication };

function DragAndDropUploadConditions():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Drag And Drop Upload Conditions" />;
}

export default DragAndDropUploadConditions;
