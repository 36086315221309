/** disabling import/no-extraneous-dependencies here is OK only because
 * LAYOUT_CONSTANTS only happens to be used by unit tests
 * @athena/forge-shared should be moved back to `dependencies` once EFEA-1895
 * is resolved.
 */
// eslint-disable-next-line import/no-extraneous-dependencies
import { colorPrimitives, layout } from '@athena/forge-shared';
export const NAMESPACE = 'fe';
export const COMPONENT_PREFIX = 'c';
export const LAYOUT_PREFIX = 'l';
export const FEATURE_PREFIX = 'f';
export const STATE_PREFIX = 'is';

export const SEMANTIC_COLORS = ['primary', 'secondary', 'success', 'alert', 'warning'] as const;
export type SemanticColors = typeof SEMANTIC_COLORS[number];

export const ALERT_TYPES = ['attention', 'critical', 'info', 'new', 'success'] as const;
export type AlertTypes = typeof ALERT_TYPES[number];

export const HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
export type HeadingTags = typeof HEADING_TAGS[number];

export const GLOBAL_FEATURE = `${NAMESPACE}_${FEATURE_PREFIX}_all`;
export const LAYOUT_CONSTANTS = layout;
export const COLOR_PRIMITIVES = colorPrimitives;
