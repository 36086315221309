import {
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  HTMLAttributes,
  Ref,
  RefAttributes,
  useContext,
} from 'react';
import PropTypes, { Validator } from 'prop-types';
import { optionalBoolValidator, optionalStringValidator } from './../utils/betterPropTypes';
import { ForgeIconComponent } from '@athena/forge-icons/dist/ForgeIconProps';
import { FunctionFromString } from '../Select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { WeakValidationMap } from 'react';
import { nanoid } from 'nanoid';
import AsyncCreatable from 'react-select/async-creatable';
import { InputActionMeta, OnChangeValue } from 'react-select/dist/declarations/src';
import { withPortalDataAndRef } from '../PortalProvider/PortalContext';
import { forgeClassHelper } from '../utils/classes';
import FakeEvent from '../utils/fake-event';
import { SelectContext } from './context/SelectContext';
import {
  getFunctionFromString,
  reconcileSelectSize,
  customGetOptionValue,
  customIsOptionDisabled,
  customGetOptionLabel,
  selectFilterOptions,
  normalizeValues,
  selectInputMatchesExistingOption,
  noNewSelectOptionsAreValid,
  isSelectOption,
} from './utils/selectUtils';
import {
  CustomClearIndicator,
  CustomDropdownIndicator,
  CustomGroupHeading,
  CustomInput,
  CustomLoadingIndicator,
  CustomMenuList,
  CustomMultiValue,
  CustomOption,
  CustomValueContainer,
} from './components';
import { PortalContextProps } from '../PortalProvider/PortalContext';
import { TagColor, TextVariant, tagColors } from '../Tag';
import {
  GroupBase as SelectGroupBase,
  PropsValue,
  SelectInstance as ReactSelectType,
} from 'react-select/dist/declarations/src';
import { AsyncCreatableProps } from 'react-select/async-creatable';
import { Accessors as SelectAccessors } from 'react-select/dist/declarations/src/useCreatable';
import { FormFieldLayoutContext } from '../FormFieldLayout/FormFieldLayoutContext';
export { type GroupBase as SelectGroupBase } from 'react-select/dist/declarations/src';
export { type Accessors as SelectAccessors } from 'react-select/dist/declarations/src/useCreatable';

/**
 * Wrapper on top of react-select's Async component - https://react-select.com/async
 */
export const classes = forgeClassHelper({ name: 'select' });
export const classesAsStr = forgeClassHelper({ name: 'select', outputIsString: true });

/** The data sent to and retrieved from a Select
 *
 * The Select default export also accepts string[], but that gets normalized
 * to StructuredOption before being sent to react-select.
 */
export type StructuredOption = {
  /** Tag color */
  color?: TagColor;
  /** Option is visible, but not selectable */
  disabled?: boolean;
  /** The text to display to the user */
  label?: string;
  /** Text styling */
  textVariant?: TextVariant;
  /** The value of the option when selected */
  value: string;
};

/** Represents the union of all allowable data types
 *
 * This type cannot not be effectively used externally given that the default
 * export has overloads preventing mixing string and StructuredOption data.
 */
export type SelectOption = string | StructuredOption;

export type SelectChangeEvent<IsMulti extends boolean> = FakeEvent<OnChangeValue<StructuredOption, IsMulti>>;

/** x-large is a special size that is used internally to represent the size of a
 * Select rendered within a large layout FormField--hence, it is not included in
 * SELECT_SIZES as it is not actually available */
export type SelectSize = 'small' | 'medium' | 'large' | 'x-large';

const SELECT_SIZES: ReadonlyArray<SelectSize> = ['small', 'medium', 'large'];
export interface SelectProps<IsMulti extends boolean = false>
  extends Omit<
      AsyncCreatableProps<StructuredOption, IsMulti, SelectGroupBase<StructuredOption>>,
      | 'loadingMessage'
      | 'noOptionsMessage'
      | 'options'
      | 'value'
      | 'defaultValue'
      | 'onChange'
      | 'cacheOptions'
      | 'isMulti'
    >,
    Omit<HTMLAttributes<HTMLDivElement>, 'defaultValue' | 'onBlur' | 'onFocus' | 'onChange'> {
  /** When set, the user is able to type and select an option that isn't on the
   * existing list of options.
   *
   * Either isValidNewOption, or allowUserCreatedOptions should be set to allow users
   * to create new options that are not already on the list */
  allowUserCreatedOptions?: boolean;
  /**
   * Aria label for the input. If not used in FormField, either `aria-label`, `aria-labelledby`, or
   * `inputId` associated with a `<label>` is required for accessibility.
   */
  'aria-label'?: string;
  /**
   * HTML ID of an element that should be used as the label for the input. Will override `aria-label` if provided.
   * If not used in FormField, either `aria-label`, `aria-labelledby`, or `inputId` associated with a `<label>` is required for accessibility.
   */
  'aria-labelledby'?: string;
  /** If truthy, loaded data will be cached. The cache will remain until `cacheOptions` changes value. */
  cacheOptions?: boolean;
  /** Adds a class to the root element of the component. */
  className?: string;
  /** Close the select menu when the user selects an option. */
  closeMenuOnSelect?: boolean;
  /** Determines the initial set of selected options, but allows them to be
   * updated internally ([uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)).
   */
  defaultValue?: PropsValue<SelectOption>;
  /** Impacts the styling of the select in addition to preventing selection */
  disabled?: boolean;
  /** Disables the portal which can fix issues related to scrolling inside elements with scrolling effects
   *
   * The `menuPosition` prop also needs to be changed to something other than "fixed",
   * such "absolute".
   */
  disablePortal?: boolean;
  /** When set to true and isMulti={true}, the user is able select all options or all filtered options */
  enableSelectAll?: boolean;
  /** Highlights the input when defined (specific string doesn't matter) */
  error?: string;
  /** Hides any required styling */
  hideRequiredStyles?: boolean;
  /** The icon to display inside the */
  icon?: ForgeIconComponent;
  /** Adds an id to the root element of the component. To add an id to the `<input>` element, use the `inputId` prop */
  id?: string;
  /**
   * Will not be used if `options` are provided and component is being used synchronously.
   * The default set of options to show in the menu before the user starts searching.
   * When set to true, the results for `loadAsyncOptions('')` will be loaded on mount.
   *
   * It's important to use functions such as useMemo to not generate new option
   * values on every render. Otherwise keyboard navigation does not work as well
   * as it should.
   */
  initialAsyncOptions?: ReadonlyArray<SelectOption | SelectGroupBase<SelectOption>>;
  /**
   * Used to set an id on the HTML `<input>` element.
   * If not used in `FormField`, either `aria-label`, `aria-labelledby`, or `inputId` associated with a `<label>` is required
   * for accessibility.
   */
  inputId?: string;
  /** Function used to determine if a user-typed value is allowed to become a new option
   *
   * Either isValidNewOption, or allowUserCreatedOptions should be set to allow users
   * to create new options that are not already on the list
   */
  isValidNewOption?: (
    inputValue: string,
    value: ReadonlyArray<StructuredOption>,
    options: ReadonlyArray<StructuredOption | SelectGroupBase<StructuredOption>>,
    accessors: SelectAccessors<StructuredOption>
  ) => boolean;
  /** Able to select multiple values if true */
  isMulti?: IsMulti;
  /**
   * Function that returns a promise, which is the set of options to display in the menu once the promise resolves.
   * This function should use the `inputValue` typed into the input filter to filter the array of options.
   * Will not be used if `options` are provided and component is being used synchronously.
   */
  loadAsyncOptions?: (inputValue: string) => Promise<ReadonlyArray<SelectOption | SelectGroupBase<SelectOption>>>;
  /** Text to display when loading options (or a function returning the string to show). */
  loadingMessage?: string | FunctionFromString;
  /** Text to display when the there are no options to show in the menu (or a function returning the string to show). */
  noOptionsMessage?: FunctionFromString | string;
  /** Function that takes a fake event as an argument. This event contains a
   * target object with value (map of keys to true/false) and id properties.
   * Called when the Select value changes.
   */
  onChange?: (event: SelectChangeEvent<IsMulti>) => void;
  /**
   * Array of all options available.
   * Uses a prebuilt filter function which does a case insensitive string match using option value and text.
   * If you want to use different initial options or filtering, use `initialAsyncOptions` and `loadAsyncOptions` instead.
   * If provided, `loadAsyncOptions` and `initialAsyncOptions` props will not be used.
   *
   * It's important to use functions such as useMemo to not generate new option
   * values on every render. Otherwise keyboard navigation does not work as well
   * as it should.
   */
  options?: ReadonlyArray<SelectOption | SelectGroupBase<SelectOption>>;
  /**
   * Text shown when no values are selected and the user is not typing.
   */
  placeholder?: string;
  /** An instance of the react-select class-based component which underlies this
   * implementation.
   */
  ref?: Ref<SelectRef<IsMulti>>;
  /** Indicates whether it is required to select at least one option. */
  required?: boolean;
  /** Sets the size of the Select component */
  size?: SelectSize;
  /** Select the currently focused option when the user presses Tab. */
  tabSelectsValue?: boolean;
  /**
   * Determines the selected options, and does not allow them to be updated
   * internally ([controlled](https://reactjs.org/docs/forms.html#controlled-components)).
   */
  value?: PropsValue<SelectOption>;
}

/** The type of the forwarded ref */
export type SelectRef<IsMulti extends boolean = true> = ReactSelectType<
  StructuredOption,
  IsMulti,
  SelectGroupBase<StructuredOption>
>;

/** The final type of the Select default export.
 *
 * Needs to be defined explicitly because forwardRefToProps cannot return a
 * generic type
 */
export type SelectType = <IsMulti extends boolean = false>(
  props: SelectProps<IsMulti> & RefAttributes<SelectRef<IsMulti>>
) => ReactElement;

type SelectComponentProps<IsMulti extends boolean> = SelectProps<IsMulti> &
  PortalContextProps & {
    /** An instance of the react-select class-based component which underlies this
     * implementation.
     */
    forwardedRef?: Ref<SelectRef<IsMulti>>;
  };

/** Type assertion function borrowed from implementation of react-select/utils
 * https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/utils.ts#L392
 *
 * Used only when mutating react-select internal state in the same way they do.
 */
function multiValueAsValue<Option, IsMulti extends boolean>(
  multiValue: ReadonlyArray<Option>
): OnChangeValue<Option, IsMulti> {
  return multiValue as OnChangeValue<Option, IsMulti>;
}
/** Core implementation of Select.
 *
 * Accepts props and a forwarded ref.
 */
const SelectComponent = <IsMulti extends boolean>({
  enableSelectAll = false,
  allowUserCreatedOptions = false,
  cacheOptions = true,
  classNamePrefix,
  closeMenuOnScroll: upstreamCloseMenuOnScroll,
  closeMenuOnSelect: upstreamCloseMenuOnSelect,
  defaultOptions,
  defaultValue: upstreamDefaultValue,
  disablePortal = false,
  forwardedRef,
  getOptionValue,
  getOptionLabel,
  initialAsyncOptions: upstreamInitialAsyncOptions,
  isDisabled,
  isOptionDisabled,
  isSearchable,
  isValidNewOption: upstreamIsValidNewOption,
  loadOptions,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledby,
  className,
  disabled = false,
  error,
  hideRequiredStyles = false,
  icon,
  inputValue: upstreamInputValue,
  isClearable = false,
  isMulti = false as IsMulti,
  loadAsyncOptions: upstreamLoadAsyncOptions,
  loadingMessage = 'Loading ...',
  onInputChange,
  onKeyDown,
  noOptionsMessage = 'No options found',
  onMenuClose,
  options: upstreamOptions,
  placeholder = 'Type or Select',
  required = false,
  portalData,
  tabSelectsValue = false,
  value: upstreamValue,
  onChange,
  id,
  size = 'large',
  components = {},
  ...rest
}: SelectComponentProps<IsMulti>): ReactElement => {
  const { layout } = useContext(FormFieldLayoutContext);

  const isValidNewOption = useMemo(
    () =>
      upstreamIsValidNewOption === undefined
        ? /** if upstreamIsValidNewOption is not provided, choose the correct
           * implementation for isValidNewOption based on allowUserCreatedOptions
           */
          allowUserCreatedOptions
          ? selectInputMatchesExistingOption
          : noNewSelectOptionsAreValid
        : // If isValidNewOption is provided, then use that
          upstreamIsValidNewOption,
    [allowUserCreatedOptions, upstreamIsValidNewOption]
  );
  //React Select Ref
  const reactSelectRef = useRef<SelectRef<IsMulti>>(null);
  //Use Imperative
  useImperativeHandle<SelectRef<IsMulti> | null, SelectRef<IsMulti> | null>(forwardedRef, () => reactSelectRef.current);

  const closeMenuOnScroll = useCallback<(event: Event) => boolean>(
    (event: Event) => {
      if (typeof upstreamCloseMenuOnScroll === 'function') {
        return upstreamCloseMenuOnScroll(event);
      } else if (typeof upstreamCloseMenuOnScroll === 'boolean') {
        return upstreamCloseMenuOnScroll;
      } else if (reactSelectRef.current && event.target === reactSelectRef.current.menuListRef) {
        /** Do not close if the menu itself is being scrolled */
        return false;
      } else {
        /** Close the menu in all other cases, otherwise the menu may overlap
         * oddly with other content */
        return true;
      }
    },
    [upstreamCloseMenuOnScroll]
  );

  /** Automatically close the menu if set via props or if it is a single select */
  const closeMenuOnSelect = upstreamCloseMenuOnSelect || !isMulti;

  /**
   * This implementation is a workaround for a latency issue with React Select pertaining to defaultMenuIsOpen
   *
   * https://github.com/JedWatson/react-select/issues/4749
   * https://github.com/JedWatson/react-select/issues/5315
   *
   * Namely, if defaultMenuIsOpen is true, React Select will attempt to render the Menu prior to the control element mounting.
   * This is problematic as React Select does not provide functionality to dynamically append the menu when the control element is made available.
   * As such, by forcing a single rerender on mount, we do React Select's work for it by generating the opportunity to render the menu.
   */
  const [, setForceRerender] = useState(1);

  useEffect(() => {
    // This function will be called once after the component mounts
    setForceRerender((prevState) => prevState + 1);
  }, []); // The empty dependency array ensures this runs only once

  const [instanceId] = useState(nanoid(6));

  // Clients of Select can provide SelectOption[] for many of its props,
  // but we want to provide AsyncCreatable types of StructuredOption[].
  // In order to make this work, we need to normalize the SelectOption[]
  // we receive to be StructuredOption[].
  //
  // This pattern is applied to all of the upstream* props.

  const defaultValue = useMemo(() => normalizeValues(upstreamDefaultValue), [upstreamDefaultValue]);
  /**
   * Holds the currently selected options when Select is uncontrolled
   *
   * Utilizing useRef hook as we are not looking to execute side effects/cause re-renders as the value updates
   * This is used to discern whether or not the most recently selected item is disabled or not for the purpose of handling key down events; particularly, Backspace events
   */
  const uncontrolledSelectedOptions = useRef<ReadonlyArray<StructuredOption> | null | undefined>(defaultValue);
  /**
   * Holds the option data of the item currently in focus in the drop down
   *
   * Utilizing useRef hook as we are not looking to execute side effects/cause re-renders as the value updates
   * This is used to discern whether a focused option's removal is permissible via key press events; particularly, Tab, " ", and Enter
   */
  const focusedOptionData = useRef<StructuredOption | null>(null);
  /**
   * Holds the option data of the item currently in focus in the input
   *
   * Utilizing useRef hook as we are not looking to execute side effects/cause re-renders as the value updates
   * This is used to discern whether a focused option's removal is permissible via key press events; particularly, Backspace events
   */
  const focusedMultiValueData = useRef<StructuredOption | null>(null);

  /** Updates which option is currently in focus.
   *
   * Is propagated to children components via ReactSelectContext
   */
  const handleFocusedOptionData = useCallback((optionData: StructuredOption | null, isFocused?: boolean): void => {
    if (isFocused || !optionData) {
      focusedOptionData.current = optionData;
    } else if (
      focusedOptionData.current &&
      customGetOptionValue(focusedOptionData.current) === customGetOptionLabel(optionData)
    ) {
      focusedOptionData.current = null;
    }
  }, []);
  // updates focusedMultiValueData hook; is propagated to children components via SelectContext
  const handleFocusedMultiValueData = useCallback(
    (multiValueData: StructuredOption | null, isFocused?: boolean): void => {
      if (isFocused || !multiValueData) {
        focusedMultiValueData.current = multiValueData;
      } else if (
        focusedMultiValueData.current &&
        customGetOptionValue(focusedMultiValueData.current) === customGetOptionValue(multiValueData)
      ) {
        focusedMultiValueData.current = null;
      }
    },
    []
  );
  /**  updates value when select-all button clicked in CustomMenuList */
  const handleSelectAll = (
    visibleOptionsAndGroups: ReadonlyArray<StructuredOption | SelectGroupBase<StructuredOption>>
  ): void => {
    if (reactSelectRef.current && isMulti && visibleOptionsAndGroups) {
      // compare if all selected
      const { selectValue: currentSelected } = reactSelectRef.current.state;
      const currentOptions = new Set(currentSelected);
      const unselectedOptions = visibleOptionsAndGroups
        .flatMap((option) => (isSelectOption(option) ? option : option.options))
        .filter((option) => !currentOptions.has(option) && !option.disabled);
      if (unselectedOptions.length > 0) {
        reactSelectRef.current.setValue(multiValueAsValue([...currentSelected, ...unselectedOptions]), 'select-option');
      }
    }
  };

  /** Variable for user typed inputValue to enable sticky search text */
  const [inputValueState, setInputValueState] = useState(upstreamInputValue || '');
  const inputFieldValue = upstreamInputValue ?? inputValueState;

  const value = useMemo(() => normalizeValues(upstreamValue), [upstreamValue]);

  const options = useMemo(() => normalizeValues(upstreamOptions), [upstreamOptions]);
  const initialAsyncOptions = useMemo(
    () => normalizeValues(upstreamInitialAsyncOptions),
    [upstreamInitialAsyncOptions]
  );

  const loadAsyncOptions = useCallback(
    async (inputFieldValue: string): Promise<readonly (StructuredOption | SelectGroupBase<StructuredOption>)[]> => {
      if (upstreamLoadAsyncOptions) {
        const upstreamAsyncOptions = await upstreamLoadAsyncOptions(inputFieldValue);
        return normalizeValues(upstreamAsyncOptions);
      } else {
        return selectFilterOptions(inputFieldValue, options || []);
      }
    },
    [options, upstreamLoadAsyncOptions]
  );

  const onChangeHandler = (value: OnChangeValue<StructuredOption, IsMulti>): void => {
    if (Array.isArray(value)) {
      /** Not necessary to update uncontrolledSelectedOptions when !isMulti because the default behavior from react-select is not customized. */
      uncontrolledSelectedOptions.current = value;
    }
    if (onChange) {
      const fakeEvent = new FakeEvent({ value, id });
      onChange(fakeEvent);
    }
  };

  /** Process text typed into the search field.
   *
   * Prevents the search text from being cleared by react-select as options are
   * selected.
   */
  const onInputChangeHandler = (userInput: string, actionMeta: InputActionMeta): string => {
    const maybeNewInputValue = onInputChange?.(userInput, actionMeta);
    // If the application wants to override onInputChangeHandler, they can
    if (typeof maybeNewInputValue === 'string') {
      userInput = maybeNewInputValue;
    }
    // sticky search text
    else if (actionMeta.action === 'input-change') {
      setInputValueState(userInput);
    } else if (actionMeta.action === 'set-value') {
      userInput = actionMeta.prevInputValue;
    }
    /** react-select uses a string return value to control what options are
     * available. We need to ensure that this value matches `inputFieldValue`
     */
    return userInput;
  };

  /** Resets state when the menu is closed */
  const onMenuCloseHandler = useCallback((): void => {
    onMenuClose?.();
    handleFocusedOptionData(null);
    if (upstreamInputValue === undefined) setInputValueState('');
  }, [onMenuClose, handleFocusedOptionData, upstreamInputValue]);

  const menuPortalTarget = disablePortal ? undefined : portalData.portalNode;

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>): void => {
      onKeyDown?.(e);
      // Don't do further processing if onKeyDown called e.preventDefault().
      if (!e.defaultPrevented) {
        /**
         * Backspace events are a special case as they are executed on the values
         * in the input regardless of where focus is
         *
         * e.g., if an option in the input is in focus, that is the target;
         * otherwise, the target is the last item in the input
         */
        if (e.key === 'Backspace' || e.key === 'Delete') {
          /** If there is not user input, we must manage the backspace event */
          if (inputFieldValue.length === 0) {
            // If the currently focused item is disabled, do not allow its removal
            if (focusedMultiValueData.current && customIsOptionDisabled(focusedMultiValueData.current)) {
              e.preventDefault();
            } else {
              /**
               * Handle when no item is in focus
               *
               * When no item is in focus, the target of the backspace event is the last option in the input
               */
              const lastOption = (() => {
                // If 'value' is an array, return the last item from it
                if (Array.isArray(value)) {
                  return value[value.length - 1];
                }

                // Else, if 'uncontrolledSelectedOptions' is non-empty, return the last item from it
                else if (uncontrolledSelectedOptions?.current?.length) {
                  return uncontrolledSelectedOptions.current[uncontrolledSelectedOptions.current.length - 1];
                }
              })();

              // Prevent default action if the last selected option is disabled
              if (lastOption && customIsOptionDisabled(lastOption)) {
                e.preventDefault();
              }
            }
          }
        } else if (e.key === 'Enter' || e.key === ' ' || e.key === 'Tab') {
          /**
           * These Key Events are only effectuated on elements in the drop down
           *
           * do not clear focus item if it is disabled
           */
          if (focusedOptionData.current && customIsOptionDisabled(focusedOptionData.current)) {
            e.preventDefault();
          }
        }
      }
    },
    [onKeyDown, value, inputFieldValue]
  );

  /** the reconciled size of the Select based on the layout and size parameters
   *
   * super-compact layout === small size
   * compact layout === medium size
   * large layout === x-large size
   *
   * large size is the default Select size
   */
  const reconciledSize = reconcileSelectSize(layout, size);

  return (
    <SelectContext.Provider
      value={{
        enableSelectAll,
        required: required,
        handleFocusedOptionData,
        handleFocusedMultiValueData,
        icon,
        size: reconciledSize,
        handleSelectAll,
      }}
    >
      <AsyncCreatable<StructuredOption, IsMulti, SelectGroupBase<StructuredOption>>
        {...classes({
          modifiers: {
            'required-styles-hidden': !!hideRequiredStyles,
            'show-input-icon': !!icon,
            [reconciledSize]: true,
          },
          states: {
            disabled,
            error: error === '' || error === undefined ? false : !disabled,
            required: required && !hideRequiredStyles && !disabled,
          },
          extra: className,
        })}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
        cacheOptions={cacheOptions}
        classNamePrefix={classesAsStr()}
        closeMenuOnScroll={closeMenuOnScroll}
        closeMenuOnSelect={closeMenuOnSelect}
        components={{
          ClearIndicator: CustomClearIndicator,
          DropdownIndicator: CustomDropdownIndicator,
          GroupHeading: CustomGroupHeading,
          // Hide indicatorSeparator (the line to the left of the dropdown carrot) when isClearable is false.
          ...(!isClearable && { IndicatorSeparator: null }),
          Input: CustomInput,
          LoadingIndicator: CustomLoadingIndicator,
          MenuList: CustomMenuList,
          MultiValue: CustomMultiValue,
          Option: CustomOption,
          ValueContainer: CustomValueContainer,
          ...components,
        }}
        defaultOptions={options ? options : initialAsyncOptions}
        defaultValue={defaultValue}
        getOptionLabel={customGetOptionLabel}
        getOptionValue={customGetOptionValue}
        hideSelectedOptions={false}
        id={id}
        instanceId={instanceId}
        inputValue={inputFieldValue}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={isMulti}
        isOptionDisabled={customIsOptionDisabled}
        isSearchable={true}
        isValidNewOption={isValidNewOption}
        loadingMessage={getFunctionFromString(loadingMessage)}
        loadOptions={loadAsyncOptions}
        menuPlacement={'bottom'}
        menuPortalTarget={menuPortalTarget}
        menuPosition={'fixed'}
        noOptionsMessage={getFunctionFromString(noOptionsMessage)}
        onChange={onChangeHandler}
        onInputChange={onInputChangeHandler}
        onKeyDown={handleKeyDown}
        onMenuClose={onMenuCloseHandler}
        options={options}
        placeholder={placeholder}
        tabSelectsValue={tabSelectsValue}
        value={value}
        {...rest}
        ref={reactSelectRef}
      />
    </SelectContext.Provider>
  );
};

/** Custom validator for the 'icon' prop */
const iconPropType: Validator<ForgeIconComponent | undefined> = (props, propName, componentName) => {
  const icon = props[propName as keyof SelectProps<true>] as ForgeIconComponent | undefined;
  const iconSize = icon?.size;

  if (icon && iconSize) {
    if (iconSize === 'large') {
      return new Error(
        `Invalid combination of prop
         ${propName}, and size supplied to ${componentName}.
         Large icons are not advised for use in a Select component. Please use a Small icon.`
      );
    }
  }
  return null;
};

const selectPropTypes: WeakValidationMap<SelectComponentProps<boolean> & { '...rest': unknown }> = {
  allowUserCreatedOptions: PropTypes.bool,
  'aria-label': PropTypes.string,
  'aria-labelledby': PropTypes.string,
  cacheOptions: PropTypes.bool,
  className: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  /**
   * [react-select Async component](https://react-select.com/props#replacing-components)
   * components prop. This gets swallowed and used to override the default
   * components.
   */
  components: PropTypes.shape({
    ClearIndicator: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['ClearIndicator']
    >,
    Control: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['Control']
    >,
    DropdownIndicator: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['DropdownIndicator']
    >,
    DownChevron: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['DownChevron']
    >,
    CrossIcon: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['CrossIcon']
    >,
    Group: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['Group']
    >,
    GroupHeading: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['GroupHeading']
    >,
    IndicatorsContainer: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['IndicatorsContainer']
    >,
    IndicatorSeparator: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['IndicatorSeparator']
    >,
    Input: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['Input']
    >,
    LoadingIndicator: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['LoadingIndicator']
    >,
    Menu: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['Menu']
    >,
    MenuList: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['MenuList']
    >,
    MenuPortal: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['MenuPortal']
    >,
    LoadingMessage: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['LoadingMessage']
    >,
    NoOptionsMessage: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['NoOptionsMessage']
    >,
    MultiValue: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['MultiValue']
    >,
    MultiValueContainer: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['MultiValueContainer']
    >,
    MultiValueLabel: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['MultiValueLabel']
    >,
    MultiValueRemove: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['MultiValueRemove']
    >,
    Option: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['Option']
    >,
    Placeholder: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['Placeholder']
    >,
    SelectContainer: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['SelectContainer']
    >,
    SingleValue: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['SingleValue']
    >,
    ValueContainer: PropTypes.elementType as Validator<
      SelectComponents<StructuredOption, boolean, SelectGroupBase<StructuredOption>>['ValueContainer']
    >,
  }),
  defaultValue: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.shape({
        color: PropTypes.oneOf<TagColor>(tagColors) as Validator<TagColor | undefined>,
        disabled: optionalBoolValidator,
        label: optionalStringValidator,
        textVariant: PropTypes.oneOf<TextVariant>(['default', 'impact']) as Validator<TextVariant | undefined>,
        value: PropTypes.string.isRequired,
      }).isRequired,
    ]).isRequired
  ),
  disabled: PropTypes.bool,
  disablePortal: PropTypes.bool,
  enableSelectAll: PropTypes.bool,
  error: PropTypes.string,
  hideRequiredStyles: PropTypes.bool,
  icon: iconPropType,
  id: PropTypes.string,
  initialAsyncOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.shape({
        color: PropTypes.oneOf<TagColor>(tagColors) as Validator<TagColor | undefined>,
        disabled: optionalBoolValidator,
        label: optionalStringValidator,
        textVariant: PropTypes.oneOf<TextVariant>(['default', 'impact']) as Validator<TextVariant | undefined>,
        value: PropTypes.string.isRequired,
      }).isRequired,
      PropTypes.shape({
        label: optionalStringValidator,
        options: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
              color: PropTypes.oneOf<TagColor>(tagColors) as Validator<TagColor | undefined>,
              disabled: optionalBoolValidator,
              label: optionalStringValidator,
              textVariant: PropTypes.oneOf<TextVariant>(['default', 'impact']) as Validator<TextVariant | undefined>,
              value: PropTypes.string.isRequired,
            }).isRequired,
          ]).isRequired
        ).isRequired,
      }).isRequired,
    ]).isRequired
  ),
  inputId: PropTypes.string,
  /** Set to true to display a button for clearing current selections. */
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isValidNewOption: PropTypes.func,
  loadAsyncOptions: PropTypes.func,
  loadingMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  noOptionsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Function that takes a JS event as an argument. Called when the Select loses focus. */
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  /** Function that takes a JS event as an argument. Called when the Select receives focus. */
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.shape({
        color: PropTypes.oneOf<TagColor>(tagColors) as Validator<TagColor | undefined>,
        disabled: optionalBoolValidator,
        label: optionalStringValidator,
        textVariant: PropTypes.oneOf<TextVariant>(['default', 'impact']) as Validator<TextVariant | undefined>,
        value: PropTypes.string.isRequired,
      }).isRequired,
      PropTypes.shape({
        label: optionalStringValidator,
        options: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
              color: PropTypes.oneOf<TagColor>(tagColors) as Validator<TagColor | undefined>,
              disabled: optionalBoolValidator,
              label: optionalStringValidator,
              textVariant: PropTypes.oneOf<TextVariant>(['default', 'impact']) as Validator<TextVariant | undefined>,
              value: PropTypes.string.isRequired,
            }).isRequired,
          ]).isRequired
        ).isRequired,
      }).isRequired,
    ]).isRequired
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.oneOf(SELECT_SIZES),
  tabSelectsValue: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.shape({
          color: PropTypes.oneOf<TagColor>(tagColors) as Validator<TagColor | undefined>,
          disabled: optionalBoolValidator,
          label: optionalStringValidator,
          textVariant: PropTypes.oneOf<TextVariant>(['default', 'impact']) as Validator<TextVariant | undefined>,
          value: PropTypes.string.isRequired,
        }).isRequired,
      ]).isRequired
    ),
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.shape({
        color: PropTypes.oneOf<TagColor>(tagColors) as Validator<TagColor | undefined>,
        disabled: optionalBoolValidator,
        label: optionalStringValidator,
        textVariant: PropTypes.oneOf<TextVariant>(['default', 'impact']) as Validator<TextVariant | undefined>,
        value: PropTypes.string.isRequired,
      }).isRequired,
    ]),
  ]),
  /**
   * Props passed down to underlying [react-select Async component](https://react-select.com/props)
   */
  '...rest': PropTypes.any,
};

SelectComponent.propTypes = selectPropTypes;
SelectComponent.displayName = 'Select';

export default withPortalDataAndRef(SelectComponent) as SelectType;
