import { ReactNode, useContext } from 'react';
import { PageContext } from '../../../contexts';
import * as DemoIndex from '../../../demos';

const Demos = (): ReactNode => {
  const { pageContext } = useContext(PageContext);
  const componentTitle = pageContext?.title as keyof typeof DemoIndex | undefined;

  const ComponentDemos = componentTitle && DemoIndex[componentTitle] ? DemoIndex[componentTitle].default : null;
  return ComponentDemos && <ComponentDemos />;
};

export default Demos;
