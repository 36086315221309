import { Overlay, OverlayProps } from '@restart/ui';
import { OverlayInjectedProps } from '@restart/ui/esm/Overlay';
import { Placement } from '@restart/ui/usePopper';
import React, { ReactElement, Ref } from 'react';
import { CSSTransition } from 'react-transition-group';
import { PortalContextProps, withPortalData } from '../PortalProvider';
import { Theme } from '../Root/context';
import { forgeClassHelper } from '../utils/classes';
import { PlacementTypes } from './Tooltip';

const classes = forgeClassHelper({ name: 'tooltip', isPortal: true });

interface FadeProps {
  children?: React.ReactNode;
}

const FastFade = ({ children, ...transitionProps }: FadeProps): ReactElement => {
  return (
    <CSSTransition timeout={200} classNames="fast-fade" {...transitionProps}>
      {children}
    </CSSTransition>
  );
};

interface TooltipOverlayProps extends Omit<OverlayProps, 'children'>, PortalContextProps {
  children?: React.ReactNode;
  className?: string;
  id: string;
  theme?: Theme;
  placement?: PlacementTypes;
}

export interface TipProps extends Omit<OverlayInjectedProps, 'ref'> {
  // arrowProps is provided by <Overlay> not user input, so we can narrow the type as HTMLAttributes
  arrowProps?: React.HTMLAttributes<HTMLDivElement>;
  children?: React.ReactNode;
  className?: string;
  id: string;
  placement?: Placement;
  theme?: Theme;
}

const Tip = React.forwardRef(
  (
    { arrowProps, children, className, id, placement, theme, ...props }: TipProps,
    ref: Ref<HTMLDivElement>
  ): ReactElement => {
    return (
      <div ref={ref} {...classes({ states: placement, extra: className, theme })} {...props}>
        <div id={id} role="tooltip" {...classes({ element: 'body' })}>
          <div {...classes({ element: 'body-content' })}>{children}</div>
        </div>
        <div {...classes({ element: 'arrow' })} {...arrowProps}></div>
      </div>
    );
  }
);

Tip.displayName = 'Tip';

function TooltipOverlay({
  children,
  className,
  id,
  placement,
  show,
  theme,
  target,
  portalData: { portalNode },
  ...passedProps
}: TooltipOverlayProps): JSX.Element {
  return (
    <Overlay
      show={show}
      flip={true}
      placement={placement}
      offset={[0, 10]}
      target={target}
      transition={FastFade}
      {...passedProps}
      container={portalNode}
    >
      {(overlayProps, { arrowProps, popper }) => (
        <Tip
          className={className}
          theme={theme}
          id={id}
          arrowProps={arrowProps}
          placement={popper?.placement}
          {...overlayProps}
        >
          {children}
        </Tip>
      )}
    </Overlay>
  );
}
export default withPortalData(TooltipOverlay);
