import RawDataPreview from '../../components/raw-data-preview';

import rawContents from "!!raw-loader!./RequiredCurrencyRange.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { RawDataPreview };

function RequiredCurrencyRange():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Required Currency Range" />;
}

export default RequiredCurrencyRange;
