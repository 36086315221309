import { ReactNode } from 'react';
import ListItem from '../../ListItem';
import ProgressIndicator, { ProgressIndicatorProps } from '../../ProgressIndicator/ProgressIndicator';
import { forgeClassHelper } from '../../utils/classes';
import { FileUploadAction, FileUploadFile } from '../fileUploadReducer';

const classes = forgeClassHelper('file-upload');

export interface FileUploadInProgressProps {
  dispatch: React.Dispatch<FileUploadAction>;
  file: FileUploadFile;
}

/** Renders a file that is in progress of being uploaded*/
const FileUploadInProgress = ({ file }: FileUploadInProgressProps): ReactNode => {
  const progress: ProgressIndicatorProps =
    typeof file.percentComplete === 'number'
      ? { variant: 'determinate', currentValue: file.percentComplete }
      : { variant: 'indeterminate' };
  return (
    <ListItem uniqueKey={file.id} {...classes({ element: 'in-progress-list-item' })} key={file.id}>
      <ProgressIndicator description={`${file.fileData.name} uploading...`} shape="linear" {...progress} />
    </ListItem>
  );
};

export default FileUploadInProgress;
