import Tag from '../../../Tag';
import { TagColor } from '../../../Tag/Tag';
import { DataTableColumn, CreateColumnCommonArgs } from '../../utils/internalTypes';
import { classes } from '../../utils/dataTableClasses';

export type DataTableTagColumnOption = { label: string; semanticColor?: TagColor };

type CreateTagColumnArgs<RowData> = Omit<
  CreateColumnCommonArgs<RowData>,
  'cellValidation' | 'enableClientSideSorting'
> & {
  tagOptions: Record<string, DataTableTagColumnOption>;
  clientSideSortAscFn?: (row1: RowData, row2: RowData) => number;
  /**
   * Use `DT.ColumnFilter.create*` to create columnFilter instance.
   * - `DT.ColumnFilter.createSelectColumnFilter`
   * - `DT.ColumnFilter.createSelectionListColumnFilter`
   */
  // Internal Dev Note:
  // The second type parameter `FilterConditionValue` should be set to `any` to
  // allow users to override it. Overwriting with the `unknown` type is not allowed.
  columnFilter?: DataTableColumn<RowData, any>['columnFilter']; // eslint-disable-line @typescript-eslint/no-explicit-any
  serverSideQueryParamFn?: (selectedValues: string[]) => string;
};
export type CreateTagColumn<RowData> = typeof createTagColumn<RowData>;

export function createTagColumn<RowData>({
  columnId,
  tooltip,
  headerText,
  footer,
  determineCellAlert = () => '',
  determineCellTooltip,
  columnFilter,
  clientSideSortAscFn,
  displayCellText,
  tagOptions,
  rowDataFieldName,
  enableClientSideFullTextSearch = true,
  enableClientSideCsvDownload = true,
  isConfigurable = true,
  defaultHideColumn = false,
}: CreateTagColumnArgs<RowData>): DataTableColumn<RowData> {
  const headerTextArray = typeof headerText === 'string' ? [headerText] : headerText;

  if (typeof displayCellText === 'undefined') {
    displayCellText = (row: RowData): string => {
      const tags = row[rowDataFieldName] as string[];
      return tags.map((tagKey) => tagOptions[tagKey]?.label ?? tagKey).join(', ');
    };
  }

  return {
    columnId,
    configOptionName: isConfigurable ? headerTextArray.join(' ') : '',
    defaultHideColumn,

    // ================================
    // Header
    // ================================
    headerTooltip: tooltip,
    Header: () => (
      <>
        {headerTextArray.map((txt, idx) => (
          <div key={idx}>{txt}</div>
        ))}
      </>
    ),

    // ================================
    // Footer
    // ================================
    Footer: footer,

    // ================================
    // Cell
    // ================================
    Cell: ({ useSelector, rowId }) => {
      const rowData = useSelector((s) => s.rows[rowId].data);
      const tableLayout = useSelector((s) => s.tableLayout);
      const tags = rowData[rowDataFieldName] as string[];

      const isEmpty = tags.length === 0;

      return (
        <div
          {...classes({
            element: 'tag_column',
            modifiers: { medium: tableLayout === 'medium', compact: tableLayout === 'compact' },
          })}
        >
          {isEmpty
            ? '---'
            : tags.map((tagKey, index) => {
                try {
                  const { semanticColor, label } = tagOptions[tagKey];
                  return (
                    <Tag
                      key={index}
                      text={label}
                      color={semanticColor}
                      size={tableLayout === 'compact' ? 'small' : 'medium'}
                      isRemovable={false}
                    />
                  );
                } catch (e) {
                  return <div key={index}>{tagKey}</div>;
                }
              })}
        </div>
      );
    },

    // ================================
    // Cell Alert
    // ================================
    determineCellAlert,

    // ================================
    // Cell Tooltip
    // ================================
    determineCellTooltip,

    // ================================
    // Sorting
    // ================================
    clientSideSortAscFn,

    // ================================
    // Column Filter
    // ================================
    columnFilter,

    // ================================
    // Full-Text Search
    // ================================
    clientSideFullTextSearch: {
      enable: enableClientSideFullTextSearch,
      getTextByRow: displayCellText,
    },

    // ================================
    // Download CSV
    // ================================
    clientSideCsvDownload: {
      enable: enableClientSideCsvDownload,
      headerText: headerTextArray.join(' '),
      getTextByRow: displayCellText,
    },
  };
}
