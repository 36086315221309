/*
 * The DateInput Component
 */
import parse from 'date-fns/parse';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import getYear from 'date-fns/getYear';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import dfIsSameDay from 'date-fns/isSameDay';
import isValidDate from 'date-fns/isValid';
import subYears from 'date-fns/subYears';
import enUS from 'date-fns/locale/en-US';
import type { Locale } from 'date-fns';
import PropTypes from 'prop-types';
import React, {
  FocusEvent,
  ReactElement,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  WeakValidationMap,
} from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { forgeClassHelper } from '../utils/classes';
import FakeEvent from '../utils/fake-event';
import DateInputCalendarContainer from './DateInputCalendarContainer';
import DateInputHeader from './DateInputHeader';
import DateInputInput from './DateInputInput';
import { PortalContextProps, withPortalDataAndRef } from '../PortalProvider/PortalContext';

const validInputFormats = [
  'MM-dd-yy',
  'MM-dd-yyyy',
  'MM/dd/yy',
  'MM/dd/yyyy',
  'Mddyy',
  'Mddyyyy',
  'MM',
  'MM-dd',
  'MM/dd',
];

export type DateInputValue = Date | null;

export type DateInputOnChangeEvent = FakeEvent<Date | null>;

export interface DateInputRefType extends DatePicker {
  input: HTMLInputElement;
}

export interface DateInputProps extends Omit<ReactDatePickerProps, 'onChange' | 'value'> {
  /** @deprecated since 11.0.0, use screen.getByRole('textbox') instead
   *
   *  Adds a custom data-testid attribute to the DateInput input */
  dataTestidCalendarInput?: string;
  /** Determines the initial value of the input, but allows it to be updated ([uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)). This is the selected date shown in the input field and the calendar picker */
  defaultValue?: DateInputValue;
  /** Highlights the input when defined (specific string doesn't matter) */
  error?: string;
  /** Hides any required styling. Can be set manually or by the required field variation set for [`Form`](form) */
  hideRequiredStyles?: boolean;
  /** DataInput take custom locales as props, pass that in as a Locale object. */
  locale?: Locale;
  /**
   * Function that takes a fake event as an argument.
   * This events target value is a [JS Date object](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date) and id is a string.
   * Called when the DateInput value changes to a valid date from the user typing, using a shortcut, or choosing a day in the calendar
   */
  onChange?: (event: DateInputOnChangeEvent) => void;
  /** Text to show in the input if it has no value and is unfocused. */
  placeholder?: string;
  /** Ref forwarded to the input element */
  ref?: Ref<DateInputRefType>;
  /** Indicates if it is a required field. Passed as an attribute to the native react `<input />` */
  required?: boolean;
  /**
   * Selected date shown in the input field and the calendar picker.
   * Creates a [controlled](https://reactjs.org/docs/forms.html#controlled-components) component
   */
  value?: DateInputValue;
}

const defaultDateFormat = 'MM-dd-yyyy';

// Style the calendar popup's offset from the input using Popper's config.
const popperModifiers: ReactDatePickerProps['popperModifiers'] = [
  {
    name: 'offset',
    options: {
      offset: [0, 4],
    },
  },
];

export const classes = forgeClassHelper({ name: 'date-input' });
const classesAsStr = forgeClassHelper({ name: 'date-input', outputIsString: true });

/** Return a new date whose time component has been set to midnight */
function truncateToMidnight(date: Date | null): Date | null {
  if (date) {
    const newDate = new Date(date);
    date.setHours(0.0, 0.0, 0.0, 0.0);
    return newDate;
  } else {
    return date;
  }
}

interface NormalizeDateArgs {
  date: Date | null;
  minDate: Date | null | undefined;
  maxDate: Date | null | undefined;
  filterDate: ((date: Date) => boolean) | undefined;
}

/** Restricts the date to fall within the bounds of minDate <= date <= maxDate. */
function normalizeDateWithMinMax({
  date,
  minDate,
  maxDate,
}: Pick<NormalizeDateArgs, 'date' | 'minDate' | 'maxDate'>): Date | null {
  if (!date) {
    return date;
  } else if (maxDate && date > maxDate) {
    return new Date(maxDate);
  } else if (minDate && date < minDate) {
    return new Date(minDate);
  } else {
    return new Date(date);
  }
}

/** Rejects date that fails the filterDate function if filterDate is provided. */
function normalizeDateWithFilter({ date, filterDate }: Pick<NormalizeDateArgs, 'date' | 'filterDate'>): Date | null {
  if (date && filterDate) {
    return filterDate(date) ? date : null;
  } else {
    return date;
  }
}

/** Perform sanity checking on a typed date before accepting it.
 *
 * Restricts the date to fall within the bounds of minDate <= date <= maxDate.
 * Rejects date that fails the filterDate function if filterDate is provided.
 */
function normalizeDate({ date, minDate, maxDate, filterDate }: NormalizeDateArgs): Date | null {
  const minMaxDate = normalizeDateWithMinMax({ date, minDate, maxDate });
  return normalizeDateWithFilter({ date: minMaxDate, filterDate });
}

/** Repurposed util function from react-datepicker */
export function isSameDay(date1: Date | null, date2: Date | null): boolean {
  if (date1 && date2) {
    return dfIsSameDay(date1, date2);
  } else {
    return !date1 && !date2;
  }
}
/**
 * Repurposed util function from react-datepicker
 *
 * Evaluates the validity of a given date in terms of supplied date constraints
 * */
export function isValid(date: Date | null | false, minDate: Date | null, maxDate: Date | null): date is Date | null {
  if (date === null) return true;
  else {
    return (
      !!date &&
      isValidDate(date) &&
      (minDate === null || !isBefore(date, minDate)) &&
      (maxDate === null || !isAfter(date, maxDate))
    );
  }
}
interface DateParserArgs {
  input: string;
  minDate: Date | null;
  maxDate: Date | null;
  strictParsing?: boolean;
  event?: FocusEvent<HTMLInputElement, Element>;
  filterDate?: (date: Date) => boolean;
}

type ParsedDateData = Date | null | false;

/** If we do not have input, return with null value for reset */
const emptyInputParser = (args: DateParserArgs): ParsedDateData => (args.input ? false : null);

/** If we have raw input that matches "t" pattern, parse and return normalized date */
const tInputParser = (args: DateParserArgs): ParsedDateData => {
  const trimmedInput = args.input.trim().toLowerCase();
  const today = new Date();

  today.setHours(0.0, 0.0, 0.0, 0.0);
  let normalizedDate: Date | null = null;

  /** Handle "t" input: return today normalized */
  if (trimmedInput === 't') {
    normalizedDate = normalizeDate({
      date: today,
      minDate: args.minDate,
      maxDate: args.maxDate,
      filterDate: args.filterDate,
    });
  }

  /** Handle "t +/- <num>" input: Calculate the new date based on the offset value in relation to today */
  if (trimmedInput.match(/^t([+-]\d+)$/)) {
    const matches = trimmedInput.match(/[+-]\d+/);
    const numberOfDays = matches ? parseInt(matches[0], 10) : 0;

    normalizedDate = normalizeDate({
      date: addDays(today, numberOfDays),
      minDate: args.minDate,
      maxDate: args.maxDate,
      filterDate: args.filterDate,
    });
  }

  /** if we have a date derived from "t" variety input, return that value */
  return normalizedDate || false;
};

/** Enforce strict adherence to date format in accordance to default setting */
const strictInputParser = (args: DateParserArgs): ParsedDateData => {
  /** If strict parsing is not required, return false and continue to next parser */
  if (!args.strictParsing) return false;

  const candidateDate = normalizeDate({
    date: parse(args.input, defaultDateFormat, new Date()),
    minDate: args.minDate,
    maxDate: args.maxDate,
    filterDate: args.filterDate,
  });

  /** Does the format of our input strictly conform to the default format setting */
  const strictParsingValueMatch =
    candidateDate instanceof Date &&
    isValidDate(candidateDate) &&
    format(candidateDate, defaultDateFormat) === args.input;

  return strictParsingValueMatch ? candidateDate : null;
};

/** If we have a string, attempt to parse for supported format and return with normalized date*/
const stringInputParser = (args: DateParserArgs): ParsedDateData => {
  const supportedFormat = validInputFormats.find((candidateFormat) => {
    const candidateDate = parse(args.input, candidateFormat, new Date());
    return !isNaN(candidateDate.getTime());
  });

  const parsedDate = supportedFormat
    ? normalizeDate({
        date: parse(args.input, supportedFormat, new Date()),
        minDate: args.minDate,
        maxDate: args.maxDate,
        filterDate: args.filterDate,
      })
    : false;

  return parsedDate;
};

/**
 * Manage date parsing
 *
 * Iterate over our parsers and return first instance of a successfully parsed date
 *
 * Null and Date return types are considered successful
 * A False return type is a negative
 *
 */
const dateParsingHandler = (args: DateParserArgs): ParsedDateData => {
  const parsers = [emptyInputParser, tInputParser, strictInputParser, stringInputParser];

  for (const parser of parsers) {
    const parsedDate = parser(args);
    if (parsedDate === null || parsedDate instanceof Date) return parsedDate;
  }
  return false;
};
interface GenerateDateProps {
  input: string;
  strictParsing: boolean;
  minDate: Date | null;
  maxDate: Date | null;
  filterDate?: (date: Date) => boolean;
  event: FocusEvent<HTMLInputElement, Element>;
  handleChange: (date: Date | null, event: React.SyntheticEvent<unknown, Event> | undefined) => void;
}

const generateDate = ({
  input,
  strictParsing,
  minDate,
  maxDate,
  filterDate,
  event,
  handleChange,
}: GenerateDateProps): Date | null => {
  const parsedDate = dateParsingHandler({
    input,
    minDate,
    maxDate,
    event,
    filterDate,
    strictParsing,
  });
  /** Handle date validity */
  if (isValid(parsedDate, minDate, maxDate)) {
    handleChange(parsedDate, event);
    return parsedDate;
  }

  /** Cannot definitively parse date, so return null and do not invoke handleChange*/
  return null;
};
export interface DateInputComponentProps extends DateInputProps, PortalContextProps {
  /** Ref forwarded to the input element */
  forwardedRef?: Ref<DateInputRefType>;
}

export const defaultLocale = 'en-US';

function DateInput({
  className,
  customInput,
  dataTestidCalendarInput = 'calendar-input',
  defaultValue,
  disabled = false,
  error,
  hideRequiredStyles = false,
  required = false,
  value: controlledValue,
  /* specifically swallow unused props */
  /* eslint-disable no-unused-vars, react/prop-types */
  onChange,
  onSelect,
  autoComplete,
  calendarClassName,
  clearButtonTitle,
  dateFormat,
  dropdownMode,
  filterDate,
  forceShowMonthNavigation,
  forwardedRef,
  id,
  isClearable,
  locale = enUS,
  onBlur,
  onChangeRaw,
  placeholder = 'MM-DD-YYYY',
  placeholderText,
  portalData: { portalNode },
  preventOpenOnFocus = true,
  scrollableYearDropdown,
  selected,
  showMonthDropdown,
  showYearDropdown,
  strictParsing,
  yearDropdownItemNumber,
  disabledKeyboardNavigation = false,
  enableTabLoop = true,
  minDate: originalMinDate = subYears(new Date(getYear(new Date()), 0, 1), 5),
  maxDate: originalMaxDate = addYears(new Date(getYear(new Date()), 11, 31), 5),
  ...passthroughProps
}: DateInputComponentProps): ReactElement {
  const inputProps = {
    ...passthroughProps,
    dataTestidCalendarInput,
    defaultValue,
    disabled,
    id,
    locale,
    placeholder,
    preventOpenOnFocus,
  };

  const [date, setDate] = useState(defaultValue);

  // Dates should only have the specificity to day rather than hours
  const minDate = useMemo(() => truncateToMidnight(originalMinDate), [originalMinDate]);
  const maxDate = useMemo(() => truncateToMidnight(originalMaxDate), [originalMaxDate]);
  const datePickerRef = useRef<DateInputRefType | null>(null);

  // Forward the ref if it is passed in
  useImperativeHandle<DateInputRefType | null, DateInputRefType | null>(forwardedRef, () => datePickerRef.current);

  const handleBlur = useCallback(
    (event?: FocusEvent<HTMLInputElement>): void => {
      if (event && onBlur) {
        onBlur(event);
      }
      // this formats the date on blur
      // fixes a bug where if the user tabbed into the date input entered an
      // incorrectly formatted date and tabbed out it was never formatted
      datePickerRef.current?.setState({ inputValue: null });
    },
    [onBlur]
  );

  const handleSelect: ReactDatePickerProps['onSelect'] = useCallback(
    (newValue: Date, event?: React.SyntheticEvent<unknown, Event>) => {
      /** we are in an uncontrolled instance when controlledValue is undefined; so, update date */
      if (controlledValue === undefined) setDate(newValue);
      if (onSelect) onSelect(newValue, event);
    },
    [controlledValue, onSelect]
  );

  const handleChange: ReactDatePickerProps['onChange'] = useCallback(
    (newValue: Date | null, event: React.SyntheticEvent<unknown, Event> | undefined) => {
      if (onChange) {
        onChange(new FakeEvent({ value: newValue, id }) as DateInputOnChangeEvent);
      }
      /** we are in an uncontrolled instance when controlledValue is undefined; so, update date */
      if (controlledValue === undefined) setDate(newValue);
      else if (onSelect && newValue) onSelect(newValue, event);
    },
    [controlledValue, id, onChange, onSelect]
  );

  const handleChangeRaw: ReactDatePickerProps['onChangeRaw'] = useCallback(
    function (event: FocusEvent<HTMLInputElement, Element>) {
      if (onChangeRaw) onChangeRaw(event);

      /** React-datepicker has inaccurate types for `event`, because keyboard
       * or mouse events are called when selecting on a date in the calendar.
       * In which case, there is no text to parse. */
      if (
        event.target instanceof HTMLInputElement &&
        /** Only do further processing if onChangeRaw doesn't call event.preventDefault() */
        typeof event.isDefaultPrevented === 'function' &&
        !event.isDefaultPrevented()
      ) {
        const { value: input } = event.target;

        datePickerRef.current &&
          datePickerRef.current.setState({
            inputValue: input,
            lastPreSelectChange: 'input',
          });

        // Override handling in react-datepicker's handleChange method:
        // https://github.com/Hacker0x01/react-datepicker/blob/v4.13.0/src/index.jsx#L485
        event.preventDefault();

        const renderDate = generateDate({
          input,
          strictParsing: !!strictParsing,
          minDate,
          maxDate,
          filterDate,
          event,
          handleChange,
        });

        setDate(renderDate);
      }
    },
    [handleChange, onChangeRaw, filterDate, maxDate, minDate, strictParsing]
  );

  const portalHost = useMemo(() => {
    const rootNode = portalNode.getRootNode();
    return rootNode instanceof ShadowRoot ? rootNode : undefined;
  }, [portalNode]);

  /** Handles updating pattern of "selected" based on controlled or uncontrolled instance of DateInput */
  const manageSelected = (): Date | null | undefined => {
    /** handle uncontrolled instance */
    if (controlledValue === undefined) return date;
    /** handle controlled instance */
    return controlledValue;
  };

  return (
    <fieldset {...classes()}>
      <DatePicker
        {...classes({
          element: 'input',
        })}
        autoComplete="off"
        calendarClassName={classesAsStr({ element: 'calendar' })}
        calendarContainer={DateInputCalendarContainer}
        customInput={
          React.isValidElement(customInput) ? (
            customInput
          ) : (
            <DateInputInput
              dataTestidCalendarInput={dataTestidCalendarInput}
              error={error}
              required={required}
              hideRequiredStyles={hideRequiredStyles}
            />
          )
        }
        customInputRef={React.isValidElement(customInput) ? 'ref' : 'inputRef'}
        dateFormat={defaultDateFormat}
        dayClassName={() => classesAsStr({ element: 'day' })}
        disabledKeyboardNavigation={disabledKeyboardNavigation}
        /** disabledKeyboardNavigation===true and enableTabLoop==true
         * cause tabbing out of the input field to hit two non-printable div elements.
         * Force enableTabLoop to be false in this situation
         */
        enableTabLoop={disabledKeyboardNavigation ? false : enableTabLoop}
        filterDate={filterDate}
        minDate={minDate}
        maxDate={maxDate}
        onBlur={handleBlur}
        onChange={handleChange}
        onChangeRaw={handleChangeRaw} // handles 't' shortcut
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleBlur();
          }
        }}
        onSelect={handleSelect}
        placeholderText={placeholder}
        popperClassName={classesAsStr({ element: 'overlay' })}
        popperModifiers={popperModifiers}
        portalHost={portalHost}
        portalId={portalNode.id}
        ref={datePickerRef}
        renderCustomHeader={(props) => (
          <DateInputHeader
            disabledKeyboardNavigation={disabledKeyboardNavigation}
            locale={locale}
            minDate={minDate}
            maxDate={maxDate}
            {...props}
          />
        )}
        required={required}
        selected={manageSelected()}
        strictParsing={strictParsing}
        value={controlledValue === null ? '' : undefined}
        wrapperClassName={classesAsStr({ extra: className })}
        {...inputProps}
      />
    </fieldset>
  );
}

DateInput.displayName = 'DateInput';

export default withPortalDataAndRef(DateInput);

const valuePropType: PropTypes.Validator<DateInputValue> = function (props, propName) {
  let error = null;
  const prop = props[propName];
  if (prop && !(prop instanceof String || typeof prop === 'string') && !(prop instanceof Date)) {
    error = new Error('value must be a String or a Date');
  }
  if (prop instanceof Date) {
    if (props.minDate && prop < props.minDate) {
      error = new Error('value must be greater than minDate');
    }
    if (props.maxDate && prop > props.maxDate) {
      error = new Error('value must be less than maxDate');
    }
  }
  return error;
};

const dateInputPropTypes: WeakValidationMap<DateInputComponentProps & { '...rest': unknown }> = {
  /** Adds classes to the root of DateInput */
  className: PropTypes.string,
  /** Adds a custom data-testid attribute to the DateInput input */
  dataTestidCalendarInput: PropTypes.string,
  /** Applies modifier classes to sets of days. See the [react-datepicker documentation](https://reactdatepicker.com/#example-custom-day-class-name) for an example */
  dayClassName: PropTypes.func,
  /** Determines the initial value of the input, but allows it to be updated ([uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)). This is the selected date shown in the input field and the calendar picker */
  defaultValue: valuePropType,
  /** Applies styles to the input in addition to preventing changes. Passed as an attribute to the underlying `<input />` */
  disabled: PropTypes.bool,
  /** Prevents the calendar from being navigated using the arrow keys. Defaults to `true` to allow the arrow keys to be used to move between characters in the input. You might want to set this to `false` if the calendar is the primary way this component is used */
  disabledKeyboardNavigation: PropTypes.bool,
  /** If enabled, tab will endlessly cycle through sections of the calendar until a date is selected */
  enableTabLoop: PropTypes.bool,
  /** Highlights the input when defined (specific string doesn't matter) */
  error: PropTypes.string,
  /** An array of dates to disable. All other days (within `minDate` and `maxDate`, if provided) are valid. */
  excludeDates: PropTypes.array,
  /**
   * A function taking a JS date and returning a bool
   * indicating whether the date is enabled (`true`) or disabled (`false`).
   */
  filterDate: PropTypes.func,
  /** Hides any required styling. Can be set manually or by the required field variation set for [`Form`](form) */
  hideRequiredStyles: PropTypes.bool,
  /** Adds an id to the underlying `<input />` */
  id: PropTypes.string,
  /** An array of dates to enable. All other days (within minDate and maxDate, if provided) are disabled */
  includeDates: PropTypes.array,
  /** Set to true to display the DateInput inline (open at all times, with no separate input) */
  inline: PropTypes.bool,
  /** The upper bound of the range of the calendar picker */
  maxDate: PropTypes.instanceOf(Date),
  /** The lower bound of the range of the calendar picker */
  minDate: PropTypes.instanceOf(Date),
  /** Adds a name attribute to the underlying `<input />` */
  name: PropTypes.string,
  /**
   * Function that takes a JS event as an argument.
   * Called when the DateInput loses focus.
   * Not triggered when focus shifts between the input and the calendar
   */
  onBlur: PropTypes.func,
  /**
   * Function that takes a fake event as an argument.
   * This events target value is a [JS Date object](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date) and id is a string.
   * Called when the DateInput value changes to a valid date from the user typing, using a shortcut, or choosing a day in the calendar
   */
  onChange: PropTypes.func,
  /**
   * Function that takes a JS event as an argument.
   * This events target value is a string.
   * Called when the DateInput value changes from the user typing
   * Handles shortcuts by calling onChange with the calculated date if the shortcut is valid
   */
  onChangeRaw: PropTypes.func,
  /** Function that takes a JS event as an argument. Called when the DateInput receives focus */
  onFocus: PropTypes.func,
  /**
   * Function that takes the selected Date as an argument.
   * Called when a date is selected in the calendar.
   * Returns a [JS Date object](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date)
   */
  onSelect: PropTypes.func,
  /**
   * Allows you to override the default behavior determining what month the calendar opens to.
   * *Note that this specific date is not pre-selected or highlighted.*
   *
   * By default, the calendar opens to the selected date if one exists,
   * followed by the current date (even if it is outside the calendar range or disabled).
   */
  openToDate: PropTypes.instanceOf(Date),
  /** Text to show in the input if it has no value and is unfocused. */
  placeholder: PropTypes.string,
  /** When `true` calendar picker is not shown automatically on input receiving tab focus */
  preventOpenOnFocus: PropTypes.bool,
  /** Indicates if it is a required field. Passed as an attribute to the native react `<input />` */
  required: PropTypes.bool,
  /**
   * Selected date shown in the input field and the calendar picker.
   * Creates a [controlled](https://reactjs.org/docs/forms.html#controlled-components) component
   */

  value: valuePropType,
  /**
   * DataInput take custom locales as props, pass that in as a Locale object.
   * This prop is also a passthrough prop to react-datepicker, where it is used for additional locale translations.
   */
  locale: PropTypes.oneOfType([PropTypes.object.isRequired]),
  /**
   * Passthrough props to [react-datepicker's DateInput](https://github.com/Hacker0x01/react-datepicker/blob/master/docs/index.md) component.
   *
   * The following react-datepicker props will not be passed:
   * * `calendarClassName`
   * * `clearButtonTitle`
   * * `customInput`
   * * `dateFormat`
   * * `dropdownMode`
   * * `forceShowMonthNav`
   * * `isClearable` (hardcoded to `false`)
   * * `onChangeRaw`
   * * `placeholderText` (use `placeholder` instead)
   * * `scrollableYearDropdown`
   * * `selected` (use Forge `DateInput`'s`value` or `defaultValue` instead)
   * * `showMonthDropdown`
   * * `showYearDropdown`
   * * `value` (Forge's `DateInput` has a `value` prop)
   * * `yearDropdownItemNumber`
   */
  '...rest': PropTypes.any,
};

DateInput.propTypes = dateInputPropTypes;
