import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHomeLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M23 12.3711C22.9985 12.0422 22.8694 11.7266 22.64 11.491L12.4 1.14087C12.3083 1.04988 12.1843 0.999023 12.0551 0.999023C11.9258 0.999023 11.8017 1.04988 11.71 1.14087L1.37 11.491C1.13358 11.7269 1.00051 12.0469 1 12.3809V23.001H9V14.001H15V23.001H23V12.3711Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgHomeLarge.displayName = 'SvgHomeLarge';
const HomeLarge = withForgeIconProps(SvgHomeLarge, {
  name: 'Home',
  usage:
    'Use to return to the homepage or indicate a residence or the homepage',
  related: 'address, mailing, house, apartment, navigation',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default HomeLarge;
