import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React, { ReactElement, useContext, useEffect, useMemo, useState, WeakValidationMap } from 'react';
import { FormFieldLayoutContext } from '../FormFieldLayout/FormFieldLayoutContext';
import { optionalStringValidator } from '../utils/betterPropTypes';
import { forgeClassHelper } from '../utils/classes';
import FakeEvent from '../utils/fake-event';
import forwardRefToProps from '../utils/forwardRefToProps';

const classes = forgeClassHelper({ name: 'toggle-switch' });

export type ToggleSwitchDescriptionPosition = 'left' | 'right';
export type ToggleSwitchSize = 'small' | 'medium' | 'large';
export const toggleSwitchSizes: ToggleSwitchSize[] = ['small', 'medium', 'large'];

export interface ToggleSwitchDescriptions {
  /** String used to accessibly label the toggle button */
  id?: string;
  /** Descriptive label for checked state */
  checked: string;
  /** Descriptive label for unchecked state */
  unchecked: string;
}

/** Provides a Typescript error if checked and defaultChecked are present
 * at the same time.
 */
export interface ToggleSwitchProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onBlur' | 'onChange'> {
  /**
   * Sets the ToggleSwitch to be checked or unchecked.
   * Setting this property makes this a
   * [controlled](https://reactjs.org/docs/forms.html#controlled-components) component
   */
  checked?: boolean;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Sets the initial checked/unchecked state of the switch, while allowing
   * it to be changed by the user
   * ([uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)).*/
  defaultChecked?: boolean;
  /** Sets the position of the description of the ToggleSwitch */
  descriptionPosition?: ToggleSwitchDescriptionPosition;
  /** Provides labels for each state */
  descriptions?: ToggleSwitchDescriptions;
  /** Sets the id attribute on the native `<button>` */
  id?: string;
  /** Sets the ToggleSwitch disabled/enabled */
  disabled?: boolean;
  /** Setting this prop gives the ToggleSwitch 'error' styling */
  error?: string;
  /** Callback on ToggleSwitch blur */
  onBlur?: (event: FakeEvent<boolean>) => void;
  /** Callback on ToggleSwitch checked state changed */
  onChange?: (event: FakeEvent<boolean>) => void;
  /** Ref forwarded to ToggleSwitch's button element */
  ref?: React.Ref<HTMLButtonElement>;
  /** Sets the size of the switch. Defaults to 'medium' unless overridden by
   * Form or FormField layout.
   *
   * Recommendation is to always use the 'large' on mobile. */
  size?: ToggleSwitchSize;
  /** Sets the value attribute on the native `<button>` */
  valueAttribute?: string;
  /** Ignored props forwarded from FormField
   *
   *
   * ToggleSwitch doesn't implement styling for `required` props, so there is no need to ignore styling
   */
  hideRequiredStyles?: boolean;
}

/** Props used by the implementation of ToggleSwitch
 *
 * Adds forwardRef, which is not present in the props of the default export.
 */
interface ToggleSwitchComponentProps extends ToggleSwitchProps {
  /** Ref forwarded to ToggleSwitch's button element */
  forwardedRef?: React.Ref<HTMLButtonElement>;
}

function ToggleSwitchComponent({
  checked: upstreamChecked,
  className,
  defaultChecked = false,
  defaultValue,
  descriptionPosition = 'right',
  descriptions: upstreamDescriptions,
  disabled = false,
  error,
  forwardedRef,
  hideRequiredStyles,
  id,
  onBlur,
  onChange,
  size: upstreamSize,
  value,
  valueAttribute,
  'aria-labelledby': ariaLabelledby,
  ...passedProps
}: ToggleSwitchComponentProps): ReactElement {
  const [checked, setChecked] = useState(Boolean(upstreamChecked ?? value ?? defaultValue ?? defaultChecked));
  const isInControlledMode = upstreamChecked !== undefined || value !== undefined;

  const descriptions: Required<ToggleSwitchDescriptions> | undefined = useMemo(() => {
    if (!upstreamDescriptions) {
      return upstreamDescriptions;
    } else if (upstreamDescriptions.id) {
      /** Typescript should be able to infer that upstreamDescriptions.id cannot
       * be undefined, but is failing to do so.
       */
      return upstreamDescriptions as Required<ToggleSwitchDescriptions>;
    } else {
      return { ...upstreamDescriptions, id: nanoid() };
    }
  }, [upstreamDescriptions]);

  const { layout } = useContext(FormFieldLayoutContext);
  const size = useMemo(() => {
    if (typeof upstreamSize === 'string') {
      return upstreamSize;
    } else if (layout === 'super-compact') {
      // Apply small size when placed in <Form layout="super-compact" /> or <FormFieldLayout layout="super-compact" />
      return 'small';
    } else if (layout === 'large') {
      // Apply large size when placed in <Form layout="large" /> or <FormFieldLayout layout="large" />
      return 'large';
    } else {
      return 'medium';
    }
  }, [layout, upstreamSize]);

  useEffect(() => {
    if (isInControlledMode) {
      setChecked(Boolean(upstreamChecked ?? value));
    }
  }, [upstreamChecked, value, isInControlledMode]);

  function handleBlur(): void {
    if (onBlur) {
      onBlur(new FakeEvent({ value: checked, id }));
    }
  }

  function toggleChecked(): void {
    if (!disabled) {
      if (!isInControlledMode) {
        setChecked((p) => !p);
      }
      if (onChange) {
        onChange(new FakeEvent({ value: !checked, id }));
      }
    }
  }

  function getCombinedAriaLabelBy(): string {
    if (ariaLabelledby && descriptions) return `${ariaLabelledby} ${descriptions.id}`;
    if (descriptions) return descriptions.id;
    if (ariaLabelledby) return ariaLabelledby;
    return '';
  }

  const combinedAriaLabelledby = getCombinedAriaLabelBy();
  const toggleValue = valueAttribute ? { value: valueAttribute } : null;

  const useErrorStyles = error && !disabled;

  return (
    <div
      {...classes({
        modifiers: [descriptionPosition === 'left' ? 'left-description' : '', size],
        extra: className,
      })}
    >
      <button
        aria-checked={checked}
        aria-disabled={disabled}
        aria-labelledby={combinedAriaLabelledby}
        id={id}
        disabled={disabled}
        onClick={toggleChecked}
        onBlur={handleBlur}
        role="switch"
        ref={forwardedRef}
        type="button"
        {...toggleValue}
        {...classes({
          element: 'track',
          states: {
            checked,
            disabled,
            error: !!useErrorStyles,
          },
          modifiers: [size],
        })}
        {...passedProps}
      >
        <div
          {...classes({
            element: 'toggle',
            states: {
              checked,
              disabled,
              error: !!useErrorStyles,
            },
            modifiers: [size],
          })}
        />
      </button>
      {descriptions && (
        <label
          id={descriptions.id}
          {...classes({
            element: 'descriptions',
            states: {
              checked,
              disabled,
              error: !!useErrorStyles,
            },
            modifiers: [descriptionPosition === 'left' ? 'left' : '', size],
          })}
        >
          {checked ? descriptions.checked : descriptions.unchecked}
        </label>
      )}
    </div>
  );
  /* eslint-enable */
}

const toggleSwitchPropTypes: WeakValidationMap<ToggleSwitchComponentProps & { '...rest': unknown }> = {
  'aria-labelledby': PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  descriptionPosition: PropTypes.oneOf(['left', 'right']),
  descriptions: PropTypes.shape({
    id: optionalStringValidator,
    checked: PropTypes.string.isRequired,
    unchecked: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(toggleSwitchSizes),
  valueAttribute: PropTypes.string,
  '...rest': PropTypes.any,
};

ToggleSwitchComponent.propTypes = toggleSwitchPropTypes;

ToggleSwitchComponent.displayName = 'ToggleSwitch';

export default forwardRefToProps(ToggleSwitchComponent);
