import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgArrowRightLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 23.001L23 12.001M23 12.001L12 1.00098M23 12.001H1"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgArrowRightLarge.displayName = 'SvgArrowRightLarge';
const ArrowRightLarge = withForgeIconProps(SvgArrowRightLarge, {
  name: 'ArrowRight',
  usage: 'Use to indicate the ability to move an item to the right',
  related: 'move, direction',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ArrowRightLarge;
