import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClinicalAccelerateRightSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.4445 0.289584C12.0522 -0.099104 11.419 -0.0961339 11.0303 0.296218C10.6416 0.68857 10.6446 1.32173 11.037 1.71042L12.4445 0.289584ZM15 4.22882L15.7038 4.93924C15.8934 4.75144 16 4.49566 16 4.22882C16 3.96198 15.8934 3.7062 15.7038 3.5184L15 4.22882ZM11.037 6.74722C10.6446 7.13591 10.6416 7.76907 11.0303 8.16142C11.419 8.55377 12.0522 8.55674 12.4445 8.16805L11.037 6.74722ZM6.61298 15.9941C7.16474 15.9701 7.59258 15.5033 7.56858 14.9515C7.54459 14.3998 7.07784 13.9719 6.52608 13.9959L6.61298 15.9941ZM6.56953 4.22892L6.52608 5.22797L6.54779 5.22892H6.56953V4.22892ZM14.7325 5.22892C15.2848 5.22892 15.7325 4.7812 15.7325 4.22892C15.7325 3.67663 15.2848 3.22892 14.7325 3.22892V5.22892ZM11.037 1.71042L14.2962 4.93924L15.7038 3.5184L12.4445 0.289584L11.037 1.71042ZM14.2962 3.5184L11.037 6.74722L12.4445 8.16805L15.7038 4.93924L14.2962 3.5184ZM6.52608 13.9959C5.94228 14.0213 5.3593 13.9273 4.81208 13.7192L4.10121 15.5886C4.90255 15.8933 5.75698 16.0313 6.61298 15.9941L6.52608 13.9959ZM4.81208 13.7192C4.26483 13.5111 3.76434 13.1931 3.34106 12.784L1.95113 14.2221C2.56859 14.8189 3.29991 15.2839 4.10121 15.5886L4.81208 13.7192ZM3.34106 12.784C2.91775 12.3749 2.58034 11.883 2.34967 11.3377L0.50772 12.117C0.842853 12.9091 1.33369 13.6253 1.95113 14.2221L3.34106 12.784ZM2.34967 11.3377C2.11901 10.7925 2 10.2054 2 9.61196H0C0 10.4727 0.172574 11.3247 0.50772 12.117L2.34967 11.3377ZM2 9.61196C2 9.01848 2.11901 8.43143 2.34967 7.8862L0.50772 7.10696C0.172574 7.89917 0 8.75124 0 9.61196H2ZM2.34967 7.8862C2.58034 7.34095 2.91775 6.84904 3.34106 6.43991L1.95113 5.00182C1.33369 5.59858 0.842853 6.31478 0.50772 7.10696L2.34967 7.8862ZM3.34106 6.43991C3.76431 6.03083 4.26478 5.71292 4.81208 5.5048L4.10121 3.6354C3.29996 3.94009 2.56862 4.405 1.95113 5.00182L3.34106 6.43991ZM4.81208 5.5048C5.35936 5.29669 5.94234 5.20258 6.52608 5.22797L6.61298 3.22986C5.75692 3.19263 4.9025 3.3307 4.10121 3.6354L4.81208 5.5048ZM6.56953 5.22892H14.7325V3.22892H6.56953V5.22892Z"
      fill={props.color}
    />
  </svg>
);
SvgClinicalAccelerateRightSmall.displayName = 'SvgClinicalAccelerateRightSmall';
const ClinicalAccelerateRightSmall = withForgeIconProps(
  SvgClinicalAccelerateRightSmall,
  {
    name: 'ClinicalAccelerateRight',
    usage:
      'Use to accelerate problems and medications to the clinical encounter from left to right',
    related: 'ClinicalAccelerateLeft',
    isLabelRequired: true,
    isNeutralInteractive: false,
    size: 'small',
  }
);
export default ClinicalAccelerateRightSmall;
