import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMapSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.5 8C9.60457 8 10.5 7.10457 10.5 6C10.5 4.89543 9.60457 4 8.5 4C7.39543 4 6.5 4.89543 6.5 6C6.5 7.10457 7.39543 8 8.5 8Z"
      fill={props.color}
    />
    <path
      d="M12.996 5.97021L11.9968 5.92952C11.9962 5.94307 11.996 5.95664 11.996 5.97021L12.996 5.97021ZM8.43174 15L7.70994 15.6921C7.8936 15.8836 8.14585 15.9943 8.41116 15.9998C8.67647 16.0053 8.93307 15.905 9.12445 15.7212L8.43174 15ZM4.00294 5.97021H5.00294C5.00294 5.95863 5.00274 5.94706 5.00233 5.93549L4.00294 5.97021ZM8.43174 2C9.58758 2 10.3093 2.43762 11.0144 3.21677L12.4973 1.8748C11.5109 0.784739 10.2836 0 8.43174 0V2ZM11.0144 3.21677C11.685 3.95782 12.0377 4.92449 11.9968 5.92952L13.9951 6.0109C14.0575 4.48023 13.5182 3.00297 12.4973 1.8748L11.0144 3.21677ZM11.996 5.97021C11.996 7.40185 11.2367 9.21297 10.2443 10.8856C9.26513 12.5359 8.17715 13.858 7.73903 14.2788L9.12445 15.7212C9.7275 15.142 10.9216 13.6636 11.9643 11.9062C12.9937 10.1711 13.996 7.96735 13.996 5.97021H11.996ZM9.15355 14.3079C8.75182 13.8889 7.69168 12.4865 6.72791 10.7848C5.74625 9.05157 5.00294 7.24383 5.00294 5.97021H3.00294C3.00294 7.80996 3.9946 10.0171 4.98764 11.7704C5.99856 13.5553 7.15283 15.1111 7.70994 15.6921L9.15355 14.3079ZM5.00233 5.93549C4.96749 4.93258 5.32397 3.94724 5.98483 3.21657L4.50153 1.875C3.48536 2.99852 2.95099 4.49257 3.00354 6.00493L5.00233 5.93549ZM5.98483 3.21657C6.37045 2.79021 6.70182 2.49896 7.06068 2.30644C7.40541 2.12151 7.82781 2 8.43174 2V0C7.53182 0 6.77919 0.187839 6.11522 0.544025C5.4654 0.892628 4.95441 1.37427 4.50153 1.875L5.98483 3.21657Z"
      fill={props.color}
    />
  </svg>
);
SvgMapSmall.displayName = 'SvgMapSmall';
const MapSmall = withForgeIconProps(SvgMapSmall, {
  name: 'Map',
  usage:
    'Use to indicate or add location information, like an address or a map',
  related: 'pin, directions',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default MapSmall;
