import React, { ReactElement } from 'react';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper({ name: 'animated-spinner' });

export interface AnimatedSpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Adds a class to the root element of the component */
  className?: string;
}

const AnimatedSpinner = ({ className, ...otherProps }: AnimatedSpinnerProps): ReactElement => {
  return (
    <div {...classes({ extra: className })} title={'Animated Spinner'} {...otherProps}>
      <div {...classes({ element: 'spinner-container' })}>
        <div {...classes({ element: 'spinner-layer' })}>
          <div {...classes({ element: 'circle-clipper', modifiers: ['left'] })} />
          <div {...classes({ element: 'circle-clipper', modifiers: ['right'] })} />
        </div>
      </div>
    </div>
  );
};

export default AnimatedSpinner;
