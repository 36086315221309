import { ReactElement } from 'react';
import { classes } from '../Select';
import { StructuredOption } from '../Select';
import { components, GroupHeadingProps, GroupBase } from 'react-select';

const CustomGroupHeading = <Option extends StructuredOption, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: GroupHeadingProps<Option, IsMulti, Group>
): ReactElement => {
  return (
    <div
      {...classes({
        element: 'group-option-heading',
      })}
    >
      <components.GroupHeading {...props} />
    </div>
  );
};

export default CustomGroupHeading;
