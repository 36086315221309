import { classes } from '../Select';
import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import { HTMLAttributes, MouseEventHandler, ReactElement, TouchEventHandler } from 'react';

interface CustomClearIndicatorProps {
  innerProps: HTMLAttributes<HTMLDivElement>;
}

const CustomClearIndicator = ({ innerProps }: CustomClearIndicatorProps): ReactElement => {
  const { onMouseDown, onTouchEnd, 'aria-hidden': ariaHidden } = innerProps;
  return (
    <CloseSmall
      {...classes({
        element: 'clear-indicator',
      })}
      size={8}
      onMouseDown={onMouseDown as MouseEventHandler<SVGElement> | undefined}
      onTouchEnd={onTouchEnd as TouchEventHandler<SVGElement> | undefined}
      aria-hidden={ariaHidden}
    />
  );
};

export default CustomClearIndicator;
