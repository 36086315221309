import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLockSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C7.44772 2 7 2.44772 7 3V6H9V3C9 2.44772 8.55228 2 8 2ZM11 6H14V12C14 14.2091 12.2091 16 10 16H2V6H5V3C5 1.34315 6.34315 0 8 0C9.65685 0 11 1.34315 11 3V6ZM9 10C9 9.44771 8.55228 9 8 9C7.44772 9 7 9.44771 7 10V12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12V10Z"
      fill={props.color}
    />
  </svg>
);
SvgLockSmall.displayName = 'SvgLockSmall';
const LockSmall = withForgeIconProps(SvgLockSmall, {
  name: 'Lock',
  usage: 'Use to denote private, classified, or encrypted content',
  related: 'Unlock',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default LockSmall;
