import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDropAreaSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2H16V4.125H14V2ZM16 6.375V8.625H14V6.375H16ZM16 10.875V12C16 12.5405 15.8922 13.0584 15.6961 13.5314L13.8486 12.7653C13.9458 12.5309 14 12.273 14 12V10.875H16ZM13.5314 15.6961C13.0584 15.8922 12.5405 16 12 16H10.875V14H12C12.273 14 12.5309 13.9458 12.7653 13.8486L13.5314 15.6961ZM4.125 16H2V14H4.125V16ZM8.625 16H6.375V14H8.625V16Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12C10.2091 12 12 10.2091 12 8V4.125H12.375V2H12V0H0V12H2V12.375H4.125V12H8ZM2 2V10H8C9.10457 10 10 9.10457 10 8V2H2Z"
      fill={props.color}
    />
  </svg>
);
SvgDropAreaSmall.displayName = 'SvgDropAreaSmall';
const DropAreaSmall = withForgeIconProps(SvgDropAreaSmall, {
  name: 'DropArea',
  usage: 'Use to indicate an area an item can be moved to by dragging',
  related: 'move, reorder, list, drag, handle',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default DropAreaSmall;
