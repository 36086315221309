import rawContents from "!!raw-loader!./SkeletonCircle.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function SkeletonCircle():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Skeleton Circle" />;
}

export default SkeletonCircle;
