import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgProviderLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.50049 3.00073C10.3289 3.00073 11.0005 2.32916 11.0005 1.50073C11.0005 0.672305 10.3289 0.000732422 9.50049 0.000732422C8.67206 0.000732422 8.00049 0.672305 8.00049 1.50073C8.00049 2.32916 8.67206 3.00073 9.50049 3.00073Z"
      fill={props.color}
    />
    <path
      d="M13.5005 3.00073C14.3289 3.00073 15.0005 2.32916 15.0005 1.50073C15.0005 0.672305 14.3289 0.000732422 13.5005 0.000732422C12.6721 0.000732422 12.0005 0.672305 12.0005 1.50073C12.0005 2.32916 12.6721 3.00073 13.5005 3.00073Z"
      fill={props.color}
    />
    <path
      d="M17.5005 19.8806C18.8812 19.8806 20.0005 18.7613 20.0005 17.3806C20.0005 15.9999 18.8812 14.8806 17.5005 14.8806C16.1198 14.8806 15.0005 15.9999 15.0005 17.3806C15.0005 18.7613 16.1198 19.8806 17.5005 19.8806Z"
      fill={props.color}
    />
    <path
      d="M9.50061 1.51071C9.00061 1.00071 5.50061 -0.0193892 5.50061 3.91061C5.50061 8.91061 11.5006 13.3007 11.5006 13.3007M11.5006 13.3007C11.5006 13.3007 17.5006 8.87061 17.5006 3.91061C17.5006 -0.0193892 14.0006 1.00071 13.5006 1.51071M11.5006 13.3007L11.5006 19.9407C11.4742 20.7336 11.1514 21.4879 10.5959 22.0544C10.0405 22.621 9.29287 22.9586 8.5006 23.0007C8.10153 23.0008 7.70643 22.9213 7.33849 22.7668C6.97055 22.6123 6.63715 22.3858 6.35778 22.1008C6.0784 21.8159 5.85872 21.4781 5.71154 21.1072C5.56435 20.7362 5.49262 20.3397 5.5006 19.9407C5.5006 17.9407 6.9006 17.3806 8.5006 17.3806H16.5006"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgProviderLarge.displayName = 'SvgProviderLarge';
const ProviderLarge = withForgeIconProps(SvgProviderLarge, {
  name: 'Provider',
  usage: 'Use to indicate a medical provider',
  related: 'provider, clinic, care, nurse',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default ProviderLarge;
