import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgXRaySmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4.18005 1V3.54004M11.8199 1V3.54004M4.5 7.25022C6.78595 6.58521 9.21405 6.58521 11.5 7.25022M5.5 10.1203C7.15082 9.7952 8.84918 9.7952 10.5 10.1203M8 5.14014V12.1401M1 2.22021H15V12C15 12.7956 14.6839 13.5585 14.1213 14.1211C13.5587 14.6837 12.7956 15 12 15H1V2.22021Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgXRaySmall.displayName = 'SvgXRaySmall';
const XRaySmall = withForgeIconProps(SvgXRaySmall, {
  name: 'XRay',
  usage: 'Use to indicate or view X-ray information',
  related: 'xray, radiology, radiologist, labs, imaging',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default XRaySmall;
