import { RefObject, useLayoutEffect, useRef, useState } from 'react';
import useWindowSize from '../useWindowSize/useWindowSize';

/** Accepts a ref to an array of elements
 *
 * If any have a different top position coordinate, it returns true
 * (because at least one must be wrapping to a new line).
 */
export default function useIsWrapping(ref: RefObject<HTMLElement[]>): boolean {
  const [isWrapping, setIsWrapping] = useState(false);
  const { width } = useWindowSize();
  const lastWidth = useRef<number | null>(null);

  useLayoutEffect(() => {
    if (width !== lastWidth.current) {
      if (isWrapping) {
        /** Setting isWrapping to false allows one render cycle to compute how
         * much space the RefObjects are taking
         */
        setIsWrapping(false);
      } else if (ref.current && ref.current.length > 0) {
        /** Actually calculate whether elements are wrapping */
        lastWidth.current = width;
        const firstTop = ref.current[0].getBoundingClientRect().top;
        const wrapping = ref.current.some((element) => {
          const { top } = element.getBoundingClientRect();
          // If one or more of the elements have a different "top", then they're wrapping to a new line
          return top !== firstTop;
        });
        setIsWrapping(!!wrapping);
      }
    }
  }, [isWrapping, ref, width]);

  return isWrapping;
}
