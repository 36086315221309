import { DataTableAction } from '../stateTypes';

export function inline_edit_row<RowData>(rowId: number): DataTableAction<RowData> {
  return function inline_edit_row(s) {
    const row = s.rows[rowId];
    if (row.rowStatus === 'view') {
      row.rowStatus = 'edit';
      row.isSelected = false; // deselect when editing the row
    }
  };
}
