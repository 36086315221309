import React from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper({ name: 'hidden' });
export interface HiddenProps {
  /** Content to hide */
  children: React.ReactNode;

  /** Hide content on screen when true */
  screen?: boolean;

  /** Set to true to display content inline using a span, rather than the default `<div>`. */
  inline?: boolean;

  /** Hide content on print when true */
  print?: boolean;

  /** Specify the cutoff for when content should be hidden */
  above?: 'mobile';

  /** Specify the cutoff for when content should be hidden */
  below?: 'desktop';
}
/** Hide content based on settings. */
const Hidden = ({
  below,
  above,
  inline = false,
  children,
  screen = false,
  print = false,
}: HiddenProps): JSX.Element => {
  const hiddenOnScreen = Boolean(screen);
  const hiddenOnPrint = Boolean(print);

  const modifiers = {
    modifiers: {
      print: hiddenOnPrint,
      screen: hiddenOnScreen,
      mobile: below === 'desktop',
      desktop: above === 'mobile',
    },
  };

  return inline ? <span {...classes(modifiers)}>{children}</span> : <div {...classes(modifiers)}>{children}</div>;
};

Hidden.propTypes = {
  /** Content to hide */
  children: PropTypes.any.isRequired,

  /** Hide content on screen when true */
  screen: PropTypes.bool,

  /** Set to true to display content inline using a span, rather than the default `<div>`. */
  inline: PropTypes.bool,

  /** Hide content on print when true */
  print: PropTypes.bool,

  /** Specify the cutoff for when content should be hidden */
  above: PropTypes.oneOf(['mobile']),

  /** Specify the cutoff for when content should be hidden */
  below: PropTypes.oneOf(['desktop']),
};

export default Hidden;
