/* This file is pure JS and doesn't need to be a react component,
  but making this a .jsx allows us to get around athena's no-special-chars filename rule */

export interface ToastMetadata {
  key: string;
  onEject: (key: string) => Promise<void>;
  ejecting?: Promise<void>;
}

class ToastManager {
  currentToast: ToastMetadata | undefined;
  waitingToast: ToastMetadata | undefined;
  constructor() {
    this.currentToast = undefined;
    this.waitingToast = undefined;
  }

  async register(key: string, onEject: ToastMetadata['onEject']): Promise<boolean> {
    if (this.currentToast) {
      if (this.currentToast.key === key) {
        return true;
      }
      // Don't care if there's already another toast waiting, because newer toast wins
      this.waitingToast = { key, onEject };

      // Eject the current toast and wait for it to finish
      if (!this.currentToast.ejecting) {
        this.currentToast.ejecting = this.currentToast.onEject(this.currentToast.key);
      }
      await this.currentToast.ejecting;

      this.currentToast = { key, onEject };

      // Now check if you've been replaced
      if (!this.waitingToast || this.waitingToast.key === key) {
        this.currentToast = { key, onEject };
        this.waitingToast = undefined;
        return true;
      } else {
        return false;
      }
    } else {
      this.currentToast = { key, onEject };
      this.waitingToast = undefined;

      return true;
    }
  }

  unregister(key: string): void {
    if (this.currentToast && this.currentToast.key === key) {
      this.currentToast = undefined;
    }
    if (this.waitingToast && this.waitingToast.key === key) {
      this.waitingToast = undefined;
    }
  }
}

export default ToastManager;
