import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMedicationSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10.45 10.4099L5.68002 5.63989M2.01997 13.9801C1.51993 13.48 1.03633 12.6052 1.00447 11.7601C0.972616 10.915 1.27657 10.0917 1.84993 9.47005L9.46992 1.85017C10.0916 1.27681 10.915 0.972614 11.7601 1.00447C12.6052 1.03632 13.4398 1.48001 13.9799 2.02009C14.52 2.56018 14.9636 3.39498 14.9954 4.24006C15.0273 5.08515 14.7233 5.90846 14.15 6.5301L6.52998 14.15C5.90834 14.7233 5.08503 15.0273 4.23994 14.9954C3.39486 14.9636 2.52002 14.4801 2.01997 13.9801Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M6.5022 11.5022C7.05448 11.5022 7.5022 11.0545 7.5022 10.5022C7.5022 9.94991 7.05448 9.5022 6.5022 9.5022C5.94991 9.5022 5.5022 9.94991 5.5022 10.5022C5.5022 11.0545 5.94991 11.5022 6.5022 11.5022Z"
      fill={props.color}
    />
    <path
      d="M3.5022 12.5022C4.05448 12.5022 4.5022 12.0545 4.5022 11.5022C4.5022 10.9499 4.05448 10.5022 3.5022 10.5022C2.94991 10.5022 2.5022 10.9499 2.5022 11.5022C2.5022 12.0545 2.94991 12.5022 3.5022 12.5022Z"
      fill={props.color}
    />
  </svg>
);
SvgMedicationSmall.displayName = 'SvgMedicationSmall';
const MedicationSmall = withForgeIconProps(SvgMedicationSmall, {
  name: 'Medication',
  usage: 'Use to indicate, view, or edit patient medications',
  related: 'pill, meds, prescription',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default MedicationSmall;
