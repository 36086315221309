import SimpleResponsiveExample from './SimpleResponsiveExample.gen';
import DefaultSizing from './DefaultSizing.gen';
import Offsets from './Offsets.gen';
import Ordering from './Ordering.gen';
import Gutters from './Gutters.gen';
import Nesting from './Nesting.gen';
import RowShorthands from './RowShorthands.gen';
import VerticalAlignment from './VerticalAlignment.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <SimpleResponsiveExample />
      <DefaultSizing />
      <Offsets />
      <Ordering />
      <Gutters />
      <Nesting />
      <RowShorthands />
      <VerticalAlignment />
    </>
  );
}
