import { useCallback, useState } from 'react';

/** Returns a function which forces a component to re-render.
 *
 * Typically used when third party libraries don't manage their state correctly.
 */
export default function useForceRerender(): () => void {
  const [, setForceRerender] = useState(1);
  return useCallback((): void => setForceRerender((prevState) => prevState + 1), []);
}
