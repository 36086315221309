import rawContents from "!!raw-loader!./SimpleResponsiveExample.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function SimpleResponsiveExample():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Simple Responsive Example" />;
}

export default SimpleResponsiveExample;
