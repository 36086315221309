import rawContents from "!!raw-loader!./InputUseWithinAFormField.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function InputUseWithinAFormField():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Input Use Within A Form Field" />;
}

export default InputUseWithinAFormField;
