import { PageProps } from 'gatsby';
import { ReactElement } from 'react';
import Layout from '../components/layout';
import { GatsbyPageContext, PageData } from '../contexts';
import { EmptyState } from '@athena/forge';
import { forgeVersionType } from '../versionCheckHelper';

export default function NotFound404({ pageContext }: PageProps<PageData, GatsbyPageContext>): ReactElement {
  const isLts = forgeVersionType() === 'lts';
  return (
    <Layout pageContext={pageContext} title={'Not Found'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <EmptyState
          type="NotFound"
          primaryButtonProps={null}
          secondaryButtonProps={null}
          detail={`The page you are searching for does not exist. Try toggling to Forge ${isLts ? 'New' : 'LTS'} via the version picker and searching again as this page may exist in that version.`}
        />
      </div>
    </Layout>
  );
}
