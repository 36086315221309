import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBugSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9.99622V9.99914C14 10.3394 13.9717 10.6731 13.9172 10.998H15C15.5523 10.998 16 11.4457 16 11.998C16 12.5503 15.5523 12.998 15 12.998H13.198C12.1607 14.792 10.2213 15.9991 8 15.9991C5.77867 15.9991 3.83928 14.792 2.80202 12.998H1C0.447715 12.998 0 12.5503 0 11.998C0 11.4457 0.447715 10.998 1 10.998H2.08276C2.02833 10.6731 2 10.3394 2 9.99913V9.99622H1C0.447715 9.99622 0 9.5485 0 8.99622C0 8.44393 0.447715 7.99622 1 7.99622H2V6.99447H1C0.447715 6.99447 0 6.54675 0 5.99447C0 5.44218 0.447715 4.99447 1 4.99447H2C2.11264 4.99447 2.22094 5.01309 2.32197 5.04743C2.6339 4.13618 3.15902 3.32385 3.83781 2.66994L2.88404 1.70292C2.49621 1.30972 2.50039 0.676382 2.89337 0.288329C3.28635 -0.0997242 3.91932 -0.0955468 4.30714 0.297659L5.51939 1.52673C6.27543 1.183 7.11536 0.991549 8 0.991549C8.87436 0.991549 9.70504 1.17858 10.4542 1.51481L11.6547 0.297659C12.0425 -0.0955468 12.6755 -0.0997242 13.0685 0.288329C13.4615 0.676382 13.4656 1.30972 13.0778 1.70292L12.1426 2.65114C12.8309 3.30825 13.3631 4.12743 13.678 5.04743C13.7791 5.01309 13.8874 4.99447 14 4.99447H15C15.5523 4.99447 16 5.44218 16 5.99447C16 6.54675 15.5523 6.99447 15 6.99447H14V7.99622H15C15.5523 7.99622 16 8.44393 16 8.99622C16 9.5485 15.5523 9.99622 15 9.99622H14ZM4 6.99154C4 4.78241 5.79086 2.99155 8 2.99155C10.2091 2.99155 12 4.78241 12 6.99155V9.99914C12 12.2083 10.2091 13.9991 8 13.9991C5.79086 13.9991 4 12.2083 4 9.99913V6.99154ZM7 8C6.44772 8 6 8.44772 6 9C6 9.55229 6.44772 10 7 10H9C9.55228 10 10 9.55229 10 9C10 8.44772 9.55228 8 9 8H7Z"
      fill={props.color}
    />
  </svg>
);
SvgBugSmall.displayName = 'SvgBugSmall';
const BugSmall = withForgeIconProps(SvgBugSmall, {
  name: 'Bug',
  usage: 'Use to indicate a software bug or coding error (internal use only)',
  related: 'code, issue, p1, p2, p3, p4',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default BugSmall;
