import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  useCallback,
  useMemo,
  ReactElement,
  WeakValidationMap,
  Ref,
  TextareaHTMLAttributes,
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from '../utils/helpers';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';

const classes = forgeClassHelper('textarea');
export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  /** To control if auto grow/shrink */
  autoResize?: boolean;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Highlights the input when defined (specific string doesn't matter) */
  disabled?: boolean;
  /** Highlights the input when defined (specific string doesn't matter) */
  error?: string;
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles?: boolean;
  /** Function that takes a JS event as an argument. Called when the Textarea loses focus */
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  /** Function that takes a JS event as an argument. Called when the Textarea value changes */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /** Ref to the top-level <div> that encapsulates Tabs */
  ref?: Ref<HTMLTextAreaElement>;
  /** Indicates if it is a required field. Passed as an attribute to the native react `<textarea />` */
  required?: boolean;
  /** CSS style props */
  style?: React.CSSProperties;
}

interface TextareaComponentProps extends TextareaProps {
  /** Ref to the top-level <div> that encapsulates Tabs */
  forwardedRef?: Ref<HTMLTextAreaElement>;
}

const Textarea = ({
  autoResize = true,
  className,
  disabled,
  error,
  forwardedRef,
  hideRequiredStyles,
  onBlur,
  onChange,
  required,
  style,
  ...passThroughProps
}: TextareaComponentProps): ReactElement => {
  // State for managing textarea height and the shrinking effect
  const [height, setHeight] = useState<string | undefined>(undefined);
  const [shrinking, setShrinking] = useState<boolean>(false);

  // Ref for the textarea DOM element
  const fieldRef = useRef<HTMLTextAreaElement>(null);

  // Connect the forwardedRef passed to the component with the internal fieldRef
  useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(forwardedRef, () => fieldRef.current);

  // Handlers for change and blur events
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    onChange && onChange(event);
    grow();
  };

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>): void => {
    onBlur && onBlur(event);
    shrink();
  };

  // Helper functions to calculate heights for growing and shrinking the textarea
  const getScrollHeight = useCallback(
    (): number | undefined => (fieldRef.current ? fieldRef.current.scrollHeight : undefined),
    []
  );

  const calcHeight = useCallback((scrollHeight: number): string | undefined => {
    if (fieldRef.current) {
      const borderHeight = fieldRef.current.offsetHeight - fieldRef.current.clientHeight;

      return `${scrollHeight + borderHeight + 1}px`; // Add a pixel to safeguard against fractions
    }
  }, []);

  /** Causes the textarea to grow if a scroll bar is detected. */
  const grow = useCallback((): boolean => {
    if (!autoResize) {
      return false;
    }
    const scrollHeight = getScrollHeight();
    const height = fieldRef?.current?.getBoundingClientRect().height;

    if (height && scrollHeight && height < scrollHeight) {
      setHeight(calcHeight(scrollHeight));
      setShrinking(false);
      return true;
    }
    return false;
  }, [autoResize, calcHeight, getScrollHeight]);

  /** Shrinks the textarea by 1 line height, and triggers the "shrinking" state,
   * causing this function to be called again after a render cycle.
   */
  const shrink = useCallback((): void => {
    if (!autoResize) {
      return;
    }
    const scrollHeight = getScrollHeight();
    if (scrollHeight) {
      const lineHeight = 20; // Approximate line height for shrinking calculation
      const shorterHeight = scrollHeight - lineHeight;
      setHeight(calcHeight(shorterHeight));
    }
    setShrinking(true);
  }, [autoResize, calcHeight, getScrollHeight]);

  /** Debounce the shrink function to avoid excessive calls on resize events */
  const debouncedShrink = useMemo(() => {
    return debounce(shrink, 100);
  }, [shrink]);

  /** Expand the height to fit current text contents on mount and set up an
   * event listener to change the height when the window is resized.
   */
  useEffect(() => {
    grow();
    // Add event listener for window resize
    window.addEventListener('resize', debouncedShrink);
    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', debouncedShrink);
    };
  }, [grow, debouncedShrink]);

  /** Once `shrinking` is set, recursively shrinks and re-renders the Textarea
   * until a scroll wheel appears. At this point, grow() is called one more
   * time to get rid of the scroll wheel.
   */
  useEffect(() => {
    if (!shrinking) return;

    if (!grow()) {
      shrink();
    }
  }, [
    shrinking,
    grow,
    shrink,
    /** height is not directly referenced, but is necessary as a proxy for
     * getBoundingClientRect changing
     */ height,
  ]);

  return (
    <textarea
      {...passThroughProps}
      disabled={disabled}
      required={required}
      {...classes({
        modifiers: ['auto-size'],
        states: {
          disabled: !!disabled,
          error: !!(error && !disabled),
          required: !!(required && !hideRequiredStyles && !disabled),
        },
        extra: className,
      })}
      onChange={handleChange}
      onBlur={handleBlur}
      ref={fieldRef}
      style={{
        ...style,
        height: height,
        overflow: shrinking ? 'hidden' : undefined,
      }}
    />
  );
};
const textareaPropTypes: WeakValidationMap<TextareaProps & { '...rest': unknown }> = {
  /** To control if auto grow/shrink */
  autoResize: PropTypes.bool,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Determines the initial value of the textarea, but allows it to be updated ([uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)) */
  defaultValue: PropTypes.string,
  /** Impacts the textarea's appearance in addition to preventing editing. */
  disabled: PropTypes.bool,
  /** Highlights the input when defined (specific string doesn't matter) */
  error: PropTypes.string,
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form` */
  hideRequiredStyles: PropTypes.bool,
  /** Function that takes a JS event as an argument. Called when the Textarea loses focus */
  onBlur: PropTypes.func,
  /** Function that takes a JS event as an argument. Called when the Textarea value changes */
  onChange: PropTypes.func,
  /** Function that takes a JS event as an argument. Called when the Textarea receives focus */
  onFocus: PropTypes.func,
  /** placeholder text */
  placeholder: PropTypes.string,
  /** Indicates if it is a required field. Passed as an attribute to the native react `<textarea />` */
  required: PropTypes.bool,
  /** Value of the textarea. Creates a [controlled](https://reactjs.org/docs/forms.html#controlled-components) component */
  value: PropTypes.string,
  /** Passthrough props to the `<textarea>` element */
  '...rest': PropTypes.any,
};

Textarea.displayName = 'Textarea';

Textarea.propTypes = textareaPropTypes;

export default forwardRefToProps(Textarea);
