import React from 'react';

import Checkbox from '../Checkbox';
import Label from '../Label';

import { classes } from './NumericRangeInputUtils';

/** Arguments to NullValueCheckbox(). */
export interface NullValueCheckboxProps {
  /**
   * Optional boolean that indicates if the JSX for the NumericRange's checkbox should be created.
   * If false, an empty JSX element is returned IF the two requiredValue args are false.
   */
  allowNullValuesCheckBox?: boolean;
  /**
   * REQUIRED function for the checkbox's onChange attribute.
   */
  handleShowNoNumbersChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  /** REQUIRED prefix id for the HTML tag. */
  id: string;
  /**
   * REQUIRED boolean that indicates whether this checkbox is for currency or not.  Affects the
   * checkbox's label (description).
   */
  isCurrency: boolean;
  /**
   * Optional boolean that indicates if the first numeric is required.
   * If true, the checkbox is visible.
   */
  requiredFirstValue?: boolean;
  /**
   * Optional boolean that indicates if the second numeric is required.
   * If true, the checkbox is visible.
   */
  requiredSecondValue?: boolean;
  /**
   * REQUIRED boolean holding the state of the checkbox.  Since it can be a state variable, it
   * can cause the visible state of the checkbox to change.
   */
  showNoNumbers: boolean;
  /**
   * Optional string with an custom string for the checkbox's description if dealing with
   * currency values.
   */
  showNullAmountsCheckboxDescription?: string;
  /**
   * Optional string with an custom string for the checkbox's description if dealing with
   * non-currency number values.
   */
  showNullQuantitiesCheckboxDescription?: string;
  /**
   * REQUIRED boolean that constricts the components margins.  If true, there is no
   * bottom margin for the control but there is one on top because whatever above has
   * no bottom margin.
   */
  suppressVerticalSpacing: boolean;
}

/** Arguments to DescriptionLabel(). */
export interface DescriptionLabelProps {
  /**
   * REQUIRED boolean that indicates if the JSX for the NumericRange's checkbox should be created.
   * If false, an empty JSX element is returned IF the two requiredValue args are false.
   */
  allowNullValuesCheckBox: boolean;
  /** REQUIRED number with a count of the banners currently being displayed */
  bannerCount: number;
  /**
   * REQUIRED boolean tells the error to always render below the input area instead of based on content-size and breakpoint.
   */
  errorAlwaysBelow: boolean;
  /**
   * REQUIRED boolean that can constrict the components margins.  If false, there is no
   * bottom margin for the control unless there is more than one banner.  If true, a there
   * will be a top margin to prevent this description from bumping into any element above it.
   */
  suppressVerticalSpacing: boolean;
  /**
   * REQUIRED boolean indicating if the NumericRangeInput should suppress the display of error messages.
   */
  suppressWarningBanner?: boolean;
  /**
   * Optional string containing the description to display.  Since it is user provided and not
   * required, if undefined or an empty string it would return an empty JSX element.
   */
  labelText?: string;
}

export interface RangeLabelProps {
  /** REQUIRED string holding the HTML id for the component. */
  id: string;
  /**
   * REQUIRED boolean to force the component label to be on top of the range.
   * Used only if suppressVerticalSpacing is true.
   * */
  labelAlwaysAbove: boolean;
  /**
   * REQUIRED boolean that constricts the components margins.  If true, there is no
   * class "fe_u_margin--top-small" assigned to this label.  Used only if labelAlwaysAbove
   * is true.
   */
  suppressVerticalSpacing: boolean;
  /** Optional string holding the text of the Forge Label to be created. */
  labelText?: string;
  /** Indicates that the input is not part of a Form. */
  standalone: boolean;
}

/**
 * NullValueCheckbox(props: NullValueCheckboxProps): JSX.Element
 *
 * This function creates the Forge Checkbox component for this control.
 *
 * @param props NullValueCheckboxProps
 *
 * @returns JSX.Element if passed properties allow for one.
 */
export const NullValueCheckbox = (props: NullValueCheckboxProps): JSX.Element => {
  const {
    allowNullValuesCheckBox,
    handleShowNoNumbersChange,
    id,
    isCurrency,
    requiredFirstValue,
    requiredSecondValue,
    showNullAmountsCheckboxDescription,
    showNullQuantitiesCheckboxDescription,
    showNoNumbers,
  } = props;

  return allowNullValuesCheckBox || requiredFirstValue || requiredSecondValue ? (
    <Checkbox
      // Checking for undefined since TypeScript is ignoring the setting in NumericRange.defaultProps.
      description={
        isCurrency
          ? showNullAmountsCheckboxDescription ?? 'Show items with no amount'
          : showNullQuantitiesCheckboxDescription ?? 'Show items with no quantity'
      }
      id={`${id}-showitemscbid`}
      data-testid={`${id}-showitemscbid`}
      {...classes({
        extra: `fe_c_numeric-range-input__component_text fe_u_margin--top-xsmall`,
      })}
      checked={showNoNumbers}
      onChange={handleShowNoNumbersChange}
    />
  ) : (
    <></>
  );
};

/**
 * DescriptionLabel(props: DescriptionLabelProps): JSX.Element
 *
 * This function creates a Forge Label component for this control.  It is a
 * more detailed description than the component Label and is displayed below
 * the numeric input controls.
 *
 * @param props DescriptionLabelProps
 *
 * @returns JSX.Element if passed properties allow for one.
 */
export const DescriptionLabel = (props: DescriptionLabelProps): JSX.Element => {
  const {
    allowNullValuesCheckBox,
    bannerCount,
    errorAlwaysBelow,
    suppressVerticalSpacing,
    suppressWarningBanner,
    labelText,
  } = props;
  return labelText !== undefined && labelText.length > 0 ? (
    <Label
      text={labelText}
      {...classes({
        element: 'label',
        modifiers:
          suppressVerticalSpacing === true &&
          (errorAlwaysBelow === false || suppressWarningBanner === true || bannerCount === 0) &&
          allowNullValuesCheckBox === false
            ? 'nomargin'
            : 'withmargin',
        extra: 'fe_u_margin--top-xsmall fe_u_flex-align-items--center',
      })}
    />
  ) : (
    <></>
  );
};

/**
 * RangeLabel(props: RangeLabelProps):JSX.Element
 *
 * This function creates a Forge Label for this component.  It is descriptive of its
 * purpose and is displayed to the left of the input controls.
 *
 * @param props RangeLabelProps
 *
 * @returns JSX.Element with a Label if something to display otherwise an empty JSX element.
 */
export const RangeLabel = (props: RangeLabelProps): JSX.Element => {
  const { id, labelAlwaysAbove, labelText, standalone } = props;

  return standalone && labelText !== undefined && labelText.length > 0 ? (
    <Label
      {...classes({
        element: 'label',
        modifiers: 'default',
        extra: `${labelAlwaysAbove ? 'fe_u_margin--bottom-xsmall' : 'fe_u_margin--top-small'} fe_u_margin--right-large`,
      })}
      text={labelText}
      htmlFor={`${id}-firstnumberid`}
    />
  ) : (
    <></>
  );
};
