import { StructuredOption, SelectGroupBase, SelectOption, SelectAccessors, SelectSize } from '../Select';
import { FormLayout } from '../../Form';
import { ReactNode } from 'react';

import { PropsValue } from 'react-select/dist/declarations/src';

/** Transforms loadingMessage received as a string to the type that react-select
 * expects
 */
export type FunctionFromString = (obj: { inputValue: string }) => ReactNode;

export const getFunctionFromString = (
  message: string | FunctionFromString | undefined
): FunctionFromString | undefined => (typeof message === 'string' && message !== undefined ? () => message : message);

// functions telling react-select how to translate from our option/value data structure to theirs
export const customGetOptionLabel = (option: StructuredOption): string => option.label || option.value;
export const customGetOptionValue = (option: StructuredOption): string => option.value;
export const customIsOptionDisabled = (option: StructuredOption): boolean => (option.disabled ? true : false);

/** permissive filter function used for non-async options
 */
export const selectFilterOptions = (
  inputValue: string,
  optionsToFilter: readonly (StructuredOption | SelectGroupBase<StructuredOption>)[]
): readonly (StructuredOption | SelectGroupBase<StructuredOption>)[] => {
  if (inputValue) {
    const searchFor = (text: string): boolean => text.toLowerCase().includes(inputValue.toLowerCase());
    return optionsToFilter.reduce<Array<StructuredOption | SelectGroupBase<StructuredOption>>>(
      (filteredOptions, option) => {
        if (isSelectOption(option)) {
          if (searchFor(customGetOptionValue(option)) || searchFor(customGetOptionLabel(option))) {
            filteredOptions.push(option);
          }
        } else if (option.label && searchFor(option.label)) {
          // The entire group matches by label
          filteredOptions.push(option);
        } else {
          // Search within the group for matching options

          // Groups can only be nested 1 level deep, so type assertion is accurate.
          const nestedOptions = selectFilterOptions(inputValue, option.options) as StructuredOption[];
          if (nestedOptions.length > 0) {
            filteredOptions.push({ label: option.label, options: nestedOptions });
          }
        }
        return filteredOptions;
      },
      []
    );
  } else {
    return optionsToFilter;
  }
};

export type SelectNormalizeValuesReturn =
  | ReadonlyArray<StructuredOption | SelectGroupBase<StructuredOption>>
  | StructuredOption
  | null
  | undefined;
/** Normalizes values so that they are of type StructuredOption if they were
 * originally strings.
 *
 * Select accepts either string or StructuredOption for many of its props.
 * This function normalizes the values to be of type StructuredOption.
 */
export function normalizeValues(values: ReadonlyArray<SelectOption>): ReadonlyArray<StructuredOption>;
export function normalizeValues(
  values: ReadonlyArray<SelectOption | SelectGroupBase<SelectOption>>
): ReadonlyArray<StructuredOption | SelectGroupBase<StructuredOption>>;
export function normalizeValues(
  values: ReadonlyArray<SelectOption | SelectGroupBase<SelectOption>> | undefined
): ReadonlyArray<StructuredOption | SelectGroupBase<StructuredOption>> | undefined;
export function normalizeValues(
  values: ReadonlyArray<SelectOption> | SelectOption | null | undefined
): ReadonlyArray<StructuredOption> | null | undefined;
export function normalizeValues(
  values: ReadonlyArray<SelectOption> | undefined
): ReadonlyArray<StructuredOption | SelectGroupBase<StructuredOption>> | undefined;
export function normalizeValues<
  Values extends PropsValue<SelectOption> | Array<SelectOption | SelectGroupBase<SelectOption>> | undefined,
>(values: Values): SelectNormalizeValuesReturn {
  if (values === null || values === undefined || values === '') {
    return values as null | undefined;
  } else if (Array.isArray(values)) {
    return values.map((multiSelectOption) => {
      if (typeof multiSelectOption === 'string') {
        return {
          value: multiSelectOption,
          label: multiSelectOption,
        };
      } else if (isSelectOption(multiSelectOption)) {
        return multiSelectOption;
      } else {
        return {
          ...multiSelectOption,
          options: normalizeValues((multiSelectOption as SelectGroupBase<SelectOption>).options),
        };
      }
    });
  } else if (typeof values === 'string') {
    return {
      value: values,
      label: values,
    };
  } else {
    return [values] as StructuredOption[];
  }
}

/** Determines if the given option or group is an option */
export const isSelectOption = <Option extends StructuredOption>(
  optionOrGroup: Option | SelectGroupBase<SelectOption>
): optionOrGroup is Option => !(optionOrGroup as SelectGroupBase<SelectOption>).options;

/** Returns true if a user-typed string matches an existing option */
export const selectValueMatchesOption = (
  inputValue: string,
  option: StructuredOption,
  accessors: SelectAccessors<StructuredOption>
): boolean => {
  const candidate = String(inputValue).toLowerCase();
  const optionValue = String(accessors.getOptionValue(option)).toLowerCase();
  const optionLabel = String(accessors.getOptionLabel(option)).toLowerCase();
  return optionValue === candidate || optionLabel === candidate;
};

/** Implementation for isValidNewOption that allows user-typed options
 * so long as they don't match a pre-existing or pre-selected option
 */
export const selectInputMatchesExistingOption = (
  inputValue: string,
  selectValue: ReadonlyArray<StructuredOption>,
  selectOptions: ReadonlyArray<StructuredOption | SelectGroupBase<StructuredOption>>,
  accessors: SelectAccessors<StructuredOption>
): boolean =>
  !(
    !inputValue ||
    selectValue.some((option) => selectValueMatchesOption(inputValue, option, accessors)) ||
    selectOptions.some((option) => isSelectOption(option) && selectValueMatchesOption(inputValue, option, accessors))
  );

/** Implementation for isValidNewOption that disallows all user-typed options
 *
 * Effectively turns an AsyncCreatable into an AsyncSelect
 */
export const noNewSelectOptionsAreValid = (): false => false;

/** Determines the size of the select based on the layout and size parameters */
export const reconcileSelectSize = (layout: FormLayout, size: SelectSize): SelectSize => {
  //super-compact layout === small size
  if (layout === 'super-compact' || size === 'small') return 'small';

  //compact layout === medium size
  if (layout === 'compact' || size === 'medium') return 'medium';

  //alias large layout to x-large size
  if (layout === 'large') return 'x-large';

  //large is default Select size
  return 'large';
};
