import UseWithinAFormField from './UseWithinAFormField.gen';
import Standalone from './Standalone.gen';
import Inline from './Inline.gen';
import Validation from './Validation.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <UseWithinAFormField />
      <Standalone />
      <Inline />
      <Validation />
    </>
  );
}
