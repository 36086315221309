import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInfoButtonSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M8.75001 12C7.61001 12 7.23001 11.3499 7.25001 10.6499C7.30041 10.0838 7.47086 9.53509 7.75001 9.04004C7.99892 8.6331 8.1654 8.18112 8.24 7.70996C8.24 7.29996 8.01004 7.05 7.40004 7"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5Z"
      fill={props.color}
    />
  </svg>
);
SvgInfoButtonSmall.displayName = 'SvgInfoButtonSmall';
const InfoButtonSmall = withForgeIconProps(SvgInfoButtonSmall, {
  name: 'InfoButton',
  usage: 'Use in a Button to get more information',
  related: 'guide, tutorial',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default InfoButtonSmall;
