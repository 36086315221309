import ListBasic from './ListBasic.gen';
import ListSelection from './ListSelection.gen';
import ListControlled from './ListControlled.gen';
import ListWithDividers from './ListWithDividers.gen';
import ListWithImage from './ListWithImage.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <ListBasic />
      <ListSelection />
      <ListControlled />
      <ListWithDividers />
      <ListWithImage />
    </>
  );
}
