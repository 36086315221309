import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPlusButtonSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11 8.00073H5M8 5.00073V11.0007M15 8.00073C15 11.8667 11.866 15.0007 8 15.0007C4.13401 15.0007 1 11.8667 1 8.00073C1 4.13474 4.13401 1.00073 8 1.00073C11.866 1.00073 15 4.13474 15 8.00073Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgPlusButtonSmall.displayName = 'SvgPlusButtonSmall';
const PlusButtonSmall = withForgeIconProps(SvgPlusButtonSmall, {
  name: 'PlusButton',
  usage: 'Use in a Button to adjust or increment up',
  related: 'add',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default PlusButtonSmall;
