import { isValid } from 'date-fns';

import rawContents from "!!raw-loader!./Validation.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { isValid };

function Validation():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Validation" />;
}

export default Validation;
