import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAddDocumentLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 20C10.5523 20 11 19.5523 11 19C11 18.4477 10.5523 18 10 18V20ZM2 18C1.44772 18 1 18.4477 1 19C1 19.5523 1.44772 20 2 20V18ZM7 15C7 14.4477 6.55228 14 6 14C5.44772 14 5 14.4477 5 15H7ZM5 23C5 23.5523 5.44772 24 6 24C6.55228 24 7 23.5523 7 23H5ZM14 18H13V20H14V18ZM18.5699 19L18.6312 18.0019L18.6006 18H18.5699V19ZM22 16L22.9974 16.0722L23 16.0361V16H22ZM22 6H23V5.5905L22.7128 5.29862L22 6ZM17.08 1L17.7927 0.298615L17.4989 0H17.08V1ZM6 1V0H5V1H6ZM5 11V12H7V11H5ZM15.56 7H14.56C14.56 7.55228 15.0077 8 15.56 8V7ZM10 18H2V20H10V18ZM5 15V23H7V15H5ZM14 20H18.5699V18H14V20ZM18.5087 19.9981C19.623 20.0665 20.7194 19.6926 21.5598 18.9576L20.2431 17.4522C19.7992 17.8405 19.2199 18.038 18.6312 18.0019L18.5087 19.9981ZM21.5598 18.9576C22.4002 18.2226 22.9168 17.1857 22.9974 16.0722L21.0026 15.9278C20.96 16.5161 20.6871 17.0639 20.2431 17.4522L21.5598 18.9576ZM23 16V6H21V16H23ZM22.7128 5.29862L17.7927 0.298615L16.3672 1.70138L21.2872 6.70138L22.7128 5.29862ZM17.08 0H6V2H17.08V0ZM5 1V11H7V1H5ZM14.56 1V7H16.56V1H14.56ZM15.56 8H21.94V6H15.56V8Z"
      fill={props.color}
    />
  </svg>
);
SvgAddDocumentLarge.displayName = 'SvgAddDocumentLarge';
const AddDocumentLarge = withForgeIconProps(SvgAddDocumentLarge, {
  name: 'AddDocument',
  usage: 'Use to add a note to an item or entity',
  related: 'note, paper, file, attachment',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default AddDocumentLarge;
