import rawContents from "!!raw-loader!./HiddenOnMobile.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function HiddenOnMobile():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Hidden On Mobile" />;
}

export default HiddenOnMobile;
