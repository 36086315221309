import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMoreHorizontalLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2.5 14.5C3.88071 14.5 5 13.3807 5 12C5 10.6193 3.88071 9.5 2.5 9.5C1.11929 9.5 0 10.6193 0 12C0 13.3807 1.11929 14.5 2.5 14.5Z"
      fill={props.color}
    />
    <path
      d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z"
      fill={props.color}
    />
    <path
      d="M21.5 14.5C22.8807 14.5 24 13.3807 24 12C24 10.6193 22.8807 9.5 21.5 9.5C20.1193 9.5 19 10.6193 19 12C19 13.3807 20.1193 14.5 21.5 14.5Z"
      fill={props.color}
    />
  </svg>
);
SvgMoreHorizontalLarge.displayName = 'SvgMoreHorizontalLarge';
const MoreHorizontalLarge = withForgeIconProps(SvgMoreHorizontalLarge, {
  name: 'MoreHorizontal',
  usage: 'Use in small spaces to open a menu with additional options',
  related: 'kebab, meatball, navigation',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default MoreHorizontalLarge;
