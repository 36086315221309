import { getDay } from 'date-fns';

import rawContents from "!!raw-loader!./UseWithinAFormField.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { getDay };

function UseWithinAFormField():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Use Within A Form Field" />;
}

export default UseWithinAFormField;
