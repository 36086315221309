import { useEffect, useState, useContext, useMemo } from 'react';
import './tertiary-nav.scss';
import TertiaryNavItem from './tertiary-nav-item';
import { ChildBlock, childBlockLinkId, PageContext } from '../../contexts';
import { ReactElement } from 'react';

interface TertiaryNavProps {
  childrenBlock?: ChildBlock[];
}
const TertiaryNav = ({ childrenBlock }: TertiaryNavProps): ReactElement => {
  const { pageData } = useContext(PageContext);
  const rootChildrenBlock = childrenBlock || pageData?.newNodeInfo?.childrenBlock;

  const [activeItems, setActiveItems] = useState<string[]>([]);

  const itemIds = useMemo<string[]>(() => {
    return (
      rootChildrenBlock
        ?.map((rootChildBlock) => {
          const { childrenBlock } = rootChildBlock;
          const rootTitle = childBlockLinkId(rootChildBlock);
          const childBlocks =
            childrenBlock
              ?.filter(({ field_title }) => !!field_title)
              .map((childBlock) => childBlockLinkId(childBlock)) || [];
          return [rootTitle, ...childBlocks];
        })
        .flat() || []
    );
  }, [rootChildrenBlock]);

  const activeLink = useMemo<string | undefined>(
    () => itemIds.find((item) => activeItems.includes(item)),
    [itemIds, activeItems]
  );

  /** Highlight the active nav item based on where the user has scrolled */
  useEffect(() => {
    const headersObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveItems((prevActive) => [...prevActive, entry.target.id]);
          } else {
            setActiveItems((prevActive) => prevActive.filter((id) => id !== entry.target.id));
          }
        });
      },
      {
        // 60px is the height of our top navbar
        rootMargin: '-60px 0px 0px 0px',
      }
    );

    const elements = itemIds.map((itemId) => document.getElementById(itemId));
    if (elements) {
      elements.forEach((el) => {
        if (el) {
          headersObserver.observe(el);
        }
      });
    }

    return () => {
      if (elements) {
        elements.forEach((el) => {
          if (el) {
            headersObserver.unobserve(el);
          }
        });
      }
    };
  }, [itemIds]);

  if (rootChildrenBlock) {
    const nav = rootChildrenBlock.map((block, index) => {
      if (block.drupal_type === 'paragraph--header') {
        const { id, field_title } = block;
        const link = childBlockLinkId(block);
        return (
          <TertiaryNavItem
            {...block}
            key={field_title || id}
            link={link}
            firstHeading={index === 0 ? true : false}
            activeLink={activeLink}
          />
        );
      } else {
        return <></>;
      }
    });

    return (
      <div className="tertiary-navigation">
        <nav>{nav}</nav>
      </div>
    );
  } else {
    console.error('Could not render Tertiary nav');
    return <></>;
  }
};

export default TertiaryNav;
