import dayjs from 'dayjs';
import { DataTableSortDirection } from '../utils/internalTypes';

type CreateSortByDateString<RowData> = {
  rowDataFieldName: keyof RowData;
};

export function createSortByDateString<RowData>({ rowDataFieldName }: CreateSortByDateString<RowData>) {
  return (row1: RowData, row2: RowData, direction: DataTableSortDirection): number => {
    // if date1 is a nullable value, assign to empty string; otherwise, use resultant Date
    const date1 =
      row1[rowDataFieldName] === '' || row1[rowDataFieldName] === null
        ? ''
        : dayjs(row1[rowDataFieldName] as Date | string);

    // if date1 is a nullable value, assign to empty string; otherwise, use resultant Date
    const date2 =
      row2[rowDataFieldName] === '' || row2[rowDataFieldName] === null
        ? ''
        : dayjs(row2[rowDataFieldName] as Date | string);

    if (direction === 'asc') {
      if (date1 === '') return 1;
      if (date2 === '') return -1;

      return date1.isBefore(date2) ? -1 : 1;
    }

    if (direction === 'desc') {
      if (date1 === '') return 1;
      if (date2 === '') return -1;

      return date1.isAfter(date2) ? -1 : 1;
    }

    return 0;
  };
}
