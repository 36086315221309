import rawContents from "!!raw-loader!./Survey.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Survey():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Survey" />;
}

export default Survey;
