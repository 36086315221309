import MenuBasic from './MenuBasic.gen';
import Controlled from './Controlled.gen';
import MenuUsingTertiaryButton from './MenuUsingTertiaryButton.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <MenuBasic />
      <Controlled />
      <MenuUsingTertiaryButton />
    </>
  );
}
