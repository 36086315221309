import CardBasic from './CardBasic.gen';
import CardWithButtons from './CardWithButtons.gen';
import CardsWithImages from './CardsWithImages.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <CardBasic />
      <CardWithButtons />
      <CardsWithImages />
    </>
  );
}
