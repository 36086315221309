import rawContents from "!!raw-loader!./MultipleSources.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function MultipleSources():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Multiple Sources" />;
}

export default MultipleSources;
