import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGridSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16 0H9V7H16V0Z" fill={props.color} />
    <path d="M7 0H0V7H7V0Z" fill={props.color} />
    <path d="M7 9H0V16H7V9Z" fill={props.color} />
    <path
      d="M9 9H16V13C16 13.7956 15.6839 14.5587 15.1213 15.1213C14.5587 15.6839 13.7956 16 13 16H9V9Z"
      fill={props.color}
    />
  </svg>
);
SvgGridSmall.displayName = 'SvgGridSmall';
const GridSmall = withForgeIconProps(SvgGridSmall, {
  name: 'Grid',
  usage: 'Use to indicate a grid, or the ability to switch to a grid view',
  related: 'gallery, tile',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default GridSmall;
