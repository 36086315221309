import rawContents from "!!raw-loader!./PopoverInput.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function PopoverInput():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Popover Input" />;
}

export default PopoverInput;
