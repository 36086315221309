import rawContents from "!!raw-loader!./ButtonStyleVariants.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ButtonStyleVariants():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Button Style Variants" />;
}

export default ButtonStyleVariants;
