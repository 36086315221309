import Input from '../../../Input';
import { createSortByNumber } from '../../sorts/createSortByNumber';
import { validate_row_data } from '../../store/actions/validate_row_data';
import { classes } from '../../utils/dataTableClasses';
import { DataTableColumn, CreateColumnCommonArgs } from '../../utils/internalTypes';

export type CreatePercentColumn<RowData> = typeof createPercentColumn<RowData>;

export function createPercentColumn<RowData>({
  columnId,
  tooltip,
  headerText,
  footer,
  cellValidation = () => '',
  determineCellAlert = () => '',
  determineCellTooltip,
  displayCellText,
  rowDataFieldName,
  enableClientSideSorting = false,
  enableClientSideFullTextSearch = true,
  enableClientSideCsvDownload = true,
  isConfigurable = true,
  defaultHideColumn = false,
}: CreateColumnCommonArgs<RowData>): DataTableColumn<RowData> {
  const headerTextArray = typeof headerText === 'string' ? [headerText] : headerText;

  if (typeof displayCellText === 'undefined') {
    displayCellText = (row) => {
      const cellValue = row[rowDataFieldName];
      return typeof cellValue === 'number' ? String(cellValue) + '%' : '';
    };
  }

  return {
    columnId,
    configOptionName: isConfigurable ? headerTextArray.join(' ') : '',
    defaultHideColumn,

    // ================================
    // Header
    // ================================
    headerAlignRight: true,
    headerTooltip: tooltip,
    Header: () => (
      <>
        {headerTextArray.map((txt, idx) => (
          <div key={idx}>{txt}</div>
        ))}
      </>
    ),

    // ================================
    // Footer
    // ================================
    Footer: footer,

    // ================================
    // Cell
    // ================================
    cellStyle: () => ({ justifyContent: 'flex-end', textAlign: 'right' }),
    Cell: ({ useSelector, rowId }) => {
      const rowData = useSelector((s) => s.rows[rowId].data);
      const cellText = displayCellText ? displayCellText(rowData) : '';
      return <>{cellText === '' ? '---' : cellText}</>;
    },

    // ================================
    // EditCell
    // ================================
    EditCell: ({ useDispatch, useSelector, rowId }) => {
      const dispatch = useDispatch();
      const cellError = useSelector((s) => s.rows[rowId].cellErrors[columnId]);
      const originalValue = useSelector((s) => s.rows[rowId].originalData[rowDataFieldName]);
      const value = useSelector((s) => s.rows[rowId].data[rowDataFieldName]);

      if (typeof value !== 'number' && value !== null)
        throw new Error(`The value (${value}) should be number type or null.`);

      return (
        <Input
          {...classes({ element: 'td__input', modifiers: { unsaved: value !== originalValue } })}
          type="number"
          min={0}
          max={100}
          // Check NaN to avoid the warning message:
          // - Warning: Received NaN for the `value` attribute. If this is expected, cast the value to a string.
          value={typeof value !== 'number' || Number.isNaN(value) ? '' : value}
          error={cellError}
          onBlur={() => dispatch(validate_row_data(rowId))}
          onChange={(e) => {
            dispatch(function update_percent_cell(s) {
              // @ts-expect-error The field type has already been verified in runtime
              if (e.target.value === '') s.rows[rowId].data[rowDataFieldName] = null;
              // @ts-expect-error The field type has already been verified in runtime
              else s.rows[rowId].data[rowDataFieldName] = Number.parseInt(e.target.value);
              s.rows[rowId].cellErrors[columnId] = ''; // reset cell error
            });
          }}
        />
      );
    },

    // ================================
    // Cell Alert
    // ================================
    determineCellAlert,

    // ================================
    // Cell Tooltip
    // ================================
    determineCellTooltip,

    // ================================
    // Data Validator
    // ================================
    dataValidator: (rowData) => {
      const fieldName = String(rowDataFieldName);
      const value = rowData[rowDataFieldName];

      if (typeof value !== 'number' && value !== null) return `The '${fieldName}' field should be number type.`;
      if (Number.isNaN(value)) return `The '${fieldName}' field cannot be NaN.`;
      if (Number(value) < 0 || Number(value) > 100) return `The range of the '${fieldName}' field should be 0 ~ 100.`;
      return cellValidation(rowData);
    },

    // ================================
    // Sorting
    // ================================
    clientSideSortFn: enableClientSideSorting // prettier-break-line
      ? createSortByNumber({ rowDataFieldName })
      : undefined,

    // ================================
    // Full-Text Search
    // ================================
    clientSideFullTextSearch: {
      enable: enableClientSideFullTextSearch,
      getTextByRow: displayCellText,
    },

    // ================================
    // Download CSV
    // ================================
    clientSideCsvDownload: {
      enable: enableClientSideCsvDownload,
      headerText: headerTextArray.join(' '),
      getTextByRow: displayCellText,
    },
  };
}
