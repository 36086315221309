import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDoctorsBagLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 6V5H0V6H1ZM23 6H24V5H23V6ZM1 22H0V23H1V22ZM16 6V7C16.5523 7 17 6.55228 17 6H16ZM8 6H7C7 6.55228 7.44772 7 8 7V6ZM16 15C16.5523 15 17 14.5523 17 14C17 13.4477 16.5523 13 16 13V15ZM8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15V13ZM13 10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10H13ZM11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18H11ZM1 7H23V5H1V7ZM22 6V19H24V6H22ZM22 19C22 19.5304 21.7893 20.0392 21.4142 20.4142L22.8284 21.8284C23.5786 21.0783 24 20.0609 24 19H22ZM21.4142 20.4142C21.0392 20.7893 20.5304 21 20 21V23C21.0609 23 22.0783 22.5786 22.8284 21.8284L21.4142 20.4142ZM20 21H1V23H20V21ZM2 22V6H0V22H2ZM16 5H8V7H16V5ZM9 6V3H7V6H9ZM9 3L8.99995 2.99995L7.58574 1.58574C7.21055 1.96093 7 2.46974 7 3H9ZM8.99995 2.99995L9 3V1C8.46974 1 7.96093 1.21055 7.58574 1.58574L8.99995 2.99995ZM9 3H15V1H9V3ZM15 3L15 2.99995L16.4143 1.58574C16.0391 1.21055 15.5303 1 15 1V3ZM15 2.99995L15 3H17C17 2.46974 16.7895 1.96093 16.4143 1.58574L15 2.99995ZM15 3V6H17V3H15ZM16 13H8V15H16V13ZM11 10V18H13V10H11Z"
      fill={props.color}
    />
  </svg>
);
SvgDoctorsBagLarge.displayName = 'SvgDoctorsBagLarge';
const DoctorsBagLarge = withForgeIconProps(SvgDoctorsBagLarge, {
  name: 'DoctorsBag',
  usage: 'Use to indicate a medical practice or office',
  related: 'provider, doctor, clinic, care, nurse',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default DoctorsBagLarge;
