import rawContents from "!!raw-loader!./DisabledButton.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function DisabledButton():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Disabled Button" />;
}

export default DisabledButton;
