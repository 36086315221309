import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSendSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.00023 13.2378C9.35245 12.8124 9.29312 12.1821 8.86772 11.8298C8.44232 11.4776 7.81194 11.537 7.45973 11.9624L9.00023 13.2378ZM6.44995 14.75H5.44995C5.44995 15.1716 5.71437 15.5479 6.11102 15.6908C6.50767 15.8337 6.95133 15.7125 7.2202 15.3877L6.44995 14.75ZM6.44995 10.6099L5.73824 9.90739C5.55352 10.0945 5.44995 10.3469 5.44995 10.6099H6.44995ZM11.7117 6.70247C12.0997 6.30941 12.0955 5.67626 11.7025 5.28829C11.3094 4.90033 10.6763 4.90446 10.2883 5.29753L11.7117 6.70247ZM12.74 13.3999L12.3221 14.3084C12.6022 14.4372 12.9261 14.4297 13.1999 14.2879C13.4737 14.1461 13.6667 13.8859 13.7231 13.5828L12.74 13.3999ZM15 1.25L15.9831 1.43287C16.0521 1.06232 15.9068 0.684485 15.6074 0.455567C15.3079 0.226649 14.9052 0.185539 14.5657 0.349231L15 1.25ZM1 8L0.565701 7.09923C0.216888 7.26741 -0.00347411 7.62185 4.1205e-05 8.00908C0.00355652 8.3963 0.230317 8.74669 0.582126 8.9085L1 8ZM7.45973 11.9624L5.6797 14.1123L7.2202 15.3877L9.00023 13.2378L7.45973 11.9624ZM7.44995 14.75V10.6099H5.44995V14.75H7.44995ZM7.16166 11.3123L11.7117 6.70247L10.2883 5.29753L5.73824 9.90739L7.16166 11.3123ZM13.7231 13.5828L15.9831 1.43287L14.0169 1.06713L11.7569 13.217L13.7231 13.5828ZM14.5657 0.349231L0.565701 7.09923L1.4343 8.90077L15.4343 2.15077L14.5657 0.349231ZM0.582126 8.9085L12.3221 14.3084L13.1579 12.4914L1.41787 7.0915L0.582126 8.9085Z"
      fill={props.color}
    />
  </svg>
);
SvgSendSmall.displayName = 'SvgSendSmall';
const SendSmall = withForgeIconProps(SvgSendSmall, {
  name: 'Send',
  usage: 'Use to send a composed message',
  related: 'mail, enter, submit',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default SendSmall;
