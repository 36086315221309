import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPanelRightLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.53729 1L20.5 12.1101L9.53729 23M3.5 1L14.4627 12.1101L3.5 23"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgPanelRightLarge.displayName = 'SvgPanelRightLarge';
const PanelRightLarge = withForgeIconProps(SvgPanelRightLarge, {
  name: 'PanelRight',
  usage: 'Use to open a panel to the right',
  related: 'flyout, pane',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default PanelRightLarge;
