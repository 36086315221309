import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDownloadSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 15H0V16H1V15ZM4.20711 4.79289C3.81658 4.40237 3.18342 4.40237 2.79289 4.79289C2.40237 5.18342 2.40237 5.81658 2.79289 6.20711L4.20711 4.79289ZM8 10L7.29289 10.7071C7.68342 11.0976 8.31658 11.0976 8.70711 10.7071L8 10ZM13.2071 6.20711C13.5976 5.81658 13.5976 5.18342 13.2071 4.79289C12.8166 4.40237 12.1834 4.40237 11.7929 4.79289L13.2071 6.20711ZM9 1C9 0.447715 8.55228 0 8 0C7.44772 0 7 0.447715 7 1H9ZM14 8.64001V12H16V8.64001H14ZM14 12C14 12.5305 13.7893 13.0392 13.4142 13.4142L14.8284 14.8284C15.5786 14.0783 16 13.0608 16 12H14ZM13.4142 13.4142C13.0392 13.7893 12.5305 14 12 14V16C13.0608 16 14.0783 15.5786 14.8284 14.8284L13.4142 13.4142ZM12 14H1V16H12V14ZM2 15V8.64001H0V15H2ZM2.79289 6.20711L7.29289 10.7071L8.70711 9.29289L4.20711 4.79289L2.79289 6.20711ZM8.70711 10.7071L13.2071 6.20711L11.7929 4.79289L7.29289 9.29289L8.70711 10.7071ZM7 1V10H9V1H7Z"
      fill={props.color}
    />
  </svg>
);
SvgDownloadSmall.displayName = 'SvgDownloadSmall';
const DownloadSmall = withForgeIconProps(SvgDownloadSmall, {
  name: 'Download',
  usage: 'Use in a Button to download a file',
  related: 'save, export, document, import',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default DownloadSmall;
