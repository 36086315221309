import React, { Ref, WeakValidationMap, ReactElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BlockUI from '../BlockUI/BlockUI';
import { forgeClassHelper } from '../utils/classes';
import AnimatedSpinner from '../AnimatedSpinner';
import forwardRefToProps from '../utils/forwardRefToProps';

export interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Used if `text` is not provided. Aria label of animated loader (for assistive tech) */
  ariaLabel?: string;
  /** Adds a class to the root element of the component */
  className?: string;
  /** All the contents that should be covered by the loader when it is "loading" */
  children?: React.ReactNode;
  /** Centers the load message and animation in the currently visible area of its parent */
  fixedCenter?: boolean;
  /** Used if the loader is the root element. Covers the entire page but leaves scrollbars visible. */
  fullPage?: boolean;
  /** Whether the loader is visible and animating */
  loading?: boolean;
  /** Loading message text to be placed below the loading icon; should be specific to what content is loading */
  text?: string;
  /** Z-index override value to be sent to the container (holds overlay, spinner and text) element of Loader */
  zIndex?: number;
  /** a ref to the outermost <div> */
  ref?: Ref<HTMLDivElement>;
}

interface LoaderComponentProps extends LoaderProps {
  /** a ref to the outermost <div> */
  forwardedRef?: Ref<HTMLDivElement>;
}

const classes = forgeClassHelper('loader');

const SpinnerContainer = ({ text, ariaLabel }: Pick<LoaderProps, 'ariaLabel' | 'text'>): ReactElement => (
  <div className="fe_c_loader__inner-wrapper" role="status">
    <AnimatedSpinner />
    {text ? (
      <p {...classes('text')}>{text}</p>
    ) : (
      <p {...classes({ element: 'text', extra: 'fe_u_visually-hidden' })}>{ariaLabel}</p>
    )}
  </div>
);

const Loader = ({
  ariaLabel = 'Loading ...',
  loading = false,
  fixedCenter = false,
  fullPage = false,
  children,
  className,
  text,
  zIndex,
  forwardedRef,
  ...rootProps
}: LoaderComponentProps): ReactElement => {
  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    /** HACK: bugfix for fixedCenter not working if loading is true on mount
     *
     * BlockUi always starts with top: 50% and won't start calculating the position immediately unless fixedCenter or loading starts as false
     */

    setIsBlocking(loading);
  }, [loading]);

  return (
    <BlockUI
      aria-live="polite"
      ref={forwardedRef}
      {...classes({
        modifiers: { 'full-page': fullPage },
        extra: className,
        states: { loading: !!loading },
      })}
      blocking={isBlocking}
      loader={<SpinnerContainer text={text} ariaLabel={ariaLabel} />}
      keepInView={fixedCenter}
      style={{ zIndex }}
      {...rootProps}
    >
      <div {...classes('content')} aria-busy={loading}>
        {children}
      </div>
    </BlockUI>
  );
};

const LoaderPropTypes: WeakValidationMap<LoaderComponentProps & { '...rest': unknown }> = {
  /** Used if `text` is not provided. Aria label of animated loader (for assistive tech) */
  ariaLabel: PropTypes.string,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** All the contents that should be covered by the loader when it is "loading" */
  children: PropTypes.node,
  /** Centers the load message and animation in the currently visible area of its parent */
  fixedCenter: PropTypes.bool,
  /** Used if the loader is the root element. Covers the entire page but leaves scrollbars visible. */
  fullPage: PropTypes.bool,
  /** Whether the loader is visible and animating */
  loading: PropTypes.bool,
  /** Loading message text to be placed below the loading icon; should be specific to what content is loading */
  text: PropTypes.string,
  /** Z-index override value to be sent to the container (holds overlay, spinner and text) element of Loader */
  zIndex: PropTypes.number,
  /** Passthrough props to the root `<div>` of the component. Also passes props to [react-block-ui](https://github.com/availity/react-block-ui)'s BlockUI component */
  '...rest': PropTypes.any,
};

Loader.propTypes = LoaderPropTypes;

export default forwardRefToProps(Loader);
