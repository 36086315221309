import { classes } from '../../utils/dataTableClasses';
import { DataTableColumn, DataTableHooksAndRowId } from '../../utils/internalTypes';

type RowActionButton<RowData> = React.ComponentType<DataTableHooksAndRowId<RowData>>;

type CreateActionColumnArgs<RowData> = {
  /** @deprecated There's no need to provide the columnId; it will always be set as "row-action" */
  columnId?: string;
  headerText: string;
  actionButtons: {
    view: RowActionButton<RowData>[];
    edit: RowActionButton<RowData>[];
    deleted: RowActionButton<RowData>[];
  };
};
export type CreateRowActionColumn<RowData> = typeof createRowActionColumn<RowData>;

export function createRowActionColumn<RowData>({
  headerText,
  actionButtons,
}: CreateActionColumnArgs<RowData>): DataTableColumn<RowData> {
  return {
    columnId: 'row-action',

    // ================================
    // Header
    // ================================
    Header: () => <>{headerText}</>,

    // ================================
    // Cell
    // ================================
    Cell: (props) => {
      return (
        <div {...classes({ element: 'row_action_column' })}>
          {actionButtons.view.map((RowActionButton, idx) => (
            <RowActionButton key={idx} {...props} />
          ))}
        </div>
      );
    },

    // ================================
    // EditCell
    // ================================
    EditCell: (props) => {
      return (
        <div {...classes({ element: 'row_action_column' })}>
          {actionButtons.edit.map((RowActionButton, idx) => (
            <RowActionButton key={idx} {...props} />
          ))}
        </div>
      );
    },

    // ================================
    // DeleteCell
    // ================================
    DeleteCell: (props) => {
      return (
        <div {...classes({ element: 'row_action_column' })}>
          {actionButtons.deleted.map((RowActionButton, idx) => (
            <RowActionButton key={idx} {...props} />
          ))}
        </div>
      );
    },
  };
}
