import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBugLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 15.9975H20V13.9959H22C22.5523 13.9959 23 13.5482 23 12.9959C23 12.4436 22.5523 11.9959 22 11.9959H20V9.99436H22C22.5523 9.99436 23 9.54665 23 8.99436C23 8.44208 22.5523 7.99437 22 7.99437H20C19.9159 7.99437 19.8343 8.00474 19.7563 8.02427C19.337 6.36597 18.3998 4.91388 17.1269 3.85012L19.2453 1.70276C19.6331 1.30959 19.6289 0.676315 19.2359 0.2883C18.8429 -0.0997149 18.2099 -0.0955371 17.822 0.297631L15.4032 2.74952C14.3705 2.26339 13.217 1.99164 12 1.99164C10.7689 1.99164 9.60282 2.26971 8.56103 2.76646L6.12552 0.297631C5.73766 -0.0955371 5.10463 -0.0997149 4.71161 0.2883C4.31859 0.676315 4.31441 1.30959 4.70228 1.70276L6.84443 3.87422C5.58614 4.93576 4.65988 6.37845 4.24371 8.02427C4.1657 8.00474 4.08406 7.99437 4 7.99437H2C1.44772 7.99437 1 8.44208 1 8.99436C1 9.54665 1.44772 9.99436 2 9.99436H4V11.9959H2C1.44772 11.9959 1 12.4436 1 12.9959C1 13.5482 1.44772 13.9959 2 13.9959H4V15.9975H2C1.44772 15.9975 1 16.4452 1 16.9975C1 17.5498 1.44772 17.9975 2 17.9975H4C4.08413 17.9975 4.16583 17.9871 4.2439 17.9675C5.12084 21.4342 8.26084 23.9994 12 23.9994C15.7392 23.9994 18.8792 21.4341 19.7561 17.9675C19.8342 17.9871 19.9159 17.9975 20 17.9975H22C22.5523 17.9975 23 17.5498 23 16.9975C23 16.4452 22.5523 15.9975 22 15.9975ZM12 3.99164C8.68629 3.99164 6 6.67794 6 9.99165V15.9994C6 19.3131 8.68629 21.9994 12 21.9994C15.3137 21.9994 18 19.3131 18 15.9994V9.99164C18 6.67793 15.3137 3.99164 12 3.99164ZM10 10.9951C10 10.4429 10.4477 9.99514 11 9.99514H13C13.5523 9.99514 14 10.4429 14 10.9951C14 11.5474 13.5523 11.9951 13 11.9951H11C10.4477 11.9951 10 11.5474 10 10.9951ZM11 13.9967C10.4477 13.9967 10 14.4444 10 14.9967C10 15.549 10.4477 15.9967 11 15.9967H13C13.5523 15.9967 14 15.549 14 14.9967C14 14.4444 13.5523 13.9967 13 13.9967H11Z"
      fill={props.color}
    />
  </svg>
);
SvgBugLarge.displayName = 'SvgBugLarge';
const BugLarge = withForgeIconProps(SvgBugLarge, {
  name: 'Bug',
  usage: 'Use to indicate a software bug or coding error (internal use only)',
  related: 'code, issue, p1, p2, p3, p4',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default BugLarge;
