import { ReactNode } from 'react';
import Button from '../../Button';
import ListItem from '../../ListItem';
import { forgeClassHelper } from '../../utils/classes';
import { FileUploadAction, FileUploadFile } from '../fileUploadReducer';

const classes = forgeClassHelper('file-upload');

interface FileUploadDeletedProps {
  dispatch: React.Dispatch<FileUploadAction>;
  file: FileUploadFile;
}

/** Renders a file that had been uploaded, but has been subsequently deleted */
const FileUploadDeleted = ({ dispatch, file }: FileUploadDeletedProps): ReactNode => {
  return (
    <ListItem uniqueKey={file.id} role="status" {...classes({ element: 'deleted-status' })}>
      {file.fileData.name} has been deleted
      <Button
        size="small"
        variant="tertiary"
        text="Undo delete"
        onClick={() => dispatch({ type: 'undo-delete', affectedFile: file })}
      />
    </ListItem>
  );
};

export default FileUploadDeleted;
