import Button from '../../../Button';
import { DataTableStore } from '../../store/stateTypes';
import { classes } from '../../utils/dataTableClasses';

type ColumnConfigPopoverHeaderProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function ColumnConfigPopoverHeader<RowData>({ store }: ColumnConfigPopoverHeaderProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const hasColumnChanged = store.useSelector((s) => {
    const defaultColumnIds = s.columnConfig.defaultOptions.map((opt) => opt.columnId);

    // compare columnIds
    if (defaultColumnIds.length !== s.columnIds.length) {
      return true;
    }

    for (let i = 0; i < defaultColumnIds.length; i++) {
      if (defaultColumnIds[i] !== s.columnIds[i]) {
        return true;
      }
    }

    for (const opt of s.columnConfig.defaultOptions) {
      const column = s.columns[opt.columnId];
      if (column.hide !== opt.hideColumn) {
        return true;
      }
    }

    // has no changed
    return false;
  });

  return (
    <div {...classes({ element: 'column_config__popover_header' })}>
      <Button
        text="Default"
        variant="tertiary"
        size="medium"
        disabled={!hasColumnChanged}
        onClick={() => {
          dispatch(function user_click_column_config_default_button(s) {
            // reset column orders
            s.columnIds = s.columnConfig.defaultOptions.map((opt) => opt.columnId);
            s.columnConfig.configurableColumnIds = s.columnConfig.defaultOptions
              .filter((c) => c.columnName !== '')
              .map((c) => c.columnId);

            // reset column hide
            for (const opt of s.columnConfig.defaultOptions) {
              const column = s.columns[opt.columnId];
              column.hide = opt.hideColumn ?? false;
            }
          });
        }}
      />
    </div>
  );
}
