import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCollaborateLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m6.372 16.348 1.977 2.29a.698.698 0 0 1-.073.971.695.695 0 0 1-.98-.073l-1.455-2.391-1.455 2.391a.694.694 0 1 1-1.052-.907l1.976-2.29a.67.67 0 0 1 .522-.239.7.7 0 0 1 .522.239"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.281 4.555A3.554 3.554 0 0 1 5.832 1a3.554 3.554 0 0 1 2.563 6.016 4.834 4.834 0 0 1 2.25 4.082v.943a2.51 2.51 0 0 1-2.507 2.51H1v-3.454c0-1.718.905-3.232 2.262-4.089a3.545 3.545 0 0 1-.98-2.453Zm3.702 1.716a4.855 4.855 0 0 0-.307 0 1.723 1.723 0 0 1 .156-3.438 1.722 1.722 0 0 1 .15 3.438Zm-.33 1.835a3.005 3.005 0 0 0-2.823 2.992v1.621h5.308a.679.679 0 0 0 .677-.678v-.944A3.005 3.005 0 0 0 6 8.107a3.607 3.607 0 0 1-.346-.001Zm6.585-6.188a.916.916 0 0 1 .923-.909l-.015 1.833a.916.916 0 0 1-.908-.924Zm.908.924.359.003a909.911 909.911 0 0 0 2.842.015c.483 0 .897.405.897.916v2.96a.916.916 0 1 0 1.83 0v-2.96c0-1.505-1.215-2.749-2.727-2.749a341.692 341.692 0 0 1-2.828-.015l-.358-.003-.015 1.833Zm1.49 10.161a3.554 3.554 0 0 1 3.55-3.555 3.554 3.554 0 0 1 2.563 6.016A4.835 4.835 0 0 1 23 19.546v.943A2.51 2.51 0 0 1 20.492 23h-7.138v-3.454c0-1.72.905-3.233 2.263-4.09a3.546 3.546 0 0 1-.981-2.453Zm3.701 1.716a4.997 4.997 0 0 0-.306 0 1.723 1.723 0 0 1 .155-3.438 1.722 1.722 0 0 1 .151 3.438Zm-.329 1.835a3.005 3.005 0 0 0-2.823 2.992v1.621h5.307a.679.679 0 0 0 .678-.678v-.943a3.005 3.005 0 0 0-2.816-2.991 3.61 3.61 0 0 1-.346 0ZM5.86 16.357c.506 0 .916.41.916.916v2.96c0 .51.413.916.896.916a341.187 341.187 0 0 1 2.843.016l.358.003A.916.916 0 0 1 10.858 23l-.359-.003-.863-.006c-.674-.005-1.47-.01-1.965-.01-1.511 0-2.727-1.243-2.727-2.748v-2.96c0-.506.41-.916.915-.916Z"
      fill={props.color}
    />
    <path
      d="m18.15 6.846 1.455-2.392a.694.694 0 1 1 1.052.908l-1.977 2.29a.67.67 0 0 1-.521.238.7.7 0 0 1-.522-.238l-.238-.275"
      fill={props.color}
    />
    <path
      d="m17.39 7.377-1.748-2.025a.698.698 0 0 1-.165-.449.7.7 0 0 1 .239-.522.695.695 0 0 1 .979.073l1.455 2.392"
      fill={props.color}
    />
  </svg>
);
SvgCollaborateLarge.displayName = 'SvgCollaborateLarge';
const CollaborateLarge = withForgeIconProps(SvgCollaborateLarge, {
  name: 'Collaborate',
  usage: 'Use to show collaboration or coaching',
  related: 'N/A',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default CollaborateLarge;
