import { faker } from '@faker-js/faker';

import rawContents from "!!raw-loader!./TagAsFilterOption.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { faker };

function TagAsFilterOption():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Tag As Filter Option" />;
}

export default TagAsFilterOption;
