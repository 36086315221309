import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgListTaskSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1 2.5L2.5 4L5.5 1M1 8L2.5 9.5L5.5 6.5M1 13.5L2.5 15L5.5 12M9 2.5H15M9 8H15M9 14H15"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgListTaskSmall.displayName = 'SvgListTaskSmall';
const ListTaskSmall = withForgeIconProps(SvgListTaskSmall, {
  name: 'ListTask',
  usage:
    'Use to indicate a task list, or the ability to switch to a task list view',
  related: 'table, grid, list, checklist, tasks, task list',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ListTaskSmall;
