import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgQualitySmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11 11.03V15L8.08997 12.9199C8.0623 12.905 8.03141 12.8975 8 12.8975C7.96859 12.8975 7.9377 12.905 7.91003 12.9199L5 15V11.03"
      fill={props.color}
    />
    <path
      d="M11 11.03V15L8.08997 12.9199C8.0623 12.905 8.03141 12.8975 8 12.8975C7.96859 12.8975 7.9377 12.905 7.91003 12.9199L5 15V11.03"
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M6.16823 5.75604C5.75735 5.38699 5.12509 5.4209 4.75604 5.83177C4.38699 6.24265 4.4209 6.87491 4.83177 7.24396L6.16823 5.75604ZM7.17 8L6.50177 8.74396C6.88228 9.08573 7.45934 9.0853 7.83933 8.74296L7.17 8ZM11.1693 5.74296C11.5797 5.3733 11.6126 4.74099 11.243 4.33067C10.8733 3.92034 10.241 3.88738 9.83067 4.25704L11.1693 5.74296ZM12.5 6.5C12.5 8.98528 10.4853 11 8 11V13C11.5899 13 14.5 10.0899 14.5 6.5H12.5ZM8 11C5.51472 11 3.5 8.98528 3.5 6.5H1.5C1.5 10.0899 4.41015 13 8 13V11ZM3.5 6.5C3.5 4.01472 5.51472 2 8 2V0C4.41015 0 1.5 2.91015 1.5 6.5H3.5ZM8 2C10.4853 2 12.5 4.01472 12.5 6.5H14.5C14.5 2.91015 11.5899 0 8 0V2ZM4.83177 7.24396L6.50177 8.74396L7.83822 7.25604L6.16823 5.75604L4.83177 7.24396ZM7.83933 8.74296L11.1693 5.74296L9.83067 4.25704L6.50066 7.25704L7.83933 8.74296Z"
      fill={props.color}
    />
  </svg>
);
SvgQualitySmall.displayName = 'SvgQualitySmall';
const QualitySmall = withForgeIconProps(SvgQualitySmall, {
  name: 'Quality',
  usage: 'Use to indicate quality measure parameters',
  related: 'legal, meaningful use, survey, data',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default QualitySmall;
