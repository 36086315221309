import { forwardRef, NamedExoticComponent, Ref, RefAttributes } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ComponentWithDisplayName, ComponentWithRefContext, DistributiveOmit } from './types';

export type ForwardedRefType<Props> = Props extends { forwardedRef?: Ref<infer RefType> } ? RefType : never;

export interface ForwardedRefProps<RefType> {
  forwardedRef?: Ref<RefType>;
}

export type ForwardRefToPropsReturn<Props> = NamedExoticComponent<
  DistributiveOmit<Props, 'ref' | 'forwardedRef'> & RefAttributes<ForwardedRefType<Props>>
>;

/** React.forwardRef makes consuming "ref" as a prop complex. A common pattern
 * in Forge components is to rename the "ref" prop to "forwardedRef" to get
 * around React.forwardRef limitations. This higher order component standardizes
 * this pattern.
 */
export default function forwardRefToProps<Props extends ForwardedRefProps<ForwardedRefType<Props>>>(
  WrappedComponent: ComponentWithDisplayName<Props>
): ForwardRefToPropsReturn<Props> {
  const ForwardRefToProps: ComponentWithRefContext<
    DistributiveOmit<Props, 'ref' | 'forwardedRef'>,
    Ref<ForwardedRefType<Props>>
  > = (props, ref) => {
    const wrappedProps = {
      ...props,
      forwardedRef: ref,
      /** Typescript does not like that typeof props !== Props, hence the
       * aggressive type assertion.
       *
       * > Conversion of to type 'Props' may be a mistake because neither
       * > type sufficiently overlaps with the other
       *
       * In this case, it feels like due diligence has been done to ensure the
       * types are accurate:
       * 1. forwardedRef don't exist in keyof props, but are
       *    added by in this object.
       * 2. ref is stripped out by React.forwardRef if it happened to exist.
       * 3. All other props are spread across this object.
       */
    } as unknown as Props;
    return <WrappedComponent {...wrappedProps} />;
  };
  ForwardRefToProps.displayName = `forwardRefToProps(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;
  hoistNonReactStatics(ForwardRefToProps, WrappedComponent);

  return forwardRef(ForwardRefToProps) as ForwardRefToPropsReturn<Props>;
}
