import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPrintLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M17.5 20.0007H20C20.7956 20.0007 21.5587 19.6847 22.1213 19.1221C22.6839 18.5595 23 17.7964 23 17.0007V6.00073H1V20.0007H6.53003M6.5 5.50073V1.00073H17.5V5.50073M6.5 15.0007H17.5V23.0007H6.5V15.0007Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgPrintLarge.displayName = 'SvgPrintLarge';
const PrintLarge = withForgeIconProps(SvgPrintLarge, {
  name: 'Print',
  usage: 'Use to print',
  related: 'export',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default PrintLarge;
