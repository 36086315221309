import EmptyState from '../../EmptyState';
import { DataTableStore } from '../store/stateTypes';
import { RowEmptyReason } from '../utils/internalTypes';

type TableEmptyStateProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function TableEmptyState<RowData>({ store }: TableEmptyStateProps<RowData>): JSX.Element {
  const originalRowCount = store.useSelector((s) => Object.keys(s.rows).length);
  const rowCount = store.useSelector((s) => s.filteredAndSortedRowIds.length); // doesn't matter with client-side or server-side
  const CustomEmptyStateComponent = store.useSelector((s) => s.CustomEmptyStateComponent);

  if (rowCount > 0) return <></>;

  const emptyReason: RowEmptyReason =
    originalRowCount === 0
      ? 'NO_DATA_TO_SHOW' // prettier-break-line
      : 'NO_DATA_WITH_CLIENT_FILTERS';

  // Custom Empty State Component
  if (typeof CustomEmptyStateComponent !== 'undefined') {
    return (
      <CustomEmptyStateComponent
        emptyReason={emptyReason}
        useSelector={store.useSelector}
        useDispatch={store.useDispatch}
        useDispatchAsyncAction={store.useDispatchAsyncAction}
      />
    );
  }

  // Default Empty State Component
  return (
    <>
      {/* NO_DATA_TO_SHOW */}
      {emptyReason === 'NO_DATA_TO_SHOW' && (
        <div className="fe_u_padding--large">
          <EmptyState
            type="StartList"
            headline="No data to show"
            detail={null}
            primaryButtonProps={null}
            secondaryButtonProps={null}
          />
        </div>
      )}

      {/* NO_DATA_WITH_CLIENT_FILTERS */}
      {emptyReason === 'NO_DATA_WITH_CLIENT_FILTERS' && (
        <div className="fe_u_padding--large">
          <EmptyState
            type="NoFilterData"
            headline="No data with these filter settings"
            detail={null}
            primaryButtonProps={null}
            secondaryButtonProps={null}
          />
        </div>
      )}
    </>
  );
}
