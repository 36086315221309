import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgShareSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2.5 11C3.88071 11 5 9.88071 5 8.5C5 7.11929 3.88071 6 2.5 6C1.11929 6 0 7.11929 0 8.5C0 9.88071 1.11929 11 2.5 11Z"
      fill={props.color}
    />
    <path
      d="M13.5 5C14.8807 5 16 3.88071 16 2.5C16 1.11929 14.8807 0 13.5 0C12.1193 0 11 1.11929 11 2.5C11 3.88071 12.1193 5 13.5 5Z"
      fill={props.color}
    />
    <path
      d="M13.5 16C14.8807 16 16 14.8807 16 13.5C16 12.1193 14.8807 11 13.5 11C12.1193 11 11 12.1193 11 13.5C11 14.8807 12.1193 16 13.5 16Z"
      fill={props.color}
    />
    <path
      d="M13.5 2.5L2.5 8.5L13.5 13.5"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgShareSmall.displayName = 'SvgShareSmall';
const ShareSmall = withForgeIconProps(SvgShareSmall, {
  name: 'Share',
  usage: 'Use to open sharing options',
  related: 'send, export',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ShareSmall;
