import Highlight, { Language, defaultProps } from 'prism-react-renderer';
import github from 'prism-react-renderer/themes/github';
import { ReactElement } from 'react';
import createBEMHelper from '../../bem-helper';

import './code.scss';

const classes = createBEMHelper({ name: 'code' });

export interface CodeProps {
  codeString: string;
  language: Language;
}

/** Highlights code in the browser */
export default function Code({ codeString, language }: CodeProps): ReactElement {
  return (
    <Highlight {...defaultProps} theme={github} code={codeString} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre {...classes({ element: 'preformatted', extra: className })} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line })} key={i}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token })} key={key} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
}
