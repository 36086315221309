import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { contextDefaultValues, Theme } from './context';

export const ThemeContext = createContext(contextDefaultValues.theme);

/** Retrieves the theme from context if the provided theme is undefined. */
export const useTheme = (theme?: Theme): Theme => {
  const themeContext = useContext(ThemeContext);
  return theme || themeContext;
};

export const themeContextPropTypes = PropTypes.shape({
  rootClasses: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
});
