import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAppointmentSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2 15H1V16H2V15ZM2 4V3H1V4H2ZM14 4H15V3H14V4ZM14 12H15H14ZM6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1H6ZM4 4C4 4.55228 4.44772 5 5 5C5.55228 5 6 4.55228 6 4H4ZM12 1C12 0.447715 11.5523 0 11 0C10.4477 0 10 0.447715 10 1H12ZM10 4C10 4.55228 10.4477 5 11 5C11.5523 5 12 4.55228 12 4H10ZM14 6C14.5523 6 15 5.55228 15 5C15 4.44772 14.5523 4 14 4V6ZM2 4C1.44772 4 1 4.44772 1 5C1 5.55228 1.44772 6 2 6V4ZM10 11C10.5523 11 11 10.5523 11 10C11 9.44772 10.5523 9 10 9V11ZM6 9C5.44772 9 5 9.44772 5 10C5 10.5523 5.44772 11 6 11V9ZM9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8H9ZM7 12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12H7ZM3 15V4H1V15H3ZM2 5H14V3H2V5ZM13 4V12H15V4H13ZM13 12C13 12.5304 12.7893 13.0389 12.4142 13.414L13.8284 14.8282C14.5785 14.0781 15 13.0609 15 12L13 12ZM12.4142 13.414C12.039 13.7892 11.5302 14 11 14V16C12.0611 16 13.0784 15.5782 13.8284 14.8282L12.4142 13.414ZM11 14H2V16H11V14ZM4 1V4H6V1H4ZM10 1V4H12V1H10ZM14 4H2V6H14V4ZM10 9H6V11H10V9ZM7 8V12H9V8H7Z"
      fill={props.color}
    />
  </svg>
);
SvgAppointmentSmall.displayName = 'SvgAppointmentSmall';
const AppointmentSmall = withForgeIconProps(SvgAppointmentSmall, {
  name: 'Appointment',
  usage: 'Use to schedule an appointment',
  related: 'calendar, date, visit, encounter, add, follow up',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default AppointmentSmall;
