import Button from '../../Button';
import { AlertData, DataTableHooksAndRowId, ModalAlertData, ToastAlertData } from '../utils/internalTypes';
import { restore_row_async } from '../store/asyncActions/restore_row_async';

export type RestoreRowActionArgs<RowData> = {
  serverSideRestoreRowFn?: (rowData: RowData) => Promise<void>;
  onRowRestored?: (rowData: RowData) => void;
  confirmModal?: (rowData: RowData) => AlertData;
  completeModal?: (rowData: RowData) => ModalAlertData;
  completeToast?: (rowData: RowData) => ToastAlertData;
};
export type CreateRestoreRowActionButton<RowData> = typeof createRestoreRowActionButton<RowData>;

export function createRestoreRowActionButton<RowData>({
  serverSideRestoreRowFn,
  onRowRestored,
  confirmModal,
  completeModal,
  completeToast,
}: RestoreRowActionArgs<RowData> = {}) {
  return function RestoreRowActionButton({
    useDispatchAsyncAction,
    useSelector,
    rowId,
  }: DataTableHooksAndRowId<RowData>): JSX.Element {
    const dispatchAsyncAction = useDispatchAsyncAction();
    const rowData = useSelector((s) => s.rows[rowId].data);
    const tableType = useSelector((s) => s.tableType);

    return (
      <Button
        data-row-action-name="restore-row"
        text="Restore"
        variant="tertiary"
        onClick={async (event) => {
          // prevent trigger left click on row
          event.stopPropagation();

          dispatchAsyncAction(
            restore_row_async({
              tableType,
              rowData,
              rowId,
              serverSideRestoreRowFn,
              onRowRestored,
              confirmModal,
              completeModal,
              completeToast,
            })
          );
        }}
      />
    );
  };
}
