import PlusButtonLarge from '@athena/forge-icons/dist/PlusButtonLarge';
import PrintLarge from '@athena/forge-icons/dist/PrintLarge';
import ExportLarge from '@athena/forge-icons/dist/ExportLarge';

import rawContents from "!!raw-loader!./ModalWithCustomizedButtons.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { PlusButtonLarge, PrintLarge, ExportLarge };

function ModalWithCustomizedButtons():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Modal With Customized Buttons" />;
}

export default ModalWithCustomizedButtons;
