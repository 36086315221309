import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRecentSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4.19851 12.7004C4.84391 13.321 5.60299 13.7992 6.43002 14.1063C7.25705 14.4135 8.13506 14.5434 9.01135 14.4879C9.84 14.4494 10.653 14.2395 11.4034 13.8709C12.1538 13.5023 12.8265 12.982 13.3827 12.3402C13.9388 11.6984 14.3673 10.9478 14.6432 10.1321C14.9191 9.31633 15.0369 8.45171 14.9899 7.58811C14.8703 6.16696 14.317 4.82296 13.4111 3.75389C12.5053 2.68482 11.2946 1.94705 9.95694 1.64829C8.61924 1.34953 7.22499 1.50585 5.97875 2.09427C4.73251 2.6827 3.7 3.67188 3.03276 4.9173L2.03633 6.9955M1 3.92028L1.96654 7.03758L4.98573 6.04009M8.5 5.49713V9.49367H10.5"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgRecentSmall.displayName = 'SvgRecentSmall';
const RecentSmall = withForgeIconProps(SvgRecentSmall, {
  name: 'Recent',
  usage: 'Use to view recently modified items',
  related: 'added, edited, new',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default RecentSmall;
