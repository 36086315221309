import Input from '../../../../Input';
import { DataTableFilter, CreateDataFilterCommonArgs } from '../../../utils/internalTypes';

type CreateNumericInputFilterArgs<RowData> = CreateDataFilterCommonArgs<RowData> & {
  currency?: boolean; // Default: false
  integer?: boolean; // Default: true
  serverSideQueryParamFn?: (num: number) => string;
};
export type CreateNumericInputFilter<RowData> = typeof createNumericInputFilter<RowData>;

export function createNumericInputFilter<RowData>({
  filterId,
  filterLabel,
  serverSideQueryParamFn,
  rowDataFieldName,
  currency = false,
  integer = true,
}: CreateNumericInputFilterArgs<RowData>): DataTableFilter<RowData, number> {
  return {
    filterId,
    filterLabel,
    serverSideQueryParamFn:
      typeof serverSideQueryParamFn !== 'undefined'
        ? ({ conditionValues }) => serverSideQueryParamFn(conditionValues[0])
        : undefined,
    clientSideFilterFn: ({ rowData, conditionValues }) => {
      const fieldValue = rowData[rowDataFieldName] as number;
      const isInvalid = typeof fieldValue !== 'number' && fieldValue !== null;

      if (isInvalid) {
        throw new Error(`The filtered field (${String(rowDataFieldName)}) should be number type, or null - HERE!.`);
      }
      return fieldValue === conditionValues[0];
    },

    DataFilterComponent: ({ useSelector, onFilterConditionValuesChange }) => {
      // Each input component has distinct EMPTY_STATE, such as '', undefined, null, ...
      const EMPTY_STATE = '';
      const conditionValues = useSelector((s) => s.dataFilter.filters[filterId]?.conditionValues ?? []) as number[];

      return (
        <Input
          id={`numeric-input-filter-${filterId}`}
          role={`numeric-input-filter`}
          value={conditionValues.length === 0 ? EMPTY_STATE : conditionValues[0]}
          currency={currency}
          step={integer ? 1 : undefined}
          type="number"
          onChange={(e) => {
            const value = e.target.value;
            onFilterConditionValuesChange(
              value === EMPTY_STATE ? [] : [Number(fixEventText(currency, integer, value))]
            );
          }}
        />
      );
    },

    describeConditionValue: (conditionValue) => String(conditionValue),
  };
}

// ====================================
// Utils
// ====================================
function fixEventText(isCurrency: boolean, isInteger: boolean, value: string): string {
  if (value !== null && !isCurrency) {
    if (isInteger) {
      // Remove extraneous negative signs but keep decimal.  It will be handled next.
      value =
        value.charAt(0) === '-' ? '-' + value.substring(1).replace(/[^0-9\\.]/g, '') : value.replace(/[^0-9\\.]/g, '');
      value = Math.floor(parseFloat(value)).toFixed(0);
    } else {
      // If floating point is allowed, get rid of any extra "." or "-".
      let badCharIndex = value.indexOf('.'); //Actually, good.  The first decimal point.

      if (badCharIndex !== -1 && (badCharIndex = value.indexOf('.', badCharIndex + 1)) !== -1) {
        // Is there a second decimal point?
        value = value.substring(0, badCharIndex); // Keep all until second decimal point.
      }

      value =
        value.charAt(0) === '-' ? '-' + value.substring(1).replace(/[^0-9.]/g, '') : value.replace(/[^0-9.]/g, '');
    }
  }

  return value;
}
