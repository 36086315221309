import Basic from './Basic.gen';
import Sorting from './Sorting.gen';
import QuickFilterSortWithPagination from './QuickFilterSortWithPagination.gen';
import EditRowInDialog from './EditRowInDialog.gen';
import ClickableRow from './ClickableRow.gen';
import CustomColumn from './CustomColumn.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <Basic />
      <Sorting />
      <QuickFilterSortWithPagination />
      <EditRowInDialog />
      <ClickableRow />
      <CustomColumn />
    </>
  );
}
