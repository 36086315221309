import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import {
  ReactElement,
  ReactNode,
  Ref,
  Validator,
  WeakValidationMap,
  useCallback,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { forgeClassHelper } from '../utils/classes';
import FakeEvent from '../utils/fake-event';
import forwardRefToProps from '../utils/forwardRefToProps';
import FileUploadIncomplete from './components/FileUploadIncomplete';
import FileUploadPending from './components/FileUploadPending';
import FileUploadUploaded from './components/FileUploadUploaded';
import fileUploadReducer, {
  FileUploadAction,
  FileUploadFile,
  FileUploadState,
  fileUploadFilesDefaultValue,
} from './fileUploadReducer';
import { optionalBoolValidator, optionalStringValidator } from '../utils/betterPropTypes';

const classes = forgeClassHelper('file-upload');

export type FileUploadFiles = ReadonlyArray<FileUploadFile>;

/** Determines whether FileUpload is initiated by a simple button, or a
 * drag-and-drop region */
export type FileUploadBehavior = 'simple' | 'drag-and-drop';
export type FileUploadLimit = number | 'unlimited';
export type FileUploadExtensions = ReadonlyArray<string>;
export type FileUploadWidth = 'responsive' | 'fixed';

export interface FileUploadRef {
  wrapper: HTMLDivElement;
  dispatch: React.Dispatch<FileUploadAction>;
}

export interface FileUploadChangeValue {
  /** The current state of the files */
  files: FileUploadState;
  /** the action that will be applied */
  action: FileUploadAction;
}
export type FileUploadChangeEvent = FakeEvent<FileUploadChangeValue>;

export interface FileUploadProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  /**
   * The types of files that are accepted.
   *
   * This array of strings specifies the MIME types or file extensions
   * that will be applied to the "accept" attribute of a native HTML input element.
   * When specified, the file picker dialog will filter and display only the files
   * matching these types for selection.
   *
   * Additionally, for drag and drop, only files with these types will be accepted for upload.
   *
   * Example: ['image/png', '.pdf', '.docx]
   */
  acceptedFileTypes?: FileUploadExtensions;
  /** Determines whether FileUpload is initiated by a simple button, or a
   * drag-and-drop region */
  behavior?: FileUploadBehavior;
  /** Content to display inside the FileUpload component, below the rest of the
   * content */
  children?: ReactNode;
  /** CSS class to apply to the FileUpload's wrapper element */
  className?: string;
  /** Initial state of files within the FileUpload
   *
   * There is no `files` prop because it's easier to do incremental state
   * management via ref.current.dispatch(). If full control over FileUpload
   * state is desired, then dispatch the `set-state` action.
   */
  defaultFiles?: FileUploadState;
  /** Whether the FileUpload is disabled */
  disabled?: boolean;
  /**
   * The limit of files that can be uploaded via drag and drop. Does not work
   * with `behavior="simple"`
   *
   * This can either be a number indicating the maximum number of files allowed
   * or the string 'unlimited' to indicate no limit.
   *
   * Example:
   * fileUploadLimit: 5 // Limits to 5 files
   * fileUploadLimit: 'unlimited' // No limit on the number of files
   */
  fileUploadLimit?: FileUploadLimit;
  /** DOM id of the top-level wrapper element */
  id?: string;
  /** Function to call when a change event occurs
   *
   * event.target.value is a `FileUploadChangeValue` object representing the change
   * that has happened internal to the FileUpload component, along with the
   * current state of the files. Actions include adding an upload to the pending
   * list or initiating the upload. If this callback function returns a
   * `FileUploadAction` object, that action will be dispatched instead of
   * `event.target.value`. This is useful for changing the upload progress of a
   * file from `undefined` to 0%.
   *
   * It is not recommended to dispatch actions directly from this callback, as
   * this will lead to two dispatch events, one from the default
   * `FileUploadAction`, and another for the custom action.
   */
  onChange: (event: FileUploadChangeEvent) => FileUploadAction | null | undefined | void;
  /** Function to call when a drag event enters the FileUpload "pending" area */
  onDragEnter?: (event: React.DragEvent<HTMLDivElement>) => void;
  /** Function to call when a drag event leaves the FileUpload "pending" area */
  onDragLeave?: (event: React.DragEvent<HTMLDivElement>) => void;
  /** Function to call when a drag event drops one or more files in the
   * FileUpload "pending" area */
  onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
  /** Custom ref containing the DOM element that wraps the component, along
   * with the `dispatch` function used to update FileUpload state. */
  ref?: Ref<FileUploadRef>;
  /** Affects the width of the FileUpload component, either "fixed" or
   * "responsive"
   *
   * The standard fixed width is 550px. To change, it's recommended to supply
   * `className` and override width to the desired pixel size.
   */
  width?: FileUploadWidth;
}

interface FileUploadComponentProps extends FileUploadProps {
  forwardedRef?: Ref<FileUploadRef>;
}

/** Component used to upload files */
const FileUpload = ({
  acceptedFileTypes = [],
  behavior = 'simple',
  children,
  className,
  defaultFiles = fileUploadFilesDefaultValue,
  disabled = false,
  fileUploadLimit = 'unlimited',
  forwardedRef,
  id: upstreamId,
  onChange,
  onDragEnter,
  onDragLeave,
  onDrop,
  width = 'responsive',
  ...rest
}: FileUploadComponentProps): ReactElement => {
  const id = useMemo(() => upstreamId || nanoid(), [upstreamId]);
  const [files, dispatch] = useReducer(fileUploadReducer, defaultFiles);
  const { pending: pendingFiles, incomplete: incompleteFiles, uploaded: uploadedFiles } = files;
  const wrapperRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(forwardedRef, () => ({
    /* Type assertion should be safe, as useImperativeHandle is only called
     * after the component mounts, and the wrapper is never unmounted
     * independent of FileUpload.
     */
    wrapper: wrapperRef.current as HTMLDivElement,
    dispatch,
  }));

  /** Calls onChange to allow the application to modify the action before dispatch
   *
   * This is useful to change the upload percentage or apply metadata
   */
  const dispatchAfterOnChange = useCallback<typeof dispatch>(
    (action) => {
      const maybeNewAction = onChange?.(new FakeEvent({ value: { files, action }, id: id }));
      dispatch(maybeNewAction || action);
    },
    [id, onChange, files]
  );

  const totalNumberOfFiles = pendingFiles.length + incompleteFiles.length + uploadedFiles.length;

  return (
    <div {...classes({ modifiers: { [width]: true }, extra: className })} id={id} {...rest} ref={wrapperRef}>
      <FileUploadPending
        acceptedFileTypes={acceptedFileTypes}
        behavior={behavior}
        disabled={disabled}
        dispatch={dispatchAfterOnChange}
        totalNumberOfFiles={totalNumberOfFiles}
        files={pendingFiles}
        fileUploadLimit={fileUploadLimit}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      />
      <FileUploadIncomplete files={incompleteFiles} dispatch={dispatchAfterOnChange} />
      <FileUploadUploaded files={uploadedFiles} dispatch={dispatchAfterOnChange} />
      {children}
    </div>
  );
};

const fileUploadFilePropType: Validator<FileUploadFile> = PropTypes.shape({
  metaData: PropTypes.node,
  errorMessage: PropTypes.string,
  isCompleted: optionalBoolValidator,
  id: PropTypes.string.isRequired,
  ...(typeof File === 'undefined' ? {} : { fileData: PropTypes.instanceOf(File).isRequired }),
  percentComplete: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.oneOf<'indeterminate'>(['indeterminate']).isRequired,
  ]) as Validator<number | 'indeterminate' | undefined>,
  url: optionalStringValidator,
}).isRequired;

const fileUploadPropTypes: WeakValidationMap<FileUploadProps & { '...rest': unknown }> = {
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
  behavior: PropTypes.oneOf<FileUploadBehavior>(['simple', 'drag-and-drop']),
  children: PropTypes.node,
  className: PropTypes.string,
  defaultFiles: PropTypes.shape({
    pending: PropTypes.arrayOf(fileUploadFilePropType).isRequired,
    incomplete: PropTypes.arrayOf(fileUploadFilePropType).isRequired,
    uploaded: PropTypes.arrayOf(fileUploadFilePropType).isRequired,
  }),
  disabled: PropTypes.bool,
  fileUploadLimit: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.oneOf<'unlimited'>(['unlimited']).isRequired,
  ]),
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDrop: PropTypes.func,
  width: PropTypes.oneOf<FileUploadWidth>(['fixed', 'responsive']),
  /** Pass-through props spread onto the wrapper `<div>` */
  '...rest': PropTypes.any,
};

FileUpload.propTypes = fileUploadPropTypes;

export default forwardRefToProps(FileUpload);
