import { DataTableStore } from '../store/stateTypes';
import { classes } from '../utils/dataTableClasses';
import InlineAlert from '../../InlineAlert';

type ExpandedTableRowWrapperProps<RowData> = {
  store: DataTableStore<RowData>;
  rowId: number;
};

export function ExpandedTableRowWrapper<RowData>({ store, rowId }: ExpandedTableRowWrapperProps<RowData>): JSX.Element {
  const ExpandedTableRow = store.useSelector((s) => s.ExpandedTableRow);
  const isExpanded = store.useSelector((s) => s.rows[rowId].isExpanded);
  const rowStatus = store.useSelector((s) => s.rows[rowId].rowStatus);
  const columnIds = store.useSelector((s) => s.columnIds);

  // No Expanded Row
  if (!isExpanded || rowStatus === 'deleted') {
    return <></>;
  }

  // Display the default ExpandedTableRow if missing
  if (typeof ExpandedTableRow === 'undefined') {
    return (
      <tr {...classes({ element: 'tr', modifiers: 'expanded-bottom-row' })}>
        <td colSpan={columnIds.length}>
          <div className="fe_u_padding--small">
            {process.env.NODE_ENV !== 'production' && (
              <InlineAlert type="attention">Missing ExpandedTableRow prop</InlineAlert>
            )}
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr {...classes({ element: 'tr', modifiers: 'expanded-bottom-row' })}>
      <ExpandedTableRow
        rowId={rowId}
        useSelector={store.useSelector}
        useDispatch={store.useDispatch}
        useDispatchAsyncAction={store.useDispatchAsyncAction}
      />
    </tr>
  );
}
