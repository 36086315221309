import { ReactElement, ReactNode } from 'react';
import { ProfileContext } from '../../contexts';
import useProfile from '../../hooks/useProfile';
import useProfilePhoto from '../../hooks/useProfilePhoto';

type ProfileContextProviderProps = {
  children?: ReactNode;
};

/**
 * For best performance, context providers should wrap children in useMemo or
 * be refactored into a separate component: https://www.youtube.com/watch?v=CDGBTjMBJzg
 */
const ProfileContextProvider = ({ children }: ProfileContextProviderProps): ReactElement => {
  const profileData = useProfile();
  const profilePhotoUrl = useProfilePhoto(profileData);

  return <ProfileContext.Provider value={{ profileData, profilePhotoUrl }}>{children}</ProfileContext.Provider>;
};

export default ProfileContextProvider;
