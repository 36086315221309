import ModalBasic from './ModalBasic.gen';
import ModalWithDisabledClose from './ModalWithDisabledClose.gen';
import ModalAsAlert from './ModalAsAlert.gen';
import ComplexStyleModal from './ComplexStyleModal.gen';
import ModalWithCustomizedButtons from './ModalWithCustomizedButtons.gen';
import ComplexWithLeftPanel from './ComplexWithLeftPanel.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <ModalBasic />
      <ModalWithDisabledClose />
      <ModalAsAlert />
      <ComplexStyleModal />
      <ModalWithCustomizedButtons />
      <ComplexWithLeftPanel />
    </>
  );
}
