import MenuItem from '../../../MenuItem';
import Menu from '../../../Menu';
import Button from '../../../Button';
import DownloadSmall from '@athena/forge-icons/dist/DownloadSmall';
import DownloadLarge from '@athena/forge-icons/dist/DownloadLarge';
import dayjs from 'dayjs';
import { DataTableStore } from '../../store/stateTypes';
import { delay } from '../../utils/delay';
import { AwaitableModal } from '../../alerts/AwaitableModal';

/** Replace with csv-stringify/browser/esm when Forge is distributed as ESM */
import { stringify } from '../../../utils/third-party/csv-stringify/esm';

type ClientSideDownloadCsvButtonProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function ClientSideDownloadCsvButton<RowData>({
  store,
}: ClientSideDownloadCsvButtonProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();

  // ==================================
  // Table
  // ==================================
  const tableLayout = store.useSelector((s) => s.tableLayout);

  // ==================================
  // Rows
  // ==================================
  const isRowEditing = store.useSelector((s) => Object.values(s.rows).some((row) => row.rowStatus === 'edit'));
  const allRows = store.useSelector((s) => Object.values(s.rows).map((r) => r.originalData));
  const filteredRows = store.useSelector((s) => s.filteredAndSortedRowIds.map((rid) => s.rows[rid].originalData));
  const hasFilteredRows = allRows.length !== filteredRows.length;

  // ==================================
  // CSV
  // ==================================
  const DownloadIcon = { medium: DownloadLarge, compact: DownloadSmall }[tableLayout];
  const csvDownload = store.useSelector((s) => s.csvDownload);
  const csvDownloadFileName = `${csvDownload.downloadFilePrefix}-${dayjs().format('YYYYMMDD')}.csv`;
  const csvColumns = store.useSelector((s) => {
    return s.columnIds.map((cid) => s.columns[cid].clientSideCsvDownload).filter((csv) => csv.enable);
  });

  // ==================================
  // Download CSV By Row Data
  // ==================================
  const downloadCsvByRowData = async (rowData: RowData[]): Promise<void> => {
    dispatch(function show_loader(s) {
      s.loader.show = true;
    });
    await delay(10);
    let a;

    try {
      // Generate CSV String

      // table header (the second line)
      const csvArray = [csvColumns.map((col) => col.headerText)];

      // table body (the rest of the lines)
      for (const row of rowData) {
        csvArray.push(
          csvColumns.map((col) => {
            return col.getTextByRow(row);
          })
        );
      }
      const csvString = await generateCsvString(csvArray);

      a = document.createElement('a'); // create a fake <a/> in document.body
      a.href = 'data:application/octet-stream,' + encodeURIComponent(csvString); // https://stackoverflow.com/a/4551467
      a.download = csvDownloadFileName; // download file name
      document.body.appendChild(a);
      a.click(); // click the <a/> to download and remove the <a/>
    } catch (e) {
      await AwaitableModal(dispatch, {
        alertType: 'attention',
        disableClose: true,
        headerText: 'Generate CSV Failed',
        children: (e as Error).message,
      });
    } finally {
      if (a) {
        a.remove();
      }
    }

    dispatch(function hide_loader(s) {
      s.loader.show = false;
    });
  };

  // CSV Download is disabled
  if (!csvDownload.showButton) {
    return <></>;
  }

  // Display button if no filter applied
  if (!hasFilteredRows) {
    return (
      <Button
        text={csvDownload.buttonText}
        icon={DownloadIcon}
        variant="tertiary"
        disabled={isRowEditing}
        onClick={() => downloadCsvByRowData(allRows)}
      />
    );
  }

  // Display Menu with "Filtered Data" and "All Data" option if filters applied
  return (
    <Menu
      hideLabel
      label={csvDownload.buttonText ?? ''} // Has a non-zero length string assigned. (Default: 'Download CSV')
      trigger={
        <Button
          icon={DownloadIcon} // prettier-break-line
          text={csvDownload.buttonText}
          variant="tertiary"
          disabled={isRowEditing}
        />
      }
    >
      <MenuItem>
        <Button
          text="Filtered Data"
          variant="tertiary"
          disabled={isRowEditing}
          onClick={() => downloadCsvByRowData(filteredRows)}
        />
      </MenuItem>
      <MenuItem>
        <Button
          text="All Data"
          variant="tertiary"
          disabled={isRowEditing}
          onClick={() => downloadCsvByRowData(allRows)}
        />
      </MenuItem>
    </Menu>
  );
}

// ====================================
// Utils
// ====================================

/** Call the stringify and generate the CSV file contents. */
async function generateCsvString(csvData: string[][]): Promise<string> {
  return new Promise((resolve, reject) => {
    stringify(csvData, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}
