import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { callMsGraphFile } from '../msGraph';
import { loginRequest } from './../authConfig';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { ProfileData } from './useProfile';

export default function useProfilePhoto(userDetails: ProfileData | undefined): string {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [photoUrl, setPhotoUrl] = useState<string>('');
  /** Need to keep track of photoUrl twice in order to prevent an infinite useEffect loop */
  const photoUrlRef = useRef<string | null>(null);
  const authenticationEnabled = process.env.GATSBY_FEATURE_FLAG_AUTHENTICATION === 'true';
  const isBrowser = useCallback((): boolean => typeof window !== 'undefined', []);

  const URL = useMemo(() => (isBrowser() ? window.URL || window.webkitURL : undefined), [isBrowser]);

  /** Remove object reference so the browser knows not to keep the reference any longer */
  const clearObjectUrl = useCallback(
    (oldUrl: string | null = photoUrlRef.current): void => {
      if (oldUrl) {
        URL?.revokeObjectURL(oldUrl);
      }
      photoUrlRef.current = null;
    },
    [URL]
  );

  useEffect(() => {
    if (authenticationEnabled) {
      if (!userDetails) {
        clearObjectUrl();
        setPhotoUrl('');
      } else if (account && inProgress === InteractionStatus.None && URL && photoUrlRef.current !== photoUrl) {
        (async () => {
          const request = {
            ...loginRequest,
            account: account,
          };
          try {
            const tokenResponse = await instance.acquireTokenSilent(request);
            const { userPrincipalName } = userDetails;
            const photoDetails = await callMsGraphFile(
              tokenResponse.accessToken,
              `/users/${userPrincipalName}/photo/$value`
            );
            if (photoDetails) {
              // This will create an in-memory URL reference to a photo or file
              const newPhotoUrl = URL.createObjectURL(photoDetails);
              clearObjectUrl();
              photoUrlRef.current = newPhotoUrl;
              setPhotoUrl(newPhotoUrl);
            }
          } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(request);
            }

            console.error(e);
          }
        })();
      }
    }

    return () => {
      if (photoUrl && URL && photoUrl !== photoUrlRef.current) {
        clearObjectUrl(photoUrl);
      }
    };
  }, [URL, account, authenticationEnabled, clearObjectUrl, inProgress, instance, photoUrl, userDetails]);

  return photoUrl;
}
