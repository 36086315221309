import AttentionLarge from '@athena/forge-icons/dist/AttentionLarge';
import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import InfoLarge from '@athena/forge-icons/dist/InfoLarge';
import NewLarge from '@athena/forge-icons/dist/NewLarge';
import SuccessLarge from '@athena/forge-icons/dist/SuccessLarge';
import React, { Fragment, ReactElement, Ref, useEffect } from 'react';
import Button from '../Button';
import Heading from '../Heading';
import ScreenReaderAnnounce, { ScreenReaderAnnounceProps } from '../ScreenReaderAnnounce';
import ScreenReaderText from '../ScreenReaderText';
import { ToastAction, ToastAlertTypes } from '../Toast/Toast';
import { forgeClassHelper } from '../utils/classes';

export interface ToastTemplateProps extends ScreenReaderAnnounceProps {
  action?: ToastAction;
  alertType: ToastAlertTypes;
  headerText: string;
  onDismiss?: () => void;
  children?: React.ReactNode;
}

interface ToastTemplateComponentProps extends ToastTemplateProps {
  forwardedRef: Ref<HTMLDivElement>;
}

const classes = forgeClassHelper({ name: 'toast' });

interface IconSelectorProps {
  alertType: ToastAlertTypes;
}
const IconSelector = ({ alertType }: IconSelectorProps): ReactElement => {
  switch (alertType) {
    case 'attention':
      return <AttentionLarge />;
    case 'info':
      return <InfoLarge />;
    case 'new':
      return <NewLarge />;
    case 'success':
      return <SuccessLarge />;
  }
};

function ToastTemplateComponent({
  action,
  alertType,
  children,
  className,
  forwardedRef,
  headerText,
  onDismiss,
  ...passthroughProps
}: ToastTemplateComponentProps): ReactElement {
  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent): void {
      if (action && event.ctrlKey && event.shiftKey && event.key === '!') {
        action.onAction();
        event.preventDefault();
      }
    }

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [action]);

  return (
    <ScreenReaderAnnounce
      {...classes({
        extra: className,
        modifiers: [alertType],
      })}
      ref={forwardedRef}
      {...passthroughProps}
    >
      <div {...classes({ element: 'wrapper' })}>
        <div {...classes({ element: 'icon' })}>
          <IconSelector alertType={alertType} />
        </div>
        <div
          {...classes({ element: 'content', modifiers: { 'with-action': action !== undefined } })}
          title={headerText}
        >
          <div {...classes({ element: 'heading-container' })}>
            <Heading {...classes({ element: 'header' })} text={headerText} variant="subsection" />
            {!action && (
              <Button
                aria-label={'Close Toast'}
                onClick={onDismiss}
                tabIndex={-1}
                variant="tertiary"
                size="small"
                icon={CloseSmall}
              />
            )}
          </div>
          {children}
        </div>
        {action && (
          <div {...classes({ element: 'actions' })}>
            <Fragment>
              <button
                {...classes({ element: 'dismiss-button' })}
                onClick={onDismiss}
                tabIndex={-1}
                aria-hidden
                type="button"
              >
                Dismiss
              </button>
              <button
                {...classes({ element: 'action-button' })}
                onClick={action.onAction}
                tabIndex={-1}
                aria-hidden
                type="button"
              >
                {action.label}
              </button>
              <ScreenReaderText>Press Ctrl Shift 1 to {action.labelForScreenReader || action.label}</ScreenReaderText>
            </Fragment>
          </div>
        )}
      </div>
    </ScreenReaderAnnounce>
  );
}

function ToastTemplateController(props: ToastTemplateProps, ref: Ref<HTMLDivElement>): ReactElement {
  return <ToastTemplateComponent {...props} forwardedRef={ref} />;
}

ToastTemplateController.displayName = 'ToastTemplate';

export default React.forwardRef(ToastTemplateController);
