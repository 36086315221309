import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHeartFilledLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 6.61084L14.137 4.49084C15.1178 3.53426 16.4328 2.99902 17.8019 2.99902C19.171 2.99902 20.486 3.53426 21.4667 4.49084C21.9515 4.95741 22.3372 5.51725 22.6007 6.13672C22.8641 6.75619 23 7.42251 23 8.09583C23 8.76914 22.8641 9.43558 22.6007 10.0551C22.3372 10.6745 21.9515 11.2342 21.4667 11.7008L12 21.0009L2.53326 11.7008C2.04848 11.2342 1.6628 10.6745 1.39934 10.0551C1.13589 9.43558 1 8.76914 1 8.09583C1 7.42251 1.13589 6.75619 1.39934 6.13672C1.6628 5.51725 2.04848 4.95741 2.53326 4.49084C3.51404 3.53426 4.82896 2.99902 6.19805 2.99902C7.56715 2.99902 8.88219 3.53426 9.86297 4.49084L12 6.61084Z"
      fill={props.color}
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgHeartFilledLarge.displayName = 'SvgHeartFilledLarge';
const HeartFilledLarge = withForgeIconProps(SvgHeartFilledLarge, {
  name: 'HeartFilled',
  usage: 'Use to indicate a liked item or to unlike an item',
  related: 'star, love, heart, favorite, like',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default HeartFilledLarge;
