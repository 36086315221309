import React, { ReactElement, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper({ name: 'screen-reader-text' });

export interface ScreenReaderTextProps extends React.HTMLAttributes<HTMLDivElement> {
  /** The label for ScreenReader */
  children: React.ReactNode;

  /** Adds a class to the root element of the component */
  className?: string;
}

function ScreenReaderText({ className, children, ...rest }: ScreenReaderTextProps): ReactElement {
  return (
    <div {...classes({ extra: className })} {...rest}>
      {children}
    </div>
  );
}

export const screenReaderTextPropTypes: WeakValidationMap<ScreenReaderTextProps & { '...rest': unknown }> = {
  /** The label for ScreenReader */
  children: PropTypes.node.isRequired,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Props passed through to the root element of the component */
  '...rest': PropTypes.any,
};

ScreenReaderText.propTypes = screenReaderTextPropTypes;

export default ScreenReaderText;
