import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBoldLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2.5 24V0H11.2176C12.4843 0 13.6765 0.0984617 14.7941 0.295385C15.9118 0.467692 16.8804 0.775384 17.7 1.21846C18.5196 1.66154 19.1654 2.27692 19.6373 3.06462C20.134 3.82769 20.3824 4.8 20.3824 5.98154C20.3824 6.49846 20.3078 7.02769 20.1588 7.56923C20.0098 8.08615 19.7987 8.59077 19.5255 9.08308C19.2771 9.55077 18.9667 9.96923 18.5941 10.3385C18.2216 10.7077 17.7993 10.9785 17.3275 11.1508V11.2985C18.4948 11.6431 19.4758 12.2708 20.2706 13.1815C21.0902 14.0677 21.5 15.3108 21.5 16.9108C21.5 18.1415 21.2392 19.2123 20.7176 20.1231C20.2209 21.0092 19.5379 21.7477 18.6686 22.3385C17.8242 22.9046 16.8183 23.3231 15.651 23.5938C14.4837 23.8646 13.2542 24 11.9627 24H2.5ZM8.90784 9.37846H11.0686C12.1118 9.37846 12.8817 9.15692 13.3784 8.71385C13.8752 8.27077 14.1235 7.68 14.1235 6.94154C14.1235 6.20308 13.8752 5.67385 13.3784 5.35385C12.8817 5.03385 12.1118 4.87385 11.0686 4.87385H8.90784V9.37846ZM8.90784 19.1262H11.5157C13.9993 19.1262 15.2412 18.24 15.2412 16.4677C15.2412 15.5815 14.9307 14.9538 14.3098 14.5846C13.7137 14.2154 12.7824 14.0308 11.5157 14.0308H8.90784V19.1262Z"
      fill={props.color}
    />
  </svg>
);
SvgBoldLarge.displayName = 'SvgBoldLarge';
const BoldLarge = withForgeIconProps(SvgBoldLarge, {
  name: 'Bold',
  usage:
    'Use in a ComboButton or SegmentedButton to add or remove bold text formatting',
  related: 'edit, font, emphasis, strong, italic, underline, text editor',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default BoldLarge;
