import rawContents from "!!raw-loader!./SelectStandalone.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function SelectStandalone():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Select Standalone" />;
}

export default SelectStandalone;
