import { useEffect } from 'react';
import { DataTableDispatch } from '../store/stateTypes';
import { DataTableProps } from '../DataTable';

type PaginationPropReceiverProps<RowData> = {
  dispatch: DataTableDispatch<RowData>;
  pagination: DataTableProps<RowData>['pagination'];
};

export function PaginationPropReceiver<RowData>({
  dispatch, // prettier-break-line
  pagination,
}: PaginationPropReceiverProps<RowData>): JSX.Element {
  useEffect(() => {
    dispatch(function update_pagination(s) {
      s.pagination = { ...s.pagination, ...pagination };
      s.serverSideFetchRowDataArgs.pageSize = s.pagination.pageSize;
      s.serverSideFetchRowDataArgs.pageIndex = s.pagination.pageIndex;
    });
  }, [dispatch, pagination]);

  return <></>;
}
