import rawContents from "!!raw-loader!./RequiredVariations.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function RequiredVariations():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Required Variations" />;
}

export default RequiredVariations;
