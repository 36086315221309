import './styles/app.scss';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from './authConfig';
import ProfileContextProvider from './components/profile-context-provider';
import VersionContextProvider from './components/version-context-provider';
import { ReactElement } from 'react';

const msalInstance = new PublicClientApplication(msalConfig);

export interface RootWrapperProps {
  element: ReactElement;
}
export default function RootWrapper({ element }: RootWrapperProps): ReactElement {
  return (
    <MsalProvider instance={msalInstance}>
      <ProfileContextProvider>
        <VersionContextProvider>{element}</VersionContextProvider>
      </ProfileContextProvider>
    </MsalProvider>
  );
}
