import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgListBulletedSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15 3.5H5M15 12.5H5M15 8H5"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M1 4.5C1.55228 4.5 2 4.05228 2 3.5C2 2.94772 1.55228 2.5 1 2.5C0.447715 2.5 0 2.94772 0 3.5C0 4.05228 0.447715 4.5 1 4.5Z"
      fill={props.color}
    />
    <path
      d="M1 9C1.55228 9 2 8.55228 2 8C2 7.44772 1.55228 7 1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9Z"
      fill={props.color}
    />
    <path
      d="M1 13.5C1.55228 13.5 2 13.0523 2 12.5C2 11.9477 1.55228 11.5 1 11.5C0.447715 11.5 0 11.9477 0 12.5C0 13.0523 0.447715 13.5 1 13.5Z"
      fill={props.color}
    />
  </svg>
);
SvgListBulletedSmall.displayName = 'SvgListBulletedSmall';
const ListBulletedSmall = withForgeIconProps(SvgListBulletedSmall, {
  name: 'ListBulleted',
  usage:
    'Use to indicate a list, or the ability to switch to a list view or add list formatting',
  related: 'table, grid, bullet, text editor',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ListBulletedSmall;
