import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHeartEmptyLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 6.61108L14.137 4.49097C15.1178 3.53439 16.4328 2.99902 17.8019 2.99902C19.171 2.99902 20.486 3.53439 21.4667 4.49097C21.9515 4.95753 22.3372 5.51725 22.6007 6.13672C22.8641 6.75619 23 7.42263 23 8.09595C23 8.76926 22.8641 9.4357 22.6007 10.0552C22.3372 10.6746 21.9515 11.2344 21.4667 11.7009L12 21.001L2.53326 11.7009C2.04848 11.2344 1.6628 10.6746 1.39934 10.0552C1.13589 9.4357 1 8.76926 1 8.09595C1 7.42263 1.13589 6.75619 1.39934 6.13672C1.6628 5.51725 2.04848 4.95753 2.53326 4.49097C3.51404 3.53439 4.82896 2.99902 6.19805 2.99902C7.56715 2.99902 8.88219 3.53439 9.86297 4.49097L12 6.61108Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgHeartEmptyLarge.displayName = 'SvgHeartEmptyLarge';
const HeartEmptyLarge = withForgeIconProps(SvgHeartEmptyLarge, {
  name: 'HeartEmpty',
  usage: 'Use to like an item',
  related: 'star, love, heart, favorite, unlike',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default HeartEmptyLarge;
