import CalendarLarge from '@athena/forge-icons/dist/CalendarLarge';
import TeamLarge from '@athena/forge-icons/dist/TeamLarge';

import rawContents from "!!raw-loader!./SegmentedButtonIconsInAnchorElement.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { CalendarLarge, TeamLarge };

function SegmentedButtonIconsInAnchorElement():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Segmented Button Icons In Anchor Element" />;
}

export default SegmentedButtonIconsInAnchorElement;
