/**
 * A refactor of react-block-ui: a library we used until it was abandoned.
 */
import React, {
  KeyboardEvent,
  useEffect,
  useState,
  useRef,
  useCallback,
  CSSProperties,
  Ref,
  ReactElement,
} from 'react';
import forwardRefToProps from '../utils/forwardRefToProps';

export interface BlockUIProps {
  loader: React.ReactNode;
  blocking?: boolean;
  className?: string;
  children?: React.ReactNode;
  keepInView?: boolean;
  style?: CSSProperties;
  /** prop not utilized in Loader */
  message?: string | React.ReactNode;
  /** Ref representing the DOM element wrapping the component  */
  ref?: Ref<HTMLDivElement>;
  /** prop not utilized in Loader */
  renderChildren?: boolean;
}

export interface BlockUIComponentProps extends BlockUIProps {
  forwardedRef?: Ref<HTMLDivElement>;
}

interface PrevPropsRef {
  blocking?: boolean;
  shouldKeepInView?: boolean;
}

function safeActiveElement(doc?: Document): Element | null {
  doc = doc || document;
  let activeElement: Element | null = null;

  try {
    activeElement = document.activeElement;
    if (!activeElement || !activeElement.nodeName) {
      activeElement = doc.body;
    }
  } catch (error) {
    activeElement = doc.body;
  }

  return activeElement;
}

const BlockUI = ({
  blocking,
  className,
  children,
  forwardedRef,
  message,
  loader: Loader,
  renderChildren = true,
  keepInView: shouldKeepInView,
  ...attributes
}: BlockUIComponentProps): ReactElement => {
  const [topState, setTopState] = useState<string | number>('50%');
  const helperRef = useRef<HTMLElement | null>(null);
  const blockerRef = useRef<HTMLDivElement | null>(null);
  const topFocusRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLElement | null>(null);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);
  const focusedRef = useRef<Element | null>(null);
  const prevPropsRef = useRef<PrevPropsRef | null>(null);
  const isFirstRunRef = useRef<boolean>(true);

  const keepInView = useCallback(() => {
    if (blocking && shouldKeepInView && containerRef.current !== null) {
      const containerBounds = containerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (containerBounds.top > windowHeight || containerBounds.bottom < 0) return;

      if (containerBounds.top >= 0 && containerBounds.bottom <= windowHeight) {
        if (topState !== '50%') {
          setTopState('50%');
        }
        return;
      }

      const messageBoundsHeight = messageContainerRef.current
        ? messageContainerRef.current.getBoundingClientRect().height
        : 0;

      let top =
        Math.max(
          Math.min(windowHeight, containerBounds.bottom) - Math.max(containerBounds.top, 0),
          messageBoundsHeight
        ) / 2;

      if (containerBounds.top < 0) {
        top = Math.min(top - containerBounds.top, containerBounds.height - messageBoundsHeight / 2);
      }
      if (topState !== top) {
        setTopState(top);
      }
    }
  }, [shouldKeepInView, topState, blocking]);

  const handleBlocking = useCallback((): void => {
    if (blocking !== prevPropsRef.current?.blocking) {
      if (blocking) {
        // blocking started
        if (helperRef && helperRef.current?.parentNode && helperRef.current.parentNode.contains(safeActiveElement())) {
          focusedRef.current = safeActiveElement();
          // https://www.tjvantoll.com/2013/08/30/bugs-with-document-activeelement-in-internet-explorer/#blurring-the-body-switches-windows-in-ie9-and-ie10
          if (focusedRef.current && focusedRef.current !== document.body) {
            (window.setImmediate || setTimeout)(
              () =>
                focusedRef.current instanceof HTMLElement &&
                typeof focusedRef.current.blur === 'function' &&
                focusedRef.current.blur()
            );
          }
        }
      } else {
        const ae = safeActiveElement();
        if (focusedRef.current && (!ae || ae === document.body || ae === topFocusRef.current)) {
          if (focusedRef.current instanceof HTMLElement && typeof focusedRef.current.focus === 'function') {
            focusedRef.current.focus();
          }
          focusedRef.current = null;
        }
      }
    }
  }, [blocking]);

  const handleKeepInView = useCallback((): (() => void) => {
    if (
      shouldKeepInView &&
      (shouldKeepInView !== prevPropsRef.current?.shouldKeepInView ||
        (blocking && blocking !== prevPropsRef.current?.blocking))
    ) {
      // Only update position if props have just changed.
      keepInView();
    }
    if (shouldKeepInView || blocking) {
      window.addEventListener('scroll', keepInView);
    }
    return () => window.removeEventListener('scroll', keepInView);
  }, [blocking, shouldKeepInView, keepInView]);

  useEffect(() => {
    if (isFirstRunRef.current) {
      isFirstRunRef.current = false;
      return;
    }

    handleBlocking();
    return handleKeepInView();
  }, [handleBlocking, handleKeepInView]);

  //Store the prev prop values for comparison in subsequent renders
  useEffect(() => {
    prevPropsRef.current = { blocking, shouldKeepInView };
  }, [blocking, shouldKeepInView]);

  const setContainerRef = (ref: HTMLElement | null): void => {
    containerRef.current = ref;
    if (ref) {
      keepInView();
    }
  };

  const blockingTab = (e: KeyboardEvent<HTMLDivElement>, withShift = false): boolean | undefined => {
    // eslint-disable-next-line eqeqeq
    return blocking && e.key === 'Tab' && e.shiftKey == withShift;
  };

  const tabbedUpTop = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (blockingTab(e)) {
      blockerRef.current?.focus();
    }
  };

  const tabbedDownTop = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (blockingTab(e)) {
      e.preventDefault();
      blockerRef.current?.focus();
    }
  };

  const tabbedUpBottom = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (blockingTab(e, true)) {
      topFocusRef.current?.focus();
    }
  };

  const tabbedDownBottom = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (blockingTab(e, true)) {
      e.preventDefault();
      topFocusRef.current?.focus();
    }
  };

  const classes = blocking ? `block-ui ${className}` : className;
  const shouldRenderChildren = !blocking || renderChildren;

  return (
    <div ref={forwardedRef} {...attributes} className={classes}>
      {blocking && <div tabIndex={0} onKeyUp={tabbedUpTop} onKeyDown={tabbedDownTop} ref={topFocusRef} />}
      {shouldRenderChildren && children}
      {blocking && (
        <div
          className="block-ui-container"
          tabIndex={0}
          ref={blockerRef}
          onKeyUp={tabbedUpBottom}
          onKeyDown={tabbedDownBottom}
        >
          <div className="block-ui-overlay" ref={setContainerRef} />
          <div
            className="block-ui-message-container"
            ref={messageContainerRef}
            style={{ top: shouldKeepInView ? topState : undefined }}
          >
            <div className="block-ui-message">
              {message}
              {React.isValidElement(Loader) && Loader}
            </div>
          </div>
        </div>
      )}
      <span ref={helperRef} />
    </div>
  );
};

BlockUI.displayName = 'BlockUI';

export default forwardRefToProps(BlockUI);
