import Button from '../../Button';
import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import { DataTableStore } from '../store/stateTypes';

// Actions
import { reset_paginator } from '../store/actions/reset_paginator';
import { reset_quick_filters } from '../store/actions/reset_quick_filters';
import { reset_data_filters } from '../store/actions/reset_date_filters';
import { reset_column_filters } from '../store/actions/reset_column_filters';
import { apply_client_side_filters } from '../store/actions/apply_client_side_filters';
import { apply_server_side_filters } from '../store/actions/apply_server_side_filters';
import { hasRowsInEditStatusFromState } from '../utils/hasRowsInEditStatusFromState';
import { hasAppliedColumnFiltersFromState } from '../utils/hasAppliedColumnFiltersFromState';

type ClearAllColumnFiltersButtonProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function ClearAllColumnFiltersButton<RowData>({
  store,
}: ClearAllColumnFiltersButtonProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));
  const hasAppliedColumnFilters = store.useSelector((s) => hasAppliedColumnFiltersFromState(s));

  if (!hasAppliedColumnFilters) {
    return <></>;
  }

  return (
    <Button
      text="Clear all filters"
      variant="tertiary"
      disabled={hasRowsInEditStatus}
      onClick={() =>
        dispatch(function user_click_clear_all_column_filters_button(s) {
          reset_paginator(s);
          reset_quick_filters(s); // quick filters should have been reset already
          reset_data_filters(s); // data filters should have been reset already
          reset_column_filters(s);
          apply_client_side_filters(s);
          apply_server_side_filters(s);
        })
      }
    >
      <CloseSmall size="10px" title="Clear all filters" />
    </Button>
  );
}
