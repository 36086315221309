import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPrintSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 13.0007C12.7956 13.0007 13.5587 12.6847 14.1213 12.1221C14.6839 11.5595 15 10.7964 15 10.0007V4.18066H1V13.0007H4M4.5 3.86084V1.00073H11.5V3.86084M4.5 9.91064H11.5V15.0007H4.5V9.91064Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgPrintSmall.displayName = 'SvgPrintSmall';
const PrintSmall = withForgeIconProps(SvgPrintSmall, {
  name: 'Print',
  usage: 'Use to print',
  related: 'export',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default PrintSmall;
