import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMobileSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4 10H12M3.5 1H12.5V12C12.5 12.7956 12.1839 13.5587 11.6213 14.1213C11.0587 14.6839 10.2956 15 9.5 15H3.5V1Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M8 13.4999C8.19778 13.4999 8.39109 13.4414 8.55554 13.3315C8.71999 13.2216 8.84814 13.0652 8.92383 12.8825C8.99952 12.6998 9.01942 12.4988 8.98083 12.3049C8.94225 12.1109 8.84701 11.9328 8.70715 11.7929C8.5673 11.653 8.38905 11.5578 8.19507 11.5192C8.00109 11.4806 7.80004 11.5004 7.61731 11.5761C7.43458 11.6518 7.27846 11.7798 7.16858 11.9443C7.0587 12.1087 7 12.3022 7 12.4999C7 12.7651 7.10531 13.0194 7.29285 13.207C7.48038 13.3945 7.73478 13.4999 8 13.4999Z"
      fill={props.color}
    />
  </svg>
);
SvgMobileSmall.displayName = 'SvgMobileSmall';
const MobileSmall = withForgeIconProps(SvgMobileSmall, {
  name: 'Mobile',
  usage:
    'Use to indicate a mobile number or device, or the ability to send an SMS',
  related: 'cell, iphone, android, ios, phone, call, text',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default MobileSmall;
