import * as DR from './DateRangeInputTypes';
import { DateRangeInputHandlerProps } from './DateRangeInputHandlers';

/** Can the passed parameter be a valid Date? */
export const isDate = (date: string | Date): boolean => {
  return date instanceof Date ? true : !isNaN(Date.parse(date));
};

/* Format dates to a presentable format. */
export const dateformat = Intl.DateTimeFormat('en-us', {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
});

/** Return a new date whose time component has been set to midnight.
 *  From DateInput.
 */
export const truncateToMidnight = (date: Date | undefined): Date | undefined => {
  if (date) {
    const newDate = new Date(date);
    newDate.setHours(0.0, 0.0, 0.0, 0.0);
    return newDate;
  } else {
    return date;
  }
};

/** Helper function to create a new DR.DateRangeInputValues object for the current values. */
export const getNewValues = (
  args: DateRangeInputHandlerProps,
  id: string,
  newValue: Date | boolean | undefined
): DR.DateRangeInputValues => {
  const { allValues, ids } = args;

  if (id === ids[0]) {
    return { start: newValue instanceof Date ? newValue : undefined, end: allValues.end, checked: allValues.checked };
  } else if (id === ids[1]) {
    return { start: allValues.start, end: newValue instanceof Date ? newValue : undefined, checked: allValues.checked };
  } else {
    return {
      start: allValues.start,
      end: allValues.end,
      checked: typeof newValue === 'boolean' ? newValue : undefined,
    };
  }
};

/**
 * const compareDatesEqual = (leftDate: Date, rightDate: Date): boolean
 *
 * Compare two Date objects to see if they have the same date. Time of day is ignored.
 *
 * @param leftDate
 * @param rightDate
 * @returns boolean TRUE if leftDate === rightDate
 */
export const compareDatesEqual = (leftDate: Date, rightDate: Date): boolean => {
  return (
    leftDate.getUTCFullYear() === rightDate.getUTCFullYear() &&
    leftDate.getUTCMonth() === rightDate.getUTCMonth() &&
    // Please note that Date.getUTCDate() returns the day of the month.  It is not named well.
    leftDate.getUTCDate() === rightDate.getUTCDate()
  );
};

/**
 * const compareDatesGreaterThan = (leftDate: Date, rightDate: Date): boolean
 *
 * Compare two Date objects to see if the first date is greater than the second date. Time of day is ignored.
 *
 * @param leftDate
 * @param rightDate
 * @returns boolean TRUE if leftDate > rightDate
 */
export const compareDatesGreaterThan = (leftDate: Date, rightDate: Date): boolean => {
  const leftYear = leftDate.getUTCFullYear();
  const leftMonth = leftDate.getUTCMonth();
  const rightYear = rightDate.getUTCFullYear();
  const rightMonth = rightDate.getUTCMonth();

  return (
    leftYear > rightYear ||
    (leftYear === rightYear &&
      // Please note that Date.getUTCDate() returns the day of the month.  It is not named well.
      (leftMonth > rightMonth || (leftMonth === rightMonth && leftDate.getUTCDate() > rightDate.getUTCDate())))
  );
};

/**
 * const compareDatesNotEqual = (leftDate: Date, rightDate: Date): boolean
 *
 * Compare two Date objects to see if they have a different date. Time of day is ignored.
 *
 * @param leftDate
 * @param rightDate
 * @returns boolean TRUE if leftDate !== rightDate
 */
export const compareDatesNotEqual = (leftDate: Date, rightDate: Date): boolean => {
  return !compareDatesEqual(leftDate, rightDate);
};

/**
 * const compareDatesGreaterThanEqual = (leftDate: Date, rightDate: Date): boolean
 *
 * Compare two Date objects to see if the first date is greater than or equal to the second date. Time of day is ignored.
 *
 * @param leftDate
 * @param rightDate
 * @returns boolean TRUE if leftDate >== rightDate
 */
export const compareDatesGreaterThanEqual = (leftDate: Date, rightDate: Date): boolean => {
  return compareDatesEqual(leftDate, rightDate) || compareDatesGreaterThan(leftDate, rightDate);
};

/**
 * const compareDatesLessThan = (leftDate: Date, rightDate: Date): boolean
 *
 * Compare two Date objects to see if the first date is less than the second date. Time of day is ignored.
 *
 * @param leftDate
 * @param rightDate
 * @returns boolean TRUE if leftDate < rightDate
 */
export const compareDatesLessThan = (leftDate: Date, rightDate: Date): boolean => {
  return !compareDatesGreaterThanEqual(leftDate, rightDate);
};

/**
 * const compareDatesLessThanEqual = (leftDate: Date, rightDate: Date): boolean
 *
 * Compare two Date objects to see if the first date is less than or equal to the second date. Time of day is ignored.
 *
 * @param leftDate
 * @param rightDate
 * @returns boolean TRUE if leftDate <== rightDate
 */
export const compareDatesLessThanEqual = (leftDate: Date, rightDate: Date): boolean => {
  return !compareDatesGreaterThan(leftDate, rightDate);
};
