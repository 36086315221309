import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDragLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 24C9.10457 24 10 23.1046 10 22C10 20.8954 9.10457 20 8 20C6.89543 20 6 20.8954 6 22C6 23.1046 6.89543 24 8 24Z"
      fill={props.color}
    />
    <path
      d="M8 14C9.10457 14 10 13.1046 10 12C10 10.8954 9.10457 10 8 10C6.89543 10 6 10.8954 6 12C6 13.1046 6.89543 14 8 14Z"
      fill={props.color}
    />
    <path
      d="M8 4C9.10457 4 10 3.10457 10 2C10 0.89543 9.10457 0 8 0C6.89543 0 6 0.89543 6 2C6 3.10457 6.89543 4 8 4Z"
      fill={props.color}
    />
    <path
      d="M16 24C17.1046 24 18 23.1046 18 22C18 20.8954 17.1046 20 16 20C14.8954 20 14 20.8954 14 22C14 23.1046 14.8954 24 16 24Z"
      fill={props.color}
    />
    <path
      d="M16 14C17.1046 14 18 13.1046 18 12C18 10.8954 17.1046 10 16 10C14.8954 10 14 10.8954 14 12C14 13.1046 14.8954 14 16 14Z"
      fill={props.color}
    />
    <path
      d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z"
      fill={props.color}
    />
  </svg>
);
SvgDragLarge.displayName = 'SvgDragLarge';
const DragLarge = withForgeIconProps(SvgDragLarge, {
  name: 'Drag',
  usage: 'Use to indicate an item that can be moved by dragging',
  related: 'move, reorder, list',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default DragLarge;
