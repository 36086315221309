import rawContents from "!!raw-loader!./Headings.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function Headings():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Headings" />;
}

export default Headings;
