import PropTypes from 'prop-types';
import React, { FocusEventHandler, ReactElement, useEffect, WeakValidationMap } from 'react';

import { DateRangeInputComponentProps } from './DateRangeInputTypes';
import DateInput from '../DateInput';
import { FormContext, FormContextData } from '../Form/FormContext';
import addYears from 'date-fns/addYears';
import getYear from 'date-fns/getYear';
import subYears from 'date-fns/subYears';

import { forgeClassHelper } from '../utils/classes';
import { AttentionBanners, AttentionBannersProps } from './DateRangeInputBannerAccessory';
import useAppLogic from './DateRangeInputAppLogic';
import { NullValueCheckbox, DescriptionLabel, RangeLabel, RangeLabelProps } from './DateRangeInputComponents';
import { optionalBoolValidator, optionalDateValidator, optionalStringValidator } from '../utils/betterPropTypes';

const classes = forgeClassHelper('date-range-input');

const getDateFiveYearsBeforeJan1 = (): Date => {
  return subYears(new Date(getYear(new Date()), 0, 1), 5);
};
const getDateFiveYearsAfterDec31 = (): Date => {
  return addYears(new Date(getYear(new Date()), 11, 31), 5);
};

/** Component for selecting a date range. */
export const DateRangeInput = ({
  allowNullValuesCheckBox = false,
  className,
  disabled = false,
  error,
  errorAlwaysBelow = false,
  excludeStartDates,
  excludeEndDates,
  filterStartDate,
  filterEndDate,
  id,
  includeStartDates,
  includeEndDates,
  labelAlwaysAbove = false,
  earliestLatestDates = {
    earliest: getDateFiveYearsBeforeJan1(),
    latest: getDateFiveYearsAfterDec31(),
  },
  startEndDisabledDates = { start: false, end: false },
  startEndRequiredDates = { start: false, end: false },
  onBlurAllValues,
  onChangeAllValues,
  onFirstDateBlur,
  onSecondDateBlur,
  openToDates = {},
  placeholderText = {},
  rangeLabel = '',
  rangeDescription = '',
  refStartDate,
  refEndDate,
  required = false,
  standalone = false,
  showItemsWithNoDateDescription = 'Show items with no date',
  suppressVerticalSpacing = false,
  suppressWarningBanner = false,
  validateStartDate,
  validateEndDate,
  values = {},
}: DateRangeInputComponentProps): ReactElement => {
  const {
    bannerCount,
    disabledFirstDate,
    disabledSecondDate,
    firstDate,
    firstDateCustomValidationMsg,
    firstDateErrorMsg,
    handleFirstDateBlur,
    handleFirstDateChange,
    handleFirstDateChangeRaw,
    handleFirstDateSelect,
    handleSecondDateBlur,
    handleSecondDateChange,
    handleSecondDateChangeRaw,
    handleSecondDateSelect,
    handleShowNoDateItemsChange,
    hasFirstDateCustomValidationMsg,
    hasSecondDateCustomValidationMsg,
    isFirstDateAfterMaxDate,
    isFirstDateAfterSecondDate,
    isFirstDateBeforeMinDate,
    isSecondDateAfterMaxDate,
    isSecondDateBeforeMinDate,
    isValidFirstDate,
    isValidSecondDate,
    maxDate,
    minDate,
    openToFirstDate,
    openToSecondDate,
    passedFirstDate,
    passedSecondDate,
    passedShowNoDateItems,
    placeholderFirstDate,
    placeholderSecondDate,
    requiredFirstDate,
    requiredSecondDate,
    secondDate,
    secondDateCustomValidationMsg,
    secondDateErrorMsg,
    setBannerCount,
    setFirstDate,
    setFirstDateErrorMsg,
    setSecondDate,
    setSecondDateErrorMsg,
    setShowNoDates,
    showNoDates,
    showRequiredBannerFirstDate,
    showRequiredBannerSecondDate,
  } = useAppLogic({
    id,
    onBlurAllValues,
    onChangeAllValues,
    earliestLatestDates,
    startEndDisabledDates,
    startEndRequiredDates,
    openToDates,
    placeholderText,
    suppressVerticalSpacing,
    suppressWarningBanner,
    validateStartDate,
    validateEndDate,
    values,
  });

  /** Allow user supplied onBlur handler for blur on first date field
   * onBlurAllValues won't get triggered when user tab between fields
   */
  const handleFirstBlur: FocusEventHandler<HTMLInputElement> = (evt) => {
    handleFirstDateBlur(evt);
    if (onFirstDateBlur) onFirstDateBlur(evt);
  };
  /** Allow user supplied onBlur handler for blur on second date field */
  const handleSecondBlur: FocusEventHandler<HTMLInputElement> = (evt) => {
    handleSecondDateBlur(evt);
    if (onSecondDateBlur) onSecondDateBlur(evt);
  };

  /** Monitor for any external changes to the passed values. */
  useEffect(() => {
    setFirstDate(passedFirstDate);
  }, [passedFirstDate, setFirstDate]);
  useEffect(() => {
    setSecondDate(passedSecondDate);
  }, [passedSecondDate, setSecondDate]);
  useEffect(() => {
    setShowNoDates(passedShowNoDateItems);
  }, [passedFirstDate, passedSecondDate, passedShowNoDateItems, setShowNoDates]);

  /** These useEffect() cause the start/end date edit fields to change to an error color. */
  useEffect(() => {
    // The only important thing about the text is whether it is zero length or not per DateInput.
    setFirstDateErrorMsg(
      !isValidFirstDate ||
        hasFirstDateCustomValidationMsg ||
        showRequiredBannerFirstDate ||
        isFirstDateAfterMaxDate ||
        isFirstDateBeforeMinDate
        ? 'error'
        : ''
    );
  }, [
    hasFirstDateCustomValidationMsg,
    isFirstDateAfterMaxDate,
    isFirstDateBeforeMinDate,
    isValidFirstDate,
    setFirstDateErrorMsg,
    showRequiredBannerFirstDate,
  ]);
  useEffect(() => {
    // The only important thing about the text is whether it is zero length or not per DateInput.
    setSecondDateErrorMsg(
      !isValidSecondDate ||
        hasSecondDateCustomValidationMsg ||
        showRequiredBannerSecondDate ||
        isFirstDateAfterSecondDate ||
        isSecondDateAfterMaxDate ||
        isSecondDateBeforeMinDate
        ? 'error'
        : ''
    );
  }, [
    firstDate,
    hasSecondDateCustomValidationMsg,
    isFirstDateAfterSecondDate,
    isSecondDateAfterMaxDate,
    isSecondDateBeforeMinDate,
    isValidSecondDate,
    setSecondDateErrorMsg,
    showRequiredBannerSecondDate,
  ]);

  let count = 0;

  if (isFirstDateAfterSecondDate) count++;
  if (isFirstDateAfterMaxDate) count++;
  if (isFirstDateBeforeMinDate) count++;
  if (hasFirstDateCustomValidationMsg) count++;
  if (showRequiredBannerFirstDate) count++;
  if (!isValidFirstDate) count++;
  if (isSecondDateAfterMaxDate) count++;
  if (isSecondDateBeforeMinDate) count++;
  if (hasSecondDateCustomValidationMsg) count++;
  if (showRequiredBannerSecondDate) count++;
  if (!isValidSecondDate) count++;

  if (count !== bannerCount) {
    setBannerCount(count);
  }

  const rangeLabelProps: RangeLabelProps = {
    id,
    labelAlwaysAbove,
    standalone,
    suppressVerticalSpacing,
    labelText: rangeLabel,
  };
  const attentionBannerProps: AttentionBannersProps = {
    bannerCount,
    errorAlwaysBelow,
    firstDateCustomValidationMsg,
    hasFirstDateCustomValidationMsg,
    hasSecondDateCustomValidationMsg,
    id,
    isFirstDateAfterMaxDate,
    isFirstDateAfterSecondDate,
    isFirstDateBeforeMinDate,
    isSecondDateAfterMaxDate,
    isSecondDateBeforeMinDate,
    isValidFirstDate,
    isValidSecondDate,
    maxDate,
    minDate,
    secondDateCustomValidationMsg,
    showRequiredBannerFirstDate,
    showRequiredBannerSecondDate,
    standalone,
    suppressVerticalSpacing,
    suppressWarningBanner,
  };

  return (
    <FormContext.Consumer>
      {(context: FormContextData) => (
        <div
          id={id}
          data-testid={`${id}-date-range-input-testid`}
          {...classes({ extra: `${classes().className} ${className ?? ''}` })}
        >
          <div
            {...classes({
              extra: suppressVerticalSpacing ? undefined : 'fe_u_margin--top-small fe_u_margin--bottom-small',
            })}
          >
            {labelAlwaysAbove === true && <RangeLabel {...rangeLabelProps} />}
            <div {...classes({ extra: 'fe_u_flex-container', modifiers: 'align wrap' })}>
              {labelAlwaysAbove === false && <RangeLabel {...rangeLabelProps} />}
              <div {...classes({ extra: 'fe-input__input fe_u_margin--right-small' })}>
                <div {...classes({ extra: 'fe_u_flex-container', modifiers: 'wrap' })}>
                  <DateInput
                    id={`${id}-start-date-id`}
                    disabled={disabledFirstDate || disabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    required={
                      requiredFirstDate ||
                      (!startEndRequiredDates &&
                        required &&
                        (context.requiredVariation === 'blueBarWithRequiredLabel' ||
                          context.requiredVariation === 'allFieldsRequired'))
                    }
                    excludeDates={excludeStartDates}
                    filterDate={filterStartDate}
                    includeDates={includeStartDates}
                    error={error || firstDateErrorMsg}
                    openToDate={openToFirstDate}
                    placeholder={placeholderFirstDate}
                    value={firstDate}
                    onChange={handleFirstDateChange}
                    onChangeRaw={handleFirstDateChangeRaw}
                    onSelect={handleFirstDateSelect}
                    onBlur={handleFirstBlur}
                    ref={refStartDate}
                  />
                  <div
                    {...classes({
                      element: 'componentTo',
                      extra: 'fe_u_margin--left-small fe_u_margin--right-small fe_u_flex-align-self--center',
                    })}
                  >
                    to
                  </div>
                  <DateInput
                    id={`${id}-end-date-id`}
                    disabled={disabledSecondDate || disabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    required={
                      requiredSecondDate ||
                      (!startEndRequiredDates &&
                        required &&
                        (context.requiredVariation === 'blueBarWithRequiredLabel' ||
                          context.requiredVariation === 'allFieldsRequired'))
                    }
                    excludeDates={excludeEndDates}
                    filterDate={filterEndDate}
                    includeDates={includeEndDates}
                    error={error || secondDateErrorMsg}
                    openToDate={openToSecondDate}
                    placeholder={placeholderSecondDate}
                    value={secondDate}
                    onChange={handleSecondDateChange}
                    onChangeRaw={handleSecondDateChangeRaw}
                    onSelect={handleSecondDateSelect}
                    onBlur={handleSecondBlur}
                    ref={refEndDate}
                  />
                </div>
                <DescriptionLabel
                  allowNullValuesCheckBox={allowNullValuesCheckBox}
                  bannerCount={bannerCount}
                  errorAlwaysBelow={errorAlwaysBelow}
                  labelText={rangeDescription}
                  suppressVerticalSpacing={suppressVerticalSpacing}
                  suppressWarningBanner={suppressWarningBanner}
                />
                <NullValueCheckbox
                  allowNullValuesCheckBox={
                    allowNullValuesCheckBox ||
                    (!startEndRequiredDates &&
                      required &&
                      (context.requiredVariation === 'blueBarWithRequiredLabel' ||
                        context.requiredVariation === 'allFieldsRequired'))
                  }
                  handleShowNoDateItemsChange={handleShowNoDateItemsChange}
                  id={id}
                  requiredFirstDate={requiredFirstDate}
                  requiredSecondDate={requiredSecondDate}
                  showItemsWithNoDateDescription={showItemsWithNoDateDescription}
                  showNoDateItems={showNoDates}
                  suppressVerticalSpacing={suppressVerticalSpacing}
                />
                {errorAlwaysBelow === true && <AttentionBanners {...attentionBannerProps} />}
              </div>
              {errorAlwaysBelow === false && <AttentionBanners {...attentionBannerProps} />}
            </div>
          </div>
        </div>
      )}
    </FormContext.Consumer>
  );
};

const dateRangeInputPropTypes: WeakValidationMap<DateRangeInputComponentProps> = {
  /**
   * Optional boolean to allow the required dates to be null. This turns off the overrides for the required values.
   */
  allowNullValuesCheckBox: PropTypes.bool,
  /** Optional string of classes to add to the Forge Root elements of DateRangeInput. */
  className: PropTypes.string,
  /** Optional Forge disabled prop.  Used only for FormField and MultiField. startEndDisabledDates is more fine grained. */
  disabled: PropTypes.bool,
  /** Optional object with the lower and upper bounds for the range of the calendar picker.
   *
   * For the default values, the functions subYears() and addYears() come from the package 'date-fns'.
   *
   * import dateFns from 'date-fns';
   *
   * earliestLatestDates {
   *  earliest: Date | undefined;
   *  latest: Date | undefined;
   * }
   */
  earliestLatestDates: PropTypes.shape({
    earliest: optionalDateValidator,
    latest: optionalDateValidator,
  }),
  /** Optional string of externally determined error. */
  error: PropTypes.string,
  /**
   * Optional boolean tells the error to always render below the input area instead of based on content-size and breakpoint.
   */
  errorAlwaysBelow: PropTypes.bool,
  /**
   * Optional array of dates to disable for the end date. If used, all other days (within `earliestLatestDates`, if provided) are valid.
   */
  excludeEndDates: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired),
  /**
   * Optional array of dates to disable for the start date. If used, all other days (within `earliestLatestDates`, if provided) are valid.
   */
  excludeStartDates: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired),
  /**
   * Optional function taking a JS date and returning a bool.
   * indicates whether the end date is enabled (`true`) or disabled (`false`).
   */
  filterEndDate: PropTypes.func,
  /**
   * Optional function taking a JS date and returning a bool.
   * Indicates whether the start date is enabled (`true`) or disabled (`false`).
   */
  filterStartDate: PropTypes.func,
  /** REQUIRED for uniqueness of HTML elements. */
  id: PropTypes.string.isRequired,
  /**
   * Optional array of end dates to enable. If used, all other days (within `earliestLatestDates`, if provided) are disabled.
   */
  includeEndDates: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired),
  /**
   * Optional array of start dates to enable. If used, all other days (within `earliestLatestDates`, if provided) are disabled.
   */
  includeStartDates: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired),
  /**
   * Optional boolean to force the component label to be on top of the range.
   */
  labelAlwaysAbove: PropTypes.bool,
  /** Optional user supplied handler for blur on each date. */
  onBlurAllValues: PropTypes.func,
  /** Optional user supplied handler for changes to each date. */
  onChangeAllValues: PropTypes.func,
  /** Optional user supplied handler for blur on first date. */
  onFirstDateBlur: PropTypes.func,
  /** Optional user supplied handler for blur on second date. */
  onSecondDateBlur: PropTypes.func,
  /**
   * Optional object containing Date objects.
   *
   * Allows you to override the default behavior determining what month the calendar opens to.
   * *Note that this specific date is not pre-selected or highlighted.*
   *
   * By default, the calendar opens to the selected first/end date if one exists,
   * followed by the current date (even if it is outside the calendar range or disabled).
   *
   * openToDates {
   *  start: Date | undefined;
   *  end: Date | undefined;
   * }
   */
  openToDates: PropTypes.shape({
    end: optionalDateValidator,
    start: optionalDateValidator,
  }),
  /**
   * Optional object containing text to show in the inputs if the first or end dates have no value and are unfocused.
   * Although the defaults are "undefined", the default DateInput placeholder will be used ("MM-DD-YYYY").
   *
   * placeholderText {
   *  start: string | undefined;
   *  end: string | undefined;
   * }
   */
  placeholderText: PropTypes.shape({
    end: optionalStringValidator,
    start: optionalStringValidator,
  }),
  /**
   * Optional string containing a description that helps users select sensible first and end dates.
   */
  rangeDescription: PropTypes.string,
  /**
   * Optional label for the date range.
   */
  rangeLabel: PropTypes.string,
  /** Optional Forge require prop.  Used only for FormField and MultiField. startEndRequiredDates is more fine grained. */
  required: PropTypes.bool,
  /** Optional label for the checkbox indicating if items with no dates should be returned on filter. */
  showItemsWithNoDateDescription: PropTypes.string,
  /** Optional boolean for standalone DateRangeInputs, indicating that they are not part of a Form and should show their own label and banners (if provided). */
  standalone: PropTypes.bool,
  /**
   * Optional object containing booleans.
   * start indicates if the start Date is disabled and
   * end indicates if the end Date is disabled.
   *
   * startEndDisabledDates {
   *  start: boolean | undefined;
   *  end: boolean | undefined;
   * }
   */
  startEndDisabledDates: PropTypes.shape({
    end: optionalBoolValidator,
    start: optionalBoolValidator,
  }),
  /**
   * Optional object containing booleans.
   * start indicates if the start Date is required and
   * end indicates if the end Date is required.
   *
   * startEndRequiredDates {
   *  start: boolean | undefined;
   *  end: boolean | undefined;
   * }
   */
  startEndRequiredDates: PropTypes.shape({
    end: optionalBoolValidator,
    start: optionalBoolValidator,
  }),
  /** Optional boolean indicating if the DateRangeInput should suppress vertical spacing.  Defaults to false. */
  suppressVerticalSpacing: PropTypes.bool,
  /** Optional boolean indicating if the DateRangeInput should suppress the display of error messages.  Defaults to false. */
  suppressWarningBanner: PropTypes.bool,
  /**
   * Optional function taking a JS date and returning a string or null.
   * This function is implemented by the implementer of the DateRangeInput to allow for their own date validations for the start date.
   * If null is returned, the start date is acceptable.
   * If a string is returned, it contains a message to display to the user in an error message informing them why the selected start date
   * is not valid.
   */
  validateStartDate: PropTypes.func,
  /**
   * Optional function taking a JS date and returning a string or null
   * This function is implemented by the implementer of the DateRangeInput to allow for their own date validations for the end date.
   * If null is returned, the end date is acceptable.
   * If a string is returned, it contains a message to display to the user in an error message informing them why the selected end date
   * is not valid.
   */
  validateEndDate: PropTypes.func,
  /**
   * Optional object for storing the start date, end date, and require checkbox value.  Elements can be undefined if not used.
   *
   * DateRangeInputValues {
   *  start: Date | null | undefined;
   *  end: Date | null | undefined;
   *  checked: boolean | undefined;
   * }
   */
  values: PropTypes.shape({
    checked: optionalBoolValidator,
    end: PropTypes.instanceOf(Date),
    start: PropTypes.instanceOf(Date),
  }),
};

DateRangeInput.propTypes = dateRangeInputPropTypes;

export default DateRangeInput;
