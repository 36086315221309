import React, { useMemo } from 'react';
import Button from '../../../Button';
import Heading from '../../../Heading';
import Select from '../../../Select';
import { DataTableState, DataTableStore } from '../../store/stateTypes';
import { classes } from '../../utils/dataTableClasses';
import SegmentedButton, { SegmentedButtonOnChangeEvent } from '../../../SegmentedButton';

// Actions
import { apply_client_side_filters } from '../../store/actions/apply_client_side_filters';
import { apply_server_side_filters } from '../../store/actions/apply_server_side_filters';
import { reset_column_filters } from '../../store/actions/reset_column_filters';
import { reset_data_filters } from '../../store/actions/reset_date_filters';
import { reset_paginator } from '../../store/actions/reset_paginator';

function filter_by_quick_filters<RowData>(s: DataTableState<RowData>): void {
  reset_paginator(s);
  reset_column_filters(s);
  reset_data_filters(s);
  apply_client_side_filters(s);
  apply_server_side_filters(s);
}

type QuickFilterPanelProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function QuickFilterPanel<RowData>({ store }: QuickFilterPanelProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const {
    layout, // prettier-break-line
    options,
    selectedOptionIds,
    showAllOptions,
    showAllOptionsButton,
  } = store.useSelector((s) => s.quickFilter);

  const {
    getTextByOptionId, // prettier-break-line
    oneLineOptions,
    multiLineOptions,
    multiSelectOptions,
  } = useMemo(() => getAvailableOptions({ options, showAllOptions }), [options, showAllOptions]);

  /** reformat the selectedOptionIds to conform with SegmentedButtonCheckedValues */
  const selected = useMemo(() => {
    return selectedOptionIds.reduce((acc: string[], id) => {
      acc.push(id);
      return acc;
    }, []);
  }, [selectedOptionIds]);

  const updateSelectedValues = (e: SegmentedButtonOnChangeEvent<'checkbox'>): void => {
    e.persist();

    const selected = [...e.target.value];

    // Toggle the selection based on whether it's currently selected
    dispatch(function update_quick_filter_selected_values_from_checkbox_button(s) {
      s.quickFilter.selectedOptionIds = selected;
      filter_by_quick_filters(s);
    });
  };

  if (layout === 'none') {
    return <></>;
  }

  return (
    <div {...classes({ element: 'quick_filter', modifiers: layout })}>
      <Heading headingTag="h3" variant="subsection" text="Quick Filters" />

      {/* One Line */}
      {layout === 'one-line' && (
        /* CheckboxButton/SegmentedButton Migration Start */
        <SegmentedButton behavior="checkbox" alignment="separated" value={selected} onChange={updateSelectedValues}>
          {oneLineOptions.map((opt) => (
            <Button key={opt.value} value={opt.value} text={opt.label} />
          ))}
        </SegmentedButton>
        /* CheckboxButton/SegmentedButton Migration End */
      )}

      {/* Multi Lines */}
      {layout === 'multi-lines' && (
        /* CheckboxButton/SegmentedButton Migration Start */
        <SegmentedButton behavior="checkbox" alignment="separated" value={selected} onChange={updateSelectedValues}>
          {multiLineOptions.map((opt) => (
            <Button key={opt.value} value={opt.value}>
              {opt.element}
            </Button>
          ))}
        </SegmentedButton>
        /* CheckboxButton/SegmentedButton Migration End */
      )}

      {/* Multi Select */}
      {layout === 'multi-select' && (
        <Select
          isMulti
          options={multiSelectOptions}
          value={selectedOptionIds.map((optId) => ({ value: optId, label: getTextByOptionId(optId) }))}
          onChange={(e) => {
            dispatch(function update_quick_filter_selected_values_from_multiselect(s) {
              const selectedOptions = e.target.value;
              s.quickFilter.selectedOptionIds = selectedOptions.map((opt) => opt.value);
              filter_by_quick_filters(s);
            });
          }}
        />
      )}

      {/* Show All Option Button */}
      {showAllOptionsButton && (
        <Button
          variant="tertiary"
          text={showAllOptions ? 'Show less' : 'Show all'}
          onClick={() => {
            dispatch(function toggle_show_all_options(s) {
              s.quickFilter.showAllOptions = !s.quickFilter.showAllOptions;
            });
          }}
        />
      )}
    </div>
  );
}

// ====================================
// Utils
// ====================================
type GetAvailableOptionsArgs<RowData> = Pick<DataTableState<RowData>['quickFilter'], 'options' | 'showAllOptions'>;
type GetAvailableOptions = {
  getTextByOptionId: (value: string) => string;
  oneLineOptions: { value: string; label: string }[];
  multiSelectOptions: { value: string; label: string }[];
  multiLineOptions: { value: string; element: React.JSX.Element }[];
};
function getAvailableOptions<RowData>({
  options,
  showAllOptions,
}: GetAvailableOptionsArgs<RowData>): GetAvailableOptions {
  const availableOptions = options.filter((opt) => showAllOptions || !opt.isOptional);

  const optionIdTextMap = {} as Record<string, string>; // Map<id: string, label: string>
  options.forEach((opt) => {
    const textArray = typeof opt.label === 'string' ? [opt.label] : opt.label;
    optionIdTextMap[opt.id] = textArray.join(' ');
  });

  const getTextByOptionId = (value: string): string => {
    return optionIdTextMap[value];
  };

  return {
    getTextByOptionId,
    oneLineOptions: availableOptions.map((opt) => ({ value: opt.id, label: getTextByOptionId(opt.id) })),
    multiSelectOptions: availableOptions.map((opt) => ({ value: opt.id, label: getTextByOptionId(opt.id) })),
    multiLineOptions: availableOptions.map((opt) => {
      const textArray = typeof opt.label === 'string' ? [opt.label] : opt.label;
      return {
        value: opt.id,
        element: (
          <div>
            {textArray.map((line, index) => (
              <div key={`${opt.id}-${index}`}>{line}</div>
            ))}
          </div>
        ),
      };
    }),
  };
}
