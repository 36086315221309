import AttentionLarge from '@athena/forge-icons/dist/AttentionLarge';
import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import CriticalLarge from '@athena/forge-icons/dist/CriticalLarge';
import InfoLarge from '@athena/forge-icons/dist/InfoLarge';
import NewLarge from '@athena/forge-icons/dist/NewLarge';
import SuccessLarge from '@athena/forge-icons/dist/SuccessLarge';
import PropTypes from 'prop-types';
import React, { WeakValidationMap } from 'react';
import Button from '../Button';
import { forgeClassHelper } from '../utils/classes';
import { AlertTypes } from '../utils/constants';

const classes = forgeClassHelper({ name: 'banner' });

const iconClasses = { ...classes({ element: 'icon' }) };

const iconLookup = {
  attention: <AttentionLarge {...iconClasses} />,
  critical: <CriticalLarge {...iconClasses} />,
  info: <InfoLarge {...iconClasses} />,
  new: <NewLarge {...iconClasses} />,
  success: <SuccessLarge {...iconClasses} />,
};

export interface BannerProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Gives a default left border and header with icon that corresponds to status level */
  alertType?: AlertTypes;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Callback called when close button is clicked, if not included no close is shown */
  onHide?: React.MouseEventHandler<HTMLButtonElement>;
  /** BannerItems to render inside the Banner */
  children: React.ReactNode;
}

const Banner = ({ alertType = 'info', onHide, className, children, ...props }: BannerProps): React.ReactElement => (
  <div
    {...props}
    {...classes({
      extra: className,
      modifiers: [alertType],
    })}
    role="note"
  >
    {alertType && iconLookup[alertType]}
    <div {...classes({ element: 'items' })}>{children}</div>
    {onHide && (
      <Button
        {...classes({ element: 'dismiss-button' })}
        icon={CloseSmall}
        size="small"
        variant="tertiary"
        onClick={onHide}
      />
    )}
  </div>
);

const bannerPropTypes: WeakValidationMap<BannerProps> = {
  /** Gives a default left border and header with icon that corresponds to status level */
  alertType: PropTypes.oneOf<AlertTypes>(['attention', 'critical', 'info', 'new', 'success']),
  /** BannerItems to render inside the Banner */
  children: PropTypes.node.isRequired,
  /** Callback called when close button is clicked, if not included no close is shown */
  onHide: PropTypes.func,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
};
Banner.propTypes = bannerPropTypes;

export default Banner;
