import { ReactElement, ReactNode } from 'react';
import { components, ValueContainerProps, GroupBase } from 'react-select';
import { StructuredOption } from '../Select';
import { useSelectContext } from '../context/SelectContext';
import { classes } from '../Select';

const IconContainer = (): ReactNode => {
  const { icon: Icon } = useSelectContext();
  if (Icon)
    return (
      <div
        {...classes({
          element: 'profile-icon',
        })}
      >
        <Icon />
      </div>
    );
  return null;
};

const CustomValueContainer = <
  Option extends StructuredOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  children,
  ...props
}: ValueContainerProps<Option, IsMulti, Group>): ReactElement => {
  return (
    <>
      <IconContainer />
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    </>
  );
};

export default CustomValueContainer;
