import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUnpinLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8071 0.292888C14.4166 -0.0976332 13.7834 -0.0976289 13.3929 0.292898C13.0023 0.683425 13.0023 1.31659 13.3929 1.70711L14.7622 3.07647L9.78541 6.95121L11.2105 8.37634L16.1874 4.50159L17.8261 6.14024L19.4969 7.8111L15.6118 12.7776L17.0365 14.2023L20.9216 9.23578L22.2929 10.607C22.6834 10.9975 23.3166 10.9975 23.7071 10.607C24.0976 10.2165 24.0976 9.58331 23.7071 9.19279L19.2742 4.7599L16.9771 2.46282L16.9689 2.45472L14.8071 0.292888ZM16.1638 16.158L14.3704 14.3646L14.2224 14.5538C14.0392 14.7879 13.9697 15.0913 14.0327 15.3818C14.2812 16.5283 14.2376 17.7188 13.9058 18.8442C13.6689 19.6478 13.2904 20.3997 12.7907 21.0657L7.92585 16.1967C7.90737 16.1747 7.8878 16.1534 7.86713 16.1327C7.84668 16.1123 7.82556 16.0929 7.80384 16.0746L2.92717 11.1938C3.59454 10.6976 4.34623 10.3213 5.14877 10.0847C6.27435 9.7528 7.46489 9.70619 8.61339 9.94865C8.90155 10.0095 9.20188 9.9402 9.43427 9.75927L9.62024 9.61448L7.82687 7.8211C6.73703 7.73966 5.63796 7.85532 4.58317 8.16631C3.13933 8.59201 1.82405 9.37018 0.755628 10.4303C0.566929 10.6175 0.46054 10.8721 0.459963 11.138C0.459387 11.4038 0.56467 11.6589 0.752555 11.8469L5.74353 16.8421L0.292886 22.2929C-0.0976342 22.6834 -0.0976279 23.3166 0.2929 23.7071C0.683428 24.0976 1.31659 24.0976 1.70711 23.7071L7.15714 18.257L12.1326 23.2366C12.5226 23.627 13.1552 23.6276 13.546 23.2379C14.6142 22.173 15.3976 20.8566 15.8242 19.4098C16.1359 18.3524 16.2498 17.2502 16.1638 16.158Z"
      fill={props.color}
    />
    <path
      d="M1 1L23 23"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
SvgUnpinLarge.displayName = 'SvgUnpinLarge';
const UnpinLarge = withForgeIconProps(SvgUnpinLarge, {
  name: 'Unpin',
  usage: 'Use to unpin an item',
  related: 'thumbtack, top, favorite',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default UnpinLarge;
