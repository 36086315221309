import { useEffect, useState } from 'react';
import Input from '../../../Input';
import Form from '../../../Form';
import Button from '../../../Button';
import SearchSmall from '@athena/forge-icons/dist/SearchSmall';
import { DataTableStore } from '../../store/stateTypes';
import { hasRowsInEditStatusFromState } from '../../utils/hasRowsInEditStatusFromState';
import { DataTableBlockUI } from '../../utils/DataTableBlockUI';
import { classes } from '../../utils/dataTableClasses';
import { reset_paginator } from '../../store/actions/reset_paginator';

type ServerSideSearchBoxProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function ServerSideSearchBox<RowData>({ store }: ServerSideSearchBoxProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const tableType = store.useSelector((s) => s.tableType);
  const searchBox = store.useSelector((s) => s.searchBox);
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));
  const [searchInputValue, setSearchInputValue] = useState('');

  // If the search field is cleared by its clear 'X' button, update the state.
  useEffect(() => {
    if (searchInputValue === '') {
      dispatch(function clear_search_text(s) {
        reset_paginator(s);
        s.searchBox.inputText = s.serverSideFetchRowDataArgs.searchText = '';
      });
    }
  }, [dispatch, searchInputValue]);

  if (!searchBox.show || tableType !== 'server-side') {
    return <></>;
  }

  return (
    <DataTableBlockUI block={hasRowsInEditStatus}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        includeSubmitButton={false}
        {...classes({ element: 'server_side_search_box' })}
      >
        <Input
          clearable={true}
          icon={SearchSmall}
          onChange={(e) => {
            setSearchInputValue(e.target.value);
          }}
        />
        <Button
          text="Search"
          type="submit"
          aria-label="server-side-search-box-button"
          size="medium"
          variant="secondary"
          onClick={() => {
            dispatch(function update_search_text(s) {
              reset_paginator(s);
              s.searchBox.inputText = s.serverSideFetchRowDataArgs.searchText = searchInputValue;
            });
          }}
        />
      </Form>
    </DataTableBlockUI>
  );
}
