import React, { forwardRef, ReactElement, WeakValidationMap } from 'react';
import pseudoRandomInt from './pseudorandom';
import PropTypes from 'prop-types';
import { forgeClassHelper } from '../utils/classes';

type TypeType = 'text' | 'box' | 'circle';

export interface SkeletonProps {
  /** seed is a number that identifies this instance of the Skeleton and is required */
  seed: number;
  /** the shape of the skeleton */
  type: TypeType;
  /** indicates active loading; produces the shimmer effect */
  isFetching: boolean;
  /** secondarySeed is an optional number to provide another dimension of randomness, defaults to 0 */
  secondarySeed?: number;
  /** the height of the skeleton for box or circle, not used for text */
  height?: number;
  /** the maximum width of the skeleton for text, or the actual width for box and circle */
  width?: number;
  /** the minimum width of the skeleton for text, not used for box or circle */
  minWidth?: number;
  /** the units in which width, minWidth, and height are expressed (e.g. "px") */
  units?: string;
}

// The computation of the width of a skeleton component depends on its type and any specified width and minWidth values.
function getSkeletonWidth(props: SkeletonProps): number {
  const { type, width, minWidth, seed, secondarySeed } = props;

  if (type === 'text') {
    // text defaults to a width of 280.
    const maxWidth = width ?? 280;
    // don't call pseudorandom code if we don't have to.
    if (minWidth && minWidth === maxWidth) {
      return maxWidth;
    }
    return pseudoRandomInt({
      a: seed,
      b: secondarySeed,
      min: minWidth ?? maxWidth / 3,
      max: maxWidth,
    });
  } else {
    // circle and box default to a width of 48.
    return width ?? 48;
  }
}

const Skeleton = forwardRef(function Skeleton(
  props: SkeletonProps,
  ref?: React.ForwardedRef<HTMLDivElement>
): ReactElement {
  const { units = 'px', isFetching: isLoading, type, height } = props;
  const skeletonWidth = getSkeletonWidth(props);
  const style: Record<string, number | string> = {
    width: `${skeletonWidth}${units}`,
  };
  if (type !== 'text') {
    style.height = height ?? skeletonWidth ?? '48px';
  }

  const classes = forgeClassHelper({ name: 'skeleton' });

  return (
    <div
      ref={ref}
      role="progressbar"
      aria-label="loading"
      {...classes({ modifiers: { [type]: true, shimmer: isLoading } })}
      style={style}
    />
  );
});

export default Skeleton;

// this list is required to define the PropType skeleton type.  It should match TypeType.
// Typescript allows you to go from an array of strings to a type definition, but not
// the other way around, and defining TypeType that way is confusing and annoying.
const typeList: TypeType[] = ['text', 'box', 'circle'];

/** runtime prop-types for non-typescript use cases */
const skeletonPropTypes: WeakValidationMap<SkeletonProps> = {
  seed: PropTypes.number.isRequired,
  secondarySeed: PropTypes.number,
  type: PropTypes.oneOf(typeList).isRequired,
  isFetching: PropTypes.bool.isRequired,
  width: PropTypes.number,
  minWidth: PropTypes.number,
  height: PropTypes.number,
  units: PropTypes.string,
};

Skeleton.propTypes = skeletonPropTypes;
