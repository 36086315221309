import React from 'react';

type OnClickItem = (
  event: React.MouseEvent<HTMLUListElement, MouseEvent> | React.KeyboardEvent<HTMLUListElement>,
  /** The data-key of the corresponding list item */
  uniqueKey: string | null
) => void;

interface UseOnClickItemArgs extends React.HTMLAttributes<HTMLUListElement> {
  disabled: boolean;
  onClickItem: OnClickItem;
}

const findUniqueKeyForEvent = (
  event: React.MouseEvent<HTMLUListElement, MouseEvent> | React.KeyboardEvent<HTMLUListElement>
): string | null => {
  if (event.target) {
    let li = event.target as HTMLElement | null;
    while (li && li.parentNode !== event.currentTarget) {
      li = li.parentNode as HTMLElement;
    }

    return li ? li.getAttribute('data-key') : null;
  } else {
    return null;
  }
};

export const useOnClickItem = ({
  disabled,
  onClickItem,
  onClick,
  onKeyDown,
}: UseOnClickItemArgs): Pick<UseOnClickItemArgs, 'onClick' | 'onKeyDown'> => {
  // As a convenience, pass the unique key of the corresponding list item
  // to the callback as the second parameter.
  if (!disabled) {
    return {
      onClick: (event: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
        onClickItem(event, findUniqueKeyForEvent(event));
        onClick?.(event);
      },
      onKeyDown: (event: React.KeyboardEvent<HTMLUListElement>) => {
        if (event.code === 'Enter' || event.code === 'Space') {
          // Enter or Spacebar
          onClickItem(event, findUniqueKeyForEvent(event));
          onKeyDown?.(event);
        }
      },
    };
  }
  return { onClick, onKeyDown };
};
