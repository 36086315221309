import { ReactElement, ReactNode, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { DataTable, Button, Modal, createDataTable } from '@athena/forge';
import { forgeClassHelper } from '@athena/forge/dist/utils/classes';
import { MetadataElement, PropMetadata, PropType, PropValue } from '../../contexts';
import Code from '../code';
import './props-tables.scss';

const classes = forgeClassHelper('props-table');

export interface PropsTablesProps {
  componentsMetadata: MetadataElement[];
}

function isPropType(name: string, value: PropValue): value is PropType {
  return (
    name === 'arrayOf' &&
    (Object.prototype.hasOwnProperty.call(value, 'name') || Object.prototype.hasOwnProperty.call(value, 'value'))
  );
}

function isArrayOfProps(name: string, value: PropValue): value is PropType[] {
  return ['union', 'enum'].includes(name) && Array.isArray(value);
}

/** Determines if name/value represents an object with an unknown shape.
 *
 *  If a propType is imported from another file, then `value` is the text of
 * the import statement, not the implementation. This is due to a babel limitation
 */
function isUnknownShape(name: string, value: PropValue): value is string {
  return name === 'shape' && typeof value === 'string';
}

/** Determines if name/value represents an object with a known shape. */
function isPropRecord(name: string, value: PropValue): value is Record<string, PropType> {
  return name === 'shape' && typeof value === 'object';
}

const betterPropTypesRegex = /(?<optionality>optional|required)(?<type>\w+)Validator/;

function RenderType({ name, value, raw }: PropType): ReactElement {
  if (name === undefined && value === undefined) {
    return <></>;
  } else if (value === null || value === undefined) {
    const match = raw?.match(betterPropTypesRegex);
    if (match && match.groups?.type) {
      return <code>{match.groups.type.toLocaleLowerCase()}</code>;
    } else {
      return <code>{name}</code>;
    }
  } else if (name === undefined) {
    return <code>{value as ReactNode}</code>;
  } else if (isPropType(name, value) || isArrayOfProps(name, value)) {
    const values = isArrayOfProps(name, value) ? value : [value];
    return (
      <>
        <code>{name}</code>
        <ul>
          {values.map((t, i) => (
            <li key={`${name}_item_${i}`}>
              <RenderType {...t} />
            </li>
          ))}
        </ul>
      </>
    );
  } else if (isUnknownShape(name, value)) {
    return <code>{name}</code>;
  } else if (isPropRecord(name, value)) {
    return (
      <>
        <code>{name}</code>
        <ul>
          {Object.keys(value).map((v, i) => {
            /** Handle custom validators defined in betterPropTypes.ts */
            const match = value[v].raw?.match(betterPropTypesRegex);
            if (match) {
              const annotation = match.groups?.optionality === 'required' ? ' (required)' : '';
              const propType = match.groups?.type.toLocaleLowerCase();
              return (
                <li key={`${name}_item_${i}`}>
                  {v}
                  {annotation}:&nbsp;
                  <code>{propType}</code>
                </li>
              );
            } else {
              return (
                <li key={`${name}_item_${i}`}>
                  {v}
                  {value[v].required ? ' (required)' : ''}:&nbsp;
                  {RenderType(value[v])}
                </li>
              );
            }
          })}
        </ul>
      </>
    );
  }
  switch (name) {
    case 'instanceOf':
      return value;
    case 'objectOf':
      return (
        <>
          <code>objectOf</code>
          <ul>
            <li>
              <RenderType {...value} />
            </li>
          </ul>
        </>
      );
    default:
      console.error('Unhandled type name: ' + name);
      return <></>;
  }
}

interface RowData {
  id: string;
  prop: string;
  required: boolean;
  type: PropType;
  default: string;
  description: string;
}

const propsToRowData = (props: PropMetadata[]): RowData[] =>
  props
    /** By convention, `forwardedRef` is not a prop that ever makes its way to
     * a default export. It serves as a proxy for `ref`, but Typescript
     * definitions make better documentation for our props table.
     */
    .filter((prop) => prop.name !== 'forwardedRef')
    /** Sort required props first, ...rest last, the rest alphabetically */
    .sort((a, b) => {
      if (a.required && !b.required) {
        return -1;
      } else if (!a.required && b.required) {
        return 1;
      } else if (b.name.startsWith('...')) {
        return -1;
      } else if (a.name.startsWith('...')) {
        return 1;
      } else {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }
    })
    .map((prop) => {
      const rowData: RowData = {
        id: prop.id,
        prop: prop.name,
        required: prop.required,
        type: prop.type,
        default: prop.defaultValue ? prop.defaultValue.value : '',
        description: prop.description.text,
      };
      return rowData;
    });

/** Create two DataTable objects so that they have unique IDs */
const inlineDT = createDataTable<RowData>({ tableId: 'props-table-inline' });
const modalDT = createDataTable<RowData>({ tableId: 'props-table-modal' });

interface PropsTableContentProps extends PropsTablesProps {
  DT: DataTable<RowData>;
  insideOverlay?: boolean;
}

function PropsTableContent({ componentsMetadata, DT, insideOverlay = false }: PropsTableContentProps): ReactElement {
  return (
    <div {...classes({ element: 'related-components' })}>
      {componentsMetadata.map(({ node }: MetadataElement) => {
        /** No sense displaying the component name if there is only one
         * prop table on the page.
         */
        const title = componentsMetadata.length > 1 ? node.displayName : '';
        return (
          <DT.DataTable
            {...classes({ element: 'component', modifiers: ['inside-overlay'] })}
            key={node.id}
            tableType="client-side"
            tableLayout="medium"
            title={title}
            tableStickyHeaders={true}
            rowData={propsToRowData(node.props)}
            columns={[
              {
                columnId: 'prop',
                Header: () => <>Prop</>,
                Cell: ({ useSelector, rowId }) => {
                  const value = useSelector((s) => s.rows[rowId].data);
                  return (
                    <>
                      <strong>{value.prop}</strong>
                      {value.required && ' (required)'}
                    </>
                  );
                },
              },
              {
                columnId: 'type',
                Header: () => <>Type</>,
                Cell: ({ useSelector, rowId }) => {
                  const value = useSelector((s) => s.rows[rowId].data);
                  const { type } = value;
                  if (type) {
                    return RenderType(type);
                  } else {
                    return 'No type :(';
                  }
                },
              },
              {
                columnId: 'default',
                Header: () => <>Default</>,
                Cell: ({ useSelector, rowId }) => {
                  const value = useSelector((s) => s.rows[rowId].data);

                  const { default: defaultValue } = value;
                  if (defaultValue.indexOf('\n') > -1) {
                    return <Code codeString={defaultValue} language="javascript" />;
                  } else if (defaultValue) {
                    return <code>{defaultValue}</code>;
                  }
                },
              },
              {
                columnId: 'description',
                Header: () => <>Description</>,
                Cell: ({ useSelector, rowId }) => {
                  const value = useSelector((s) => s.rows[rowId].data);
                  const { description } = value;
                  return <ReactMarkdown remarkPlugins={[remarkGfm]}>{description}</ReactMarkdown>;
                },
              },
            ]}
          />
        );
      })}
    </div>
  );
}

export default function PropsTables({ componentsMetadata }: PropsTablesProps): ReactElement {
  /** Contains all component names. This includes sub-components tagged with @documentedBy */
  const [showModal, setShowModal] = useState(false);
  return (
    <div {...classes({ element: 'container' })}>
      <Button
        className="fe_u_margin--bottom-small"
        text="View in Modal"
        onClick={() => setShowModal((prevShowModal) => !prevShowModal)}
      />
      <Modal
        rightClassName={classes({ element: 'modal' }).className}
        width="full"
        headerText="Props (horizontal and vertical scrolling available)"
        show={showModal}
        includeButtonRow={false}
        onHide={() => setShowModal(false)}
      >
        <PropsTableContent insideOverlay componentsMetadata={componentsMetadata} DT={modalDT} />
      </Modal>
      <PropsTableContent componentsMetadata={componentsMetadata} DT={inlineDT} />
    </div>
  );
}
