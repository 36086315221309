import React from 'react';
import Label from '../Label';
import Checkbox from '../Checkbox';
import { DateInputOnChangeEvent } from '../DateInput';
import { forgeClassHelper } from '../utils/classes';

const classes = forgeClassHelper({ name: 'date-range-input' });

export interface NullValueCheckboxProps {
  /**
   * Optional boolean that indicates if the JSX for the DateRangeInput's checkbox should be created.
   * If false, an empty JSX element is returned IF the two requiredValue args are false.
   */
  allowNullValuesCheckBox?: boolean;
  /**
   * REQUIRED function for the checkbox's onChange attribute.
   */
  handleShowNoDateItemsChange: (ev: DateInputOnChangeEvent) => void;
  /** REQUIRED prefix id for the HTML tag. */
  id: string;
  /**
   * Optional boolean that indicates if the first date is required.
   * If true, the checkbox is visible.
   */
  requiredFirstDate?: boolean;
  /**
   * Optional boolean that indicates if the second date is required.
   * If true, the checkbox is visible.
   */
  requiredSecondDate?: boolean;
  /** Optional string with an custom string for the checkbox's description. */
  showItemsWithNoDateDescription?: string;
  /**
   * REQUIRED boolean holding the state of the checkbox.  Since it can be a state variable, it
   * can cause the visible state of the checkbox to change.
   */
  showNoDateItems: boolean;
  /**
   * REQUIRED boolean that constricts the components margins.  If true, there is no
   * bottom margin for the control but there is one on top because whatever above has
   * no bottom margin.
   */
  suppressVerticalSpacing: boolean;
}

export interface DescriptionLabelProps {
  /**
   * REQUIRED boolean that indicates if the JSX for the DateRangeInput's checkbox should be created.
   * If false, an empty JSX element is returned IF the two requiredValue args are false.
   */
  allowNullValuesCheckBox: boolean;
  /** REQUIRED number with a count of the banners currently being displayed */
  bannerCount: number;
  /**
   * REQUIRED boolean tells the error to always render below the input area instead of based on content-size and breakpoint.
   */
  errorAlwaysBelow: boolean;
  /**
   * REQUIRED boolean that can constrict the components margins.  If false, there is no
   * bottom margin for the control unless there is more than one banner.  If true, a there
   * will be a top margin to prevent this description from bumping into any element above it.
   */
  suppressVerticalSpacing?: boolean;
  /**
   * REQUIRED boolean indicating if the DateRangeInput should suppress the display of error messages.
   */
  suppressWarningBanner?: boolean;
  /**
   * Optional string containing the description to display.  Since it is user provided and not
   * required, if undefined or an empty string it would return an empty JSX element.
   */
  labelText?: string;
}

export interface RangeLabelProps {
  /** REQUIRED string holding the HTML id for the component. */
  id: string;
  /**
   * REQUIRED boolean to force the component label to be on top of the range.
   * Used only if suppressVerticalSpacing is true.
   * */
  labelAlwaysAbove: boolean;
  standalone: boolean;
  /**
   * REQUIRED boolean that constricts the components margins.  If true, there is no
   * bottom top for this label.  Used only if labelAlwaysAbove is true.
   */
  suppressVerticalSpacing: boolean;
  /** Optional string holding the text of the Forge Label to be created. */
  labelText?: string;
}

/**
 * NullValueCheckbox(props: NullValueCheckboxProps): JSX.Element
 *
 * This function creates the Forge Checkbox component for this control.
 *
 * @param props NullValueCheckboxProps
 *
 * @returns JSX.Element if passed properties allow for one.
 */
export const NullValueCheckbox = (props: NullValueCheckboxProps): JSX.Element => {
  const {
    allowNullValuesCheckBox,
    handleShowNoDateItemsChange,
    id,
    requiredFirstDate,
    requiredSecondDate,
    showItemsWithNoDateDescription,
    showNoDateItems,
  } = props;

  return allowNullValuesCheckBox || requiredFirstDate || requiredSecondDate ? (
    <Checkbox
      // Checking for undefined since TypeScript is ignoring the setting in DateRangeInput.defaultProps.
      description={showItemsWithNoDateDescription ?? 'Show items with no date'}
      id={`${id}-showitemscbid`}
      data-testid={`${id}-showitemscbid`}
      {...classes({
        extra: `fe_u_margin--top-xsmall`,
      })}
      checked={showNoDateItems}
      onChange={handleShowNoDateItemsChange as unknown as React.ChangeEventHandler<HTMLDivElement>}
    />
  ) : (
    <></>
  );
};

/**
 * DescriptionLabel(props: DescriptionLabelProps): JSX.Element
 *
 * This function creates a Forge Label component for this control.  It is a
 * more detailed description than the component Label and is displayed below
 * the numeric input controls.
 *
 * @param props DescriptionLabelProps
 *
 * @returns JSX.Element if passed properties allow for one.
 */
export const DescriptionLabel = (props: DescriptionLabelProps): JSX.Element => {
  const {
    allowNullValuesCheckBox,
    bannerCount,
    errorAlwaysBelow,
    suppressVerticalSpacing,
    suppressWarningBanner,
    labelText,
  } = props;
  return labelText !== undefined && labelText.length > 0 ? (
    <Label
      text={labelText}
      {...classes({
        element: 'label',
        modifiers:
          suppressVerticalSpacing === true &&
          (errorAlwaysBelow === false || suppressWarningBanner === true || bannerCount === 0) &&
          allowNullValuesCheckBox === false
            ? 'nomargin'
            : 'withmargin',
        extra: 'fe_u_margin--top-xsmall fe_u_flex-align-items--center',
      })}
    />
  ) : (
    <></>
  );
};

/**
 * RangeLabel(props: RangeLabelProps):JSX.Element
 *
 * This function creates a Forge Label for this component.  It is descriptive of its
 * purpose and is displayed to the left of the input controls.
 *
 * @param props RangeLabelProps
 *
 * @returns JSX.Element with a Label if something to display otherwise an empty JSX element.
 */
export const RangeLabel = (props: RangeLabelProps): JSX.Element => {
  const { standalone, id, labelAlwaysAbove, labelText } = props;

  return standalone && labelText !== undefined && labelText.length > 0 ? (
    <Label
      {...classes({
        element: 'label',
        modifiers: 'default',
        extra: `${labelAlwaysAbove ? 'fe_u_margin--bottom-xsmall' : 'fe_u_margin--top-small'} fe_u_margin--right-large`,
      })}
      text={labelText}
      htmlFor={`${id}-start-date-id`}
    />
  ) : (
    <></>
  );
};
