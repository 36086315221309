import { DataTableSortDirection } from '../utils/internalTypes';

type CreateSortAscTextProps<RowData> = {
  rowDataFieldName: keyof RowData;
  caseSensitive: boolean;
};
export function createSortByText<RowData>({ rowDataFieldName, caseSensitive }: CreateSortAscTextProps<RowData>) {
  return (row1: RowData, row2: RowData, direction: DataTableSortDirection): number => {
    let val1 = row1[rowDataFieldName] as string;
    let val2 = row2[rowDataFieldName] as string;

    if (typeof val1 !== 'string' || typeof val2 !== 'string') {
      throw new Error(`The field (${val1}, ${val2}) should be string type.`);
    }

    if (!caseSensitive) {
      val1 = val1.toLowerCase();
      val2 = val2.toLowerCase();
    }

    if (direction === 'asc') {
      // empty string should always be at the bottom of the list
      if (val1 === '') return 1;
      if (val2 === '') return -1;
      // Ascending Logic
      if (val1 > val2) return 1;
      if (val1 < val2) return -1;
    }

    if (direction === 'desc') {
      // empty string should always be at the bottom of the list
      if (val1 === '') return 1;
      if (val2 === '') return -1;
      // Descending Logic
      if (val1 > val2) return -1;
      if (val1 < val2) return 1;
    }

    return 0;
  };
}
