import { CalendarDateTime, Time, ZonedDateTime } from '@internationalized/date';
import { SegmentType } from '@react-stately/datepicker';
import { useControlledState } from '@react-stately/utils';
import { KeyboardEvent as AriaKeyboardEvent } from '@react-types/shared';
import PropTypes from 'prop-types';
import { ReactElement, Ref, WeakValidationMap, useCallback, useContext, useMemo, useRef } from 'react';
import { Provider, type TimeFieldProps, type TimeValue as TimeInputValue } from 'react-aria-components';
import AriaDateInput, { AraDateInputSegmentNodes } from '../AriaDateInput';
import AriaFieldError, { AriaErrorMessage, AriaErrorMessageFunction } from '../AriaFieldError';
import AriaLabel from '../AriaLabel';
import AriaText from '../AriaText';
import AriaTimeField from '../AriaTimeField';
import { FormLayout } from '../Form';
import FormError from '../FormError';
import { FormFieldLayoutContext } from '../FormFieldLayout/FormFieldLayoutContext';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';
import { AriaDateInputContext } from '../AriaDateInput/AriaDateInputContext';

export type { TimeValue as TimeInputValue } from 'react-aria-components';

const classes = forgeClassHelper({ name: 'time-input' });

export type TimeInputValidate<T extends TimeInputValue> = TimeFieldProps<T>['validate'];

const TWELVE_HOUR_CYCLE = 12;
const TWENTY_FOUR_HOUR_CYCLE = 24;
export type HourCycleOptions = typeof TWELVE_HOUR_CYCLE | typeof TWENTY_FOUR_HOUR_CYCLE;

export type TimeInputSize = 'small' | 'medium' | 'large';

export interface TimeInputProps<T extends TimeInputValue = Time>
  extends Omit<TimeFieldProps<T>, 'className' | 'onChange'> {
  /** The className to apply to the outermost element */
  className?: string;
  /** Disables the input */
  disabled?: boolean;
  /** Label applied above the input
   *
   * This is typically accomplished using FormField, but can be used on its own
   */
  label?: string;
  /** The default value (uncontrolled). */
  defaultValue?: T | null;
  /** The description of the input, applied below the input */
  description?: string;
  /** Highlights the input when defined (specific string doesn't matter)
   *
   * This exists for compatibility with FormField. If using independent of
   * FormField, the `validate` prop is more flexible.
   */
  error?: string;
  /** Applies custom formatting for error messages. error messages returned by
   * the `validate` callback function
   */
  formatErrorMessages?: AriaErrorMessage;
  /** Whether to hide styling associated with a required field */
  hideRequiredStyles?: boolean;
  /** The className to apply to the input, as opposed to the outermost element */
  inputClassName?: string;
  /** Called when the value changes
   *
   * Overrides Typescript definition from react-aria, because the react-aria
   * version fails to indicate that the value can be null.
   */
  onChange?: (value: TimeValueOrNull<T | null>) => void;
  /** controls the default values of each segment when the user first interacts
   * with them, e.g. using the up and down arrow keys.
   *
   * Recommend setting this if using as a controlled component using
   * ZonedDateTime or CalendarDateTime, otherwise the onChange value will be
   * a Time object when transitioning from null to a real value */
  placeholderValue?: T;
  /** Whether the input is required */
  required?: boolean;
  /** Whether to display the time in 12 or 24 hour format. By default, this is
   * determined by the user's locale.
   */
  hourCycle?: HourCycleOptions;
  /** ref to the outermost DOM element */
  ref?: Ref<HTMLDivElement>;
  /** How large the input should be */
  size?: TimeInputSize;
  /** Validation callback function. Returned strings are used as error messages */
  validate?: TimeInputValidate<T>;
  /** The value of the input (controlled) */
  value?: T | null;
}
interface TimeInputComponentProps<T extends TimeInputValue> extends TimeInputProps<T> {
  forwardedRef?: Ref<HTMLDivElement>;
}

/** Wraps error messages in <FormError /> */
export const defaultFormatErrorMessages: AriaErrorMessageFunction = (v) =>
  v.validationErrors.map((e) => <FormError key={e}>{e}</FormError>);

/** Reconcile TimeInput's size prop against context supplied by FormField
 *
 * TimeInput size should change in response to FormField's layout prop,
 * unless overridden by TimeInput's size prop
 */
function reconcileSize({
  timeInputSize,
  formFieldLayoutSize,
}: {
  timeInputSize?: TimeInputSize;
  formFieldLayoutSize?: FormLayout;
}): TimeInputSize {
  if (timeInputSize !== undefined) {
    return timeInputSize;
  } else
    switch (formFieldLayoutSize) {
      case 'large':
      case 'medium':
        return 'large';
      case 'compact':
        return 'medium';
      case 'super-compact':
        return 'small';
      default:
        return 'large';
    }
}

/** Reduces T to its base class
 *
 * Adapted from react-aria. It influences the type of the value
 * supplied by `onChange`. Modified to support null values.
 *
 * React-aria's TimePicker claims to extend TimeValue, but in reality its value
 * prop and onChange callback parameter may be null.
 *
 * https://github.com/adobe/react-spectrum/blob/main/packages/%40react-types/datepicker/src/index.d.ts#L131
 */
type TimeValueOrNull<T> = T extends null
  ? null
  : T extends ZonedDateTime
    ? ZonedDateTime
    : T extends CalendarDateTime
      ? CalendarDateTime
      : T extends Time
        ? Time
        : never;

/** Allows data entry of time values */
function TimeInput<T extends TimeInputValue>({
  className,
  children,
  defaultValue = null,
  description,
  disabled = false,
  error,
  formatErrorMessages = defaultFormatErrorMessages,
  forwardedRef,
  hideRequiredStyles,
  hourCycle,
  inputClassName,
  isRequired: upstreamIsRequired,
  label,
  onChange: upstreamOnChange,
  onKeyDown: upstreamOnKeyDown,
  /** There is a risk that `value` has a runtime value of `null`, but is
   * intended to be a CalendarDateTime or ZonedDateTime. In this case, the value
   * provided by onChange will be a Time instead of the intended T type.
   * Given that the type given by onChange typically flows to `value` in a
   * controlled component, the application will already get warned about a
   * Typescript mismatch between the onChange type and the value type.
   *
   * react-aria-components defaults placeholderValue to new Time() internally,
   * so this default value is consistent with the underlying implementation.
   */
  placeholderValue = new Time() as T,
  required = false,
  size: upstreamSize,
  value: upstreamValue,
  ...props
}: TimeInputComponentProps<T>): ReactElement {
  const isRequired = upstreamIsRequired ?? required;
  const isRequiredStyling = isRequired && !hideRequiredStyles;
  const formFieldContext = useContext(FormFieldLayoutContext);
  const size = useMemo(() => {
    return reconcileSize({ timeInputSize: upstreamSize, formFieldLayoutSize: formFieldContext.layout });
  }, [upstreamSize, formFieldContext.layout]);

  const [value, setValue] = useControlledState<T | null, TimeValueOrNull<T> | null>(
    upstreamValue as Exclude<T | null, undefined> | undefined,
    defaultValue as Exclude<T | null, undefined>,
    upstreamOnChange
  );

  /** Ref to the DOM nodes for each SegmentType */
  const refOfSegments = useRef<AraDateInputSegmentNodes>({});
  /** Ref to the last editable SegmentType */
  const lastSegmentTypeRef = useRef<SegmentType>();
  /** Fills in the current time when the user types "n", and sets focus to the
   * last editable segment
   */
  const onKeyDown = useCallback(
    (e: AriaKeyboardEvent): void => {
      upstreamOnKeyDown?.(e);
      if (!e.defaultPrevented) {
        /** Type "n" to get the current time */
        if (e.key === 'n') {
          e.preventDefault();

          /** Set the current time */
          const localTime = new Date();
          const prototypeValue = value ?? defaultValue ?? placeholderValue;
          /**
           * Creates a new value by setting the hour, minute, second, and millisecond
           * from the localTime. The type assertion `as T` is used here because
           * `prototypeValue.set` returns a general object which we are asserting
           * to be of type T, ensuring it conforms to the expected type.
           */
          const newValue = prototypeValue.set({
            hour: localTime.getHours(),
            minute: localTime.getMinutes(),
            second: localTime.getSeconds(),
            millisecond: localTime.getMilliseconds(),
          }) as T;
          setValue(newValue);

          /** Set focus to the last editable segment */
          if (lastSegmentTypeRef.current) {
            refOfSegments.current[lastSegmentTypeRef.current]?.focus();
          }
        }
      }
    },
    [defaultValue, placeholderValue, setValue, upstreamOnKeyDown, value]
  );

  return (
    <Provider
      values={[
        [
          AriaDateInputContext,
          {
            className: inputClassName,
            disabled: disabled,
            isRequiredStyling: isRequiredStyling,
            refOfSegments: refOfSegments,
            size: size,
            lastSegmentTypeRef: lastSegmentTypeRef,
          },
        ],
      ]}
    >
      <AriaTimeField<T>
        {...classes({
          extra: className,
        })}
        defaultValue={defaultValue}
        hourCycle={hourCycle}
        isDisabled={disabled}
        /** Normally, react-aria would use `validate` to determine if there are
         * errors, but the `error` prop needs to short-circuit that, as `validate`
         * is only called when the input is blurred */
        isInvalid={(!!error && !disabled) || undefined}
        isRequired={isRequired}
        /** Aggressive type assertion for two reasons:
         * 1. `onChange` value parameter is never a class that extends from T
         * 2. TimeFieldProps fails to stipulate that `onChange` value may be null
         */
        onChange={setValue as unknown as TimeFieldProps<T>['onChange']}
        onKeyDown={onKeyDown}
        placeholderValue={placeholderValue}
        ref={forwardedRef}
        value={value}
        {...props}
      >
        {children ?? (
          <>
            <AriaLabel>{label}</AriaLabel>
            <AriaDateInput />
            {description && <AriaText slot="description">{description}</AriaText>}
            {!error && <AriaFieldError {...classes('errors')}>{formatErrorMessages}</AriaFieldError>}
          </>
        )}
      </AriaTimeField>
    </Provider>
  );
}

const timeInputPropTypes: WeakValidationMap<TimeInputProps<TimeInputValue> & { '...rest': unknown }> = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Time),
    PropTypes.instanceOf(ZonedDateTime),
    PropTypes.instanceOf(CalendarDateTime),
  ]),
  description: PropTypes.string,
  error: PropTypes.string,
  formatErrorMessages: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  hideRequiredStyles: PropTypes.bool,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
  placeholderValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Time),
    PropTypes.instanceOf(ZonedDateTime),
    PropTypes.instanceOf(CalendarDateTime),
  ]),
  required: PropTypes.bool,
  hourCycle: PropTypes.oneOf([12, 24]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  validate: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Time),
    PropTypes.instanceOf(ZonedDateTime),
    PropTypes.instanceOf(CalendarDateTime),
  ]),
  /** Passthrough props to react-aria-components `<TimeField />`
   *
   * @see https://react-spectrum.adobe.com/react-aria/components/TimeField/
   */
  '...rest': PropTypes.any,
};
TimeInput.propTypes = timeInputPropTypes;

export default forwardRefToProps(TimeInput) as <T extends TimeInputValue = Time>(
  props: TimeInputProps<T>
) => ReactElement;
