import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgReverseCheckLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M14 23.0004C16.3869 23.0004 18.6762 22.052 20.364 20.3641C22.0518 18.6763 23 16.3873 23 14.0004C23 11.6134 22.0518 9.32442 20.364 7.6366C18.6762 5.94877 16.3869 5.00037 14 5.00037H1M1 5.00037L4.99001 1.00037M1 5.00037L5 9.00037M2 16.0004L6 20.0004L14 12.0004"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgReverseCheckLarge.displayName = 'SvgReverseCheckLarge';
const ReverseCheckLarge = withForgeIconProps(SvgReverseCheckLarge, {
  name: 'ReverseCheck',
  usage: "This icon's guidance is pending",
  related: 'N/A',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default ReverseCheckLarge;
