import rawContents from "!!raw-loader!./QuickFilterSortWithPagination.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function QuickFilterSortWithPagination():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Quick Filter Sort With Pagination" />;
}

export default QuickFilterSortWithPagination;
