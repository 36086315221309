import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFeedbackLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1H0V2V17.8776V18.8776H1H4.47689L6.7485 22.1496L6.74846 22.1496L6.75482 22.1585C6.93967 22.4187 7.18408 22.6308 7.46767 22.7772C7.75133 22.9236 8.06592 23 8.38501 23C8.70422 23 9.01868 22.9236 9.30223 22.7772C9.58133 22.6331 9.82248 22.4254 10.0063 22.1709L12.4999 18.8475H19.9997H20C21.0449 18.8477 22.0484 18.4389 22.7958 17.7086C23.5432 16.9782 23.9754 15.9844 23.9997 14.9395L24 14.9278V14.9162V8H22V14.9034C21.9852 15.4223 21.7694 15.9153 21.398 16.2781C21.0243 16.6433 20.5226 16.8477 20.0003 16.8475H20H12H11.5001L11.2001 17.2473L8.40008 20.9791L8.39231 20.9894L8.38499 20.9998L5.82144 17.3073L5.52311 16.8776H5H2V3H17V1H1ZM22.8953 4.56793L20.4524 2.13166L21.3935 1.19318C21.5207 1.06938 21.6914 1 21.8691 1C22.0468 1 22.2174 1.06938 22.3446 1.19318L23.8064 2.65087C23.9306 2.7777 24 2.94788 24 3.12511C24 3.30234 23.9306 3.47252 23.8064 3.59935L22.8953 4.56793ZM15.9172 11.5371L13.3041 11.9963C13.2631 12.0033 13.2209 12.0004 13.1813 11.9878C13.1416 11.9752 13.1057 11.9532 13.0763 11.9239C13.0469 11.8946 13.0249 11.8587 13.0123 11.8192C12.9996 11.7797 12.9967 11.7376 13.0037 11.6967L13.4642 9.09079L15.9172 11.5371ZM14.2521 8.30623L19.8388 2.7368L22.2808 5.173L16.6941 10.7424L14.2521 8.30623Z"
      fill={props.color}
    />
  </svg>
);
SvgFeedbackLarge.displayName = 'SvgFeedbackLarge';
const FeedbackLarge = withForgeIconProps(SvgFeedbackLarge, {
  name: 'Feedback',
  usage:
    'Use to indicate the ability to leave feedback. Can link to an embedded survey',
  related: 'comment, form, survey, write, compose',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default FeedbackLarge;
