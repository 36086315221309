import SearchSmall from '@athena/forge-icons/dist/SearchSmall';
import { ReactElement } from 'react';
import MainNavItems from '../main-nav-items';
import Profile from '../profile';
import SearchBox from '../search-box';
import VersionSwitcher from '../version-switcher/version-switcher';
import VersionsMenu from '../versions-menu';
import createBEMHelper from '../../bem-helper';
import './main-nav.scss';

const classes = createBEMHelper({ name: 'main-nav' });

export default function MainNav({ isHomePage = false }): ReactElement {
  return (
    <div {...classes({ element: 'header' })}>
      <MainNavItems />
      {!isHomePage && (
        <div {...classes({ element: 'search-wrapper' })}>
          <SearchBox icon={SearchSmall} />
        </div>
      )}
      <Profile />
      {process.env.GATSBY_VERSION_SWITCH_URL !== '' && <VersionSwitcher />}
      <VersionsMenu />
    </div>
  );
}
