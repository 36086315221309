import Select from '../../../Select';
import Paginator from '../../../Paginator';
import { DataTableStore } from '../../store/stateTypes';
import { getRowCountFromState } from '../../utils/getRowCountFromState';
import { hasRowsInEditStatusFromState } from '../../utils/hasRowsInEditStatusFromState';
import { DataTableBlockUI } from '../../utils/DataTableBlockUI';
import { classes } from '../../utils/dataTableClasses';

type TablePaginatorProps<RowData> = {
  store: DataTableStore<RowData>;
  insideControlPanel: boolean;
};

export function TablePaginator<RowData>({ store, insideControlPanel }: TablePaginatorProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const tableId = store.useSelector((s) => s.tableId);
  const showLoader = store.useSelector((s) => s.externalLoader.show || s.loader.show);
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));
  const rowCount = store.useSelector((s) => getRowCountFromState(s));
  const {
    layout, // prettier-break-line
    paginatorLocation,
    pageIndex,
    pageSize,
    pageSizeOptions,
  } = store.useSelector((s) => s.pagination);

  /**
   * We return <></> if:
   *    1) The user specifies "bottom-left" but the current render is in the Control Panel
   *    2) The user specifies "top-right" but the current render is NOT in the Control Panel
   * If the user specifies "top-and-bottom", it will render for both the control panel (top-right)
   * and the footer (bottom-right).
   */
  if (
    layout === 'none' ||
    (insideControlPanel && paginatorLocation === 'bottom-left') ||
    (!insideControlPanel && paginatorLocation === 'top-right')
  ) {
    return <></>;
  }

  const pageCount = Math.ceil(rowCount / pageSize);
  const startRowIndex = pageIndex * pageSize;
  const endRowIndex = Math.min(startRowIndex + pageSize, rowCount);
  const pageInfo =
    pageSize > 0
      ? `${startRowIndex + 1}-${endRowIndex} of ${rowCount.toLocaleString()}`
      : `1-${rowCount.toLocaleString()} of ${rowCount.toLocaleString()}`; // show 'All Rows' if pageSize <= 0

  /**
   *  We want to keep the original style of "top-right" when the user specifies "top-and-bottom"
   *  for the paginator on the Control Panel.
   */
  const modifierStyle = insideControlPanel && paginatorLocation === 'top-and-bottom' ? 'top-right' : paginatorLocation;

  return (
    <DataTableBlockUI block={showLoader || hasRowsInEditStatus}>
      <div data-testid="data-table-paginator" {...classes({ element: 'paginator', modifiers: modifierStyle })}>
        {pageSizeOptions.length === 0 ? (
          <span {...classes({ element: 'paginator__page_info' })}>{pageInfo}</span>
        ) : (
          <Select
            placeholder={pageInfo}
            value="" // always show place holder
            options={pageSizeOptions.map((pageSize) => ({
              value: pageSize.toString(),
              label: pageSize > 0 ? `${pageSize} rows` : `Show all ${rowCount.toLocaleString()}`, // show 'All Rows' if pageSize <= 0
            }))}
            onChange={(e) => {
              dispatch(function set_page_size(s) {
                const { newPageSize, newPageIndex } = (() => {
                  if (e.target.value?.value === undefined) {
                    return {
                      newPageSize: 0,
                      newPageIndex: 0,
                    };
                  } else {
                    const value = parseInt(e.target.value.value);
                    return {
                      newPageSize: value,
                      newPageIndex: Math.floor(startRowIndex / value),
                    };
                  }
                })();
                s.pagination.pageSize = s.serverSideFetchRowDataArgs.pageSize = newPageSize;
                s.pagination.pageIndex = s.serverSideFetchRowDataArgs.pageIndex = newPageIndex;
              });
            }}
            hidden={rowCount === 0}
          />
        )}

        <Paginator
          pageInputId={`${tableId}-paginator-${paginatorLocation}`}
          // disable page jump if we are showing all rows or if it's only one page; show 'default' instead
          displayMode={(pageSize <= 0 || pageCount <= 1) && layout === 'withPageJump' ? 'default' : layout}
          pageCount={pageSize <= 0 || rowCount === 0 ? 1 : pageCount} // show 1 page if pageSize <= 0 or rowCount = 0 (no entries)
          selectedIndex={pageSize > 0 ? pageIndex : 0} // show 'All Rows' if pageSize <= 0
          onSelectedIndexChange={(newPageIndex) => {
            dispatch(function set_page_index(s) {
              s.pagination.pageIndex = s.serverSideFetchRowDataArgs.pageIndex = newPageIndex;
            });
          }}
        />
      </div>
    </DataTableBlockUI>
  );
}
