import { createContext } from 'react';

export type FormRequiredVariation = 'blueBarWithRequiredLabel' | 'blueBarWithLegend' | 'allFieldsRequired';
export type FormLayout = 'medium' | 'compact' | 'super-compact' | 'large';
export interface FormContextData {
  labelWidth: number;
  labelAlwaysAbove: boolean;
  layout: FormLayout;
  nested: boolean;
  requiredVariation: FormRequiredVariation;
}

export const defaultFormContext: FormContextData = {
  requiredVariation: 'blueBarWithRequiredLabel',
  layout: 'medium',
  nested: true,
  labelWidth: 3,
  labelAlwaysAbove: false,
};

export const FormContext = createContext(defaultFormContext);
