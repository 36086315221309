import { Fragment, ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/layout';
import TertiaryNav from '../components/tertiary-nav/tertiary-nav';
import Header from '../components/content-blocks/header';
import PageHeader from '../components/page-header';
import IconTable from '../components/icon-table';
import './component-page.scss';
import './icon-page.scss';
import { GatsbyPageContext, PageData, ChildBlock } from '../contexts';

export default function IconPage({
  data,
  pageContext,
  location,
}: PageProps<PageData, GatsbyPageContext>): ReactElement {
  if (data.newNodeInfo && data.iconsSVG && data.iconsMeta) {
    // type: node--non_component_page
    const {
      newNodeInfo: { childrenBlock, title, field_non_component_summary: summary }, // body: { summary, value }
    } = data;

    const blocks = childrenBlock?.map((block: ChildBlock, index: number) => {
      const key = block.id || index;
      if (block.drupal_type === 'paragraph--header') {
        return <Header {...block} key={key} />;
      } else {
        return <Fragment key={key} />;
      }
    });

    return (
      <>
        <Layout pageContext={pageContext} title={title} summary={summary} pageData={data} location={location}>
          <div className="component-flex">
            <div className="content icon-page">
              <PageHeader title={title} summary={summary} />
              <IconTable icons={data.iconsSVG} iconsMeta={data.iconsMeta} />
              {blocks}
            </div>
            <TertiaryNav />
          </div>
        </Layout>
      </>
    );
  } else {
    console.warn(`Could not render component: ${data?.path}`);
    return <></>;
  }
}

export const query = graphql`
  query ($title: String) {
    newNodeInfo: nonComponentPage(title: { eq: $title }) {
      title
      field_non_component_summary
      field_deprecated
      field_deprecated_reason
      childrenBlock {
        id
        field_title
        field_text_body
        field_card_body
        field_card_link
        field_information_body
        field_information_type
        field_don_t_description
        field_do_description
        field_do_image
        field_don_t_image
        field_image
        field_markdown_body
        drupal_type
        childrenBlock {
          id
          field_title
          field_text_body
          field_card_body
          field_card_link
          field_information_body
          field_information_type
          field_don_t_description
          field_do_description
          field_do_image
          field_don_t_image
          field_image
          field_markdown_body
          drupal_type
          childrenBlock {
            id
            field_title
            field_text_body
            field_card_body
            field_card_link
            field_information_body
            field_information_type
            field_don_t_description
            field_do_description
            field_do_image
            field_don_t_image
            field_image
            field_markdown_body
            drupal_type
          }
        }
      }
    }
    iconsSVG: allFile(filter: { sourceInstanceName: { eq: "icons" } }) {
      edges {
        node {
          id
          name
          svg {
            originalContent
          }
          internal {
            mediaType
          }
        }
      }
    }
    iconsMeta: allIconMetadata {
      nodes {
        Related
        Name
        Usage
        id
        Label_Required
        Neutral_Interactive
      }
    }
  }
`;
