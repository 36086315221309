import PushDrawerPersistent from './PushDrawerPersistent.gen';
import PushDrawerHidden from './PushDrawerHidden.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <PushDrawerPersistent />
      <PushDrawerHidden />
    </>
  );
}
