import { useEffect, useState } from 'react';
import Select from '../../../../Select';
import { AwaitableModal } from '../../../alerts/AwaitableModal';
import { DataTableFilter, CreateDataFilterCommonArgs, DataTableValueTextOption } from '../../../utils/internalTypes';

type CreateSelectFilterArgs<RowData> = CreateDataFilterCommonArgs<RowData> & {
  isMulti: boolean;
  fetchOptions: () => Promise<DataTableValueTextOption[]>;
  serverSideQueryParamFn?: (selectedOptions: ReadonlyArray<DataTableValueTextOption>) => string;
};
export type CreateSelectFilter<RowData> = typeof createSelectFilter<RowData>;

export function createSelectFilter<RowData>({
  isMulti,
  filterId,
  filterLabel,
  rowDataFieldName,
  fetchOptions,
  serverSideQueryParamFn,
}: CreateSelectFilterArgs<RowData>): DataTableFilter<RowData, DataTableValueTextOption> {
  return {
    filterId,
    filterLabel,

    serverSideQueryParamFn:
      typeof serverSideQueryParamFn !== 'undefined'
        ? ({ conditionValues }) => serverSideQueryParamFn(conditionValues)
        : undefined,

    clientSideFilterFn: ({ rowData, conditionValues }) => {
      const rowDataValues = rowData[rowDataFieldName] as string | string[];
      const fieldValues = Array.isArray(rowDataValues) ? rowDataValues : [rowDataValues];

      const conditionValueSet = new Set(conditionValues.map((opt) => opt.value));

      for (const fieldValue of fieldValues) {
        if (typeof fieldValue !== 'string') {
          throw new Error(`The filtered field (${String(rowDataFieldName)}) should be string[] type.`);
        }

        if (conditionValueSet.has(fieldValue)) return true;
      }

      return false;
    },

    DataFilterComponent: ({ useSelector, useDispatch, onFilterConditionValuesChange }) => {
      const dispatch = useDispatch();
      const conditionValues = useSelector(
        (s) => s.dataFilter.filters[filterId]?.conditionValues ?? []
      ) as DataTableValueTextOption[];

      // ==============================
      // Fetch the options
      // ==============================
      const [isLoading, setIsLoading] = useState(true);
      const [options, setOptions] = useState<DataTableValueTextOption[]>([]);
      useEffect(() => {
        (async () => {
          try {
            const options = await fetchOptions();
            setOptions(options);
          } catch (e) {
            await AwaitableModal(dispatch, {
              alertType: 'attention',
              headerText: 'Fetch Options Failed',
              children: (e as Error).message,
            });
          }
          setIsLoading(false);
        })();
      }, [dispatch, setIsLoading, setOptions]);

      if (isLoading) {
        return <div>Loading the options...</div>;
      }

      return (
        <Select
          isMulti={isMulti}
          isClearable={true}
          value={conditionValues}
          options={options}
          onChange={(e) => {
            const val = e.target.value;
            const optionValue = (isMulti ? val : val === null ? [] : [val]) as DataTableValueTextOption[];
            onFilterConditionValuesChange(optionValue);
          }}
        />
      );
    },

    describeConditionValue: (conditionValue) => conditionValue.label,
  };
}
