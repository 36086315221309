import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStandardScreenSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 -0.000244141H3H6V1.99976H4V3.99976H2V0.999756V-0.000244141ZM2 14.9998V11.9998H4V13.9998H6V15.9998H3H2V14.9998ZM10 1.99976H12V3.99976H14V0.999756V-0.000244141H13H10V1.99976ZM13 15.9998H10V13.9998H12V11.9998H14V14.9998V15.9998H13ZM7 4.99976H9V9.99976C9 10.552 8.55228 10.9998 8 10.9998H7V4.99976ZM5 2.99976H7H9H11V4.99976V9.99976C11 11.6566 9.65685 12.9998 8 12.9998H7H5V10.9998V4.99976V2.99976Z"
      fill={props.color}
    />
  </svg>
);
SvgStandardScreenSmall.displayName = 'SvgStandardScreenSmall';
const StandardScreenSmall = withForgeIconProps(SvgStandardScreenSmall, {
  name: 'StandardScreen',
  usage: 'Use to change to semi-full screen view',
  related: 'Expand, screenshot, capture',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default StandardScreenSmall;
