import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgChartLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 15.5C7.44772 15.5 7 15.9477 7 16.5C7 17.0523 7.44772 17.5 8 17.5V15.5ZM14 17.5C14.5523 17.5 15 17.0523 15 16.5C15 15.9477 14.5523 15.5 14 15.5V17.5ZM8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14V12ZM16 14C16.5523 14 17 13.5523 17 13C17 12.4477 16.5523 12 16 12V14ZM8 8.5C7.44772 8.5 7 8.94772 7 9.5C7 10.0523 7.44772 10.5 8 10.5V8.5ZM16 10.5C16.5523 10.5 17 10.0523 17 9.5C17 8.94772 16.5523 8.5 16 8.5V10.5ZM15.74 2C15.1877 2 14.74 2.44772 14.74 3C14.74 3.55228 15.1877 4 15.74 4V2ZM20 3H21V2H20V3ZM4 23H3V24H4V23ZM4 3V2H3V3H4ZM8.25 4C8.80228 4 9.25 3.55228 9.25 3C9.25 2.44772 8.80228 2 8.25 2V4ZM15 5V6H16V5H15ZM9 5H8V6H9V5ZM8 17.5H14V15.5H8V17.5ZM8 14H16V12H8V14ZM8 10.5H16V8.5H8V10.5ZM15.74 4H20V2H15.74V4ZM19 3V20H21V3H19ZM19 20C19 20.5305 18.7893 21.0392 18.4142 21.4142L19.8284 22.8284C20.5786 22.0783 21 21.0608 21 20H19ZM18.4142 21.4142C18.0392 21.7893 17.5305 22 17 22V24C18.0608 24 19.0783 23.5786 19.8284 22.8284L18.4142 21.4142ZM17 22H4V24H17V22ZM5 23V3H3V23H5ZM4 4H8.25V2H4V4ZM10 2H14V0H10V2ZM14 2L14 2.00001L15.4142 0.585801C15.0392 0.210754 14.5305 0 14 0V2ZM14 2.00001L14 2H16C16 1.46955 15.7893 0.960864 15.4142 0.585801L14 2.00001ZM14 2V5H16V2H14ZM15 4H9V6H15V4ZM10 5V2H8V5H10ZM10 2L10 2.00001L8.58579 0.585801C8.21072 0.960864 8 1.46955 8 2H10ZM10 2.00001L10 2V0C9.46953 0 8.96083 0.210754 8.58579 0.585801L10 2.00001Z"
      fill={props.color}
    />
  </svg>
);
SvgChartLarge.displayName = 'SvgChartLarge';
const ChartLarge = withForgeIconProps(SvgChartLarge, {
  name: 'Chart',
  usage: 'Use to indicate or open a patient chart',
  related: 'record, patient record',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default ChartLarge;
