import LoaderBasic from './LoaderBasic.gen';
import FixedCenter from './FixedCenter.gen';

export default function Demos(): React.ReactElement {
  return (
    <>
      <LoaderBasic />
      <FixedCenter />
    </>
  );
}
