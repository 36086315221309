import { useId } from '@react-aria/utils';
import React, { useMemo } from 'react';
import {
  DataTableBulkEdit,
  DataTableBulkEditCompleteModalFn,
  DataTableBulkEditCompleteToastFn,
  DataTableBulkEditConfirmModalFn,
  DataTableClickLeftOptions,
  DataTableClientSideEditRowInPlaceFn,
  DataTableClientSideQuickFilterFn,
  DataTableEditTableButton,
  DataTableEntityName,
  DataTableEntityPrefix,
  DataTableFetchCSVFn,
  DataTableFilters,
  DataTableLastDataUpdateTimeInTableTitle,
  DataTableLayout,
  DataTableOnRowClickLeft,
  DataTableOnRowsSaved,
  DataTablePagination,
  DataTablePaginationLayout,
  DataTablePaginationLocation,
  DataTableProps,
  DataTableQuickFilter,
  DataTableQuickFilterLayout,
  DataTableServerSideEditRowsAsyncFn,
  DataTableServerSideFetchRowDataFn,
  DataTableServerSideQuickFilterQueryParamFn,
  DataTableServerSideSaveMultipleRowsFn,
  DataTableType,
  DataTableWrapHeaderStyle,
} from './DataTable';
import { createDataTableInterface } from './createDataTableInterface';
import { createDataTableRenderer } from './createDataTableRenderer';
import {
  DataTableAction,
  DataTableAsyncAction,
  DataTableCustomEmptyStateComponentProps,
  DataTableHooks,
  DataTableState,
} from './store/stateTypes';

// Utils
import { Store, StoreOption, createStore, createUseStoreHook } from '../utils/createStore';
import { getDataTableInitialState } from './store/state';
import {
  DataTableAlertFunctions,
  DataTableColumn,
  DataTableDataValidator,
  DataTableGetTextByRow,
  DataTableHooksAndRowId,
  DataTableSingleEditCompleteModalFn,
  DataTableSingleEditCompleteToastFn,
  DataTableSingleEditConfirmModalFn,
  DataTableValueTextOption,
} from './utils/internalTypes';

// Actions
import { apply_client_side_filters } from './store/actions/apply_client_side_filters';
import { apply_server_side_filters } from './store/actions/apply_server_side_filters';
import { deselect_all_rows } from './store/actions/deselect_all_rows';
import { filter_by_column_filters } from './store/actions/filter_by_column_filters';
import { inline_edit_row } from './store/actions/inline_edit_row';
import { refresh_row_data } from './store/actions/refresh_row_data';
import { reset_column_filters } from './store/actions/reset_column_filters';
import { reset_data_filters } from './store/actions/reset_date_filters';
import { reset_paginator } from './store/actions/reset_paginator';
import { reset_quick_filters } from './store/actions/reset_quick_filters';
import { validate_row_data } from './store/actions/validate_row_data';

// Expose Filter Creators
import { CreateDateInputFilter, createDateInputFilter } from './filters/DataFilters/DateInputFilter/createDateInputFilter'; // prettier-ignore
import { CreateDateRangeInputFilter, createDateRangeInputFilter } from './filters/DataFilters/DateRangeInputFilter/createDateRangeInputFilter'; // prettier-ignore
import { CreateSelectFilter, createSelectFilter } from './filters/DataFilters/SelectFilter/createSelectFilter'; // prettier-ignore
import { CreateNumericInputFilter, createNumericInputFilter } from './filters/DataFilters/NumericInputFilter/createNumericInputFilter'; // prettier-ignore
import { CreateNumericRangeInputFilter, createNumericRangeInputFilter } from './filters/DataFilters/NumericRangeInputFilter/createNumericRangeInputFilter'; // prettier-ignore
import { CreateRadioGroupFilter, createRadioGroupFilter } from './filters/DataFilters/RadioGroupFilter/createRadioGroupFilter'; // prettier-ignore
import { CreateTextInputFilter, createTextInputFilter } from './filters/DataFilters/TextInputFilter/createTextInputFilter'; // prettier-ignore
import { CreateSegmentedButtonFilter, createSegmentedButtonFilter } from './filters/DataFilters/SegmentedButtonFilter/createSegmentedButtonFilter'; // prettier-ignore

// Expose Column Filter Creators
import { CreateSelectColumnFilter, createSelectColumnFilter } from './filters/ColumnFilters/SelectColumnFilter/createSelectColumnFilter'; // prettier-ignore
import { CreateSelectionListColumnFilter, createSelectionListColumnFilter } from './filters/ColumnFilters/SelectionListColumnFilter/createSelectionListColumnFilter'; // prettier-ignore

// Expose Column Creators
import { CreateCurrencyColumn, createCurrencyColumn } from './columns/CurrencyColumn/createCurrencyColumn';
import { CreateDateInputColumn, createDateInputColumn } from './columns/DateInputColumn/createDateInputColumn';
import { CreateDateStringColumn, createDateStringColumn } from './columns/DateStringColumn/createDateStringColumn';
import { CreateIconColumn, createIconColumn } from './columns/IconColumn/createIconColumn';
import { CreateIdColumn, createIdColumn } from './columns/IdColumn/createIdColumn';
import { CreateLongTextColumn, createLongTextColumn } from './columns/LongTextColumn/createLongTextColumn';
import { CreateNumericColumn, createNumericColumn } from './columns/NumericColumn/createNumericColumn';
import { CreatePercentColumn, createPercentColumn } from './columns/PercentColumn/createPercentColumn';
import { CreateRadioGroupColumn, createRadioGroupColumn } from './columns/RadioGroupColumn/createRadioGroupColumn';
import { CreateSelectColumn, createSelectColumn } from './columns/SelectColumn/createSelectColumn'; // prettier-ignore
import { CreateTagColumn, createTagColumn } from './columns/TagColumn/createTagColumn';
import { CreateTextColumn, createTextColumn } from './columns/TextColumn/createTextColumn';

// Expose Row Function Column Creators
import { CreateRowActionColumn, createRowActionColumn } from './rowFunctionColumns/RowActionColumn/createRowActionColumn'; // prettier-ignore
import { CreateRowAlertColumn, createRowAlertColumn } from './rowFunctionColumns/RowAlertColumn/createRowAlertColumn';
import { CreateRowExpandArrowColumn, createRowExpandArrowColumn } from './rowFunctionColumns/RowExpandArrowColumn/createRowExpandArrowColumn'; // prettier-ignore
import { CreateRowSelectColumn, createRowSelectColumn } from './rowFunctionColumns/RowSelectColumn/createRowSelectColumn'; // prettier-ignore

// Expose Row Action Button Creators
import { CreateDeleteRowActionButton, createDeleteRowActionButton } from './rowActionButtons/createDeleteRowActionButton'; // prettier-ignore
import { CreateInlineEditRowActionButton, createInlineEditRowActionButton } from './rowActionButtons/createInlineEditRowActionButton'; // prettier-ignore
import { CreateRestoreRowActionButton, createRestoreRowActionButton } from './rowActionButtons/createRestoreRowActionButton'; // prettier-ignore
import { CreateSaveCancelRowActionButton, createSaveCancelRowActionButton } from './rowActionButtons/createSaveCancelRowActionButton'; // prettier-ignore

// Expose Context Menu Component Creators
import { ContextMenu, ContextMenuItemProps, createContextMenu } from './contextMenu/createContextMenu';

// Alerts
import { AwaitableModal, AwaitableModalType } from './alerts/AwaitableModal';

// ====================================
// Types
// ====================================
export type CreateDataTableArgs<RowData> = {
  tableId: string;
  onDispatch?: StoreOption<DataTableState<RowData>>['onDispatch'];
};
export type DataTable<RowData> = Omit<Store<DataTableState<RowData>>, 'inferTypes'> & {
  /**
   * `DT.DataTableRenderer` is a component to render the DataTable UI based on
   * the the current state. It has no any props.
   *
   * This serves as a lower-level component designed to facilitate advanced DataTable features.
   * It is necessary to set it up with the `DT.Provider` and `DT.DataTableRenderer` components.
   *
   * More details: https://athenaconfluence.athenahealth.com/x/lJEoIg
   */
  DataTableRenderer: () => JSX.Element;

  /**
   * `DT.DataTableInterface` is a props receiver component. It doesn't have UI, but only has many
   * `useEffects` to detect the props changed and dispatch properly actions to the store.
   *
   * This serves as a lower-level component designed to facilitate advanced DataTable features.
   * It is necessary to set it up with the `DT.Provider` and `DT.DataTableInterface` components.
   *
   * More details: https://athenaconfluence.athenahealth.com/x/lJEoIg
   */
  DataTableInterface: (props: DataTableProps<RowData>) => JSX.Element;

  /**
   * A DataTable component
   *
   * - DataTable is suitable for the small-to-medium level react app or you want to wrap the `DataTable` in another component.
   * - It is recommended to start with the `DT.DataTable` when there are no performance concerns.
   * - If you get performance issues, use `DT.SingletonDataTable` instead.
   *
   * For example:
   * ```tsx
   * const DT = createDataTable<RowData>({ tableId: 'xxx' });
   *
   * // DataTable instance 1
   * <DT.DataTable
   *   tableType="client-side"
   *   rowData={rowData1}
   *   columns={columns1}
   * />
   *
   * // DataTable instance 2
   * <DT.DataTable
   *   tableType="client-side"
   *   rowData={rowData2}
   *   columns={columns2}
   * />
   * ```
   */
  DataTable: (props: DataTableProps<RowData>) => JSX.Element;

  /**
   * A Singleton DataTable component implemented with the context API
   *
   * - SingletonDataTable is a performant component and only re-render the essential sub-component.
   * - SingletonDataTable is recommended to use if you only need one DataTable instance created from createDataTable()
   * - SingletonDataTable serves as a high-level DataTable performant component wrapping the `DT.Provider`, `DT.DataTableInterface`, and `DT.DataTableRenderer` components.
   * - More details: https://athenaconfluence.athenahealth.com/x/lJEoIg
   *
   * For example:
   * ```tsx
   * const DT1 = createDataTable<RowData>({ tableId: 'xxx' });
   * const DT2 = createDataTable<RowData>({ tableId: 'yyy' });
   *
   * <DT1.SingletonDataTable
   *   tableType="client-side"
   *   rowData={rowData}
   *   columns={columns}
   * />
   *
   * <DT2.SingletonDataTable
   *   tableType="client-side"
   *   rowData={rowData}
   *   columns={columns}
   * />
   * ```
   */
  SingletonDataTable: (props: DataTableProps<RowData>) => JSX.Element;

  /**
   * Get Blank DataTable State
   *
   * - This is useful when you want to set the default value for useState() to prevent undefined
   *
   * For example:
   * ```tsx
   * const [dataTableState, setDataTableState] = useState(DT.getBlankDataTableState());
   * console.log(dataTableState);
   *
   * <DT.DataTable
   *   tableType="client-side"
   *   rowData={rowData}
   *   columns={columns}
   *   onStateChanged={setDataTableState}
   * />
   * ```
   */
  getBlankDataTableState: () => DataTableState<RowData>;

  /**
   * `Type` is the exposed types for user to create the custom components.
   *
   * Instead of adding generic type everywhere in the code, `createDataTable` generated types
   * for you which are embedded with `RowData`.
   *
   * Example:
   * ```
   * // The type definition of the Row Data
   * type Patient = {
   *   firstName: string;
   *   lastName: string;
   * }
   *
   * // Create data table with the row type information.
   * const DT = createDataTable<Patient>({ tableId: 'patient-table' });
   *
   * // This is the type that has included type information of the `Patient` type.
   * type ExpandedTableRow = typeof DT.Type.ExpandedTableRow;
   *
   * const CustomExpandedTableRow: ExpandedTableRow = ({ useSelector, rowId }) => {
   *   // TypeScript understands the `firstName` and `lastName` fields from `Patient`!
   *   const firstName = useSelector((s) => s.rows[rowId].data.firstName);
   *   const lastName = useSelector((s) => s.rows[rowId].data.lastName);
   *   ...
   * }
   * ```
   *
   * This type inference design is inspired by [Zod](https://github.com/colinhacks/zod) and [ArkType](https://github.com/arktypeio/arktype).
   *
   * - Zod's example:
   *   ```ts
   *   const User = z.object({ username: z.string() });
   *   type User = z.infer<typeof User>;
   *   // { username: string }
   *   ```
   *
   * - ArkType's example:
   *   ```ts
   *   const user = type({ username: "string" });
   *   type User = typeof user.infer;
   *   // { username: string }
   *   ```
   */
  Type: {
    AddRowComponent: React.ComponentType<DataTableHooks<RowData>>;
    AlertFunctions: DataTableAlertFunctions<RowData>;
    BulkEdit: DataTableBulkEdit<RowData>;
    BulkEditCompleteModalFn: DataTableBulkEditCompleteModalFn;
    BulkEditCompleteToastFn: DataTableBulkEditCompleteToastFn;
    BulkEditComponent: React.ComponentType<DataTableHooks<RowData>>;
    BulkEditConfirmModalFn: DataTableBulkEditConfirmModalFn;
    ClickLeftOptions: DataTableClickLeftOptions;
    ClientSideEditRowInPlaceFn: DataTableClientSideEditRowInPlaceFn<RowData>;
    ClientSideQuickFilterFn: DataTableClientSideQuickFilterFn<RowData>;
    Column: DataTableColumn<RowData>;
    ContextMenuItemProps: ContextMenuItemProps<RowData>;
    CustomEmptyStateComponentProps: DataTableCustomEmptyStateComponentProps<RowData>;
    Action: DataTableAction<RowData>;
    AsyncAction: DataTableAsyncAction<RowData>;
    State: DataTableState<RowData>;
    DataValidator: DataTableDataValidator<RowData>;
    EditTableButton: DataTableEditTableButton<RowData>;
    EmptyStateComponent: React.ComponentType<DataTableCustomEmptyStateComponentProps<RowData>>;
    EntityName: DataTableEntityName;
    EntityPrefix: DataTableEntityPrefix;
    ExpandedTableRow: React.ComponentType<DataTableHooksAndRowId<RowData>>;
    FetchCSVFn: DataTableFetchCSVFn;
    Filters: DataTableFilters<RowData>;
    GetTextByRow: DataTableGetTextByRow<RowData>;
    Hooks: DataTableHooks<RowData>;
    HooksAndRowId: DataTableHooksAndRowId<RowData>;
    LastDataUpdateTimeInTableTitle: DataTableLastDataUpdateTimeInTableTitle;
    Layout: DataTableLayout;
    OnRowClickLeft: DataTableOnRowClickLeft<RowData>;
    OnRowsSaved: DataTableOnRowsSaved<RowData>;
    Pagination: DataTablePagination<RowData>;
    PaginationLayout: DataTablePaginationLayout;
    PaginationLocation: DataTablePaginationLocation;
    Props: DataTableProps<RowData>;
    QuickFilter: DataTableQuickFilter<RowData>;
    QuickFilterLayout: DataTableQuickFilterLayout;
    RowActionButton: React.ComponentType<DataTableHooksAndRowId<RowData>>;
    ServerSideEditRowsAsyncFn: DataTableServerSideEditRowsAsyncFn<RowData>;
    ServerSideFetchRowDataFn: DataTableServerSideFetchRowDataFn<RowData>;
    ServerSideQuickFilterQueryParamFn: DataTableServerSideQuickFilterQueryParamFn<RowData>;
    ServerSideSaveMultipleRowsFn: DataTableServerSideSaveMultipleRowsFn<RowData>;
    SingleEditCompleteModalFn: DataTableSingleEditCompleteModalFn<RowData>;
    SingleEditCompleteToastFn: DataTableSingleEditCompleteToastFn<RowData>;
    SingleEditConfirmModalFn: DataTableSingleEditConfirmModalFn<RowData>;
    Type: DataTableType;
    ValueTextOption: DataTableValueTextOption;
    WrapHeaderStyle: DataTableWrapHeaderStyle;
  };

  // ================================
  // Actions
  // ================================
  Action: {
    apply_client_side_filters: DataTableAction<RowData>;
    apply_server_side_filters: DataTableAction<RowData>;
    deselect_all_rows: DataTableAction<RowData>;
    filter_by_column_filters: DataTableAction<RowData>;
    inline_edit_row: (rowId: number) => DataTableAction<RowData>;
    refresh_row_data: (data: RowData[]) => DataTableAction<RowData>;
    reset_column_filters: DataTableAction<RowData>;
    reset_data_filters: DataTableAction<RowData>;
    reset_paginator: DataTableAction<RowData>;
    reset_quick_filters: DataTableAction<RowData>;
    validate_row_data: (rowId: number) => DataTableAction<RowData>;
  };

  // ================================
  // Filter Creators
  // ================================
  Filter: {
    createDateInputFilter: CreateDateInputFilter<RowData>;
    createDateRangeInputFilter: CreateDateRangeInputFilter<RowData>;
    createSelectFilter: CreateSelectFilter<RowData>;
    createNumericInputFilter: CreateNumericInputFilter<RowData>;
    createNumericRangeInputFilter: CreateNumericRangeInputFilter<RowData>;
    createRadioGroupFilter: CreateRadioGroupFilter<RowData>;
    createTextInputFilter: CreateTextInputFilter<RowData>;
    createSegmentedButtonFilter: CreateSegmentedButtonFilter<RowData>;
  };

  // ================================
  // Column Creators
  // ================================
  Column: {
    // Data Column
    createCurrencyColumn: CreateCurrencyColumn<RowData>;
    createDateInputColumn: CreateDateInputColumn<RowData>;
    createDateStringColumn: CreateDateStringColumn<RowData>;
    createIconColumn: CreateIconColumn<RowData>;
    createIdColumn: CreateIdColumn<RowData>;
    createLongTextColumn: CreateLongTextColumn<RowData>;
    createNumericColumn: CreateNumericColumn<RowData>;
    createPercentColumn: CreatePercentColumn<RowData>;
    createRadioGroupColumn: CreateRadioGroupColumn<RowData>;
    createSelectColumn: CreateSelectColumn<RowData>;
    createTagColumn: CreateTagColumn<RowData>;
    createTextColumn: CreateTextColumn<RowData>;

    // Row Functions
    createRowActionColumn: CreateRowActionColumn<RowData>;
    createRowAlertColumn: CreateRowAlertColumn<RowData>;
    createRowSelectColumn: CreateRowSelectColumn<RowData>;
    createRowExpandArrowColumn: CreateRowExpandArrowColumn<RowData>;
  };

  // ================================
  // Column Filter Creators
  // ================================
  ColumnFilter: {
    createSelectionListColumnFilter: CreateSelectionListColumnFilter<RowData>;
    createSelectColumnFilter: CreateSelectColumnFilter<RowData>;
  };

  // ================================
  // Row Action Button Creators
  // ================================
  RowActionButton: {
    createInlineEditRowActionButton: CreateInlineEditRowActionButton<RowData>;
    createDeleteRowActionButton: CreateDeleteRowActionButton<RowData>;
    createSaveCancelRowActionButton: CreateSaveCancelRowActionButton<RowData>;
    createRestoreRowActionButton: CreateRestoreRowActionButton<RowData>;
  };

  // ================================
  // Custom Context Menu Creator
  // ================================
  /**
   * Use components under the `DT.ContextMenu.*` to create a custom context menu
   *
   * - `DT.ContextMenu.Menu`
   * - `DT.ContextMenu.Item`
   * - `DT.ContextMenu.InlineEditRowActionItem`
   * - `DT.ContextMenu.DeleteRowActionItem`
   * - `DT.ContextMenu.RestoreRowActionItem`
   * - `DT.ContextMenu.Title`
   * - `DT.ContextMenu.Separator`
   * - `DT.ContextMenu.Submenu`
   */
  ContextMenu: ContextMenu<RowData>;

  // ================================
  // Awaitable Modal
  // ================================
  AwaitableModal: AwaitableModalType<RowData>;
};

export function createDataTable<
  RowData = 'The generic type is required when using the createDataTable<T>() function.',
>({ tableId, onDispatch }: CreateDataTableArgs<RowData>): DataTable<RowData> {
  // ================================
  // Non-context-based DataTable
  // ================================
  const DataTable = React.forwardRef(function DataTable(
    props: Omit<DataTableProps<RowData>, 'ref'>,
    ref: DataTableProps<RowData>['ref']
  ): JSX.Element {
    const tableIdWithUuid = `${tableId}--${useId()}`;
    const useStore = useMemo(() => {
      return createUseStoreHook({
        onDispatch,
        initialState: getDataTableInitialState<RowData>({ tableId: tableIdWithUuid }),
      });
    }, [tableIdWithUuid]);

    const { store } = useStore();
    const DataTableInterface = useMemo(() => createDataTableInterface(store), [store]);
    const DataTableRenderer = useMemo(() => createDataTableRenderer(store, tableIdWithUuid), [store, tableIdWithUuid]);
    return (
      <>
        <DataTableInterface ref={ref} {...props} />
        <DataTableRenderer />
      </>
    );
  }) as DataTable<RowData>['DataTable'];

  // ================================
  // Context-based DataTable
  // ================================
  const TableStore = createStore({
    onDispatch,
    initialState: getDataTableInitialState<RowData>({ tableId }),
  });
  const DataTableInterface = createDataTableInterface(TableStore);
  const DataTableRenderer = createDataTableRenderer(TableStore, tableId);
  const DataTableRendererMemo = React.memo(DataTableRenderer);
  const SingletonDataTable = React.forwardRef(function SingletonDataTable(
    props: Omit<DataTableProps<RowData>, 'ref'>,
    ref: DataTableProps<RowData>['ref']
  ): JSX.Element {
    return (
      <TableStore.Provider>
        <DataTableInterface ref={ref} {...props} />
        <DataTableRendererMemo />
      </TableStore.Provider>
    );
  }) as DataTable<RowData>['SingletonDataTable'];

  return {
    // ================================
    // TableStore
    // ================================
    Provider: TableStore.Provider,
    useSelector: TableStore.useSelector,
    useDispatch: TableStore.useDispatch,
    useDispatchAsyncAction: TableStore.useDispatchAsyncAction,
    testUtils: TableStore.testUtils,

    // ================================
    // Components
    // ================================
    DataTableInterface,
    DataTableRenderer,
    DataTable, // SingletonDataTable is the default DataTable prior to Forge 12.12.0 and Forge 16.0.0
    SingletonDataTable,

    // ================================
    // Utils
    // ================================
    getBlankDataTableState: () => getDataTableInitialState({ tableId: '' }),

    // Set values to `undefined` because `Type` is not used in runtime.
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    Type: {
      AddRowComponent: undefined!,
      AlertFunctions: undefined!,
      BulkEdit: undefined!,
      BulkEditCompleteModalFn: undefined!,
      BulkEditCompleteToastFn: undefined!,
      BulkEditComponent: undefined!,
      BulkEditConfirmModalFn: undefined!,
      ClickLeftOptions: undefined!,
      ClientSideEditRowInPlaceFn: undefined!,
      ClientSideQuickFilterFn: undefined!,
      Column: undefined!,
      ContextMenuItemProps: undefined!,
      CustomEmptyStateComponentProps: undefined!,
      Action: undefined!,
      AsyncAction: undefined!,
      State: undefined!,
      DataValidator: undefined!,
      EditTableButton: undefined!,
      EmptyStateComponent: undefined!,
      EntityName: undefined!,
      EntityPrefix: undefined!,
      ExpandedTableRow: undefined!,
      FetchCSVFn: undefined!,
      Filters: undefined!,
      GetTextByRow: undefined!,
      Hooks: undefined!,
      HooksAndRowId: undefined!,
      LastDataUpdateTimeInTableTitle: undefined!,
      Layout: undefined!,
      OnRowClickLeft: undefined!,
      OnRowsSaved: undefined!,
      Pagination: undefined!,
      PaginationLayout: undefined!,
      PaginationLocation: undefined!,
      Props: undefined!,
      QuickFilter: undefined!,
      QuickFilterLayout: undefined!,
      RowActionButton: undefined!,
      ServerSideEditRowsAsyncFn: undefined!,
      ServerSideFetchRowDataFn: undefined!,
      ServerSideQuickFilterQueryParamFn: undefined!,
      ServerSideSaveMultipleRowsFn: undefined!,
      SingleEditCompleteModalFn: undefined!,
      SingleEditCompleteToastFn: undefined!,
      SingleEditConfirmModalFn: undefined!,
      Type: undefined!,
      ValueTextOption: undefined!,
      WrapHeaderStyle: undefined!,
    },
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    // ================================
    // Actions
    // ================================
    Action: {
      apply_client_side_filters,
      apply_server_side_filters,
      deselect_all_rows,
      filter_by_column_filters,
      inline_edit_row,
      refresh_row_data,
      reset_column_filters,
      reset_data_filters,
      reset_paginator,
      reset_quick_filters,
      validate_row_data,
    },

    // ================================
    // Filter Creators
    // ================================
    Filter: {
      createDateInputFilter: createDateInputFilter<RowData>,
      createDateRangeInputFilter: createDateRangeInputFilter<RowData>,
      createSelectFilter: createSelectFilter<RowData>,
      createNumericInputFilter: createNumericInputFilter<RowData>,
      createNumericRangeInputFilter: createNumericRangeInputFilter<RowData>,
      createRadioGroupFilter: createRadioGroupFilter<RowData>,
      createTextInputFilter: createTextInputFilter<RowData>,
      createSegmentedButtonFilter: createSegmentedButtonFilter<RowData>,
    },

    // ================================
    // Column Creators
    // ================================
    Column: {
      // Data Column
      createCurrencyColumn: createCurrencyColumn<RowData>,
      createDateInputColumn: createDateInputColumn<RowData>,
      createDateStringColumn: createDateStringColumn<RowData>,
      createIconColumn: createIconColumn<RowData>,
      createIdColumn: createIdColumn<RowData>,
      createLongTextColumn: createLongTextColumn<RowData>,
      createNumericColumn: createNumericColumn<RowData>,
      createPercentColumn: createPercentColumn<RowData>,
      createRadioGroupColumn: createRadioGroupColumn<RowData>,
      createSelectColumn: createSelectColumn<RowData>,
      createTagColumn: createTagColumn<RowData>,
      createTextColumn: createTextColumn<RowData>,

      // Row Functions
      createRowActionColumn: createRowActionColumn<RowData>,
      createRowAlertColumn: createRowAlertColumn<RowData>,
      createRowSelectColumn: createRowSelectColumn<RowData>,
      createRowExpandArrowColumn: createRowExpandArrowColumn<RowData>,
    },

    // ================================
    // Column Filter Creators
    // ================================
    ColumnFilter: {
      createSelectionListColumnFilter: createSelectionListColumnFilter<RowData>,
      createSelectColumnFilter: createSelectColumnFilter<RowData>,
    },

    // ================================
    // Row Action Button Creators
    // ================================
    RowActionButton: {
      createInlineEditRowActionButton: createInlineEditRowActionButton<RowData>,
      createDeleteRowActionButton: createDeleteRowActionButton<RowData>,
      createSaveCancelRowActionButton: createSaveCancelRowActionButton<RowData>,
      createRestoreRowActionButton: createRestoreRowActionButton<RowData>,
    },

    // ================================
    // Custom Context Menu Creator
    // ================================
    ContextMenu: createContextMenu(TableStore),

    // ================================
    // Awaitable Modal
    // ================================
    AwaitableModal,
  };
}
