import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDocumentLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20 8.75H21V8.32646L20.6958 8.03176L20 8.75ZM20 18.29L21 18.3005V18.29H20ZM15.2 23L15.2084 22H15.2V23ZM4 23H3V24H4V23ZM4 1V0H3V1H4ZM12 1L12.6958 0.28176L12.4049 0H12V1ZM20 9.85999C20.5523 9.85999 21 9.41227 21 8.85999C21 8.3077 20.5523 7.85999 20 7.85999V9.85999ZM12 8.85999H11C11 9.41227 11.4477 9.85999 12 9.85999V8.85999ZM19 8.75V18.29H21V8.75H19ZM19.0001 18.2796C18.9896 19.2754 18.5846 20.2264 17.8737 20.9239L19.2745 22.3515C20.3634 21.2829 20.984 19.8261 20.9999 18.3005L19.0001 18.2796ZM17.8737 20.9239C17.1629 21.6214 16.2043 22.0085 15.2084 22L15.1915 24C16.7171 24.0129 18.1855 23.42 19.2745 22.3515L17.8737 20.9239ZM15.2 22H4V24H15.2V22ZM5 23V1H3V23H5ZM4 2H12V0H4V2ZM11.3042 1.71824L19.3042 9.46824L20.6958 8.03176L12.6958 0.28176L11.3042 1.71824ZM20 7.85999H12V9.85999H20V7.85999ZM13 8.85999V1H11V8.85999H13Z"
      fill={props.color}
    />
  </svg>
);
SvgDocumentLarge.displayName = 'SvgDocumentLarge';
const DocumentLarge = withForgeIconProps(SvgDocumentLarge, {
  name: 'Document',
  usage: 'Use to indicate or add a page or document',
  related: 'chart, note, paper, file, attachment',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default DocumentLarge;
