import { PageProps } from 'gatsby';
import { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../components/layout';
// eslint-disable-next-line import/no-webpack-loader-syntax
import changelogContents from '!!raw-loader!@athena/forge/CHANGELOG.md';

export default function ReleaseNotesPage({ pageContext }: PageProps): ReactElement {
  return (
    <Layout pageContext={pageContext} title={'Release Notes'} legacy={true}>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{changelogContents}</ReactMarkdown>
    </Layout>
  );
}
