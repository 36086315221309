import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCriticalSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.39862 3.50418L1.39646 10.5077C1.13542 10.9639 0.998662 11.4807 1.00001 12.0063C1.00136 12.5319 1.14078 13.0478 1.40416 13.5027C1.66754 13.9575 2.04576 14.3352 2.50094 14.5979C2.95612 14.8607 3.47225 14.9993 3.99784 15H12.0022C12.5277 14.9993 13.044 14.8607 13.4992 14.5979C13.9544 14.3352 14.3325 13.9575 14.5958 13.5027C14.8592 13.0478 14.9986 12.5319 15 12.0063C15.0013 11.4807 14.8646 10.9639 14.6035 10.5077L10.6014 3.50418C10.3382 3.04699 9.95922 2.66734 9.50252 2.40328C9.04582 2.13922 8.52755 2 8 2C7.47245 2 6.95418 2.13922 6.49748 2.40328C6.04078 2.66734 5.66181 3.04699 5.39862 3.50418Z"
      fill="#CA0D0D"
      stroke="#CA0D0D"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M8 5V10"
      stroke="white"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M8 14C8.55228 14 9 13.5523 9 13C9 12.4477 8.55228 12 8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14Z"
      fill="white"
    />
  </svg>
);
SvgCriticalSmall.displayName = 'SvgCriticalSmall';
const CriticalSmall = withForgeIconProps(SvgCriticalSmall, {
  name: 'Critical',
  usage:
    'Use only to call attention to patient safety or critical business issues. For use in alerts, not buttons; follow Forge interruption design guidance',
  related: 'alert, warning',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default CriticalSmall;
