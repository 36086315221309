import rawContents from "!!raw-loader!./ComplexOptions.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ComplexOptions():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Complex Options" />;
}

export default ComplexOptions;
