import { DataTableAsyncAction, DataTableState } from '../stateTypes';
import { AwaitableModal } from '../../alerts/AwaitableModal';
import { deselect_all_rows } from '../actions/deselect_all_rows';

type ApplyBulkEditAsyncArgs<RowData> = {
  tableType: DataTableState<RowData>['tableType'];
  rows: DataTableState<RowData>['rows'];
  bulkEdit: DataTableState<RowData>['bulkEdit'];
  selectedRowIds: number[];
  selectedBulkEditOptionIndex: number;
};

export function apply_bulk_edit_async<RowData>({
  tableType,
  rows,
  bulkEdit,
  selectedRowIds,
  selectedBulkEditOptionIndex,
}: ApplyBulkEditAsyncArgs<RowData>): DataTableAsyncAction<RowData> {
  return async (dispatch) => {
    const bulkOption = bulkEdit.options[selectedBulkEditOptionIndex];

    // ========================
    // Confirm Modal
    // ========================
    if (typeof bulkEdit.confirmModal !== 'undefined') {
      const { title, message } = bulkEdit.confirmModal(bulkOption.name, selectedRowIds.length);
      const result = await AwaitableModal(dispatch, {
        alertType: 'attention',
        headerText: title,
        children: message,
      });
      if (result === 'secondary') return;
    }

    try {
      // ========================
      // Client-Side Bulk Edit Rows
      // ========================
      if (tableType === 'client-side') {
        // Check clientSideEditRowInPlaceFn
        if (typeof bulkOption.clientSideEditRowInPlaceFn === 'undefined') {
          throw new Error('Missing clientSideEditRowInPlaceFn for client-side bulk edit');
        }

        dispatch(function client_side_bulk_edit(s) {
          s.filteredAndSortedRowIds
            .filter((rid) => s.rows[rid].isSelected)
            .forEach((rid) => bulkOption.clientSideEditRowInPlaceFn?.(s.rows[rid].data));

          // deselect all rows after apply bulk edit
          deselect_all_rows(s);
        });
      }

      // ========================
      // Server-Side Bulk Edit Rows
      // ========================
      else if (tableType === 'server-side') {
        // Check serverSideEditRowsAsyncFn
        if (typeof bulkOption.serverSideEditRowsAsyncFn === 'undefined') {
          throw new Error('Missing serverSideEditRowsAsyncFn for server-side bulk edit');
        }

        dispatch(function before_server_side_bulk_edit(s) {
          s.loader.show = true;
        });

        const selectedRowData = selectedRowIds.map((rid) => rows[rid].data);
        await bulkOption.serverSideEditRowsAsyncFn(selectedRowData);

        dispatch(function after_server_side_bulk_edit_success(s) {
          deselect_all_rows(s); // deselect all rows
          s.forceFetchRowDataCounter++; // force refreshing data
        });
      }

      // ========================
      // Complete Modal
      // ========================
      if (typeof bulkEdit.completeModal !== 'undefined') {
        const { alertType, title, message } = bulkEdit.completeModal(bulkOption.name, selectedRowIds.length);
        await AwaitableModal(dispatch, { alertType, headerText: title, children: message });
      }

      // ========================
      // Complete Toast
      // ========================
      if (typeof bulkEdit.completeToast !== 'undefined') {
        const { alertType, title, message } = bulkEdit.completeToast(bulkOption.name, selectedRowIds.length);
        dispatch(function push_toast_stack(s) {
          s.toastStack.push({ alertType, headerText: title, children: message });
        });
      }
    } catch (e) {
      dispatch(function after_server_side_bulk_edit_failure(s) {
        s.loader.show = false;
      });

      // ========================
      // Error Modal
      // ========================
      await AwaitableModal(dispatch, {
        alertType: 'attention',
        headerText: 'Bulk Edit Failed',
        children: (e as Error).message,
      });
    }
  };
}
