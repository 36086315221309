import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStartScreenShareLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1.99939H0V2.99939V16.4994V17.4994H1H10.9997V19.9994H7C6.44772 19.9994 6 20.4471 6 20.9994C6 21.5517 6.44772 21.9994 7 21.9994H11.9997H17C17.5523 21.9994 18 21.5517 18 20.9994C18 20.4471 17.5523 19.9994 17 19.9994H12.9997V17.4994H19.9994C22.2085 17.4994 23.9994 15.7085 23.9994 13.4994V11.9994H21.9994V13.4994C21.9994 14.604 21.104 15.4994 19.9994 15.4994H11.9997H2V3.99939H14V1.99939H1ZM17 1.99939C16.4477 1.99939 16 2.4471 16 2.99939C16 3.55167 16.4477 3.99939 17 3.99939H20.5853L16.2928 8.29232C15.9023 8.68286 15.9024 9.31603 16.2929 9.70653C16.6834 10.097 17.3166 10.097 17.7071 9.70646L21.9994 5.41377V8.99939C21.9994 9.55167 22.4471 9.99939 22.9994 9.99939C23.5517 9.99939 23.9994 9.55167 23.9994 8.99939V2.99939C23.9994 2.4471 23.5517 1.99939 22.9994 1.99939H17Z"
      fill={props.color}
    />
  </svg>
);
SvgStartScreenShareLarge.displayName = 'SvgStartScreenShareLarge';
const StartScreenShareLarge = withForgeIconProps(SvgStartScreenShareLarge, {
  name: 'StartScreenShare',
  usage: 'Use to initiate a screenshare',
  related: 'Desktop',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default StartScreenShareLarge;
