import rawContents from "!!raw-loader!./ToggleSwitchAppManager.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = {};

function ToggleSwitchAppManager():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Toggle Switch App Manager" />;
}

export default ToggleSwitchAppManager;
