import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSendLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.71 8.74023L9.56995 16.25V23L12.35 19.5L13.5699 17.9902M19.45 20.7998L23 1L1 12L19.45 20.7998Z"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SvgSendLarge.displayName = 'SvgSendLarge';
const SendLarge = withForgeIconProps(SvgSendLarge, {
  name: 'Send',
  usage: 'Use to send a composed message',
  related: 'mail, enter, submit',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default SendLarge;
