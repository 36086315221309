import React, { useEffect } from 'react';
import type { DataTableStore } from './store/stateTypes';
import { DataTableProps, getDataTableDefaultPropValues } from './DataTable';

// Actions
import { refresh_row_data } from './store/actions/refresh_row_data';
import { apply_client_side_filters } from './store/actions/apply_client_side_filters';
import { apply_server_side_filters } from './store/actions/apply_server_side_filters';

// Prop Receivers
import { ColumnPropReceiver } from './propReceivers/ColumnPropReceiver';
import { DataFilterPropReceiver } from './propReceivers/DataFilterPropReceiver';
import { QuickFilterPropReceiver } from './propReceivers/QuickFilterPropReceiver';
import { PaginationPropReceiver } from './propReceivers/PaginationPropReceiver';
import { StateChangeObserver } from './propReceivers/StateChangeObserver';
import { ImperativeHandler } from './propReceivers/ImperativeHandler';

type DataTableInterface<RowData> = (props: DataTableProps<RowData>) => JSX.Element;

/**
 * `DataTableInterface` is a props receiver component. It doesn't have UI, but only has many
 * `useEffects` to detect the props changed and dispatch properly actions to the store.
 *
 * More details: https://athenaconfluence.athenahealth.com/x/lJEoIg
 */
export function createDataTableInterface<RowData>(TableStore: DataTableStore<RowData>): DataTableInterface<RowData> {
  /** Receives props and updates the DataStore when those props change
   *
   * It's particularly important that default values for objects and functions
   * are compile-time constants so that undefined props don't automatically
   * trigger re-renders of DataTableRendererMemo.
   */
  return React.forwardRef(function DataTableInterface(
    props: Omit<DataTableProps<RowData>, 'ref'>,
    ref: DataTableProps<RowData>['ref']
  ): JSX.Element {
    const {
      tableType,

      // ==================================
      // 1. UI Options
      // ==================================
      className,
      rootStyle,
      tableStyle,
      tableLayout,
      tableShowVerticalDividers,
      tableStickyHeaders,
      tableWrapHeaderStyle,
      grayDeletedRows,
      onRenderRowProps,

      // ==================================
      // 2. Table Title Panel
      // ==================================
      title,
      entityName,
      lastDataUpdateTimeInTableTitle,
      showServerSideRefreshButton,
      CustomAddRowComponent,
      csvDownload,

      // ==================================
      // 3. Quick Filter Panel
      // ==================================
      quickFilter,

      // ==================================
      // 4. Data Filter Panel
      // ==================================
      filterHideable,
      filterPanelExpanded,
      filters,

      // ==================================
      // 5. Control Panel: bulk edit, edit table button, delete rows checkbox, search box, paginator
      // ==================================
      bulkEdit,
      CustomBulkEditComponent,
      editTableButton,
      showDeleteRowCheckbox,
      showSearchBox,
      showColumnConfigButton,
      pagination,

      // ==================================
      // 6. Columns
      // ==================================
      showColumnFilters,
      columns,

      // ==================================
      // 7. Rows
      // ==================================
      rowData,
      determineSelectableRow,
      serverSideFetchRowDataFn,

      // ==================================
      // 8. Expanded Table Row
      // ==================================
      ExpandedTableRow,
      determineExpandableRow,

      // ==================================
      // 9. Click Rows
      // ==================================
      onRowClickLeft,
      enableContextMenu,

      // ==================================
      // 10. Empty State
      // ==================================
      CustomEmptyStateComponent,

      // ==================================
      // 11. Loader
      // ==================================
      loader,

      // ==================================
      // 12. Callbacks
      // ==================================
      onStateChanged,
      onSelectedRowsChanged,
    } = { ...getDataTableDefaultPropValues<RowData>(), ...props };

    const dispatch = TableStore.useDispatch();

    useEffect(() => {
      dispatch(function update_table_type(s) {
        s.tableType = tableType;
      });
    }, [dispatch, tableType]);

    // ==================================
    // 1. UI Options (8)
    // ==================================
    useEffect(() => {
      dispatch(function update_root_class_name(s) {
        s.rootClassName = className;
      });
    }, [dispatch, className]);

    useEffect(() => {
      dispatch(function update_root_style(s) {
        s.rootStyle = rootStyle;
      });
    }, [dispatch, rootStyle]);

    useEffect(() => {
      dispatch(function update_table_style(s) {
        s.tableStyle = tableStyle;
      });
    }, [dispatch, tableStyle]);

    useEffect(() => {
      dispatch(function update_table_layout(s) {
        s.tableLayout = tableLayout;
      });
    }, [dispatch, tableLayout]);

    useEffect(() => {
      dispatch(function update_table_show_vertical_dividers(s) {
        s.tableShowVerticalDividers = tableShowVerticalDividers;
      });
    }, [dispatch, tableShowVerticalDividers]);

    useEffect(() => {
      dispatch(function update_table_sticky_header_and_footer(s) {
        s.tableStickyHeaders = tableStickyHeaders;
      });
    }, [dispatch, tableStickyHeaders]);

    useEffect(() => {
      dispatch(function update_table_wrap_headers(s) {
        s.tableWrapHeaderStyle = tableWrapHeaderStyle;
      });
    }, [dispatch, tableWrapHeaderStyle]);

    useEffect(() => {
      dispatch(function update_gray_deleted_rows(s) {
        s.grayDeletedRows = grayDeletedRows;
      });
    }, [dispatch, grayDeletedRows]);

    useEffect(() => {
      dispatch(function update_on_render_row(s) {
        s.onRenderRowProps = onRenderRowProps;
      });
    }, [dispatch, onRenderRowProps]);

    // ==================================
    // 2. Table Title Panel: title, entity name, add row component, csv download button
    // ==================================
    useEffect(() => {
      dispatch(function update_title(s) {
        s.tableTitle = title;
      });
    }, [dispatch, title]);

    useEffect(() => {
      dispatch(function update_entity_name(s) {
        s.entityName = { ...s.entityName, ...entityName };
      });
    }, [dispatch, entityName]);

    useEffect(() => {
      dispatch(function update_last_data_update_time_in_title(s) {
        s.lastDataUpdateTimeInTableTitle = {
          ...s.lastDataUpdateTimeInTableTitle,
          ...lastDataUpdateTimeInTableTitle,
        };
      });
    }, [dispatch, lastDataUpdateTimeInTableTitle]);

    useEffect(() => {
      dispatch(function update_refresh_data_table(s) {
        s.showServerSideRefreshButton = showServerSideRefreshButton;
      });
    }, [dispatch, showServerSideRefreshButton]);

    useEffect(() => {
      dispatch(function update_custom_add_row_component(s) {
        s.CustomAddRowComponent = CustomAddRowComponent;
      });
    }, [dispatch, CustomAddRowComponent]);

    useEffect(() => {
      dispatch(function update_show_csv_download(s) {
        s.csvDownload = { ...s.csvDownload, ...csvDownload };
      });
    }, [dispatch, csvDownload]);

    // ==================================
    // 4. Data Filter Panel
    // ==================================
    useEffect(() => {
      dispatch(function update_data_filter_hideable(s) {
        s.dataFilter.isHideable = filterHideable;
      });
    }, [dispatch, filterHideable]);

    useEffect(() => {
      dispatch(function update_data_filter_panel_expanded(s) {
        s.dataFilter.isExpanded = filterPanelExpanded;
      });
    }, [dispatch, filterPanelExpanded]);

    // ==================================
    // 5. Control Panel: bulk edit, edit table button, delete rows checkbox, search box, paginator
    // ==================================
    useEffect(() => {
      dispatch(function update_bulk_edit(s) {
        s.bulkEdit = { ...s.bulkEdit, ...bulkEdit };
      });
    }, [dispatch, bulkEdit]);

    useEffect(() => {
      dispatch(function update_custom_bulk_edit_component(s) {
        s.CustomBulkEditComponent = CustomBulkEditComponent;
      });
    }, [dispatch, CustomBulkEditComponent]);

    useEffect(() => {
      dispatch(function update_edit_table_button(s) {
        s.editTableButton = {
          ...s.editTableButton,
          ...editTableButton,
        };
      });
    }, [dispatch, editTableButton]);

    useEffect(() => {
      dispatch(function update_delete_rows_checkbox(s) {
        s.deletedRowsCheckbox.show = showDeleteRowCheckbox;
      });
    }, [dispatch, showDeleteRowCheckbox]);

    useEffect(() => {
      dispatch(function update_show_search_box(s) {
        s.searchBox.show = showSearchBox;
      });
    }, [dispatch, showSearchBox]);

    useEffect(() => {
      dispatch(function update_enable_column_config(s) {
        s.columnConfig.enable = showColumnConfigButton;
      });
    }, [dispatch, showColumnConfigButton]);

    // ==================================
    // 6. Columns
    // ==================================
    useEffect(() => {
      dispatch(function update_show_column_filters(s) {
        s.showColumnFilters = showColumnFilters;
      });
    }, [dispatch, showColumnFilters]);

    // ==================================
    // 8. Expanded Table Row
    // ==================================
    useEffect(() => {
      dispatch(function update_expanded_table_row_component(s) {
        s.ExpandedTableRow = ExpandedTableRow;
      });
    }, [dispatch, ExpandedTableRow]);

    // ==================================
    // 9. Click Rows
    // ==================================
    useEffect(() => {
      dispatch(function update_on_row_click_left_callback(s) {
        s.onRowClickLeft = onRowClickLeft;
      });
    }, [dispatch, onRowClickLeft]);

    useEffect(() => {
      dispatch(function update_enable_context_menu(s) {
        s.contextMenu.enable = enableContextMenu;
      });
    }, [dispatch, enableContextMenu]);

    // ==================================
    // 10. Empty State
    // ==================================
    useEffect(() => {
      dispatch(function update_empty_state_component(s) {
        s.CustomEmptyStateComponent = CustomEmptyStateComponent;
      });
    }, [dispatch, CustomEmptyStateComponent]);

    // ==================================
    // 11. Loader
    // ==================================
    useEffect(() => {
      dispatch(function update_external_loader(s) {
        s.externalLoader = loader;
      });
    }, [dispatch, loader]);

    // ==================================
    // Rows (Important: Keep Rows updated behind other props)
    // ==================================
    useEffect(() => {
      dispatch(function update_determine_selectable_row(s) {
        s.determineSelectableRow = determineSelectableRow;
      });
    }, [dispatch, determineSelectableRow]);

    useEffect(() => {
      dispatch(function update_determine_expandable_row(s) {
        s.determineExpandableRow = determineExpandableRow;
      });
    }, [dispatch, determineExpandableRow]);

    useEffect(() => {
      dispatch(refresh_row_data(rowData));
    }, [dispatch, rowData]);

    /* Important: Keep update_fetch_row_data as the last useEffect */
    useEffect(() => {
      dispatch(function update_fetch_row_data(s) {
        s.serverSideFetchRowDataFn = serverSideFetchRowDataFn;
        apply_client_side_filters(s);
        apply_server_side_filters(s);
      });
    }, [dispatch, serverSideFetchRowDataFn]);

    return (
      <>
        <ColumnPropReceiver dispatch={dispatch} columns={columns} />
        <QuickFilterPropReceiver dispatch={dispatch} quickFilter={quickFilter} />
        <DataFilterPropReceiver dispatch={dispatch} filters={filters} />
        <PaginationPropReceiver dispatch={dispatch} pagination={pagination} />
        <ImperativeHandler store={TableStore} ref={ref} />
        <StateChangeObserver
          store={TableStore}
          onStateChanged={onStateChanged}
          onSelectedRowsChanged={onSelectedRowsChanged}
        />
      </>
    );
  }) as <RowData>(props: DataTableProps<RowData>) => JSX.Element;
}
