import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgChatSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15 2H1V12.2339H4L5.68005 14.5725C5.77221 14.7045 5.89483 14.8123 6.0376 14.8867C6.18036 14.9611 6.33899 15 6.5 15C6.66101 15 6.81964 14.9611 6.9624 14.8867C7.10517 14.8123 7.22779 14.7045 7.31995 14.5725L9 12.2339H12C12.7837 12.2341 13.5363 11.9279 14.0969 11.3807C14.6576 10.8334 14.9817 10.0887 15 9.30574V2Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
SvgChatSmall.displayName = 'SvgChatSmall';
const ChatSmall = withForgeIconProps(SvgChatSmall, {
  name: 'Chat',
  usage: 'Use to open a chat window or inbox, or to indicate messages',
  related: 'message, conversation, notification, mail, chatting',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default ChatSmall;
