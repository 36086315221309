import { withPrefix } from 'gatsby';

import rawContents from "!!raw-loader!./AvatarSquare.demo.tsx";
import DemoContainer from "../../components/demo-container";

const scope = { withPrefix };

function AvatarSquare():React.ReactElement {
    return <DemoContainer scope={scope} contents={rawContents} description="Avatar Square" />;
}

export default AvatarSquare;
