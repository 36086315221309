import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInfoButtonLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M9.34998 10.56C9.88998 9.76999 10.69 9.00981 11.65 9.37981C11.8859 9.47702 12.0921 9.63458 12.2483 9.83636C12.4044 10.0381 12.505 10.2772 12.5399 10.53C12.5843 10.9313 12.5259 11.3373 12.37 11.7099C12.06 12.4899 11.75 13.27 11.37 14.04C10.9611 14.8666 10.6652 15.7444 10.49 16.6498C10.3209 17.3035 10.4067 17.9972 10.73 18.59C10.9282 18.8561 11.1978 19.0603 11.5077 19.1789C11.8175 19.2975 12.1548 19.3256 12.48 19.2599C13.2361 19.0943 13.8989 18.6429 14.33 17.9999"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.85 7.28003C12.6784 7.28003 13.35 6.60846 13.35 5.78003C13.35 4.9516 12.6784 4.28003 11.85 4.28003C11.0215 4.28003 10.35 4.9516 10.35 5.78003C10.35 6.60846 11.0215 7.28003 11.85 7.28003Z"
      fill={props.color}
    />
  </svg>
);
SvgInfoButtonLarge.displayName = 'SvgInfoButtonLarge';
const InfoButtonLarge = withForgeIconProps(SvgInfoButtonLarge, {
  name: 'InfoButton',
  usage: 'Use in a Button to get more information',
  related: 'guide, tutorial',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default InfoButtonLarge;
