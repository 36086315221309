import { ReactNode, ReactElement } from 'react';
import { GatsbyPageContext, PageContext, PageData } from '../../contexts';
import { useStaticQuery, graphql, PageProps } from 'gatsby';
interface PageContextProviderProps {
  pageContext?: GatsbyPageContext;
  children: ReactNode;
  title?: string;
  pageData?: PageData;
  location?: PageProps['location'];
}

export type ChildNavMap = Record<string, string>;

export interface Version {
  version: string;
  link: string;
}

export interface MenuItem {
  id: string;
  menu_name: string;
  title: string;
  description: string;
  route: {
    parameters: {
      node: string;
    };
  };
  url: string;
  childrenMenuItems: MenuItem[];
}
export interface ComponentNode {
  title: string;
  field_component_preview: {
    title: string;
  };
  relationships: {
    field_component_preview: {
      uri: {
        url: string;
      };
    };
  };
  drupal_internal__nid: number;
}
export interface SVGIcons {
  id: string;
  name: string;
  svg: {
    content: string;
  };
  internal: {
    mediaType: string;
  };
}

export interface ContextData {
  allMenuItems: { edges: Array<{ node: MenuItem }> };
  allNodeComponent: { nodes: ComponentNode[] };
  iconsSVG: {
    nodes: SVGIcons[];
  };
}

/**
 * For best performance, context providers should wrap children in useMemo or
 * be refactored into a separate component: https://www.youtube.com/watch?v=CDGBTjMBJzg
 */
export default function PageContextProvider({
  children,
  pageContext,
  pageData,
  location,
}: PageContextProviderProps): ReactElement {
  const data: ContextData = useStaticQuery(graphql`
    query PageContextQuery {
      allMenuItems(filter: { parent: { id: { eq: null } } }, sort: { fields: [weight], order: DESC }) {
        edges {
          node {
            id
            menu_name
            title
            description
            route {
              parameters {
                node
              }
            }
            url
            childrenMenuItems {
              id
              menu_name
              title
              description
              route {
                parameters {
                  node
                }
              }
              url
            }
          }
        }
      }
      allNodeComponent {
        nodes {
          title
          field_component_preview {
            title
          }
          relationships {
            field_component_preview {
              uri {
                url
              }
            }
          }
          drupal_internal__nid
        }
      }
      iconsSVG: allFile(filter: { sourceInstanceName: { eq: "icons" } }) {
        nodes {
          id
          name
          svg {
            content
          }
          internal {
            mediaType
          }
        }
      }
    }
  `);

  const menuItems = data.allMenuItems.edges.map((edge) => edge.node);

  const childParentMap: ChildNavMap = menuItems.reduce((accumulator: ChildNavMap, menuItem: MenuItem) => {
    const linkedPageId = menuItem.route.parameters.node;
    accumulator[linkedPageId] = linkedPageId;

    menuItem.childrenMenuItems.forEach((childMenuItem) => {
      accumulator[childMenuItem.route.parameters.node] = linkedPageId;
    });

    return accumulator;
  }, {});

  const svgIcons = data.iconsSVG.nodes;

  return (
    <PageContext.Provider value={{ childParentMap, pageContext, menuItems, pageData, location, svgIcons }}>
      {children}
    </PageContext.Provider>
  );
}
