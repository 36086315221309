import { DataTableSortDirection } from '../utils/internalTypes';
import { DataTableStore } from '../store/stateTypes';
import { classes } from '../utils/dataTableClasses';
import { hasRowsInEditStatusFromState } from '../utils/hasRowsInEditStatusFromState';
import { DataTableBlockUI } from '../utils/DataTableBlockUI';
import Button from '../../Button';
import { DEFAULT_CLIENT_SORT_FN } from '../store/stateTypes';

const NEXT_SORTING_DIRECTION: Record<DataTableSortDirection, DataTableSortDirection> = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};

type SortControllerProps<RowData> = {
  columnId: string;
  store: DataTableStore<RowData>;
};

export function SortController<RowData>({ columnId, store }: SortControllerProps<RowData>): JSX.Element {
  const tableType = store.useSelector((s) => s.tableType);
  const dispatch = store.useDispatch();
  const hasRowsInEditStatus = store.useSelector((s) => hasRowsInEditStatusFromState(s));
  const sort = store.useSelector((s) => s.columns[columnId]?.sort);
  if (typeof sort === 'undefined' || !sort.enable) {
    return <></>;
  }

  const nextDirection = NEXT_SORTING_DIRECTION[sort.direction];

  return (
    <DataTableBlockUI block={hasRowsInEditStatus}>
      <Button
        {...classes({ element: 'column-header__sort-controller' })}
        disabled={hasRowsInEditStatus}
        variant="tertiary"
        onClick={() => {
          dispatch(function sorting(s) {
            // Reset all the column's sorting direction
            for (const columnId in s.columns) {
              s.columns[columnId].sort.direction = '';
            }

            // Check the row length
            if (s.filteredAndSortedRowIds.length === 0) return;

            // Restore the initial sequence
            s.filteredAndSortedRowIds.sort((a, b) => a - b);

            // Get the next direction
            s.columns[columnId].sort.direction = nextDirection;

            // ==========================
            // Client-side Sorting
            // ==========================
            if (tableType === 'client-side') {
              if (nextDirection === '') return;

              s.filteredAndSortedRowIds.sort((a, b) => {
                /**
                 * Provide backward compatibility
                 *
                 * if client app is still providing sort definition via deprecated prop, use it for backward compatibility
                 */
                if (sort.clientSideSortAscFn !== DEFAULT_CLIENT_SORT_FN) {
                  return sort.clientSideSortAscFn(s.rows[a].data, s.rows[b].data) * (nextDirection === 'asc' ? 1 : -1);
                }

                /**
                 * If deprecated prop is not provided we return the value assigned to clientSideSortFn
                 *
                 * This will either be DEFAULT_CLIENT_SORT_FN or provided definition
                 */

                return sort.clientSideSortFn(s.rows[a].data, s.rows[b].data, nextDirection);
              });
              return;
            }

            // ==========================
            // Server-side Sorting
            // ==========================
            if (tableType === 'server-side') {
              s.serverSideFetchRowDataArgs.sortByColumnId = nextDirection ? columnId : '';
              s.serverSideFetchRowDataArgs.sortOrder = nextDirection ?? '';
              return;
            }
          });
        }}
      >
        <span
          aria-label="data-table-column-header-sort-controller-icon"
          {...classes({
            element: 'column-header__sort-controller__icon',
            modifiers: {
              asc: sort.direction === 'asc',
              desc: sort.direction === 'desc',
            },
          })}
        />
      </Button>
    </DataTableBlockUI>
  );
}
