import { NAMESPACE, LAYOUT_PREFIX } from '../utils/constants';
import { forgeClassHelper } from '../utils/classes';

const gridRowClasses = forgeClassHelper({
  prefix: `${NAMESPACE}_${LAYOUT_PREFIX}_`,
  name: 'grid-row',
});

const getModifiers = function (propName, modfiers) {
  return Object.keys(modfiers).map(
    (breakpoint) => (propName ? propName + '-' : '') + breakpoint + '-' + modfiers[breakpoint]
  );
};

export { gridRowClasses, getModifiers };
