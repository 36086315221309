import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgQualityLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16 23L15.4 23.8C15.703 24.0273 16.1084 24.0638 16.4472 23.8944C16.786 23.725 17 23.3788 17 23H16ZM12 20L12.6 19.2C12.2444 18.9333 11.7556 18.9333 11.4 19.2L12 20ZM8 23H7C7 23.3788 7.214 23.725 7.55279 23.8944C7.89157 24.0638 8.29698 24.0273 8.6 23.8L8 23ZM8.67175 8.22919C8.26263 7.8582 7.63022 7.8891 7.25922 8.29822C6.88823 8.70734 6.91913 9.33975 7.32825 9.71075L8.67175 8.22919ZM10.79 11.5L10.1182 12.2408C10.5074 12.5937 11.1034 12.5853 11.4824 12.2215L10.79 11.5ZM16.6924 7.2215C17.0909 6.83909 17.1039 6.20606 16.7215 5.80758C16.3391 5.40911 15.7061 5.39609 15.3076 5.7785L16.6924 7.2215ZM15 16.1001V23H17V16.1001H15ZM16.6 22.2L12.6 19.2L11.4 20.8L15.4 23.8L16.6 22.2ZM11.4 19.2L7.4 22.2L8.6 23.8L12.6 20.8L11.4 19.2ZM9 23V16H7V23H9ZM19 9C19 12.866 15.866 16 12 16V18C16.9706 18 21 13.9706 21 9H19ZM12 16C8.13401 16 5 12.866 5 9H3C3 13.9706 7.02944 18 12 18V16ZM5 9C5 5.13401 8.13401 2 12 2V0C7.02944 0 3 4.02944 3 9H5ZM12 2C15.866 2 19 5.13401 19 9H21C21 4.02944 16.9706 0 12 0V2ZM7.32825 9.71075L10.1182 12.2408L11.4617 10.7592L8.67175 8.22919L7.32825 9.71075ZM11.4824 12.2215L16.6924 7.2215L15.3076 5.7785L10.0976 10.7785L11.4824 12.2215Z"
      fill={props.color}
    />
  </svg>
);
SvgQualityLarge.displayName = 'SvgQualityLarge';
const QualityLarge = withForgeIconProps(SvgQualityLarge, {
  name: 'Quality',
  usage: 'Use to indicate quality measure parameters',
  related: 'legal, meaningful use, survey, data',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default QualityLarge;
